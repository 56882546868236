import React from "react";

import {
  projectQuery,
  TOOLTIP_DEFAULT_SPACING,
  TOOLTIP_HEIGHT,
  TRACK_ELEMENT_HEIGHT,
  TrackElement,
  TrackElementType
} from "@store/project";
import {bindHover, usePopupState} from "material-ui-popup-state/hooks";

import TooltipDetails from "@screens/auth/common/schema/elements/TooltipDetails";
import {Colors} from "@utils/theme/constants.utils";

interface PointElementProps {
  handleClick?: () => void;
  position?: "bottom" | "top";
  showTooltip?: boolean;
  tooltipDirection?: 'start' | 'end';
  trackElement: TrackElement;
  trackName: string;
  x: number;
  y: number;
}

const PointElement = (props: PointElementProps) => {
  const {handleClick, position = "top", showTooltip, tooltipDirection, trackElement, trackName, x, y} = props;

  const tooltipState = usePopupState({variant: 'popover', popupId: `tooltip-${trackElement.id}`});

  const tooltipCoo = {
    x,
    y: y + (position === "top" ? -1 : 1) * (TRACK_ELEMENT_HEIGHT / 2 + TOOLTIP_HEIGHT / 2 + TOOLTIP_DEFAULT_SPACING),
  };

  const textCooX = tooltipCoo.x + (tooltipDirection === "start" ? -1 : 1) * (TOOLTIP_HEIGHT / 2 + 8);

  const wmNotOnRail = projectQuery.isProjectTypeNew && trackElement.type === TrackElementType.WORKSITE_MACHINE && !trackElement.attributes?.onRail;

  return (
    <g {...bindHover(tooltipState)}>
      <circle cx={x} cy={y} fill={TrackElementType.getColor(trackElement)} r={TRACK_ELEMENT_HEIGHT / 2}/>
      {(showTooltip || tooltipState.isOpen) && (
        <g>
          <rect
            style={{cursor: handleClick ? 'pointer' : undefined}} onClick={handleClick}
            x={x - TOOLTIP_HEIGHT / 2 - 4}
            y={y - (position === "top" ? TOOLTIP_DEFAULT_SPACING + TOOLTIP_HEIGHT + 8 + TRACK_ELEMENT_HEIGHT / 2 : 0)}
            rx={TRACK_ELEMENT_HEIGHT / 2} ry={TRACK_ELEMENT_HEIGHT / 2}
            fill="transparent"
            width={TOOLTIP_HEIGHT + 8} height={TOOLTIP_DEFAULT_SPACING + TOOLTIP_HEIGHT + 8}/>
          {!!trackElement.attributes?.APN && (
            <text fontSize={10} fontWeight="400" alignmentBaseline="middle"
                  x={tooltipCoo.x - ((trackElement.attributes.APN?.length || 0) * 6 + 25)} y={tooltipCoo.y}
                  fill={Colors.schema.elements.PN}>
              {trackElement.attributes.APN}
            </text>)} {/*à revoir position */}
          <g style={{cursor: handleClick ? 'pointer' : undefined}} onClick={handleClick}>
            <line
              x1={x} x2={x}
              y1={y + (position === "top" ? -1 : 1) * (TRACK_ELEMENT_HEIGHT / 2)}
              y2={y + (position === "top" ? -1 : 1) * (TRACK_ELEMENT_HEIGHT / 2 + TOOLTIP_DEFAULT_SPACING)}
              strokeWidth={2} stroke={TrackElementType.getColor(trackElement)}/>
            <circle
              cx={tooltipCoo.x} cy={tooltipCoo.y} r={TOOLTIP_HEIGHT / 2}
              fill={TrackElementType.getColor(trackElement)}
              stroke={!!trackElement.attributes?.APN || wmNotOnRail ? Colors.secondaryRed : undefined}
              strokeWidth={3}
            />
            <image
              xlinkHref={TrackElementType.getIcon(trackElement.type)}
              height={24} width={24}
              x={tooltipCoo.x - 12}
              y={tooltipCoo.y - 12}/>
          </g>
          <TooltipDetails
            trackElement={trackElement} trackName={trackName}
            position={tooltipDirection}
            x={textCooX} y={tooltipCoo.y}/>
        </g>
      )}
    </g>
  );
}

export default PointElement;
