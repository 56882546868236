import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {ID} from '@datorama/akita';
import _ from 'lodash';
import {DispatchTypeEnum, TTxDispatchType} from '@store/dispatches/dispatch.model';

interface checkNoImmobilizedTTXOutsideZFOrZTParams {
  fromDispatch?: boolean;
  dispatchType?: TTxDispatchType;
  trackElementId?: ID;
  pkStart: Pk;
  length: number;
  ZFs: TrackElement[];
  ZTs: TrackElement[];
}

/* Should not be able to immobilize TTx outside a ZF or a sleeping ZT */
const checkNoImmobilizedTTXOutsideZFOrZT = (params: checkNoImmobilizedTTXOutsideZFOrZTParams): IncompatibilityError[] => {
  const {fromDispatch, dispatchType, trackElementId, pkStart, length, ZFs, ZTs} = params;

  if (!fromDispatch || !dispatchType || dispatchType !== DispatchTypeEnum.MOVEMENT_IMMOBILIZATION_TTX) return [];

  const pkEnd = Pk.addMeters(pkStart, length);

  const ZTsAndZFsOnTTx = [...ZTs, ...ZFs].filter((t) =>
    !!t.pkEnd &&
    (Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd) ||
      Pk.isPkBetweenPks(pkEnd, t.pkStart, t.pkEnd) ||
      Pk.isPkBetweenPks(t.pkStart, pkStart, pkEnd) ||
      Pk.isPkBetweenPks(t.pkEnd, pkStart, pkEnd))
    && !_.isEqual(pkStart, t.pkEnd) && !_.isEqual(pkEnd, t.pkStart));

  if (!ZTsAndZFsOnTTx.length) {
    return [{
      error: IncompatibilityErrorEnum.NO_IMMOBILIZED_TTX_OUTSIDE_ZF_OR_ZT,
      trackElementId,
      concernedTrackElementIds: [],
    }];
  }

  return [];
}

export default checkNoImmobilizedTTXOutsideZFOrZT;