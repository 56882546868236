import {object, ref, string} from 'yup';

import * as YupUtils from "@utils/yup.utils";
import I18n from "@utils/i18n.utils";

import {UpdateCurrentUserPassword} from '@store/session';

export const newPasswordValidation = (data: UpdateCurrentUserPassword) => {
  const requiredString = string().required(I18n.t("errors.required"));

  try {
    const schema = object().shape({
      currentPassword: requiredString,
      newPassword: requiredString
        .min(8, I18n.t("errors.tooShort", {count: 8}))
        .test("oneUppercase", I18n.t("errors.oneUppercase"), (value) => !!value && /[A-Z]/.test(value))
        .test("oneLowercase", I18n.t("errors.oneLowercase"), (value) => !!value && /[a-z]/.test(value))
        .test("oneNumber", I18n.t("errors.oneNumber"), (value) => !!value && /[0-9]/.test(value))
        .test("oneSpecialChar", I18n.t("errors.oneSpecialChar"), (value) => !!value && /[!@#$%^&*()]/.test(value)),
      confirmPassword: requiredString.oneOf([ref('newPassword')], I18n.t("errors.confirmPassword")),
    });

    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}
