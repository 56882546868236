import dayjs from "dayjs";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import IsSameOrBefore from "dayjs/plugin/isSameOrBefore";

import frLocale from "dayjs/locale/fr";

dayjs.locale(frLocale);
dayjs.extend(CustomParseFormat);
dayjs.extend(IsSameOrBefore);

export default dayjs;
