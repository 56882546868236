import React from 'react';
import {FormHelperText, InputLabel, MenuItem, Select} from '@mui/material';
import {WithStyles, withStyles} from "@mui/styles";

import style from "./Select.style";

export interface MultiSelectComponentItem {
  label: string;
  value: any;
}

interface MultiSelectComponentProps extends WithStyles<typeof style> {
  color?: 'primary' | 'secondary';
  label?: string;
  handleChange?: (value: any) => void;
  fullWidth?: boolean;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  error?: string;
  values?: any[];
  items?: MultiSelectComponentItem[];
}

const MultiSelectComponent = (props: MultiSelectComponentProps) => {
  const {
    classes,
    color = "primary",
    label,
    fullWidth = true,
    handleChange,
    required,
    disabled,
    placeholder,
    readOnly,
    error,
    values = [],
    items,
  } = props;

  return (
    <>
      {!!label && <InputLabel error={!!error}>{label}{(required) ? '*' : ''}</InputLabel>}
      <Select
        color={color}
        multiple
        classes={{
          filled: !values?.length ? classes.placeholder : undefined,
        }}
        IconComponent={(params) => <img alt="" src="/images/arrow_down_icon.svg" {...params} />}
        onChange={(evt) => handleChange?.(evt.target.value)}
        fullWidth={fullWidth}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        error={!!error}
        value={values}>
        {placeholder && (
          <MenuItem value="" className={classes.placeholder}>{placeholder}</MenuItem>
        )}
        {items?.map((item) => (
          <MenuItem key={`${item.label} ${item.value}`} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

export default withStyles(style)(MultiSelectComponent);
