import React, {useEffect, useState} from "react";
import {finalize} from "rxjs";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {Outlet, useNavigate, useParams} from "react-router-dom";

import {
  CreateOrUpdateTrackElement,
  projectQuery,
  projectService,
  TrackElementCategory,
  TrackElementType
} from "@store/project";
import {sessionQuery, sessionService} from "@store/session";

import TrainTrackForm from "@screens/auth/common/schema/forms/trackElements/TrainTrack.form";
import TrackDeviceForm from "@screens/auth/common/schema/forms/trackElements/TrackDevice.form";
import InstallationForm from "@screens/auth/common/schema/forms/trackElements/Installation.form";
import SignalisationForm from "@screens/auth/common/schema/forms/trackElements/Signalisation.form";
import ZoneForm from "@screens/auth/common/schema/forms/trackElements/Zone.form";
import DeclivityForm from "@screens/auth/common/schema/forms/trackElements/Declivity.form";
import PRSForm from "@screens/auth/common/schema/forms/trackElements/PRS.form";
import WorksiteForm from "@screens/auth/common/schema/forms/trackElements/Worksite.form";
import ActivityForm from "@screens/auth/common/schema/forms/trackElements/Activity.form";
import RestrictionsForm from "@screens/auth/common/schema/forms/trackElements/Restrictions.form";
import FullScreenSpecialZoneModal from "@screens/auth/common/schema/components/FullScreenSpecialZone.modal";
import TrainStationForm from "@screens/auth/common/schema/forms/trackElements/TrainStation.form";
import ConstructionForm from "@screens/auth/common/schema/forms/trackElements/Construction.form";
import ManageWorksiteMachineForm
  from "@screens/auth/common/schema/forms/trackElements/editionModals/ManageWorksiteMachine.form";
import ManageCEForm from "@screens/auth/common/schema/forms/trackElements/editionModals/ManageCE.form";
import DeleteConfirmation from "@screens/auth/common/schema/components/DeleteConfirmation.component";
import ConfirmCodeModal from "@components/confirmCode/ConfirmCode.modal";
import {IncompatibilityError} from "@utils/incompatibilities/incompatibility.utils";
import {Role} from '@store/users';

const ManageTrackElementForm = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const {subTrackElementId, trackElementId} = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmCode, setShowConfirmCode] = useState<IncompatibilityError[] | false>(false);

  const [trackElement, setTrackElement] = useState<CreateOrUpdateTrackElement | undefined>();

  const [elementId, setElementId] = useState(subTrackElementId || trackElementId || "");

  useEffect(() => {
    const _trackElementId$ = projectQuery.projectTrackElementById$(elementId).subscribe((el) => {
      setTrackElement(el ? CreateOrUpdateTrackElement.fromTrackElement(el) : undefined);
    });

    return () => {
      _trackElementId$.unsubscribe();
    };
  }, [elementId]);

  useEffect(() => {
    setElementId(subTrackElementId || trackElementId || "");
  }, [subTrackElementId, trackElementId]);

  const handleClose = () => navigate(subTrackElementId && trackElementId ? ".." : "../..");

  if (!trackElement) return null;

  const handleValidate = (incompatibilities?: IncompatibilityError[]) => {
    setLoading(true);
    projectService.updateTrackElement(
      trackElement,
      incompatibilities?.map((i) => t(`incompatibilities.${i.error}`))
    )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("schema.success.elementUpdated"), {variant: "success"});
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleDelete = (incompatibilities?: IncompatibilityError[]) => {
    setLoading(true);
    projectService
      .deleteTrackElement(trackElement.id || "", incompatibilities?.map((i) => t(`incompatibilities.${i.error}`)))
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("schema.success.elementDeleted"), {variant: "success"});
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const displayRightTrackElement = () => {
    switch (trackElement.category) {
      case TrackElementCategory.TRAIN_TRACK:
        return (
          <TrainTrackForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.trainTrack.title.edit")}
            description={t("schema.trainTrack.description.edit")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleDelete={() => setShowConfirmDelete(true)}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
          />
        );
      case TrackElementCategory.TRACK_DEVICE:
        if (trackElement.type === TrackElementType.TRACK_CHANGE) return null;
        return (
          <TrackDeviceForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.trackDevice.title.edit")}
            description={t("schema.trackDevice.description.edit")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleDelete={() => setShowConfirmDelete(true)}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
          />
        );
      case TrackElementCategory.INSTALLATION:
        if (!projectQuery.isProjectTypeNew && trackElement.type === TrackElementType.PN) return null;
        return (
          <InstallationForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.installation.title.edit")}
            description={t("schema.installation.description.edit")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleDelete={() => setShowConfirmDelete(true)}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
          />
        );
      case TrackElementCategory.SIGNALISATION:
        return (
          <SignalisationForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.signalisation.title.edit")}
            description={t("schema.signalisation.description.edit")}
            setTrackElement={setTrackElement}
            hideDelete={!sessionQuery.role || ![Role.SUPER_ADMIN, Role.OS_C, Role.OS_R].includes(sessionQuery.role)}
            handleClose={handleClose}
            handleDelete={() => setShowConfirmDelete(true)}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
          />
        );
      case TrackElementCategory.ZONE:
        if (trackElement.type === TrackElementType.TRAINING_ZONE) {
          return <FullScreenSpecialZoneModal handleClose={handleClose}/>;
        }

        return (
          <ZoneForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.zone.title.edit")}
            description={t("schema.zone.description.edit")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleDelete={() => setShowConfirmDelete(true)}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
          />
        );
      case TrackElementCategory.PRS:
        return (
          <PRSForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.PRS.title.edit")}
            description={t("schema.PRS.description.edit")}
            setTrackElement={setTrackElement}
            hideDelete={!sessionQuery.role || ![Role.SUPER_ADMIN, Role.OS_C, Role.OS_R].includes(sessionQuery.role)}
            handleClose={handleClose}
            handleDelete={() => setShowConfirmDelete(true)}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
          />
        );
      case TrackElementCategory.TRAIN_STATION:
        return (
          <TrainStationForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.trainStation.title.edit")}
            description={t("schema.trainStation.description.edit")}
            setTrackElement={setTrackElement}
            hideDelete={!sessionQuery.role || ![Role.SUPER_ADMIN, Role.OS_C, Role.OS_R].includes(sessionQuery.role)}
            handleClose={handleClose}
            handleDelete={() => setShowConfirmDelete(true)}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
          />
        );
      case TrackElementCategory.CONSTRUCTION:
        return (
          <ConstructionForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.construction.title.edit")}
            description={t("schema.construction.description.edit")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleDelete={() => setShowConfirmDelete(true)}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
          />
        );
      case TrackElementCategory.DECLIVITY:
        return (
          <DeclivityForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.declivity.title.edit")}
            description={t("schema.declivity.description.edit")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleDelete={() => setShowConfirmDelete(true)}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
          />
        );
      case TrackElementCategory.WORKSITE:
        return (
          <WorksiteForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.worksite.title.edit")}
            description={t("schema.worksite.description.edit")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleDelete={() => setShowConfirmDelete(true)}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
          />
        );
      case TrackElementCategory.ACTIVITY:
        return (
          <ActivityForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.activity.title.edit")}
            description={t("schema.activity.description.edit")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleDelete={() => setShowConfirmDelete(true)}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
          />
        );
      case TrackElementCategory.WORK_ZONE:
        return <FullScreenSpecialZoneModal handleClose={handleClose}/>;
      case TrackElementCategory.ELEMENTARY_CONSTRUCTION_SITE:
        return (
          <ManageCEForm
            loading={loading}
            trackElement={trackElement}
            description={t("schema.CE.description.edit")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleDelete={() => setShowConfirmDelete(true)}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
          />
        );
      case TrackElementCategory.WORK_TRAIN:
        return null;
      case TrackElementCategory.RESTRICTION:
        return (
          <RestrictionsForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.restriction.title.edit")}
            description={t("schema.restriction.description.edit")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleDelete={() => setShowConfirmDelete(true)}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
          />
        );
      case TrackElementCategory.WORKSITE_MACHINE:
        if (projectQuery.isProjectTypeNew) return null;
        return (
          <ManageWorksiteMachineForm
            loading={loading}
            trackElement={trackElement}
            description={t("schema.worksiteMachine.description.edit")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleDelete={() => setShowConfirmDelete(true)}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {displayRightTrackElement()}
      {showConfirmDelete && (
        <DeleteConfirmation trackElement={trackElement} handleClose={() => setShowConfirmDelete(false)}
                            handleConfirm={handleDelete}/>
      )}
      {!!showConfirmCode && (
        <ConfirmCodeModal
          handleClose={() => setShowConfirmCode(false)}
          handleConfirm={(code) => sessionService.confirmIdentificationCode(code)}
          handleSuccess={() => handleValidate(showConfirmCode)}/>
      )}
      <Outlet/>
    </>
  );
};

export default ManageTrackElementForm;
