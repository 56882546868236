import React from 'react';
import {Grid, Stack} from "@mui/material";
import {CreateOrUpdateTrackElement, PK_INPUT_REGEXP} from "@store/project";
import TextFieldComponent from "@components/textField/TextField.component";
import {useTranslation} from "react-i18next";
import {FieldErrors} from "@utils/yup.utils";

interface TrainingZoneFormProps {
  readOnly?: boolean;
  setTrackElement: (trackElement: CreateOrUpdateTrackElement) => void;
  trackElement: CreateOrUpdateTrackElement;
  errors: FieldErrors;
  IncompatibilitiesAcceptation?: (() => JSX.Element) | null;
}

const TrainingZoneForm = (props: TrainingZoneFormProps) => {
  const {
    readOnly,
    trackElement,
    setTrackElement,
    errors,
    IncompatibilitiesAcceptation,
  } = props;

  const {t} = useTranslation();

  const updateTrackElement = (name: string) => (value: any) => setTrackElement({...trackElement, [name]: value});
  const updateTrackElementAttributes = (name: string) => (value: any) => setTrackElement({
    ...trackElement,
    attributes: {...trackElement.attributes, [name]: value}
  })

  return (
    <Stack flex={1} direction="column" justifyContent="center" alignItems="center" pt="20px" pb="40px"
           overflow="auto" className="scrollable" width="100%">
      <Grid container width="60%" spacing={2} mx="auto">
        <Grid item xs={6}>
          <TextFieldComponent
            readOnly={readOnly}
            label={t("schema.zone.ZFNb")}
            handleChange={updateTrackElementAttributes("name")}
            value={trackElement.attributes?.name || ""}
            error={errors["attributes.name"]}/>
        </Grid>
        <Grid item xs={6}>
          <TextFieldComponent
            label={t("schema.zone.activeRZF")}
            readOnly
            value={trackElement.attributes?.RZF || ""}/>
        </Grid>
        <Grid item xs={6}>
          <TextFieldComponent
            readOnly={readOnly}
            label={t("schema.zone.startPK")}
            handleChange={updateTrackElement("pkStart")}
            pattern={PK_INPUT_REGEXP}
            value={trackElement.pkStart || ""}
            error={errors.pkStart}/>
        </Grid>
        <Grid item xs={6}>
          <TextFieldComponent
            readOnly={readOnly}
            label={t("schema.zone.endPK")}
            handleChange={updateTrackElement("pkEnd")}
            pattern={PK_INPUT_REGEXP}
            value={trackElement.pkEnd || ""}
            error={errors.pkEnd}/>
        </Grid>
      </Grid>
      {IncompatibilitiesAcceptation && (
        <Stack pt="20px">
          <IncompatibilitiesAcceptation/>
        </Stack>
      )}
    </Stack>
  );
};

export default TrainingZoneForm;
