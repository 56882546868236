import React from 'react';
import {Grid, Stack} from "@mui/material";
import {CreateOrUpdateTrackElement, PK_INPUT_REGEXP, projectQuery} from "@store/project";
import TextFieldComponent from "@components/textField/TextField.component";
import {useTranslation} from "react-i18next";
import {FieldErrors} from "@utils/yup.utils";
import MultiSelectComponent from "@components/select/MultiSelect.component";

interface CEFormProps {
  setTrackElement: (trackElement: CreateOrUpdateTrackElement) => void;
  trackElement: CreateOrUpdateTrackElement;
  errors: FieldErrors;
}

const CEForm = (props: CEFormProps) => {
  const {
    trackElement,
    setTrackElement,
    errors,
  } = props;

  const {t} = useTranslation();

  const updateTrackElement = (name: string) => (value: any) => setTrackElement({...trackElement, [name]: value});
  const updateAttributes = (name: string) => (value: any) => setTrackElement({
    ...trackElement,
    attributes: {...trackElement.attributes, [name]: value}
  });

  return (
    <Stack flex={1} direction="column" justifyContent="center" alignItems="center" height="100%" pt="20px" pb="40px"
           overflow="auto" className="scrollable">
      <Grid container width="60%" spacing={2} mx={"auto"}>
        <Grid item xs={12}>
          <TextFieldComponent
            label={t(`schema.CE.ZTNb`)}
            handleChange={updateAttributes("name")}
            value={trackElement.attributes?.name || ""}
            error={errors["attributes.name"]}/>
        </Grid>
        <Grid item xs={12}>
          <MultiSelectComponent
            label={t("schema.CE.tracks")}
            handleChange={updateTrackElement("tracks")}
            items={projectQuery.projectTracks.map((t) => ({label: t.attributes?.name || "-", value: t.id}))}
            values={trackElement.tracks}
            error={errors.tracks}/>
        </Grid>
        <Grid item xs={6}>
          <TextFieldComponent
            label={t("schema.CE.fromPK")}
            handleChange={updateTrackElement("pkStart")}
            pattern={PK_INPUT_REGEXP}
            value={trackElement.pkStart || ""}
            error={errors.pkStart}/>
        </Grid>
        <Grid item xs={6}>
          <TextFieldComponent
            label={t("schema.CE.toPK")}
            handleChange={updateTrackElement("pkEnd")}
            pattern={PK_INPUT_REGEXP}
            value={trackElement.pkEnd || ""}
            error={errors.pkEnd}/>
        </Grid>
        <Grid item xs={12}>
          <TextFieldComponent
            label={t("schema.CE.company")}
            handleChange={updateAttributes("company")}
            value={trackElement.attributes?.company || ""}
            error={errors["attributes.company"]}/>
        </Grid>
        <Grid item xs={12}>
          <TextFieldComponent
            label={t(`schema.CE.RZTName`)}
            handleChange={updateAttributes("RZT")}
            value={trackElement.attributes?.RZT || ""}
            error={errors["attributes.RZT"]}/>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CEForm;
