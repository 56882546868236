import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Grid, Stack} from "@mui/material";

import {
  CreateOrUpdateTrackElement,
  Pk,
  PK_INPUT_REGEXP,
  projectQuery,
  projectService,
  TrackElementType
} from "@store/project";

import {trackElementValidation} from "@screens/auth/common/schema/forms/trackElements/forms.yup";
import {FieldErrors} from "@utils/yup.utils";

import ModalComponent from "@components/modals/Modal.component";
import TextFieldComponent from "@components/textField/TextField.component";
import SelectComponent from "@components/select/Select.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";

import useIncompatibilitiesAcceptation from "../../components/IncompatibilitiesAcceptation.component";
import { IncompatibilityError } from "@utils/incompatibilities/incompatibility.utils";

interface ActivityFormProps {
  description: string;
  handleClose: () => void;
  handleDelete?: () => void;
  handlePrevious?: () => void;
  handleValidate: (incompatibilities?: IncompatibilityError[]) => void;
  loading?: boolean;
  setTrackElement: (trackElement: CreateOrUpdateTrackElement) => void;
  title: string;
  trackElement: CreateOrUpdateTrackElement;
}

const ActivityForm = (props: ActivityFormProps) => {
  const {
    description,
    handleClose,
    handleDelete,
    handlePrevious,
    handleValidate,
    loading,
    setTrackElement,
    title,
    trackElement
  } = props;

  const {t} = useTranslation();

  const [errors, setErrors] = useState<FieldErrors>({});

  const {IncompatibilitiesAcceptation, disabledByIncompatibilities, incompatibilities} = useIncompatibilitiesAcceptation({
    trackElement,
    creation: !handleDelete
  });

  useEffect(() => {
    const ZT = !!trackElement.pkStart
      ? (projectQuery.isProjectTypeNew ? projectQuery.projectCE : projectQuery.projectZT).find(
        (z) =>
          !!z.pkEnd &&
          (!projectQuery.isProjectTypeNew || z.tracks.some((t) => !!trackElement.tracks?.includes(t))) &&
          Pk.isPkBetweenPks(Pk.fromString(trackElement.pkStart)!, z.pkStart, z.pkEnd)
      )
      : undefined;

    if (!!ZT) {
      projectService.getActivityNumber(ZT.id).subscribe({
        next: (activityNumber) => {
          setTrackElement({
            ...trackElement,
            type: TrackElementType.ACTIVITY,
            attributes: {
              ...(trackElement.attributes || {}),
              name: trackElement.attributes?.name || `Act ${activityNumber}`,
              ZT: ZT.id,
              dateTime: !trackElement.attributes?.dateTime ? new Date() : trackElement.attributes?.dateTime,
            },
          });
        },
      });
    } else {
      setTrackElement({
        ...trackElement,
        type: projectQuery.isProjectTypeNew ? TrackElementType.LIGHTWEIGHT : trackElement.type,
        attributes: {
          ...(trackElement.attributes || {}),
          dateTime: !trackElement.attributes?.dateTime ? new Date() : trackElement.attributes?.dateTime,
        },
      });
    }
  }, []);

  const updateTrackElement = (name: string) => (value: any) => setTrackElement({...trackElement, [name]: value});
  const updateAttributes = (name: string) => (value: any) =>
    setTrackElement({
      ...trackElement,
      attributes: {...trackElement.attributes, [name]: value},
    });

  const handleAdd = () => {
    const errors = trackElementValidation(trackElement);

    setErrors(errors);
    if (!Object.keys(errors).length) handleValidate(incompatibilities);
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      title={title}
      description={description}
      content={
        <Box width="450px">
          <Grid container columnSpacing={2.5} rowSpacing={1.5}>
            <Grid item xs={12}>
              <SelectComponent
                label={t("schema.activity.type")}
                placeholder={t("schema.activity.typePlaceholder")}
                handleChange={(type) => {
                  setTrackElement({...trackElement, type, attributes: {...trackElement.attributes, name: undefined}});
                  setErrors({});
                }}
                value={trackElement.type || ""}
                error={errors.type}
                items={projectQuery.isProjectTypeNew ? TrackElementType.activitiesNew : TrackElementType.activities}
              />
            </Grid>
            {trackElement.type === TrackElementType.ACTIVITY && (
              <>
                <Grid item xs={12}>
                  <TextFieldComponent
                    label={t("schema.activity.typeNew.name")}
                    handleChange={updateAttributes("name")}
                    value={trackElement.attributes?.name || ""}
                    error={errors["attributes.name"]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldComponent
                    label={t("schema.activity.natureOfActivity")}
                    handleChange={updateAttributes("natureOfActivity")}
                    value={trackElement.attributes?.natureOfActivity || ""}
                    error={errors["attributes.natureOfActivity"]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldComponent
                    label={t("schema.activity.typeNew.jobs")}
                    handleChange={updateAttributes("jobs")}
                    value={trackElement.attributes?.jobs || ""}
                  />
                </Grid>
              </>
            )}
            {trackElement.type === TrackElementType.LIGHTWEIGHT && (
              <Grid item xs={6}>
                <TextFieldComponent
                  label={t("schema.activity.name")}
                  handleChange={updateAttributes("name")}
                  value={trackElement.attributes?.name || ""}
                  error={errors["attributes.name"]}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <TimePickerComponent
                label={t("schema.activity.startDate")}
                handleChange={updateAttributes("dateTime")}
                value={trackElement.attributes?.dateTime}
                error={errors["attributes.dateTime"]}
              />
            </Grid>
            {trackElement.type === TrackElementType.ACTIVITY && (
              <Grid item xs={6}>
                <TimePickerComponent
                  label={t("schema.activity.endDate")}
                  handleChange={updateAttributes("endDateTime")}
                  value={trackElement.attributes?.endDateTime}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextFieldComponent
                label={t(`schema.activity.${projectQuery.isProjectTypeNew ? "typeNew." : ""}RA`)}
                handleChange={updateAttributes("RA")}
                value={trackElement.attributes?.RA || ""}
                error={errors["attributes.RA"]}
              />
            </Grid>
            {trackElement.type === TrackElementType.ACTIVITY && (
              <Grid item xs={12}>
                <SelectComponent
                  label={projectQuery.isProjectTypeNew ? t("schema.activity.typeNew.CE") : t("schema.activity.ZT")}
                  placeholder={projectQuery.isProjectTypeNew ? t("schema.activity.typeNew.CEPlaceholder") : t("schema.activity.ZTPlaceholder")}
                  handleChange={updateAttributes("ZT")}
                  value={trackElement.attributes?.ZT || ""}
                  items={(projectQuery.isProjectTypeNew ? projectQuery.projectCE : projectQuery.projectZT).map((t) => ({
                    label: t.attributes?.name || "-",
                    value: t.id,
                  }))}
                  error={errors["attributes.ZT"]}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <TextFieldComponent
                label={t("schema.activity.startPK")}
                handleChange={updateTrackElement("pkStart")}
                pattern={PK_INPUT_REGEXP}
                value={trackElement.pkStart || ""}
                error={errors.pkStart}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldComponent
                label={t("schema.activity.endPK")}
                handleChange={updateTrackElement("pkEnd")}
                pattern={PK_INPUT_REGEXP}
                value={trackElement.pkEnd || ""}
                error={errors.pkEnd}
              />
            </Grid>
            {trackElement.type === TrackElementType.ACTIVITY && (
              <Grid item xs={12}>
                <TextFieldComponent
                  multiline
                  rows={4}
                  label={t("schema.activity.typeNew.additionalInfo")}
                  handleChange={updateAttributes("additionalInfo")}
                  value={trackElement.attributes?.additionalInfo || ""}
                />
              </Grid>
            )}
          </Grid>
          {IncompatibilitiesAcceptation && (
            <Stack pt="20px">
              <IncompatibilitiesAcceptation/>
            </Stack>
          )}
        </Box>
      }
      actions={
        <>
          {!!handlePrevious && (
            <Button variant="outlined" color="primary" disabled={loading} onClick={handlePrevious}>
              {t("global.previous")}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={loading || disabledByIncompatibilities}
            onClick={handleAdd}>
            {t(handleDelete ? "global.modify" : "global.add")}
          </Button>
          {!!handleDelete && (
            <Button variant="contained" color="error" disabled={loading} onClick={handleDelete}>
              {t("schema.activity.close")}
            </Button>
          )}
        </>
      }
    />
  );
};

export default ActivityForm;
