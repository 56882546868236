import {alpha, Components} from "@mui/material";

import {Colors} from "@utils/theme/constants.utils";

const inputs: Components = {
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: alpha(Colors.text, 0.5),
        fontSize: 10,
        fontWeight: 400,
        marginBottom: 5,
        marginLeft: 14,
        textTransform: 'uppercase',
      },
    },
  },
  MuiTextField: {
    variants: [
      {
        props: {multiline: true},
        style: {
          "& .MuiFilledInput-root": {
            height: 'fit-content',
            padding: '9px 0 6px',
          },
        },
      },
      {
        props: {"aria-label": "search", variant: "filled"},
        style: {
          "& .MuiFilledInput-root": {
            borderRadius: 24,
            height: 48,
            paddingLeft: 22,
            paddingRight: 22,
          },
          "& .MuiInputAdornment-positionEnd": {
            "& .MuiIconButton-root": {
              padding: 0,
            },
          },
        },
      },
    ],
    defaultProps: {
      variant: 'filled',
      margin: 'none',
      hiddenLabel: true,
      size: "small",
    },
  },
  MuiFilledInput: {
    defaultProps: {
      disableUnderline: true,
    },
    variants: [
      {
        props: {readOnly: true},
        style: {
          "& .MuiFilledInput-input": {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        backgroundColor: Colors.inputBackground,
        borderRadius: 6,
        height: 38,
        "&[aria-label=clickable]": {
          cursor: 'pointer',
          "& .MuiFilledInput-input": {
            cursor: 'pointer',
          },
        },
      },
      colorSecondary: {
        backgroundColor: Colors.white,
      },
      input: {
        color: Colors.text,
        fontSize: 14,
        fontWeight: 400,
        padding: '0 14px',
        "&::placeholder": {
          color: alpha(Colors.text, 0.5),
          fontWeight: 400,
          opacity: 1,
        },
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      variant: 'filled',
      displayEmpty: true,
      disableUnderline: true,
    },
    styleOverrides: {
      select: {
        backgroundColor: "transparent",
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      listbox: {
        padding: 0,
        width: '100%',
      },
      option: {
        "&[aria-selected=true]": {
          backgroundColor: alpha(Colors.primary, 0.2),
          fontWeight: 700,
        },
      },
      inputRoot: {
        color: Colors.text,
        fontSize: 14,
        fontWeight: 700,
        paddingTop: 4.5,
      },
      loading: {
        color: Colors.text,
        fontSize: 14,
        fontWeight: 700,
        height: 38,
        paddingTop: 12,
        textAlign: 'center',
      },
      noOptions: {
        color: Colors.text,
        fontSize: 14,
        fontWeight: 500,
        height: 38,
        paddingTop: 12,
        textAlign: 'center',
      },
      clearIndicator: {
        padding: 0,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: 11,
        marginBottom: -7,
        marginLeft: 14,
        marginRight: 14,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      colorPrimary: {
        color: Colors.primary,
      },
    },
  },
};

export default inputs;
