import React, {useEffect, useState} from 'react'
import {Button, debounce, Stack} from "@mui/material";
import {WithStyles, withStyles} from "@mui/styles";
import {useSnackbar} from "notistack";
import {t} from "i18next";
import {useParams} from "react-router-dom";
import {finalize} from "rxjs";

import {PeopleCountingFilters, PeopleCountingModel, reportingService} from "@store/reporting";
import {filesService} from "@store/files";

import SearchBar from "@components/textField/SearchBar.component";
import Table from "@components/table/Table";
import {peopleCountingColumns} from "@screens/auth/common/reporting/tabs/peopleCounting/table.utils";

import dayjs from "@utils/dayjs.utils";

import style from "./PeopleCountingTab.style";
import PeopleCountingDrawer from "@screens/auth/common/reporting/tabs/peopleCounting/PeopleCounting.drawer";

interface PeopleCountingTabProps extends WithStyles<typeof style> {
}

const PeopleCountingTab = (props: PeopleCountingTabProps) => {
  const {classes} = props;

  const {enqueueSnackbar} = useSnackbar();

  const {projectId} = useParams();

  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<PeopleCountingFilters>({});

  const [activeDrawer, setActiveDrawer] = useState(false);

  const [peopleCounting, setPeopleCounting] = useState<PeopleCountingModel[]>([]);

  useEffect(() => {
    if (projectId) {
      setLoading(true);
      reportingService.getPeopleCounting(projectId, filters)
        .pipe(finalize(() => setLoading(false)))
        .subscribe(setPeopleCounting);
    }
  }, [projectId, filters]);

  const handleDownloadTrackElementCSV = () => {
    if (!projectId) return;
    reportingService.getPeopleCountingCSV(projectId, filters).subscribe({
      next: (res) => {
        filesService.downloadFile(res, `people-counting_${dayjs().format("DD-MM-YYYY")}.csv`);
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  return (
    <>
      <div className={classes.tableContainer}>
        <Stack direction="row" spacing={2} className={classes.tableButtons}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleDownloadTrackElementCSV}
            startIcon={<img src="/images/export_icon.svg"/>}>
            {t("reporting.peopleCounting.export")}
          </Button>
        </Stack>
        <Stack direction="row" spacing={2} mb="20px">
          <SearchBar
            placeholder={t("reporting.peopleCounting.search")}
            color="secondary"
            handleChange={debounce((value) => {
              setFilters((state) => ({...state, search: value}));
            }, 300)}/>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setActiveDrawer(true)}
            startIcon={<img src="/images/filter_icon.svg" alt=""/>}>
            {t("reporting.peopleCounting.filter")}
          </Button>
        </Stack>
        <Table
          loading={loading}
          columns={peopleCountingColumns()}
          disablePagination
          data={peopleCounting || []}
        />
      </div>
      {activeDrawer && (
        <PeopleCountingDrawer
          handleClose={() => setActiveDrawer(false)}
          handleReset={() => setFilters((state) => ({
            search: state.search,
            category: undefined,
            startDate: undefined,
            startHour: undefined,
            endDate: undefined,
            endHour: undefined,
          }))}
          handleSave={setFilters}
          filters={filters}/>
      )}
    </>
  );
}

export default withStyles(style)(PeopleCountingTab);
