import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement, TrackElementType, WorkTrainDirectionEnum} from "@store/project";
import {DispatchTypeEnum, ZFDispatchType} from "@store/dispatches/dispatch.model";
import {ID} from "@datorama/akita";
import {getZFBlockingElementsOnPks} from '@utils/incompatibilities/common/functions/getZFBlockingElementsOnPks';
import {getTrackNodes} from '@utils/incompatibilities/common/functions/getTrackNodes';
import _ from "lodash";

interface CheckZFWithOneFreeTrackParams {
  trackElementId?: ID;
  specificTrackElement?: TrackElement;
  pkStart: Pk;
  pkEnd: Pk;
  fromDispatch?: boolean;
  otherElements: TrackElement[];
  status?: ZFDispatchType;
  tracks: TrackElement[];
  ADVs: TrackElement[];
}

/* Should not be able to add specific elements on the tracks of a ZF if none of the ZF's tracks are a free zone */
export const checkZFWithOneFreeTrack = (params: CheckZFWithOneFreeTrackParams): IncompatibilityError[] => {
  const {
    trackElementId,
    pkStart,
    pkEnd,
    fromDispatch,
    specificTrackElement,
    otherElements,
    tracks,
    status,
    ADVs
  } = params;

  if (!fromDispatch || status === DispatchTypeEnum.MODIFICATION_TRAINING_ZONE) {
    const blockingElementsOnZF = [...(specificTrackElement ? [specificTrackElement] : []), ...otherElements]
      .filter((e) => getZFBlockingElementsOnPks(e, pkStart, pkEnd));

    if (!blockingElementsOnZF.length) return [];

    const workSitesOnZF = blockingElementsOnZF.filter((e) => e.type === TrackElementType.WORKSITE);
    if (!!workSitesOnZF.length) {
      return [{
        error: IncompatibilityErrorEnum.ZF_WITH_ONE_FREE_TRACK,
        trackElementId,
        concernedTrackElementIds: workSitesOnZF.map((ws) => ws.id),
      }];
    }

    const tracksOnPkStart = tracks.filter((t) => !!t.pkEnd && Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd));
    const ZFFreeTracksLeftToRight = tracksOnPkStart.map((t) => (
      getTrackNodes({
        currentTrack: t,
        ADVs,
        projectTracks: tracks,
        direction: WorkTrainDirectionEnum.LEFT_TO_RIGHT,
        fromPkStart: pkStart,
        toPkEnd: pkEnd,
        blockingElementsOnZF,
      })
    ));

    const tracksOnPkEnd = tracks.filter((t) => !!t.pkEnd && Pk.isPkBetweenPks(pkEnd, t.pkStart, t.pkEnd));
    const ZFFreeTracksRightToLeft = tracksOnPkEnd.map((t) => (
      getTrackNodes({
        currentTrack: t,
        ADVs,
        projectTracks: tracks,
        direction: WorkTrainDirectionEnum.RIGHT_TO_LEFT,
        fromPkStart: pkEnd,
        toPkEnd: pkStart,
        blockingElementsOnZF,
      })
    ));

    if (!ZFFreeTracksLeftToRight.some((n) => n.hasOneFreeTrack)
      && !ZFFreeTracksRightToLeft.some((n) => n.hasOneFreeTrack)) {
      return [
        {
          error: IncompatibilityErrorEnum.ZF_WITH_ONE_FREE_TRACK,
          trackElementId,
          concernedTrackElementIds: _.uniq(
            [...ZFFreeTracksLeftToRight, ...ZFFreeTracksRightToLeft]
              .flatMap((node) => node.blockingElements.map((e) => e.id))
          ),
        },
      ];
    }
  }

  return [];
};

export default checkZFWithOneFreeTrack;
