import React from "react";

import ModalComponent from "@components/modals/Modal.component";

interface ContinueModalProps {
	actions?: JSX.Element | JSX.Element[];
	handleClose: () => void;
	title: string;
}

const ContinueModal = (props: ContinueModalProps) => {
	const {actions, handleClose, title} = props;

	return (
		<ModalComponent
			handleClose={handleClose}
			title={title}
			actions={actions}/>
	);
}

export default ContinueModal;
