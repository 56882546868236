import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {ID} from "@datorama/akita";

interface CheckOutZT20mPNParams {
  trackElementId?: ID;
  pkStart: Pk;
  ZTs: TrackElement[];
}

/* Should not be able to set the PN less than 20 meters of a ZT if not encompassed  */
const checkOutZT20mPN = (params: CheckOutZT20mPNParams): IncompatibilityError[] => {
  const {pkStart, trackElementId, ZTs} = params;

  const ZTLessThan20Meters = ZTs.filter(
    (t) =>
      (Pk.isAfterPk(pkStart, Pk.addMeters(t.pkStart, -20)) && Pk.isBeforePk(pkStart, t.pkStart)) ||
      (!!t.pkEnd && Pk.isAfterPk(pkStart, t.pkEnd) && Pk.isBeforePk(pkStart, Pk.addMeters(t.pkEnd, 20)))
  );

  if (ZTLessThan20Meters.length) {
    return ZTLessThan20Meters.map((ZT) => ({
      error: IncompatibilityErrorEnum.OUT_ZT_20M_PN,
      trackElementId,
      concernedTrackElementIds: [ZT.id],
    }));
  }

  return [];
};

export default checkOutZT20mPN;
