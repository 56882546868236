import React from "react";
import {bindHover, usePopupState} from "material-ui-popup-state/hooks";

import {TOOLTIP_HEIGHT, TRACK_ELEMENT_HEIGHT, TrackElement, TrackElementType} from "@store/project";
import TooltipDetails from "@screens/auth/common/schema/elements/TooltipDetails";
import {Colors} from "@utils/theme/constants.utils";
import {alpha} from "@mui/material";

interface TrainStationElementProps {
  handleClick?: () => void;
  showTooltip?: boolean;
  tooltipDirection?: 'start' | 'end';
  trackElement: TrackElement;
  x: number;
}

const TrainStationElement = (props: TrainStationElementProps) => {
  const {handleClick, showTooltip, tooltipDirection, trackElement, x} = props;

  const tooltipState = usePopupState({variant: 'popover', popupId: `tooltip-${trackElement.id}`});

  const height = TOOLTIP_HEIGHT * 0.75;

  const textCooX = x + (tooltipDirection === "start" ? -1 : 1) * (height / 2 + 8);

  return (
    <g>
      <g
        style={{cursor: handleClick ? 'pointer' : undefined}}
        onClick={handleClick}>
        {trackElement.attributes?.emergencyExit && (
          <circle
            cx={x} cy={TOOLTIP_HEIGHT / 2} r={height / 2 + 2}
            stroke={Colors.schema.elements.worksiteMachine}
            strokeWidth={3}
            fill={alpha(TrackElementType.getColor(trackElement), 0)}
            {...bindHover(tooltipState)}
          />
        )}
        <circle
          cx={x} cy={TOOLTIP_HEIGHT / 2} r={height / 2}
          stroke={trackElement.attributes?.emergencyExit ? "white" : undefined}
          strokeWidth={trackElement.attributes?.emergencyExit ? 2 : undefined}
          fill={TrackElementType.getColor(trackElement)}
          {...bindHover(tooltipState)}/>
        <image
          xlinkHref={TrackElementType.getIcon(trackElement.type)}
          x={x - 12}
          y={TOOLTIP_HEIGHT / 2 - 12}
          {...bindHover(tooltipState)}/>
      </g>
      {(showTooltip || tooltipState.isOpen) && (
        <TooltipDetails
          trackElement={trackElement}
          position={tooltipDirection}
          x={textCooX} y={TOOLTIP_HEIGHT / 2 + TRACK_ELEMENT_HEIGHT / 2}/>
      )}
    </g>
  );
}

export default TrainStationElement;