import {IncompatibilityError, IncompatibilityErrorEnum,} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement, TrackElementType} from "@store/project";
import {ID} from "@datorama/akita";
import {DispatchTypeEnum, TTxDispatchType} from "@store/dispatches/dispatch.model";

interface CheckNoElementOnSidelanesOnSplittingParams {
  fromDispatch?: boolean;
  dispatchType?: TTxDispatchType;
  trackElementId?: ID;
  trackIds: ID[];
  pkStart: Pk;
  length: number;
  tracks: TrackElement[];
  otherElements: TrackElement[];
}

/* TTx splitting can't have blocking element on its lane or sidelanes bellow 50m */
const checkNoElementOnSidelanesOnSplitting = (
  params: CheckNoElementOnSidelanesOnSplittingParams
): IncompatibilityError[] => {
  const {
    fromDispatch,
    dispatchType,
    trackElementId,
    trackIds,
    pkStart,
    length,
    tracks,
    otherElements,
  } = params;

  if (!fromDispatch || !dispatchType || dispatchType !== DispatchTypeEnum.SPLITTING_SPLITTING)
    return [];

  const newPkStart = Pk.addMeters(pkStart, -50);
  const newPkEnd = Pk.addMeters(pkStart, length + 50);
  const splittingTrack = tracks.find((t) => t.id === trackIds[0]);
  if (!splittingTrack) return [];

  const sideLanes = tracks
    .filter(
      (t) =>
        t.attributes.yIndex === splittingTrack.attributes.yIndex - 1 ||
        t.attributes.yIndex === splittingTrack.attributes.yIndex + 1
    )
    .concat(splittingTrack);

  const elementsOnSideLanesBellow50m = otherElements.filter((t) => {
    const withoutTracks = [TrackElementType.WORKSITE, TrackElementType.OUTSIDE_ZPF].includes(t.type);

    if (t.pkEnd) {
      return (
        (Pk.isPkBetweenPks(t.pkStart, newPkStart, newPkEnd) ||
          Pk.isPkBetweenPks(t.pkEnd, newPkStart, newPkEnd) ||
          Pk.isPkBetweenPks(newPkStart, t.pkStart, t.pkEnd) ||
          Pk.isPkBetweenPks(newPkEnd, t.pkStart, t.pkEnd))
      ) && (withoutTracks || sideLanes.some((sl) => t.tracks.includes(sl.id)));
    }

    if (t.type === TrackElementType.WORK_TRAIN) {
      const tpkEnd = t.attributes?.TTXLength ? Pk.addMeters(t.pkStart, +t.attributes.TTXLength) : undefined;
      return (
        !!tpkEnd &&
        (Pk.isPkBetweenPks(t.pkStart, newPkStart, newPkEnd) ||
          Pk.isPkBetweenPks(tpkEnd, newPkStart, newPkEnd) ||
          Pk.isPkBetweenPks(newPkStart, t.pkStart, tpkEnd) ||
          Pk.isPkBetweenPks(newPkEnd, t.pkStart, tpkEnd))
        && (withoutTracks || sideLanes.some((sl) => t.tracks.includes(sl.id)))
      );
    }

    return Pk.isPkBetweenPks(t.pkStart, newPkStart, newPkEnd) && (withoutTracks || sideLanes.some((sl) => t.tracks.includes(sl.id)));
  });

  if (!!elementsOnSideLanesBellow50m?.length) {
    return [
      {
        error: IncompatibilityErrorEnum.NO_ELEMENT_ON_SIDELANES_ON_SPLITTING,
        trackElementId,
        concernedTrackElementIds: elementsOnSideLanesBellow50m.map((t) => t.id),
      },
    ];
  }

  return [];
};

export default checkNoElementOnSidelanesOnSplitting;
