import React, {useEffect, useState} from "react";
import {Box, Button, Card, DialogActions, Grid, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {Outlet, useNavigate, useParams} from "react-router-dom";

import {ProjectLight, ProjectType} from "@store/projects";
import {sessionQuery} from "@store/session";
import {Role} from "@store/users";
import {Pk, projectQuery, projectService} from "@store/project";

import TextFieldComponent from "@components/textField/TextField.component";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import UpdateProjectModal from "@screens/auth/common/projectDetails/projectInfo/updateProject/UpdateProject.modal";

const InformationTab = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const {projectId} = useParams();

  const [openModifyProject, setOpenModifyProject] = useState(false);

  const [project, setProject] = useState<ProjectLight>();

  useEffect(() => {
    projectService.getProjectInfoById(projectId || "").subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });

    const _project$ = projectQuery.projectInfo$.subscribe(setProject);

    return () => {
      _project$.unsubscribe();
    };
  }, [enqueueSnackbar, projectId]);

  if (!project) return null;

  return (
    <>
      <Box flex={1} display="flex" flexDirection="column" pt="20px" pb="20px">
        <Box flex={1} component={Card} p="10px 10px 0">
          <Box height="100%" overflow="auto" className="scrollable" p="30px 30px 30px">
            <Stack direction="row" spacing={8}>
              <Box flex={0.5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      label={t("projectDetails.projectInfo.projectInfo.type")}
                      value={project.projectType || ""}
                      readOnly/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      label={t("projectDetails.projectInfo.projectInfo.name")}
                      value={project.name || ""}
                      readOnly/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      label={t("projectDetails.projectInfo.projectInfo.status")}
                      value={t(`global.${project.isPending ? "opened" : "closed"}`)}
                      readOnly/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      label={t("projectDetails.projectInfo.projectInfo.lineId")}
                      value={project.lineId || ""}
                      readOnly/>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldComponent
                      label={t("projectDetails.projectInfo.projectInfo.from")}
                      value={project.from || ""}
                      readOnly/>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldComponent
                      label={t("projectDetails.projectInfo.projectInfo.to")}
                      value={project.to || ""}
                      readOnly/>
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerComponent
                      label={t("projectDetails.projectInfo.projectInfo.startDate")}
                      value={project.startingDate}
                      readOnly/>
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerComponent
                      label={t("projectDetails.projectInfo.projectInfo.endDate")}
                      value={project.endingDate}
                      readOnly/>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldComponent
                      label={t("projectDetails.projectInfo.projectInfo.startPK")}
                      value={Pk.toString(project.startingKilometerPoint) || ""}
                      readOnly/>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldComponent
                      label={t("projectDetails.projectInfo.projectInfo.endPK")}
                      value={Pk.toString(project.endingKilometerPoint) || ""}
                      readOnly/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      label={t("projectDetails.projectInfo.projectInfo.navigationScale")}
                      value={project.navigationScale?.toString() || ""}
                      readOnly/>
                  </Grid>
                </Grid>
              </Box>
              <Box flex={0.5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      label={t("global.roles.OSC")}
                      value={project.os_c ? `${project.os_c.lastname.toUpperCase()} ${project.os_c.firstname}` : ""}
                      readOnly/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      label={t(`global.roles.${project.projectType === ProjectType.NEW ? "OSRNew" : "OSR"}`)}
                      value={project.os_r ? `${project.os_r.lastname.toUpperCase()} ${project.os_r.firstname}` : ""}
                      readOnly/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      label={t(`global.roles.${project.projectType === ProjectType.NEW ? "RELFNew" : "RELF"}`)}
                      value={project.relfs && project.relfs.length > 0 ? `${project.relfs[0].lastname.toUpperCase()} ${project.relfs[0].firstname}` : ""}
                      readOnly/>
                  </Grid>
                  {project.relfs && project.relfs.length > 1 && project.relfs.slice(1, project.relfs.length).map((relf) => (
                    <Grid item xs={12} key={relf.id}>
                      <TextFieldComponent
                        label={t(`global.roles.${project.projectType === ProjectType.NEW ? "RELFNew" : "RELF"}`)}
                        value={`${relf.lastname.toUpperCase()} ${relf.firstname}`}
                        readOnly/>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Stack>
          </Box>
        </Box>
        {sessionQuery.hasSufficientRoleOrAbove(Role.OS_R) && !projectQuery.isClosed && (
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => setOpenModifyProject(true)}>
              {t("global.modify")}
            </Button>
            {sessionQuery.hasSufficientRoleOrAbove(Role.OS_C) && (
              <Button variant="half-opacity" color="error" onClick={() => navigate("close")}>
                {t("global.close")}
              </Button>
            )}
          </DialogActions>
        )}
        {sessionQuery.hasAnyOfRoles(Role.SUPER_ADMIN) && projectQuery.isClosed && (
          <DialogActions>
            <Button variant="contained" color="error" onClick={() => navigate("delete")}>
              {t("global.delete")}
            </Button>
          </DialogActions>
        )}
      </Box>
      <Outlet/>
      {openModifyProject && (
        <UpdateProjectModal
          handleClose={() => setOpenModifyProject(false)}
          project={project}/>
      )}
    </>
  );
}

export default InformationTab;
