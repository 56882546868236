import {alpha, Checkbox, FormControlLabel, Stack, styled, Typography} from "@mui/material";
import {CreateDispatch} from "@store/dispatches/dispatch.model";
import {CreateOrUpdateTrackElement, TrackElement} from "@store/project";
import {getElementIncompatibilities} from "@utils/incompatibilities";
import {isLinkedToDeletion, isOnlyForCreation} from "@utils/incompatibilities/incompatibility.utils";
import {Colors} from "@utils/theme/constants.utils";
import {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

const StyledIncompatibilityItem = styled(Typography)({
  color: Colors.secondaryRed,
  backgroundColor: alpha(Colors.secondaryRed, 0.06),
  padding: "14px",
  width: "100%",
  textAlign: "center",
  borderRadius: "6px",
});

interface UseIncompatibilitiesAcceptationProps {
  creation?: boolean;
  deletion?: boolean;
  dispatch?: CreateDispatch;
  trackElement?: TrackElement | CreateOrUpdateTrackElement;
}

const useIncompatibilitiesAcceptation = (props: UseIncompatibilitiesAcceptationProps) => {
  const {creation, deletion, dispatch, trackElement} = props;

  const {t} = useTranslation();

  const [ignoreIncompatibilities, setIgnoreIncompatibilities] = useState(false);

  const incompatibilities = useMemo(
    () =>
      getElementIncompatibilities(trackElement, dispatch).filter((inc) => {
        if (deletion && !isLinkedToDeletion(inc.error)) return false;

        if (!creation && isOnlyForCreation(inc.error)) return false;

        return true;
      }),
    [trackElement, dispatch, deletion, creation]
  );

  const handleCheck = useCallback(
    (checked: boolean) => {
      setIgnoreIncompatibilities(checked);
    },
    [setIgnoreIncompatibilities]
  );

  const Elem = () => (
    <Stack alignItems={"center"} spacing={"20px"}>
      <Stack spacing={"10px"} width={"100%"}>
        {incompatibilities.map((incompatibility, index) => (
          <StyledIncompatibilityItem fontSize={"12px"} key={index}>
            {t(`incompatibilities.${incompatibility.error}`)}
          </StyledIncompatibilityItem>
        ))}
      </Stack>

      <FormControlLabel
        control={(
          <Checkbox
            checked={ignoreIncompatibilities}
            onChange={(_, checked) => handleCheck(checked)}
            size="small"/>
        )}
        label={
          <Typography color="primary" fontSize={12} pt="3px !important">
            {t("incompatibilities.labels.ignore")}
          </Typography>
        }
      />
    </Stack>
  );

  return {
    IncompatibilitiesAcceptation: !!incompatibilities.length ? Elem : null,
    disabledByIncompatibilities: !!incompatibilities.length && !ignoreIncompatibilities,
    incompatibilities
  };
};

export default useIncompatibilitiesAcceptation;
