import {IncompatibilityError, IncompatibilityErrorEnum,} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {ID} from "@datorama/akita";
import _ from "lodash";
import {DispatchTypeEnum, SplittingPart, TTxDispatchType} from "@store/dispatches/dispatch.model";

interface checkNoDifferentZTOrZFOnPostSplittingParams {
  fromDispatch?: boolean;
  dispatchType?: TTxDispatchType;
  trackElementId?: ID;
  ZFs: TrackElement[];
  ZTs: TrackElement[];
  splittingParts: SplittingPart[];
}

/* Should not be able to split TTx parts that are inside different ZFs or ZTs */
const checkNoDifferentZTOrZFOnPostSplitting = (
  params: checkNoDifferentZTOrZFOnPostSplittingParams
): IncompatibilityError[] => {
  const {fromDispatch, dispatchType, trackElementId, ZFs, ZTs, splittingParts} = params;

  if (!fromDispatch || !dispatchType || dispatchType !== DispatchTypeEnum.SPLITTING_POST_SPLITTING)
    return [];


  const ZTsAndZFsContainingEveryTTx = [...ZTs, ...ZFs].filter((t) =>
    splittingParts.every((sp) => {
      if (!sp.pkStart) return false;
      const pkEnd = Pk.addMeters(sp.pkStart, sp.TTXLength ?? 0);
      return (
        !!t.pkEnd &&
        (Pk.isPkBetweenPks(sp.pkStart, t.pkStart, t.pkEnd) ||
          Pk.isPkBetweenPks(pkEnd, t.pkStart, t.pkEnd) ||
          Pk.isPkBetweenPks(t.pkStart, sp.pkStart, pkEnd) ||
          Pk.isPkBetweenPks(t.pkEnd, sp.pkStart, pkEnd)) &&
        !_.isEqual(sp.pkStart, t.pkEnd) &&
        !_.isEqual(pkEnd, t.pkStart)
      );
    })
  );

  if (!ZTsAndZFsContainingEveryTTx.length) {
    return [
      {
        error: IncompatibilityErrorEnum.NO_DIFFERENT_ZT_OR_ZF_ON_POST_SPLITTING,
        trackElementId,
        concernedTrackElementIds: [],
      },
    ];
  }

  return [];
};

export default checkNoDifferentZTOrZFOnPostSplitting;
