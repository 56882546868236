import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, debounce, Stack, Typography} from "@mui/material";
import {Outlet, useNavigate} from "react-router-dom";

import TabsComponent from "@components/tabs/Tabs.component";
import SearchBar from "@components/textField/SearchBar.component";
import {projectQuery} from "@store/project";

import DispatchDrawer, {FiltersType} from "./Dispatch.drawer";

import {RoutesAuth, RoutesDispatches, useRouteMatch} from "@utils/routes.utils";
import {dispatchFiltersService} from "@store/dispatches/filters.service";
import {sessionQuery} from '@store/session';
import {Role} from '@store/users';

const DispatchScreen = () => {

  const {t} = useTranslation();
  const navigate = useNavigate();

  const currentPath = useRouteMatch(RoutesDispatches.routes);
  const [activeDrawer, setActiveDrawer] = useState<"filter" | undefined>(undefined);

  useEffect(() => {
    return () => {
      dispatchFiltersService.resetStore();
    };
  }, []);

  return (
    <>
      <Stack width="100%" height="100%" pt="30px" pb="30px" spacing={3}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" height="48px">
          <Stack height="48px" justifyContent="center">
            <Typography fontSize={26} fontWeight="900">
              {projectQuery.projectInfo.name || "-"}
            </Typography>
            <Stack direction="row" alignItems="center" spacing="3px">
              <Typography textAlign="center" fontSize={14} fontWeight="700">
                {t("schema.title", {lineId: projectQuery.projectInfo.lineId})} -
              </Typography>
              <Typography textAlign="center" fontSize={14} fontWeight="400">
                {projectQuery.projectInfo.from || "-"} / {projectQuery.projectInfo.to || "-"}
              </Typography>
            </Stack>
          </Stack>
          {sessionQuery.hasSufficientRoleOrAbove(Role.RELF) && !projectQuery.isClosed && (
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                startIcon={<img src="/images/pen_icon.svg"/>}
                color="primary"
                onClick={() => {
                  navigate(`${currentPath}/${RoutesAuth.HISTORY_COMMON}`)
                }}>
                {t("dispatches.add")}
              </Button>
              {!projectQuery.isProjectTypeNew && (
                <Button
                  variant="contained"
                  startIcon={<img src="/images/add_icon_white.svg"/>}
                  color="primary"
                  onClick={() => {
                    navigate(`${currentPath}/${RoutesAuth.HISTORY_DISPATCH}`)
                  }}>
                  {t(`dispatches.createDispatch`)}
                </Button>
              )}
              <Button
                variant="contained"
                startIcon={<img src="/images/pen_icon.svg"/>}
                color="primary"
                onClick={() => {
                  navigate(`${currentPath}/${RoutesAuth.PEOPLE_COUNTING}`)
                }}>
                {t(`dispatches.peopleCounting`)}
              </Button>
            </Stack>
          )}
        </Stack>
        <Stack direction="row" spacing={2}>
          <SearchBar
            placeholder={t(`dispatches.${projectQuery.isProjectTypeNew ? "searchNew" : "search"}`)}
            color="secondary"
            handleChange={debounce((value) => {
              dispatchFiltersService.setFilters({search: value})
            }, 300)}/>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setActiveDrawer("filter")}
            startIcon={<img src="/images/filter_icon.svg" alt=""/>}>
            {t("dispatches.filter")}
          </Button>
        </Stack>
        <TabsComponent
          value={currentPath ? RoutesDispatches.routes.indexOf(currentPath as RoutesAuth) : 0}
          tabs={projectQuery.isProjectTypeNew
            ? [t("dispatches.todaysDispatchesNew"), t("dispatches.historyNew"), t("dispatches.relfRegisterNew")]
            : [t("dispatches.todaysDispatches"), t("dispatches.history"), t("dispatches.relfRegister")]
          }
          handleChange={(value) => {
            if ((currentPath !== RoutesAuth.TRACK_ELEMENTS && value === 2) || (currentPath === RoutesAuth.TRACK_ELEMENTS && value !== 2)) {
              dispatchFiltersService.resetFilters();
            }
            navigate(RoutesDispatches.routes[value]);
          }}/>
        <Outlet/>
      </Stack>
      {activeDrawer && (
        <DispatchDrawer
          handleClose={() => setActiveDrawer(undefined)}
          type={currentPath === RoutesAuth.TRACK_ELEMENTS ? FiltersType.RELF : FiltersType.DISPATCH}/>
      )}
    </>
  )
}

export default DispatchScreen;
