import React, {FormEvent, useEffect, useState} from "react";
import {Button, Card, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {WithStyles, withStyles} from "@mui/styles";

import {CustomAuthentication, sessionQuery, sessionService} from "@store/session";

import {FieldErrors} from "@utils/yup.utils";
import {customSignInValidation} from "@screens/unauth/login/login.yup";

import TextFieldComponent from "@components/textField/TextField.component";
import ForgotPasswordModal from "@screens/unauth/forgotPassword/ForgotPassword.modal";

import styles from "./Login.style";
import EnvironmentRibbonComponent from '@components/EnvironmentRibbon.component';
import NewPasswordScreen from '@screens/newPassword/NewPassword.modal';

interface LoginProps extends WithStyles<typeof styles> {
}

const Login = (props: LoginProps) => {
  const {classes} = props;

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const [data, setData] = useState<CustomAuthentication>({});
  const [errors, setErrors] = useState<FieldErrors>({});

  useEffect(() => {
    const _loading$ = sessionQuery.selectLoading().subscribe(setLoading);

    return () => {
      _loading$.unsubscribe();
    };
  }, []);

  const updateData = (name: string) => (value: any) => setData({...data, [name]: value});

  const handleCustomAuthentication = (evt: FormEvent) => {
    evt.preventDefault();

    const errors = customSignInValidation(data);

    setErrors(errors);
    if (!!Object.keys(errors).length) return;

    sessionService.login(data).subscribe({
      error: (err) => {
        enqueueSnackbar(err.text, err.options);
        if (err.error === 'PASSWORD_CHANGE_REQUIRED') {
          setShowChangePassword(true);
        }
      },
    });
  }

  return (
    <div className={classes.container}>
      <EnvironmentRibbonComponent/>
      <img className={classes.logo} alt="logo sages rail" src="/images/logo_white.png"/>
      <Card className={classes.card}>
        <Typography fontSize={22} fontWeight="900">{t("auth.title")}</Typography>
        <Typography fontSize={14} fontWeight="400">{t("auth.description")}</Typography>
        <form onSubmit={handleCustomAuthentication} noValidate>
          <Grid container spacing={2} className={classes.custom}>
            <Grid item xs={12}>
              <TextFieldComponent
                label={t("auth.email")}
                placeholder={t("auth.emailPlaceholder")}
                handleChange={updateData("email")}
                value={data.email || ""}
                error={errors.email}/>
            </Grid>
            <Grid item xs={12}>
              <TextFieldComponent
                label={t("auth.password")}
                placeholder={t("auth.passwordPlaceholder")}
                handleChange={updateData("password")}
                value={data.password || ""}
                error={errors.password}
                type="password"/>
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Button color="primary" aria-label="shadow" variant="contained" disabled={loading} type="submit">
                {t("auth.login")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
      {showResetPassword && <ForgotPasswordModal handleClose={() => setShowResetPassword(false)}/>}
      {showChangePassword && <NewPasswordScreen/>}
    </div>
  );
}

export default withStyles(styles)(Login);
