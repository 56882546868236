import React, {useMemo} from "react";
import {Box, Button, Grid, IconButton, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";

import {Role, User} from "@store/users";
import {filesService, UploadedFile} from "@store/files";
import {sessionQuery} from "@store/session";

import dayjs from "@utils/dayjs.utils";

import ModalComponent from "@components/modals/Modal.component";
import TextFieldComponent from "@components/textField/TextField.component";
import DatePickerComponent from "@components/datePicker/DatePicker.component";

interface UserDetailsFormProps {
  handleClose: () => void;
  handleSetToEditMode: () => void;
  user: User;
}

const UserDetailsForm = (props: UserDetailsFormProps) => {
  const {handleClose, handleSetToEditMode, user} = props;

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const openFile = (file: UploadedFile) => {
    if (file) {
      filesService.getFile(file.fileId).subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  }

  const canUpdateUser = useMemo(() => {
    if (sessionQuery.hasAnyOfRoles(Role.SUPER_ADMIN)) return true;

    if (user.id === sessionQuery.id) return false;

    return !sessionQuery.hasRoleOrBelow(user.role);
  }, [user]);

  return (
    <ModalComponent
      handleClose={handleClose}
      title={`${user.lastname.toUpperCase()} ${user.firstname}`}
      description={t("users.details.description")}
      content={(
        <Stack width="450px" mt="15px" spacing={4}>
          <Box>
            <Grid container columnSpacing={2.5} rowSpacing={1.5}>
              <Grid item xs={12}>
                <TextFieldComponent
                  label={t("users.fields.lastname")}
                  value={user.lastname || ""}
                  readOnly/>
              </Grid>
              <Grid item xs={12}>
                <TextFieldComponent
                  label={t("users.fields.firstname")}
                  value={user.firstname || ""}
                  readOnly/>
              </Grid>
              <Grid item xs={12}>
                <TextFieldComponent
                  label={t("users.fields.email")}
                  value={user.email || ""}
                  readOnly/>
              </Grid>
              {sessionQuery.hasAnyOfRoles(Role.SUPER_ADMIN) && !user.isDisabled && (
                <>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      label={t("users.fields.password")}
                      value="********"
                      readOnly/>
                  </Grid>
                  <Grid container item xs={12} justifyContent="center">
                    <Button color="primary" variant="text" size="small" onClick={() => navigate("reset-password")}>
                      {t("users.fields.resetPassword")}
                    </Button>
                  </Grid>
                  {user.role !== Role.CLIENT && (
                    <>
                      <Grid item xs={12}>
                        <TextFieldComponent
                          label={t("users.fields.operationalIdentificationCode")}
                          value="****"
                          readOnly/>
                      </Grid>
                      <Grid container item xs={12} justifyContent="center">
                        <Button
                          color="primary"
                          variant="text"
                          size="small"
                          onClick={() => navigate("reset-identification-code")}>
                          {t("users.fields.generateNewOperationalIdentificationCode")}
                        </Button>
                      </Grid>
                    </>
                  )}
                </>
              )}
              <Grid item xs={12}>
                <TextFieldComponent
                  label={t("users.fields.function")}
                  value={user.role || ""}
                  readOnly/>
              </Grid>
              {user.role !== Role.CLIENT && (
                <>
                  <Grid item xs={6}>
                    <TextFieldComponent
                      label={t("users.fields.trainingCertificate")}
                      placeholder={!user.formationFile ? t("users.fields.noFile") : undefined}
                      value={user.formationFile?.fileName || ""}
                      endAdornment={(
                        <IconButton
                          size="small"
                          sx={{marginRight: '-7px'}}
                          onClick={() => openFile(user.formationFile)}>
                          <img alt="download file" src="/images/download_dark_icon.svg"/>
                        </IconButton>
                      )}
                      readOnly/>
                    {user.formationFile && dayjs().isAfter(user.formationExpireDate) && (
                      <Stack direction="row" alignItems="center" mt="3px">
                        <img alt="" src="/images/information_red_icon.svg"/>
                        <Typography mt="4px" ml="4px" fontSize={12} fontWeight="400" color="error">
                          {t("users.fields.expiredFile")}
                        </Typography>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerComponent
                      label={t("users.fields.fileExpirationDate")}
                      value={user.formationExpireDate}
                      disablePast
                      readOnly/>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldComponent
                      label={t("users.fields.qualificationTitle")}
                      placeholder={!user.habilitationFile ? t("users.fields.noFile") : undefined}
                      value={user.habilitationFile?.fileName || ""}
                      endAdornment={(
                        <IconButton
                          size="small"
                          sx={{marginRight: '-7px'}}
                          onClick={() => openFile(user.habilitationFile)}>
                          <img alt="download file" src="/images/download_dark_icon.svg"/>
                        </IconButton>
                      )}
                      readOnly/>
                    {user.habilitationExpireDate && dayjs().isAfter(user.habilitationExpireDate) && (
                      <Stack direction="row" alignItems="center" mt="3px">
                        <img alt="" src="/images/information_red_icon.svg"/>
                        <Typography mt="4px" ml="4px" fontSize={12} fontWeight="400" color="error">
                          {t("users.fields.expiredFile")}
                        </Typography>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerComponent
                      label={t("users.fields.fileExpirationDate")}
                      value={user.habilitationExpireDate}
                      disablePast
                      readOnly/>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldComponent
                      label={t("users.fields.medicalVisit")}
                      placeholder={!user.medicalVisitFile ? t("users.fields.noFile") : undefined}
                      value={user.medicalVisitFile?.fileName || ""}
                      endAdornment={(
                        <IconButton
                          size="small"
                          sx={{marginRight: '-7px'}}
                          onClick={() => openFile(user.medicalVisitFile)}>
                          <img alt="download file" src="/images/download_dark_icon.svg"/>
                        </IconButton>
                      )}
                      readOnly/>
                    {user.medicalVisitExpireDate && dayjs().isAfter(user.medicalVisitExpireDate) && (
                      <Stack direction="row" alignItems="center" mt="3px">
                        <img alt="" src="/images/information_red_icon.svg"/>
                        <Typography mt="4px" ml="4px" fontSize={12} fontWeight="400" color="error">
                          {t("users.fields.expiredFile")}
                        </Typography>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerComponent
                      label={t("users.fields.fileExpirationDate")}
                      value={user.medicalVisitExpireDate}
                      disablePast
                      readOnly/>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Stack>
      )}
      actions={canUpdateUser ? (
        <>
          {!user.isDisabled && (
            <>
              <Button variant="contained" color="primary" onClick={handleSetToEditMode}>
                {t("global.modify")}
              </Button>
              {sessionQuery.hasAnyOfRoles(Role.SUPER_ADMIN) && (
                <Button variant="half-opacity" color="error" onClick={() => navigate("disable")}>
                  {t("global.disable")}
                </Button>
              )}
            </>
          )}
          {user.isDisabled && sessionQuery.hasAnyOfRoles(Role.SUPER_ADMIN) && (
            <Button variant="half-opacity" color="primary" onClick={() => navigate("reactivate")}>
              {t("global.reactivate")}
            </Button>
          )}
          <Button variant="contained" color="error" onClick={() => navigate("delete")}>
            {t("global.delete")}
          </Button>
        </>
      ) : undefined}/>
  );
}

export default UserDetailsForm;
