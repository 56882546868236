import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {finalize} from "rxjs";

import {RoutesAuth} from "@utils/routes.utils";

import ContinueModal from "@components/modals/Continue.modal";
import {usersService} from "@store/users";

const DeleteUserModal = () => {
  const {t} = useTranslation();
  const {userId} = useParams();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!userId) navigate(RoutesAuth.USERS);
  }, [navigate, userId]);

  if (!userId) return null;

  const handleClose = () => navigate("..");

  const handleDelete = () => {
    setLoading(true);
    usersService.deleteUser(userId).pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("users.success.deleted"), {variant: "success"});
          navigate("../..");
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  }

  return (
    <ContinueModal
      handleClose={handleClose}
      title={t("users.delete.title")}
      actions={(
        <>
          <Button variant="outlined" color="primary" disabled={loading} onClick={handleClose}>
            {t("global.cancel")}
          </Button>
          <Button variant="contained" color="primary" disabled={loading} onClick={handleDelete}>
            {t("global.delete")}
          </Button>
        </>
      )}/>
  );
}

export default DeleteUserModal;
