import React from 'react'
import {Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

import ModalComponent from "@components/modals/Modal.component";

interface CountingPeopleDetailsModalProps {
  countingPeople: { [nature: string]: number };
  handleClose: () => void;
}

const CountingPeopleDetailsModal = (props: CountingPeopleDetailsModalProps) => {
  const {countingPeople, handleClose} = props;

  const {t} = useTranslation();

  if (!Object.keys(countingPeople).length) return null;

  const total = Object.keys(countingPeople).reduce((res, key) => res + (countingPeople[key] ?? 0), 0);

  return (
    <ModalComponent
      handleClose={handleClose}
      maxWidth={false}
      fullWidth={false}
      content={(
        <Stack pt="15px" width="350px" px="50px" spacing="15px">
          <Stack spacing="5px">
            {Object.keys(countingPeople).filter((key) => !!countingPeople[key]).map((key) => (
              <Stack direction="row" alignItems="center" key={key} justifyContent="space-between" spacing="30px">
                <Typography fontWeight="900" lineHeight="1.25" color="primary" fontSize="14px">{key}</Typography>
                <Typography fontWeight="500" fontSize="14px">{countingPeople[key] ?? 0}</Typography>
              </Stack>
            ))}
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing="30px">
            <Typography fontWeight="900" fontSize="18px" lineHeight="1.25" color="primary">
              {t("reporting.data3Details.total")}
            </Typography>
            <Typography fontWeight="500" fontSize="18px">{total}</Typography>
          </Stack>
        </Stack>
      )}
      title={t("reporting.data3Details.title")}/>
  );
}

export default CountingPeopleDetailsModal;
