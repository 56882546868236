import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import {Language, translations} from '@locales/index';

import dayjs from "@utils/dayjs.utils";

i18n
	.use(initReactI18next)
	.init({
		resources: translations,
		lng: Language.FR,
		fallbackLng: Language.FR,
		interpolation: {
			escapeValue: false,
			format: (value, format, locale) => {
				if (value instanceof Date && locale) {
					return dayjs(value).locale(locale).format(format);
				}

				if (typeof value === "string" && format === "uppercase") {
					return value.toUpperCase();
				}

				if (typeof value === "string" && format === "lowercase") {
					return value.toLowerCase();
				}

				return value;
			},
		},
	})
	.then(() => {
	});

export default i18n;
