import {Query} from "@datorama/akita";

import {Session} from "./session.model";
import {sessionStore, SessionStore} from "./session.store";
import {Role} from '@store/users';

export class SessionQuery extends Query<Session> {

  currentUser$ = this.select("user");

  constructor(protected store: SessionStore) {
    super(store);
  }

  get id() {
    return this.getValue().user?.id;
  }

  get token() {
    return this.getValue().accessToken;
  }

  get role() {
    return this.getValue().user?.role;
  }

  hasSufficientRoleOrAbove(role: Role) {
    const rolesOrder = [Role.SUPER_ADMIN, Role.OS_C, Role.OS_R, Role.RELF, Role.CLIENT];
    const userRole = this.role;

    return !!userRole && rolesOrder.indexOf(userRole) <= rolesOrder.indexOf(role);
  }

  hasRoleOrBelow(role: Role) {
    const rolesOrder = [Role.SUPER_ADMIN, Role.OS_C, Role.OS_R, Role.RELF, Role.CLIENT];
    const userRole = this.role;

    return !!userRole && rolesOrder.indexOf(userRole) >= rolesOrder.indexOf(role);
  }

  hasAnyOfRoles(roles: Role | Role[]) {
    const userRole = this.role;

    if (Array.isArray(roles)) {
      return !!userRole && roles.includes(userRole);
    } else {
      return !!userRole && userRole === roles;
    }
  }
}

export const sessionQuery = new SessionQuery(sessionStore);
