import * as React from 'react';
import {useEffect} from 'react';
import {FormControlLabel, Grid, InputLabel, Radio, RadioGroup, Stack, Typography} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import {Pk, PK_INPUT_REGEXP, projectQuery, TrackElement} from "@store/project";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {t} from "i18next";
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {sessionQuery} from "@store/session";
import NumberTextFieldComponent from "@components/textField/NumberTextField.component";
import {ordersService} from "@store/dispatches/orders.service";
import {useParams} from "react-router-dom";

export const OpeningAuthorizationWorkAreaForm = (props: DispatchFormGlobalProps) => {

  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const {projectId} = useParams();

  const updateDispatchContent = (name: string) => (value: any) => setDispatch({
    ...dispatch,
    dispatch: {...dispatch.dispatch, [name]: value}
  });

  useEffect(() => {
    if (trackElement) {
      const tracks = projectQuery.projectTracks.filter((t) => {
        if (trackElement.pkStart && t.pkEnd && Pk.isPkBetweenPks(trackElement.pkStart, t.pkStart, t.pkEnd)) return true;
        return !!(trackElement.pkEnd && t.pkEnd && Pk.isPkBetweenPks(trackElement.pkEnd, t.pkStart, t.pkEnd));
      }) || [] as TrackElement[];

      ordersService.getDispatchNumber(projectId!).subscribe({
        next: (dispatchNbr) => {
          setDispatch({
            type: DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_OPEN_A_WORK_AREA,
            dispatch: {
              from: trackElement.attributes.RZT,
              to: `${sessionQuery.getValue().user?.firstname || ''} ${sessionQuery.getValue().user?.lastname || ''}`,
              date: new Date(),
              hour: new Date(),
              tracks: tracks.map((t) => t.id),
              pkStart: Pk.toString(trackElement.pkStart),
              pkEnd: Pk.toString(trackElement.pkEnd),
              nbr: trackElement.attributes.name,
              fromDate: new Date(),
              natureOfWork: trackElement.attributes.natureOfWork,
              isPassable: true,
              deliveredDispatchNbr: dispatchNbr,
            }
          });
        },
      });
    }
  }, [])

  return (
    <div>
      <Grid container columnSpacing={2.5} rowSpacing={1.5}>

        <Grid item xs={4}>
          <TextFieldComponent
            label={"M./Mme RZT"}
            readOnly={!editable}
            value={dispatch?.dispatch?.from || ''}
            handleChange={updateDispatchContent("from")}
            error={errors["from"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent label={"M./Mme RELF"}
                              readOnly={!editable}
                              value={dispatch?.dispatch?.to || ''}
                              handleChange={updateDispatchContent("to")}
                              error={errors["to"]}/>
        </Grid>

        <Grid item xs={4}>
          <TextFieldComponent label={"J'autorise l'ouverture de la ZT N°"} placeholder={"N°"}
                              readOnly
                              value={dispatch?.dispatch?.nbr || ''}
                              error={errors["nbr"]}/>
        </Grid>

        <Grid item xs={12}>
          <TextFieldComponent label={"Nature des travaux"}
                              readOnly={!editable}
                              value={dispatch?.dispatch?.natureOfWork || ''}
                              handleChange={updateDispatchContent("natureOfWork")}
                              error={errors["natureOfWork"]}/>
        </Grid>

        <Grid item xs={3}>
          <TextFieldComponent
            label={"Du Km"}
            readOnly={!editable}
            handleChange={updateDispatchContent("pkStart")}
            pattern={PK_INPUT_REGEXP}
            value={dispatch?.dispatch?.pkStart || ""}
            error={errors["pkStart"]}/>
        </Grid>
        <Grid item xs={3}>
          <TextFieldComponent
            label={"Au Km"}
            readOnly={!editable}
            handleChange={updateDispatchContent("pkEnd")}
            pattern={PK_INPUT_REGEXP}
            value={dispatch?.dispatch?.pkEnd || ""}
            error={errors["pkEnd"]}/>
        </Grid>
        <Grid item xs={6}>
          <TextFieldComponent
            label={'Sur voie(s)'}
            readOnly
            multiline
            value={projectQuery.projectTracks
              .filter((t) => (dispatch.dispatch?.tracks || []).includes(t.id))
              .map((t) => t.attributes.name)
              .join(", ")
            }/>
        </Grid>

        <Grid item xs={4}>
          <DatePickerComponent
            label={"Du"}
            readOnly={!editable}
            value={dispatch?.dispatch?.fromDate}
            handleChange={updateDispatchContent("fromDate")}
            error={errors["fromDate"]}
          />
        </Grid>
        <Grid item xs={4}>
          <DatePickerComponent
            label={"au"}
            readOnly={!editable}
            value={dispatch?.dispatch?.toDate}
            handleChange={updateDispatchContent("toDate")}
            error={errors["toDate"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            label={"Jusqu'à"}
            readOnly={!editable}
            value={editable ? dispatch?.dispatch?.toHour : dispatch?.dispatch?.toDate}
            handleChange={updateDispatchContent("toHour")}
            error={errors["toHour"]}
          />
        </Grid>


        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" mb="-7.5px">
            <InputLabel sx={{ml: '5px', pt: '6px', width: '310px'}}>
              {"La ZT est franchissable par un TTx / Engin de Chantier"}
            </InputLabel>
            <RadioGroup
              row
              value={false}
              onChange={(evt: any) => {
                updateDispatchContent("isPassable")(evt.target.value === 'true')
              }}
            >
              <FormControlLabel
                control={<Radio size="small"/>}
                value={'true'}
                disabled={!editable}
                checked={dispatch?.dispatch?.isPassable}
                label={(
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    {t("global.yes")}
                  </Typography>
                )}/>
              <FormControlLabel
                control={<Radio size="small"/>}
                value={'false'}
                disabled={!editable}
                checked={!dispatch?.dispatch?.isPassable}
                label={(
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    {t("global.no")}
                  </Typography>
                )}/>
            </RadioGroup>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <TextFieldComponent
            label={"Informations complémentaires"}
            multiline
            readOnly={!editable}
            rows={3}
            handleChange={updateDispatchContent("additionalInformation")}
            value={dispatch?.dispatch?.additionalInformation || ""}
            error={errors["additionalInformation"]}/>
        </Grid>

        <Grid item xs={4}>
          <DatePickerComponent
            label={"Le"}
            readOnly={!editable}
            value={dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("date")}
            error={errors["date"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            label={"A"}
            readOnly={!editable}
            value={editable ? dispatch?.dispatch?.hour : dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("hour")}
            error={errors["hour"]}
          />
        </Grid>
        <Grid item xs={4}>
        </Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={"N° de dépêche délivré"}
            readOnly={!editable}
            handleChange={updateDispatchContent("deliveredDispatchNbr")}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            value={dispatch?.dispatch?.deliveredDispatchNbr}
            error={errors["deliveredDispatchNbr"]}/>
        </Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={"N° de dépêche reçu"}
            readOnly={!editable}
            handleChange={updateDispatchContent("receivedDispatchNbr")}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            value={dispatch?.dispatch?.receivedDispatchNbr}
            error={errors["receivedDispatchNbr"]}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default OpeningAuthorizationWorkAreaForm;
