import {alpha, Components} from "@mui/material";

import {Colors} from "@utils/theme/constants.utils";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    "schema-step": true;
    "half-opacity": true;
  }
}

const buttons: Components = {
  MuiButton: {
    styleOverrides: {
      root: (props) => ({
        ...(props.ownerState.variant === 'half-opacity' ? {
          alignItems: 'center',
          borderRadius: 24,
          boxShadow: `0 4px 10px 0 ${alpha(Colors.primary, 0.2)}`,
          display: 'flex',
          fontSize: 14,
          fontWeight: 700,
          height: 48,
          padding: '0 30px',
          textTransform: 'none',
          minWidth: '180px !important',
          whiteSpace: 'nowrap',
          ...(props.ownerState.color === 'primary' ? {
            backgroundColor: alpha(Colors.primary, 0.2),
            color: Colors.primary,
            "&:hover": {backgroundColor: alpha(Colors.primary, 0.4)}
          } : {}),
          ...(props.ownerState.color === 'error' ? {
            backgroundColor: alpha(Colors.secondaryRed, 0.2),
            color: Colors.secondaryRed,
            "&:hover": {backgroundColor: alpha(Colors.secondaryRed, 0.4)}
          } : {}),
        } : {}),
      }),
      contained: {
        alignItems: 'center',
        borderRadius: 24,
        boxShadow: `0 4px 10px 0 ${alpha(Colors.primary, 0.2)}`,
        color: 'white',
        display: 'flex',
        fontSize: 14,
        fontWeight: 700,
        height: 48,
        padding: '0 30px',
        textTransform: 'none',
        minWidth: '180px !important',
        whiteSpace: 'nowrap',
        "& > .MuiButton-startIcon": {
          marginBottom: 2,
          marginRight: 10,
        },
      },
      containedSizeLarge: {
        minWidth: '280px !important',
      },
      containedSizeSmall: {
        height: 36,
        borderRadius: 18,
        padding: 15,
        minWidth: "0 !important",
      },
      outlined: {
        alignItems: 'center',
        borderRadius: 24,
        display: 'flex',
        fontSize: 14,
        fontWeight: 700,
        height: 48,
        padding: '0 30px',
        textTransform: 'none',
        minWidth: '180px !important',
        whiteSpace: 'nowrap',
      },
      textPrimary: {
        fontSize: 14,
        fontWeight: 700,
        padding: 0,
      },
      text: {
        fontSize: 12,
        fontWeight: 400,
        textTransform: 'none',
      },
      textSizeSmall: {
        fontSize: 10,
        fontWeight: 500,
        textDecoration: 'underline',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      colorPrimary: {
        backgroundColor: Colors.primary,
        boxShadow: `0 10px 17px 0 ${alpha(Colors.primary, 0.16)}`,
        "&:hover": {
          backgroundColor: 'rgb(9, 30, 80)',
        },
      },
      colorSecondary: {
        backgroundColor: Colors.secondaryRed,
        boxShadow: `0 10px 17px 0 ${alpha(Colors.secondaryRed, 0.16)}`,
        "&:hover": {
          backgroundColor: 'rgb(184, 44, 44)',
        },
      },
      sizeLarge: {
        height: 80,
        width: 80,
      },
    },
  },
};

export default buttons;
