import {catchError, finalize, from, map, Observable, tap} from "rxjs";
import {ID} from "@datorama/akita";
import {AxiosError, AxiosResponse} from "axios";

import {usersStore, UsersStore} from "@store/users/users.store";
import {CreateUser, UpdateUser, User} from "@store/users/users.model";

import APIAxios, {APIRoutes} from "@api/axios.api";
import SnackError from "@utils/error.utils";
import {sessionQuery, sessionService} from '@store/session';

export class UsersService {
  constructor(private store: UsersStore) {
  }

  getUsers = (): Observable<User[]> => {
    this.store.setLoading(true);

    return from(APIAxios(APIRoutes.GETUsers()))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError(err.response?.data?.message, "error");
        }),
        map((response: AxiosResponse<User[]>) => {
          return response.data;
        }),
        tap((users) => this.store.upsertMany(users)),
        finalize(() => this.store.setLoading(false)),
      );
  }

  createUser = (data: CreateUser): Observable<User> => {
    return from(APIAxios({
      ...APIRoutes.POSTCreateUser(),
      data: CreateUser.toFormData(data),
    }))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError(err.response?.data?.message, "error");
        }),
        map((response: AxiosResponse<User>) => {
          return response.data;
        }),
        tap((user) => this.store.add(user)),
      );
  }

  getUserById = (userId: ID): Observable<User> => {
    return from(APIAxios(APIRoutes.GETUserById(userId)))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError(err.response?.data?.message, "error");
        }),
        map((response: AxiosResponse<User>) => {
          return response.data;
        }),
        tap((user) => this.store.upsert(userId, user)),
      );
  }

  updateUser = (data: UpdateUser): Observable<User> => {
    return from(APIAxios({
      ...APIRoutes.PUTUpdateUser(data.id),
      data: UpdateUser.toFormData(data),
    }))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError(err.response?.data?.message, "error");
        }),
        map((response: AxiosResponse<User>) => {
          return response.data;
        }),
        tap((user) => this.store.upsert(data.id, user)),
      );
  }

  deleteUser = (userId: ID): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.DELETEUser(userId)))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError(err.response?.data?.message, "error");
        }),
        tap(() => {
          if (userId === sessionQuery.id) sessionService.logout();
          this.store.remove(userId);
        }),
      );
  }

  disableUser = (userId: ID): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.PUTDisableUser(userId)))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError(err.response?.data?.message, "error");
        }),
        tap(() => {
          if (userId === sessionQuery.id) sessionService.logout();
          this.store.update(userId, {isDisabled: true});
        }),
      );
  }

  reactivateUser = (userId: ID): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.PUTReactivateUser(userId)))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError(err.response?.data?.message, "error");
        }),
        tap(() => {
          this.store.update(userId, {isDisabled: false});
        }),
      );
  }

  resetUserIdentificationCode = (userId: ID): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.PUTResetUserIdentificationCode(userId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
    );
  }

  resetUserPassword = (userId: string, password: string): Observable<AxiosResponse> => {
    return from(APIAxios({...APIRoutes.POSTResetPassword(userId), data: {password}})).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
    );
  }
}

export const usersService = new UsersService(usersStore);
