import {QueryEntity} from '@datorama/akita';

import {ProjectsState, ProjectsStore, projectsStore} from "@store/projects/projects.store";
import {ProjectLight} from "@store/projects/projects.model";
import {map} from "rxjs";

export class ProjectsQuery extends QueryEntity<ProjectsState, ProjectLight> {

  projects$ = this.selectAll().pipe(
    map((projects) =>
      projects.sort((p1, p2) => p1.name.localeCompare(p2.name))
    ),
  );

  openedProjects$ = this.selectAll().pipe(
    map((projects) => projects.filter((p) => p.isPending)),
  );

  constructor(protected store: ProjectsStore) {
    super(store);
  }
}

export const projectsQuery = new ProjectsQuery(projectsStore);
