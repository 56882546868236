import React, {useEffect, useState} from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";

import {User, usersService} from "@store/users";

import UserDetailsForm from "@screens/auth/OS/users/userDetails/UserDetails.form";
import UpdateUserForm from "@screens/auth/OS/users/userDetails/UpdateUser.form";

const UserDetailsModal = () => {
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const {userId} = useParams();

  const [isEditMode, setIsEditMode] = useState(false);

  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (userId) {
      usersService.getUserById(userId).subscribe({
        next: setUser,
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  }, [enqueueSnackbar, userId]);

  if (!user) return null;

  const handleClose = () => navigate("..");

  return (
    <>
      {isEditMode ? (
        <UpdateUserForm
          handleClose={handleClose}
          handleCancelEditMode={() => setIsEditMode(false)}
          handleSave={(updatedUser) => {
            setUser(updatedUser);
            setIsEditMode(false);
          }}
          user={user}/>
      ) : (
        <UserDetailsForm
          handleClose={handleClose}
          handleSetToEditMode={() => setIsEditMode(true)}
          user={user}/>
      )}
      <Outlet
        context={{
          setUserDisabled: (isDisabled: boolean) => {
            setUser((state) => ({...state!, isDisabled}));
          },
        }}/>
    </>
  );
};

export default UserDetailsModal;
