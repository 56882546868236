import {Components} from "@mui/material";

import {Colors} from "@utils/theme/constants.utils";

const tabs: Components = {
  MuiTabs: {
    styleOverrides: {
      root: (props) => ({
        backgroundColor: 'transparent',
        minHeight: 0,
        ...(props.ownerState["aria-label"] === "steps" ? {
          backgroundColor: 'transparent',
          "& .MuiTab-root": {
            borderRadius: 24,
            color: Colors.primary,
            fontSize: 12,
            fontWeight: 400,
            height: 30,
            padding: '4px 15px 0',
            "&.Mui-selected": {
              color: Colors.white,
              fontSize: 12,
              fontWeight: 700,
            },
          },
        } : {}),
      }),
      indicator: {
        height: 0,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        overflow: 'visible',
        "& .MuiDivider-root": {
          backgroundColor: "#D8D9D7",
          height: 16,
          width: 1,
        },
      },
      textColorPrimary: {
        backgroundColor: 'transparent',
        borderRadius: 15,
        color: Colors.black,
        fontSize: 13,
        fontWeight: 400,
        minHeight: 0,
        padding: "7px 10px 5px",
        position: 'relative',
        textTransform: 'none',
        "& .MuiTab-iconWrapper": {
          position: 'absolute',
          right: 0,
        },
        "&:last-of-type .MuiDivider-root": {
          display: 'none',
        },
        "&.Mui-selected": {
          backgroundColor: Colors.primary,
          color: Colors.white,
          fontSize: 14,
          fontWeight: 700,
          "& .MuiDivider-root": {
            display: 'none',
          },
        },
      },
    },
  },
};

export default tabs;
