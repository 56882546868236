import React from "react";
import {Dialog, DialogContent, IconButton} from "@mui/material";

import SpecialZoneSchemaComponent from "@screens/auth/common/schema/SpecialZoneSchema.component";

interface FullScreenSpecialZoneModalProps {
  handleClose: () => void;
}

const FullScreenSpecialZoneModal = (props: FullScreenSpecialZoneModalProps) => {
  const {handleClose} = props;

  return (
    <Dialog open={true} fullScreen scroll="paper" onClose={handleClose} aria-label="schema-fullscreen">
      <IconButton aria-label="close" onClick={handleClose}>
        <img alt="" src="/images/cross_icon.svg"/>
      </IconButton>
      <DialogContent>
        <SpecialZoneSchemaComponent/>
      </DialogContent>
    </Dialog>
  );
}

export default FullScreenSpecialZoneModal;