import {Pk, TrackElement} from '@store/project';
import {IncompatibilityError} from '@utils/incompatibilities/incompatibility.utils';
import {ID} from '@datorama/akita';
import checkNoElementOutsideTrack from '@utils/incompatibilities/track/NO_ELEMENT_OUTSIDE_TRACK/noElementOutsideTrack';

interface GetS9A3TrackIncParams {
  trackElementId?: ID;
  pkStart?: Pk;
  pkEnd?: Pk;
  otherTrackElements?: TrackElement[];
}

export const getS9A3TrackInc = (params: GetS9A3TrackIncParams): IncompatibilityError[] => {
  const {
    trackElementId,
    pkStart,
    pkEnd,
    otherTrackElements = [],
  } = params;

  const incompatibilities: IncompatibilityError[] = [];

  if (!pkStart || !pkEnd) return incompatibilities;

  incompatibilities.push(
    ...checkNoElementOutsideTrack({
      trackElementId,
      pkStart,
      pkEnd,
      otherTrackElements,
    }),
  );

  return incompatibilities;
}