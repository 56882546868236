import React from "react";
import { Grid } from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import { useTranslation } from "react-i18next";
import NumberTextFieldComponent from "@components/textField/NumberTextField.component";
import { DispatchHistory } from "@store/dispatches/dispatch.model";

interface FreeDispatchFormProps {
  dispatch: DispatchHistory;
}
export const FreeDispatchForm = (props: FreeDispatchFormProps) => {
  const { dispatch } = props;
  const { t } = useTranslation();
  return (
    <Grid container direction="column" columnSpacing={2.5} rowSpacing={2.5}>
      <Grid item>
        <TextFieldComponent
          label={t("dispatches.createHistoryCommon.fields.name")}
          readOnly
          value={dispatch.content?.name}
        />
      </Grid>
      <Grid item>
        <TextFieldComponent
          label={t("dispatches.createHistoryCommon.fields.description")}
          multiline
          rows={4}
          value={dispatch.content?.description}
        />
      </Grid>
      <Grid item container xs={12} columnSpacing={2.5}>
        <Grid item xs={6}>
          <NumberTextFieldComponent
            value={dispatch.content.dispatchNumberDelivered}
            readOnly
            label={t("dispatches.createHistoryCommon.fields.numberDelivered")}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberTextFieldComponent
            value={dispatch.content.dispatchNumberReceived}
            required
            label={t("dispatches.createHistoryCommon.fields.numberReceived")}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
