import React from "react";
import {Divider, Tab, Tabs} from "@mui/material";

interface TabsProps {
	handleChange?: (value: number) => void;
	tabs: string[];
	value?: number;
}

const TabsComponent = (props: TabsProps) => {
	const {handleChange, tabs, value} = props;

	return (
		<Tabs
			variant="fullWidth"
			onChange={(evt, value) => handleChange?.(value)}
			value={value || 0}>
			{tabs.map((tab, index) => (
				<Tab
					key={`Tab ${index} - ${tab}`}
					label={tab}
					iconPosition="end"
					disabled={!handleChange}
					icon={<Divider/>}
					value={index}/>
			))}
		</Tabs>
	);
}

export default TabsComponent;
