import React, {useEffect, useState} from 'react'
import {Box, Card, CardActionArea, Divider, Grid, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";

import {ProjectStats, reportingService} from "@store/reporting";
import {Pk, projectQuery, TrackElement, TrackElementCategory, TrackElementType} from "@store/project";

import CountingPeopleDetailsModal from "@screens/auth/common/reporting/components/CountingPeopleDetails.modal";
import {Colors} from "@utils/theme/constants.utils";
import AccidentsAtWorkModal from "@screens/auth/common/reporting/components/accidentsAtWork/AccidentsAtWork.modal";
import ProjectOrdersChart from '@screens/auth/common/reporting/components/charts/ProjectOrders.chart';
import ProjectDispatchesChart from '@screens/auth/common/reporting/components/charts/ProjectDispatches.chart';
import ProjectDispatchesPerDayChart
  from '@screens/auth/common/reporting/components/charts/ProjectDispatchesPerDay.chart';

interface StatCardProps {
  extra?: string;
  label: string;
  onClick?: () => void;
  value: string | number;
}

interface ChartCardProps {
  fullHeightChild?: boolean;
  height?: string;
  label: string;
  children: React.ReactNode;
}

const StatCard = (props: StatCardProps) => {
  const {extra, label, onClick, value} = props;

  return (
    <Card component={onClick ? CardActionArea : Card} onClick={onClick}>
      <Stack justifyContent="space-between" p="30px 30px 15px" height="200px">
        <Typography fontSize="14px" fontWeight="500" flex={1}>{label}</Typography>
        <Typography fontSize="38px" fontWeight="800" color="primary" textAlign="right">
          {value}{!!extra && <Box component="span" fontSize="14px" fontWeight="500" ml="3px">{extra}</Box>}
        </Typography>
      </Stack>
    </Card>
  );
};

const ChartCard = (props: ChartCardProps) => {
  const {fullHeightChild, height, label, children} = props;
  return (
    <Card>
      <Stack justifyContent="space-between" p="30px 30px 15px" height={height || "350px"} position="relative">
        <Typography fontSize="14px" fontWeight="500" flex={!fullHeightChild ? 1 : undefined}>{label}</Typography>
        {children}
      </Stack>
    </Card>
  );
}

interface MultipleStatsCardProps {
  onClick?: () => void;
  stats: {
    label: string;
    value: string | number;
  }[];
}

const MultipleStatsCard = (props: MultipleStatsCardProps) => {
  const {onClick, stats} = props;

  return (
    <Card component={onClick ? CardActionArea : Card} onClick={onClick}>
      <Stack direction="row" p="30px 30px 15px" height="200px">
        {stats.map((s, index) => (
          <React.Fragment key={index}>
            <Stack
              flex={1 / stats.length}
              justifyContent="space-between"
              pl={index !== 0 ? "30px" : undefined}
              pr={index < stats.length - 1 ? "30px" : undefined}>
              <Typography fontSize="14px" fontWeight="500" flex={1}>{s.label}</Typography>
              <Typography fontSize="38px" fontWeight="800" color="primary" textAlign="right">
                {s.value}
              </Typography>
            </Stack>
            {index < stats.length - 1 && (
              <Divider
                orientation="vertical"
                variant="fullWidth"
                sx={{backgroundColor: Colors.primary, borderRightWidth: '4px'}}/>
            )}
          </React.Fragment>
        ))}
      </Stack>
    </Card>
  );
};

const ProjectNumbersTab = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [stats, setStats] = useState<ProjectStats>();
  const [trackElements, setTrackElements] = useState<TrackElement[]>([]);

  const [showCountingPeople, setShowCountingPeople] = useState(false);
  const [showAccidentsAtWork, setShowAccidentsAtWork] = useState(false);

  useEffect(() => {
    reportingService.getProjectStats(projectQuery.projectId).subscribe({
      next: setStats,
      error: (err) => enqueueSnackbar(err.text),
    });

    const _trackElements$ = projectQuery.projectTrackElements$.subscribe(setTrackElements);

    return () => {
      _trackElements$.unsubscribe();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("RELOAD_ACCIDENT_AT_WORK", () => {
      reportingService.getProjectStats(projectQuery.projectId).subscribe({
        next: setStats,
        error: (err) => enqueueSnackbar(err.text),
      });
    });

    return () => {
      window.removeEventListener("RELOAD_ACCIDENT_AT_WORK", () => {
      });
    };
  }, []);

  return (
    <>
      {!!stats && (
        <Box pt="10px" overflow="auto">
          <Grid container spacing="30px">
            <Grid item xs={4}>
              <StatCard
                label={t("reporting.data1")}
                value={trackElements
                  .filter((t) => {
                    const relatedTrack = projectQuery.projectTracks.find((tr) => tr.id === t.tracks[0]);

                    if (!relatedTrack?.pkEnd) return false;

                    return [TrackElementType.WORKSITE_MACHINE, TrackElementType.WORK_TRAIN].includes(t.type)
                      && Pk.isPkBetweenPks(t.pkStart, relatedTrack.pkStart, relatedTrack.pkEnd)
                      && Pk.isPkBetweenPks(t.pkStart, projectQuery.projectInfo.startingKilometerPoint, projectQuery.projectInfo.endingKilometerPoint)
                      && (!t.pkEnd || (
                        Pk.isPkBetweenPks(t.pkEnd, relatedTrack.pkStart, relatedTrack.pkEnd)
                        && Pk.isPkBetweenPks(t.pkEnd, projectQuery.projectInfo.startingKilometerPoint, projectQuery.projectInfo.endingKilometerPoint)
                      ));
                  })
                  .length
                }/>
            </Grid>
            <Grid item xs={4}>
              <StatCard
                label={t("reporting.data2")}
                value={trackElements
                  .filter((t) => {
                    const relatedTrack = projectQuery.projectTracks.find((tr) => tr.id === t.tracks[0]);

                    if (!relatedTrack?.pkEnd) return false;

                    return t.category === TrackElementCategory.RESTRICTION
                      && Pk.isPkBetweenPks(t.pkStart, relatedTrack.pkStart, relatedTrack.pkEnd)
                      && Pk.isPkBetweenPks(t.pkStart, projectQuery.projectInfo.startingKilometerPoint, projectQuery.projectInfo.endingKilometerPoint)
                      && (!t.pkEnd || (
                        Pk.isPkBetweenPks(t.pkEnd, relatedTrack.pkStart, relatedTrack.pkEnd)
                        && Pk.isPkBetweenPks(t.pkEnd, projectQuery.projectInfo.startingKilometerPoint, projectQuery.projectInfo.endingKilometerPoint)
                      ));
                  })
                  .length
                }/>
            </Grid>
            <Grid item xs={4}>
              <StatCard
                label={t("reporting.data3")}
                onClick={!!Object.keys(stats.countingPeople).length ? () => setShowCountingPeople(true) : undefined}
                value={Object.keys(stats.countingPeople).reduce((res, key) => res + (stats?.countingPeople?.[key] ?? 0), 0)}/>
            </Grid>
            <Grid item xs={4}>
              <StatCard
                label={t(`reporting.data4${projectQuery.isProjectTypeNew ? 'New' : ''}`)}
                value={trackElements
                  .filter((t) => {
                    const relatedTracks = t.tracks.map((trId) => projectQuery.projectTracks.find((tr) => tr.id === trId));

                    if (!t.pkEnd || ![TrackElementCategory.WORK_ZONE, TrackElementCategory.ELEMENTARY_CONSTRUCTION_SITE].includes(t.category)) {
                      return false;
                    }

                    if (t.category === TrackElementCategory.WORK_ZONE) {
                      return Pk.isPkBetweenPks(t.pkStart, projectQuery.projectInfo.startingKilometerPoint, projectQuery.projectInfo.endingKilometerPoint)
                        && Pk.isPkBetweenPks(t.pkEnd, projectQuery.projectInfo.startingKilometerPoint, projectQuery.projectInfo.endingKilometerPoint);
                    }

                    let CETracks = relatedTracks.filter((tr) => !!tr && !!tr.pkEnd);
                    if (!CETracks.length) return false;

                    return Pk.isPkBetweenPks(t.pkStart, projectQuery.projectInfo.startingKilometerPoint, projectQuery.projectInfo.endingKilometerPoint)
                      && Pk.isPkBetweenPks(t.pkEnd, projectQuery.projectInfo.startingKilometerPoint, projectQuery.projectInfo.endingKilometerPoint)
                      && CETracks.every((tr) =>
                        Pk.isPkBetweenPks(t.pkStart, tr!.pkStart, tr!.pkEnd!) && Pk.isPkBetweenPks(t.pkEnd!, tr!.pkStart, tr!.pkEnd!)
                      );
                  })
                  .length}/>
            </Grid>
            <Grid item xs={8}>
              <MultipleStatsCard
                onClick={() => setShowAccidentsAtWork(true)}
                stats={[
                  {label: t("reporting.data5.1"), value: stats.accidentsAtWorkNbr},
                  {label: t("reporting.data5.2"), value: stats.daysWithoutAccidents},
                  {
                    label: t("reporting.data5.3"),
                    value: stats.maxDaysWithoutAccidents,
                  },
                ]}/>
            </Grid>
            {!projectQuery.isProjectTypeNew && (
              <>
                <Grid item xs={6}>
                  <ChartCard label={t("reporting.chart1")} fullHeightChild>
                    <ProjectDispatchesChart/>
                  </ChartCard>
                </Grid>
                <Grid item xs={6}>
                  <ChartCard label={t("reporting.chart2")} fullHeightChild>
                    <ProjectOrdersChart/>
                  </ChartCard>
                </Grid>
                <Grid item xs={12} mb="30px">
                  <ChartCard label={t("reporting.chart3")} fullHeightChild height="450px">
                    <ProjectDispatchesPerDayChart/>
                  </ChartCard>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      )}
      {showCountingPeople && (
        <CountingPeopleDetailsModal
          countingPeople={stats?.countingPeople || {}}
          handleClose={() => setShowCountingPeople(false)}/>
      )}
      {showAccidentsAtWork && (
        <AccidentsAtWorkModal handleClose={() => setShowAccidentsAtWork(false)}/>
      )}
    </>
  );
}

export default ProjectNumbersTab;
