import React, {useEffect, useState} from "react";
import {alpha, Box, Drawer, IconButton, Stack, styled, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Colors} from "@utils/theme/constants.utils";
import {IncompatibilityError} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, projectQuery, TrackElement, TrackElementType} from "@store/project";

const StyledIncompatibilityWarning = styled(Stack)({
  borderRadius: "10px",
  padding: "14px",
  color: Colors.secondaryRed,
  backgroundColor: alpha(Colors.secondaryRed, 0.06),
  borderRight: "6px",
});

interface ProjectIncompatibilitiesDrawerProps {
  handleClose: () => void;
  projectIncompatibilities: IncompatibilityError[];
  open: boolean;
}

const ProjectIncompatibilitiesDrawer = (props: ProjectIncompatibilitiesDrawerProps) => {
  const {handleClose, projectIncompatibilities, open} = props;

  const {t} = useTranslation();

  const [trackElements, setTrackElements] = useState<TrackElement[]>([]);

  useEffect(() => {
    return projectQuery.projectTrackElements$.subscribe(setTrackElements).unsubscribe;
  }, []);

  const getTracksNames = (trackElement: TrackElement) => {
    switch (trackElement.type) {
      case TrackElementType.WORK_ZONE:
      case TrackElementType.WORKSITE:
      case TrackElementType.TRAINING_ZONE:
      case TrackElementType.OUTSIDE_ZPF:
      case TrackElementType.PN:
        return '';
      default:
        return trackElement.tracks
          .map((tId) => trackElements.find((t) => t.id === tId)?.attributes?.name)
          .filter((t) => t)
          .join(', ');
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "transparent !important",
          boxShadow: "none",
          padding: 0,
        },
      }}>
      <Box position="absolute" top="10px" left="10px">
        <IconButton onClick={handleClose}>
          <img alt="" src="/images/cross_icon.svg"/>
        </IconButton>
      </Box>
      <Stack width="500px" height="100%" overflow="hidden">
        <Stack
          width="100%"
          height="100%"
          boxShadow="0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)"
          bgcolor="white"
          spacing={"40px"}
          p="50px">
          <Typography fontWeight="900" align="center" fontSize="20px">
            {t("incompatibilities.labels.title")}
          </Typography>
          <Stack spacing={"10px"} height={"100%"} overflow={"auto"}>
            {projectIncompatibilities.map((incompatibility, index) => {
              const trackElement = trackElements.find((t) => t.id === incompatibility.trackElementId);

              if (!trackElement) return null;

              const tracksNames = getTracksNames(trackElement);

              return (
                <StyledIncompatibilityWarning key={index} spacing={"8px"}>
                  <Typography fontSize="14px" fontWeight={700}>
                    {TrackElementType.getRELFElementLabel(trackElement.type)}
                    {trackElement.attributes?.name ? ` ${trackElement.attributes.name}` : ''} - {
                    trackElement.pkStart && trackElement.pkEnd
                      ? t("incompatibilities.labels.fromPKToPK", {
                        fromPK: Pk.toString(trackElement.pkStart),
                        toPK: Pk.toString(trackElement.pkEnd),
                      })
                      : t("incompatibilities.labels.atPK", {atPK: Pk.toString(trackElement.pkStart)})
                  }{tracksNames ? ` - ${tracksNames}` : ''}
                  </Typography>
                  <Typography fontSize="12px">{t(`incompatibilities.${incompatibility.error}`)}</Typography>
                </StyledIncompatibilityWarning>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default ProjectIncompatibilitiesDrawer;
