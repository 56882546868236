import {Store, StoreConfig} from "@datorama/akita";
import {Buffer} from "buffer/";

import {AuthenticationResponse, Session} from "./session.model";

import APIAxios from "@api/axios.api";
import SnackError from '@utils/error.utils';

const initSession = (): Session => {
  return {
    accessToken: Buffer.from(localStorage.getItem("token") || "", "base64").toString(),

    user: undefined,
  };
}

@StoreConfig({name: "session", resettable: true})
export class SessionStore extends Store<Session> {
  constructor() {
    super(initSession());

    const accessToken = this.getValue().accessToken;
    if (accessToken) {
      APIAxios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
  }

  login = (authenticationResponse: AuthenticationResponse) => {
    this.setAccessToken(authenticationResponse.accessToken);

    if (authenticationResponse.shouldResetPassword)
      throw new SnackError('PASSWORD_CHANGE_REQUIRED', 'warning');

    this.update(authenticationResponse);
  }

  setAccessToken = (accessToken: string) => {
    APIAxios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    localStorage.setItem("token", Buffer.from(accessToken).toString("base64"));
  }

  logout = () => {
    delete APIAxios.defaults.headers.common["Authorization"];

    window.localStorage.clear();

    window.location.reload();
  }
}

export const sessionStore = new SessionStore();
