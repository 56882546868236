import React, {useEffect} from 'react';
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ordersService} from "@store/dispatches/orders.service";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {Grid, InputLabel} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import NumberTextFieldComponent from "@components/textField/NumberTextField.component";
import {sessionQuery} from "@store/session";

const PnAttestReopenedForm = (props: DispatchFormGlobalProps) => {

  const {projectId} = useParams();
  const {t} = useTranslation();

  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const updateDispatchContent = (name: string) => (value: any) => setDispatch({
    ...dispatch,
    dispatch: {...dispatch.dispatch, [name]: value}
  });

  useEffect(() => {
    ordersService.getDispatchNumber(projectId!).subscribe({
      next: (dispatchNbr) => {
        setDispatch({
          type: DispatchTypeEnum.PN_ATTEST_REOPENED,
          dispatch: {
            from: `${sessionQuery.getValue().user?.firstname || ''} ${sessionQuery.getValue().user?.lastname || ''}`,
            to: trackElement?.attributes.APN,
            pnNbr: trackElement?.attributes?.name,
            date: new Date(),
            hour: new Date(),
            deliveredDispatchNbr: dispatchNbr,
          }
        });
      },
    });
  }, [])

  return (
    <div>
      <Grid container direction={"row"} columnSpacing={2.5} rowSpacing={1.5}>

        <Grid item xs={4}>
          <TextFieldComponent
            label={t('schema.pn.RELF')}
            readOnly={!editable}
            placeholder=" "
            value={dispatch?.dispatch?.from || ''}
            handleChange={updateDispatchContent("from")}
            error={errors["from"]}/>
        </Grid>

        <Grid item xs={4}>
          <TextFieldComponent
            label={`${t('schema.pn.to')} ${t('schema.pn.APN')}`}
            readOnly={!editable}
            placeholder=" "
            value={dispatch?.dispatch?.to || ''}
            handleChange={updateDispatchContent("to")}
            error={errors["to"]}/>
        </Grid>

        <Grid item xs={6}>
          <TextFieldComponent
            label={`${t('schema.pn.pronounceTheReturnToService')} ${t('schema.pn.ofPn')} N°`}
            readOnly={!editable}
            placeholder=" "
            value={dispatch?.dispatch?.pnNbr || ""}
            handleChange={updateDispatchContent("pnNbr")}
            error={errors["pnNbr"]}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel sx={{mx: '14px', textAlign: "left", whiteSpace: 'pre-line'}}>
            {t('schema.pn.canHaveTheAdminLockingDeviceRemoved')}
          </InputLabel>
        </Grid>

        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={t('schema.pn.dispatchDelivered')}
            readOnly={!editable}
            handleChange={updateDispatchContent("deliveredDispatchNbr")}
            value={dispatch?.dispatch?.deliveredDispatchNbr}
            error={errors["deliveredDispatchNbr"]}/>
        </Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={t('schema.pn.dispatchReceived')}
            readOnly={!editable}
            handleChange={updateDispatchContent("receivedDispatchNbr")}
            value={dispatch?.dispatch?.receivedDispatchNbr}
            error={errors["receivedDispatchNbr"]}/>
        </Grid>

      </Grid>
    </div>
  );
};

export default PnAttestReopenedForm;
