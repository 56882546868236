// @ts-ignore
import AxiformaLight from "./Axiforma/Axiforma-Light.woff2";
// @ts-ignore
import AxiformaRegular from "./Axiforma/Axiforma-Regular.woff2";
// @ts-ignore
import AxiformaMedium from "./Axiforma/Axiforma-Medium.woff2";
// @ts-ignore
import AxiformaBold from "./Axiforma/Axiforma-Bold.woff2";
// @ts-ignore
import AxiformaBlack from "./Axiforma/Axiforma-Black.woff2";

const fonts = `
  @font-face {
    font-family: 'Axiforma';
    font-weight: 100;
    src: local('Axiforma'), local('Axiforma-Light'), url(${AxiformaLight}) format('woff2');
  }
  @font-face {
    font-family: 'Axiforma';
    font-weight: 400;
    src: local('Axiforma'), local('Axiforma-Regular'), url(${AxiformaRegular}) format('woff2');
  }
  @font-face {
    font-family: 'Axiforma';
    font-weight: 500;
    src: local('Axiforma'), local('Axiforma-Medium'), url(${AxiformaMedium}) format('woff2');
  }
  @font-face {
    font-family: 'Axiforma';
    font-weight: 700;
    src: local('Axiforma'), local('Axiforma-Bold'), url(${AxiformaBold}) format('woff2');
  }
  @font-face {
    font-family: 'Axiforma';
    font-weight: 900;
    src: local('Axiforma'), local('Axiforma-Black'), url(${AxiformaBlack}) format('woff2');
  }
  `;

export default fonts;
