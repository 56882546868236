import {alpha, Components} from '@mui/material';

import {Colors} from "@utils/theme/constants.utils";

const tooltip: Components = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: (props) => ({
        ...(props.ownerState["aria-label"] === "show-pk" ? {
          backgroundColor: Colors.white,
          border: `1px solid ${Colors.schema.pkTooltip}`,
          boxShadow: `0 6px 16px 0 ${alpha(Colors.schema.pkTooltip, 0.3)}`,
          color: Colors.text,
          padding: '5.5px 8.5px',
        } : {}),
        ...(props.ownerState["aria-label"] === "show-element" ? {
          alignItems: 'center',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          padding: 0,
          "& > .MuiTooltip-arrow::before": {
            display: 'none',
          },
        } : {}),
        ...(props.ownerState["aria-label"] === "incompatibility" ? {
          backgroundColor: Colors.white,
          boxShadow: `0 6px 16px 0 ${alpha(Colors.schema.pkTooltip, 0.3)}`,
          color: Colors.text,
          padding: 10,
          margin: '0px !important',
        } : {}),
      }),
      arrow: (props) => ({
        ...(props.ownerState["aria-label"] === "incompatibility" ? {
          boxShadow: `0 6px 16px 0 ${alpha(Colors.schema.pkTooltip, 0.3)}`,
          color: Colors.white,
        } : {}),
      }),
    },
  }
}

export default tooltip;