import {Pk, TrackElement, TrackElementType} from '@store/project';
import {ID} from '@datorama/akita';

export const getZFBlockingElementsOnPks = (e: TrackElement, start: Pk, end: Pk, trackId?: ID) => {
  if ([TrackElementType.WORKSITE, TrackElementType.OBSTACLE, TrackElementType.TRAFFIC_BAN].includes(e.type)) {
    return (
      !!e.pkEnd &&
      (Pk.isPkBetweenPks(e.pkStart, start, end) ||
        Pk.isPkBetweenPks(e.pkEnd, start, end) ||
        Pk.isPkBetweenPks(start, e.pkStart, e.pkEnd) ||
        Pk.isPkBetweenPks(end, e.pkStart, e.pkEnd))
    ) && (!trackId || (e.type !== TrackElementType.WORKSITE && e.tracks.includes(trackId)));
  }

  if (e.type === TrackElementType.WORK_TRAIN) {
    const tpkEnd = e.attributes?.TTXLength ? Pk.addMeters(e.pkStart, +e.attributes.TTXLength) : undefined;
    return (
      !!tpkEnd &&
      (Pk.isPkBetweenPks(e.pkStart, start, end) ||
        Pk.isPkBetweenPks(tpkEnd, start, end) ||
        Pk.isPkBetweenPks(start, e.pkStart, tpkEnd) ||
        Pk.isPkBetweenPks(end, e.pkStart, tpkEnd))
      && (!trackId || e.tracks.includes(trackId))
    );
  }

  if (e.type === TrackElementType.WORKSITE_MACHINE) {
    return Pk.isPkBetweenPks(e.pkStart, start, end) && (!trackId || e.tracks.includes(trackId));
  }

  return false;
};