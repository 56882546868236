import React, {useState} from "react";
import {Box, Button, Stack} from "@mui/material";
import {t} from "i18next";

import DatePickerComponent from "@components/datePicker/DatePicker.component";
import SelectComponent from "@components/select/Select.component";
import RightDrawer from "@components/rightDrawer/RightDrawer.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {DispatchFiltersState} from "@store/dispatches/filters.store";
import {dispatchFiltersQuery} from "@store/dispatches/filters.query";
import {projectQuery, TrackElementType} from "@store/project";
import {newProjectDispatches, oldProjectDispatches, TrackElementHistoryType} from "@store/dispatches/dispatch.model";
import {dispatchFiltersService} from "@store/dispatches/filters.service";
import dayjs from '@utils/dayjs.utils';

export enum FiltersType {
  DISPATCH = "DISPATCH",
  RELF = "RELF",
}

interface DispatchDrawerProps {
  handleClose: () => void;
  type: FiltersType;
}

const DispatchDrawer = (props: DispatchDrawerProps) => {
  const {handleClose, type} = props;

  const [filters, setFilters] = useState<DispatchFiltersState>(dispatchFiltersQuery.filters);

  const startDateError = filters.startDate && !dayjs(filters.startDate, 'DD/MM/YYYY', true).isValid();
  const endDateError = filters.endDate && !dayjs(filters.endDate, 'DD/MM/YYYY', true).isValid();
  const disabledSetFilters = startDateError || endDateError;

  return (
    <RightDrawer
      open
      title={t("dispatches.filter")}
      actions={(
        <Stack direction="row" spacing={2} alignSelf="center">
          <Box>
            <Button variant="outlined" color="primary" fullWidth onClick={() => {
              dispatchFiltersService.resetFilters();
              handleClose();
            }}>{t("dispatches.resetFilters")}</Button>
          </Box>
          <Box>
            <Button variant="contained" color="primary" fullWidth disabled={disabledSetFilters} onClick={() => {
              if (disabledSetFilters) return;
              dispatchFiltersService.setFilters(filters);
              handleClose();
            }}>{t("dispatches.filter")}</Button>
          </Box>
        </Stack>
      )}
      content={(
        <Stack spacing={1} pt="30px">
          {type === FiltersType.DISPATCH ? (
            <>
              <Box>
                <SelectComponent
                  value={filters?.dispatchCategory || ""}
                  handleChange={(dispatchCategory) => setFilters((state) => ({...state, dispatchCategory}))}
                  label={t("dispatches.filterDrawer.category")}
                  items={TrackElementType[projectQuery.isProjectTypeNew ? "allNew" : "all"].map((type) => ({
                    label: TrackElementType.getRELFElementLabel(type),
                    value: type,
                  }))}
                  placeholder={t("dispatches.filterDrawer.categoryPlaceholder")}/>
              </Box>
              <Box>
                <SelectComponent
                  value={filters?.dispatchNature || ""}
                  handleChange={(dispatchNature) => setFilters((state) => ({...state, dispatchNature}))}
                  label={t("dispatches.filterDrawer.nature")}
                  items={projectQuery.isProjectTypeNew ? newProjectDispatches : oldProjectDispatches}
                  placeholder={t("dispatches.filterDrawer.naturePlaceholder")}/>
              </Box>
            </>
          ) : (
            <>
              <Box>
                <SelectComponent
                  value={filters?.trackElementHistoryCategory || ""}
                  handleChange={(trackElementHistoryCategory) => setFilters((state) => ({
                    ...state,
                    trackElementHistoryCategory
                  }))}
                  label={t("dispatches.filterDrawer.category")}
                  items={TrackElementHistoryType.selectOptions}
                  placeholder={t("dispatches.filterDrawer.categoryPlaceholder")}/>
              </Box>
              <Box>
                <SelectComponent
                  value={filters?.trackElementHistoryType || ""}
                  handleChange={(trackElementHistoryType) => setFilters((state) => ({
                    ...state,
                    trackElementHistoryType
                  }))}
                  label={t("dispatches.filterDrawer.type")}
                  items={TrackElementType[projectQuery.isProjectTypeNew ? "allNew" : "all"].map((type) => ({
                    label: TrackElementType.getRELFElementLabel(type),
                    value: type,
                  }))}
                  placeholder={t("dispatches.filterDrawer.typePlaceholder")}/>
              </Box>
            </>
          )}
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box>
              <DatePickerComponent
                value={filters?.startDate}
                handleChange={(startDate) => setFilters((state) => ({
                  ...state,
                  startDate,
                  startHour: !startDate ? undefined : state.startHour
                }))}
                error={startDateError ? t('errors.date') : undefined}
                placeholder=" "
                label={t("dispatches.filterDrawer.fromDate")}/>
            </Box>
            <Box>
              <TimePickerComponent
                disabled={!filters.startDate}
                value={filters?.startHour}
                handleChange={(startHour) => setFilters((state) => ({...state, startHour}))}
                placeholder=" "
                label={t("dispatches.filterDrawer.at")}/>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box>
              <DatePickerComponent
                value={filters?.endDate}
                handleChange={(endDate) => setFilters((state) => ({
                  ...state,
                  endDate,
                  endHour: !endDate ? undefined : state.endHour
                }))}
                error={endDateError ? t('errors.date') : undefined}
                placeholder=" "
                label={t("dispatches.filterDrawer.toDate")}/>
            </Box>
            <Box>
              <TimePickerComponent
                disabled={!filters.endDate}
                value={filters?.endHour}
                handleChange={(endHour) => setFilters((state) => ({...state, endHour}))}
                placeholder=" "
                label={t("dispatches.filterDrawer.at")}/>
            </Box>
          </Stack>
        </Stack>
      )}
      handleClose={handleClose}/>
  )
}

export default DispatchDrawer;