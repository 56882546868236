import React from "react";
import {bindHover, usePopupState} from "material-ui-popup-state/hooks";

import {TOOLTIP_HEIGHT, TRACK_ELEMENT_HEIGHT, TrackElement, TrackElementType} from "@store/project";
import TooltipDetails from "@screens/auth/common/schema/elements/TooltipDetails";
import {Colors} from "@utils/theme/constants.utils";
import {alpha} from "@mui/material";

interface TrainStationElementProps {
  handleClick?: () => void;
  showTooltip?: boolean;
  tooltipDirection?: 'start' | 'end';
  trackElement: TrackElement;
  x1: number;
  x2: number;
}

const TrainStationElement = (props: TrainStationElementProps) => {
  const {handleClick, showTooltip, tooltipDirection, trackElement, x1, x2} = props;

  const tooltipState = usePopupState({variant: 'popover', popupId: `tooltip-${trackElement.id}`});

  const textCooX = tooltipDirection === "start" ? x1 - 8 : x2 + 8;

  const height = TOOLTIP_HEIGHT * 0.75;

  let width = x2 - x1;
  if (width < height) width = height;

  return (
    <g>
      <g
        style={{cursor: handleClick ? 'pointer' : undefined}}
        onClick={handleClick}>
        {trackElement.attributes?.emergencyExit && (
          <rect
            x={x1 - 2} y={TOOLTIP_HEIGHT * 0.125 - 2}
            rx={height / 2 + 2} ry={height / 2 + 2}
            fill={alpha(TrackElementType.getColor(trackElement), 0)}
            stroke={Colors.schema.elements.worksiteMachine}
            strokeWidth={3}
            width={width + 4} height={height + 4}
            {...bindHover(tooltipState)}/>
        )}
        <rect
          x={x1} y={TOOLTIP_HEIGHT * 0.125}
          rx={height / 2} ry={height / 2}
          fill={alpha(TrackElementType.getColor(trackElement), 0.4)}
          stroke={trackElement.attributes?.emergencyExit ? "white" : undefined}
          strokeWidth={trackElement.attributes?.emergencyExit ? 2 : undefined}
          width={width} height={height}
          {...bindHover(tooltipState)}/>
        <image
          xlinkHref={TrackElementType.getIcon(trackElement.type)}
          x={x1 + width / 2 - 12}
          y={TOOLTIP_HEIGHT / 2 - 12}
          {...bindHover(tooltipState)}/>
      </g>
      {(showTooltip || tooltipState.isOpen) && (
        <TooltipDetails
          trackElement={trackElement}
          position={tooltipDirection}
          x={textCooX} y={TOOLTIP_HEIGHT / 2 + TRACK_ELEMENT_HEIGHT / 2}/>
      )}
    </g>
  );
}

export default TrainStationElement;