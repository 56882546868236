import {Components} from "@mui/material";
import {Colors} from "@utils/theme/constants.utils";

const accordions: Components = {
  MuiAccordion: {
    styleOverrides: {
      root: (props) => ({
        ...(props.ownerState.variant === "dispatch" ? {
          backgroundColor: Colors.background,
          "& .MuiFilledInput-root": {
            backgroundColor: Colors.white,
          },
          "&::before": {
            backgroundColor: 'transparent',
            content: "''",
          },
        } : {}),
      }),
    },
  },
};

export default accordions;
