import React from 'react';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {SnackbarProvider} from "notistack";
import {BrowserRouter as Router} from "react-router-dom";
import {LocalizationProvider} from '@mui/lab';
import AdapterDayjs from "@mui/lab/AdapterDayjs"

import Routes from "./screens/index";

import {SnackbarUtilsConfigurator} from '@utils/snackbar.utils';
import theme from "@utils/theme";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr">
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={2500}
          anchorOrigin={{vertical: "top", horizontal: "center"}}>
          <CssBaseline/>
          <SnackbarUtilsConfigurator/>
          <Router basename={process.env.PUBLIC_URL}>
            <Routes/>
          </Router>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
