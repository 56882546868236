import {object, string} from 'yup';

import I18n from '@utils/i18n.utils';
import * as YupUtils from "@utils/yup.utils";

export const forgotPasswordValidation = (email: string) => {
  const requiredString = string().required(I18n.t("errors.required"));

  try {
    const schema = object().shape({
      email: requiredString.email(I18n.t("errors.email")),
    });

    schema.validateSync({email}, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}
