import {useTranslation} from "react-i18next";
import {Button, Stack} from "@mui/material";
import {useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useEffect, useState} from "react";
import {finalize} from "rxjs";
import dayjs from "dayjs";

import {ordersService} from "@store/dispatches/orders.service";
import {TrackElement} from "@store/project";
import {Order} from "@store/dispatches/dispatch.model";

import ModalComponent from "@components/modals/Modal.component";
import TextFieldComponent from "@components/textField/TextField.component";

interface CreateOrderModalProps {
  currentOrders: Order[]
  handleClose: () => void;
  trackElement: TrackElement
}

const CreateOrderModal = (props: CreateOrderModalProps) => {
  const {currentOrders, handleClose, trackElement} = props;
  const {trackElementId} = useParams();
  const {enqueueSnackbar} = useSnackbar();

  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");

  useEffect(() => {
    const trackElementName = trackElement.attributes?.name || '';
    const todayDate = dayjs().format("DD.MM.YYYY");
    const todayOrders = currentOrders.filter(order => order.creationDate && dayjs(order.creationDate).format("DD.MM.YYYY") === todayDate);

    const numberOrder = todayOrders.length ? ' - ' + (todayOrders.length + 1) : '';
    setName(`${trackElementName} - ${todayDate}${numberOrder}`);
  }, []);

  const handleCreate = () => {
    if (!trackElementId) return;
    setLoading(true);
    ordersService.createOrder(trackElementId, name).pipe(finalize(() => setLoading(false))).subscribe({
      next: handleClose,
      error: err => enqueueSnackbar(err.text, err.options),
    });
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("dispatches.newOrder")}
      description={t("dispatches.newOrderDescription")}
      content={(
        <Stack width="60%" pt="20px" pb="20px">
          <TextFieldComponent
            label={t("dispatches.orderName")}
            readOnly
            value={name}/>
        </Stack>
      )}
      actions={(
        <>
          <Button variant="outlined" color="primary" disabled={loading} onClick={handleClose}>
            {t("global.cancel")}
          </Button>
          <Button variant="contained" color="primary" disabled={loading || !name} onClick={handleCreate}>
            {t("global.create")}
          </Button>
        </>
      )}/>
  );
}

export default CreateOrderModal;
