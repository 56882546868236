import React, {useEffect} from "react";
import {Grid} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {sessionQuery} from "@store/session";

const ModificationNoticeWorkAreaForm = (props: DispatchFormGlobalProps) => {
  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const updateDispatchContent = (name: string) => (value: any) =>
    setDispatch({
      ...dispatch,
      dispatch: {...dispatch.dispatch, [name]: value},
    });

  useEffect(() => {
    if (trackElement) {
      setDispatch({
        type: DispatchTypeEnum.WORK_ZONE_NOTICE_OF_MODIFICATION_OF_THE_WORK_AREA,
        dispatch: {
          from: trackElement.attributes.RZT,
          to: `${sessionQuery.getValue().user?.firstname || ""} ${sessionQuery.getValue().user?.lastname || ""}`,
          date: new Date(),
          hour: new Date(),
          nbr: trackElement.attributes.name,
          natureOfWork: trackElement.attributes.natureOfWork,
        },
      });
    }
  }, []);

  return (
    <div>
      <Grid container columnSpacing={2.5} rowSpacing={1.5}>
        <Grid item xs={4}>
          <TextFieldComponent
            label={"M./Mme RZT"}
            readOnly={!editable}
            value={dispatch?.dispatch?.from || ""}
            handleChange={updateDispatchContent("from")}
            error={errors["from"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent
            label={"M./Mme RELF"}
            readOnly={!editable}
            value={dispatch?.dispatch?.to || ""}
            handleChange={updateDispatchContent("to")}
            error={errors["to"]}
          />
        </Grid>

        <Grid item xs={4}>
          <TextFieldComponent
            label={"Modification du bornage de la ZT N°"}
            placeholder={"N°"}
            readOnly
            value={dispatch?.dispatch?.nbr || ""}
            error={errors["nbr"]}
          />
        </Grid>

        <Grid item xs={4}>
          <DatePickerComponent
            label={"Le"}
            readOnly={!editable}
            value={dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("date")}
            error={errors["date"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            label={"à"}
            readOnly={!editable}
            value={editable ? dispatch?.dispatch?.hour : dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("hour")}
            error={errors["hour"]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ModificationNoticeWorkAreaForm;
