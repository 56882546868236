import React from "react";

import {
  TOOLTIP_DEFAULT_SPACING,
  TOOLTIP_HEIGHT,
  TRACK_ELEMENT_HEIGHT,
  TrackElement,
  TrackElementCategory,
  TrackElementType
} from "@store/project";
import {bindHover, usePopupState} from "material-ui-popup-state/hooks";

import TooltipDetails from "@screens/auth/common/schema/elements/TooltipDetails";

interface ZoneElementProps {
  handleClick?: () => void;
  position?: "bottom" | "top";
  showTooltip?: boolean;
  tooltipDirection?: 'start' | 'end';
  trackElement: TrackElement;
  trackName: string;
  x1: number;
  x2: number;
  y: number;
}

const ZoneElement = (props: ZoneElementProps) => {
  const {handleClick, position = "top", showTooltip, tooltipDirection, trackElement, trackName, x1, x2, y} = props;

  const tooltipState = usePopupState({variant: 'popover', popupId: `tooltip-${trackElement.id}`});

  const tooltipCoo = {
    x: x1 + (x2 - x1) / 2,
    y: y + (position === "top" ? -1 : 1) * (TRACK_ELEMENT_HEIGHT / 2 + TOOLTIP_HEIGHT / 2 + TOOLTIP_DEFAULT_SPACING / 2),
  };

  const textCooX = tooltipCoo.x + (tooltipDirection === "start" ? -1 : 1) * (TOOLTIP_HEIGHT / 2 + 8);

  return (
    <g {...bindHover(tooltipState)}>
      <rect
        x={x1} y={y - TRACK_ELEMENT_HEIGHT / 2}
        rx={TRACK_ELEMENT_HEIGHT / 2} ry={TRACK_ELEMENT_HEIGHT / 2}
        fill={trackElement.category === TrackElementCategory.RESTRICTION ? "url(#restriction)" : TrackElementType.getColor(trackElement)}
        width={x2 - x1} height={TRACK_ELEMENT_HEIGHT}/>
      {(showTooltip || tooltipState.isOpen) && (
        <g>
          <rect
            style={{cursor: handleClick ? 'pointer' : undefined}} onClick={handleClick}
            x={tooltipCoo.x - TOOLTIP_HEIGHT / 2 - 4}
            y={y - (position === "top" ? TOOLTIP_HEIGHT + 8 + TRACK_ELEMENT_HEIGHT / 2 : 0)}
            rx={TRACK_ELEMENT_HEIGHT / 2} ry={TRACK_ELEMENT_HEIGHT / 2}
            fill="transparent"
            width={TOOLTIP_HEIGHT + 8} height={TOOLTIP_HEIGHT + 8}/>
          <g style={{cursor: handleClick ? 'pointer' : undefined}} onClick={handleClick}>
            <circle
              cx={tooltipCoo.x} cy={tooltipCoo.y} r={TOOLTIP_HEIGHT / 2}
              fill={TrackElementType.getColor(trackElement)}/>
            <image
              xlinkHref={TrackElementType.getIcon(trackElement.type, trackElement)}
              height={24} width={24}
              x={tooltipCoo.x - 12}
              y={tooltipCoo.y - 12}/>
          </g>
          <TooltipDetails
            trackElement={trackElement} trackName={trackName}
            position={tooltipDirection}
            x={textCooX} y={tooltipCoo.y}/>
        </g>
      )}
    </g>
  );
}

export default ZoneElement;