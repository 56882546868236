import {Components} from "@mui/material";

import {Colors} from "@utils/theme/constants.utils";

const dialog: Components = {
  MuiDrawer: {
    styleOverrides: {
      root: {
        zIndex: 1300,
      }
    }
  },
  MuiDialog: {
    styleOverrides: {
      root: (props) => ({
        "& .MuiIconButton-root[aria-label=close]": {
          left: 20,
          padding: 0,
          position: 'absolute',
          top: 20,
        },
        ...(props.ownerState["aria-label"] === "project-details" ? {
          zIndex: 1298,
          "& .MuiDialog-paper": {
            backgroundColor: Colors.background,
            borderRadius: 0,
            padding: "20px 20px 0",
          },
          "& .MuiDialogContent-root": {
            backgroundColor: Colors.background,
            padding: "0",
          },
        } : {}),
        ...(props.ownerState["aria-label"] === "full-height" ? {
          ...(props.ownerState.color === 'primary' ? {
            "& .MuiDialog-paper": {
              backgroundColor: Colors.inputBackground,
              height: '100%',
            },
          } : {
            "& .MuiDialog-paper": {
              height: '100%',
            },
          }),
        } : {}),
        ...(props.ownerState["aria-label"] === "schema-fullscreen" ? {
          "& .MuiIconButton-root[aria-label=close]": {
            left: 20,
            padding: 0,
            position: 'absolute',
            top: 20,
            zIndex: 1300,
          },
          "& .MuiDialog-paperFullScreen": {
            borderRadius: 0,
            height: '100%',
            maxHeight: '100%',
            padding: 0,
            "& > .MuiDialogContent-root": {
              padding: '20px 0 0',
              "& div[aria-label=schema-options]": {
                left: 'inherit',
                right: 20,
                top: 0,
              },
            },
          },
        } : {}),
      }),
      paper: {
        backgroundColor: Colors.white,
        borderRadius: 12,
        padding: "60px 0 30px",
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontSize: 20,
        fontWeight: 900,
        marginBottom: 10,
        padding: '0 20px',
        textAlign: 'center',
      },
    },
  },
  MuiDialogContentText: {
    styleOverrides: {
      root: {
        fontSize: 14,
        fontWeight: 400,
        textAlign: 'center',
        whiteSpace: 'pre-line',
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        justifyContent: 'center',
        marginTop: 20,
        "& > :not(:first-of-type)": {
          marginLeft: 30,
        },
      },
      spacing: {
        padding: 0,
      },
    },
  },
};

export default dialog;
