import axios, {AxiosError, AxiosRequestConfig} from "axios";
import {ID} from "@datorama/akita";
import {sessionService} from "@store/session";

const APIAxios = axios.create({
  baseURL: process.env.REACT_APP_API_HOST || "",
});

APIAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    if (error.response?.status === 401 && error.response?.data.message !== "CODE_NOT_CORRECT") {
      await sessionService.logout();
    }

    return Promise.reject(error);
  }
);

export const APIRoutes = {
  /* AUTH REQUESTS */
  POSTLogin: (): AxiosRequestConfig => ({method: "post", url: "/auth/login"}),
  GETForgotPassword: (email: string): AxiosRequestConfig => ({
    method: "get",
    url: `/auth/forgotten-password/${email}`,
  }),
  PATCHChangePassword: (): AxiosRequestConfig => ({method: "patch", url: "/auth/change-password"}),
  /* ------------- */

  /* EXPORTS */
  GETProjectDispatchesCSV: (projectId: string): AxiosRequestConfig => ({
    method: "get",
    url: `/export/project/${projectId}/dispatches`,
  }),
  /* ------- */

  /* USERS */
  GETCurrentUser: (): AxiosRequestConfig => ({method: "get", url: "/users/me"}),
  GETCurrentUserCode: (): AxiosRequestConfig => ({method: "post", url: "/users/identification-code"}),
  GETUsers: (): AxiosRequestConfig => ({method: "get", url: "/users"}),
  GETUserById: (userId: ID): AxiosRequestConfig => ({method: "get", url: `/users/${userId}`}),
  POSTCreateUser: (): AxiosRequestConfig => ({
    method: "post",
    url: "/users",
    headers: {"Content-Type": "multipart/form-data"},
  }),
  PUTUpdateUser: (userId: ID): AxiosRequestConfig => ({method: "put", url: `/users/${userId}`}),
  DELETEUser: (userId: ID): AxiosRequestConfig => ({method: "delete", url: `/users/${userId}`}),
  PUTDisableUser: (userId: ID): AxiosRequestConfig => ({method: "put", url: `/users/${userId}/disable`}),
  PUTReactivateUser: (userId: ID): AxiosRequestConfig => ({method: "put", url: `/users/${userId}/reactivate`}),
  POSTConfirmCode: (): AxiosRequestConfig => ({method: "post", url: `/users/confirm-identification-code`}),
  PUTResetUserIdentificationCode: (userId: ID): AxiosRequestConfig => ({
    method: "put",
    url: `/users/${userId}/reset-identification-code`,
  }),
  POSTResetPassword: (userId: ID): AxiosRequestConfig => ({method: "post", url: `/users/${userId}/reset-password`}),
  /* ----- */

  /* PROJECTS */
  GETProjects: (): AxiosRequestConfig => ({method: "get", url: "/projects"}),
  GETProjectById: (projectId: ID): AxiosRequestConfig => ({method: "get", url: `/projects/${projectId}`}),
  POSTCreateProject: (): AxiosRequestConfig => ({method: "post", url: "/projects"}),
  PUTUpdateProject: (projectId: ID): AxiosRequestConfig => ({method: "put", url: `/projects/${projectId}`}),
  PATCHCloseProject: (projectId: ID): AxiosRequestConfig => ({method: "patch", url: `/projects/${projectId}`}),
  DELETEProject: (projectId: ID): AxiosRequestConfig => ({method: "delete", url: `/projects/${projectId}`}),
  /* ----- */

  /* PROJECT CUSTOMERS */
  GETCustomers: (projectId: ID): AxiosRequestConfig => ({method: "get", url: `/projects/${projectId}/customers`}),
  POSTAssociateCustomers: (projectId: ID, customerId: ID): AxiosRequestConfig => ({
    method: "post",
    url: `/projects/${projectId}/customers/${customerId}`,
  }),
  DELETERemoveCustomer: (projectId: ID, customerId: ID): AxiosRequestConfig => ({
    method: "delete",
    url: `/projects/${projectId}/customers/${customerId}`,
  }),
  /* ----- */

  /* PROJECT TRACK ELEMENTS */
  GETTrackElements: (projectId: ID): AxiosRequestConfig => ({
    method: "get",
    url: `/track-elements/project/${projectId}`,
  }),
  GETTrackElementLastZT: (projectId: ID): AxiosRequestConfig => ({
    method: "get",
    url: `/track-elements/project/${projectId}/zt`,
  }),
  GETTrackElementClosedZTToday: (projectId: ID): AxiosRequestConfig => ({
    method: "get",
    url: `/track-elements/project/${projectId}/closed-today-zt`,
  }),
  POSTCreateTrackElement: (projectId: ID): AxiosRequestConfig => ({
    method: "post",
    url: `/track-elements/project/${projectId}`,
  }),
  PATCHUpdateTrack: (trackElementId: ID): AxiosRequestConfig => ({
    method: "patch",
    url: `/track-elements/${trackElementId}`,
  }),
  DELETETrackElement: (trackElementId: ID): AxiosRequestConfig => ({
    method: "delete",
    url: `/track-elements/${trackElementId}`,
  }),
  PATCHUpdateTracks: (): AxiosRequestConfig => ({method: "patch", url: `/track-elements`}),
  GETActivityNumber: (trackElementId: ID): AxiosRequestConfig => ({
    method: "get",
    url: `/track-elements/${trackElementId}/activity-number`,
  }),
  /* ----- */

  /* FILES */
  GETFile: (fileId: ID): AxiosRequestConfig => ({method: "get", url: `/upload/${fileId}`, responseType: "blob"}),
  /* ----- */

  /* DISPATCH */
  GETOrder: (trackElementId: ID): AxiosRequestConfig => ({
    method: "get",
    url: `/dispatch/track-elements/${trackElementId}/order`,
  }),
  POSTOrder: (trackElementId: ID): AxiosRequestConfig => ({
    method: "post",
    url: `/dispatch/track-elements/${trackElementId}/order`,
  }),

  POSTDispatch: (orderId: ID): AxiosRequestConfig => ({
    method: "post",
    url: `/dispatch/order/${orderId}`,
  }),
  PATCHCloseOrder: (orderId: ID): AxiosRequestConfig => ({
    method: "patch",
    url: `/dispatch/order/${orderId}/closeOrder`,
  }),

  GETTodayDispatches: (projectId: ID): AxiosRequestConfig => ({
    method: "get",
    url: `/dispatch/project/${projectId}/today-dispatch`,
  }),
  GETDispatchHistory: (): AxiosRequestConfig => ({
    method: "get",
    url: "/history/dispatch",
  }),
  GETOneDispatch: (dispatchId: ID): AxiosRequestConfig => ({
    method: "get",
    url: `/history/dispatch/${dispatchId}`,
  }),
  GETTrackElementHistory: (): AxiosRequestConfig => ({
    method: "get",
    url: "/history/trackElement",
  }),
  GETTrackElementCSV: (): AxiosRequestConfig => ({
    method: "get",
    url: "/history/trackElement/csv",
  }),
  GETDispatchHistoryCSV: (): AxiosRequestConfig => ({
    method: "get",
    url: "/history/dispatch-csv",
  }),

  GETTodayDispatchCSV: (projectId: ID): AxiosRequestConfig => ({
    method: "get",
    url: `/dispatch/project/${projectId}/today-dispatch-csv`,
  }),

  GETDispatchesByOrder: (orderId: ID): AxiosRequestConfig => ({
    method: "get",
    url: `/dispatch/order/${orderId}`,
  }),
  GETDispatchNbr: (projectId: ID): AxiosRequestConfig => ({
    method: "get",
    url: `/dispatch/project/${projectId}/deliveredNbr`,
  }),
  GETAllDispatchesByProject: (projectId: ID): AxiosRequestConfig => ({
    method: "get",
    url: `/dispatch/project/${projectId}`,
  }),

  /* History */

  POSTHistoryCommon: (): AxiosRequestConfig => ({
    method: "post",
    url: `/history/common`,
  }),

  POSTHistoryDispatch: (): AxiosRequestConfig => ({
    method: "post",
    url: `/history/free-dispatch`,
  }),
  POSTHistoryPeopleCounting: (): AxiosRequestConfig => ({
    method: "post",
    url: `/history/people-counting`,
  }),
  POSTHistoryAccidentsAtWork: (): AxiosRequestConfig => ({
    method: "post",
    url: `/history/accidents-at-work`,
  }),
  POSTRZTHandoverHistory: (trackElementId: ID): AxiosRequestConfig => ({
    method: "post",
    url: `/history/rzt-handover/${trackElementId}`,
  }),
  POSTADVAcquittalHistory: (trackElementId: ID): AxiosRequestConfig => ({
    method: "post",
    url: `/history/adv-acquittal/${trackElementId}`,
  }),
  POSTPNAcquittalHistory: (trackElementId: ID): AxiosRequestConfig => ({
    method: "post",
    url: `/history/pn-acquittal/${trackElementId}`,
  }),
  POSTStopSignAcquittalHistory: (trackElementId: ID): AxiosRequestConfig => ({
    method: "post",
    url: `/history/stop-sign-acquittal/${trackElementId}`,
  }),
  /* ------ */

  /* REPORTING */
  GETProjectStats: (): AxiosRequestConfig => ({
    method: "get",
    url: `/history/trackElement-stats`,
  }),
  GETProjectCountingPeople: (): AxiosRequestConfig => ({
    method: "get",
    url: `/history/counting-people`,
  }),
  GETProjectCountingPeopleCSV: (): AxiosRequestConfig => ({
    method: "get",
    url: "/history/counting-people-csv",
  }),
  GETProjectAccidentsAtWork: (): AxiosRequestConfig => ({
    method: "get",
    url: `/history/accidents-at-work`,
  }),
  GETProjectAccidentsAtWorkCSV: (): AxiosRequestConfig => ({
    method: "get",
    url: `/history/accidents-at-work-csv`,
  }),
  GETProjectDispatchesStats: (projectId: ID): AxiosRequestConfig => ({
    method: "get",
    url: `/dispatch/project/${projectId}/dispatches-stats`,
  }),
  GETProjectOrdersStats: (projectId: ID): AxiosRequestConfig => ({
    method: "get",
    url: `/dispatch/project/${projectId}/orders-stats`,
  }),
  GETProjectDispatchesByDayStats: (projectId: ID): AxiosRequestConfig => ({
    method: "get",
    url: `/dispatch/project/${projectId}/dispatches-by-day-stats`,
  }),
  /* --------- */
};

export default APIAxios;
