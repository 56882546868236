import {Pk, TrackElement} from '@store/project';
import {PNDispatchType} from '@store/dispatches/dispatch.model';
import {IncompatibilityError} from '@utils/incompatibilities/incompatibility.utils';
import checkClosePNInZT from '@utils/incompatibilities/PN/CLOSE_PN_IN_ZT/closePNInZT';
import checkOpenPNInZT from '@utils/incompatibilities/PN/OPEN_PN_IN_ZT/openPNInZT';
import checkOutZT20mPN from '@utils/incompatibilities/PN/OUT_ZT_20M_PN/outZT20mPN';
import checkInZT20mPN from '@utils/incompatibilities/PN/IN_ZT_20M_PN/inZT20mPN';
import checkOutZF20mPN from '@utils/incompatibilities/PN/OUT_ZF_20M_PN/outZF20mPN';
import checkInZF20mPN from '@utils/incompatibilities/PN/IN_ZF_20M_PN/inZF20mPN';
import { ID } from '@datorama/akita';


interface GetS9A3PNIncParams {
  trackElementId?: ID;
  fromDispatch?: boolean;
  pkStart?: Pk;
  status?: PNDispatchType;
  ZFs?: TrackElement[];
  ZTs?: TrackElement[];
}

export const getS9A3PNInc = (params: GetS9A3PNIncParams): IncompatibilityError[] => {
  const {fromDispatch, pkStart, status, trackElementId, ZFs = [], ZTs = []} = params;

  if (!pkStart) return [];

  const incompatibilities: IncompatibilityError[] = [];

  incompatibilities.push(...checkClosePNInZT({trackElementId, fromDispatch, pkStart, status, ZTs}));

  incompatibilities.push(...checkOpenPNInZT({trackElementId, fromDispatch, pkStart, status, ZTs}));

  incompatibilities.push(...checkOutZT20mPN({trackElementId, pkStart, ZTs}));

  incompatibilities.push(...checkInZT20mPN({trackElementId, pkStart, ZTs}));

  incompatibilities.push(...checkOutZF20mPN({trackElementId, pkStart, ZFs}));

  incompatibilities.push(...checkInZF20mPN({trackElementId, pkStart, ZFs}));

  return incompatibilities;
}
