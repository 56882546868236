import {date, object, string} from 'yup';
import * as YupUtils from "@utils/yup.utils";
import I18n from "@utils/i18n.utils";
import {CreateDispatch, CreateDispatchContent, DispatchTypeEnum} from "@store/dispatches/dispatch.model";

const requiredString = string().required(I18n.t("errors.required"));
const requiredDate = date().nullable()
  .required(I18n.t("errors.required"))
  .typeError(I18n.t("errors.date"));
  const requiredHour = date().nullable()
    .required(I18n.t("errors.required"))
    .typeError(I18n.t("errors.hour"));

const maneuverTrackChange = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      trackChangeNbr: requiredString,
      from: requiredString,
      maneuverOrientation: requiredString,
      date: requiredDate,
      hour: requiredHour,
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

export function trackChangeDispatchValidation(data: CreateDispatch) {
  switch (data.type) {
    case DispatchTypeEnum.MANEUVER_TRACK_CHANGE:
      return maneuverTrackChange(data.dispatch || {});
  }
  return {};
}
