import _ from "lodash";

import {Pk, TrackElement} from "@store/project";

import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {ID} from "@datorama/akita";

interface checkNoLWinZTParams {
  trackElementId?: ID;
  pkStart: Pk;
  pkEnd: Pk;
  ZTs: TrackElement[];
}

/* Should not be able to set LW Pks inside a ZT */
const checkNoLWinZT = (params: checkNoLWinZTParams): IncompatibilityError[] => {
  const {pkStart, pkEnd, trackElementId, ZTs} = params;

  const LWInZTs = ZTs.filter(
    (t) =>
      !!t.pkEnd &&
      (Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd) ||
        Pk.isPkBetweenPks(pkEnd, t.pkStart, t.pkEnd) ||
        Pk.isPkBetweenPks(t.pkStart, pkStart, pkEnd) ||
        Pk.isPkBetweenPks(t.pkEnd, pkStart, pkEnd)) &&
      !_.isEqual(pkStart, t.pkEnd) &&
      !_.isEqual(pkEnd, t.pkStart)
  );

  if (LWInZTs.length) {
    return LWInZTs.map((ZT) => ({
      error: IncompatibilityErrorEnum.NO_LW_IN_ZT,
      trackElementId,
      concernedTrackElementIds: [ZT.id],
    }));
  }

  return [];
};

export default checkNoLWinZT;
