import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement, TrackElementType} from "@store/project";
import {ID} from "@datorama/akita";

interface checkNoElementOutsideTrackParams {
  trackElementId?: ID;
  pkStart: Pk;
  pkEnd: Pk;
  otherTrackElements: TrackElement[];
}

/* Track can't have track elements outside of it */
const checkNoElementOutsideTrack = (params: checkNoElementOutsideTrackParams): IncompatibilityError[] => {
  const {trackElementId, pkStart, pkEnd, otherTrackElements} = params;

  if (!trackElementId) return [];

  const elementsOnTrack = otherTrackElements.filter((t) => {
    switch (t.type) {
      case TrackElementType.WORK_ZONE:
      case TrackElementType.WORKSITE:
      case TrackElementType.TRAINING_ZONE:
      case TrackElementType.OUTSIDE_ZPF:
      case TrackElementType.PN:
        return false;
      default:
        return t.tracks.includes(trackElementId);
    }
  })

  const elementsOutsideTrack = elementsOnTrack.filter((t) => {
    let tStart = t.pkStart;
    let tEnd = t.pkEnd;

    if (t.type === TrackElementType.TRACK_CHANGE && !!t.tracks.length && t.tracks[1] === trackElementId && !!t.pkEnd) {
      tStart = t.pkEnd;
      tEnd = undefined;
    }

    if (t.type === TrackElementType.WORK_TRAIN) {
      tEnd = Pk.addMeters(t.pkStart, t.attributes?.TTXLength ?? 0);
    }

    return !Pk.isPkBetweenPks(tStart, pkStart, pkEnd) || (!!tEnd && !Pk.isPkBetweenPks(tEnd, pkStart, pkEnd));
  });

  if (elementsOutsideTrack.length) {
    return [
      {
        error: IncompatibilityErrorEnum.NO_ELEMENT_OUTSIDE_TRACK,
        trackElementId,
        concernedTrackElementIds: elementsOutsideTrack.map((t) => t.id),
      },
    ];
  }

  return [];
}

export default checkNoElementOutsideTrack;