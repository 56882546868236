import {ID, Query} from "@datorama/akita";
import {map} from "rxjs";

import {Project, TTxPath} from "./project.model";
import {projectStore, ProjectStore} from "./project.store";
import {Pk, TrackElement, TrackElementCategory, TrackElementType} from "@store/project/schema.model";
import {ProjectType} from "@store/projects";
import {ManeuverOrientation} from "@store/dispatches/dispatch.model";
import {User} from '@store/users';

export class ProjectQuery extends Query<Project> {
  projectInfo$ = this.select("info");
  projectTrackElements$ = this.select("trackElements").pipe(
    map((trackElements) => {
      const trainingZoneAndWorkingArea = trackElements.filter(
        (el) => el.type === TrackElementType.TRAINING_ZONE || el.type === TrackElementType.WORK_ZONE
      );

      return trackElements.filter((trackElement) => {
        if (trackElement.category === TrackElementCategory.TRAIN_TRACK && trackElement.type !== TrackElementType.MAIN_TRACK) {
          if (!trackElement.pkEnd) return false;

          if (
            trainingZoneAndWorkingArea.some(
              (el) =>
                Pk.isPkBetweenPks(trackElement.pkStart, el.pkStart, el.pkEnd!) ||
                Pk.isPkBetweenPks(el.pkStart, trackElement.pkStart, trackElement.pkEnd!) ||
                Pk.isPkBetweenPks(trackElement.pkEnd!, el.pkStart, el.pkEnd!) ||
                Pk.isPkBetweenPks(el.pkEnd!, trackElement.pkStart, trackElement.pkEnd!)
            )
          ) {
            return false;
          }
        }

        return true;
      });
    })
  );
  projectTracks$ = this.select("trackElements").pipe(
    map((trackElements) =>
      trackElements.filter((t) => {
        if (t.category !== TrackElementCategory.TRAIN_TRACK) return false;

        if (!Pk.isPkBetweenPks(t.pkStart, this.projectInfo.startingKilometerPoint, this.projectInfo.endingKilometerPoint)) {
          return false;
        }
        return !(!t.pkEnd || !Pk.isPkBetweenPks(t.pkEnd, this.projectInfo.startingKilometerPoint, this.projectInfo.endingKilometerPoint));
      })
    ),
    map((tracks) => tracks.sort((t1, t2) => (t1.attributes?.yIndex || 0) - (t2.attributes?.yIndex || 0)))
  );

  constructor(protected store: ProjectStore) {
    super(store);
  }

  get projectId() {
    return this.getValue().info.id;
  }

  get projectInfo() {
    return this.getValue().info;
  }

  get isProjectTypeNew() {
    return this.getValue().info.projectType === ProjectType.NEW;
  }

  get isClosed() {
    return !this.getValue().info.isPending;
  }

  get projectTracks() {
    return this.getValue().trackElements.filter((t) => {
      if (t.category !== TrackElementCategory.TRAIN_TRACK) return false;

      if (!Pk.isPkBetweenPks(t.pkStart, this.projectInfo.startingKilometerPoint, this.projectInfo.endingKilometerPoint)) {
        return false;
      }
      return !(!t.pkEnd || !Pk.isPkBetweenPks(t.pkEnd, this.projectInfo.startingKilometerPoint, this.projectInfo.endingKilometerPoint));
    });
  }

  get projectWM() {
    return this.getValue().trackElements.filter((t) => t.type === TrackElementType.WORKSITE_MACHINE);
  }

  get projectTTX() {
    return this.getValue().trackElements.filter((t) => t.type === TrackElementType.WORK_TRAIN);
  }

  get projectZT() {
    return this.getValue().trackElements.filter((t) => t.type === TrackElementType.WORK_ZONE);
  }

  get projectClosedZTToday() {
    return this.getValue().closedZTToday;
  }

  get projectCE() {
    return this.getValue().trackElements.filter((t) => t.type === TrackElementType.ELEMENTARY_CONSTRUCTION_SITE);
  }

  get projectPN() {
    return this.getValue().trackElements.filter((t) => t.type === TrackElementType.PN);
  }

  get projectZF() {
    return this.getValue().trackElements.filter((t) => t.type === TrackElementType.TRAINING_ZONE);
  }

  get activeProjectZF() {
    const ZTs = this.projectZT;

    return this.getValue()
      .trackElements.filter((t) => t.type === TrackElementType.TRAINING_ZONE)
      .filter(
        (t) =>
          !ZTs.some(
            (ZT) => !!ZT.pkEnd && !!t.pkEnd && Pk.isPkBetweenPks(t.pkStart, ZT.pkStart, ZT.pkEnd) && Pk.isPkBetweenPks(t.pkEnd, ZT.pkStart, ZT.pkEnd)
          )
      );
  }

  get allTrackElements() {
    return this.getValue().trackElements;
  }

  get projectViaducts() {
    return this.getValue().trackElements.filter((t) => t.type === TrackElementType.VIADUCT);
  }

  get projectTunnels() {
    return this.getValue().trackElements.filter((t) => t.type === TrackElementType.TUNNEL);
  }

  get projectRestrictions() {
    return this.getValue().trackElements.filter((t) => t.category === TrackElementCategory.RESTRICTION);
  }

  get projectCCI() {
    return this.getValue().trackElements.filter((t) => t.type === TrackElementType.WORKSITE);
  }

  get projectActivities() {
    return this.getValue().trackElements.filter((t) => t.category === TrackElementCategory.ACTIVITY);
  }

  get projectTrainingZone() {
    return this.getValue().trackElements.filter((t) => t.type === TrackElementType.TRAINING_ZONE);
  }

  get projectADVs() {
    return this.getValue().trackElements.filter((t) => t.type === TrackElementType.TRACK_CHANGE);
  }

  get projectSignalisation() {
    return this.getValue().trackElements.filter((t) => t.category === TrackElementCategory.SIGNALISATION);
  }

  projectTrackElementsByType$ = (type: TrackElementType) =>
    this.select("trackElements").pipe(map((trackElements) => trackElements.filter((te) => te.type === type)));

  projectTrackElementById$ = (trackElementId: ID) =>
    this.select("trackElements").pipe(map((trackElements) => trackElements.find((trackElement) => trackElement.id === trackElementId)));

  trackElementsBySpecialZone$ = (trackElementId: ID) =>
    this.select("trackElements").pipe(
      map((trackElements) => {
        const specialZone = trackElements.find((trackElement) => trackElement.id === trackElementId);
        if (!specialZone || !specialZone?.pkEnd) return [];

        return trackElements.filter((trackElement) => {
          if (
            trackElement.type === TrackElementType.TRAINING_ZONE ||
            trackElement.type === TrackElementType.WORK_ZONE ||
            trackElement.type === TrackElementType.DECLIVITY
          ) {
            return false;
          }

          if (!trackElement.pkEnd && !Pk.isPkBetweenPks(trackElement.pkStart, specialZone.pkStart, specialZone.pkEnd!)) return false;

          if (trackElement.pkEnd) {
            if (
              !Pk.isPkBetweenPks(trackElement.pkStart, specialZone.pkStart, specialZone.pkEnd!) &&
              !Pk.isPkBetweenPks(specialZone.pkStart, trackElement.pkStart, trackElement.pkEnd) &&
              !Pk.isPkBetweenPks(trackElement.pkEnd, specialZone.pkStart, specialZone.pkEnd!) &&
              !Pk.isPkBetweenPks(specialZone.pkEnd!, trackElement.pkStart, trackElement.pkEnd)
            ) {
              return false;
            }
          }

          return true;
        });
      })
    );

  getTTxPath = (pkStart: Pk, pkEnd: Pk, trackId: ID, advs?: TrackElement[]): TTxPath[] => {
    const isRightToLeft = Pk.toMeters(pkEnd) < Pk.toMeters(pkStart);

    const ADVs = advs ?? this.projectADVs;
    // .filter((adv) => adv.attributes?.maneuverOrientation === ManeuverOrientation.DEFLECT);

    const path: TTxPath[] = [];

    let currentPk = pkStart;
    let currentTrack = trackId;

    while (
      isRightToLeft
        ? currentPk.kilometer > pkEnd.kilometer || (currentPk.kilometer === pkEnd.kilometer && currentPk.meter > pkEnd.meter)
        : currentPk.kilometer < pkEnd.kilometer || (currentPk.kilometer === pkEnd.kilometer && currentPk.meter < pkEnd.meter)
      ) {
      if (!currentTrack || !currentPk) return [];

      const trackADVs = ADVs.filter((a) => {
        if (!a.tracks.includes(currentTrack)) return false;
        if (!a.pkEnd) return false;

        const advPk = a.tracks.indexOf(currentTrack) === 0 ? a.pkStart : a.pkEnd;

        const currentPkStart = isRightToLeft ? pkEnd : currentPk;
        const currentPkEnd = isRightToLeft ? currentPk : pkEnd;

        return Pk.isPkBetweenPks(advPk, currentPkStart, currentPkEnd);
      });

      let closestADV: TrackElement | undefined = undefined;

      for (const advIndex in trackADVs) {
        const adv = trackADVs[advIndex];
        if (!path.some((t) => t.ADV && t.ADV.id === adv.id)) {
          const advPK = adv.tracks.indexOf(currentTrack) === 0 ? adv.pkStart : adv.pkEnd!;

          const closestADVPk = closestADV?.tracks.indexOf(currentTrack) === 0 ? closestADV?.pkStart : closestADV?.pkEnd;

          if (!closestADV) {
            closestADV = adv;
          } else if (isRightToLeft && !!closestADVPk && Pk.toMeters(advPK) > Pk.toMeters(closestADVPk)) {
            closestADV = adv;
          } else if (!isRightToLeft && !!closestADVPk && Pk.toMeters(closestADVPk) > Pk.toMeters(advPK)) {
            closestADV = adv;
          }
        }
      }

      if (closestADV) {
        const closestADVTrackIndex = closestADV.tracks.indexOf(currentTrack);

        const advPkStart = closestADVTrackIndex === 0 ? closestADV.pkStart : closestADV.pkEnd!;
        const advPkEnd = closestADVTrackIndex === 0 ? closestADV.pkEnd! : closestADV.pkStart;

        path.push({
          pkStart: currentPk,
          pkEnd: advPkStart,
          trackId: currentTrack,
          ADV: closestADV,
        });

        if (
          closestADV.attributes?.maneuverOrientation === ManeuverOrientation.DEFLECT &&
          (isRightToLeft ? Pk.toMeters(advPkStart) > Pk.toMeters(advPkEnd) : Pk.toMeters(advPkStart) < Pk.toMeters(advPkEnd))
        ) {
          currentPk = advPkEnd;
          currentTrack = closestADVTrackIndex === 0 ? closestADV.tracks[1] : closestADV.tracks[0];
        } else {
          currentPk = advPkStart;
        }
      } else {
        path.push({
          pkStart: currentPk,
          pkEnd: pkEnd,
          trackId: currentTrack,
        });

        currentPk = pkEnd;
      }
    }

    return path;
  };

  checkIfElementInTTxPath = (path: TTxPath[], pkStart: Pk, pkEnd?: Pk, tracks?: ID[]): boolean => {
    return path.some((p) => {
      const isRightToLeft = Pk.toMeters(p.pkEnd) < Pk.toMeters(p.pkStart);

      const pPkStart = isRightToLeft ? p.pkEnd : p.pkStart;
      const pPkEnd = isRightToLeft ? p.pkStart : p.pkEnd;

      if (!pkEnd) {
        return Pk.isPkBetweenPks(pkStart, pPkStart, pPkEnd) && (!tracks || tracks.includes(p.trackId));
      }

      return (
        (Pk.isPkBetweenPks(pkStart, pPkStart, pPkEnd) ||
          Pk.isPkBetweenPks(pkEnd, pPkStart, pPkEnd) ||
          Pk.isPkBetweenPks(pPkStart, pkStart, pkEnd) ||
          Pk.isPkBetweenPks(pPkEnd, pkStart, pkEnd)) &&
        (!tracks || tracks.includes(p.trackId))
      );
    });
  };

  projectCustomerById$ = (customerId: ID) => this.select("customers").pipe(map((customers) => customers.find((c) => c.id === customerId)));

  private sortCustomers = (customers: User[]) => customers.sort((c1, c2) => c1.lastname.localeCompare(c2.lastname));

  projectCustomers$ = this.select("customers").pipe(map(this.sortCustomers));
}

export const projectQuery = new ProjectQuery(projectStore);
