import {alpha, createTheme} from "@mui/material";

import fonts from "@fonts/index";

import {Colors} from "@utils/theme/constants.utils";

import palette from "@utils/theme/palette.theme";
import buttons from "@utils/theme/buttons.theme";
import inputs from "@utils/theme/inputs.theme";
import cards from "@utils/theme/cards.theme";
import tabs from "@utils/theme/tabs.theme";
import divider from "@utils/theme/divider.theme";
import typography from "@utils/theme/typography.theme";
import dialog from "@utils/theme/dialog.theme";
import menu from "@utils/theme/menu.theme";
import tooltip from "@utils/theme/tooltip.theme";
import accordions from "@utils/theme/accordions.theme";

const theme = createTheme({
  palette,
  typography: {
    fontFamily: "Axiforma, sans-serif",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `${fonts}
				html {
					height: 100%;
				}
				body {
					height: 100%;
					scrollbar-color: ${Colors.primary} ${alpha(Colors.primary, 0.2)};
					scrollbar-width: thin;
				}
				.scrollable {
					mask-image: linear-gradient(
						to bottom,
						transparent,
						white 20px,
						white calc(100% - 20px),
						transparent
					);
				}
				div#root {
					height: 100%;
				}
				*::-webkit-scrollbar {
					height: 6px;
					width: 6px;
				}
				*::-webkit-scrollbar-track {
					background-color: ${alpha(Colors.primary, 0.2)};
					border-radius: 3px;
					margin: 15px;
				}
				*::-webkit-scrollbar-thumb {
					background-color: ${Colors.primary};
					border-radius: 3px;
				}`,
    },
    ...typography,
    ...inputs,
    ...buttons,
    ...cards,
    ...tabs,
    ...divider,
    ...dialog,
    ...menu,
    ...tooltip,
    ...accordions,
  },
});

export default theme;
