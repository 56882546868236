import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, projectQuery, TrackElement} from "@store/project";
import {ID} from "@datorama/akita";
import {DispatchTypeEnum, TTxDispatchType} from '@store/dispatches/dispatch.model';

interface checkTTXPathIncompleteParams {
  fromDispatch?: boolean;
  dispatchType?: TTxDispatchType;
  trackElementId?: ID;
  trackIds: ID[];
  pkStart: Pk;
  finalPk?: Pk;
  finalTrack?: ID;
  ADVs: TrackElement[];
}

/* Movement delivery must have an available TTX path */
const checkTTXPathIncomplete = (params: checkTTXPathIncompleteParams): IncompatibilityError[] => {
  const {fromDispatch, dispatchType, trackElementId, trackIds, pkStart, finalPk, finalTrack, ADVs} = params;

  if (!finalPk || !finalTrack
    || (fromDispatch && dispatchType && ![
      DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE,
      DispatchTypeEnum.MOVEMENT_ENGAGEMENT,
    ].includes(dispatchType))) return [];

  const TTxPath = projectQuery.getTTxPath(pkStart, finalPk, trackIds[0], ADVs);

  const lastPath = TTxPath[TTxPath.length - 1];

  if (!lastPath || Pk.toMeters(lastPath.pkEnd) !== Pk.toMeters(finalPk) || lastPath.trackId !== finalTrack) {
    return [{
      error: IncompatibilityErrorEnum.TTX_PATH_INCOMPLETE,
      trackElementId,
      concernedTrackElementIds: [],
    }]
  }

  return [];
};

export default checkTTXPathIncomplete;