import React, {useEffect, useState} from "react";
import {Button, CircularProgress} from "@mui/material";
import {withStyles, WithStyles} from "@mui/styles";
import {useSnackbar} from "notistack";

import {User, usersService} from "@store/users";

import ModalComponent from "@components/modals/Modal.component";
import SearchBar from "@components/textField/SearchBar.component";
import UserCard from "@components/user/User.card";

import style from "./selectUser.style";
import {Observable} from "rxjs";

interface SelectUserModalProps extends WithStyles<typeof style> {
  button?: string;
  getUsersByRole: Observable<User[]>;
  handleAdd: (user: User) => void;
  handleClose: () => void;
  search: string;
  title: string;
  usersDisabled?: User[];
}

const SelectUserModal = (props: SelectUserModalProps) => {
  const {button, classes, getUsersByRole, handleAdd, handleClose, title, usersDisabled} = props;

  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(true);

  const [search, setSearch] = useState("");

  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    usersService.getUsers().subscribe({
      next: () => setLoading(false),
      error: (err) => enqueueSnackbar(err.text, err.options),
    });

    const _users$ = getUsersByRole.subscribe(setUsers);

    return () => {
      _users$.unsubscribe();
    };
  }, [enqueueSnackbar, getUsersByRole]);

  return (
    <ModalComponent
      maxWidth={false}
      fullWidth={false}
      scroll="body"
      title={title}
      handleClose={handleClose}
      content={(
        <div className={classes.container}>
          <SearchBar
            handleChange={setSearch}
            placeholder={search}/>
          <div className={`scrollable ${classes.usersContainer}`}>
            {loading ? (
              <div className={classes.loadingContainer}>
                <CircularProgress/>
              </div>
            ) : (
              <div className={classes.gridContainer}>
                {users.filter((user) => {
                  const searchRegexp = new RegExp(search, 'i');
                  return !search || user.firstname.match(searchRegexp) || user.lastname.match(searchRegexp);
                }).map((user) => (
                  <UserCard
                    key={user.id}
                    variant="outlined"
                    selected={selectedUser?.id === user.id}
                    disabled={usersDisabled?.some((u) => user.id === u.id)}
                    handleClick={() => setSelectedUser(selectedUser?.id === user.id ? undefined : user)}
                    user={user}/>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      actions={(
        <Button
          variant="contained"
          color="primary"
          disabled={loading || !selectedUser}
          onClick={() => {
            handleAdd(selectedUser!);
            handleClose();
          }}>
          {button}
        </Button>
      )}
    />
  );
};

export default withStyles(style)(SelectUserModal);
