import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import ModalComponent from "@components/modals/Modal.component";
import {Button, Stack} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import {finalize, Observable} from "rxjs";

interface ConfirmCodeModalProps {
  handleClose: () => void;
  handleConfirm: (code: string) => Observable<any>;
  handleSuccess: () => void;
}

const ConfirmCodeModal = (props: ConfirmCodeModalProps) => {
  const {handleClose, handleConfirm, handleSuccess} = props;

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [code, setCode] = useState("");

  const confirmDisabled = !code || loading;

  const handleDisplay = () => {
    setLoading(true);
    handleConfirm(code)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => handleSuccess(),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (confirmDisabled) return;
    if (e.key === "Enter") {
      handleDisplay();
    }
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      maxWidth="sm"
      title={t("askCode.title")}
      description={!code ? t("askCode.description") : undefined}
      content={
        <Stack width="100%" pt="20px">
          <TextFieldComponent
            autoFocus
            label={t("askCode.code")}
            handleChange={setCode}
            onKeyDown={handleKeyDown}
            value={code}
            required
            type="password"
          />
        </Stack>
      }
      actions={
        <Button variant="contained" color="primary" disabled={confirmDisabled} onClick={handleDisplay}>
          {t("global.validate")}
        </Button>
      }
    />
  );
};

export default ConfirmCodeModal;
