import {createStyles} from "@mui/styles";

const classes = createStyles({
	container: {
		overflow: 'hidden',
		"& > :first-child": {
			marginBottom: 10,
		},
	},
	usersContainer: {
		height: "50vh",
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: '10px 15px',
		width: 'calc(156px * 5 + 23px * 4 + 15px * 2)',
	},
	gridContainer: {
		columnGap: 23,
		display: 'grid',
		gridTemplateColumns: 'repeat(5, 1fr)',
		gridTemplateRows: 'fit-content',
		rowGap: 23,
	},
	loadingContainer: {
		alignContent: 'center',
		columnGap: 23,
		display: 'grid',
		gridTemplateColumns: '1fr',
		justifyItems: 'center',
		rowGap: 23,
		height: '100%',
		width: '100%',
	},
});

export default classes;
