import React from "react";

import {TOOLTIP_HEIGHT, TRACK_ELEMENT_HEIGHT, TrackElement, TrackElementType} from "@store/project";
import TooltipDetails from "@screens/auth/common/schema/elements/TooltipDetails";
import {bindHover, usePopupState} from "material-ui-popup-state/hooks";

interface PRSElementProps {
  handleClick?: () => void;
  showTooltip?: boolean;
  tooltipDirection?: 'start' | 'end';
  trackElement: TrackElement;
  x: number;
}

const PRSElement = (props: PRSElementProps) => {
  const {handleClick, showTooltip, tooltipDirection, trackElement, x} = props;

  const tooltipState = usePopupState({variant: 'popover', popupId: `tooltip-${trackElement.id}`});

  const PRSHeight = TOOLTIP_HEIGHT * 0.75;

  const textCooX = x + (tooltipDirection === "start" ? -1 : 1) * (PRSHeight / 2 + 8);

  return (
    <g>
      <g
        style={{cursor: handleClick ? 'pointer' : undefined}}
        onClick={handleClick}>
        <circle
          cx={x} cy={TOOLTIP_HEIGHT / 2} r={PRSHeight / 2}
          fill={TrackElementType.getColor(trackElement)}
          {...bindHover(tooltipState)}/>
        <image
          xlinkHref={TrackElementType.getIcon(trackElement.type)}
          x={x - 8.75}
          y={TOOLTIP_HEIGHT / 2 - 8.75}
          {...bindHover(tooltipState)}/>
      </g>
      {(showTooltip || tooltipState.isOpen) && (
        <TooltipDetails
          trackElement={trackElement}
          position={tooltipDirection}
          x={textCooX} y={TOOLTIP_HEIGHT / 2 + TRACK_ELEMENT_HEIGHT / 2}/>
      )}
    </g>
  );
}

export default PRSElement;