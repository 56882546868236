import {EntityState, EntityStore, ID, StoreConfig} from '@datorama/akita';

import {User} from "@store/users/users.model";

export interface UsersState extends EntityState<User, ID> {
}

@StoreConfig({name: 'users', resettable: true})
export class UsersStore extends EntityStore<UsersState, User> {
}

export const usersStore = new UsersStore();
