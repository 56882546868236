import React, {useState} from "react";
import {Box, Button, Grid, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";

import {CreateOrUpdateTrackElement, PK_INPUT_REGEXP, TrackElementType} from "@store/project";

import {trackElementValidation} from "@screens/auth/common/schema/forms/trackElements/forms.yup";
import {FieldErrors} from "@utils/yup.utils";

import ModalComponent from "@components/modals/Modal.component";
import SelectComponent from "@components/select/Select.component";
import TextFieldComponent from "@components/textField/TextField.component";

import useIncompatibilitiesAcceptation from "../../components/IncompatibilitiesAcceptation.component";
import { IncompatibilityError } from "@utils/incompatibilities/incompatibility.utils";

interface TrainTrackFormProps {
  description: string;
  handleClose: () => void;
  handleDelete?: () => void;
  handlePrevious?: () => void;
  handleValidate: (incompatibilities?: IncompatibilityError[]) => void;
  loading?: boolean;
  setTrackElement: (trackElement: CreateOrUpdateTrackElement) => void;
  title: string;
  trackElement: CreateOrUpdateTrackElement;
}

const TrainTrackForm = (props: TrainTrackFormProps) => {
  const {
    description,
    handleClose,
    handleDelete,
    handlePrevious,
    handleValidate,
    loading,
    setTrackElement,
    title,
    trackElement
  } = props;

  const {t} = useTranslation();

  const [errors, setErrors] = useState<FieldErrors>({});

  const {IncompatibilitiesAcceptation, disabledByIncompatibilities, incompatibilities} = useIncompatibilitiesAcceptation({trackElement, creation: !handleDelete});

  const updateTrackElement = (name: string) => (value: any) => setTrackElement({...trackElement, [name]: value});
  const updateAttributes = (name: string) => (value: any) =>
    setTrackElement({
      ...trackElement,
      attributes: {...trackElement.attributes, [name]: value},
    });

  const handleAdd = () => {
    const errors = trackElementValidation(trackElement);

    setErrors(errors);
    if (!Object.keys(errors).length) handleValidate(incompatibilities);
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      title={title}
      description={description}
      content={
        <Box width="450px">
          <Grid container columnSpacing={2.5} rowSpacing={1.5}>
            <Grid item xs={12}>
              <SelectComponent
                label={t("schema.trainTrack.addType")}
                placeholder={t("schema.trainTrack.addTypePlaceHolder")}
                handleChange={(type) => {
                  updateTrackElement("type")(type);
                  setErrors({});
                }}
                value={trackElement.type || ""}
                error={errors.type}
                items={TrackElementType.trainTrackTypes}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldComponent
                label={t("schema.trainTrack.lineId")}
                handleChange={updateAttributes("lineId")}
                value={trackElement.attributes?.lineId || ""}
                error={errors["attributes.lineId"]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldComponent
                label={t("schema.trainTrack.name")}
                handleChange={updateAttributes("name")}
                value={trackElement.attributes?.name || ""}
                error={errors["attributes.name"]}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldComponent
                label={t("schema.trainTrack.startPK")}
                handleChange={updateTrackElement("pkStart")}
                pattern={PK_INPUT_REGEXP}
                value={trackElement.pkStart || ""}
                error={errors.pkStart}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldComponent
                label={t("schema.trainTrack.endPK")}
                handleChange={updateTrackElement("pkEnd")}
                pattern={PK_INPUT_REGEXP}
                value={trackElement.pkEnd || ""}
                error={errors.pkEnd}
              />
            </Grid>
          </Grid>
          {IncompatibilitiesAcceptation && (
            <Stack pt="20px">
              <IncompatibilitiesAcceptation/>
            </Stack>
          )}
        </Box>
      }
      actions={
        <>
          {!!handlePrevious && (
            <Button variant="outlined" color="primary" disabled={loading} onClick={handlePrevious}>
              {t("global.previous")}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={loading || disabledByIncompatibilities}
            onClick={handleAdd}>
            {t(handleDelete ? "global.modify" : "global.add")}
          </Button>
          {!!handleDelete && (
            <Button variant="contained" color="error" disabled={loading} onClick={handleDelete}>
              {t("global.delete")}
            </Button>
          )}
        </>
      }
    />
  );
};

export default TrainTrackForm;
