import React, {useEffect} from 'react';
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {DispatchTypeEnum, ManeuverOrientation} from "@store/dispatches/dispatch.model";
import {Grid} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import SelectComponent from "@components/select/Select.component";
import DatePickerComponent from "@components/datePicker/DatePicker.component";

const ManeuverTrackChangeForm = (props: DispatchFormGlobalProps) => {
  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const updateDispatchContent = (name: string) => (value: any) => setDispatch({
    ...dispatch,
    dispatch: {...dispatch.dispatch, [name]: value}
  });

  useEffect(() => {
    setDispatch({
      type: DispatchTypeEnum.MANEUVER_TRACK_CHANGE,
      dispatch: {
        trackChangeNbr: trackElement?.attributes?.name,
        date: new Date(),
        hour: new Date(),
      },
    })
  }, []);

  return (
    <div>
      <Grid container direction={"row"} columnSpacing={2.5} rowSpacing={1.5}>
        <Grid item xs={12}>
          <TextFieldComponent
            label={"N° de l'AdV"}
            readOnly
            value={dispatch?.dispatch?.trackChangeNbr || ''}
            handleChange={updateDispatchContent("trackChangeNbr")}
            error={errors["trackChangeNbr"]}/>
        </Grid>

        <Grid item xs={6}>
          <TextFieldComponent
            label={"Nom de l'aiguilleur"}
            readOnly={!editable}
            handleChange={updateDispatchContent("from")}
            value={dispatch?.dispatch?.from || ""}
            error={errors["from"]}/>
        </Grid>

        <Grid item xs={6}>
          <SelectComponent
            label={"Sens de Manœuvre"}
            readOnly={!editable}
            items={ManeuverOrientation.selectItems}
            value={dispatch?.dispatch?.maneuverOrientation || ""}
            handleChange={updateDispatchContent("maneuverOrientation")}
            error={errors["maneuverOrientation"]}/>
        </Grid>

        <Grid item xs={6}>
          <DatePickerComponent
            label={"Le"}
            readOnly={!editable}
            value={dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("date")}
            error={errors["date"]}/>
        </Grid>
        <Grid item xs={6}>
          <TimePickerComponent
            label={"à"}
            readOnly={!editable}
            value={editable ? dispatch?.dispatch?.hour : dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("hour")}
            error={errors["hour"]}/>
        </Grid>

        <Grid item xs={12}>
          <TextFieldComponent
            multiline
            rows={4}
            label={"Informations complémentaires"}
            handleChange={updateDispatchContent("additionalInformation")}
            value={dispatch?.dispatch?.additionalInformation || ""}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default ManeuverTrackChangeForm;
