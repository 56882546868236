import {ID} from "@datorama/akita";

export const MAX_FILE_SIZE = 2; // MB

export interface UploadedFile {
  fileId: ID;
  fileName: string;
}


