import {QueryEntity} from '@datorama/akita';
import {map} from "rxjs";

import {UsersState, usersStore, UsersStore} from "@store/users/users.store";
import {Role, User} from "@store/users/users.model";

export class UsersQuery extends QueryEntity<UsersState, User> {
  constructor(protected store: UsersStore) {
    super(store);
  }

  usersByRole$ = (role: Role) => this.selectAll().pipe(
    map((users) => users.filter((u) => u.role === role)),
    map(this.sortUsers),
  );

  private sortUsers = (users: User[]) => users.sort((u1, u2) => `${u1.lastname} ${u1.firstname}`.localeCompare(`${u2.lastname} ${u2.firstname}`));

  users$ = this.selectAll().pipe(
    map(this.sortUsers),
  );

}

export const usersQuery = new UsersQuery(usersStore);
