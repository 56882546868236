import {alpha, Components} from "@mui/material";

import {Colors} from "@utils/theme/constants.utils";

const divider: Components = {
	MuiDivider: {
		styleOverrides: {
			root: {
				borderColor: alpha(Colors.primary, 0.05),
			},
		},
	},
};

export default divider;
