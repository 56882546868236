import React, {useEffect, useState} from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {withStyles, WithStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

import Table from "@components/table/Table";
import {ordersQuery} from "@store/dispatches/orders.query";
import {TodayDispatchHistory} from "@store/dispatches/dispatch.model";
import {ordersService} from "@store/dispatches/orders.service";
import {Button, Stack} from "@mui/material";
import {dispatchHistoryColumns} from "./table.utils";

import style from "./TodayDispatchesTab.style";
import {dispatchFiltersQuery} from "@store/dispatches/filters.query";
import {DispatchFiltersState} from "@store/dispatches/filters.store";
import {filesService} from "@store/files";
import {useSnackbar} from "notistack";
import dayjs from "dayjs";
import {projectQuery} from "@store/project";

interface DispatchesTabProps extends WithStyles<typeof style> {
}

const TodayDispatchesTab = (props: DispatchesTabProps) => {
  const {classes} = props;
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const {projectId} = useParams();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<DispatchFiltersState>({});

  const [dispatches, setDispatches] = useState<TodayDispatchHistory>();

  useEffect(() => {
    if (projectId) {
      ordersService.getTodaysDispatches(projectId, page + 1, filters).subscribe(setDispatches);
    }
    const _loading$ = ordersQuery.selectLoading().subscribe(setLoading);

    return () => {
      _loading$.unsubscribe();
    };
  }, [page, filters]);

  useEffect(() => {
    const _filters$ = dispatchFiltersQuery.filters$.subscribe(setFilters);

    return () => {
      _filters$.unsubscribe();
    };
  }, []);

  const handleDownloadCSV = () => {
    if (!projectId) return;
    ordersService.getTodayDispatchCSV(projectId, filters).subscribe({
      next: (res) => {
        filesService.downloadFile(res, `today-dispatch_${projectQuery.projectId}_${dayjs().format("DD-MM-YYYY")}.csv`);
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  return (
    <div className={classes.tableContainer}>
      <Stack direction="row" spacing={2} className={classes.tableButtons}>
        <Button variant="contained" size="small" color="primary" onClick={handleDownloadCSV}
                startIcon={<img src="/images/export_icon.svg"/>}>
          {t("dispatches.export")}
        </Button>
      </Stack>
      <Table
        loading={loading}
        columns={dispatchHistoryColumns(projectQuery.isProjectTypeNew)}
        pageCount={dispatches?.meta.pageCount || 1}
        handlePageChange={setPage}
        page={page}
        data={dispatches?.data || []}
        onRowClick={(params) => {
          if (params.row.id) navigate(`${params.row.orderId}/${params.row.id}`);
        }}
      />
      <Outlet/>
    </div>
  );
};

export default withStyles(style)(TodayDispatchesTab);
