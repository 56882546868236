import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {DispatchTypeEnum, ZTDispatchType} from "@store/dispatches/dispatch.model";
import {ID} from "@datorama/akita";

interface CheckOutZT20mPNParams {
  trackElementId?: ID;
  fromDispatch?: boolean;
  pkStart: Pk;
  pkEnd: Pk;
  PNs: TrackElement[];
  status?: ZTDispatchType;
}

/* Should not be able to set the PKs of a ZT less than 20 meters of a none encompassed PN */
const checkOutZT20mPN = (params: CheckOutZT20mPNParams): IncompatibilityError[] => {
  const {fromDispatch, pkStart, pkEnd, PNs, status, trackElementId} = params;

  const PNsLessThan20m = PNs.filter(
    (t) =>
      (Pk.isAfterPk(t.pkStart, Pk.addMeters(pkStart, -20)) && Pk.isBeforePk(t.pkStart, pkStart)) ||
      (Pk.isAfterPk(t.pkStart, pkEnd) && Pk.isBeforePk(t.pkStart, Pk.addMeters(pkEnd, 20)))
  );

  if ((!fromDispatch || status === DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA) && PNsLessThan20m.length) {
    return PNsLessThan20m.map((PN) => ({
      error: IncompatibilityErrorEnum.OUT_ZT_20M_PN,
      trackElementId,
      concernedTrackElementIds: [PN.id],
    }));
  }

  return [];
};

export default checkOutZT20mPN;
