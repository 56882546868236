import React, {useEffect, useState} from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Outlet, useNavigate} from "react-router-dom";

import {sessionQuery} from "@store/session";
import {Role} from "@store/users";

import {RoutesProjectInfo, useRouteMatch} from "@utils/routes.utils";

import TabsComponent from "@components/tabs/Tabs.component";
import {projectQuery} from "@store/project";
import {ProjectLight} from "@store/projects";
import {ordersService} from "@store/dispatches/orders.service";

import dayjs from "dayjs";
import {filesService} from "@store/files";
import {useSnackbar} from "notistack";

const ProjectInfoScreen = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const currentPath = useRouteMatch(RoutesProjectInfo.routes);

  const [project, setProject] = useState<ProjectLight>();

  useEffect(() => {
    const _project$ = projectQuery.projectInfo$.subscribe(setProject);

    return () => {
      _project$.unsubscribe();
    };
  }, []);

  const handleDownloadAllDispatchesCSV = () => {
    if (!project?.id) return;
    ordersService.getAllDispatchCSV(project.id).subscribe({
      next: (res) => {
        filesService.downloadFile(res, `all-dispatch_${projectQuery.projectId}_${dayjs().format("DD-MM-YYYY")}.csv`);
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  if (!project) return null;

  return (
    <Stack width="100%" height="100%" pt="30px">
      <Stack direction="row" alignItems="center" justifyContent="space-between" height="48px">
        <Stack height="48px" justifyContent="center">
          <Typography fontSize={26} fontWeight="900">
            {projectQuery.projectInfo.name || "-"}
          </Typography>
          <Stack direction="row" alignItems="center" spacing="3px">
            <Typography textAlign="center" fontSize={14} fontWeight="700">
              {t("schema.title", {lineId: projectQuery.projectInfo.lineId})} -
            </Typography>
            <Typography textAlign="center" fontSize={14} fontWeight="400">
              {projectQuery.projectInfo.from || "-"} / {projectQuery.projectInfo.to || "-"}
            </Typography>
          </Stack>
        </Stack>
        {sessionQuery.hasSufficientRoleOrAbove(Role.RELF) && (
          <Button
            variant="contained"
            startIcon={<img alt="export" src="/images/export_icon.svg"/>}
            color="primary"
            onClick={handleDownloadAllDispatchesCSV}>
            {t("dispatches.exportDispatches")}
          </Button>
        )}
      </Stack>
      {sessionQuery.hasSufficientRoleOrAbove(Role.OS_R) && (
        <Box mb="10px" mt="30px">
          <TabsComponent
            value={RoutesProjectInfo.routes.indexOf(currentPath as RoutesProjectInfo)}
            handleChange={(value) => navigate(RoutesProjectInfo.routes[value])}
            tabs={[t("projectDetails.projectInfo.tabs.projectInfo"), t("projectDetails.projectInfo.tabs.clients")]}
          />
        </Box>
      )}
      <Outlet/>
    </Stack>
  );
};

export default ProjectInfoScreen;
