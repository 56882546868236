import {ID} from "@datorama/akita";
import _ from 'lodash';

import {Pk, TrackElement} from "@store/project";

import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";

interface CheckNoTTxOnNotPassableZTParams {
  fromDispatch?: boolean;
  trackElementId?: ID;
  pkStart: Pk;
  length: number;
  ZTs: TrackElement[];
}

/* Should not be able to set TTx Pk on a not passable ZT */
const checkNoTTxOnNotPassableZT = (params: CheckNoTTxOnNotPassableZTParams): IncompatibilityError[] => {
  const {fromDispatch, trackElementId, pkStart, length, ZTs} = params;

  const pkEnd = Pk.addMeters(pkStart, length);

  if (!fromDispatch) {
    const TTxInNotPassableZTs = ZTs.filter((t) => !!t.pkEnd &&
      (Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd)
        || Pk.isPkBetweenPks(pkEnd, t.pkStart, t.pkEnd)
        || Pk.isPkBetweenPks(t.pkStart, pkStart, pkEnd)
        || Pk.isPkBetweenPks(t.pkEnd, pkStart, pkEnd)
      ) && !_.isEqual(pkStart, t.pkEnd) && !_.isEqual(pkEnd, t.pkStart) && !!t.attributes?.isNotPassable);

    if (!!TTxInNotPassableZTs.length) {
      return [
        {
          error: IncompatibilityErrorEnum.NO_TTX_ON_NOT_PASSABLE_ZT,
          trackElementId,
          concernedTrackElementIds: TTxInNotPassableZTs.map((ZT) => ZT.id),
        },
      ];
    }
  }

  return [];
};

export default checkNoTTxOnNotPassableZT;