import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {ID} from "@datorama/akita";

interface CheckNoCCIOutZTParams {
  trackElementId?: ID;
  pkStart: Pk;
  pkEnd: Pk;
  ZTs: TrackElement[];
}

/* Should not be able to create / update a CCI outside a ZT */
const checkNoCCIOutZT = (params: CheckNoCCIOutZTParams): IncompatibilityError[] => {
  const {pkStart, pkEnd, trackElementId, ZTs} = params;

  const CCIInZTs = ZTs.filter((t) => !!t.pkEnd && Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd) && Pk.isPkBetweenPks(pkEnd, t.pkStart, t.pkEnd));

  if (!CCIInZTs.length) {
    return [
      {
        error: IncompatibilityErrorEnum.NO_CCI_OUT_ZT,
        trackElementId,
        concernedTrackElementIds: [],
      },
    ];
  }

  return [];
};

export default checkNoCCIOutZT;
