import React, {useState} from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

import SchemaComponent from "@screens/auth/common/schema/Schema.component";
import ModalComponent from "@components/modals/Modal.component";

interface Step2FormProps {
  handleClose: () => void;
  handleFinish: () => void;
}

const Step2Form = (props: Step2FormProps) => {
  const {handleClose, handleFinish} = props;

  const {t} = useTranslation();

  const [showSchema, setShowSchema] = useState(false);

  return (
    <ModalComponent
      maxWidth={showSchema ? false : undefined}
      fullHeight={showSchema}
      handleClose={handleClose}
      title={t("projects.create.title")}
      content={(
        <Stack flex={1} width="100%">
          <Stack mt="40px" alignItems="center" spacing={1}>
            <Typography fontSize={16} fontWeight="800">
              {t("global.step", {step: 2})}
            </Typography>
            <Typography fontSize={14} fontWeight="400">
              {t(`projects.create.stepDescription.2`)}
            </Typography>
          </Stack>
          <Box flex={1} width="100%" display="flex" alignItems="flex-start" justifyContent="center">
            {showSchema ? (
              <Stack height="100%" mt="5px" width="100%" spacing={4}>
                <Typography fontSize={13} fontWeight="400" textAlign="center" sx={{opacity: 0.5}}>
                  {t("projects.create.step2.schemaDescription")}
                </Typography>
                <SchemaComponent isCreation/>
              </Stack>
            ) : (
              <Stack height="100%" justifyContent="center" alignItems="center" spacing={1.5} p="60px 0 30px">
                {/*<Button variant="contained" color="primary" size="large">*/}
                {/*	{t("Importer depuis l'API SNCF")}*/}
                {/*</Button>*/}
                {/*<Typography fontSize={14} fontWeight="700">{t("global.or")}</Typography>*/}
                <Button variant="contained" color="primary" size="large" onClick={() => setShowSchema(true)}>
                  {t("projects.create.step2.createSchema")}
                </Button>
                {/*<Typography fontSize={14} fontWeight="700">{t("global.or")}</Typography>*/}
                {/*<Button variant="contained" color="primary" size="large">*/}
                {/*	{t("Importer un fichier CSV")}*/}
                {/*</Button>*/}
                {/*<Button variant="text" color="primary" size="small">*/}
                {/*	{t("Télécharger le fichier model ici")}*/}
                {/*</Button>*/}
              </Stack>
            )}
          </Box>
        </Stack>
      )}
      actions={(
        <Button
          variant="contained"
          color="primary"
          disabled={!showSchema}
          onClick={handleFinish}>
          {t("global.finish")}
        </Button>
      )}/>
  );
}

export default Step2Form;
