import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Grid, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {Outlet, useNavigate} from "react-router-dom";

import {ProjectFilters, ProjectLight, projectsQuery, projectsService} from "@store/projects";

import TabsComponent from "@components/tabs/Tabs.component";
import ProjectCard from "@components/projects/Project.card";
import {sessionQuery} from "@store/session";
import {Role} from "@store/users";

const ProjectsScreen = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const [filters, setFilters] = useState<ProjectFilters>({isPending: false});

  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState<ProjectLight[]>([]);

  useEffect(() => {
    projectsService.getProjects().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });

    const _projects$ = projectsQuery.projects$.subscribe(setProjects);

    const _loading$ = projectsQuery.selectLoading().subscribe(setLoading);

    return () => {
      _projects$.unsubscribe();
      _loading$.unsubscribe();
    };
  }, [enqueueSnackbar]);

  const updateFilters = (name: string) => (value: any) => setFilters({...filters, [name]: value});

  return (
    <Stack width="100%" pt="30px">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack spacing={0.5}>
          <Typography fontSize={26} fontWeight="900">{t("projects.title")}</Typography>
          <Typography fontSize={14} fontWeight="400" sx={{opacity: 0.3}}>
            {t(`projects.description.${sessionQuery.hasSufficientRoleOrAbove(Role.OS_C) ? 'OS_C' : 'other'}`)}
          </Typography>
        </Stack>
        {sessionQuery.hasSufficientRoleOrAbove(Role.OS_R) && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("create")}
            startIcon={<img alt="" src="/images/add_icon_white.svg"/>}>
            {t("projects.createProject")}
          </Button>
        )}
      </Stack>
      <Box mt="30px" mb="10px">
        <TabsComponent
          value={+filters.isPending}
          handleChange={(value) => updateFilters("isPending")(Boolean(value))}
          tabs={[t("projects.openedProjects"), t("projects.closedProjects")]
            .filter((_, index) => sessionQuery.hasAnyOfRoles(Role.RELF) ? index !== 1 : true)}/>
      </Box>
      {loading ? (
        <Box height="100%" width="100%" display="flex" alignItems="center" mb="20px" justifyContent="center">
          <CircularProgress size={50}/>
        </Box>
      ) : (
        <Box className="scrollable" overflow="auto" pr="5px" pt="20px" pb="20px">
          <Grid container spacing={2}>
            {projects
              .filter((project) => project.isPending !== filters.isPending)
              .map((project) => (
                <Grid key={project.id} item xs={3}>
                  <ProjectCard project={project}/>
                </Grid>
              ))
            }
          </Grid>
        </Box>
      )}
      <Outlet/>
    </Stack>
  );
};

export default ProjectsScreen;
