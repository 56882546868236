import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {ID} from '@datorama/akita';

interface checkNoTTXBiggerThanZFParams {
  trackElementId?: ID;
  pkStart: Pk;
  length: number;
  ZFs: TrackElement[];
}

/* Should not be able to put on track a TTX bigger than the ZF it is in */
const checkNoTTXBiggerThanZF = (params: checkNoTTXBiggerThanZFParams): IncompatibilityError[] => {
  const {trackElementId, pkStart, length, ZFs} = params;

  const pkEnd = Pk.addMeters(pkStart, +length);

  const TTXBiggerThanZFs = ZFs.filter((t) => !!t.pkEnd
    && (Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd) || Pk.isPkBetweenPks(pkEnd, t.pkStart, t.pkEnd))
    && Pk.toMeters(t.pkEnd) - Pk.toMeters(t.pkStart) < +length
  );

  if (TTXBiggerThanZFs.length) {
    return TTXBiggerThanZFs.map((ZF) => ({
      error: IncompatibilityErrorEnum.NO_TTX_BIGGER_THAN_ZF,
      trackElementId,
      concernedTrackElementIds: [ZF.id],
    }));
  }

  return [];
}

export default checkNoTTXBiggerThanZF;