import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {DispatchTypeEnum, ZTDispatchType} from "@store/dispatches/dispatch.model";
import {ID} from "@datorama/akita";

interface CheckInZT20mActivityParams {
  trackElementId?: ID;
  activities: TrackElement[];
  fromDispatch?: boolean;
  pkStart: Pk;
  pkEnd: Pk;
  status?: ZTDispatchType;
}

/* Should not be able to set the PKs of a ZT less than 20 meters of a none encompassed activity */
const checkInZT20mActivity = (params: CheckInZT20mActivityParams): IncompatibilityError[] => {
  const {activities, fromDispatch, pkStart, pkEnd, status, trackElementId} = params;

  const activitiesLessThan20m = activities.filter(
    (t) =>
      !!t.pkEnd &&
      Pk.isPkBetweenPks(t.pkStart, pkStart, pkEnd) &&
      Pk.isPkBetweenPks(t.pkEnd, pkStart, pkEnd) &&
      (!Pk.isPkBetweenPks(t.pkStart, Pk.addMeters(pkStart, 20), Pk.addMeters(pkEnd, -20)) ||
        !Pk.isPkBetweenPks(t.pkEnd, Pk.addMeters(pkStart, 20), Pk.addMeters(pkEnd, -20)))
  );

  if ((!fromDispatch || status === DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA) && activitiesLessThan20m.length) {
    return activitiesLessThan20m.map((ACT) => ({
      error: IncompatibilityErrorEnum.IN_ZT_20M_ACTIVITY,
      trackElementId,
      concernedTrackElementIds: [ACT.id],
    }));
  }

  return [];
};

export default checkInZT20mActivity;
