import React, {useEffect, useState} from "react";
import {Box, Dialog, DialogContent, Stack} from "@mui/material";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {switchMap} from "rxjs";

import {RoutesAuth} from "@utils/routes.utils";

import LeftBarComponent from "@components/leftBar/LeftBar.component";
import {projectService} from "@store/project";

const ProjectDetailsModal = () => {
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const {projectId} = useParams();

  const handleClose = () => navigate("..");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    projectService.getProjectInfoById(projectId || "")
      .pipe(
        switchMap(() => projectService.getProjectTrackElements()),
        switchMap(() => projectService.getProjectClosedZTToday()),
      )
      .subscribe({
        next: () => setLoading(false),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });

    return () => projectService.unsetActiveProject();
  }, [enqueueSnackbar, projectId]);

  if (loading) return null;

  return (
    <Dialog open={true} fullScreen aria-label="project-details">
      <DialogContent>
        <Stack direction="row" height="100%" width="100%">
          <Box pb="20px" pr="20px">
            <LeftBarComponent
              handleClose={handleClose}
              routes={RoutesAuth.projectDetailsRoutes}/>
          </Box>
          <Box height="100%" width="100%">
            <Outlet/>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default ProjectDetailsModal;
