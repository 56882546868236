import _ from "lodash";

import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {DispatchTypeEnum, ZTDispatchType} from "@store/dispatches/dispatch.model";
import {ID} from "@datorama/akita";

interface CheckNoZTonCCIParams {
  trackElementId?: ID;
  fromDispatch?: boolean;
  pkStart: Pk;
  pkEnd: Pk;
  status?: ZTDispatchType;
  CCIs: TrackElement[];
}

/* Should not be able to set the PKs of a ZT on a CCI */
const checkNoZTonCCI = (params: CheckNoZTonCCIParams): IncompatibilityError[] => {
  const {fromDispatch, pkStart, pkEnd, status, CCIs, trackElementId} = params;

  const CCIsOnZT = CCIs.filter(
    (t) =>
      !!t.pkEnd &&
      (Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd) ||
        Pk.isPkBetweenPks(pkEnd, t.pkStart, t.pkEnd) ||
        Pk.isPkBetweenPks(t.pkStart, pkStart, pkEnd) ||
        Pk.isPkBetweenPks(t.pkEnd, pkStart, pkEnd)) &&
      !_.isEqual(pkStart, t.pkEnd) &&
      !_.isEqual(pkEnd, t.pkStart)
  );

  if ((!fromDispatch || status === DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA) && CCIsOnZT.length) {
    return CCIsOnZT.map((CCI) => ({
      error: IncompatibilityErrorEnum.NO_ZT_ON_CCI,
      trackElementId,
      concernedTrackElementIds: [CCI.id],
    }));
  }

  return [];
};

export default checkNoZTonCCI;
