import translationFR from "./fr/translations.json";

export enum Language {
	FR = "fr",
}

export const translations = {
	fr: {
		translation: translationFR,
	},
};
