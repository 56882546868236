import { createStyles } from "@mui/styles";

const classes = () => createStyles({
  tableContainer: {
    flex: 1,
    display: "flex",
    marginTop: "30px",
    height: "100%",
    position: "relative"
  },
  tableButtons: {
    position: "absolute",
    bottom: 40,
    right: 10,
    zIndex: 2
  }
})

export default classes;