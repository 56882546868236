import React, {useEffect, useState} from 'react';
import ModalComponent from "@components/modals/Modal.component";
import {Box, Button, Stack, Typography} from "@mui/material";
import TabsComponent from "@components/tabs/Tabs.component";
import {Pk, projectQuery, TrackElement, TrackElementType} from "@store/project";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {RoutesTrackElement} from "@utils/routes.utils";
import {Order} from "@store/dispatches/dispatch.model";
import {ordersService} from "@store/dispatches/orders.service";
import {ordersQuery} from "@store/dispatches/orders.query";
import InstructionsCard from "@components/orders/Order.card";
import CreateOrderModal from "@screens/auth/common/schema/forms/orders/CreateOrder.modal";
import {sessionQuery} from '@store/session';
import {Role} from '@store/users';

const SpecialElementOrdersModal = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const {trackElementId} = useParams();

  const [showCreateOrder, setShowCreateOrder] = useState(false);

  const [orders, setOrders] = useState<Order[]>([]);

  const [trackElement, setTrackElement] = useState<TrackElement>();

  useEffect(() => {
    const _trackElementId$ = projectQuery.projectTrackElementById$(trackElementId || "").subscribe(setTrackElement);

    if (trackElementId) {
      ordersService.getOrders(trackElementId).subscribe({
        error: err => enqueueSnackbar(err.text, err.options),
      })
    }

    const _orders$ = ordersQuery.ordersByTrackElementId$(trackElementId || '').subscribe(setOrders);

    return () => {
      _trackElementId$.unsubscribe();
      _orders$.unsubscribe();
    };
  }, [enqueueSnackbar, trackElementId]);

  const handleClose = () => navigate("../..");

  if (!trackElement) return null;

  const getModalTitle = () => {
    switch (trackElement.type) {
      case TrackElementType.WORK_ZONE:
        return t(`schema.workArea.name`, {name: trackElement.attributes?.name || "-"});
      case TrackElementType.WORK_TRAIN:
        return t("schema.workTrain.state.subtitle", {name: trackElement.attributes?.name || "-"});
      case TrackElementType.PN:
        return t("schema.pn.name", {name: trackElement.attributes?.name || "-"});
      case TrackElementType.TRAINING_ZONE:
        return t("schema.zone.ZFNb", {name: trackElement.attributes?.name || "-"})
      case TrackElementType.WORKSITE_MACHINE:
        return t("schema.worksiteMachine.worksiteMachine", {name: trackElement.attributes?.name || "-"});
      case TrackElementType.TRACK_CHANGE:
        return t("schema.trackDevice.types.trackChange", {name: trackElement.attributes?.name || "-"});
      default:
        return "-";
    }
  };

  const isZFEncompassedByZT =
    trackElement.type === TrackElementType.TRAINING_ZONE &&
    projectQuery.projectZT.some(
      (ZT) =>
        !!ZT.pkEnd &&
        !!trackElement.pkEnd &&
        Pk.isPkBetweenPks(trackElement.pkStart, ZT.pkStart, ZT.pkEnd) &&
        Pk.isPkBetweenPks(trackElement.pkEnd, ZT.pkStart, ZT.pkEnd)
    );

  return (
    <>
      <ModalComponent
        handleClose={handleClose}
        title={getModalTitle()}
        description={`${t("global.pk")} ${Pk.toString(trackElement.pkStart)}${trackElement.pkEnd ? ` - ${t("global.pk")} ${Pk.toString(trackElement.pkEnd)}` : ""}`}
        fullWidth
        content={(
          <Stack
            height="calc(80vh + 20px)" width="100%" mb="-20px"
            alignItems="center" justifyContent="start" overflow="hidden">
            <Box width="80%" my="20px">
              <TabsComponent
                value={1}
                handleChange={(value) => navigate(`../${RoutesTrackElement.routes[value]}`)}
                tabs={[t("dispatches.tabs.info"), t(`dispatches.tabs.${projectQuery.isProjectTypeNew ? "ordersAndDispatchesNew" : "ordersAndDispatches"}`)]}/>
            </Box>
            <Stack flex={2} alignItems="center" width="70%" spacing={1} marginTop="20px" overflow="hidden">
              <Typography textAlign="center" fontSize={16} fontWeight="400">
                {t("global.todayInLetters", {date: new Date()})}
              </Typography>
              <Stack width="100%" overflow="auto" className="scrollable" py="20px" spacing={5}>
                <Stack width="100%" spacing={1}>
                  <Typography fontSize={16} fontWeight="700" color="primary" textAlign="center">
                    {t("En cours")}
                  </Typography>
                  <Stack flex={1} width="100%" spacing={2}>
                    {orders.filter((o) => !o.isClose).map((o, index) =>
                      <Box key={o.id}>
                        <InstructionsCard
                          onClick={() => navigate(`${o.id}`)}
                          order={o}
                          name={o.name}/>
                      </Box>
                    )}
                  </Stack>
                </Stack>
                <Stack width="100%" spacing={1}>
                  <Typography fontSize={16} fontWeight="700" color="primary" textAlign="center">
                    {t("Terminées")}
                  </Typography>
                  <Stack flex={1} width="100%" spacing={2}>
                    {orders.filter((o) => o.isClose).map((o, index) =>
                      <Box key={o.id}>
                        <InstructionsCard
                          onClick={() => navigate(`${o.id}`)}
                          order={o}
                          name={o.name}/>
                      </Box>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
        actions={!sessionQuery.hasAnyOfRoles(Role.CLIENT) && !projectQuery.isClosed ? (
          <Button
            variant="contained"
            color="primary"
            disabled={isZFEncompassedByZT}
            onClick={() => setShowCreateOrder(true)}>
            {t("dispatches.newOrder")}
          </Button>
        ) : undefined}/>
      {showCreateOrder && (
        <CreateOrderModal
          currentOrders={orders}
          trackElement={trackElement}
          handleClose={() => setShowCreateOrder(false)}/>
      )}
      <Outlet/>
    </>
  );
};

export default SpecialElementOrdersModal;
