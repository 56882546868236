import {array, boolean, date, number, object, string} from 'yup';
import {PK_REGEXP} from "@store/project";
import * as YupUtils from "@utils/yup.utils";
import I18n from "@utils/i18n.utils";
import {CreateDispatch, CreateDispatchContent, DispatchTypeEnum} from "@store/dispatches/dispatch.model";

const requiredString = string().required(I18n.t("errors.required"));
const requiredNumber = number().required(I18n.t("errors.required"));
const requiredPK = requiredString.matches(PK_REGEXP, I18n.t("errors.pk"));
const requiredDate = date().nullable()
  .required(I18n.t("errors.required"))
  .typeError(I18n.t("errors.date"));
const requiredHour = date().nullable()
  .required(I18n.t("errors.required"))
  .typeError(I18n.t("errors.hour"));
const requiredTTxNbrs = array()
  .required(I18n.t("errors.required"))
  .min(2, I18n.t("errors.arrayMin2", {value: 2}));

const movementDeliveryRoute = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      nbr: requiredString,
      ATTX: requiredString,
      pkStart: requiredPK,
      pkEnd: requiredPK,
      way: requiredString,
      route: array().of(requiredPK),
      immobilization: boolean(),
      pkImmobilization: string().when("immobilization", {
        is: (immobilization: boolean) => immobilization,
        then: requiredPK,
      }),
      date: requiredDate,
      hour: requiredHour,
      deliveredDispatchNbr: requiredString,
      receivedDispatchNbr: requiredString
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const movementImmobilization = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      ttxNbr: requiredString,
      ATTX: requiredString,
      workZoneNbr: string().when("trainingZoneNbr", {
        is: (trainingZoneNbr: boolean) => !trainingZoneNbr,
        then: string().required(I18n.t("errors.requiredIfNoTrainingZone")),
      }),
      trainingZoneNbr: string().when("workZoneNbr", {
        is: (workZoneNbr: boolean) => !workZoneNbr,
        then: string().required(I18n.t("errors.requiredIfNoWorkArea")),
      }),
      pkStart: requiredPK,
      date: requiredDate,
      hour: requiredHour,
      nbrAntiDriftShims: requiredNumber.moreThan(0, I18n.t("errors.numberSup", {value: 0})),
      nbrScrewBrakes: requiredNumber,
    }, [["workZoneNbr", "trainingZoneNbr"]]);
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const movementEngagement = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      nbr: requiredString,
      ATTX: requiredString,
      workZoneNbr: string().when("trainingZoneNbr", {
        is: (trainingZoneNbr: boolean) => !trainingZoneNbr,
        then: string().required(I18n.t("errors.requiredIfNoTrainingZone")),
      }),
      trainingZoneNbr: string().when("workZoneNbr", {
        is: (workZoneNbr: boolean) => !workZoneNbr,
        then: string().required(I18n.t("errors.requiredIfNoWorkArea")),
      }),
      pkStart: requiredPK,
      pkEnd: requiredPK,
      // arrivalDate: requiredDate,
      engagementRequestDate: requiredDate,
      // engagementAuthorizedDate: requiredDate,
      immobilization: boolean(),
      pkImmobilization: string().when("immobilization", {
        is: (immobilization: boolean) => immobilization,
        then: requiredPK,
      }),
      date: requiredDate,
      hour: requiredHour,
      deliveredDispatchNbr: requiredString,
      receivedDispatchNbr: requiredString,
    }, [["workZoneNbr", "trainingZoneNbr"]]);
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const movementEngagementArrivalAndAuthorization = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({date: requiredDate});
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const movementRelease = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      ttxNbr: requiredString,
      ATTX: requiredString,
      pkStart: requiredPK,
      startWay: requiredString,
      pkEnd: requiredPK,
      endWay: requiredString,
      route: array().of(requiredPK),
      date: requiredDate,
      hour: requiredHour,
      deliveredDispatchNbr: requiredString,
      receivedDispatchNbr: requiredString,
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const movementRemoval = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      ttxNbr: requiredString,
      workZoneNbr: string().when("trainingZoneNbr", {
        is: (trainingZoneNbr: boolean) => !trainingZoneNbr,
        then: string().required(I18n.t("errors.requiredIfNoTrainingZone")),
      }),
      trainingZoneNbr: string().when("workZoneNbr", {
        is: (workZoneNbr: boolean) => !workZoneNbr,
        then: string().required(I18n.t("errors.requiredIfNoWorkArea")),
      }),
      pkStart: requiredPK,
      date: requiredDate,
      hour: requiredHour,
    }, [["workZoneNbr", "trainingZoneNbr"]]);
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const movementReset = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      ttxNbr: requiredString,
      to: requiredString,
      pkStart: requiredPK,
      resetDate: requiredDate,
      date: requiredDate,
      hour: requiredHour,
      deliveredDispatchNbr: requiredString,
      receivedDispatchNbr: requiredString,
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

export const splitting = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape(
      {
        ttxNbr: requiredString,
        emNbr: requiredNumber,
        nbrOfCars: requiredNumber,
        from: requiredString,
        to: requiredString,
        workZoneNbr: string().when("trainingZoneNbr", {
          is: (trainingZoneNbr: boolean) => !trainingZoneNbr,
          then: string().required(I18n.t("errors.requiredIfNoTrainingZone")),
        }),
        trainingZoneNbr: string().when("workZoneNbr", {
          is: (workZoneNbr: boolean) => !workZoneNbr,
          then: string().required(I18n.t("errors.requiredIfNoWorkArea")),
        }),
        pkStart: requiredPK,
        way: requiredString,
        fromDate: requiredHour,
        date: requiredDate,
        hour: requiredHour,
        deliveredDispatchNbr: requiredNumber,
        receivedDispatchNbr: requiredNumber,
      },
      [["workZoneNbr", "trainingZoneNbr"]]
    );
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
};

export const postSplitting = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      splittingParts: array().of(
        object().shape({
          ATTX: requiredString,
          ttxNbr: requiredString,
          emNbr: requiredNumber,
          nbrOfCars: requiredNumber,
          pkStart: requiredPK,
          way: requiredString,
          TTXLength: requiredNumber,
          date: requiredDate,
          hour: requiredHour,
        })
      ),
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
};

export const grouping = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape(
      {
        ttxNbrs: requiredTTxNbrs,
        from: requiredString,
        to: requiredString,
        workZoneNbr: string().when("trainingZoneNbr", {
          is: (trainingZoneNbr: boolean) => !trainingZoneNbr,
          then: string().required(I18n.t("errors.requiredIfNoTrainingZone")),
        }),
        trainingZoneNbr: string().when("workZoneNbr", {
          is: (workZoneNbr: boolean) => !workZoneNbr,
          then: string().required(I18n.t("errors.requiredIfNoWorkArea")),
        }),
        pkStart: requiredPK,
        way: requiredString,
        fromDate: requiredHour,
        date: requiredDate,
        hour: requiredHour,
        deliveredDispatchNbr: requiredNumber,
        receivedDispatchNbr: requiredNumber,
      },
      [["workZoneNbr", "trainingZoneNbr"]]
    );
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
};

export function workTrainDispatchValidation(data: CreateDispatch) {

  switch (data.type) {
    case DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE:
      return movementDeliveryRoute(data.dispatch || {});
    case DispatchTypeEnum.MOVEMENT_IMMOBILIZATION_TTX:
      return movementImmobilization(data.dispatch || {});
    case DispatchTypeEnum.MOVEMENT_ENGAGEMENT:
      return movementEngagement(data.dispatch || {});
    case DispatchTypeEnum.MOVEMENT_ENGAGEMENT_AUTHORIZATION:
    case DispatchTypeEnum.MOVEMENT_ENGAGEMENT_ARRIVAL:
      return movementEngagementArrivalAndAuthorization(data.dispatch || {});
    case DispatchTypeEnum.MOVEMENT_RELEASE:
      return movementRelease(data.dispatch || {});
    case DispatchTypeEnum.MOVEMENT_REMOVAL:
      return movementRemoval(data.dispatch || {});
    case DispatchTypeEnum.MOVEMENT_RESET:
      return movementReset(data.dispatch || {});
    case DispatchTypeEnum.SPLITTING_SPLITTING:
      return splitting(data.dispatch || {});
    case DispatchTypeEnum.SPLITTING_POST_SPLITTING:
      return postSplitting(data.dispatch || {});
    case DispatchTypeEnum.SPLITTING_GROUPING:
      return grouping(data.dispatch || {});
  }
  return {};
}
