import {useLocation} from "react-router-dom";

import {sessionQuery} from "@store/session";
import {Role} from "@store/users";

export enum RoutesUnAuth {
  LOGIN = "/login",
}

export enum RoutesAuth {
  PROJECTS = "projects",
  USERS = "users",
  ACCOUNT = "account",
  PROJECT_VIEW = "project-view",
  NEWS_AND_REGISTERS = "news-and-register",
  REPORTING = "reporting",
  PROJECT_INFO = "project-info",
  DISPATCHES = "dispatches",
  TODAY_DISPATCHES = "today-dispatches",
  HISTORY_COMMON = 'create-common',
  HISTORY = "history",
  HISTORY_DISPATCH = 'create-dispatch',
  TRACK_ELEMENTS = "track-elements",
  PROJECT_NUMBERS = "project-numbers",
  PEOPLE_COUNTING = 'people-counting',
}

export namespace RoutesAuth {
  export const leftBarRoutes = (): RoutesAuth[] => {
    switch (sessionQuery.role) {
      case Role.SUPER_ADMIN:
      case Role.OS_R:
      case Role.OS_C:
        return [RoutesAuth.PROJECTS, RoutesAuth.USERS, RoutesAuth.ACCOUNT];
      case Role.RELF:
      case Role.CLIENT:
        return [RoutesAuth.PROJECTS, RoutesAuth.ACCOUNT];
      default:
        return [];
    }
  };

  export const projectDetailsRoutes: RoutesAuth[] = [RoutesAuth.PROJECT_VIEW, RoutesAuth.DISPATCHES, RoutesAuth.REPORTING, RoutesAuth.PROJECT_INFO];

  export const routeIcon = (route: RoutesAuth, isActive: boolean) => {
    switch (route) {
      case RoutesAuth.PROJECTS:
        return `/images/projects_tab${isActive ? "_active" : ""}.svg`;
      case RoutesAuth.USERS:
        return `/images/users_tab${isActive ? "_active" : ""}.svg`;
      case RoutesAuth.ACCOUNT:
        return `/images/settings_tab${isActive ? "_active" : ""}.svg`;
      case RoutesAuth.PROJECT_VIEW:
        return `/images/line_diagram_tab${isActive ? "_active" : ""}.svg`;
      case RoutesAuth.NEWS_AND_REGISTERS:
        return `/images/dispatch_tab${isActive ? "_active" : ""}.svg`;
      case RoutesAuth.REPORTING:
        return `/images/reporting_tab${isActive ? "_active" : ""}.svg`;
      case RoutesAuth.PROJECT_INFO:
        return `/images/information_tab${isActive ? "_active" : ""}.svg`;
      case RoutesAuth.DISPATCHES:
        return `/images/dispatch_tab${isActive ? "_active" : ""}.svg`;
    }
  };
}

export enum RoutesTrackElement {
  INFO = "info",
  ORDERS = "orders",
  DISPATCHES = "dispatches",
}

export namespace RoutesTrackElement {
  export const routes: RoutesTrackElement[] = [RoutesTrackElement.INFO, RoutesTrackElement.ORDERS];
}

export enum RoutesProjectInfo {
  INFO = "info",
  CLIENTS = "clients",
}

export namespace RoutesProjectInfo {
  export const routes: RoutesProjectInfo[] = [RoutesProjectInfo.INFO, RoutesProjectInfo.CLIENTS];
}

export namespace RoutesDispatches {
  export const routes: RoutesAuth[] = [RoutesAuth.TODAY_DISPATCHES, RoutesAuth.HISTORY, RoutesAuth.TRACK_ELEMENTS]
}

export namespace RoutesReporting {
  export const routes: RoutesAuth[] = [RoutesAuth.PROJECT_NUMBERS, RoutesAuth.PEOPLE_COUNTING];
}

export enum RoutesClients {
  SCHEMA = "schema",
  REPORTING = "reporting",
  PEOPLE_COUNTING = 'people-counting',
  TRACK_ELEMENTS = "track-elements",
  HISTORY = "history",
}

export namespace RoutesClients {
  export const routes: RoutesClients[] = [RoutesClients.SCHEMA, RoutesClients.REPORTING, RoutesClients.PEOPLE_COUNTING, RoutesClients.TRACK_ELEMENTS, RoutesClients.HISTORY];
}

export const useRouteMatch = (patterns: readonly string[] = []) => {
  const {pathname} = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = pathname.includes(`/${pattern}`);

    if (possibleMatch) {
      return pattern;
    }
  }

  return null;
}
