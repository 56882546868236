import {createStyles} from "@mui/styles";
import {Theme} from "@mui/material";

const classes = (theme: Theme) => createStyles({
	container: {
		alignItems: 'center',
		backgroundColor: theme.palette.primary.main,
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'center',
		position: 'relative',
		width: '100%',
	},
	logo: {
		position: 'absolute',
		top: '15vh',
		width: 500,
	},
	loading: {
		marginTop: '15vh',
		width: 450,
	},
});

export default classes;
