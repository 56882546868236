import {CreateOrUpdateTrackElement, Pk, projectQuery, TrackElement, TrackElementType} from "@store/project";
import {CreateDispatch, DispatchTypeEnum} from "@store/dispatches/dispatch.model";

import {IncompatibilityError} from "@utils/incompatibilities/incompatibility.utils";

import {getS9A3ZTInc} from "@utils/incompatibilities/ZT/ZTIncompabilities";
import {getS9A3PNInc} from "@utils/incompatibilities/PN/PNIncompabilities";
import {getS9A3ZFInc} from "@utils/incompatibilities/ZF/ZFIncompabilities";
import {getS9A3ActivityInc} from "@utils/incompatibilities/activity/activityIncompabilities";
import {getS9A3WMInc} from "./WM/WMIncompatibilities";
import {getS9A3CCIInc} from "./CCI/CCIIncompabilities";
import {getS9A3TTXInc} from "./TTX/TTXIncompabilities";
import {getS9A3CommonInc} from "@utils/incompatibilities/common/commonIncompabilities";
import {getS9A3TrackInc} from "@utils/incompatibilities/track/trackIncompabilities";

export const getElementIncompatibilities = (
  trackElement?: TrackElement | CreateOrUpdateTrackElement,
  dispatch?: CreateDispatch
): IncompatibilityError[] => {
  if (!trackElement) return [];

  const trackElementPkStart = typeof trackElement.pkStart === "string" ? Pk.fromString(trackElement.pkStart) : trackElement.pkStart;
  const trackElementPkEnd = trackElement.pkEnd
    ? typeof trackElement.pkEnd === "string"
      ? Pk.fromString(trackElement.pkEnd)
      : trackElement.pkEnd
    : undefined;

  const tracks = projectQuery.projectTracks;
  const ADVs = projectQuery.projectADVs;

  const PNs = projectQuery.projectPN;
  const ZTs = projectQuery.projectZT;
  const ZFs = projectQuery.activeProjectZF;
  const TTx = projectQuery.projectTTX;
  const WMs = projectQuery.projectWM;
  const closedZTToday = projectQuery.projectClosedZTToday;

  const restrictions = projectQuery.projectRestrictions;
  const viaducts = projectQuery.projectViaducts;
  const tunnels = projectQuery.projectTunnels;

  const CCIs = projectQuery.projectCCI;
  const activities = projectQuery.projectActivities;

  if (!projectQuery.isProjectTypeNew) {
    if (dispatch) {
      switch (dispatch.type) {
        case DispatchTypeEnum.PN_REQUEST_TO_CLOSE_PN:
        case DispatchTypeEnum.PN_AUTHORIZATION_TO_CLOSE_PN:
        case DispatchTypeEnum.PN_NOTICE_TO_CLOSE_PN:
        case DispatchTypeEnum.PN_REQUEST_TO_REOPEN_PN:
        case DispatchTypeEnum.PN_AUTHORIZATION_TO_REOPEN_PN:
        case DispatchTypeEnum.PN_NOTICE_TO_REOPEN_PN:
        case DispatchTypeEnum.PN_ATTEST_REOPENED:
          return getS9A3PNInc({
            trackElementId: trackElement.id,
            fromDispatch: true,
            pkStart: trackElementPkStart,
            status: dispatch.type,
            ZTs,
            ZFs,
          });
        case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_OPEN_A_WORK_AREA:
          return getS9A3ZTInc({
            trackElementId: trackElement.id,
            fromDispatch: true,
            pkStart: Pk.fromString(dispatch.dispatch?.pkStart),
            pkEnd: Pk.fromString(dispatch.dispatch?.pkEnd),
            status: dispatch.type,
            TTx,
          });
        case DispatchTypeEnum.WORK_ZONE_CLOSURE_OF_A_WORK_AREA:
          return getS9A3ZTInc({
            trackElementId: trackElement.id,
            fromDispatch: true,
            pkStart: Pk.fromString(dispatch.dispatch?.pkStart),
            pkEnd: Pk.fromString(dispatch.dispatch?.pkEnd),
            PNs,
            status: dispatch.type,
            activities,
            ZTs: ZTs.filter((ZT) => ZT.id !== trackElement.id),
            ZFs,
            CCIs,
            TTx,
          });
        case DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA:
          return getS9A3ZTInc({
            trackElementId: trackElement.id,
            fromDispatch: true,
            pkStart: Pk.fromString(dispatch.dispatch?.pkStart),
            pkEnd: Pk.fromString(dispatch.dispatch?.pkEnd),
            PNs,
            status: dispatch.type,
            activities,
            ZTs: ZTs.filter((ZT) => ZT.id !== trackElement.id),
            ZFs,
            CCIs,
            TTx,
          });
        case DispatchTypeEnum.MODIFICATION_TRAINING_ZONE:
          return getS9A3ZFInc({
            trackElementId: trackElement.id,
            fromDispatch: true,
            pkStart: Pk.fromString(dispatch.dispatch?.pkStart),
            pkEnd: Pk.fromString(dispatch.dispatch?.pkEnd),
            PNs,
            ZTs,
            status: dispatch.type,
            CCIs,
            TTx,
            WMs,
            restrictions,
            tracks,
            ADVs,
          });
        case DispatchTypeEnum.MOVEMENT_IMMOBILIZATION_TTX:
          return getS9A3TTXInc({
            trackElementId: trackElement.id,
            fromDispatch: true,
            dispatchType: dispatch.type,
            pkStart: Pk.fromString(dispatch.dispatch?.pkStart),
            length: trackElement.attributes?.TTXLength,
            ZTs,
            ZFs,
            trackIds: trackElement.tracks,
          });
        case DispatchTypeEnum.SPLITTING_POST_SPLITTING:
          return getS9A3TTXInc({
            trackElementId: trackElement.id,
            fromDispatch: true,
            dispatchType: dispatch.type,
            ZFs,
            ZTs,
            splittingParts: dispatch.dispatch?.splittingParts?.map((sp) => ({
              pkStart: Pk.fromString(sp.pkStart),
              TTXLength: sp.TTXLength,
            })),
          });
        case DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE:
        case DispatchTypeEnum.MOVEMENT_ENGAGEMENT:
        case DispatchTypeEnum.MOVEMENT_RELEASE:
        case DispatchTypeEnum.SPLITTING_SPLITTING:
          return getS9A3TTXInc({
            trackElementId: trackElement.id,
            fromDispatch: true,
            dispatchType: dispatch.type,
            pkStart: Pk.fromString(dispatch.dispatch?.pkStart),
            length: trackElement.attributes?.TTXLength,
            finalPk: Pk.fromString(dispatch.dispatch?.pkEnd),
            finalTrack: dispatch.dispatch?.way,
            CCIs,
            TTx,
            ZFs,
            WMs,
            ZTs,
            restrictions,
            trackIds: trackElement.tracks,
            tracks: projectQuery.projectTracks,
            ADVs,
            activities,
          });
        default:
          return [];
      }
    }

    const commonIncompatibilities = () =>
      trackElement.type
        ? getS9A3CommonInc({
          trackElementId: trackElement.id,
          type: trackElement.type,
          trackIds: trackElement.tracks,
          pkStart: trackElementPkStart,
          pkEnd: trackElementPkEnd,
          length: trackElement.type === TrackElementType.WORK_TRAIN ? trackElement.attributes?.TTXLength : undefined,
          WMs: WMs.filter((WM) => WM.id !== trackElement.id),
          TTx: TTx.filter((TT) => TT.id !== trackElement.id),
          restrictions: restrictions.filter((restriction) => restriction.id !== trackElement.id),
          ZFs,
          tracks,
          CCIs,
          ADVs: projectQuery.projectADVs,
        })
        : [];

    switch (trackElement.type) {
      case TrackElementType.PN:
        return getS9A3PNInc({
          trackElementId: trackElement.id,
          pkStart: trackElementPkStart,
          status: trackElement.attributes?.status,
          ZTs,
          ZFs,
        });
      case TrackElementType.WORK_ZONE:
        return getS9A3ZTInc({
          trackElementId: trackElement.id,
          pkStart: trackElementPkStart,
          pkEnd: trackElementPkEnd,
          activities,
          PNs,
          ZTs: ZTs.filter((ZT) => ZT.id !== trackElement.id),
          ZFs,
          CCIs,
          TTx,
          closedZTToday,
          name: trackElement.attributes?.name,
        });
      case TrackElementType.TRAINING_ZONE:
        return getS9A3ZFInc({
          trackElementId: trackElement.id,
          pkStart: trackElementPkStart,
          pkEnd: trackElementPkEnd,
          PNs,
          ZTs,
          CCIs,
          TTx,
          WMs,
          restrictions,
          tracks,
          ADVs,
        });
      case TrackElementType.LIGHTWEIGHT:
      case TrackElementType.OUTSIDE_ZPF:
      case TrackElementType.ACTIVITY:
        return getS9A3ActivityInc({
          trackElementId: trackElement.id,
          type: trackElement.type,
          pkStart: trackElementPkStart,
          pkEnd: trackElementPkEnd,
          ZTs,
          viaducts,
          tunnels,
          tracks: trackElement.tracks,
        });
      case TrackElementType.WORKSITE_MACHINE:
        return [
          ...getS9A3WMInc({
            trackElementId: trackElement.id,
            pkStart: trackElementPkStart,
            CCIs,
            ZTs,
            tracks: trackElement.tracks,
          }),
          ...commonIncompatibilities(),
        ];
      case TrackElementType.WORKSITE:
        return [
          ...getS9A3CCIInc({
            trackElementId: trackElement.id,
            pkStart: trackElementPkStart,
            pkEnd: trackElementPkEnd,
            ZTs,
            PNs,
          }),
          ...commonIncompatibilities(),
        ];
      case TrackElementType.WORK_TRAIN:
        return [
          ...getS9A3TTXInc({
            trackElementId: trackElement.id,
            pkStart: trackElementPkStart,
            length: trackElement.attributes?.TTXLength,
            finalPk: trackElement.attributes?.finalPk,
            finalTrack: trackElement.attributes?.finalTrack,
            CCIs,
            ZTs,
            ZFs,
            TTx,
            WMs,
            restrictions,
            trackIds: trackElement.tracks,
            tracks,
            ADVs,
          }),
          ...commonIncompatibilities(),
        ];
      case TrackElementType.MAIN_TRACK:
      case TrackElementType.SERVICE_TRACK:
      case TrackElementType.SIDING_TRACK:
      case TrackElementType.TRACK_OUT_OF_SERVICE:
        return getS9A3TrackInc({
          trackElementId: trackElement.id,
          pkStart: trackElementPkStart,
          pkEnd: trackElementPkEnd,
          otherTrackElements: projectQuery.allTrackElements,
        });
      case TrackElementType.OBSTACLE:
      case TrackElementType.TRAFFIC_BAN:
        return commonIncompatibilities();
      default:
        return [];
    }
  }

  return [];
};

export const getProjectIncompatibilities = (): IncompatibilityError[] => {
  const tracks = projectQuery.projectTracks;
  const PNs = projectQuery.projectPN;
  const ZTs = projectQuery.projectZT;
  const ZFs = projectQuery.activeProjectZF;
  const CCIs = projectQuery.projectCCI;
  const WMs = projectQuery.projectWM;
  const TTx = projectQuery.projectTTX;
  const restrictions = projectQuery.projectRestrictions;
  const viaducts = projectQuery.projectViaducts;
  const tunnels = projectQuery.projectTunnels;
  const activities = projectQuery.projectActivities;
  const closedZTToday = projectQuery.projectClosedZTToday;
  const ADVs = projectQuery.projectADVs;

  const incompatibilities: IncompatibilityError[] = [];

  if (!projectQuery.isProjectTypeNew) {
    incompatibilities.push(
      ...PNs.flatMap((PN) =>
        getS9A3PNInc({
          trackElementId: PN.id,
          pkStart: PN.pkStart,
          status: PN.attributes?.status,
          ZTs,
          ZFs,
        })
      )
    );

    incompatibilities.push(
      ...ZTs.flatMap((ZT) =>
        getS9A3ZTInc({
          trackElementId: ZT.id,
          name: ZT.attributes?.name,
          pkStart: ZT.pkStart,
          pkEnd: ZT.pkEnd,
          activities,
          PNs,
          ZTs: ZTs.filter((oZT) => oZT.id !== ZT.id),
          ZFs,
          CCIs,
          TTx,
          closedZTToday,
        })
      )
    );

    incompatibilities.push(
      ...ZFs.flatMap((ZF) =>
        getS9A3ZFInc({
          trackElementId: ZF.id,
          pkStart: ZF.pkStart,
          pkEnd: ZF.pkEnd,
          PNs,
          ZTs,
          CCIs,
          TTx,
          WMs,
          ADVs,
          restrictions,
          tracks,
        })
      )
    );

    incompatibilities.push(
      ...WMs.flatMap((WM) =>
        getS9A3WMInc({
          trackElementId: WM.id,
          pkStart: WM.pkStart,
          CCIs,
          ZTs,
          tracks: WM.tracks,
        })
      )
    );

    incompatibilities.push(
      ...TTx.flatMap((TT) =>
        getS9A3TTXInc({
          trackElementId: TT.id,
          pkStart: TT.pkStart,
          length: TT.attributes?.TTXLength,
          finalPk: TT.attributes?.finalPk,
          finalTrack: TT.attributes?.finalTrack,
          CCIs,
          ZFs,
          ZTs,
          TTx,
          WMs,
          restrictions,
          trackIds: TT.tracks,
          ADVs,
        })
      )
    );

    incompatibilities.push(
      ...CCIs.flatMap((CCI) =>
        getS9A3CCIInc({
          trackElementId: CCI.id,
          pkStart: CCI.pkStart,
          pkEnd: CCI.pkEnd,
          ZTs,
          PNs,
        })
      )
    );

    incompatibilities.push(
      ...activities.flatMap((activity) =>
        getS9A3ActivityInc({
          trackElementId: activity.id,
          type: activity.type,
          pkStart: activity.pkStart,
          pkEnd: activity.pkEnd,
          ZTs,
          viaducts,
          tunnels,
          tracks: activity.tracks,
        })
      )
    );

    incompatibilities.push(
      ...tracks.flatMap((track) =>
        getS9A3TrackInc({
          trackElementId: track.id,
          pkStart: track.pkStart,
          pkEnd: track.pkEnd,
          otherTrackElements: projectQuery.allTrackElements,
        })
      )
    );
  }

  return incompatibilities;
};
