import {alpha, Components} from "@mui/material";

import {Colors, Sizes} from "@utils/theme/constants.utils";

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    dispatch: true;
    "left-bar": true;
    order: true;
    schema: true;
  }
}

const cards: Components = {
  MuiCard: {
    variants: [
      {
        props: {"aria-label": "user-card"},
        style: {
          borderRadius: 8,
          boxShadow: `0 8px 24px 0 ${alpha("#D8D9D7", 0.1)}`,
          height: 156,
          padding: 15,
          position: 'relative',
          width: 156,
          "& .MuiIconButton-root[aria-label=remove-user]": {
            position: 'absolute',
            right: 3,
            top: 3,
            zIndex: 100,
          },
        },
      },
      {
        props: {"aria-label": "user-card", "aria-disabled": true},
        style: {
          opacity: 0.5,
        },
      },
      {
        props: {"aria-label": "user-card", variant: "outlined"},
        style: {
          border: `1px solid ${alpha(Colors.primary, 0.1)} !important`,
          boxShadow: 'none',
        },
      },
      {
        props: {"aria-label": "user-card", variant: "outlined", "aria-selected": true},
        style: {
          border: `1px solid ${Colors.primary} !important`,
          boxShadow: 'none',
        },
      },
    ],
    styleOverrides: {
      root: (props) => ({
        borderRadius: 12,
        boxShadow: `0 7px 20px 0 ${alpha("#D8D9D7", 0.1)}`,
        ...(props.ownerState.variant === "left-bar" ? {
          alignItems: 'center',
          boxShadow: `0 10px 10px 0 ${alpha(Colors.black, 0.1)}`,
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          position: 'relative',
          width: Sizes.leftBar,
        } : {}),
        ...(props.ownerState.variant === "order" ? {
          backgroundColor: Colors.background,
          padding: '18px 24px',
        } : {}),
        ...(props.ownerState.variant === "schema" ? {
          border: `1px solid ${Colors.background}`,
          borderRadius: 8,
          boxShadow: 'none',
          height: '100%',
          padding: '17px 0 0',
          position: 'relative',
          width: '100%',
          "& div[aria-label=schema-options]": {
            left: 20,
            position: 'absolute',
            top: 20,
            zIndex: 1,
            "& > .MuiIconButton-root": {
              height: 50,
              width: 50,
              "> img": {
                height: 30,
                width: 30,
              }
            },
          },
          "& .MuiIconButton-root[aria-label=full-screen]": {
            position: 'absolute',
            top: 6,
            right: 6,
            zIndex: 1,
          },
        } : {}),
      }),
    },
  },
};

export default cards;
