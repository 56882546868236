import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {ID} from "@datorama/akita";
import _ from "lodash";
import {DispatchTypeEnum, TTxDispatchType} from '@store/dispatches/dispatch.model';

interface CheckNoMovementWithoutDispatchParams {
  fromDispatch?: boolean;
  dispatchType?: TTxDispatchType;
  trackElementId?: ID;
  pkStart: Pk;
  length: number;
  ZTs: TrackElement[];
  ZFs: TrackElement[];
  TTx: TrackElement[];
}

/* Should not be able to move TTx without dispatch except if in ZT or ZF and moved in same ZT or ZF */
const checkNoMovementWithoutDispatch = (params: CheckNoMovementWithoutDispatchParams): IncompatibilityError[] => {
  const {fromDispatch, dispatchType, trackElementId, pkStart, length, ZTs, ZFs, TTx} = params;

  const isMovementDispatch = dispatchType && [DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE, DispatchTypeEnum.MOVEMENT_ENGAGEMENT].includes(dispatchType);

  if (!trackElementId) return [];

  const currentTTX = TTx.find((t) => t.id === trackElementId);

  if (!currentTTX || (_.isEqual(currentTTX.pkStart, pkStart) && currentTTX.attributes?.TTXLength === length)) {
    return [];
  }

  if ((!fromDispatch || !isMovementDispatch) && currentTTX.attributes?.isImmobilized) {
    return [{
      error: IncompatibilityErrorEnum.NO_MOVEMENT_WITHOUT_DISPATCH_IF_IMMOBILIZED,
      trackElementId,
      concernedTrackElementIds: [],
    }];
  }

  const currentPkEnd = Pk.addMeters(currentTTX.pkStart, currentTTX.attributes?.TTXLength ?? 0);
  const pkEnd = Pk.addMeters(pkStart, length);

  const currentlyAndWillBeInSameZT = ZTs.some((t) => {
    if (!t.pkEnd) return false;

    const isCurrentlyInZT = (
      Pk.isPkBetweenPks(currentTTX.pkStart, t.pkStart, t.pkEnd)
      || Pk.isPkBetweenPks(currentPkEnd, t.pkStart, t.pkEnd)
      || Pk.isPkBetweenPks(t.pkStart, currentTTX.pkStart, currentPkEnd)
      || Pk.isPkBetweenPks(t.pkEnd, currentTTX.pkStart, currentPkEnd)
    ) && !_.isEqual(currentTTX.pkStart, t.pkEnd) && !_.isEqual(currentPkEnd, t.pkStart);

    const willBeInZT = (
      Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd)
      || Pk.isPkBetweenPks(pkEnd, t.pkStart, t.pkEnd)
      || Pk.isPkBetweenPks(t.pkStart, pkStart, pkEnd)
      || Pk.isPkBetweenPks(t.pkEnd, pkStart, pkEnd)
    ) && !_.isEqual(pkStart, t.pkEnd) && !_.isEqual(pkEnd, t.pkStart);

    return isCurrentlyInZT && willBeInZT;
  });

  if (currentlyAndWillBeInSameZT) return [];

  const currentlyAndWillBeInSameZF = ZFs.some((t) => {
    if (!t.pkEnd) return false;

    const isCurrentlyInZF = (
      Pk.isPkBetweenPks(currentTTX.pkStart, t.pkStart, t.pkEnd)
      || Pk.isPkBetweenPks(currentPkEnd, t.pkStart, t.pkEnd)
      || Pk.isPkBetweenPks(t.pkStart, currentTTX.pkStart, currentPkEnd)
      || Pk.isPkBetweenPks(t.pkEnd, currentTTX.pkStart, currentPkEnd)
    ) && !_.isEqual(currentTTX.pkStart, t.pkEnd) && !_.isEqual(currentPkEnd, t.pkStart);

    const willBeInZF = (
      Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd)
      || Pk.isPkBetweenPks(pkEnd, t.pkStart, t.pkEnd)
      || Pk.isPkBetweenPks(t.pkStart, pkStart, pkEnd)
      || Pk.isPkBetweenPks(t.pkEnd, pkStart, pkEnd)
    ) && !_.isEqual(pkStart, t.pkEnd) && !_.isEqual(pkEnd, t.pkStart);

    return isCurrentlyInZF && willBeInZF;
  });

  if (currentlyAndWillBeInSameZF || (fromDispatch && isMovementDispatch)) return [];

  return [{
    error: IncompatibilityErrorEnum.NO_MOVEMENT_WITHOUT_DISPATCH,
    trackElementId,
    concernedTrackElementIds: [],
  }];
};

export default checkNoMovementWithoutDispatch;
