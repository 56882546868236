import React from "react";
import {Drawer, IconButton, Stack, Typography} from "@mui/material";
import {withStyles, WithStyles} from "@mui/styles";

import style from "./RightDrawer.style";

interface RightDrawerProps extends WithStyles<typeof style> {
  handleClose: () => void;
  open: boolean;
  title: string;
  content: JSX.Element | JSX.Element[];
  actions: JSX.Element | JSX.Element[];
}

const RightDrawer = (props: RightDrawerProps) => {

  const {actions, handleClose, open, title, content, classes} = props;

  return (
    <Drawer anchor="right" open={open} onBackdropClick={handleClose} hidden={false}>
      <IconButton className={classes.close} onClick={handleClose}>
        <img alt="" src="/images/cross_icon.svg"/>
      </IconButton>
      <Stack direction="column" padding={4} className={classes.contentContainer} justifyContent="space-between">
        <Stack justifyContent="space-between">
          <Typography fontWeight="900" align="center" fontSize="20px">{title}</Typography>
          {content}
        </Stack>
        {actions}
      </Stack>
    </Drawer>
  )
}

export default withStyles(style)(RightDrawer);