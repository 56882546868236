import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Grid, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {Outlet, useNavigate} from "react-router-dom";

import {Role, User, UsersFilters, usersQuery, usersService} from "@store/users";

import TabsComponent from "@components/tabs/Tabs.component";
import SearchBar from "@components/textField/SearchBar.component";
import UserCard from "@components/user/User.card";
import {sessionQuery} from "@store/session";

const UsersScreen = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const [filters, setFilters] = useState<UsersFilters>({search: "", roles: [Role.OS_C]});

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    usersService.getUsers().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });

    const _users$ = usersQuery.users$.subscribe(setUsers);

    const _loading$ = usersQuery.selectLoading().subscribe(setLoading);

    return () => {
      _users$.unsubscribe();
      _loading$.unsubscribe();
    };
  }, [enqueueSnackbar, filters]);

  const updateFilters = (name: string) => (value: any) => setFilters({...filters, [name]: value});

  return (
    <Stack width="100%" pt="30px">
      <Stack spacing={0.5}>
        <Typography fontSize={26} fontWeight="900">{t("users.title")}</Typography>
        <Typography fontSize={14} fontWeight="400" sx={{opacity: 0.3}}>
          {t("users.description")}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={3} mt="30px">
        <SearchBar
          placeholder={t("users.search")}
          value={filters.search}
          handleChange={updateFilters("search")}
          color="secondary"/>
        {sessionQuery.hasSufficientRoleOrAbove(Role.OS_R) && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("create")}
            startIcon={<img alt="" src="/images/add_icon_white.svg"/>}>
            {t("users.createUser")}
          </Button>
        )}
      </Stack>
      <Box mt="30px" mb="10px">
        <TabsComponent
          value={Role.roles.indexOf(filters.roles[0])}
          handleChange={(value) => updateFilters("roles")([Role.roles[value]])}
          tabs={[t("users.tabs.0"), t("users.tabs.1"), t("users.tabs.2"), t("users.tabs.3"), t("users.tabs.4")]}/>
      </Box>
      {loading ? (
        <Box height="100%" width="100%" display="flex" alignItems="center" mb="20px" justifyContent="center">
          <CircularProgress size={50}/>
        </Box>
      ) : (
        <Box className="scrollable" overflow="auto" pr="5px" pt="20px" pb="20px">
          <Grid container spacing={4}>
            {users.filter((user) => {
              if (!filters.roles.includes(user.role)) return false;

              const search = new RegExp(filters.search, 'i');
              return !search || user.firstname.match(search) || user.lastname.match(search);
            }).map((user) => (
              <Grid key={user.id} item>
                <UserCard user={user} handleClick={() => navigate(user.id.toString())}/>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <Outlet/>
    </Stack>
  );
};

export default UsersScreen;
