import {TrackElementHistoryType} from "@store/dispatches/dispatch.model";
import {SelectComponentItem} from "@components/select/Select.component";

import I18n from "@utils/i18n.utils";
import {ID} from "@datorama/akita";

export interface DateFilters {
  startDate?: Date;
  startHour?: Date;
  endDate?: Date;
  endHour?: Date;
}

export interface PaginationMeta {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export const peopleCountingCategories: SelectComponentItem[] = [{
  label: I18n.t("dispatches.managePeopleCounting.types.PEOPLE_COUNTING_ENTRANCE"),
  value: TrackElementHistoryType.PEOPLE_COUNTING_ENTRANCE,
},
  {
    label: I18n.t("dispatches.managePeopleCounting.types.PEOPLE_COUNTING_EXIT"),
    value: TrackElementHistoryType.PEOPLE_COUNTING_EXIT,
  },
];

export interface PeopleCountingFilters extends DateFilters {
  search?: string;
  category?: TrackElementHistoryType;
}

export interface PeopleCountingModel {
  id: string;
  type: TrackElementHistoryType.PEOPLE_COUNTING_ENTRANCE | TrackElementHistoryType.PEOPLE_COUNTING_EXIT;
  createdAt: Date;
  content: {
    interlocutor: string;
    nature: string;
    company: string;
    number: string;
    currentNumber: string;
    observations: string;
  };
}

export interface CreateAccidentAtWork {
  projectId: ID;
  name?: string;
  date?: Date;
  hour?: Date;
  victim?: string;
  company?: string;
  location?: string;
  nature?: string;
  causes?: string;
}

export interface AccidentAtWorkFilters extends DateFilters {
  search?: string;
}

export interface AccidentAtWorkModel {
  id: string;
  content: {
    name: string;
    date: Date;
    victim: string;
    company: string;
    location: string;
    nature: string;
    causes: string;
  };
}

interface ProjectDispatchesStatsPerType {
  [type: string]: number;
}

export interface ProjectDispatchesStats {
  type: string;
  total: number;
  perType: ProjectDispatchesStatsPerType;
}

export interface ProjectDispatchesByDateStats {
  date: string;
  perType: ProjectDispatchesStats[];
}

export interface ProjectOrdersStats {
  type: string;
  value: number;
}

export interface ProjectStats {
  countingPeople: { [nature: string]: number };
  accidentsAtWorkNbr: number;
  daysWithoutAccidents: number;
  maxDaysWithoutAccidents: number;
}