import {CreateDispatch, DispatchTypeEnum, Order} from "@store/dispatches/dispatch.model";
import {getZTOrderLogic} from "@store/dispatches/orderLogic/ZTLogic";
import {TrackElement, TrackElementType} from "@store/project";
import {getZFOrderLogic} from '@store/dispatches/orderLogic/ZFLogic';
import {getPNOrderLogic} from '@store/dispatches/orderLogic/PNLogic';
import {getTTxOrderLogic} from '@store/dispatches/orderLogic/TTxLogic';

interface DispatchDependency {
  condition: boolean;
  dispatchType: DispatchTypeEnum | null;
}

export interface OrderLogic {
  dispatchType: DispatchTypeEnum;
  dependsOf: DispatchDependency[];
}

export function getDispatchesOrderLogicMap(dispatch: CreateDispatch, order: Order, trackElement: TrackElement): boolean {
  let currentLogic: OrderLogic[] = [];

  switch (trackElement.type) {
    case TrackElementType.WORK_ZONE:
      currentLogic = getZTOrderLogic(trackElement);
      break;
    case TrackElementType.PN:
      currentLogic = getPNOrderLogic(trackElement)
      break;
    case TrackElementType.TRAINING_ZONE:
      currentLogic = getZFOrderLogic(trackElement);
      break;
    case TrackElementType.WORK_TRAIN:
      currentLogic = getTTxOrderLogic(trackElement, order);
      break;
  }

  const lastDispatchType = order?.dispatches?.at(-1)?.type ?? null;

  const dispatchDependencies = currentLogic
    .filter((logicItem) => logicItem.dispatchType === dispatch.type)
    .flatMap((logicItem) => logicItem.dependsOf);

  if (!dispatchDependencies.length) return true;

  return dispatchDependencies.some((d) => d.condition && d.dispatchType === lastDispatchType);
}
