import React, {useState} from "react";
import {Stack, Tooltip, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

import {Pk, TrackElement, TrackElementType, TRAIN_TRACK_HEIGHT} from "@store/project";

import {Colors} from "@utils/theme/constants.utils";

interface TrackLineElementProps {
  handleLineClick?: (pk: Pk) => void;
  hidden?: boolean;
  trackElement: TrackElement;
  transformPointToPk: (screenPoint: number) => Pk;
  x1: number;
  x2: number;
  y: number;
}

const TrackLineElement = (props: TrackLineElementProps) => {
  const {handleLineClick, hidden, trackElement, transformPointToPk, x1, x2, y} = props;

  const {t} = useTranslation();

  const [currentX, setCurrentX] = useState<number | undefined>(undefined);

  const positionRef = React.useRef({x: 0, y: 0});

  const popperRef = React.useRef<any>(null);
  const areaRef = React.useRef<SVGLineElement>(null);

  const handleMouseMove = (event: any) => {
    setCurrentX(event.clientX);

    positionRef.current = {x: event.clientX, y: event.clientY};

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  const trackHeight = trackElement.type === TrackElementType.MAIN_TRACK ? TRAIN_TRACK_HEIGHT : TRAIN_TRACK_HEIGHT / 2;
  const halfOpacity = trackElement.type === TrackElementType.TRACK_OUT_OF_SERVICE

  return (
    <g>
      <line
        x1={x1} y1={y} x2={x2} y2={y}
        strokeWidth={trackHeight}
        stroke={hidden ? "transparent" : (
          halfOpacity ? Colors.schema.halfOpacityTrack : Colors.schema.track
        )}/>
      {!hidden && trackElement.attributes?.name && (
        <text
          id="test"
          x={x2 - 10}
          y={y - TRAIN_TRACK_HEIGHT * 2}
          fill={Colors.primary}
          fontSize={8}
          fontWeight="600"
          dominantBaseline="bottom"
          textAnchor="end">
          {trackElement.attributes.name}
        </text>
      )}
      {!!hidden && (
        <Tooltip
          aria-label="show-pk"
          title={(
            <Stack spacing={0.25}>
              <Typography fontSize={10} fontWeight="700">
                {t("global.pk")} {currentX !== undefined ? Pk.toString(transformPointToPk(currentX)) || "-" : "-"}
              </Typography>
              {trackElement.attributes.name && (
                <Typography fontSize={10} fontWeight="400" sx={{opacity: 0.5}}>
                  {trackElement.attributes.name}
                </Typography>
              )}
            </Stack>
          )}
          componentsProps={{tooltip: {style: {margin: 0}}}}
          PopperProps={{
            popperRef,
            anchorEl: {
              getBoundingClientRect: () => {
                return new DOMRect(
                  positionRef.current.x,
                  (areaRef.current?.getBoundingClientRect().y || 0) + trackHeight * 1.5,
                  0,
                  0,
                );
              },
            },
          }}>
          <line
            ref={areaRef}
            x1={x1} y1={y} x2={x2} y2={y}
            onClick={(evt) => handleLineClick?.(transformPointToPk(evt.clientX))}
            onMouseMove={handleMouseMove}
            style={{cursor: handleLineClick ? 'pointer' : undefined, opacity: 0}}
            stroke={Colors.schema.halfOpacityTrack}
            strokeWidth={trackHeight * 2}/>
        </Tooltip>
      )}
    </g>
  );
}

export default TrackLineElement;