import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Grid, IconButton, Stack, Typography} from "@mui/material";

import {CreateOrUpdateTrackElement, PK_INPUT_REGEXP} from "@store/project";

import {trackElementValidation} from "@screens/auth/common/schema/forms/trackElements/forms.yup";
import {FieldErrors} from "@utils/yup.utils";

import ModalComponent from "@components/modals/Modal.component";
import TextFieldComponent from "@components/textField/TextField.component";

import useIncompatibilitiesAcceptation from "../../components/IncompatibilitiesAcceptation.component";
import { IncompatibilityError } from "@utils/incompatibilities/incompatibility.utils";

interface WorksiteFormProps {
  description: string;
  handleClose: () => void;
  handleDelete?: () => void;
  handlePrevious?: () => void;
  handleValidate: (incompatibilities?: IncompatibilityError[]) => void;
  loading?: boolean;
  setTrackElement: (trackElement: CreateOrUpdateTrackElement) => void;
  title: string;
  trackElement: CreateOrUpdateTrackElement;
}

const WorksiteForm = (props: WorksiteFormProps) => {
  const {
    description,
    handleClose,
    handleDelete,
    handlePrevious,
    handleValidate,
    loading,
    setTrackElement,
    title,
    trackElement
  } = props;

  const {t} = useTranslation();

  const [errors, setErrors] = useState<FieldErrors>({});

  const {IncompatibilitiesAcceptation, disabledByIncompatibilities, incompatibilities} = useIncompatibilitiesAcceptation({trackElement, creation: !handleDelete});

  const updateTrackElement = (name: string) => (value: any) => setTrackElement({...trackElement, [name]: value});
  const updateAttributes = (name: string) => (value: any) =>
    setTrackElement({
      ...trackElement,
      attributes: {...trackElement.attributes, [name]: value},
    });

  const handleAdd = () => {
    const errors = trackElementValidation(trackElement);

    setErrors(errors);
    if (!Object.keys(errors).length) handleValidate(incompatibilities);
  };

  const onDeleteWMName = (index: number) => {
    const worksiteMachineNames = [...trackElement.attributes.worksiteMachineNames];
    worksiteMachineNames.splice(index, 1);
    updateAttributes("worksiteMachineNames")(worksiteMachineNames);
  };

  const onAddWMName = () => {
    const worksiteMachineNames = [...(trackElement.attributes.worksiteMachineNames || [])];
    worksiteMachineNames.push("");
    updateAttributes("worksiteMachineNames")(worksiteMachineNames);
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      title={title}
      description={description}
      content={
        <Box width="450px">
          <Grid container columnSpacing={2.5} rowSpacing={1.5}>
            <Grid item xs={12}>
              <TextFieldComponent
                label={t("schema.worksite.name")}
                handleChange={updateAttributes("name")}
                value={trackElement.attributes?.name || ""}
                error={errors["attributes.name"]}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldComponent
                label={t("schema.worksite.company")}
                handleChange={updateAttributes("company")}
                value={trackElement.attributes?.company || ""}
                error={errors["attributes.company"]}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldComponent
                label={t("schema.worksite.RCCI")}
                handleChange={updateAttributes("RCCI")}
                value={trackElement.attributes?.RCCI || ""}
                error={errors["attributes.RCCI"]}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldComponent
                label={t("schema.worksite.startPK")}
                handleChange={updateTrackElement("pkStart")}
                pattern={PK_INPUT_REGEXP}
                value={trackElement.pkStart || ""}
                error={errors.pkStart}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldComponent
                label={t("schema.worksite.endPK")}
                handleChange={updateTrackElement("pkEnd")}
                pattern={PK_INPUT_REGEXP}
                value={trackElement.pkEnd || ""}
                error={errors.pkEnd}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack pt={2} spacing={1}>
                <Typography fontSize={14} fontWeight="600">
                  {t("schema.worksite.worksiteMachineNames")}
                </Typography>
                <Stack spacing={2}>
                  {((trackElement.attributes.worksiteMachineNames || []) as String[])?.map((worksiteMachineName, index) => (
                    <Stack direction={"row"} alignContent={"center"} spacing={2}>
                      <Box width={"100%"}>
                        <TextFieldComponent
                          key={index}
                          placeholder={t("schema.worksite.worksiteMachineName")}
                          handleChange={(value) => {
                            const worksiteMachineNames = [...trackElement.attributes.worksiteMachineNames];
                            worksiteMachineNames[index] = value;
                            updateAttributes("worksiteMachineNames")(worksiteMachineNames);
                          }}
                          value={worksiteMachineName}
                          error={errors["attributes.worksiteMachineNames"]}
                        />
                      </Box>

                      <Stack justifyContent={"center"}>
                        <IconButton onClick={() => onDeleteWMName(index)}>
                          <img alt="delete row" src="/images/delete_red_icon.svg"/>
                        </IconButton>
                      </Stack>
                    </Stack>
                  ))}
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<img alt="" src="/images/add_icon_white.svg"/>}
                    onClick={onAddWMName}
                    size="small">
                    {t("global.add")}
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          {IncompatibilitiesAcceptation && (
            <Stack pt="20px">
              <IncompatibilitiesAcceptation/>
            </Stack>
          )}
        </Box>
      }
      actions={
        <>
          {!!handlePrevious && (
            <Button variant="outlined" color="primary" disabled={loading} onClick={handlePrevious}>
              {t("global.previous")}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={loading || disabledByIncompatibilities}
            onClick={handleAdd}>
            {t(handleDelete ? "global.modify" : "global.add")}
          </Button>
          {!!handleDelete && (
            <Button variant="contained" color="error" disabled={loading} onClick={handleDelete}>
              {t("global.delete")}
            </Button>
          )}
        </>
      }
    />
  );
};

export default WorksiteForm;
