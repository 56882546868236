import React from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack} from "@mui/material";

interface ModalComponentProps {
  actions?: JSX.Element | JSX.Element[];
  color?: 'primary' | 'secondary';
  content?: JSX.Element | JSX.Element[];
  description?: string;
  handleClose?: () => void;
  fullHeight?: boolean;
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  scroll?: "body" | "paper";
  title: string;
  titleIcon?: JSX.Element;
}

const ModalComponent = (props: ModalComponentProps) => {
  const {
    actions,
    color = 'secondary',
    content,
    description,
    fullHeight,
    fullWidth,
    handleClose,
    maxWidth = "md",
    scroll = "paper",
    title,
    titleIcon,
  } = props;

  return (
    <Dialog open={true} maxWidth={maxWidth} fullWidth={fullWidth === undefined ? true : fullWidth}
            scroll={scroll} onClose={handleClose} aria-label={fullHeight ? "full-height" : undefined} color={color}>
      {!!handleClose && (
        <IconButton aria-label="close" onClick={handleClose}>
          <img alt="" src="/images/cross_icon.svg"/>
        </IconButton>
      )}
      <DialogTitle>
        {!!titleIcon && (
          <Stack alignItems="center" width="100%" mb="50px">
            {titleIcon}
          </Stack>
        )}
        {title}
      </DialogTitle>
      {!!description && <DialogContentText>{description}</DialogContentText>}
      <DialogContent className={scroll === "paper" ? "scrollable" : undefined}>
        {content}
      </DialogContent>
      {!!actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
}

export default ModalComponent;
