import React, {useEffect, useState} from "react";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {finalize} from "rxjs";
import {RoutesAuth} from "@utils/routes.utils";
import {FieldErrors} from "@utils/yup.utils";
import {UpdateUserPassword, usersService} from "@store/users";

import Modal from "@components/modals/Modal.component";
import TextFieldComponent from "@components/textField/TextField.component";
import {resetUserPasswordValidation} from "./resetUserPassword.yup";

const ResetUserPassword = () => {
  const {t} = useTranslation();
  const {userId} = useParams();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<UpdateUserPassword>({});
  const [errors, setErrors] = useState<FieldErrors>({});

  useEffect(() => {
    if (!userId) navigate(RoutesAuth.USERS);
  }, [navigate, userId]);

  if (!userId) return null;

  const handleClose = () => navigate("..");

  const updateData = (name: string) => (value: any) => setData((state) => ({...state, [name]: value}));

  const onGenerateNewPassword = () => {
    const errors = resetUserPasswordValidation(data);

    setErrors(errors);

    if (!data.newPassword || Object.keys(errors).length) return;

    setLoading(true);

    usersService.resetUserPassword(userId, data.newPassword).pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("resetPassword.success"), {variant: "success"});
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  }
  return (
    <Modal
      title={t("resetPassword.title")}
      handleClose={handleClose}
      content={(
        <Stack mt="25px" mb="10px" width="70%" justifyContent="center" alignContent="center" spacing={3.5}>
          <Stack spacing={3.5}>
            <Typography align="center" fontWeight="400" fontSize={16}>
              {t("resetPassword.description1")}
            </Typography>
            <Typography align="center" fontWeight="400" fontSize={16}>
              {t("resetPassword.description2")}
            </Typography>
          </Stack>
          <Box>
            <Grid container rowSpacing={1.5}>
              <Grid item xs={2}/>
              <Grid item xs={8}>
                <TextFieldComponent
                  required
                  label={t("resetPassword.newPassword")}
                  type="password"
                  value={data.newPassword || ""}
                  handleChange={updateData('newPassword')}
                  placeholder={t("resetPassword.newPassword")}
                  error={errors.newPassword}/>
              </Grid>
              <Grid item xs={2}/>
              <Grid item xs={2}/>
              <Grid item xs={8}>
                <TextFieldComponent
                  required
                  label={t("resetPassword.confirmPassword")}
                  type="password"
                  value={data.confirmPassword || ""}
                  handleChange={updateData('confirmPassword')}
                  placeholder={t("resetPassword.confirmPassword")}
                  error={errors.confirmPassword}/>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      )}
      actions={(
        <>
          <Button variant="contained" color="primary" onClick={onGenerateNewPassword} disabled={loading}>
            {t("resetPassword.button")}
          </Button>
        </>
      )}/>
  );
}
export default ResetUserPassword;