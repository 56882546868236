import {array, date, object, string} from 'yup';
import {PK_REGEXP} from "@store/project";
import * as YupUtils from "@utils/yup.utils";
import I18n from "@utils/i18n.utils";
import {CreateDispatch, CreateDispatchContent, DispatchTypeEnum} from "@store/dispatches/dispatch.model";

const requiredString = string().required(I18n.t("errors.required"));
const requiredArray = array().min(1, I18n.t("errors.required")).required(I18n.t("errors.required"));
const requiredPK = requiredString.matches(PK_REGEXP, I18n.t("errors.pk"));
const requiredDate = date().nullable()
  .required(I18n.t("errors.required"))
  .typeError(I18n.t("errors.date"));

const movementWorkSiteMachine = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      machineNbr: requiredString,
      startDate: requiredDate,
      pkStart: requiredPK,
      pkEnd: requiredPK,
      tracks: requiredArray,
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const endMovementWorkSiteMachine = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      endDate: requiredDate,
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const unenrailementOrEnrailementWorkSiteMachine = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      machineNbr: requiredString,
      startDate: requiredDate,
      pkStart: requiredPK,
      tracks: requiredArray,
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const engagementOrReleaseWorkSiteMachine = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      machineNbr: requiredString,
      workZoneNbr: requiredString,
      startDate: requiredDate,
      pkStart: requiredPK,
      tracks: requiredArray,
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const immobilizationWorkSiteMachine = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      machineNbr: requiredString,
      startDate: requiredDate,
      pkStart: requiredPK,
      tracks: requiredArray,
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

export function worksiteMachineDispatchValidation(data: CreateDispatch) {
  switch (data.type) {
    case DispatchTypeEnum.MOVEMENT_WORKSITE_MACHINE:
      return movementWorkSiteMachine(data.dispatch || {});
    case DispatchTypeEnum.END_MOVEMENT_WORKSITE_MACHINE:
      return endMovementWorkSiteMachine(data.dispatch || {});
    case DispatchTypeEnum.UNENRAILMENT_WORKSITE_MACHINE:
    case DispatchTypeEnum.ENRAILMENT_WORKSITE_MACHINE:
      return unenrailementOrEnrailementWorkSiteMachine(data.dispatch || {});
    case DispatchTypeEnum.ENGAGEMENT_WORKSITE_MACHINE:
    case DispatchTypeEnum.RELEASE_WORKSITE_MACHINE:
      return engagementOrReleaseWorkSiteMachine(data.dispatch || {});
    case DispatchTypeEnum.IMMOBILIZATION_WORKSITE_MACHINE:
      return immobilizationWorkSiteMachine(data.dispatch || {});
  }
  return {};
}
