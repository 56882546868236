import {GridColumns} from "@mui/x-data-grid";

import I18n from "@utils/i18n.utils";
import {TrackElementHistoryType} from "@store/dispatches/dispatch.model";
import {TrackElementType} from "@store/project";
import {Tooltip} from "@mui/material";

export const trackElementsColumns = (isProjectNew?: boolean) => [
  {
    field: "type",
    headerName: I18n.t("dispatches.table.category"),
    headerAlign: "center",
    align: "center",
    cellClassName: (params) => params.row.type === TrackElementHistoryType.INCOMPATIBILITY ? 'error' : undefined,
    valueGetter: (params) => TrackElementHistoryType.label(params.value),
    width: 350,
  },
  {
    field: "elementType",
    headerName: I18n.t("dispatches.table.type"),
    headerAlign: "center",
    align: "center",
    cellClassName: (params) => params.row.type === TrackElementHistoryType.INCOMPATIBILITY ? 'error' : undefined,
    valueGetter: (params) => {
      switch (params.row.type as TrackElementHistoryType) {
        case TrackElementHistoryType.COMMON:
        case TrackElementHistoryType.INCOMPATIBILITY:
          return params.row.content.name || "-";
        default:
          return TrackElementType.getRELFElementLabel(params.row.content.type);
      }
    },
    renderCell: (params) => (
      <Tooltip title={params.value ?? ""}>
        <span style={{
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center'
        }}>{params.value}</span>
      </Tooltip>
    ),
    width: 350,
  },
  {
    field: "name",
    headerName: I18n.t("dispatches.table.trackElementName"),
    headerAlign: "center",
    align: "center",
    cellClassName: (params) => params.row.type === TrackElementHistoryType.INCOMPATIBILITY ? 'error' : undefined,
    valueGetter: (params) => {
      switch (params.row.type as TrackElementHistoryType) {
        case TrackElementHistoryType.COMMON:
        case TrackElementHistoryType.INCOMPATIBILITY:
          return params.row.content?.description || "-";
        default:
          return params.row.content?.name ||
            params.row.content?.contentChange?.attributes?.name ||
            "-";
      }
    },
    renderCell: (params) => (
      <Tooltip title={params.value ?? ""}>
        <span style={{
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center'
        }}>{params.value}</span>
      </Tooltip>
    ),
    flex: 1.5,
  },
  {
    field: "user",
    headerName: I18n.t(
      `dispatches.table.${isProjectNew ? "relfNew" : "relf"}`
    ),
    headerAlign: "center",
    align: "center",
    cellClassName: (params) => params.row.type === TrackElementHistoryType.INCOMPATIBILITY ? 'error' : undefined,
    valueGetter: (params) =>
      params.row.user.firstname + " " + params.row.user.lastname?.toUpperCase(),
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: I18n.t("dispatches.table.date"),
    cellClassName: (params) => params.row.type === TrackElementHistoryType.INCOMPATIBILITY ? 'error' : undefined,
    valueFormatter: (params) =>
      params.value
        ? I18n.t("dispatches.table.day", {date: new Date(params.value)})
        : "-",
    headerAlign: "center",
    align: "center",
    width: 150,
  },
] as GridColumns;
