import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {ID} from "@datorama/akita";

interface CheckNoWMOutZTParams {
  trackElementId?: ID;
  pkStart: Pk;
  ZTs: TrackElement[];
}

/* Should not be able to set WM Pk outside a ZT */
const checkNoWMOutZT = (params: CheckNoWMOutZTParams): IncompatibilityError[] => {
  const {trackElementId, pkStart, ZTs} = params;

  const WMInZTs = ZTs.filter((t) => !!t.pkEnd && Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd));

  if (!WMInZTs.length) {
    return [
      {
        error: IncompatibilityErrorEnum.NO_WM_OUT_ZT,
        trackElementId,
        concernedTrackElementIds: [],
      },
    ];
  }

  return [];
};

export default checkNoWMOutZT;
