import React, {useEffect, useMemo, useState} from "react";
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';

import {ProjectOrdersStats, reportingService} from '@store/reporting';
import {projectQuery, TrackElement} from '@store/project';
import {TrackElementType} from '@store/project/schema.model';

import DoughnutChart from '@components/chart/Doughnut.chart';
import {finalize} from 'rxjs';

const ProjectOrdersChart = () => {
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ProjectOrdersStats[]>([]);

  useEffect(() => {
    reportingService.getOrdersStats(projectQuery.projectId).pipe(finalize(() => setLoading(false))).subscribe({
      next: setData,
      error: (err) => enqueueSnackbar(err.text),
    });
  }, []);

  const total = useMemo(() => {
    return data.reduce((res, d) => res + d.value, 0);
  }, [data]);

  return (
    <DoughnutChart<ProjectOrdersStats>
      displayTotal
      total={total}
      loading={loading}
      labels={[
        {label: 'Catégorie de consignes', field: 'type'},
        {label: 'Nombre de consignes', field: 'value'},
      ]}
      getTooltip={(x) =>
        `<div style="padding: 5px 10px">
          <div style="line-height: 1.25; margin-bottom: 5px">${x.type}</div>
          <strong style="white-space: nowrap">${t("dispatches.orderCount", {count: x.value ?? 0})}</strong>
        </div>`
      }
      data={data.map((x) => ({
        type: TrackElementType.getRELFElementLabel(x.type as TrackElementType),
        value: x.value,
        color: TrackElementType.getColor({type: x.type} as TrackElement),
      }))}
    />
  );
}

export default ProjectOrdersChart;