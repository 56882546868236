import React, {useEffect, useState} from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {finalize} from "rxjs";

import {usersService} from "@store/users";

import {RoutesAuth} from "@utils/routes.utils";

import Modal from "@components/modals/Modal.component";

const ResetUserIdentificationCodeModal = () => {
  const {t} = useTranslation();
  const {userId} = useParams();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!userId) navigate(RoutesAuth.USERS);
  }, [navigate, userId]);

  if (!userId) return null;

  const handleClose = () => navigate("..");

  const onGenerateNewCode = () => {
    setLoading(true);
    usersService.resetUserIdentificationCode(userId).pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("operationalCode.success"), {variant: "success"});
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  }

  return (
    <Modal
      title={t("operationalCode.title")}
      handleClose={handleClose}
      content={(
        <Box mt="25px" mb="10px" width="70%" alignItems="flex-start" justifyContent="center">
          <Grid container rowSpacing={3.5}>
            <Grid item xs={12}>
              <Typography align="center" fontWeight="400" fontSize={16}>
                {t("operationalCode.description1")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" fontWeight="400" fontSize={16}>
                {t("operationalCode.description2")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      actions={(
        <>
          <Button variant="contained" color="primary" onClick={onGenerateNewCode} disabled={loading}>
            {t("operationalCode.button")}
          </Button>
        </>
      )}/>
  );
}

export default ResetUserIdentificationCodeModal;
