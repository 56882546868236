import {array, date, object, string} from 'yup';
import {PK_REGEXP} from "@store/project";
import * as YupUtils from "@utils/yup.utils";
import I18n from "@utils/i18n.utils";
import {CreateDispatch, CreateDispatchContent, DispatchTypeEnum} from "@store/dispatches/dispatch.model";

const requiredString = string().required(I18n.t("errors.required"));
const requiredPK = requiredString.matches(PK_REGEXP, I18n.t("errors.pk"));
const requiredDate = date().nullable()
  .required(I18n.t("errors.required"))
  .typeError(I18n.t("errors.date"));
const requiredHour = date().nullable()
  .required(I18n.t("errors.required"))
  .typeError(I18n.t("errors.hour"));

const requestToOpenAWorkAreaValidation = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      nbr: requiredString,
      natureOfWork: requiredString,
      pkStart: requiredPK,
      pkEnd: requiredPK,
      tracks: array().required(I18n.t("errors.required")).min(1, I18n.t("errors.required")),
      date: requiredDate,
      hour: requiredHour,
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}
const authorizationToOpenAWorkAreaValidation = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      nbr: requiredString,
      natureOfWork: requiredString,
      pkStart: requiredPK,
      pkEnd: requiredPK,
      tracks: array().required(I18n.t("errors.required")).min(1, I18n.t("errors.required")),
      fromDate: requiredDate,
      toDate: requiredDate,
      toHour: requiredHour,
      date: requiredDate,
      hour: requiredHour,
      deliveredDispatchNbr: requiredString,
      receivedDispatchNbr: requiredString
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}
const noticeOpeningAWorkAreaValidation = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      nbr: requiredString,
      natureOfWork: requiredString,
      date: requiredDate,
      hour: requiredHour,
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const authorizationForRestartingAWorkArealnStandbyValidation = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      nbr: requiredString,
      natureOfWork: requiredString,
      date: requiredDate,
      hour: requiredHour,
      deliveredDispatchNbr: requiredString,
      receivedDispatchNbr: requiredString,
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const requestForRestartingAWorkArealnStandbyValidation = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      nbr: requiredString,
      natureOfWork: requiredString,
      pkStart: requiredPK,
      pkEnd: requiredPK,
      tracks: array().required(I18n.t("errors.required")).min(1, I18n.t("errors.required")),
      date: requiredDate,
      hour: requiredHour,
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const requestForModificationOfTheWorkAreaValidation = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      nbr: requiredString,
      natureOfWork: requiredString,
      pkStart: requiredPK,
      pkEnd: requiredPK,
      tracks: array().required(I18n.t("errors.required")).min(1, I18n.t("errors.required")),
      date: requiredDate,
      hour: requiredHour,
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}
const authorizationToModifyTheWorkAreaValidation = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      nbr: requiredString,
      natureOfWork: requiredString,
      pkStart: requiredPK,
      pkEnd: requiredPK,
      tracks: array().required(I18n.t("errors.required")).min(1, I18n.t("errors.required")),
      date: requiredDate,
      hour: requiredHour,
      deliveredDispatchNbr: requiredString,
      receivedDispatchNbr: requiredString
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}
const noticeOfModificationOfTheWorkAreaValidation = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      nbr: requiredString,
      date: requiredDate,
      hour: requiredHour,
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}
const closureOfAWorkAreaValidation = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      nbr: requiredString,
      natureOfWork: requiredString,
      pkStart: requiredPK,
      pkEnd: requiredPK,
      tracks: array().required(I18n.t("errors.required")).min(1, I18n.t("errors.required")),
      closingDate: requiredDate,
      closingHour: requiredHour,
      date: requiredDate,
      hour: requiredHour,
      deliveredDispatchNbr: requiredString,
      receivedDispatchNbr: requiredString
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}
const setStandbyWorkAreaValidation = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      nbr: requiredString,
      natureOfWork: requiredString,
      pkStart: requiredPK,
      pkEnd: requiredPK,
      tracks: array().required(I18n.t("errors.required")).min(1, I18n.t("errors.required")),
      standByDate: requiredDate,
      standByHour: requiredHour,
      date: requiredDate,
      hour: requiredHour,
      deliveredDispatchNbr: requiredString,
      receivedDispatchNbr: requiredString
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

export function workAreaDispatchValidation(data: CreateDispatch) {

  switch (data.type) {
    case DispatchTypeEnum.WORK_ZONE_REQUEST_TO_OPEN_A_WORK_AREA:
      return requestToOpenAWorkAreaValidation(data.dispatch || {});
    case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_OPEN_A_WORK_AREA:
      return authorizationToOpenAWorkAreaValidation(data.dispatch || {});
    case DispatchTypeEnum.WORK_ZONE_NOTICE_OF_OPENING_OF_A_WORK_AREA:
      return noticeOpeningAWorkAreaValidation(data.dispatch || {});
    case DispatchTypeEnum.WORK_ZONE_CLOSURE_OF_A_WORK_AREA:
      return closureOfAWorkAreaValidation(data.dispatch || {});
    case DispatchTypeEnum.WORK_ZONE_SET_STANDBY_WORK_AREA:
      return setStandbyWorkAreaValidation(data.dispatch || {});
    case DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_RESTARTING_A_WORK_AREA_IN_STANDBY:
      return requestForRestartingAWorkArealnStandbyValidation(data.dispatch || {});
    case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_RESTARTING_A_WORK_AREA_IN_STANDBY:
      return authorizationForRestartingAWorkArealnStandbyValidation(data.dispatch || {});
    case DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA:
      return requestForModificationOfTheWorkAreaValidation(data.dispatch || {});
    case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_MODIFY_THE_WORK_AREA:
      return authorizationToModifyTheWorkAreaValidation(data.dispatch || {});
    case DispatchTypeEnum.WORK_ZONE_NOTICE_OF_MODIFICATION_OF_THE_WORK_AREA:
      return noticeOfModificationOfTheWorkAreaValidation(data.dispatch || {});
  }

  return {};
}
