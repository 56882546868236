import {alpha} from "@mui/material";
import {createStyles} from '@mui/styles';

import {Colors} from "@utils/theme/constants.utils";

const classes = () =>
	createStyles({
		selectFile: {
			alignItems: 'center',
			backgroundColor: Colors.inputBackground,
			borderRadius: 6,
			display: 'flex',
			flexDirection: 'row',
			height: 38,
			justifyContent: 'flex-start',
		},
		fileButton: {
			alignItems: 'center',
			color: Colors.text + " !important",
			display: 'flex',
			flexDirection: 'row',
			fontSize: '14px !important',
			fontWeight: '400 !important',
			height: '100%',
			justifyContent: 'space-between !important',
			padding: '0 14px 0 14px !important',
			whiteSpace: 'nowrap',
			width: '100%',
		},
		fileButtonPlaceholder: {
			alignItems: 'center',
			color: alpha(Colors.text, 0.5) + " !important",
			display: 'flex',
			flexDirection: 'row',
			fontSize: '14px !important',
			fontWeight: '400 !important',
			height: '100%',
			justifyContent: 'space-between !important',
			padding: '0 14px 0 14px !important',
			whiteSpace: 'nowrap',
			width: '100%',
		},
		fileName: {
			flex: 1,
			overflow: 'hidden',
			textAlign: 'left',
			textOverflow: 'ellipsis',
		},
	});
export default classes;
