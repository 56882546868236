import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";

import {Role, User, usersQuery} from "@store/users";
import {projectQuery, projectService} from '@store/project';

import SelectUserModal from '@screens/auth/common/selectUser/SelectUser.modal';

const AssociateClientModal = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const handleClose = () => navigate("..");

  const [customers, setCustomers] = useState<User[]>([]);

  useEffect(() => {
    const _customers$ = projectQuery.projectCustomers$.subscribe(setCustomers);

    return () => {
      _customers$.unsubscribe();
    };
  }, [enqueueSnackbar]);

  const handleValidate = (user: User) => {
    projectService.associateCustomer(user.id).subscribe({
      next: () => {
        enqueueSnackbar(t("projectDetails.projectInfo.clients.success.associated"), {variant: "success"});
        handleClose();
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }

  return (
    <SelectUserModal
      button={t("global.validate")}
      handleAdd={handleValidate}
      handleClose={handleClose}
      search={t("projectDetails.projectInfo.clients.associate.search")}
      getUsersByRole={usersQuery.usersByRole$(Role.CLIENT)}
      usersDisabled={customers}
      title={t("projectDetails.projectInfo.clients.associate.title")}/>
  );
};

export default AssociateClientModal;
