import {Components} from "@mui/material";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    "schema-element": true;
  }
}

const typography: Components = {
  MuiTypography: {
    styleOverrides: {
      root: (props) => ({
        color: props.ownerState.variant === "schema-element" && props.ownerState.color
          ? props.ownerState.color as string
          : undefined,
        lineHeight: 1.25,
      }),
    },
  },
};

export default typography;
