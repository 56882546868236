import {OrderLogic} from "@store/dispatches/orderLogic/orderLogicMap";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {TrackElement} from '@store/project';

export const getZFOrderLogic = (trackElement: TrackElement): OrderLogic[] => {
  return [
    {
      dispatchType: DispatchTypeEnum.AUTHORIZATION_TRAINING_ZONE,
      dependsOf: [
        {
          condition: !trackElement.attributes?.RZF,
          dispatchType: null,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.MODIFICATION_TRAINING_ZONE,
      dependsOf: [
        {
          condition: true,
          dispatchType: DispatchTypeEnum.AUTHORIZATION_TRAINING_ZONE,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.MODIFICATION_TRAINING_ZONE,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.CONFIRMATION_INITIAL_DEMARCATION_TRAINING_ZONE,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.CONFIRMATION_INITIAL_DEMARCATION_TRAINING_ZONE,
      dependsOf: [
        {
          condition: true,
          dispatchType: DispatchTypeEnum.MODIFICATION_TRAINING_ZONE,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.END_MISSION_TRAINING_ZONE,
      dependsOf: [
        {
          condition: true,
          dispatchType: DispatchTypeEnum.AUTHORIZATION_TRAINING_ZONE,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.MODIFICATION_TRAINING_ZONE,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.CONFIRMATION_INITIAL_DEMARCATION_TRAINING_ZONE,
        },
      ],
    },
  ];
}
