import React, {useEffect} from 'react';
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {sessionQuery} from "@store/session";
import {Grid} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";

const MovementTransferForm = (props: DispatchFormGlobalProps) => {

  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const updateDispatchContent = (name: string) => (value: any) => setDispatch({
    ...dispatch,
    dispatch: {...dispatch.dispatch, [name]: value}
  });

  useEffect(() => {
    setDispatch({
      type: DispatchTypeEnum.MOVEMENT_TRANSFER,
      dispatch: {
        from: `${sessionQuery.getValue().user?.firstname || ''} ${sessionQuery.getValue().user?.lastname || ''}`,
        to: trackElement?.attributes.ATTX,
        date: new Date(),
        hour: new Date()
      }
    })
  }, [])

  return (
    <div>
      <Grid container direction={"row"} columnSpacing={2.5} rowSpacing={1.5}>
        <Grid item xs={4}>
          <TextFieldComponent label={"M./Mme ATTx (cédant)"}
                              readOnly={!editable}
                              value={dispatch?.dispatch?.from || ''}
                              handleChange={updateDispatchContent("from")}
                              error={errors["from"]}/>
        </Grid>

        <Grid item xs={4}>
          <TextFieldComponent label={"M./Mme ATTx (prenant)"}
                              readOnly={!editable}
                              value={dispatch?.dispatch?.to || ''}
                              handleChange={updateDispatchContent("to")}
                              error={errors["to"]}/>
        </Grid>

        <Grid item xs={8}>
          <DatePickerComponent
            label={"Je vous remets le service le"}
            readOnly={!editable}
            value={dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("date")}
            error={errors["date"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            label={"à"}
            readOnly={!editable}
            value={editable ? dispatch?.dispatch?.hour : dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("hour")}
            error={errors["hour"]}
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldComponent
            label={"Informations complémentaires"}
            multiline
            readOnly={!editable}
            rows={3}
            handleChange={updateDispatchContent("description")}
            value={dispatch?.dispatch?.description || ""}
            error={errors["description"]}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default MovementTransferForm;
