import React from 'react';
import {Card, CardActionArea, Stack, Typography} from "@mui/material";
import {Order} from "@store/dispatches/dispatch.model";
import {useTranslation} from "react-i18next";

export interface OrderCardProps {
  name: string;
  order: Order;
  onClick: () => void;
}

const OrderCard = (props: OrderCardProps) => {
  const {name, order, onClick} = props;

  const {t} = useTranslation();

  return (
    <Card component={CardActionArea} variant="order" onClick={onClick}>
      <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="column" spacing={0.5}>
          <Typography fontSize={16} fontWeight={700} color="primary">{name}</Typography>
          <Typography fontSize={12} fontWeight={400}>
            {t("dispatches.dispatchCount", {count: order.dispatches?.length || 0})}
          </Typography>
        </Stack>
        <img
          alt="go to order"
          style={{height: 20, width: 20}}
          src="/images/aroow_right_blue_icon.svg"/>
      </Stack>
    </Card>
  );
}

export default OrderCard;
