import React, {useCallback, useEffect, useState} from 'react';
import {map, of, Subscription, switchMap} from "rxjs";

import {sessionQuery, sessionService} from "@store/session";
import {projectsService} from "@store/projects";

import displayAlert from "@utils/snackbar.utils";

import Auth from "./auth/Auth";
import UnAuth from "./unauth/UnAuth";
import LoadingScreen from "@screens/loading/Loading.screen";

const Routes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const getUser = useCallback(() => {
    return sessionQuery.currentUser$
      .pipe(
        switchMap((user) => {
          if (user) {
            return projectsService.getProjects().pipe(map(() => true));
          }
          return of(false);
        }),
      )
      .subscribe((isAuthenticated) => {
        setIsAuthenticated(isAuthenticated)
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    let _user$: Subscription | undefined;

    setTimeout(() => {
      if (sessionQuery.token) {
        sessionService.fetchCurrentUser().subscribe({
          next: () => {
            _user$ = getUser();
          },
          error: (err) => displayAlert.error(err.text),
        });
      } else {
        _user$ = getUser();
      }
    }, 1500);

    return () => {
      _user$?.unsubscribe();
    }
  }, [getUser]);

  if (isLoading) {
    return <LoadingScreen/>;
  }

  return isAuthenticated ? <Auth/> : <UnAuth/>;
}

export default Routes;