import React, {useEffect} from 'react';
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {Grid} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import SelectComponent from "@components/select/Select.component";
import {Pk, PK_INPUT_REGEXP, projectQuery} from "@store/project";

const UnenrailementWorksiteMachineForm = (props: DispatchFormGlobalProps) => {
  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const updateDispatchContent = (name: string) => (value: any) => setDispatch({
    ...dispatch,
    dispatch: {...dispatch.dispatch, [name]: value}
  });

  useEffect(() => {
    setDispatch({
      type: DispatchTypeEnum.UNENRAILMENT_WORKSITE_MACHINE,
      dispatch: {
        machineNbr: trackElement?.attributes?.name,
        startDate: new Date(),
        workZoneNbr: trackElement?.attributes?.ZT,
        tracks: trackElement?.tracks,
        pkStart: Pk.toString(trackElement?.pkStart),
      },
    })
  }, []);

  return (
    <div>
      <Grid container direction={"row"} columnSpacing={2.5} rowSpacing={1.5}>
        <Grid item xs={12}>
          <TextFieldComponent
            label={"N° de l'engin"}
            readOnly
            value={dispatch?.dispatch?.machineNbr || ''}
            handleChange={updateDispatchContent("machineNbr")}
            error={errors["machineNbr"]}/>
        </Grid>

        <Grid item xs={12}>
          <SelectComponent
            label={"N° du CE"}
            readOnly={!editable}
            value={dispatch?.dispatch?.workZoneNbr || ""}
            items={projectQuery.projectCE.map((zt) => ({
              label: zt.attributes.name || "-",
              value: zt.id,
            }))}
            handleChange={updateDispatchContent("workZoneNbr")}
            error={errors["workZoneNbr"]}/>
        </Grid>

        <Grid item xs={6}>
          <TimePickerComponent
            label={"Heure de désenraillement"}
            readOnly={!editable}
            value={dispatch?.dispatch?.startDate}
            handleChange={updateDispatchContent("startDate")}
            error={errors["startDate"]}/>
        </Grid>

        <Grid item xs={6}>
          <TextFieldComponent
            label={"PK de désenraillement"}
            readOnly={!editable}
            placeholder=" "
            handleChange={updateDispatchContent("pkStart")}
            pattern={PK_INPUT_REGEXP}
            value={dispatch?.dispatch?.pkStart || ""}
            error={errors["pkStart"]}/>
        </Grid>

        <Grid item xs={12}>
          <SelectComponent
            label={"Voie concernée"}
            readOnly
            items={projectQuery.projectTracks.map((track) => ({
              label: track.attributes.name || "-",
              value: track.id,
            }))}
            value={dispatch?.dispatch?.tracks?.[0] ?? ""}/>
        </Grid>

        <Grid item xs={12}>
          <TextFieldComponent
            multiline
            rows={4}
            readOnly={!editable}
            label={"Informations complémentaires"}
            handleChange={updateDispatchContent("additionalInformation")}
            value={dispatch?.dispatch?.additionalInformation || ""}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default UnenrailementWorksiteMachineForm;
