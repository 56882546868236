import React, {useEffect, useState} from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Box, Button, Grid, Stack} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {Role, User, usersService} from "@store/users";
import {sessionQuery} from '@store/session';

import ModalComponent from '@components/modals/Modal.component';
import TextFieldComponent from '@components/textField/TextField.component';

const ClientDetailsModal = () => {
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const {userId} = useParams();
  const {t} = useTranslation();

  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (userId) {
      usersService.getUserById(userId).subscribe({
        next: setUser,
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  }, [enqueueSnackbar, userId]);

  if (!user) return null;

  const handleClose = () => navigate("..");

  return (
    <>
      <ModalComponent
        handleClose={handleClose}
        title={`${user.lastname.toUpperCase()} ${user.firstname}`}
        description={t("projectDetails.projectInfo.clients.details.description")}
        content={(
          <Stack width="450px" mt="15px" spacing={4}>
            <Box>
              <Grid container columnSpacing={2.5} rowSpacing={1.5}>
                <Grid item xs={12}>
                  <TextFieldComponent
                    label={t("users.fields.lastname")}
                    value={user.lastname || ""}
                    readOnly/>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldComponent
                    label={t("users.fields.firstname")}
                    value={user.firstname || ""}
                    readOnly/>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldComponent
                    label={t("users.fields.email")}
                    value={user.email || ""}
                    readOnly/>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldComponent
                    label={t("users.fields.function")}
                    value={user.role || ""}
                    readOnly/>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        )}
        actions={sessionQuery.hasSufficientRoleOrAbove(Role.OS_R) ? (
          <Button variant="contained" color="error" onClick={() => navigate("delete")}>
            {t("projectDetails.projectInfo.clients.details.remove")}
          </Button>
        ) : undefined}/>
      <Outlet/>
    </>
  );
};

export default ClientDetailsModal;
