import {EntityState, EntityStore, ID, StoreConfig} from '@datorama/akita';

import {ProjectLight} from "@store/projects/projects.model";

export interface ProjectsState extends EntityState<ProjectLight, ID> {
}

@StoreConfig({name: 'projects', resettable: true})
export class ProjectsStore extends EntityStore<ProjectsState, ProjectLight> {
}

export const projectsStore = new ProjectsStore();
