import {date, mixed, object, ref, string} from 'yup';

import {CreateUser, Role} from "@store/users";

import * as YupUtils from "@utils/yup.utils";
import I18n from "@utils/i18n.utils";

export const createUserValidation = (data: Partial<CreateUser>) => {
  const requiredString = string().required(I18n.t("errors.required"));
  const requiredDate = date().nullable()
    .required(I18n.t("errors.required"))
    .typeError(I18n.t("errors.date"));

  try {
    const schema = object().shape({
      firstname: requiredString,
      lastname: requiredString,
      password: requiredString,
      confirmPassword: requiredString.oneOf([ref('password')], I18n.t("errors.confirmPassword")),
      email: string().required(I18n.t("errors.required")).email(I18n.t("errors.email")),
      role: requiredString,
      formationLocalFile: mixed().when("role", {
        is: (role: Role) => role !== Role.CLIENT,
        then: mixed().required(I18n.t("errors.required")),
      }),
      formationExpireDate: date().when("role", {
        is: (role: Role) => role !== Role.CLIENT,
        then: requiredDate,
      }),
      habilitationLocalFile: mixed().when("role", {
        is: (role: Role) => role !== Role.CLIENT,
        then: mixed().required(I18n.t("errors.required")),
      }),
      habilitationExpireDate: date().when("role", {
        is: (role: Role) => role !== Role.CLIENT,
        then: requiredDate,
      }),
      medicalVisitLocalFile: mixed().when("role", {
        is: (role: Role) => role !== Role.CLIENT,
        then: mixed().required(I18n.t("errors.required")),
      }),
      medicalVisitExpireDate: date().when("role", {
        is: (role: Role) => role !== Role.CLIENT,
        then: requiredDate,
      }),
    });

    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}
