import { createStyles } from "@mui/styles";

const classes = () => createStyles({
  close: {
    position: "absolute",
    top: 15,
    left: 15,
    height: 25,
    width: 25
  },
  contentContainer: {
    minWidth: 400,
    height: "100%"
  }
});

export default classes;