import React, {useMemo} from "react";
import {CircularProgress, Stack, Typography} from '@mui/material';
import {Chart} from "react-google-charts";

import {Colors} from '@utils/theme/constants.utils';

interface DoughnutChartLabel<T> {
  field: keyof T;
  label: string;
}

interface DoughnutChartProps<T> {
  data: (T & { color: string })[];
  displayTotal?: boolean;
  labels: [DoughnutChartLabel<T>, DoughnutChartLabel<T>];
  loading?: boolean;
  getTooltip?: (data: T & { color: string }) => string;
  total?: number;
}

const DoughnutChart = <T, >(props: DoughnutChartProps<T>) => {
  const {data, displayTotal, labels, loading, getTooltip, total = 0} = props;

  const formattedData = useMemo(() => {
    return [
      labels.map((x) => x.label).concat(
        ...(getTooltip ? [{role: "tooltip", type: "string", p: {html: true}}] : []) as any
      ),
      ...data.map((x) => [
        x[labels[0].field],
        x[labels[1].field],
        ...(getTooltip ? [getTooltip(x)] : []),
      ])
    ];
  }, [data, labels]);

  return (
    <Stack flex={1} position="relative" width="100%" alignItems="center" justifyContent="center">
      {loading ? (
        <CircularProgress/>
      ) : (
        <>
          <Chart
            width="100%"
            height="100%"
            chartType="PieChart"
            data={formattedData}
            options={{
              fontName: 'Axiforma, sans-serif',
              fontSize: 14,
              pieHole: 0.55,
              pieSliceTextStyle: {color: Colors.black, fontSize: 11},
              colors: data.length ? data.map((x) => x.color) : undefined,
              chartArea: {width: "80%", height: "75%"},
              legend: {alignment: "center"},
              tooltip: {
                isHtml: true,
                trigger: !!getTooltip ? 'selection' : 'none',
                textStyle: {color: Colors.black},
              },
            }}
          />
          {!!displayTotal && (
            <Typography
              fontSize="26px"
              fontWeight="800"
              color="primary"
              position="absolute"
              sx={{transform: 'translateX(-50%)'}}
              pt="5px"
              left="35%">
              {total}
            </Typography>
          )}
        </>
      )}
    </Stack>
  );
}

export default DoughnutChart;