import React from "react";
import {TimePicker} from "@mui/lab";
import {InputLabel, TextField} from "@mui/material";

interface TimePickerProps {
  color?: "primary" | "secondary";
  disabled?: boolean;
  error?: string;
  format?: string;
  handleChange?: (date?: Date) => void;
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  value?: Date;
}

const TimePickerComponent = (props: TimePickerProps) => {
  const {
    color = "primary",
    disabled,
    error,
    format,
    handleChange,
    label,
    placeholder,
    readOnly,
    required,
    value
  } = props;

  return (
    <TimePicker
      disabled={disabled}
      readOnly={readOnly}
      value={value || null}
      showToolbar
      inputFormat={format || "HH:mm"}
      onChange={(date) => handleChange?.(date || undefined)}
      renderInput={(params) => (
        <>
          {!!label && <InputLabel error={!!error}>{label}{(required) ? '*' : ''}</InputLabel>}
          <TextField
            {...params}
            focused={readOnly ? false : undefined}
            InputProps={{
              ...params.InputProps,
              endAdornment: <img alt="" src="/images/time_icon.svg"/>,
            }}
            error={!!error}
            helperText={error}
            fullWidth
            inputProps={{
              ...params.inputProps,
              readOnly,
              placeholder: placeholder || label,
            }}
            color={color}/>
        </>
      )}
      PopperProps={{
        // @ts-ignore
        sx: {"& *:focus": {outline: "none"}},
      }}/>
  );
}

export default TimePickerComponent;
