import {OrderLogic} from "@store/dispatches/orderLogic/orderLogicMap";
import {DispatchTypeEnum, Order} from "@store/dispatches/dispatch.model";
import {Pk, projectQuery, TrackElement, WorkTrainDirectionEnum} from "@store/project";

const isTTXNextToInactiveZF = (trackElement: TrackElement): boolean => {
  if (!trackElement.attributes?.finalPk || !trackElement.attributes?.direction) return false;

  if (trackElement.attributes.direction === WorkTrainDirectionEnum.RIGHT_TO_LEFT) {
    return !!projectQuery.projectZF
      .filter((ZF) =>
        !ZF.attributes?.RZF && !!ZF.pkEnd && Pk.toMeters(ZF.pkEnd) === Pk.toMeters(trackElement.pkStart)
      ).length;
  } else if (trackElement.attributes.direction === WorkTrainDirectionEnum.LEFT_TO_RIGHT) {
    return !!projectQuery.projectZF
      .filter((ZF) =>
        !ZF.attributes?.RZF && !!ZF.pkEnd && Pk.toMeters(ZF.pkStart) === (Pk.toMeters(trackElement.pkStart) + trackElement.attributes.TTXLength ?? 0)
      ).length;
  } else {
    return false;
  }
}

const isTTXNextToZTOrActiveZF = (trackElement: TrackElement): boolean => {
  if (!trackElement.attributes?.finalPk || !trackElement.attributes?.direction) return false;

  if (trackElement.attributes.direction === WorkTrainDirectionEnum.RIGHT_TO_LEFT) {
    return !![
      ...projectQuery.projectZT
        .filter((ZT) =>
          !ZT.attributes.isNotPassable && !!ZT.pkEnd && Pk.toMeters(ZT.pkEnd) === Pk.toMeters(trackElement.pkStart)
        ),
      ...projectQuery.projectZF
        .filter((ZF) =>
          !!ZF.attributes?.RZF && !!ZF.pkEnd && Pk.toMeters(ZF.pkEnd) === Pk.toMeters(trackElement.pkStart)
        ),
    ].length;
  } else if (trackElement.attributes.direction === WorkTrainDirectionEnum.LEFT_TO_RIGHT) {
    return !![
      ...projectQuery.projectZT
        .filter((ZT) =>
          !ZT.attributes.isNotPassable && !!ZT.pkEnd && Pk.toMeters(ZT.pkStart) === (Pk.toMeters(trackElement.pkStart) + trackElement.attributes.TTXLength ?? 0)
        ),
      ...projectQuery.projectZF
        .filter((ZF) =>
          !!ZF.attributes?.RZF && !!ZF.pkEnd && Pk.toMeters(ZF.pkStart) === (Pk.toMeters(trackElement.pkStart) + trackElement.attributes.TTXLength ?? 0)
        ),
    ].length;
  } else {
    return false;
  }
}

const isTTXIntoInactiveZF = (order: Order): boolean => {
  const lastDispatch = order?.dispatches?.at(-1);
  if (!lastDispatch || lastDispatch.type !== DispatchTypeEnum.MOVEMENT_ENGAGEMENT || !lastDispatch.dispatch.arrivalDate) {
    return false;
  }

  const currentZF = projectQuery.projectZF.find((ZF) => ZF.id === lastDispatch.dispatch.workZoneNbr);
  return !!currentZF && !currentZF.attributes?.RZF;
}

const needToImmobilizeTTx = (order: Order): boolean => {
  const lastDispatch = order?.dispatches?.filter((d) => d.type !== DispatchTypeEnum.MOVEMENT_RESET).at(-1);
  if (!lastDispatch || lastDispatch.type !== DispatchTypeEnum.MOVEMENT_ENGAGEMENT) return false;

  return lastDispatch.dispatch.immobilization;
}

const checkIfEngagementStepDone = (order: Order, key: 'engagementAuthorizedDate' | 'arrivalDate'): boolean => {
  const lastDispatch = order?.dispatches?.at(-1);
  if (!lastDispatch || lastDispatch.type !== DispatchTypeEnum.MOVEMENT_ENGAGEMENT) return false;

  return !!lastDispatch.dispatch[key];
}

export const getTTxOrderLogic = (trackElement: TrackElement, order: Order): OrderLogic[] => {
  return [
    {
      dispatchType: DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE,
      dependsOf: [
        {
          condition: true,
          dispatchType: null,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.MOVEMENT_REMOVAL,
      dependsOf: [
        {
          condition: isTTXNextToInactiveZF(trackElement),
          dispatchType: DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE,
        },
        {
          condition: isTTXNextToInactiveZF(trackElement),
          dispatchType: DispatchTypeEnum.MOVEMENT_RELEASE,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.MOVEMENT_ENGAGEMENT,
      dependsOf: [
        {
          condition: !!trackElement.attributes?.isImmobilized,
          dispatchType: null,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.MOVEMENT_REMOVAL,
        },
        {
          condition: isTTXNextToZTOrActiveZF(trackElement),
          dispatchType: DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE,
        },
        {
          condition: isTTXNextToZTOrActiveZF(trackElement),
          dispatchType: DispatchTypeEnum.MOVEMENT_RELEASE,
        },
        {
          condition: isTTXNextToZTOrActiveZF(trackElement) && !needToImmobilizeTTx(order),
          dispatchType: DispatchTypeEnum.MOVEMENT_RESET,
        },
        {
          condition: !needToImmobilizeTTx(order) && checkIfEngagementStepDone(order, 'arrivalDate'),
          dispatchType: DispatchTypeEnum.MOVEMENT_ENGAGEMENT,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.MOVEMENT_ENGAGEMENT_AUTHORIZATION,
      dependsOf: [
        {
          condition: !checkIfEngagementStepDone(order, 'engagementAuthorizedDate'),
          dispatchType: DispatchTypeEnum.MOVEMENT_ENGAGEMENT,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.MOVEMENT_ENGAGEMENT_ARRIVAL,
      dependsOf: [
        {
          condition: checkIfEngagementStepDone(order, 'engagementAuthorizedDate') && !checkIfEngagementStepDone(order, 'arrivalDate'),
          dispatchType: DispatchTypeEnum.MOVEMENT_ENGAGEMENT,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.MOVEMENT_RESET,
      dependsOf: [
        {
          condition: isTTXIntoInactiveZF(order),
          dispatchType: DispatchTypeEnum.MOVEMENT_ENGAGEMENT,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.MOVEMENT_RELEASE,
      dependsOf: [
        {
          condition: checkIfEngagementStepDone(order, 'arrivalDate') && !needToImmobilizeTTx(order),
          dispatchType: DispatchTypeEnum.MOVEMENT_ENGAGEMENT,
        },
        {
          condition: !needToImmobilizeTTx(order),
          dispatchType: DispatchTypeEnum.MOVEMENT_RESET,
        }
      ],
    },
    {
      dispatchType: DispatchTypeEnum.MOVEMENT_IMMOBILIZATION_TTX,
      dependsOf: [
        {
          condition: checkIfEngagementStepDone(order, 'arrivalDate') && needToImmobilizeTTx(order),
          dispatchType: DispatchTypeEnum.MOVEMENT_ENGAGEMENT,
        },
        {
          condition: needToImmobilizeTTx(order),
          dispatchType: DispatchTypeEnum.MOVEMENT_RESET,
        }
      ],
    },
    {
      dispatchType: DispatchTypeEnum.SPLITTING_SPLITTING,
      dependsOf: [
        {
          condition: trackElement.attributes?.authorizedSplitting,
          dispatchType: null,
        },
        {
          condition: trackElement.attributes?.authorizedSplitting,
          dispatchType: DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE,
        },
        {
          condition: trackElement.attributes?.authorizedSplitting,
          dispatchType: DispatchTypeEnum.MOVEMENT_ENGAGEMENT_ARRIVAL,
        },
        {
          condition: trackElement.attributes?.authorizedSplitting,
          dispatchType: DispatchTypeEnum.MOVEMENT_RELEASE,
        },
        {
          condition: trackElement.attributes?.authorizedSplitting,
          dispatchType: DispatchTypeEnum.MOVEMENT_RESET,
        },
      ]
    },
    {
      dispatchType: DispatchTypeEnum.SPLITTING_POST_SPLITTING,
      dependsOf: [
        {
          condition: true,
          dispatchType: DispatchTypeEnum.SPLITTING_SPLITTING,
        },
      ]
    },
    {
      dispatchType: DispatchTypeEnum.SPLITTING_GROUPING,
      dependsOf: [
        {
          condition: true,
          dispatchType: null
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.MOVEMENT_ENGAGEMENT_ARRIVAL,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.MOVEMENT_RELEASE,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.MOVEMENT_RESET,
        },
      ]
    }
  ];
}
