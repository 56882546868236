import React from "react";

import {Colors} from "@utils/theme/constants.utils";

import {TrackElement, TrackElementType, TRAIN_TRACK_HEIGHT} from "@store/project";

interface TrackChangeElementProps {
  handleClick?: () => void;
  fromTrack: TrackElement;
  toTrack: TrackElement;
  deflect?: boolean;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

const TrackChangeElement = (props: TrackChangeElementProps) => {
  const {deflect, fromTrack, handleClick, toTrack, x1, x2, y1, y2} = props;

  const priority = [TrackElementType.MAIN_TRACK, TrackElementType.SIDING_TRACK, TrackElementType.SERVICE_TRACK, TrackElementType.TRACK_OUT_OF_SERVICE];

  const belongsTo = priority.indexOf(fromTrack.type) > priority.indexOf(toTrack.type) ? fromTrack : toTrack;

  const trackHeight = belongsTo.type === TrackElementType.MAIN_TRACK ? TRAIN_TRACK_HEIGHT : TRAIN_TRACK_HEIGHT / 2;
  const halfOpacity = belongsTo.type === TrackElementType.TRACK_OUT_OF_SERVICE;

  return (
    <g>
      <rect x={x1 - trackHeight / 2} y={y1 - trackHeight / 2}
            height={trackHeight} width={trackHeight}
            rx={trackHeight / 2} ry={trackHeight / 2}
            opacity={halfOpacity ? 0.3 : 1}
            fill={Colors.schema.track}/>
      <rect x={x2 - trackHeight / 2} y={y2 - trackHeight / 2}
            height={trackHeight} width={trackHeight}
            rx={trackHeight / 2} ry={trackHeight / 2}
            opacity={halfOpacity ? 0.3 : 1}
            fill={Colors.schema.track}/>
      <line
        x1={x1} y1={y1} x2={x2} y2={y2}
        opacity={halfOpacity ? 0.3 : 1}
        strokeWidth={trackHeight} stroke={deflect ? Colors.schema.elements.PRS : Colors.schema.track}/>
      <line
        x1={x1} y1={y1} x2={x2} y2={y2}
        onClick={handleClick} style={{cursor: handleClick ? 'pointer' : undefined, opacity: 0}}
        strokeWidth={trackHeight * 2} stroke={Colors.schema.track}/>
    </g>
  );
}

export default TrackChangeElement;