import {Pk, TrackElement} from "@store/project";
import {IncompatibilityError} from "@utils/incompatibilities/incompatibility.utils";
import checkNoWMonCCI from "./NO_WM_ON_CCI/noWMonCCI";
import checkNoWMOutZT from "@utils/incompatibilities/WM/NO_WM_OUT_ZT/noWMOutZT";
import {ID} from "@datorama/akita";
import checkNoWMOnNotPassableZT from '@utils/incompatibilities/WM/NO_WM_ON_NOT_PASSABLE_ZT/noWMOnNotPassableZT';

interface GetS9A3WMIncParams {
  trackElementId?: ID;
  tracks?: ID[];
  pkStart?: Pk;
  CCIs?: TrackElement[];
  ZTs?: TrackElement[];
}

export const getS9A3WMInc = (params: GetS9A3WMIncParams): IncompatibilityError[] => {
  const {trackElementId, pkStart, CCIs = [], ZTs = []} = params;

  if (!pkStart) return [];

  const incompatibilities: IncompatibilityError[] = [];

  incompatibilities.push(...checkNoWMOutZT({trackElementId, pkStart, ZTs}));

  incompatibilities.push(...checkNoWMonCCI({trackElementId, pkStart, CCIs}));

  incompatibilities.push(...checkNoWMOnNotPassableZT({trackElementId, pkStart, ZTs}));

  return incompatibilities;
};
