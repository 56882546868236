import React, {useState} from "react";
import {Button, Stack} from "@mui/material";
import {CreateOrUpdateTrackElement} from "@store/project";
import {useTranslation} from "react-i18next";
import {FieldErrors} from "@utils/yup.utils";
import {trackElementValidation} from "@screens/auth/common/schema/forms/trackElements/forms.yup";
import ModalComponent from "@components/modals/Modal.component";
import CEForm from "@screens/auth/common/schema/forms/trackElements/CE.form";

import useIncompatibilitiesAcceptation from "../../../components/IncompatibilitiesAcceptation.component";
import { IncompatibilityError } from "@utils/incompatibilities/incompatibility.utils";

interface CreateCEFormProps {
  description: string;
  handleClose: () => void;
  handlePrevious?: () => void;
  handleValidate: (incompatibilities?: IncompatibilityError[]) => void;
  loading?: boolean;
  setTrackElement: (trackElement: CreateOrUpdateTrackElement) => void;
  trackElement: CreateOrUpdateTrackElement;
}

const CreateCEForm = (props: CreateCEFormProps) => {
  const {description, handleClose, handlePrevious, handleValidate, loading, setTrackElement, trackElement} = props;

  const {t} = useTranslation();

  const [errors, setErrors] = useState<FieldErrors>({});
  const [showConfirmCode, setShowConfirmCode] = useState(false);

  const {IncompatibilitiesAcceptation, disabledByIncompatibilities, incompatibilities} = useIncompatibilitiesAcceptation({trackElement, creation: true});

  const handleAdd = () => {
    const errors = trackElementValidation(trackElement);

    setErrors(errors);
    if (!Object.keys(errors).length) handleValidate(incompatibilities);
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t(`schema.CE.title.add`)}
      description={description}
      content={
        <Stack>
          <CEForm errors={errors} trackElement={trackElement} setTrackElement={setTrackElement}/>
          {IncompatibilitiesAcceptation && (
            <Stack pt="20px">
              <IncompatibilitiesAcceptation/>
            </Stack>
          )}
        </Stack>
      }
      actions={
        <>
          {!!handlePrevious && (
            <Button variant="outlined" color="primary" disabled={loading} onClick={handlePrevious}>
              {t("global.previous")}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={loading || disabledByIncompatibilities}
            onClick={handleAdd}>
            {t("global.add")}
          </Button>
        </>
      }
    />
  );
};

export default CreateCEForm;
