import React, {useState} from "react";

import {
  CreateOrUpdateTrackElement,
  Pk,
  projectQuery,
  projectService,
  TrackCursor,
  TrackElementCategory,
  TrackElementType
} from "@store/project";
import {finalize} from "rxjs";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";

import TrainTrackForm from "@screens/auth/common/schema/forms/trackElements/TrainTrack.form";
import TrackDeviceForm from "@screens/auth/common/schema/forms/trackElements/TrackDevice.form";
import InstallationForm from "@screens/auth/common/schema/forms/trackElements/Installation.form";
import SignalisationForm from "@screens/auth/common/schema/forms/trackElements/Signalisation.form";
import ZoneForm from "@screens/auth/common/schema/forms/trackElements/Zone.form";
import PRSForm from "@screens/auth/common/schema/forms/trackElements/PRS.form";
import DeclivityForm from "@screens/auth/common/schema/forms/trackElements/Declivity.form";
import WorksiteForm from "@screens/auth/common/schema/forms/trackElements/Worksite.form";
import ActivityForm from "@screens/auth/common/schema/forms/trackElements/Activity.form";
import RestrictionsForm from "@screens/auth/common/schema/forms/trackElements/Restrictions.form";
import CreateWorkAreaForm from "@screens/auth/common/schema/forms/trackElements/creationModals/CreateWorkArea.form";
import TrainStationForm from "@screens/auth/common/schema/forms/trackElements/TrainStation.form";
import CreateWorkTrainForm from "@screens/auth/common/schema/forms/trackElements/creationModals/CreateWorkTrain.form";
import ConstructionForm from "@screens/auth/common/schema/forms/trackElements/Construction.form";
import CreateWorksiteMachineForm
  from "@screens/auth/common/schema/forms/trackElements/creationModals/CreateWorksiteMachine.form";
import CreateCEForm from "@screens/auth/common/schema/forms/trackElements/creationModals/CreateCE.form";
import ConfirmCodeModal from "@components/confirmCode/ConfirmCode.modal";
import {sessionService} from "@store/session";
import {IncompatibilityError} from "@utils/incompatibilities/incompatibility.utils";

interface CreateTrackElementFormProps {
  handleClose: () => void;
  handlePrevious?: () => void;
  trackCursor?: TrackCursor;
  trackElementCategory: TrackElementCategory;
}

const CreateTrackElementForm = (props: CreateTrackElementFormProps) => {
  const {handleClose, handlePrevious, trackCursor, trackElementCategory} = props;

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [showConfirmCode, setShowConfirmCode] = useState<IncompatibilityError[] | false>(false);

  const getTrackElementType = () => {
    switch (trackElementCategory) {
      case TrackElementCategory.TRACK_DEVICE:
        return TrackElementType.TRACK_CHANGE;
      case TrackElementCategory.SIGNALISATION:
        return projectQuery.isProjectTypeNew ? TrackElementType.SIGNALISATION : TrackElementType.WHISTLE;
      case TrackElementCategory.ZONE:
        return TrackElementType.TRAINING_ZONE;
      case TrackElementCategory.PRS:
        return TrackElementType.PRS;
      case TrackElementCategory.DECLIVITY:
        return TrackElementType.DECLIVITY;
      case TrackElementCategory.WORK_ZONE:
        return TrackElementType.WORK_ZONE;
      case TrackElementCategory.ELEMENTARY_CONSTRUCTION_SITE:
        return TrackElementType.ELEMENTARY_CONSTRUCTION_SITE;
      case TrackElementCategory.WORK_TRAIN:
        return TrackElementType.WORK_TRAIN;
      case TrackElementCategory.WORKSITE:
        return TrackElementType.WORKSITE;
      case TrackElementCategory.WORKSITE_MACHINE:
        return TrackElementType.WORKSITE_MACHINE;
      case TrackElementCategory.TRAIN_STATION:
        return TrackElementType.TRAIN_STATION;
      case TrackElementCategory.CONSTRUCTION:
        return TrackElementType.CONSTRUCTION;
      default:
        return undefined;
    }
  };

  const getElementState = () => {
    switch (trackElementCategory) {
      default:
        return undefined;
    }
  };

  const [trackElement, setTrackElement] = useState<CreateOrUpdateTrackElement>({
    category: trackElementCategory,
    type: getTrackElementType(),
    tracks: trackCursor?.track ? [trackCursor.track] : undefined,
    pkStart: trackCursor ? Pk.toString(trackCursor.pk) : undefined,
    attributes: {
      state: getElementState(),
      isFlat: trackElementCategory === TrackElementCategory.DECLIVITY ? false : undefined,
      lineId: trackElementCategory === TrackElementCategory.TRAIN_TRACK ? projectQuery.projectInfo.lineId : undefined,
    },
  });

  const handleValidate = (incompatibilities?: IncompatibilityError[]) => {
    projectService
      .createTrackElement(
        trackElement,
        incompatibilities?.map((i) => t(`incompatibilities.${i.error}`))
      )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("schema.success.elementCreated"), {variant: "success"});
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const displayRightTrackElement = () => {
    switch (trackElementCategory) {
      case TrackElementCategory.TRAIN_TRACK:
        return (
          <TrainTrackForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.trainTrack.title.add")}
            description={t("schema.trainTrack.description.add")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
            handlePrevious={handlePrevious || (() => undefined)}
          />
        );
      case TrackElementCategory.TRACK_DEVICE:
        return (
          <TrackDeviceForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.trackDevice.title.add")}
            description={t("schema.trackDevice.description.add")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
            handlePrevious={handlePrevious || (() => undefined)}
          />
        );
      case TrackElementCategory.INSTALLATION:
        return (
          <InstallationForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.installation.title.add")}
            description={t("schema.installation.description.add")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
            handlePrevious={handlePrevious || (() => undefined)}
          />
        );
      case TrackElementCategory.SIGNALISATION:
        return (
          <SignalisationForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.signalisation.title.add")}
            description={t("schema.signalisation.description.add")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
            handlePrevious={handlePrevious || (() => undefined)}
          />
        );
      case TrackElementCategory.ZONE:
        return (
          <ZoneForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.zone.title.add")}
            description={t("schema.zone.description.add")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
            handlePrevious={handlePrevious || (() => undefined)}
          />
        );
      case TrackElementCategory.PRS:
        return (
          <PRSForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.PRS.title.add")}
            description={t("schema.PRS.description.add")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
            handlePrevious={handlePrevious || (() => undefined)}
          />
        );
      case TrackElementCategory.TRAIN_STATION:
        return (
          <TrainStationForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.trainStation.title.add")}
            description={t("schema.trainStation.description.add")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
            handlePrevious={handlePrevious || (() => undefined)}
          />
        );
      case TrackElementCategory.CONSTRUCTION:
        return (
          <ConstructionForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.construction.title.add")}
            description={t("schema.construction.description.add")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
            handlePrevious={handlePrevious || (() => undefined)}
          />
        );
      case TrackElementCategory.DECLIVITY:
        return (
          <DeclivityForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.declivity.title.add")}
            description={t("schema.declivity.description.add")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
            handlePrevious={handlePrevious || (() => undefined)}
          />
        );
      case TrackElementCategory.WORKSITE:
        return (
          <WorksiteForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.worksite.title.add")}
            description={t("schema.worksite.description.add")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
            handlePrevious={handlePrevious || (() => undefined)}
          />
        );
      case TrackElementCategory.ACTIVITY:
        return (
          <ActivityForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.activity.title.add")}
            description={t("schema.activity.description.add")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
            handlePrevious={handlePrevious || (() => undefined)}
          />
        );
      case TrackElementCategory.WORK_ZONE:
        return (
          <CreateWorkAreaForm
            loading={loading}
            trackElement={trackElement}
            description={t(`schema.workArea.subtitle`)}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
            handlePrevious={handlePrevious || (() => undefined)}
          />
        );
      case TrackElementCategory.ELEMENTARY_CONSTRUCTION_SITE:
        return (
          <CreateCEForm
            loading={loading}
            trackElement={trackElement}
            description={t(`schema.CE.description.add`)}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
            handlePrevious={handlePrevious || (() => undefined)}
          />
        );
      case TrackElementCategory.WORK_TRAIN:
        return (
          <CreateWorkTrainForm
            loading={loading}
            description={t("schema.workTrain.subtitle.default")}
            handleClose={handleClose}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
            setTrackElement={setTrackElement}
            trackElement={trackElement}
            handlePrevious={handlePrevious || (() => undefined)}
          />
        );
      case TrackElementCategory.RESTRICTION:
        return (
          <RestrictionsForm
            loading={loading}
            trackElement={trackElement}
            title={t("schema.restriction.title.add")}
            description={t("schema.restriction.description.add")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
            handlePrevious={handlePrevious || (() => undefined)}
          />
        );
      case TrackElementCategory.WORKSITE_MACHINE:
        return (
          <CreateWorksiteMachineForm
            loading={loading}
            trackElement={trackElement}
            description={t("schema.worksiteMachine.description.add")}
            setTrackElement={setTrackElement}
            handleClose={handleClose}
            handleValidate={(incompatibilities) => (incompatibilities?.length ? setShowConfirmCode(incompatibilities) : handleValidate())}
            handlePrevious={handlePrevious || (() => undefined)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {displayRightTrackElement()}
      {!!showConfirmCode && (
        <ConfirmCodeModal
          handleClose={() => setShowConfirmCode(false)}
          handleConfirm={(code) => sessionService.confirmIdentificationCode(code)}
          handleSuccess={() => handleValidate(showConfirmCode)}
        />
      )}
    </>
  );
};

export default CreateTrackElementForm;
