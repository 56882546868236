import {ID} from "@datorama/akita";

export interface IncompatibilityError {
  concernedTrackElementIds: ID[];
  error: IncompatibilityErrorEnum;
  trackElementId?: ID;
}

export enum IncompatibilityErrorEnum {
  /* PN INCOMPATIBILITIES */
  CLOSE_PN_IN_ZT = 'CLOSE_PN_IN_ZT',
  OPEN_PN_IN_ZT = 'OPEN_PN_IN_ZT',
  /* -------------------- */

  /* ZT INCOMPATIBILITIES */
  NO_ZT_ON_ZT = 'NO_ZT_ON_ZT',
  NO_ZT_CUTS_ZF = 'NO_ZT_CUTS_ZF',
  OUT_ZT_20M_PN = 'OUT_ZT_20M_PN',
  IN_ZT_20M_PN = 'IN_ZT_20M_PN',
  IN_ZT_20M_ACTIVITY = 'IN_ZT_20M_ACTIVITY',
  NO_ZT_ON_CCI = 'NO_ZT_ON_CCI',
  IMMOBILIZED_TTX_IN_CLOSING_ZT = 'IMMOBILIZED_TTX_IN_CLOSING_ZT',
  NO_ZT_OPEN_AUTHORIZATION_IN_TTX_MOVEMENT = 'NO_ZT_OPEN_AUTHORIZATION_IN_TTX_MOVEMENT',
  NO_DUPLICATE_ZT_NAME = 'NO_DUPLICATE_ZT_NAME',
  /* -------------------- */

  /* ZF INCOMPATIBILITIES */
  OUT_ZF_20M_PN = 'OUT_ZF_20M_PN',
  IN_ZF_20M_PN = 'IN_ZF_20M_PN',
  NO_ZF_CUTS_ZT = 'NO_ZF_CUTS_ZT',
  ZF_WITH_ONE_FREE_TRACK = 'ZF_WITH_ONE_FREE_TRACK',
  /* -------------------- */

  /* WM INCOMPATIBILITIES */
  NO_WM_ON_CCI = 'NO_WM_ON_CCI',
  NO_WM_OUT_ZT = 'NO_WM_OUT_ZT',
  NO_WM_ON_NOT_PASSABLE_ZT = 'NO_WM_ON_NOT_PASSABLE_ZT',
  /* -------------------- */

  /* ACTIVITIES INCOMPATIBILITIES */
  NO_LW_IN_ZT = 'NO_LW_IN_ZT',
  NO_LW_IN_VIA_OR_TUN = 'NO_LW_IN_VIA_OR_TUN',
  /* ---------------------------- */

  /* CCI INCOMPATIBILITIES */
  NO_CCI_OUT_ZT = 'NO_CCI_OUT_ZT',
  IN_ZT_20M_CCI = 'IN_ZT_20M_CCI',
  NO_CCI_IN_OPEN_PN = 'NO_CCI_IN_OPEN_PN',
  /* -------------------- */

  /* TTX INCOMPATIBILITIES */
  NO_TTX_BIGGER_THAN_ZT = 'NO_TTX_BIGGER_THAN_ZT',
  NO_TTX_BIGGER_THAN_ZF = 'NO_TTX_BIGGER_THAN_ZF',
  TTX_ONLY_AT_START_OR_END_OF_TRACK = 'TTX_ONLY_AT_START_OR_END_OF_TRACK',
  NO_BLOCKING_ELEMENT_ON_TTX_PATH = 'NO_BLOCKING_ELEMENT_ON_TTX_PATH',
  NO_TTX_ON_SAME_PATH = 'NO_TTX_ON_SAME_PATH',
  NO_MOVEMENT_WITHOUT_DISPATCH = 'NO_MOVEMENT_WITHOUT_DISPATCH',
  NO_MOVEMENT_IN_SLEEPING_ZT = 'NO_MOVEMENT_IN_SLEEPING_ZT',
  NO_MOVEMENT_WITHOUT_DISPATCH_IF_IMMOBILIZED = 'NO_MOVEMENT_WITHOUT_DISPATCH_IF_IMMOBILIZED',
  NO_IMMOBILIZED_TTX_OUTSIDE_ZF_OR_ZT = 'NO_IMMOBILIZED_TTX_OUTSIDE_ZF_OR_ZT',
  NO_TTX_ON_NOT_PASSABLE_ZT = 'NO_TTX_ON_NOT_PASSABLE_ZT',
  TTX_PATH_INCOMPLETE = 'TTX_PATH_INCOMPLETE',
  NO_SPLITTING_OUTSIDE_ZT_OR_ZF = 'NO_SPLITTING_OUTSIDE_ZT_OR_ZF',
  NO_DIFFERENT_ZT_OR_ZF_ON_POST_SPLITTING = 'NO_DIFFERENT_ZT_OR_ZF_ON_POST_SPLITTING',
  NO_ELEMENT_ON_SIDELANES_ON_SPLITTING = 'NO_ELEMENT_ON_SIDELANES_ON_SPLITTING',
  /* -------------------- */

  /* TRACK INCOMPATIBILITIES */
  NO_ELEMENT_OUTSIDE_TRACK = 'NO_ELEMENT_OUTSIDE_TRACK',
}

export const isLinkedToDeletion = (incompatibilityError: IncompatibilityErrorEnum) =>
  [IncompatibilityErrorEnum.NO_WM_OUT_ZT, IncompatibilityErrorEnum.IMMOBILIZED_TTX_IN_CLOSING_ZT].includes(incompatibilityError);

export const isOnlyForCreation = (incompatibilityError: IncompatibilityErrorEnum) =>
  [IncompatibilityErrorEnum.NO_CCI_OUT_ZT, IncompatibilityErrorEnum.TTX_ONLY_AT_START_OR_END_OF_TRACK, IncompatibilityErrorEnum.NO_DUPLICATE_ZT_NAME].includes(incompatibilityError);
