import {ValidationError} from "yup";

export interface FieldErrors {
	[field: string]: string;
}

export const transformError = (err: ValidationError): FieldErrors => {
	return err.inner.reduce((formError, innerError) => {
		const oldError = formError[innerError.path!];

		return {
			...formError,
			[innerError.path!]: oldError || innerError.message,
		};
	}, {} as FieldErrors);
}
