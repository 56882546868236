import React from "react";
import {Stack, styled, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {Colors} from "@utils/theme/constants.utils";

const Ribbon = styled(Stack)((props: { dark?: 'true' }) => ({
  background: props.dark === 'true' ? Colors.white : Colors.primary,
  boxShadow: `0 0 0 90px ${props.dark === 'true' ? Colors.white : Colors.primary}`,
  clipPath: 'inset(-10% -100%)',
  height: 30,
  inset: '0 auto auto 0',
  position: 'absolute',
  transform: 'translate(-29.3%) rotate(-45deg)',
  transformOrigin: '100% 0',
  width: 140,
  zIndex: 1000,
}));

interface EnvironmentRibbonComponentProps {
  dark?: boolean;
}

const EnvironmentRibbonComponent = (props: EnvironmentRibbonComponentProps) => {
  const {dark} = props;

  const {t} = useTranslation();

  return (
    <>
      {process.env.NODE_ENV === 'development' && (
        <Ribbon dark={dark ? "true" : undefined} alignItems="center" justifyContent="center">
          <Typography color={dark ? Colors.primary : Colors.white} fontWeight="700" fontSize="0.875rem">
            {t("environment.LOCAL")}
          </Typography>
        </Ribbon>
      )}
      {window.location.origin.includes('.preprod.dev-id.') && (
        <Ribbon dark={dark ? "true" : undefined} alignItems="center" justifyContent="center">
          <Typography color={dark ? Colors.primary : Colors.white} fontWeight="700" fontSize="0.875rem">
            {t("environment.PREPROD")}
          </Typography>
        </Ribbon>
      )}
    </>
  );
}

export default EnvironmentRibbonComponent;