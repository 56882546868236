import React, {useEffect, useState} from "react";
import {Box, Divider, Drawer, IconButton, Stack, styled, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Pk, PNType, projectQuery, TrackElement, TrackElementType} from "@store/project";
import {useSnackbar} from "notistack";
import {Colors} from "@utils/theme/constants.utils";
import {useNavigate} from "react-router-dom";

interface StyledIconButtonProps {
  active: boolean;
  bgColor: string;
}

const StyledIconButton = styled(IconButton)((props: StyledIconButtonProps) => ({
  backgroundColor: props.bgColor,
  borderRadius: "20px 0 0 20px",
  height: 50,
  opacity: props.active ? 1 : 0.5,
  width: 50,
  "&:hover": {
    backgroundColor: props.bgColor,
    opacity: 1,
  },
}));

interface ElementsListDrawerProps {
  handleClose: () => void;
  open: boolean
}

const ElementsListDrawer = (props: ElementsListDrawerProps) => {
  const {handleClose, open} = props;

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const [active, setActive] = useState<TrackElementType>(TrackElementType.WORK_ZONE);
  const [trackElements, setTrackElements] = useState<TrackElement[]>([]);

  const menus = [TrackElementType.WORK_ZONE, TrackElementType.WORK_TRAIN, TrackElementType.PN, TrackElementType.TRAINING_ZONE];

  useEffect(() => {
    projectQuery.projectTrackElementsByType$(active).subscribe({
      next: setTrackElements,
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, [active, enqueueSnackbar]);

  const displayTrackElement = (te: TrackElement) => {
    const line = projectQuery.projectTracks.find((l) => l.id === te.tracks[0]);

    switch (te.type) {
      case TrackElementType.WORK_ZONE:
        return (
          <Stack>
            <Typography fontSize={14} fontWeight="800">
              {t("schema.workArea.name", {name: te.attributes?.name || "-"})}
            </Typography>
            <Typography fontSize={12} fontWeight="400">
              {t("schema.workArea.RZTSmall")} {te.attributes?.RZT || "-"}
            </Typography>
            <Typography fontSize={12} fontWeight="600" mt="5px">
              {t("global.pk")} {Pk.toString(te.pkStart)}{te.pkEnd ? ` - ${t("global.pk")} ${Pk.toString(te.pkEnd)}` : ""}
            </Typography>
            {!!line && (
              <Typography fontSize={12} fontWeight="600">{line.attributes?.name ?? "-"}</Typography>
            )}
          </Stack>
        );
      case TrackElementType.WORK_TRAIN:
        return (
          <Stack>
            <Typography fontSize={14} fontWeight="800">
              {t("schema.workTrain.workTrain", {name: te.attributes?.name || "-"})}
            </Typography>
            {te.attributes?.isImmobilized && (
              <Typography fontSize={12} fontWeight="400">
                {t("schema.workTrain.immobilized")}
              </Typography>
            )}
            <Typography fontSize={12} fontWeight="600" mt="5px">
              {t("global.pk")} {Pk.toString(te.pkStart)}{te.pkEnd ? ` - ${t("global.pk")} ${Pk.toString(te.pkEnd)}` : ""}
            </Typography>
            {!!line && (
              <Typography fontSize={12} fontWeight="600">{line.attributes?.name ?? "-"}</Typography>
            )}
          </Stack>
        );
      case TrackElementType.PN:
        return (
          <Stack>
            <Typography fontSize={14} fontWeight="800">
              {te.attributes?.name || "-"}
            </Typography>
            <Typography fontSize={12} fontWeight="400">
              {te.attributes?.type ? PNType.label(te.attributes.type) : "-"}
            </Typography>
            <Typography fontSize={12} fontWeight="600" mt="5px">
              {t("global.pk")} {Pk.toString(te.pkStart)}{te.pkEnd ? ` - ${t("global.pk")} ${Pk.toString(te.pkEnd)}` : ""}
            </Typography>
            {!!line && (
              <Typography fontSize={12} fontWeight="600">{line.attributes?.name ?? "-"}</Typography>
            )}
          </Stack>
        );
      case TrackElementType.TRAINING_ZONE:
        return (
          <Stack>
            <Typography fontSize={14} fontWeight="800">
              {t("schema.zone.types.trainingZoneLight")}
            </Typography>
            <Typography fontSize={12} fontWeight="600" mt="5px">
              {t("global.pk")} {Pk.toString(te.pkStart)}{te.pkEnd ? ` - ${t("global.pk")} ${Pk.toString(te.pkEnd)}` : ""}
            </Typography>
            {!!line && (
              <Typography fontSize={12} fontWeight="600">{line.attributes?.name ?? "-"}</Typography>
            )}
          </Stack>
        );
      default:
        return null;
    }
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: 'transparent !important',
          boxShadow: 'none',
          padding: 0,
        },
      }}>
      <Box position="absolute" left="0px" top="40px">
        <Stack spacing={1}>
          {menus.map((menu) => (
            <StyledIconButton
              key={menu}
              active={menu === active}
              bgColor={TrackElementType.getColor({type: menu} as any)}
              onClick={() => setActive(menu)}
              centerRipple={false}>
              <img alt="" src={TrackElementType.getIcon(menu)} style={{height: 30, width: 30}}/>
            </StyledIconButton>
          ))}
        </Stack>
      </Box>
      <Box position="absolute" top="15px" left="65px">
        <IconButton onClick={handleClose}>
          <img alt="" src="/images/cross_icon.svg"/>
        </IconButton>
      </Box>
      <Stack width="440px" height="100%" overflow="hidden">
        <Stack
          ml="50px"
          width="400px"
          height="100%"
          boxShadow="0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)"
          bgcolor="white"
          spacing={4}
          p={4}>
          <Typography fontWeight="900" align="center" fontSize="20px">
            {TrackElementType.shortcuts.find((s) => s.value === active)?.label ?? "-"}
          </Typography>
          <Stack spacing={2}>
            <Divider variant="fullWidth" orientation="horizontal" color={Colors.background}/>
            {trackElements.map((te) => {
              let detailsPath = `${te.id}`;
              let ordersPath = "";
              if (te.type !== TrackElementType.PN) {
                detailsPath = `${te.id}/0/details/info`;
                ordersPath = `${te.id}/0/details/orders`;
              }

              return (
                <Box key={te.id}>
                  <Stack pb={2} direction="row" alignItems="center" justifyContent="space-between">
                    {displayTrackElement(te)}
                    <Stack direction="row" alignItems="center">
                      <IconButton onClick={() => navigate(detailsPath)}>
                        <img alt="details" src="/images/eye_icon.svg"/>
                      </IconButton>
                      <IconButton onClick={() => navigate(ordersPath)}>
                        <img alt="details" src="/images/dispatch_blue_icon.svg" style={{height: 20, width: 20}}/>
                      </IconButton>
                    </Stack>
                  </Stack>
                  <Divider variant="fullWidth" orientation="horizontal" color={Colors.background}/>
                </Box>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
}

export default ElementsListDrawer;