import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement, WorkZoneStatus, WorkZoneStep} from "@store/project";
import {ID} from "@datorama/akita";
import _ from "lodash";

interface CheckNoMovementInSleepingZTParams {
  trackElementId?: ID;
  pkStart: Pk;
  length: number;
  ZTs: TrackElement[];
  TTx: TrackElement[];
}

/* Should not be able to move TTx that is in sleeping ZT */
const checkNoMovementInSleepingZT = (params: CheckNoMovementInSleepingZTParams): IncompatibilityError[] => {
  const {trackElementId, pkStart, length, ZTs, TTx} = params;

  if (!trackElementId) return [];

  const currentTTX = TTx.find((t) => t.id === trackElementId);

  if (!currentTTX || (_.isEqual(currentTTX.pkStart, pkStart) && currentTTX.attributes?.TTXLength === length)) return [];

  const pkEnd = Pk.addMeters(pkStart, length);

  const sleepingZTs = ZTs.filter((t) =>
    !t.attributes?.status
    || (t.attributes?.status === WorkZoneStatus.SOMMEIL && t.attributes?.step === WorkZoneStep.AVIS)
  );

  const ZTsOnTTx = sleepingZTs.filter((t) => !!t.pkEnd
    && (
      Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd)
      || Pk.isPkBetweenPks(pkEnd, t.pkStart, t.pkEnd)
      || Pk.isPkBetweenPks(t.pkStart, pkStart, pkEnd)
      || Pk.isPkBetweenPks(t.pkEnd, pkStart, pkEnd)
    ) && !_.isEqual(pkStart, t.pkEnd) && !_.isEqual(pkEnd, t.pkStart)
  );

  if (ZTsOnTTx.length) {
    return [{
      error: IncompatibilityErrorEnum.NO_MOVEMENT_IN_SLEEPING_ZT,
      trackElementId,
      concernedTrackElementIds: ZTsOnTTx.map((t) => t.id),
    }];
  }

  return [];
};

export default checkNoMovementInSleepingZT;
