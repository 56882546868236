import * as React from 'react';
import {useEffect} from 'react';
import {FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {sessionQuery} from "@store/session";
import {Pk, PK_INPUT_REGEXP, projectQuery, TrackElement} from "@store/project";
import NumberTextFieldComponent from "@components/textField/NumberTextField.component";
import {ordersService} from "@store/dispatches/orders.service";
import {useParams} from "react-router-dom";


export const StandbySetWorkAreaForm = (props: DispatchFormGlobalProps) => {

  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const {projectId} = useParams();

  const updateDispatchContent = (name: string) => (value: any) => setDispatch({
    ...dispatch,
    dispatch: {...dispatch.dispatch, [name]: value}
  });

  useEffect(() => {
    if (trackElement) {
      const tracks = projectQuery.projectTracks.filter((t) => {
        if (trackElement.pkStart && t.pkEnd && Pk.isPkBetweenPks(trackElement.pkStart, t.pkStart, t.pkEnd)) return true;
        return !!(trackElement.pkEnd && t.pkEnd && Pk.isPkBetweenPks(trackElement.pkEnd, t.pkStart, t.pkEnd));
      }) || [] as TrackElement[];

      ordersService.getDispatchNumber(projectId!).subscribe({
        next: (dispatchNbr) => {
          setDispatch({
            type: DispatchTypeEnum.WORK_ZONE_SET_STANDBY_WORK_AREA,
            dispatch: {
              from: trackElement.attributes.RZT,
              to: `${sessionQuery.getValue().user?.firstname || ''} ${sessionQuery.getValue().user?.lastname || ''}`,
              date: new Date(),
              hour: new Date(),
              tracks: tracks.map((t) => t.id),
              pkStart: Pk.toString(trackElement.pkStart),
              pkEnd: Pk.toString(trackElement.pkEnd),
              nbr: trackElement.attributes.name,
              standByDate: new Date(),
              standByHour: new Date(),
              natureOfWork: trackElement.attributes.natureOfWork,
              withRestrictions: false,
              deliveredDispatchNbr: dispatchNbr,
            }
          });
        },
      });
    }
  }, [])

  return (
    <div>
      <Grid container columnSpacing={2.5} rowSpacing={1.5}>

        <Grid item xs={4}>
          <TextFieldComponent label={"M./Mme RZT"}
                              readOnly={!editable}
                              value={dispatch?.dispatch?.from || ''}
                              handleChange={updateDispatchContent("from")}
                              error={errors["from"]}/>
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent label={"M./Mme RELF"}
                              readOnly={!editable}
                              value={dispatch?.dispatch?.to || ''}
                              handleChange={updateDispatchContent("to")}
                              error={errors["to"]}/>
        </Grid>

        <Grid item xs={4}>
          <TextFieldComponent label={"La ZT N°"}
                              readOnly
                              placeholder={"N°"} value={dispatch?.dispatch?.nbr || ''}
                              error={errors["nbr"]}/>
        </Grid>

        <Grid item xs={12}>
          <TextFieldComponent label={"Nature des travaux"}
                              readOnly={!editable}
                              value={dispatch?.dispatch?.natureOfWork || ''}
                              handleChange={updateDispatchContent("natureOfWork")}
                              error={errors["natureOfWork"]}/>
        </Grid>

        <Grid item xs={3}>
          <TextFieldComponent
            label={"Du Km"}
            readOnly={!editable}
            handleChange={updateDispatchContent("pkStart")}
            pattern={PK_INPUT_REGEXP}
            value={dispatch?.dispatch?.pkStart || ""}
            error={errors["pkStart"]}/>
        </Grid>
        <Grid item xs={3}>
          <TextFieldComponent
            label={"Au Km"}
            readOnly={!editable}
            handleChange={updateDispatchContent("pkEnd")}
            pattern={PK_INPUT_REGEXP}
            value={dispatch?.dispatch?.pkEnd || ""}
            error={errors["pkEnd"]}/>
        </Grid>
        <Grid item xs={6}>
          <TextFieldComponent
            label={'Sur voie(s)'}
            readOnly
            multiline
            value={projectQuery.projectTracks
              .filter((t) => (dispatch.dispatch?.tracks || []).includes(t.id))
              .map((t) => t.attributes.name)
              .join(", ")
            }/>
        </Grid>

        <Grid item xs={4}>
          <DatePickerComponent
            label={"Est mise en sommeil le"}
            readOnly={!editable}
            value={dispatch?.dispatch?.standByDate}
            handleChange={updateDispatchContent("standByDate")}
            error={errors["standByDate"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            label={"à"}
            readOnly={!editable}
            value={editable ? dispatch?.dispatch?.standByHour : dispatch?.dispatch?.standByDate}
            handleChange={updateDispatchContent("standByHour")}
            error={errors["standByHour"]}
          />
        </Grid>

        <Grid item xs={12}>
          <Stack alignItems="center" direction="row" justifyContent="start" spacing={3}>
            <RadioGroup
              row
              value={false}
              onChange={(evt: any) => {
                updateDispatchContent("withRestrictions")(evt.target.value === 'true')
              }}
            >
              <FormControlLabel
                label={<Typography fontSize={12} fontWeight="600" pt="3px">Avec
                  restrictions</Typography>}
                labelPlacement={'start'}
                disabled={!editable}
                checked={dispatch?.dispatch?.withRestrictions}
                value={'true'}
                control={(
                  <Radio
                    size="small"/>
                )}
              />
              <FormControlLabel
                label={<Typography fontSize={12} fontWeight="600" pt="3px">Sans
                  restrictions</Typography>}
                disabled={!editable}
                value={'false'}
                checked={!dispatch?.dispatch?.withRestrictions}
                labelPlacement={'start'}
                control={(
                  <Radio
                    size="small"/>
                )}
              />
            </RadioGroup>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <TextFieldComponent
            multiline
            rows={4}
            readOnly={!editable}
            label={"Informations complémentaires : (Restrictions de circulation, immobilisation des TTx, etc...)"}
            handleChange={updateDispatchContent("additionalInformation")}
            value={dispatch?.dispatch?.additionalInformation || ""}
            error={errors["additionalInformation"]}/>
        </Grid>

        <Grid item xs={4}>
          <DatePickerComponent
            readOnly={!editable}
            label={"Le"}
            value={dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("date")}
            error={errors["date"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            readOnly={!editable}
            label={"à"}
            value={editable ? dispatch?.dispatch?.hour : dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("hour")}
            error={errors["hour"]}
          />
        </Grid>
        <Grid item xs={4}></Grid>

        <Grid item xs={4}>
          <NumberTextFieldComponent label={"N° de dépêche délivré"}
                                    readOnly={!editable}
                                    handleChange={updateDispatchContent("deliveredDispatchNbr")}
                                    value={dispatch?.dispatch?.deliveredDispatchNbr}
                                    pattern={new RegExp(/^(\d*|\d+)$/)}
                                    error={errors["deliveredDispatchNbr"]}/>
        </Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent label={"N° de dépêche reçu"}
                                    readOnly={!editable}
                                    handleChange={updateDispatchContent("receivedDispatchNbr")}
                                    value={dispatch?.dispatch?.receivedDispatchNbr}
                                    pattern={new RegExp(/^(\d*|\d+)$/)}
                                    error={errors["receivedDispatchNbr"]}/>
        </Grid>

      </Grid>
    </div>
  );
};

export default StandbySetWorkAreaForm;
