import {Pk, TrackElement, TrackElementType} from "@store/project";
import {IncompatibilityError} from "@utils/incompatibilities/incompatibility.utils";
import checkInZT20mActivity from "@utils/incompatibilities/activity/IN_ZT_20M_ACTIVITY/inZT20mActivity";
import checkNoLWonZT from "@utils/incompatibilities/activity/NO_LW_IN_ZT/noLWinZT";
import checkNoLWinViaductOrTunnel from "@utils/incompatibilities/activity/NO_LW_IN_VIA_OR_TUN/noLWinViaductOrTunnel";
import {ID} from "@datorama/akita";

interface GetS9A3ActivityIncParams {
  trackElementId?: ID;
  tracks?: ID[];
  type: TrackElementType;
  pkStart?: Pk;
  pkEnd?: Pk;
  ZTs?: TrackElement[];
  viaducts?: TrackElement[];
  tunnels?: TrackElement[];
}

export const getS9A3ActivityInc = (params: GetS9A3ActivityIncParams): IncompatibilityError[] => {
  const {type, pkStart, pkEnd, trackElementId, tracks, ZTs = [], viaducts = [], tunnels = []} = params;

  if (!pkStart || !pkEnd) return [];

  const incompatibilities: IncompatibilityError[] = [];

  if (type === TrackElementType.LIGHTWEIGHT) {
    incompatibilities.push(...checkNoLWonZT({pkStart, pkEnd, ZTs, trackElementId}));
    incompatibilities.push(...checkNoLWinViaductOrTunnel({pkStart, pkEnd, viaducts, tunnels, trackElementId, tracks}));
  }

  if (type !== TrackElementType.LIGHTWEIGHT) {
    incompatibilities.push(...checkInZT20mActivity({pkStart, pkEnd, ZTs, trackElementId}));
  }

  return incompatibilities;
};
