import React, {useEffect, useState} from "react";
import {Box, Button, Card, DialogActions, Grid, IconButton, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";

import {sessionQuery, sessionService} from "@store/session";
import {filesService, UploadedFile} from "@store/files";
import {Role, User} from "@store/users";

import dayjs from "@utils/dayjs.utils";

import TextFieldComponent from "@components/textField/TextField.component";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import AskCodeModal from "@screens/auth/common/account/AskCode.modal";

const AccountScreen = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [user, setUser] = useState<User>();

  const [askCode, setAskCode] = useState(false);

  useEffect(() => {
    sessionService.fetchCurrentUser().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });

    const _currentUser$ = sessionQuery.currentUser$.subscribe(setUser);

    return () => {
      _currentUser$.unsubscribe();
    };
  }, [enqueueSnackbar]);

  const openFile = (file?: UploadedFile) => {
    if (file) {
      filesService.getFile(file.fileId).subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  }

  return (
    <>
      <Stack width="100%" pt="30px" pb="20px" spacing={4}>
        <Stack spacing={0.5}>
          <Typography fontSize={26} fontWeight="900">{t("account.title")}</Typography>
          <Typography fontSize={14} fontWeight="400" sx={{opacity: 0.3}}>
            {t("account.description")}
          </Typography>
        </Stack>
        <Box flex={1} component={Card} p="10px 10px 0">
          <Box height="100%" overflow="auto" className="scrollable" p="30px 30px 30px">
            <Grid container columnSpacing={8} rowSpacing={2}>
              <Grid item xs={6}>
                <TextFieldComponent
                  label={t("users.fields.lastname")}
                  value={user?.lastname || ""}
                  readOnly/>
              </Grid>
              <Grid item xs={6}>
                <TextFieldComponent
                  label={t("users.fields.firstname")}
                  value={user?.firstname || ""}
                  readOnly/>
              </Grid>
              <Grid item xs={6}>
                <TextFieldComponent
                  label={t("users.fields.email")}
                  value={user?.email || ""}
                  readOnly/>
              </Grid>
              <Grid item xs={6}>
                <TextFieldComponent
                  label={t("users.fields.function")}
                  value={user?.role || ""}
                  readOnly/>
              </Grid>
              {user?.role !== Role.CLIENT && (
                <>
                  <Grid item xs={6}>
                    <TextFieldComponent
                      label={t("account.trainingCertificate")}
                      placeholder={!user?.formationFile ? t("users.fields.noFile") : undefined}
                      value={user?.formationFile?.fileName || ""}
                      endAdornment={(
                        <IconButton size="small" sx={{marginRight: '-7px'}}
                                    onClick={() => openFile(user?.formationFile)}>
                          <img alt="download file" src="/images/download_dark_icon.svg"/>
                        </IconButton>
                      )}
                      readOnly/>
                    {user && user.formationExpireDate && dayjs().isAfter(user.formationExpireDate) && (
                      <Stack direction="row" alignItems="center" mt="3px">
                        <img alt="" src="/images/information_red_icon.svg"/>
                        <Typography mt="4px" ml="4px" fontSize={12} fontWeight="400" color="error">
                          {t("users.fields.expiredFile")}
                        </Typography>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerComponent
                      label={t("users.fields.fileExpirationDate")}
                      value={user && user.formationExpireDate ? user.formationExpireDate : undefined}
                      disablePast
                      readOnly/>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldComponent
                      label={t("users.fields.qualificationTitle")}
                      placeholder={!user?.habilitationFile ? t("users.fields.noFile") : undefined}
                      value={user?.habilitationFile?.fileName || ""}
                      endAdornment={(
                        <IconButton size="small" sx={{marginRight: '-7px'}}
                                    onClick={() => openFile(user?.habilitationFile)}>
                          <img alt="download file" src="/images/download_dark_icon.svg"/>
                        </IconButton>
                      )}
                      readOnly/>
                    {user && user.habilitationExpireDate && dayjs().isAfter(user.habilitationExpireDate) && (
                      <Stack direction="row" alignItems="center" mt="3px">
                        <img alt="" src="/images/information_red_icon.svg"/>
                        <Typography mt="4px" ml="4px" fontSize={12} fontWeight="400" color="error">
                          {t("users.fields.expiredFile")}
                        </Typography>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerComponent
                      label={t("users.fields.fileExpirationDate")}
                      value={user && user.habilitationExpireDate ? user.habilitationExpireDate : undefined}
                      disablePast
                      readOnly/>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldComponent
                      label={t("users.fields.medicalVisit")}
                      placeholder={!user?.medicalVisitFile ? t("users.fields.noFile") : undefined}
                      value={user?.medicalVisitFile?.fileName || ""}
                      endAdornment={(
                        <IconButton size="small" sx={{marginRight: '-7px'}}
                                    onClick={() => openFile(user?.medicalVisitFile)}>
                          <img alt="download file" src="/images/download_dark_icon.svg"/>
                        </IconButton>
                      )}
                      readOnly/>
                    {user && user.medicalVisitExpireDate && dayjs().isAfter(user.medicalVisitExpireDate) && (
                      <Stack direction="row" alignItems="center" mt="3px">
                        <img alt="" src="/images/information_red_icon.svg"/>
                        <Typography mt="4px" ml="4px" fontSize={12} fontWeight="400" color="error">
                          {t("users.fields.expiredFile")}
                        </Typography>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerComponent
                      label={t("users.fields.fileExpirationDate")}
                      value={user && user.medicalVisitExpireDate ? user.medicalVisitExpireDate : undefined}
                      disablePast
                      readOnly/>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldComponent
                      label={t("account.operationalIdentificationCode")}
                      value="*******"
                      endAdornment={(
                        <IconButton size="small" sx={{marginRight: '-7px'}} onClick={() => setAskCode(true)}>
                          <img alt="" src="/images/eye_icon_black.svg"/>
                        </IconButton>
                      )}
                      readOnly/>
                  </Grid>
                </>
              )}
              <Grid item xs={12}/>
            </Grid>
          </Box>
        </Box>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={sessionService.logout}
            startIcon={<img alt="" src="/images/log_out_icon.svg"/>}>
            {t("global.logout")}
          </Button>
        </DialogActions>
      </Stack>
      {askCode && (
        <AskCodeModal handleClose={() => setAskCode(false)}/>
      )}
    </>
  );
};

export default AccountScreen;
