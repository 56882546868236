import {createStyles} from "@mui/styles";
import {Colors} from "@utils/theme/constants.utils";
import {alpha, Theme} from '@mui/material';

const classes = (theme: Theme) => createStyles({
  container: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    flex: 1,
    marginBottom: 20,
    borderWidth: 0,

    "& .MuiDataGrid-root": {
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      border: 'none',
    },

    "& .MuiDataGrid-overlay": {
      backgroundColor: 'transparent',
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: theme.palette.secondary.main,
      border: "none !important",
      borderRadius: '4px 4px 0 0',
    },

    "& .MuiDataGrid-columnHeader": {
      outline: 'none !important',
      userSelect: 'none',
      "&.pre-line .MuiDataGrid-columnHeaderTitle": {
        lineHeight: 1.35,
        textAlign: 'center',
        whiteSpace: 'pre-line !important',
      },
    },

    "& .MuiDataGrid-iconButtonContainer": {
      "& .MuiIconButton-root": {
        color: Colors.primary,
        marginRight: '-1.5em',
      },
    },

    "& .MuiDataGrid-columnHeaderTitle": {
      color: Colors.primary,
      fontSize: 10,
      fontWeight: "700",
      paddingTop: '2.5px !important',
      textTransform: 'uppercase',
    },


    "& .MuiDataGrid-cell": {
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
      color: theme.palette.text,
      fontSize: 12,
      fontWeight: "500",
      outline: 'none !important',
      padding: '2.4px 14.5px 0 !important',
      userSelect: 'none !important',
      "&:not(:last-of-type)": {
        borderRight: `1px solid ${theme.palette.secondary.main}`,
      },

      "&.error, & > .error": {
        backgroundColor: alpha(Colors.secondaryRed, 0.2),
        color: Colors.secondaryRed,
      },

      "&.red, & > .red": {
        color: Colors.secondaryRed,
      },

      "&.actions": {
        padding: '2.4px 6px 0 !important',
      },
      "&.noPaddingLeft": {
        padding: '2.4px 0 0 !important',
        "& > .MuiIconButton-root": {
          marginRight: 3,
          marginTop: -4,
        },
      },
      "&.clickable": {
        padding: '0 !important',
        "& > :first-child": {
          color: Colors.text,
          fontSize: 12,
          height: '100%',
          paddingTop: '2.4px !important',
          "& .MuiIconButton-root": {
            marginTop: -2.4,
          },
        },
      },
    },

    "& .MuiDataGrid-columnSeparator": {
      display: "none !important",
    },

    "& .MuiDataGrid-footerContainer": {
      minHeight: 0,
      alignItems: "center",
      borderTop: "0px",
      justifyContent: "center",
    },

    "& .MuiPagination-root": {
      color: Colors.primary,
      marginTop: 10,
    },

    "& .MuiPaginationItem-page": {
      color: Colors.primary,
      fontSize: 12,
      fontWeight: "700",
      height: '20px !important',
      minWidth: 0,
      paddingTop: 2,
      width: '20px !important',
    },

    "& .MuiPaginationItem-previousNext": {
      color: Colors.primary,
      height: '20px !important',
      minWidth: 0,
      width: '20px !important',
    },
  },
})

export default classes;