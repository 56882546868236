import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, projectQuery, TrackElement, WorkTrainDirectionEnum} from "@store/project";
import {DispatchTypeEnum, TTxDispatchType} from "@store/dispatches/dispatch.model";
import {ID} from "@datorama/akita";

interface CheckNoTTxOnSamePathParams {
  fromDispatch?: boolean;
  dispatchType?: TTxDispatchType;
  trackElementId?: ID;
  trackIds: ID[];
  pkStart: Pk;
  finalPk?: Pk;
  TTx: TrackElement[];
}

/* Should not be able to move TTx on same path than another one if they are not going in same direction */
const checkNoTTxOnSamePath = (params: CheckNoTTxOnSamePathParams): IncompatibilityError[] => {
  const {fromDispatch, dispatchType, trackElementId, trackIds, pkStart, finalPk, TTx} = params;

  if (!finalPk || (fromDispatch && dispatchType && ![
    DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE,
    DispatchTypeEnum.MOVEMENT_ENGAGEMENT,
    DispatchTypeEnum.MOVEMENT_RELEASE,
  ].includes(dispatchType))) {
    return [];
  }

  const TTxDirection = Pk.toMeters(finalPk) < Pk.toMeters(pkStart) ? WorkTrainDirectionEnum.RIGHT_TO_LEFT : WorkTrainDirectionEnum.LEFT_TO_RIGHT;

  const TTxPath = projectQuery.getTTxPath(pkStart, finalPk, trackIds[0]);

  const TTxOnSamePath = TTx.filter((t) => {
    if (!t.attributes || !t.attributes.finalPk || t.id === trackElementId || t.attributes.direction === TTxDirection) {
      return false;
    }

    const tPath = projectQuery.getTTxPath(t.pkStart, t.attributes.finalPk, t.tracks[0]);
    return tPath.some((p) => projectQuery.checkIfElementInTTxPath(TTxPath, p.pkStart, p.pkEnd, [p.trackId]));
  });

  if (!!TTxOnSamePath.length) {
    return [{
      error: IncompatibilityErrorEnum.NO_TTX_ON_SAME_PATH,
      trackElementId,
      concernedTrackElementIds: TTxOnSamePath.map((t) => t.id),
    }];
  }

  return [];
};

export default checkNoTTxOnSamePath;
