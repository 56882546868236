import {Store, StoreConfig} from "@datorama/akita";
import {TrackElementType} from "@store/project";
import {DispatchTypeEnum, TrackElementHistoryType} from "@store/dispatches/dispatch.model";

export interface DispatchFiltersState {
  search?: string;
  dispatchCategory?: TrackElementType;
  dispatchNature?: DispatchTypeEnum;
  trackElementHistoryCategory?: TrackElementHistoryType;
  trackElementHistoryType?: TrackElementType;
  startDate?: Date;
  startHour?: Date;
  endDate?: Date;
  endHour?: Date;
}

export function createInitialState(): DispatchFiltersState {
  return {
    search: "",
  };
}

@StoreConfig({name: "dispatchFilters"})
export class DispatchFiltersStore extends Store<DispatchFiltersState> {
  constructor() {
    super(createInitialState());
  }
}

export const dispatchFiltersStore = new DispatchFiltersStore();
