import {ID} from "@datorama/akita";

import {Pk, TrackElement} from "@store/project";

import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";

interface CheckNoWMOnNotPassableZTParams {
  trackElementId?: ID;
  pkStart: Pk;
  ZTs: TrackElement[];
}

/* Should not be able to set WM Pk on a not passable ZT */
const checkNoWMOnNotPassableZT = (params: CheckNoWMOnNotPassableZTParams): IncompatibilityError[] => {
  const {trackElementId, pkStart, ZTs} = params;

  const WMInNotPassableZTs = ZTs.filter((t) => !!t.pkEnd && Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd) && !!t.attributes?.isNotPassable);

  if (!!WMInNotPassableZTs.length) {
    return [
      {
        error: IncompatibilityErrorEnum.NO_WM_ON_NOT_PASSABLE_ZT,
        trackElementId,
        concernedTrackElementIds: WMInNotPassableZTs.map((ZT) => ZT.id),
      },
    ];
  }

  return [];
};

export default checkNoWMOnNotPassableZT;