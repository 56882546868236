import React, {useEffect, useMemo, useState} from "react";
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {finalize} from 'rxjs';
import {Box} from "@mui/material";

import {ProjectDispatchesByDateStats, reportingService} from '@store/reporting';
import {projectQuery, TrackElement, TrackElementType} from '@store/project';

import dayjs from "@utils/dayjs.utils";

import StackedBarChart from '@components/chart/StackedBar.chart';
import SelectComponent, {SelectComponentItem} from '@components/select/Select.component';

const ProjectDispatchesPerDayChart = () => {
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ProjectDispatchesByDateStats[]>([]);

  const [selectedDate, setSelectedDate] = useState<string>(dayjs().startOf('month').toISOString());

  useEffect(() => {
    reportingService.getDispatchesByDayStats(projectQuery.projectId, dayjs(selectedDate).set('day', 15).toDate()).pipe(finalize(() => setLoading(false))).subscribe({
      next: setData,
      error: (err) => enqueueSnackbar(err.text),
    });
  }, [selectedDate]);

  const categoriesOrder = useMemo(() => {
    return [
      TrackElementType.TRACK_CHANGE,
      TrackElementType.WORK_ZONE,
      TrackElementType.WORK_TRAIN,
      TrackElementType.PN,
      TrackElementType.TRAINING_ZONE,
    ];
  }, []);

  const monthsSelectItems = useMemo(() => {
    const items: SelectComponentItem[] = [];
    let startDate = dayjs(projectQuery.projectInfo.startingDate).startOf('month');

    while (startDate.isSameOrBefore(undefined)) {
      const dateFormatted = startDate.format('MMMM YYYY');
      items.push({
        label: dateFormatted[0].toUpperCase() + dateFormatted.slice(1).toLowerCase(),
        value: startDate.toISOString(),
      });
      startDate = startDate.add(1, 'month');
    }

    return items;
  }, []);

  return (
    <>
      <Box position="absolute" top="20px" right="30px" zIndex={1} width="200px">
        <SelectComponent
          items={monthsSelectItems}
          handleChange={setSelectedDate}
          value={selectedDate}/>
      </Box>
      <StackedBarChart<ProjectDispatchesByDateStats>
        loading={loading}
        colors={categoriesOrder.map((x) => TrackElementType.getColor({type: x} as TrackElement))}
        labels={[
          'Catégorie de dépêches',
          ...categoriesOrder.map((x) => TrackElementType.getRELFElementLabel(x))
        ]}
        getTooltip={(x) =>
          `<div style="padding: 10px">
          <div style="line-height: 1.25; margin-bottom: 5px">${x.date}</div>
          <strong style="white-space: nowrap">
          ${t("dispatches.dispatchCount", {count: x.perType.reduce((res, t) => res + t.total, 0)})}
          </strong>
          <br/>
          <br/>
          ${x.perType.map((y) =>
            `<div style="font-size: 12px">
            ${TrackElementType.getRELFElementLabel(y.type as TrackElementType)} : <span style="font-weight: bold">${y.total}</span>
            </div>`
          ).join('')}
          </div>`
        }
        data={data.map((x) =>
          [
            x.date,
            categoriesOrder.map((type) =>
              x.perType.find((y) => y.type === type)?.total ?? 0
            ),
            x,
          ]
        )}
      />
    </>
  );
}

export default ProjectDispatchesPerDayChart;