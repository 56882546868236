import React, {useEffect, useState} from 'react'
import {Box, Button, debounce, Stack} from "@mui/material";
import {WithStyles, withStyles} from "@mui/styles";
import {useSnackbar} from "notistack";
import {t} from "i18next";
import {useParams} from "react-router-dom";
import {finalize} from "rxjs";

import {AccidentAtWorkFilters, AccidentAtWorkModel, reportingService} from "@store/reporting";
import {filesService} from "@store/files";

import SearchBar from "@components/textField/SearchBar.component";
import Table from "@components/table/Table";
import {accidentsAtWorkColumns} from "@screens/auth/common/reporting/components/accidentsAtWork/table.utils";

import dayjs from "@utils/dayjs.utils";

import style from "./AccidentsAtWorkModal.style";
import ModalComponent from "@components/modals/Modal.component";
import AccidentsAtWorkDrawer from "@screens/auth/common/reporting/components/accidentsAtWork/AccidentsAtWork.drawer";
import {
  AddAccidentAtWorkModal
} from "@screens/auth/common/reporting/components/accidentsAtWork/addAccidentAtWork/AddAccidentAtWork.modal";
import {sessionQuery} from "@store/session";
import {Role} from "@store/users";
import {projectQuery} from '@store/project';

interface AccidentsAtWorkModalProps extends WithStyles<typeof style> {
  handleClose: () => void;
}

const AccidentsAtWorkModal = (props: AccidentsAtWorkModalProps) => {
  const {classes, handleClose} = props;

  const {enqueueSnackbar} = useSnackbar();

  const {projectId} = useParams();

  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<AccidentAtWorkFilters>({});

  const [activeDrawer, setActiveDrawer] = useState(false);
  const [addAccident, setAddAccident] = useState(false);

  const [accidentsAtWork, setAccidentsAtWork] = useState<AccidentAtWorkModel[]>([]);

  useEffect(() => {
    if (projectId) {
      setLoading(true);
      reportingService.getAccidentsAtWork(projectId, filters)
        .pipe(finalize(() => setLoading(false)))
        .subscribe(setAccidentsAtWork);
    }
  }, [projectId, filters]);

  useEffect(() => {
    window.addEventListener("RELOAD_ACCIDENT_AT_WORK", () => {
      if (projectId) {
        setLoading(true);
        reportingService.getAccidentsAtWork(projectId, filters)
          .pipe(finalize(() => setLoading(false)))
          .subscribe(setAccidentsAtWork);
      }
    });

    return () => {
      window.removeEventListener("RELOAD_ACCIDENT_AT_WORK", () => {
      });
    };
  }, []);

  const handleDownloadTrackElementCSV = () => {
    if (!projectId) return;
    reportingService.getAccidentsAtWorkCSV(projectId, filters).subscribe({
      next: (res) => {
        filesService.downloadFile(res, `accidents-at-work_${dayjs().format("DD-MM-YYYY")}.csv`);
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  return (
    <>
      <ModalComponent
        color="primary"
        handleClose={handleClose}
        fullWidth
        fullHeight
        maxWidth={false}
        title={t("reporting.accidentsAtWork.title")}
        content={(
          <div className={classes.modalContent}>
            <Stack direction="row" spacing={2} className={classes.tableButtons}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleDownloadTrackElementCSV}
                startIcon={<img src="/images/export_icon.svg"/>}>
                {t("reporting.accidentsAtWork.export")}
              </Button>
            </Stack>
            <Stack direction="row" spacing={2} mb="20px">
              <SearchBar
                placeholder={t("reporting.accidentsAtWork.search")}
                color="secondary"
                handleChange={debounce((value) => {
                  setFilters((state) => ({...state, search: value}));
                }, 300)}/>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setActiveDrawer(true)}
                startIcon={<img src="/images/filter_icon.svg" alt=""/>}>
                {t("reporting.accidentsAtWork.filter")}
              </Button>
              {sessionQuery.hasSufficientRoleOrAbove(Role.OS_R) && !projectQuery.isClosed && (
                <Box width="300px">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setAddAccident(true)}
                    startIcon={<img src="/images/add_icon_white.svg" alt=""/>}>
                    {t("reporting.accidentsAtWork.addButton")}
                  </Button>
                </Box>
              )}
            </Stack>
            <Table
              loading={loading}
              columns={accidentsAtWorkColumns()}
              disablePagination
              data={accidentsAtWork || []}
            />
          </div>
        )}/>
      {addAccident && (
        <AddAccidentAtWorkModal
          handleClose={() => setAddAccident(false)}/>
      )}
      {activeDrawer && (
        <AccidentsAtWorkDrawer
          handleClose={() => setActiveDrawer(false)}
          handleReset={() => setFilters((state) => ({
            search: state.search,
            category: undefined,
            startDate: undefined,
            startHour: undefined,
            endDate: undefined,
            endHour: undefined,
          }))}
          handleSave={setFilters}
          filters={filters}/>
      )}
    </>
  );
}

export default withStyles(style)(AccidentsAtWorkModal);
