import {SelectComponentItem} from "@components/select/Select.component";
import I18n from "@utils/i18n.utils";
import {Pk, TrackElementCategory, TrackElementType} from "@store/project";
import {ID} from "@datorama/akita";
import {User} from "@store/users";

export interface Order {
  id: string;
  name: string;
  trackElementId: string;
  creationDate?: Date;
  isClose: boolean;
  dispatches: Dispatch[];
}

export namespace Order {
  export const create = (): Order => ({
    id: "",
    name: "",
    trackElementId: "",
    creationDate: undefined,
    isClose: false,
    dispatches: [],
  });
}

export interface Dispatch {
  id: string;
  type: DispatchTypeEnum;
  dispatch: DispatchContent;
  createdAt: Date;
  category: TrackElementCategory;
}

export interface TodayDispatchHistory {
  data: DispatchHistoryData[];
  meta: HistoryMeta;
}

export interface GetDispatchHistory {
  data: DispatchHistory[];
  meta: HistoryMeta;
}

export interface DispatchHistoryContent {
  content: DispatchHistoryContentData;
  createdAt: Date;
  order: ID;
  project: ID;
  trackElement: ID;
  type: DispatchTypeEnum;
  updatedAt: Date;
  _id: ID;
  description?: string;
  name?: string;
  dispatchNumberDelivered?: number;
  dispatchNumberReceived?: number;
}

export interface DispatchHistory {
  content: DispatchHistoryContent;
  createdAt: Date;
  id: ID;
  type: DispatchTypeEnum;
  user: TrackElementUser;
}

export interface DispatchHistoryContentData {
  date: Date;
  from: string;
  natureOfWork: string;
  nbr: string;
  pkEnd: Pk;
  pkStart: Pk;
  to: string;
  tracks: string[];
}

export interface HistoryMeta {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface DispatchHistoryData {
  id: ID;
  category: DispatchTypeEnum;
  orderId: ID;
  from: Date;
  dispatch: TodayDispatchContent;
  createdAt: Date;
}

export interface TodayDispatchContent {
  closingDate: Date;
  closingHour: Date;
  date: Date;
  deliveredDispatchNbr: number;
  from: string;
  natureOfWork: string;
  pkEnd: Pk;
  pkStart: Pk;
  recievedDispatchNbr: number;
  to: string;
  tracks: string[];
  withRestrictions: boolean;
}

export enum TrackElementHistoryType {
  CREATE_TRACK_ELEMENT = "CREATE_TRACK_ELEMENT",
  UPDATE_TRACK_ELEMENT = "UPDATE_TRACK_ELEMENT",
  DELETE_TRACK_ELEMENT = "DELETE_TRACK_ELEMENT",
  COMMON = "COMMON",
  PEOPLE_COUNTING_ENTRANCE = "PEOPLE_COUNTING_ENTRANCE",
  PEOPLE_COUNTING_EXIT = "PEOPLE_COUNTING_EXIT",
  INCOMPATIBILITY = "INCOMPATIBILITY",
}

export namespace TrackElementHistoryType {
  export const label = (historyType: TrackElementHistoryType) => {
    switch (historyType) {
      case TrackElementHistoryType.CREATE_TRACK_ELEMENT:
        return I18n.t("dispatches.historyType.createTrackElement");
      case TrackElementHistoryType.UPDATE_TRACK_ELEMENT:
        return I18n.t("dispatches.historyType.updateTrackElement");
      case TrackElementHistoryType.DELETE_TRACK_ELEMENT:
        return I18n.t("dispatches.historyType.deleteTrackElement");
      case TrackElementHistoryType.COMMON:
        return I18n.t("dispatches.historyType.common");
      case TrackElementHistoryType.INCOMPATIBILITY:
        return I18n.t("dispatches.historyType.incompatibilityValidation");
      case TrackElementHistoryType.PEOPLE_COUNTING_ENTRANCE:
        return I18n.t("dispatches.managePeopleCounting.fullTypes.PEOPLE_COUNTING_ENTRANCE");
      case TrackElementHistoryType.PEOPLE_COUNTING_EXIT:
        return I18n.t("dispatches.managePeopleCounting.fullTypes.PEOPLE_COUNTING_EXIT");
      default:
        return "-";
    }
  };

  export const selectOptions: SelectComponentItem[] = [
    {
      label: TrackElementHistoryType.label(TrackElementHistoryType.CREATE_TRACK_ELEMENT),
      value: TrackElementHistoryType.CREATE_TRACK_ELEMENT,
    },
    {
      label: TrackElementHistoryType.label(TrackElementHistoryType.UPDATE_TRACK_ELEMENT),
      value: TrackElementHistoryType.UPDATE_TRACK_ELEMENT,
    },
    {
      label: TrackElementHistoryType.label(TrackElementHistoryType.DELETE_TRACK_ELEMENT),
      value: TrackElementHistoryType.DELETE_TRACK_ELEMENT,
    },
    {
      label: TrackElementHistoryType.label(TrackElementHistoryType.COMMON),
      value: TrackElementHistoryType.COMMON,
    },
    {
      label: TrackElementHistoryType.label(TrackElementHistoryType.INCOMPATIBILITY),
      value: TrackElementHistoryType.INCOMPATIBILITY,
    },
  ];
}

export interface TrackElementHistory {
  meta: HistoryMeta;
  data: TrackElementHistoryData[];
}

export interface TrackElementHistoryData {
  id: ID;
  createdAt: Date;
  user: User;
  type: TrackElementHistoryType;
  content: TrackElementContent;
}

export interface TrackElementContent {
  id: ID;
  type: TrackElementType;
  project: ID;
  description?: string;
}

export interface TrackElementUser {
  id: ID;
  firstName: string;
  lastName: string;
}

export interface CreateHistoryCommon {
  name?: string;
  projectId: ID;
  description?: string;
  dispatchNumberDelivered?: number;
  dispatchNumberReceived?: number;
  newRZT?: string;
  dateTime?: Date;
  TTxId?: ID;
}

export interface CreatePeopleCountingHistory {
  projectId: ID;
  interlocutor?: string;
  nature?: string;
  company?: string;
  type?: TrackElementHistoryType.PEOPLE_COUNTING_ENTRANCE | TrackElementHistoryType.PEOPLE_COUNTING_EXIT;
  number?: string;
  observations?: string;
}

export interface CreateRZTHandover {
  projectId: ID;
  RELF?: string;
  fromRZT?: string;
  toRZT?: string;
  date?: Date;
  hour?: Date;
}

export interface CreateADVAcquittal {
  projectId: ID;
  advName?: string;
  RELF?: string;
  fromRZT?: string;
  TTxNbr?: string;
  TTxId?: ID;
  date?: Date;
  hour?: Date;
}

export interface CreatePNAcquittal {
  projectId: ID;
  pnName?: string;
  RELF?: string;
  fromAPN?: string;
  TTxNbr?: string;
  TTxId?: ID;
  date?: Date;
  hour?: Date;
}

export interface CreateStopSignAcquittal {
  projectId: ID;
  RELF?: string;
  ATTX?: string;
  TTxNbr?: string;
  stopSignPk?: string;
  TTxId?: ID;
  date?: Date;
  hour?: Date;
}

export interface CreateDispatch extends Partial<Omit<Dispatch, "dispatch">> {
  dispatch?: CreateDispatchContent;
}

export interface CreateDispatchSplittingPart extends Partial<Omit<SplittingPart, "pkStart">> {
  pkStart?: string;
}

export interface CreateDispatchContent extends Partial<Omit<DispatchContent, "pkStart" | "pkEnd" | "route" | "pkImmobilization" | "splittingParts">> {
  pkStart?: string;
  pkEnd?: string;
  route?: string[];
  pkImmobilization?: string;
  splittingParts?: CreateDispatchSplittingPart[];
}

export namespace CreateDispatch {
  export const fromDispatch = (dispatch: Dispatch): CreateDispatch => ({
    ...dispatch,
    dispatch: {
      ...dispatch.dispatch,
      splittingParts: dispatch.dispatch.splittingParts?.map((sp) => ({
        ...sp,
        pkStart: Pk.toString(sp.pkStart),
      })),
      pkStart: Pk.toString(dispatch.dispatch.pkStart),
      pkEnd: Pk.toString(dispatch.dispatch.pkEnd),
      route: dispatch.dispatch.route?.map((r) => Pk.toString(r) || ""),
      pkImmobilization: Pk.toString(dispatch.dispatch.pkImmobilization),
    },
  });
}

export interface HoursAndMinutes {
  hours: number;
  minutes: number;
}

export interface DispatchContent extends DispatchContentMovement, DispatchContentPn, DispatchContentWorksiteMachine, DispatchContentTrackChange {
  from: string;
  to: string;
  nbr: number;
  natureOfWork: string;
  pkStart: Pk;
  pkEnd?: Pk;
  date: Date;
  hour: Date;
  tracks: ID[];
  fromDate: Date;
  toDate: Date;
  toHour: Date;
  isPassable: boolean;
  additionalInformation: string;
  deliveredDispatchNbr: number;
  receivedDispatchNbr: number;
  observations: string;
  closingDate: Date;
  closingHour: Date;
  withRestrictions: boolean;
  standByDate: Date;
  standByHour: Date;
  splittingParts?: SplittingPart[];
  way?: ID;
  ttxNbr?: number;
  workZoneNbr?: number;
  trainingZoneNbr?: number;
  nbrAntiDriftShims?: number;
  nbrScrewBrakes?: number;
  nbrOfCars?: number;
  emNbr?: number;
  hours: HoursAndMinutes;
  ATTX: string;
}

export interface DispatchContentMovement {
  startWay: ID;
  endWay: ID;
  specificInstructions: string;
  pn: string;
  description?: string;
  splitting: boolean;
  immobilization: boolean;
  pkImmobilization: Pk;
  route: Pk[];
  ttxNbrs?: string[];
  type?: TrackElementType;
  arrivalDate: Date;
  engagementRequestDate: Date;
  engagementAuthorizedDate: Date;
  resetDate: Date;
}

export interface DispatchContentPn {
  pnNbr: string;
  observation: string;
  situation: boolean;
  dateFrom: Date;
  dateFromHour: Date;
  dateTo: Date;
}

export interface DispatchContentWorksiteMachine {
  machineNbr: string;
  activityNbr: string;
  startDate: Date;
  endDate: Date;
  additionalInformation?: string;
}

export enum ManeuverOrientation {
  DIRECT = "DIRECT",
  DEFLECT = "DEFLECT",
}

export namespace ManeuverOrientation {
  export const selectItems: SelectComponentItem[] = [
    {
      label: I18n.t("schema.trackDevice.maneuver.DIRECT"),
      value: ManeuverOrientation.DIRECT,
    },
    {
      label: I18n.t("schema.trackDevice.maneuver.DEFLECT"),
      value: ManeuverOrientation.DEFLECT,
    },
  ];

  export const label = (type?: ManeuverOrientation) => {
    switch (type) {
      case ManeuverOrientation.DEFLECT:
        return I18n.t("schema.trackDevice.maneuver.DEFLECT");
      default:
        return I18n.t("schema.trackDevice.maneuver.DIRECT");
    }
  };
}

export interface DispatchContentTrackChange {
  trackChangeNbr: number;
  from: string;
  maneuverOrientation: ManeuverOrientation;
  date: Date;
  additionalInformation?: string;
}

export enum DispatchGlobalTypeEnum {
  WORK_ZONE = "WORK_ZONE",
  TRAINING_ZONE = "TRAINING_ZONE",
}

export interface SplittingPart extends Partial<Omit<DispatchContent, "pkStart" | "TTXLength" | "authorizedSplitting" | "plannedImmobilization">> {
  pkStart?: Pk;
  TTXLength?: number;
  authorizedSplitting?: boolean;
  plannedImmobilization?: boolean;
}

export enum DispatchTypeEnum {
  WORK_ZONE_REQUEST_TO_OPEN_A_WORK_AREA = "WORK_ZONE_REQUEST_TO_OPEN_A_WORK_AREA",
  WORK_ZONE_AUTHORIZATION_TO_OPEN_A_WORK_AREA = "WORK_ZONE_AUTHORIZATION_TO_OPEN_A_WORK_AREA",
  WORK_ZONE_NOTICE_OF_OPENING_OF_A_WORK_AREA = "WORK_ZONE_NOTICE_OF_OPENING_OF_A_WORK_AREA",
  WORK_ZONE_REQUEST_FOR_RESTARTING_A_WORK_AREA_IN_STANDBY = "WORK_ZONE_REQUEST_FOR_RESTARTING_A_WORK_AREA_IN_STANDBY",
  WORK_ZONE_AUTHORIZATION_TO_RESTARTING_A_WORK_AREA_IN_STANDBY = "WORK_ZONE_AUTHORIZATION_TO_RESTARTING_A_WORK_AREA_IN_STANDBY",
  WORK_ZONE_CLOSURE_OF_A_WORK_AREA = "WORK_ZONE_CLOSURE_OF_A_WORK_AREA",
  WORK_ZONE_SET_STANDBY_WORK_AREA = "WORK_ZONE_SET_STANDBY_WORK_AREA",
  WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA = "WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA",
  WORK_ZONE_AUTHORIZATION_TO_MODIFY_THE_WORK_AREA = "WORK_ZONE_AUTHORIZATION_TO_MODIFY_THE_WORK_AREA",
  WORK_ZONE_NOTICE_OF_MODIFICATION_OF_THE_WORK_AREA = "WORK_ZONE_NOTICE_OF_MODIFICATION_OF_THE_WORK_AREA",
  SPLITTING_SPLITTING = "SPLITTING_SPLITTING",
  SPLITTING_POST_SPLITTING = "SPLITTING_POST_SPLITTING",
  SPLITTING_GROUPING = "SPLITTING_GROUPING",
  MOVEMENT_DELIVERY_ROUTE = "MOVEMENT_DELIVERY_ROUTE",
  MOVEMENT_ENGAGEMENT = "MOVEMENT_ENGAGEMENT",
  MOVEMENT_ENGAGEMENT_AUTHORIZATION = "MOVEMENT_ENGAGEMENT_AUTHORIZATION",
  MOVEMENT_ENGAGEMENT_ARRIVAL = "MOVEMENT_ENGAGEMENT_ARRIVAL",
  MOVEMENT_RELEASE = "MOVEMENT_RELEASE",
  MOVEMENT_REMOVAL = "MOVEMENT_REMOVAL",
  MOVEMENT_RESET = "MOVEMENT_RESET",
  MOVEMENT_IMMOBILIZATION_TTX = "MOVEMENT_IMMOBILIZATION_TTX",
  MOVEMENT_TRANSFER = "MOVEMENT_TRANSFER",
  /* PN */
  PN_TERMINATION = "PN_TERMINATION",
  PN_RECOVERY_MONITORING = "PN_RECOVERY_MONITORING",

  PN_REQUEST_TO_CLOSE_PN = "PN_REQUEST_TO_CLOSE_PN",
  PN_AUTHORIZATION_TO_CLOSE_PN = "PN_AUTHORIZATION_TO_CLOSE_PN",
  PN_NOTICE_TO_CLOSE_PN = "PN_NOTICE_TO_CLOSE_PN",

  PN_REQUEST_TO_REOPEN_PN = "PN_REQUEST_TO_REOPEN_PN",
  PN_AUTHORIZATION_TO_REOPEN_PN = "PN_AUTHORIZATION_TO_REOPEN_PN",
  PN_NOTICE_TO_REOPEN_PN = "PN_NOTICE_TO_REOPEN_PN",
  PN_ATTEST_REOPENED = "PN_ATTEST_REOPENED",

  ADD_DEMARCATION_TRAINING_ZONE = "ADD_DEMARCATION_TRAINING_ZONE",
  REMOVE_DEMARCATION_TRAINING_ZONE = "REMOVE_DEMARCATION_TRAINING_ZONE",
  AUTHORIZATION_TRAINING_ZONE = "AUTHORIZATION_TRAINING_ZONE",
  MODIFICATION_TRAINING_ZONE = "MODIFICATION_TRAINING_ZONE",
  CONFIRMATION_INITIAL_DEMARCATION_TRAINING_ZONE = "CONFIRMATION_INITIAL_DEMARCATION_TRAINING_ZONE",
  END_MISSION_TRAINING_ZONE = "END_MISSION_TRAINING_ZONE",

  /* WORKSITE_MACHINE */
  MOVEMENT_WORKSITE_MACHINE = "WORKSITE_MACHINE",
  END_MOVEMENT_WORKSITE_MACHINE = "END_MOVEMENT_WORKSITE_MACHINE",
  ENRAILMENT_WORKSITE_MACHINE = "ENRAILMENT_WORKSITE_MACHINE",
  ENGAGEMENT_WORKSITE_MACHINE = "ENGAGEMENT_WORKSITE_MACHINE",
  IMMOBILIZATION_WORKSITE_MACHINE = "IMMOBILIZATION_WORKSITE_MACHINE",
  UNENRAILMENT_WORKSITE_MACHINE = "UNENRAILMENT_WORKSITE_MACHINE",
  RELEASE_WORKSITE_MACHINE = "RELEASE_WORKSITE_MACHINE",

  /* TRACK CHANGE */
  MANEUVER_TRACK_CHANGE = "MANEUVER_TRACK_CHANGE",

  /* FREE */
  FREE_DISPATCH = "FREE_DISPATCH",
}

export type PNDispatchType =
  | DispatchTypeEnum.PN_TERMINATION
  | DispatchTypeEnum.PN_RECOVERY_MONITORING
  | DispatchTypeEnum.PN_REQUEST_TO_CLOSE_PN
  | DispatchTypeEnum.PN_AUTHORIZATION_TO_CLOSE_PN
  | DispatchTypeEnum.PN_NOTICE_TO_CLOSE_PN
  | DispatchTypeEnum.PN_REQUEST_TO_REOPEN_PN
  | DispatchTypeEnum.PN_AUTHORIZATION_TO_REOPEN_PN
  | DispatchTypeEnum.PN_NOTICE_TO_REOPEN_PN
  | DispatchTypeEnum.PN_ATTEST_REOPENED;

export type ZTDispatchType =
  | DispatchTypeEnum.WORK_ZONE_REQUEST_TO_OPEN_A_WORK_AREA
  | DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_OPEN_A_WORK_AREA
  | DispatchTypeEnum.WORK_ZONE_NOTICE_OF_OPENING_OF_A_WORK_AREA
  | DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_RESTARTING_A_WORK_AREA_IN_STANDBY
  | DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_RESTARTING_A_WORK_AREA_IN_STANDBY
  | DispatchTypeEnum.WORK_ZONE_CLOSURE_OF_A_WORK_AREA
  | DispatchTypeEnum.WORK_ZONE_SET_STANDBY_WORK_AREA
  | DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA
  | DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_MODIFY_THE_WORK_AREA
  | DispatchTypeEnum.WORK_ZONE_NOTICE_OF_MODIFICATION_OF_THE_WORK_AREA;

export type ZFDispatchType =
  | DispatchTypeEnum.ADD_DEMARCATION_TRAINING_ZONE
  | DispatchTypeEnum.REMOVE_DEMARCATION_TRAINING_ZONE
  | DispatchTypeEnum.AUTHORIZATION_TRAINING_ZONE
  | DispatchTypeEnum.MODIFICATION_TRAINING_ZONE
  | DispatchTypeEnum.CONFIRMATION_INITIAL_DEMARCATION_TRAINING_ZONE
  | DispatchTypeEnum.END_MISSION_TRAINING_ZONE;

export type TTxDispatchType =
  | DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE
  | DispatchTypeEnum.MOVEMENT_ENGAGEMENT
  | DispatchTypeEnum.MOVEMENT_ENGAGEMENT_AUTHORIZATION
  | DispatchTypeEnum.MOVEMENT_ENGAGEMENT_ARRIVAL
  | DispatchTypeEnum.MOVEMENT_RELEASE
  | DispatchTypeEnum.MOVEMENT_REMOVAL
  | DispatchTypeEnum.MOVEMENT_RESET
  | DispatchTypeEnum.MOVEMENT_IMMOBILIZATION_TTX
  | DispatchTypeEnum.MOVEMENT_TRANSFER
  | DispatchTypeEnum.SPLITTING_SPLITTING
  | DispatchTypeEnum.SPLITTING_POST_SPLITTING
  | DispatchTypeEnum.SPLITTING_GROUPING;

export namespace Dispatch {
  export const create = () => ({
    id: "",
    type: DispatchTypeEnum.WORK_ZONE_REQUEST_TO_OPEN_A_WORK_AREA,
    dispatch: {
      from: "",
      to: "",
      nbr: 0,
      natureOfWork: "",
      pkStart: {kilometer: 0, meter: 0},
      pkEnd: {kilometer: 0, meter: 0},
      way: 0,
      date: "",
    },
  });

  export const getActionDate = (type: DispatchTypeEnum) => {
    switch (type) {
      case DispatchTypeEnum.PN_REQUEST_TO_CLOSE_PN:
        return "dateFrom";
      case DispatchTypeEnum.MOVEMENT_TRANSFER:
      case DispatchTypeEnum.MOVEMENT_RESET:
      case DispatchTypeEnum.MOVEMENT_REMOVAL:
      case DispatchTypeEnum.MOVEMENT_RELEASE:
      case DispatchTypeEnum.MOVEMENT_IMMOBILIZATION_TTX:
      case DispatchTypeEnum.MOVEMENT_ENGAGEMENT_ARRIVAL:
      case DispatchTypeEnum.MOVEMENT_ENGAGEMENT_AUTHORIZATION:
      case DispatchTypeEnum.MOVEMENT_ENGAGEMENT:
      case DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE:
      case DispatchTypeEnum.PN_TERMINATION:
      case DispatchTypeEnum.PN_REQUEST_TO_REOPEN_PN:
      case DispatchTypeEnum.PN_RECOVERY_MONITORING:
      case DispatchTypeEnum.PN_NOTICE_TO_CLOSE_PN:
      case DispatchTypeEnum.PN_NOTICE_TO_REOPEN_PN:
      case DispatchTypeEnum.PN_AUTHORIZATION_TO_REOPEN_PN:
      case DispatchTypeEnum.PN_AUTHORIZATION_TO_CLOSE_PN:
      case DispatchTypeEnum.WORK_ZONE_SET_STANDBY_WORK_AREA:
      case DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_RESTARTING_A_WORK_AREA_IN_STANDBY:
      case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_RESTARTING_A_WORK_AREA_IN_STANDBY:
      case DispatchTypeEnum.WORK_ZONE_REQUEST_TO_OPEN_A_WORK_AREA:
      case DispatchTypeEnum.WORK_ZONE_NOTICE_OF_OPENING_OF_A_WORK_AREA:
      case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_OPEN_A_WORK_AREA:
      case DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA:
      case DispatchTypeEnum.WORK_ZONE_NOTICE_OF_MODIFICATION_OF_THE_WORK_AREA:
      case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_MODIFY_THE_WORK_AREA:
      case DispatchTypeEnum.WORK_ZONE_CLOSURE_OF_A_WORK_AREA:
      case DispatchTypeEnum.MANEUVER_TRACK_CHANGE:
      case DispatchTypeEnum.AUTHORIZATION_TRAINING_ZONE:
      case DispatchTypeEnum.MODIFICATION_TRAINING_ZONE:
      case DispatchTypeEnum.CONFIRMATION_INITIAL_DEMARCATION_TRAINING_ZONE:
      case DispatchTypeEnum.END_MISSION_TRAINING_ZONE:
      case DispatchTypeEnum.SPLITTING_SPLITTING:
      case DispatchTypeEnum.SPLITTING_GROUPING:
        return "date";
      case DispatchTypeEnum.PN_ATTEST_REOPENED:
      case DispatchTypeEnum.END_MOVEMENT_WORKSITE_MACHINE:
      case DispatchTypeEnum.ENGAGEMENT_WORKSITE_MACHINE:
      case DispatchTypeEnum.ENRAILMENT_WORKSITE_MACHINE:
      case DispatchTypeEnum.IMMOBILIZATION_WORKSITE_MACHINE:
      case DispatchTypeEnum.MOVEMENT_WORKSITE_MACHINE:
      case DispatchTypeEnum.RELEASE_WORKSITE_MACHINE:
      case DispatchTypeEnum.UNENRAILMENT_WORKSITE_MACHINE:
      case DispatchTypeEnum.FREE_DISPATCH:
      case DispatchTypeEnum.SPLITTING_POST_SPLITTING:
      default:
        return "";
    }
  };

  export const getInterlocutor = (type: DispatchTypeEnum) => {
    switch (type) {
      case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_OPEN_A_WORK_AREA:
      case DispatchTypeEnum.WORK_ZONE_NOTICE_OF_OPENING_OF_A_WORK_AREA:
      case DispatchTypeEnum.WORK_ZONE_REQUEST_TO_OPEN_A_WORK_AREA:
      case DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_RESTARTING_A_WORK_AREA_IN_STANDBY:
      case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_RESTARTING_A_WORK_AREA_IN_STANDBY:
      case DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA:
      case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_MODIFY_THE_WORK_AREA:
      case DispatchTypeEnum.WORK_ZONE_NOTICE_OF_MODIFICATION_OF_THE_WORK_AREA:
      case DispatchTypeEnum.WORK_ZONE_SET_STANDBY_WORK_AREA:
      case DispatchTypeEnum.WORK_ZONE_CLOSURE_OF_A_WORK_AREA:
      case DispatchTypeEnum.MOVEMENT_RESET:
      case DispatchTypeEnum.PN_TERMINATION:
      case DispatchTypeEnum.PN_REQUEST_TO_CLOSE_PN:
      case DispatchTypeEnum.PN_NOTICE_TO_CLOSE_PN:
      case DispatchTypeEnum.PN_AUTHORIZATION_TO_REOPEN_PN:
      case DispatchTypeEnum.PN_NOTICE_TO_REOPEN_PN:
      case DispatchTypeEnum.PN_REQUEST_TO_REOPEN_PN:
      case DispatchTypeEnum.CONFIRMATION_INITIAL_DEMARCATION_TRAINING_ZONE:
      case DispatchTypeEnum.END_MISSION_TRAINING_ZONE:
      case DispatchTypeEnum.MANEUVER_TRACK_CHANGE:
        return "from";
      case DispatchTypeEnum.MOVEMENT_REMOVAL:
      case DispatchTypeEnum.PN_RECOVERY_MONITORING:
      case DispatchTypeEnum.PN_AUTHORIZATION_TO_CLOSE_PN:
      case DispatchTypeEnum.PN_ATTEST_REOPENED:
      case DispatchTypeEnum.AUTHORIZATION_TRAINING_ZONE:
      case DispatchTypeEnum.MODIFICATION_TRAINING_ZONE:
      case DispatchTypeEnum.SPLITTING_SPLITTING:
      case DispatchTypeEnum.SPLITTING_GROUPING:
        return "to";
      case DispatchTypeEnum.MOVEMENT_IMMOBILIZATION_TTX:
      case DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE:
      case DispatchTypeEnum.MOVEMENT_ENGAGEMENT:
      case DispatchTypeEnum.MOVEMENT_RELEASE:
        return "ATTX";
      case DispatchTypeEnum.MOVEMENT_WORKSITE_MACHINE:
      case DispatchTypeEnum.ENRAILMENT_WORKSITE_MACHINE:
      case DispatchTypeEnum.ENGAGEMENT_WORKSITE_MACHINE:
      case DispatchTypeEnum.IMMOBILIZATION_WORKSITE_MACHINE:
      case DispatchTypeEnum.UNENRAILMENT_WORKSITE_MACHINE:
      case DispatchTypeEnum.RELEASE_WORKSITE_MACHINE:
      case DispatchTypeEnum.END_MOVEMENT_WORKSITE_MACHINE:
      case DispatchTypeEnum.MOVEMENT_ENGAGEMENT_ARRIVAL:
      case DispatchTypeEnum.MOVEMENT_ENGAGEMENT_AUTHORIZATION:
      case DispatchTypeEnum.SPLITTING_POST_SPLITTING:
      default:
        return "";
    }
  };
}

export const trainingZoneDispatchTypes: SelectComponentItem[] = [
  {
    label: I18n.t("dispatches.types.authorizationTrainingZone"),
    value: DispatchTypeEnum.AUTHORIZATION_TRAINING_ZONE,
  },
  {
    label: I18n.t("dispatches.types.modificationTrainingZone"),
    value: DispatchTypeEnum.MODIFICATION_TRAINING_ZONE,
  },
  {
    label: I18n.t("dispatches.types.confirmationTrainingZone"),
    value: DispatchTypeEnum.CONFIRMATION_INITIAL_DEMARCATION_TRAINING_ZONE,
  },
  {
    label: I18n.t("dispatches.types.endMissionTrainingZone"),
    value: DispatchTypeEnum.END_MISSION_TRAINING_ZONE,
  },
];

export const workAreaDispatchTypes: SelectComponentItem[] = [
  {
    label: I18n.t("dispatches.types.requestOpenWorkArea"),
    value: DispatchTypeEnum.WORK_ZONE_REQUEST_TO_OPEN_A_WORK_AREA,
  },
  {
    label: I18n.t("dispatches.types.authorizationOpeningWorkArea"),
    value: DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_OPEN_A_WORK_AREA,
  },
  {
    label: I18n.t("dispatches.types.noticeOpeningWorkArea"),
    value: DispatchTypeEnum.WORK_ZONE_NOTICE_OF_OPENING_OF_A_WORK_AREA,
  },
  {
    label: I18n.t("dispatches.types.restoringSleepingWorkAreaRequest"),
    value: DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_RESTARTING_A_WORK_AREA_IN_STANDBY,
  },
  {
    label: I18n.t("dispatches.types.restoringSleepingWorkAreaAuthorization"),
    value: DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_RESTARTING_A_WORK_AREA_IN_STANDBY,
  },
  {
    label: I18n.t("dispatches.types.closingWorkArea"),
    value: DispatchTypeEnum.WORK_ZONE_CLOSURE_OF_A_WORK_AREA,
  },
  {
    label: I18n.t("dispatches.types.sleepWorkArea"),
    value: DispatchTypeEnum.WORK_ZONE_SET_STANDBY_WORK_AREA,
  },
  {
    label: I18n.t("dispatches.types.requestModifyWorkArea"),
    value: DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA,
  },
  {
    label: I18n.t("dispatches.types.authorizationModifyWorkArea"),
    value: DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_MODIFY_THE_WORK_AREA,
  },
  {
    label: I18n.t("dispatches.types.noticeModifyWorkArea"),
    value: DispatchTypeEnum.WORK_ZONE_NOTICE_OF_MODIFICATION_OF_THE_WORK_AREA,
  },
];

export const workTrainDispatchTypes: SelectComponentItem[] = [
  {
    label: I18n.t("dispatches.types.movementDeliveryRoute"),
    value: DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE,
  },
  {
    label: I18n.t("dispatches.types.movementEngagement"),
    value: DispatchTypeEnum.MOVEMENT_ENGAGEMENT,
  },
  {
    label: I18n.t("dispatches.types.movementEngagementAuthorization"),
    value: DispatchTypeEnum.MOVEMENT_ENGAGEMENT_AUTHORIZATION,
  },
  {
    label: I18n.t("dispatches.types.movementEngagementArrival"),
    value: DispatchTypeEnum.MOVEMENT_ENGAGEMENT_ARRIVAL,
  },
  {
    label: I18n.t("dispatches.types.movementRelease"),
    value: DispatchTypeEnum.MOVEMENT_RELEASE,
  },
  {
    label: I18n.t("dispatches.types.movementRemoval"),
    value: DispatchTypeEnum.MOVEMENT_REMOVAL,
  },
  {
    label: I18n.t("dispatches.types.movementReinstatement"),
    value: DispatchTypeEnum.MOVEMENT_RESET,
  },
  {
    label: I18n.t("dispatches.types.movementImmobilizationTTx"),
    value: DispatchTypeEnum.MOVEMENT_IMMOBILIZATION_TTX,
  },
  {
    label: I18n.t("dispatches.types.splittingSplitting"),
    value: DispatchTypeEnum.SPLITTING_SPLITTING,
  },
  {
    label: I18n.t("dispatches.types.splittingPostSplitting"),
    value: DispatchTypeEnum.SPLITTING_POST_SPLITTING,
  },
  {
    label: I18n.t("dispatches.types.splittingGrouping"),
    value: DispatchTypeEnum.SPLITTING_GROUPING,
  },
];

export const worksiteMachineDispatchTypes: SelectComponentItem[] = [
  {
    label: I18n.t(`dispatches.types.worksiteMachine.${DispatchTypeEnum.MOVEMENT_WORKSITE_MACHINE}`),
    value: DispatchTypeEnum.MOVEMENT_WORKSITE_MACHINE,
  },
  {
    label: I18n.t(`dispatches.types.worksiteMachine.${DispatchTypeEnum.END_MOVEMENT_WORKSITE_MACHINE}`),
    value: DispatchTypeEnum.END_MOVEMENT_WORKSITE_MACHINE,
  },
  {
    label: I18n.t(`dispatches.types.worksiteMachine.${DispatchTypeEnum.ENRAILMENT_WORKSITE_MACHINE}`),
    value: DispatchTypeEnum.ENRAILMENT_WORKSITE_MACHINE,
  },
  {
    label: I18n.t(`dispatches.types.worksiteMachine.${DispatchTypeEnum.ENGAGEMENT_WORKSITE_MACHINE}`),
    value: DispatchTypeEnum.ENGAGEMENT_WORKSITE_MACHINE,
  },
  {
    label: I18n.t(`dispatches.types.worksiteMachine.${DispatchTypeEnum.IMMOBILIZATION_WORKSITE_MACHINE}`),
    value: DispatchTypeEnum.IMMOBILIZATION_WORKSITE_MACHINE,
  },
  {
    label: I18n.t(`dispatches.types.worksiteMachine.${DispatchTypeEnum.UNENRAILMENT_WORKSITE_MACHINE}`),
    value: DispatchTypeEnum.UNENRAILMENT_WORKSITE_MACHINE,
  },
  {
    label: I18n.t(`dispatches.types.worksiteMachine.${DispatchTypeEnum.RELEASE_WORKSITE_MACHINE}`),
    value: DispatchTypeEnum.RELEASE_WORKSITE_MACHINE,
  },
];

export const trackChangeDispatchTypes: SelectComponentItem[] = [
  {
    label: I18n.t(`dispatches.types.trackChange.${DispatchTypeEnum.MANEUVER_TRACK_CHANGE}`),
    value: DispatchTypeEnum.MANEUVER_TRACK_CHANGE,
  },
];

export const installationPnDispatchTypes: SelectComponentItem[] = [
  {
    label: I18n.t("dispatches.types.pn.termination"),
    value: DispatchTypeEnum.PN_TERMINATION,
  },
  {
    label: I18n.t("dispatches.types.pn.recoveryMonitoring"),
    value: DispatchTypeEnum.PN_RECOVERY_MONITORING,
  },
  {
    label: I18n.t("dispatches.types.pn.requestClosePn"),
    value: DispatchTypeEnum.PN_REQUEST_TO_CLOSE_PN,
  },
  {
    label: I18n.t("dispatches.types.pn.authorizationClosePn"),
    value: DispatchTypeEnum.PN_AUTHORIZATION_TO_CLOSE_PN,
  },
  {
    label: I18n.t("dispatches.types.pn.noticeClosePn"),
    value: DispatchTypeEnum.PN_NOTICE_TO_CLOSE_PN,
  },
  {
    label: I18n.t("dispatches.types.pn.requestOpenPn"),
    value: DispatchTypeEnum.PN_REQUEST_TO_REOPEN_PN,
  },
  {
    label: I18n.t("dispatches.types.pn.authorizationReopenPn"),
    value: DispatchTypeEnum.PN_AUTHORIZATION_TO_REOPEN_PN,
  },
  {
    label: I18n.t("dispatches.types.pn.noticeReopenPn"),
    value: DispatchTypeEnum.PN_NOTICE_TO_REOPEN_PN,
  },
  {
    label: I18n.t("dispatches.types.pn.attestReopened"),
    value: DispatchTypeEnum.PN_ATTEST_REOPENED,
  },
];

export const oldProjectDispatches = [
  ...workAreaDispatchTypes,
  ...workTrainDispatchTypes,
  ...installationPnDispatchTypes,
  ...trainingZoneDispatchTypes,
];

export const newProjectDispatches = [...trackChangeDispatchTypes, ...worksiteMachineDispatchTypes];

export const allDispatchesTypes = [
  ...workAreaDispatchTypes,
  ...workTrainDispatchTypes,
  ...installationPnDispatchTypes,
  ...trackChangeDispatchTypes,
  ...worksiteMachineDispatchTypes,
  ...trainingZoneDispatchTypes,
];