import {OrdersState, OrdersStore, ordersStore} from "@store/dispatches/orders.store";
import {CreateDispatch, DispatchTypeEnum, Order,} from "@store/dispatches/dispatch.model";
import {getDispatchesOrderLogicMap} from "@store/dispatches/orderLogic/orderLogicMap";
import {ID, QueryEntity} from "@datorama/akita";
import {map} from "rxjs";
import dayjs from "dayjs";
import {TrackElement} from "@store/project";

export class OrdersQuery extends QueryEntity<OrdersState, Order> {
  ordersByTrackElementId$ = (trackElementId: ID) =>
    this.selectAll().pipe(
      map((orders) => orders.filter((o) => o.trackElementId === trackElementId)),
      map((orders) =>
        orders.sort((o1, o2) => {
          const date1 = dayjs(o1.creationDate).toDate();
          const date2 = dayjs(o2.creationDate).toDate();
          if (!date1 || !date2) return 0;
          return date2.getTime() - date1.getTime();
        })
      )
    );

  orderById$ = (orderId: ID) => this.selectEntity(orderId);

  constructor(protected store: OrdersStore) {
    super(store);
  }

  lastModificationRequestDispatch(orderId: string) {
    const order = this.getAll().find((o) => o.id === orderId);

    return [...order?.dispatches || []]
      .sort((d1, d2) => {
        const date1 = new Date(d1.createdAt).getTime();
        const date2 = new Date(d2.createdAt).getTime();
        return date2 - date1;
      })
      .find((d) => d.type === DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA);
  }

  isDispatchInOrderLogic(dispatch: CreateDispatch, order: Order, trackElement: TrackElement) {
    if (!dispatch.type) return true;
    return getDispatchesOrderLogicMap(dispatch, order, trackElement);
  }

}

export const ordersQuery = new OrdersQuery(ordersStore);
