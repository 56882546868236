import {createStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const classes = (theme: Theme) => createStyles({
  icon: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 10,
    "& > :first-child": {
      padding: 13,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '50%',
      height: 70,
      width: 70
    },
    '&.disabled': {
      opacity: 0.5,
    },
  },
  text: {
    textOverflow: "ellipsis !important",
    whiteSpace: "nowrap",
    display: "inline-block",
    width: 120,
    overflow: "hidden",
    '&.disabled': {
      opacity: 0.5,
    },
  },
});

export default classes;
