import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Button, Stack, Typography} from "@mui/material";

import {projectService} from "@store/project";

import ModalComponent from "@components/modals/Modal.component";
import Step1Form from "@screens/auth/OS/projects/createProject/steps/Step1.form";
import Step2Form from "@screens/auth/OS/projects/createProject/steps/Step2.form";

const CreateProjectModal = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [succeed, setSucceed] = useState(false);

  const [step, setStep] = useState(1);

  useEffect(() => {
    return () => {
      projectService.unsetActiveProject();
    };
  }, []);

  const handleClose = () => navigate("..");

  if (succeed) {
    return (
      <ModalComponent
        handleClose={handleClose}
        title={t("projects.create.success.title")}
        content={(
          <Stack spacing={4} mt="20px">
            <img alt="success" height="80px" src="/images/check_icon.svg"/>
            <Typography fontSize={16} fontWeight="400" whiteSpace="pre-line" textAlign="center" lineHeight={1.5}>
              {t("projects.create.success.description")}
            </Typography>
          </Stack>
        )}
        actions={(
          <Button variant="contained" color="primary" onClick={handleClose}>
            {t("global.finish")}
          </Button>
        )}/>
    );
  }

  switch (step) {
    case 1:
      return <Step1Form handleClose={handleClose} goToNextStep={() => setStep(step + 1)}/>;
    case 2:
      return <Step2Form handleClose={handleClose} handleFinish={() => setSucceed(true)}/>;
    default:
      return null;
  }
};

export default CreateProjectModal;
