import React from "react";

import {
  TOOLTIP_DEFAULT_SPACING,
  TOOLTIP_HEIGHT,
  TRACK_ELEMENT_HEIGHT,
  TrackElement,
  TrackElementCategory,
  TrackElementType
} from "@store/project";
import {bindHover, usePopupState} from "material-ui-popup-state/hooks";

import TooltipDetails from "@screens/auth/common/schema/elements/TooltipDetails";

interface SpecialZoneElementProps {
  handleClick?: () => void;
  showTooltip?: boolean;
  tooltipDirection?: 'start' | 'end';
  trackElement: TrackElement;
  x1: number;
  x2: number;
  y: number;
}

const SpecialZoneElement = (props: SpecialZoneElementProps) => {
  const {handleClick, showTooltip, tooltipDirection, trackElement, x1, x2, y} = props;

  const tooltipState = usePopupState({variant: 'popover', popupId: `tooltip-${trackElement.id}`});

  const elementHeight = TRACK_ELEMENT_HEIGHT * (trackElement.type === TrackElementType.TRAINING_ZONE ? 1.5 : 1);

  const tooltipCoo = {
    x: x1 + (x2 - x1) / 2,
    y: y + (trackElement.type === TrackElementType.WORK_ZONE ? -1 : 1) * (elementHeight / 2 + TOOLTIP_HEIGHT / 2 + TOOLTIP_DEFAULT_SPACING / 2),
  };

  const textCooX = tooltipCoo.x + (tooltipDirection === "start" ? -1 : 1) * (TOOLTIP_HEIGHT / 2 + 8);

  return (
    <g>
      <rect
        x={x1} y={y - elementHeight / 2}
        rx={elementHeight / 2} ry={elementHeight / 2}
        fill={trackElement.category === TrackElementCategory.RESTRICTION ? "url(#restriction)" : TrackElementType.getColor(trackElement)}
        width={x2 - x1} height={elementHeight}
        style={{cursor: handleClick ? 'pointer' : undefined}} onClick={handleClick}
        {...bindHover(tooltipState)}/>
      {(showTooltip || tooltipState.isOpen) && (
        <g>
          <g style={{cursor: handleClick ? 'pointer' : undefined}} onClick={handleClick}>
            <circle
              cx={tooltipCoo.x} cy={tooltipCoo.y} r={TOOLTIP_HEIGHT / 2}
              fill={TrackElementType.getColor(trackElement)}/>
            <image
              xlinkHref={TrackElementType.getIcon(trackElement.type)}
              height={24} width={24}
              x={tooltipCoo.x - 12}
              y={tooltipCoo.y - 12}/>
          </g>
          <TooltipDetails
            trackElement={trackElement}
            position={tooltipDirection}
            textY={4.5}
            x={textCooX} y={tooltipCoo.y}/>
        </g>
      )}
    </g>
  );
}

export default SpecialZoneElement;