import {CreateDispatch, CreateDispatchContent, DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {number, object, string, date} from "yup";
import I18n from "@utils/i18n.utils";
import {PK_REGEXP} from "@store/project";
import * as YupUtils from "@utils/yup.utils";

const requiredString = string().required(I18n.t("errors.required"));
const requiredNumber = number().typeError(I18n.t("errors.number")).required(I18n.t("errors.number"));
const requiredPK = requiredString.matches(PK_REGEXP, I18n.t("errors.pk"));
const requiredDate = date().nullable()
.required(I18n.t("errors.required"))
.typeError(I18n.t("errors.date"));
const requiredHour = date().nullable()
.required(I18n.t("errors.required"))
.typeError(I18n.t("errors.hour"));

const endMission = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      nbr: requiredString,
      date: requiredDate,
      hour: requiredHour,
      pkStart: requiredPK,
      pkEnd: requiredPK,
      deliveredDispatchNbr: requiredNumber,
      receivedDispatchNbr: requiredNumber,
    })
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const confirmationInitialDemarcation = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      nbr: requiredString,
      date: requiredDate,
      hour: requiredHour,
      pkStart: requiredPK,
      pkEnd: requiredPK,
      deliveredDispatchNbr: requiredNumber,
      receivedDispatchNbr: requiredNumber,
    })
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const modification = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      nbr: requiredString,
      date: requiredDate,
      hour: requiredHour,
      pkStart: requiredPK,
      pkEnd: requiredPK,
      deliveredDispatchNbr: requiredNumber,
      receivedDispatchNbr: requiredNumber,
    })
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const authorization = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      nbr: requiredString,
      date: requiredDate,
      hour: requiredHour,
      pkStart: requiredPK,
      pkEnd: requiredPK,
      deliveredDispatchNbr: requiredNumber,
      receivedDispatchNbr: requiredNumber,
    })
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

export function trainingZoneDispatchValidation(data: CreateDispatch) {
  switch (data.type) {
    case DispatchTypeEnum.AUTHORIZATION_TRAINING_ZONE:
      return authorization(data.dispatch || {});
    case DispatchTypeEnum.MODIFICATION_TRAINING_ZONE:
      return modification(data.dispatch || {});
    case DispatchTypeEnum.CONFIRMATION_INITIAL_DEMARCATION_TRAINING_ZONE:
      return confirmationInitialDemarcation(data.dispatch || {});
    case DispatchTypeEnum.END_MISSION_TRAINING_ZONE:
      return endMission(data.dispatch || {});
  }

  return {};
}
