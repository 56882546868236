import {
	IncompatibilityError,
	IncompatibilityErrorEnum,
} from "@utils/incompatibilities/incompatibility.utils";
import { Pk, TrackElement } from "@store/project";
import { ID } from "@datorama/akita";
import _ from "lodash";
import { DispatchTypeEnum, TTxDispatchType } from "@store/dispatches/dispatch.model";

interface checkNoSplittingOutsideZTOrZFParams {
	fromDispatch?: boolean;
	dispatchType?: TTxDispatchType;
	trackElementId?: ID;
	pkStart: Pk;
	length: number;
	ZFs: TrackElement[];
	ZTs: TrackElement[];
}

/* Should not be able to split TTx outside a ZF or a ZT */
const checkNoSplittingTTXOutsideZFOrZT = (
	params: checkNoSplittingOutsideZTOrZFParams
): IncompatibilityError[] => {
	const { fromDispatch, dispatchType, trackElementId, pkStart, length, ZFs, ZTs } = params;

	if (!fromDispatch || !dispatchType || dispatchType !== DispatchTypeEnum.SPLITTING_SPLITTING)
		return [];

	const pkEnd = Pk.addMeters(pkStart, length);

	const ZTsAndZFsOnTTx = [...ZTs, ...ZFs].filter(
		(t) =>
			!!t.pkEnd &&
			(Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd) ||
				Pk.isPkBetweenPks(pkEnd, t.pkStart, t.pkEnd) ||
				Pk.isPkBetweenPks(t.pkStart, pkStart, pkEnd) ||
				Pk.isPkBetweenPks(t.pkEnd, pkStart, pkEnd)) &&
			!_.isEqual(pkStart, t.pkEnd) &&
			!_.isEqual(pkEnd, t.pkStart)
	);

	if (!ZTsAndZFsOnTTx.length) {
		return [
			{
				error: IncompatibilityErrorEnum.NO_SPLITTING_OUTSIDE_ZT_OR_ZF,
				trackElementId,
				concernedTrackElementIds: [],
			},
		];
	}

	return [];
};

export default checkNoSplittingTTXOutsideZFOrZT;
