import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {ID} from "@datorama/akita";

interface CheckNoWMonCCIParams {
  trackElementId?: ID;
  pkStart: Pk;
  CCIs: TrackElement[];
}

/* Should not be able to set the PK of the WM on a CCI */
const checkNoWMonCCI = (params: CheckNoWMonCCIParams): IncompatibilityError[] => {
  const {trackElementId, pkStart, CCIs} = params;

  const WMInCCIs = CCIs.filter(
    (t) => !!t.pkEnd && Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd)
  );

  if (!!WMInCCIs.length) {
    return WMInCCIs.map((CCI) => ({
      error: IncompatibilityErrorEnum.NO_WM_ON_CCI,
      trackElementId,
      concernedTrackElementIds: [CCI.id],
    }));
  }

  return [];
};

export default checkNoWMonCCI;
