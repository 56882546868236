import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {DispatchTypeEnum, ZTDispatchType} from "@store/dispatches/dispatch.model";
import {ID} from "@datorama/akita";

interface CheckOpenPNInZTParams {
  trackElementId?: ID;
  fromDispatch?: boolean;
  pkStart: Pk;
  pkEnd: Pk;
  PNs: TrackElement[];
  status?: ZTDispatchType;
}

/* Should not be able to close ZT if PN is currently opening */
const checkOpenPNInZT = (params: CheckOpenPNInZTParams): IncompatibilityError[] => {
  const {fromDispatch, pkStart, pkEnd, PNs, status, trackElementId} = params;

  if (fromDispatch && status === DispatchTypeEnum.WORK_ZONE_CLOSURE_OF_A_WORK_AREA) {
    const PNsInZT = PNs.filter((t) => Pk.isPkBetweenPks(t.pkStart, pkStart, pkEnd));

    const PNIsOpeningInZT = PNsInZT.filter(
      (PN) =>
        PN.attributes.status &&
        [DispatchTypeEnum.PN_REQUEST_TO_REOPEN_PN, DispatchTypeEnum.PN_AUTHORIZATION_TO_REOPEN_PN, DispatchTypeEnum.PN_NOTICE_TO_REOPEN_PN].includes(
          PN.attributes.status
        )
    );

    if (PNIsOpeningInZT) {
      return PNIsOpeningInZT.map((PN) => ({
        error: IncompatibilityErrorEnum.OPEN_PN_IN_ZT,
        trackElementId,
        concernedTrackElementIds: [PN.id],
      }));
    }
  }

  return [];
};

export default checkOpenPNInZT;
