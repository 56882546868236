import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {Box, Card, Checkbox, FormControlLabel, IconButton, Stack, Tab, Tabs, Typography} from "@mui/material";
import {NavLink, useNavigate, useParams} from "react-router-dom";

import {sessionQuery} from "@store/session";
import {Role} from "@store/users";
import {
  Pk,
  projectQuery,
  projectService,
  SCHEMA_SCALE,
  TrackCursor,
  TrackElement,
  TrackElementCategory,
  TrackElementType
} from "@store/project";

import {Sizes} from "@utils/theme/constants.utils";

import AddElementButtonComponent from "@screens/auth/common/schema/components/AddElementButton.component";
import ScaleComponent from "@screens/auth/common/schema/components/Scale.component";
import SelectElementForm from "@screens/auth/common/schema/forms/SelectElement.form";
import FullScreenSchemaModal from "@screens/auth/common/schema/components/FullScreenSchema.modal";
import LineSchemaComponent from "@screens/auth/common/schema/components/LineSchema.component";
import DeclivityComponent from "@screens/auth/common/schema/components/Declivity.component";
import FullScreenSpecialZoneModal from "@screens/auth/common/schema/components/FullScreenSpecialZone.modal";

export interface SchemaParameters {
  endX: number;
  height: number;
  kilometerSpacing: number;
  meterSpacing: number;
  navigationScale: number;
  padding: number;
  pkMaximum: number;
  pkMinimum: number;
  realWidth: number;
  startX: number;
  stepsNumber: number;
  width: number;
}

interface SchemaCardProps {
  isCreation?: boolean;
  isFullScreen?: boolean;
}

const SchemaComponent = (props: SchemaCardProps) => {
  const {isCreation, isFullScreen} = props;

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const {tabIndex} = useParams();

  const canvas = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(false);

  const [showSpecialZone, setShowSpecialZone] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);

  const activeStep = tabIndex && !isNaN(parseInt(tabIndex)) ? +tabIndex : 0;
  const [params, setParams] = useState<SchemaParameters>();

  const [trackElements, setTrackElements] = useState<TrackElement[]>([]);

  const [displayCategories, setDisplayCategories] = useState<TrackElementCategory[]>([]);
  const [showTrackElementForm, setShowTrackElementForm] = useState<TrackCursor | false>(false);

  useEffect(() => {
    const displayCategories: TrackElementCategory[] = JSON.parse(localStorage.getItem("displayCategories") || "[]");

    setDisplayCategories(displayCategories);
  }, []);

  useEffect(() => {
    projectService.getProjectTrackElements().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });

    const _trackElements$ = projectQuery.projectTrackElements$.subscribe(setTrackElements);

    return () => _trackElements$.unsubscribe();
  }, [enqueueSnackbar]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((event) => {
      const {endingKilometerPoint, startingKilometerPoint, navigationScale} = projectQuery.projectInfo;

      const pkMinimum = startingKilometerPoint.kilometer - (startingKilometerPoint.kilometer % navigationScale);
      const pkMaximum = endingKilometerPoint.kilometer % navigationScale === 0 ? endingKilometerPoint.kilometer : endingKilometerPoint.kilometer + navigationScale - (endingKilometerPoint.kilometer % navigationScale);

      const realWidth = event[0].contentRect.width * SCHEMA_SCALE;

      const newParams: SchemaParameters = {
        endX: event[0].target.getBoundingClientRect().right,
        height: event[0].contentRect.height,
        kilometerSpacing: realWidth / navigationScale,
        meterSpacing: realWidth / navigationScale / 1000,
        navigationScale,
        padding: realWidth * (1 - SCHEMA_SCALE) / 2,
        pkMaximum,
        pkMinimum,
        realWidth,
        startX: event[0].target.getBoundingClientRect().left,
        stepsNumber: Math.ceil((pkMaximum - pkMinimum) / navigationScale),
        width: event[0].contentRect.width,
      };

      setParams(newParams);
    });

    if (canvas.current) {
      resizeObserver.observe(canvas.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [canvas]);

  const updateDisplayCategories = (categories: TrackElementCategory[]) => {
    setDisplayCategories(categories);
    localStorage.setItem("displayCategories", JSON.stringify(categories));
  }

  const handleElementClick = (el: TrackElement) => {
    if (projectQuery.isProjectTypeNew) {
      switch (el.type) {
        case TrackElementType.TRACK_CHANGE:
        case TrackElementType.WORKSITE_MACHINE:
          return navigate(`${el.id}/0/details`);
        default:
          return navigate(`${el.id}`);
      }
    } else {
      switch (el.type) {
        case TrackElementType.TRACK_CHANGE:
        case TrackElementType.WORK_TRAIN:
        case TrackElementType.PN:
          return navigate(`${el.id}/0/details`);
        default:
          return navigate(`${el.id}`);
      }
    }
  }

  const stepStart = params ? params.pkMinimum + params.navigationScale * activeStep : 0;

  return (
    <Card variant="schema" sx={{
      border: isFullScreen ? 'none' : undefined,
      width: isFullScreen ? '100vw' : `calc(100vw - ${Sizes.leftBar}px - 20px - 20px - 20px)`
    }}>
      <Box ref={canvas} height="100%" width="100%">
        {!!params && (
          <Stack height="100%" width="100%">
            <Stack pb="20px">
              {!isFullScreen && (
                <IconButton aria-label="full-screen" onClick={() => setShowFullScreen(true)}>
                  <img alt="full screen" src="/images/expand_icon.svg"/>
                </IconButton>
              )}
              {sessionQuery.hasSufficientRoleOrAbove(Role.OS_R) && !projectQuery.isClosed && (
                <AddElementButtonComponent/>
              )}
            </Stack>
            <Stack direction="row" my="15px" maxWidth={params.width * 0.7} alignSelf="center">
              <Tabs aria-label="steps" variant="scrollable" scrollButtons value={activeStep}>
                {Array.from(Array(params.stepsNumber)).map((_, index) => (
                  <Tab
                    key={t("schema.fromTo", {
                      start: params.pkMinimum + params.navigationScale * index,
                      end: params.pkMinimum + (params.navigationScale * (index + 1))
                    }).toString()}
                    component={NavLink}
                    to={`../${index}`}
                    label={t("schema.fromTo", {
                      start: params.pkMinimum + params.navigationScale * index,
                      end: params.pkMinimum + (params.navigationScale * (index + 1))
                    })}/>
                ))}
              </Tabs>
            </Stack>
            <ScaleComponent stepStart={stepStart} params={params}/>
            <Stack flex={1} overflow="hidden">
              <Stack flex={1} overflow="auto" pt="10px" pb="20px" justifyContent="space-between"
                     sx={{overflowX: "hidden"}}>
                {!!trackElements.length && (
                  <Stack direction="row" alignItems="flex-start">
                    {!loading && (
                      <LineSchemaComponent
                        displayCategories={displayCategories}
                        handleLineClick={!projectQuery.isClosed && sessionQuery.hasSufficientRoleOrAbove(Role.RELF) ? setShowTrackElementForm : undefined}
                        handleElementClick={!projectQuery.isClosed ? handleElementClick : undefined}
                        handleSpecialZoneClick={(el) => navigate(el.id.toString())}
                        trackElements={trackElements.filter((e) => e.category !== TrackElementCategory.DECLIVITY)}
                        stepStart={stepStart} params={params}/>
                    )}
                  </Stack>
                )}
                <DeclivityComponent
                  handleElementClick={!projectQuery.isClosed ? (el) => navigate(el.id.toString()) : undefined}
                  params={params} stepStart={stepStart}
                  trackElements={trackElements.filter((e) =>
                    e.category === TrackElementCategory.DECLIVITY && !!e.pkEnd && Pk.isAfter(e.pkEnd, stepStart) && Pk.isBefore(e.pkStart, stepStart + params.navigationScale)
                  )}/>
              </Stack>
              {!!trackElements.length && (
                <Stack alignItems="center" direction="row" justifyContent="center" spacing={1} my="20px">
                  {TrackElementCategory[projectQuery.isProjectTypeNew ? "trackElementCategoriesNew" : "trackElementCategoriesNew"].map((c) => (
                    <FormControlLabel
                      key={c.value}
                      control={(
                        <Checkbox
                          checked={displayCategories.includes(c.value)}
                          onChange={(evt, checked) => {
                            if (checked) {
                              updateDisplayCategories(displayCategories.concat(c.value));
                            } else {
                              updateDisplayCategories(displayCategories.filter((v) => v !== c.value));
                            }
                          }}
                          size="small"/>
                      )}
                      label={<Typography fontSize={12} fontWeight="600" pt="3px">{c.label}</Typography>}/>
                  ))}
                </Stack>
              )}
            </Stack>
          </Stack>
        )}
        {showSpecialZone &&
          <FullScreenSpecialZoneModal handleClose={() => setShowSpecialZone(false)}/>
        }
        {showFullScreen && !isFullScreen && (
          <FullScreenSchemaModal handleClose={() => setShowFullScreen(false)}/>
        )}
        {!!showTrackElementForm && (
          <SelectElementForm
            isCreation={isCreation}
            trackCursor={showTrackElementForm}
            handleClose={() => setShowTrackElementForm(false)}/>
        )}
      </Box>
    </Card>
  );
}

export default SchemaComponent;
