import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {ID} from '@datorama/akita';

interface checkNoTTXBiggerThanZTParams {
  trackElementId?: ID;
  pkStart: Pk;
  length: number;
  ZTs: TrackElement[];
}

/* Should not be able to put on track a TTX bigger than the ZT it is in */
const checkNoTTXBiggerThanZT = (params: checkNoTTXBiggerThanZTParams): IncompatibilityError[] => {
  const {trackElementId, pkStart, length, ZTs} = params;

  const pkEnd = Pk.addMeters(pkStart, +length);

  const TTXBiggerThanZTs = ZTs.filter((t) => !!t.pkEnd
    && (Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd) || Pk.isPkBetweenPks(pkEnd, t.pkStart, t.pkEnd))
    && Pk.toMeters(t.pkEnd) - Pk.toMeters(t.pkStart) < +length
  );

  if (TTXBiggerThanZTs.length) {
    return TTXBiggerThanZTs.map((ZT) => ({
      error: IncompatibilityErrorEnum.NO_TTX_BIGGER_THAN_ZT,
      trackElementId,
      concernedTrackElementIds: [ZT.id],
    }));
  }

  return [];
}

export default checkNoTTXBiggerThanZT;