import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {DispatchTypeEnum, PNDispatchType} from "@store/dispatches/dispatch.model";
import {ID} from "@datorama/akita";

interface CheckOpenPNInZTParams {
  trackElementId?: ID;
  fromDispatch?: boolean;
  pkStart: Pk;
  status?: PNDispatchType;
  ZTs: TrackElement[];
}

/* Should not be able to open PN if not in a ZT */
const checkOpenPNInZT = (params: CheckOpenPNInZTParams): IncompatibilityError[] => {
  const {fromDispatch, trackElementId, pkStart, status, ZTs} = params;

  if (status) {
    const ZTsOnPN = ZTs.filter((t) => !!t.pkEnd && Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd));

    const PNIsOpening = fromDispatch
      ? [
          DispatchTypeEnum.PN_REQUEST_TO_REOPEN_PN,
          DispatchTypeEnum.PN_AUTHORIZATION_TO_REOPEN_PN,
          DispatchTypeEnum.PN_NOTICE_TO_REOPEN_PN,
          DispatchTypeEnum.PN_ATTEST_REOPENED,
        ].includes(status)
      : [DispatchTypeEnum.PN_REQUEST_TO_REOPEN_PN, DispatchTypeEnum.PN_AUTHORIZATION_TO_REOPEN_PN, DispatchTypeEnum.PN_NOTICE_TO_REOPEN_PN].includes(
          status
        );

    if (PNIsOpening && !ZTsOnPN.length) {
      return [
        {
          error: IncompatibilityErrorEnum.OPEN_PN_IN_ZT,
          trackElementId,
          concernedTrackElementIds: [],
        },
      ];
    }
  }

  return [];
};

export default checkOpenPNInZT;
