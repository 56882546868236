import {ID} from "@datorama/akita";

import {ProjectLight, ProjectType} from "@store/projects";
import {Pk, TrackElement} from "@store/project/schema.model";
import {User} from '@store/users';

export interface TTxPath {
  pkStart: Pk;
  pkEnd: Pk;
  trackId: ID;
  ADV?: TrackElement;
}

export interface UpdateProject extends Partial<Omit<ProjectLight, "isPending" | "startingKilometerPoint" | "endingKilometerPoint">> {
  id: ID;
  startingKilometerPoint?: string;
  endingKilometerPoint?: string;
}

export namespace UpdateProject {
  export const fromProject = (project: ProjectLight): UpdateProject => ({
    ...project,
    startingKilometerPoint: Pk.toString(project.startingKilometerPoint),
    endingKilometerPoint: Pk.toString(project.endingKilometerPoint),
  });
}

export interface CreateProject extends Partial<Omit<ProjectLight, "isPending" | "startingKilometerPoint" | "endingKilometerPoint">> {
  startingKilometerPoint?: string;
  endingKilometerPoint?: string;
}

export interface Project {
  info: ProjectLight;
  customers: User[];
  trackElements: TrackElement[];
  closedZTToday: TrackElement[]
}

export namespace Project {
  export const create = () => ({
    info: {
      id: "",
      isPending: false,
      name: "",
      lineId: "",
      from: "",
      to: "",
      os_c: undefined,
      os_r: undefined,
      relfs: [],
      clients: [],
      startingDate: undefined,
      endingDate: undefined,
      startingKilometerPoint: {kilometer: 0, meter: 0},
      endingKilometerPoint: {kilometer: 0, meter: 0},
      navigationScale: 0,
      projectType: ProjectType.LEGACY,
    },
    customers: [] as User[],
    trackElements: [] as TrackElement[],
    closedZTToday: [] as TrackElement[]
  });
}
