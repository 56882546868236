import React, {useEffect} from "react";
import {DispatchFormGlobalProps} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {Box, FormControlLabel, Grid, InputLabel, Radio, RadioGroup, Stack, Typography} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import {Pk, PK_INPUT_REGEXP, PK_REGEXP, projectQuery, TrackElementType} from "@store/project";
import {useTranslation} from "react-i18next";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import SelectComponent from "@components/select/Select.component";
import NumberTextFieldComponent from "@components/textField/NumberTextField.component";
import {ordersService} from "@store/dispatches/orders.service";
import {useParams} from "react-router-dom";
import {
  getADVsOnPath,
  getElementsOnPath,
  getNotPassableZTsOnPath,
  getPNsOnPath,
  getStopSignsOnPath,
} from "@screens/auth/common/schema/forms/dispatches/workTrain/getElementsOnDispatch";

const MovementDeliveryRouteForm = (props: DispatchFormGlobalProps) => {
  const {projectId} = useParams();
  const {t} = useTranslation();

  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const updateDispatchContent = (name: string) => (value: any) =>
    setDispatch({
      ...dispatch,
      dispatch: {...dispatch.dispatch, [name]: value},
    });

  useEffect(() => {
    ordersService.getDispatchNumber(projectId!).subscribe({
      next: (dispatchNbr) => {
        setDispatch({
          type: DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE,
          dispatch: {
            nbr: trackElement?.attributes.name,
            ATTX: trackElement?.attributes.ATTX,
            pkStart: Pk.toString(trackElement?.pkStart),
            splitting: trackElement?.attributes?.authorizedSplitting ?? false,
            immobilization: trackElement?.attributes?.plannedImmobilization ?? false,
            type: TrackElementType.WORK_ZONE,
            date: new Date(),
            hour: new Date(),
            deliveredDispatchNbr: dispatchNbr,
          },
        });
      },
    });
  }, []);

  useEffect(() => {
    if (editable) {
      let stringPkStart = dispatch.dispatch?.pkStart;
      let stringPkEnd = dispatch.dispatch?.pkEnd;

      if (!!stringPkStart && PK_REGEXP.test(stringPkStart) && !!stringPkEnd && PK_REGEXP.test(stringPkEnd) && !!trackElement?.tracks) {
        const pkStart = Pk.fromString(stringPkStart)!;
        const pkEnd = Pk.fromString(stringPkEnd)!;

        const TTXPath = projectQuery.getTTxPath(pkStart, pkEnd, trackElement.tracks[0]);

        const pnsOnPath = getPNsOnPath(pkStart, pkEnd);
        const instructions = getElementsOnPath(TTXPath);
        const advsOnPath = getADVsOnPath(TTXPath);
        const notPassableZTs = getNotPassableZTsOnPath(TTXPath);
        const stopSignsOnPath = getStopSignsOnPath(TTXPath);

        setDispatch({
          ...dispatch,
          dispatch: {
            ...dispatch.dispatch,
            pn: [...pnsOnPath, ...advsOnPath, ...stopSignsOnPath].join("\n"),
            specificInstructions: [...instructions, ...notPassableZTs].join("\n"),
          },
        });
      } else {
        setDispatch({
          ...dispatch,
          dispatch: {
            ...dispatch.dispatch,
            pn: undefined,
            specificInstructions: undefined,
          },
        });
      }
    }
  }, [editable, dispatch.dispatch?.pkStart, dispatch.dispatch?.pkEnd]);

  return (
    <div>
      <Grid container direction={"row"} columnSpacing={2.5} rowSpacing={1.5}>
        <Grid item xs={6}>
          <TextFieldComponent
            label={"Acheminiement sur la ligne fermée du TTX n°"}
            placeholder=" "
            readOnly={!editable}
            value={dispatch?.dispatch?.nbr || ""}
            handleChange={updateDispatchContent("nbr")}
            error={errors["nbr"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent
            label={"Nom ATTX"}
            readOnly={!editable}
            value={dispatch?.dispatch?.ATTX || ""}
            handleChange={updateDispatchContent("ATTX")}
            error={errors["ATTX"]}/>
        </Grid>
        <Grid item xs={2}/>

        <Grid item xs={4}>
          <TextFieldComponent
            label={"Point kilométrique de départ"}
            readOnly={!editable}
            value={dispatch?.dispatch?.pkStart || ""}
            pattern={PK_INPUT_REGEXP}
            handleChange={updateDispatchContent("pkStart")}
            error={errors["pkStart"]}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectComponent
            label={"TTx stationné sur ZT en sommeil n°"}
            readOnly={!editable}
            value={dispatch?.dispatch?.workZoneNbr || ""}
            items={projectQuery.projectZT.map((zt) => ({
              label: zt.attributes.name || "-",
              value: zt.id,
            }))}
            handleChange={updateDispatchContent("workZoneNbr")}
            error={errors["workZoneNbr"]}
          />
        </Grid>
        <Grid item xs={4} />

        <Grid item xs={1} />
        <Grid item xs={10}>
          <Stack direction="row" alignItems="center" justifyContent="center" mb="-7.5px">
            <InputLabel sx={{ml: "5px", pt: "6px", width: "60px"}}>{"Vers"}</InputLabel>
            <RadioGroup
              row
              value={dispatch.dispatch?.type ?? "null"}
              onChange={(evt, type) => updateDispatchContent("type")(type === "null" ? undefined : type)}>
              <FormControlLabel
                control={<Radio size="small" />}
                value={TrackElementType.WORK_ZONE}
                disabled={!editable}
                label={
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    ZONE TRAVAUX
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Radio size="small" />}
                value={TrackElementType.TRAINING_ZONE}
                disabled={!editable}
                label={
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    ZONE DE FORMATION
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Radio size="small" />}
                value="null"
                disabled={!editable}
                label={
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    PK DE DESTINATION
                  </Typography>
                }
              />
            </RadioGroup>
          </Stack>
        </Grid>
        <Grid item xs={1} />

        <Grid item xs={2} />
        <Grid item xs={4}>
          <TextFieldComponent
            label={"Située au Km"}
            readOnly={!editable}
            placeholder=" "
            handleChange={updateDispatchContent("pkEnd")}
            pattern={PK_INPUT_REGEXP}
            value={dispatch?.dispatch?.pkEnd || ""}
            error={errors["pkEnd"]}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectComponent
            label={"Sur voie"}
            readOnly={!editable}
            handleChange={updateDispatchContent("way")}
            value={dispatch?.dispatch?.way || ""}
            error={errors["way"]}
            items={projectQuery.projectTracks.map((t) => ({
              label: t.attributes.name || "-",
              value: t.id,
            }))}
          />
        </Grid>
        <Grid item xs={2} />

        <Grid item xs={12}>
          <Grid container direction={"row"} columnSpacing={2.5} rowSpacing={1.5}>
            <Grid item xs={8}>
              <TextFieldComponent
                label={"Mesures particulières - Restrictions de circulation"}
                placeholder=" "
                multiline
                readOnly={!editable}
                minRows={3}
                handleChange={updateDispatchContent("specificInstructions")}
                value={dispatch?.dispatch?.specificInstructions || ""}
                error={errors["specificInstructions"]}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldComponent
                label={"PN"}
                placeholder=" "
                multiline
                readOnly={!editable}
                minRows={3}
                handleChange={updateDispatchContent("pn")}
                value={dispatch?.dispatch?.pn || ""}
                error={errors["pn"]}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" mb="-7.5px">
            <InputLabel sx={{ml: "5px", pt: "6px", width: "180px"}}>{"Fractionnement autorisé"}</InputLabel>
            <RadioGroup
              row
              value={dispatch.dispatch?.splitting || false}
              onChange={(evt) => updateDispatchContent("splitting")(evt.target.value === "true")}>
              <FormControlLabel
                control={<Radio size="small" />}
                value={"true"}
                disabled={!editable}
                label={
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    {t("global.yes")}
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Radio size="small" />}
                value={"false"}
                disabled={!editable}
                label={
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    {t("global.no")}
                  </Typography>
                }
              />
            </RadioGroup>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" flex={1}>
            <Stack direction="row" alignItems="center">
              <InputLabel sx={{ml: "5px", pt: "6px", width: "180px"}}>{"Immobilisation prévue"}</InputLabel>
              <RadioGroup
                row
                value={dispatch.dispatch?.immobilization || false}
                onChange={(evt) => updateDispatchContent("immobilization")(evt.target.value === "true")}>
                <FormControlLabel
                  control={<Radio size="small" />}
                  value={"true"}
                  disabled={!editable}
                  label={
                    <Typography fontSize={12} fontWeight="600" pt="3px">
                      {t("global.yes")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={<Radio size="small" />}
                  value={"false"}
                  disabled={!editable}
                  label={
                    <Typography fontSize={12} fontWeight="600" pt="3px">
                      {t("global.no")}
                    </Typography>
                  }
                />
              </RadioGroup>
            </Stack>
            {dispatch?.dispatch?.immobilization && (
              <Stack direction="row" alignItems="center">
                <InputLabel sx={{pt: "6px", width: "50px"}}>{"AU KM"}</InputLabel>
                <Box width="250px">
                  <TextFieldComponent
                    readOnly={!editable}
                    handleChange={updateDispatchContent("pkImmobilization")}
                    pattern={PK_INPUT_REGEXP}
                    value={dispatch?.dispatch?.pkImmobilization || ""}
                    error={errors["pkImmobilization"]}
                  />
                </Box>
              </Stack>
            )}
          </Stack>
        </Grid>

        <Grid item xs={4}>
          <DatePickerComponent
            label={"Le"}
            placeholder=" "
            readOnly={!editable}
            value={dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("date")}
            error={errors["date"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            label={"à"}
            placeholder=" "
            readOnly={!editable}
            value={editable ? dispatch?.dispatch?.hour : dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("hour")}
            error={errors["hour"]}
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={t("schema.pn.dispatchDelivered")}
            readOnly={!editable}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            handleChange={updateDispatchContent("deliveredDispatchNbr")}
            value={dispatch?.dispatch?.deliveredDispatchNbr}
            error={errors["deliveredDispatchNbr"]}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={t("schema.pn.dispatchReceived")}
            readOnly={!editable}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            handleChange={updateDispatchContent("receivedDispatchNbr")}
            value={dispatch?.dispatch?.receivedDispatchNbr}
            error={errors["receivedDispatchNbr"]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MovementDeliveryRouteForm;
