import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import ModalComponent from "@components/modals/Modal.component";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {Box, Button, Grid} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import {CreatePeopleCountingHistory, TrackElementHistoryType} from "@store/dispatches/dispatch.model";
import {projectQuery} from "@store/project";
import {FieldErrors} from "@utils/yup.utils";
import {historyService} from "@store/history";
import SelectComponent from "@components/select/Select.component";
import {managePeopleCountingYup} from "@screens/auth/common/dispatch/managePeopleCounting/managePeopleCounting.yup";

export const ManagePeopleCountingModal = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const [peopleCounting, setPeopleCounting] = useState<CreatePeopleCountingHistory>({
    projectId: projectQuery.projectId,
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<FieldErrors>({});

  const handleClose = () => navigate("..");

  const updatePeopleCounting = (name: string) => (value: any) =>
    setPeopleCounting((state) => ({...state, [name]: value}));

  const handleCreate = () => {
    const errors = managePeopleCountingYup(peopleCounting);

    setErrors(errors);

    if (!!Object.keys(errors).length) return;

    setLoading(true);

    historyService.createPeopleCountingHistory(peopleCounting)
      .subscribe({
        next: () => {
          enqueueSnackbar(t('dispatches.managePeopleCounting.success'), {variant: 'success'});
          handleClose();
        },
        error: err => enqueueSnackbar(err.text, err.options),
      });
  }

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("dispatches.managePeopleCounting.title")}
      content={(
        <Box mt="15px" width="500px" display="flex" alignItems="flex-start" justifyContent="center">
          <Grid container columnSpacing={2.5} rowSpacing={1.5}>
            <Grid item xs={12}>
              <TextFieldComponent
                label={t("dispatches.managePeopleCounting.fields.interlocutor")}
                handleChange={updatePeopleCounting("interlocutor")}
                value={peopleCounting.interlocutor || ""}
                error={errors.interlocutor}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldComponent
                label={t("dispatches.managePeopleCounting.fields.nature")}
                handleChange={updatePeopleCounting("nature")}
                value={peopleCounting.nature || ""}
                error={errors.nature}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldComponent
                label={t("dispatches.managePeopleCounting.fields.company")}
                handleChange={updatePeopleCounting("company")}
                value={peopleCounting.company || ""}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectComponent
                label={t("dispatches.managePeopleCounting.fields.type")}
                placeholder={t("dispatches.managePeopleCounting.fields.type")}
                handleChange={updatePeopleCounting("type")}
                value={peopleCounting.type || ""}
                error={errors.type}
                items={[
                  {
                    label: t(`dispatches.managePeopleCounting.types.${TrackElementHistoryType.PEOPLE_COUNTING_ENTRANCE}`),
                    value: TrackElementHistoryType.PEOPLE_COUNTING_ENTRANCE,
                  },
                  {
                    label: t(`dispatches.managePeopleCounting.types.${TrackElementHistoryType.PEOPLE_COUNTING_EXIT}`),
                    value: TrackElementHistoryType.PEOPLE_COUNTING_EXIT,
                  },
                ]}
                required/>
            </Grid>
            <Grid item xs={6}>
              <TextFieldComponent
                label={t("dispatches.managePeopleCounting.fields.number")}
                pattern={new RegExp(/^\d*$/)}
                handleChange={updatePeopleCounting("number")}
                error={errors.number}
                value={peopleCounting.number || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldComponent
                multiline
                rows={3}
                label={t("dispatches.managePeopleCounting.fields.observations")}
                handleChange={updatePeopleCounting("observations")}
                value={peopleCounting.observations || ""}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      actions={(
        <Button variant="contained" color="primary" disabled={loading} onClick={handleCreate}>
          {t("global.saveAndValidate")}
        </Button>
      )}/>
  );
}
