import React, {useEffect, useState} from "react";
import ModalComponent from "@components/modals/Modal.component";
import {Box, Button, Stack} from "@mui/material";
import TabsComponent from "@components/tabs/Tabs.component";
import WorkAreaForm from "@screens/auth/common/schema/forms/trackElements/WorkArea.form";
import {
  CreateOrUpdateTrackElement,
  Pk,
  projectQuery,
  projectService,
  TrackElement,
  TrackElementType
} from "@store/project";
import {useTranslation} from "react-i18next";
import {trackElementValidation} from "@screens/auth/common/schema/forms/trackElements/forms.yup";
import {FieldErrors} from "@utils/yup.utils";
import {useSnackbar} from "notistack";
import {finalize} from "rxjs";
import {useNavigate, useParams} from "react-router-dom";
import {RoutesTrackElement} from "@utils/routes.utils";
import TrainingZoneForm from "@screens/auth/common/schema/forms/trackElements/TrainingZone.form";
import WorkTrainForm from "@screens/auth/common/schema/forms/trackElements/WorkTrain.form";
import InstallationPnForm from "@screens/auth/common/schema/forms/trackElements/InstallationPn.form";
import WorksiteMachineForm from "@screens/auth/common/schema/forms/trackElements/WorksiteMachine.form";
import TrackDeviceTrackChangeForm from "@screens/auth/common/schema/forms/trackElements/TrackDeviceTrackChange.form";
import {sessionQuery, sessionService} from "@store/session";
import {Role} from "@store/users";
import useIncompatibilitiesAcceptation from "../../../components/IncompatibilitiesAcceptation.component";
import DeleteConfirmation from "../../../components/DeleteConfirmation.component";
import ConfirmCodeModal from "@components/confirmCode/ConfirmCode.modal";
import {IncompatibilityError} from "@utils/incompatibilities/incompatibility.utils";

const SpecialElementInfoModal = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const {trackElementId} = useParams();

  const [loading, setLoading] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmCode, setShowConfirmCode] = useState<IncompatibilityError[] | false>(false);

  const [baseInfo, setBaseInfo] = useState<TrackElement>();
  const [trackElement, setTrackElement] = useState<CreateOrUpdateTrackElement>({});

  const [errors, setErrors] = useState<FieldErrors>({});

  const {
    IncompatibilitiesAcceptation,
    disabledByIncompatibilities,
    incompatibilities
  } = useIncompatibilitiesAcceptation({trackElement});

  useEffect(() => {
    const _trackElementId$ = projectQuery.projectTrackElementById$(trackElementId || "").subscribe((el) => {
      setBaseInfo(el);
      setTrackElement(el ? CreateOrUpdateTrackElement.fromTrackElement(el) : {});
    });

    return () => {
      _trackElementId$.unsubscribe();
    };
  }, [trackElementId]);

  const handleClose = () => navigate(-1);

  const checkErrors = () => {
    const errors = trackElementValidation(trackElement);

    setErrors(errors);
    return errors;
  };

  const handleAdd = () => {
    const errors = checkErrors();

    if (!Object.keys(errors).length) {
      setLoading(true);
      projectService
        .updateTrackElement(
          trackElement,
          incompatibilities?.map((i) => t(`incompatibilities.${i.error}`))
        )
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => enqueueSnackbar(t("schema.success.elementUpdated"), {variant: "success"}),
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    }
  };

  const handleDelete = (incompatibilities?: IncompatibilityError[]) => {
    setLoading(true);
    projectService
      .deleteTrackElement(
        trackElement.id || "",
        incompatibilities?.map((i) => t(`incompatibilities.${i.error}`))
      )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("schema.success.elementDeleted"), {variant: "success"});
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  if (!baseInfo) return null;

  const getModalTitle = () => {
    switch (baseInfo.type) {
      case TrackElementType.TRAINING_ZONE:
        return t("schema.zone.types.trainingZone");
      case TrackElementType.WORK_ZONE:
        return t(`schema.workArea.name`, {name: baseInfo.attributes?.name || "-"});
      case TrackElementType.WORK_TRAIN:
        return t("schema.workTrain.state.subtitle", {name: baseInfo.attributes?.name || "-"});
      case TrackElementType.PN:
        return t("schema.pn.name", {name: baseInfo.attributes?.name || "-"});
      case TrackElementType.WORKSITE_MACHINE:
        return t("schema.worksiteMachine.worksiteMachine", {name: baseInfo.attributes?.name || "-"});
      case TrackElementType.TRACK_CHANGE:
        return t("schema.trackDevice.types.trackChange", {name: baseInfo.attributes?.name || "-"});
      default:
        return "-";
    }
  };

  const pkStart = Pk.fromString(trackElement.pkStart);
  const pkEnd = Pk.fromString(trackElement.pkEnd);
  const isZFEncompassedByZT =
    trackElement &&
    trackElement.type === TrackElementType.TRAINING_ZONE &&
    projectQuery.projectZT.some(
      (ZT) => !!ZT.pkEnd && !!pkStart && !!pkEnd && Pk.isPkBetweenPks(pkStart, ZT.pkStart, ZT.pkEnd) && Pk.isPkBetweenPks(pkEnd, ZT.pkStart, ZT.pkEnd)
    );

  const getForm = () => {
    switch (baseInfo.type) {
      case TrackElementType.TRAINING_ZONE:
        return (
          <TrainingZoneForm
            readOnly={sessionQuery.hasAnyOfRoles(Role.CLIENT) || projectQuery.isClosed}
            setTrackElement={setTrackElement}
            trackElement={trackElement}
            errors={errors}
            IncompatibilitiesAcceptation={!isZFEncompassedByZT ? IncompatibilitiesAcceptation : undefined}
          />
        );
      case TrackElementType.WORK_ZONE:
        return (
          <WorkAreaForm
            readOnly={sessionQuery.hasAnyOfRoles(Role.CLIENT) || projectQuery.isClosed}
            setTrackElement={setTrackElement}
            trackElement={trackElement}
            errors={errors}
            IncompatibilitiesAcceptation={IncompatibilitiesAcceptation}
          />
        );
      case TrackElementType.WORK_TRAIN:
        return (
          <WorkTrainForm
            readOnly={sessionQuery.hasAnyOfRoles(Role.CLIENT) || projectQuery.isClosed}
            setTrackElement={setTrackElement}
            trackElement={trackElement}
            errors={errors}
            IncompatibilitiesAcceptation={IncompatibilitiesAcceptation}
          />
        );
      case TrackElementType.PN:
        return (
          <InstallationPnForm
            readOnly={sessionQuery.hasAnyOfRoles(Role.CLIENT) || projectQuery.isClosed}
            setTrackElement={setTrackElement}
            trackElement={trackElement}
            errors={errors}
            IncompatibilitiesAcceptation={IncompatibilitiesAcceptation}
          />
        );
      case TrackElementType.WORKSITE_MACHINE:
        return (
          <WorksiteMachineForm
            readOnly={sessionQuery.hasAnyOfRoles(Role.CLIENT) || projectQuery.isClosed}
            setTrackElement={setTrackElement}
            trackElement={trackElement}
            errors={errors}
            IncompatibilitiesAcceptation={IncompatibilitiesAcceptation}
          />
        );
      case TrackElementType.TRACK_CHANGE:
        return (
          <TrackDeviceTrackChangeForm
            setTrackElement={setTrackElement}
            trackElement={trackElement}
            errors={errors}
            readOnly={(projectQuery.isProjectTypeNew && sessionQuery.hasRoleOrBelow(Role.RELF)) || projectQuery.isClosed}
            IncompatibilitiesAcceptation={IncompatibilitiesAcceptation}
          />
        );
      default:
        return null;
    }
  };

  const shouldDisplayTab = projectQuery.isProjectTypeNew
    ? [TrackElementType.WORKSITE_MACHINE, TrackElementType.TRACK_CHANGE]
    : [
      TrackElementType.PN,
      TrackElementType.WORK_TRAIN,
      TrackElementType.WORK_ZONE,
      TrackElementType.TRAINING_ZONE,
      TrackElementType.TRACK_CHANGE,
    ].includes(baseInfo.type);

  return (
    <>
      <ModalComponent
        handleClose={handleClose}
        title={getModalTitle()}
        description={`${t("global.pk")} ${Pk.toString(baseInfo.pkStart)}${
          baseInfo.pkEnd ? ` - ${t("global.pk")} ${Pk.toString(baseInfo.pkEnd)}` : ""
        }`}
        fullWidth
        content={
          <Stack height={shouldDisplayTab ? "80vh" : undefined} width="100%" alignItems="center" justifyContent="start"
                 overflow="hidden">
            {shouldDisplayTab && (
              <Box width="80%" my="20px">
                <TabsComponent
                  value={0}
                  handleChange={(value) => navigate(`../${RoutesTrackElement.routes[value]}`)}
                  tabs={[
                    t("dispatches.tabs.info"),
                    t(`dispatches.tabs.${projectQuery.isProjectTypeNew ? "ordersAndDispatchesNew" : "ordersAndDispatches"}`),
                  ]}
                />
              </Box>
            )}
            {getForm()}
          </Stack>
        }
        actions={
          !projectQuery.isClosed && (baseInfo.type !== TrackElementType.TRACK_CHANGE || sessionQuery.hasSufficientRoleOrAbove(Role.OS_R)) && !sessionQuery.hasAnyOfRoles(Role.CLIENT) ? (
            <>
              <Button
                variant="contained"
                color="primary"
                disabled={loading || isZFEncompassedByZT || disabledByIncompatibilities}
                onClick={
                  !!IncompatibilitiesAcceptation
                    ? () => {
                      const errors = checkErrors();
                      if (!Object.keys(errors).length) setShowConfirmCode(incompatibilities);
                    }
                    : handleAdd
                }>
                {t("global.modify")}
              </Button>
              {(![TrackElementType.PN, TrackElementType.TRAINING_ZONE].includes(baseInfo.type)
                || sessionQuery.role && [Role.SUPER_ADMIN, Role.OS_C, Role.OS_R].includes(sessionQuery.role)) && (
                <Button
                  variant="contained"
                  color="error"
                  disabled={loading || isZFEncompassedByZT}
                  onClick={() => setShowConfirmDelete(true)}>
                  {t("global.delete")}
                </Button>
              )}
            </>
          ) : undefined
        }
      />
      {showConfirmDelete && (
        <DeleteConfirmation
          trackElement={trackElement}
          handleClose={() => setShowConfirmDelete(false)}
          handleConfirm={() => handleDelete(incompatibilities)}
        />
      )}
      {showConfirmCode && (
        <ConfirmCodeModal
          handleClose={() => setShowConfirmCode(false)}
          handleConfirm={(code) => sessionService.confirmIdentificationCode(code)}
          handleSuccess={() => {
            setShowConfirmCode(false);
            handleAdd();
          }}
        />
      )}
    </>
  );
};

export default SpecialElementInfoModal;
