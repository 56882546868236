import {object, ref, string} from 'yup';

import {UpdateUserPassword} from '@store/users';

import * as YupUtils from "@utils/yup.utils";
import I18n from "@utils/i18n.utils";

export const resetUserPasswordValidation = (data: UpdateUserPassword) => {
  const requiredString = string().required(I18n.t("errors.required"));

  try {
    const schema = object().shape({
      newPassword: requiredString,
      confirmPassword: requiredString.oneOf([ref('newPassword')], I18n.t("errors.confirmPassword")),
    });

    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}
