import React from "react";
import {useTranslation} from "react-i18next";
import {Grid, Stack} from "@mui/material";

import {CreateOrUpdateTrackElement, PK_INPUT_REGEXP, projectQuery} from "@store/project";
import {FieldErrors} from "@utils/yup.utils";
import TextFieldComponent from "@components/textField/TextField.component";
import SelectComponent from "@components/select/Select.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";

interface WorksiteMachineFormProps {
  readOnly?: boolean;
  setTrackElement: (trackElement: CreateOrUpdateTrackElement) => void;
  trackElement: CreateOrUpdateTrackElement;
  errors: FieldErrors;
  IncompatibilitiesAcceptation?: (() => JSX.Element) | null;
}

const WorksiteMachineForm = (props: WorksiteMachineFormProps) => {
  const {
    errors,
    readOnly,
    setTrackElement,
    trackElement,
    IncompatibilitiesAcceptation,
  } = props;

  const {t} = useTranslation();

  const updateTrackElement = (name: string) => (value: any) => setTrackElement({...trackElement, [name]: value});
  const updateAttributes = (name: string) => (value: any) => setTrackElement({
    ...trackElement,
    attributes: {...trackElement.attributes, [name]: value}
  });

  return (
    <Stack flex={1} direction="column" justifyContent="center" alignItems="center" height="100%" pt="20px" pb="40px"
           overflow="auto" className="scrollable">
      <Grid container width="60%" columnSpacing={2.5} rowSpacing={1.5}>
        <Grid item xs={12}>
          <TextFieldComponent
            readOnly={readOnly}
            label={t("schema.worksiteMachine.name")}
            handleChange={updateAttributes("name")}
            value={trackElement.attributes?.name || ""}
            error={errors["attributes.name"]}/>
        </Grid>
        <Grid item xs={12}>
          <SelectComponent
            readOnly={readOnly}
            label={t("schema.worksiteMachine.selectTrack")}
            placeholder={t("schema.trackDevice.selectTrackPlaceholder")}
            handleChange={(track) => {
              updateTrackElement("tracks")(track ? [track] : undefined);
            }}
            value={!!trackElement.tracks?.length ? trackElement.tracks[0] : ""}
            error={errors.tracks}
            items={projectQuery.projectTracks.map((t) => ({label: t.attributes?.name || "-", value: t.id}))}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectComponent
            readOnly={readOnly}
            label={t(`schema.worksiteMachine.${projectQuery.isProjectTypeNew ? "typeNew." : ""}ZT`)}
            placeholder={t(`schema.worksiteMachine.${projectQuery.isProjectTypeNew ? "typeNew." : ""}ZTPlaceholder`)}
            handleChange={updateAttributes("ZT")}
            value={trackElement.attributes?.ZT || ""}
            items={projectQuery[projectQuery.isProjectTypeNew ? "projectCE" : "projectZT"].map((t) => ({
              label: t.attributes?.name || "-",
              value: t.id
            }))}
            error={errors["attributes.ZT"]}/>
        </Grid>
        <Grid item xs={6}>
          <TextFieldComponent
            readOnly={readOnly}
            label={t("schema.worksiteMachine.startPK")}
            handleChange={updateTrackElement("pkStart")}
            pattern={PK_INPUT_REGEXP}
            value={trackElement.pkStart || ""}
            error={errors.pkStart}/>
        </Grid>
        <Grid item xs={6}>
          <TimePickerComponent
            readOnly={readOnly}
            label={t("schema.worksiteMachine.entrustingTime")}
            handleChange={updateAttributes("dateTime")}
            value={trackElement.attributes?.dateTime}
            error={errors["attributes.dateTime"]}/>
        </Grid>
      </Grid>
      {IncompatibilitiesAcceptation && (
        <Stack pt="20px">
          <IncompatibilitiesAcceptation/>
        </Stack>
      )}
    </Stack>
  );
}

export default WorksiteMachineForm;