import {AxiosError, AxiosResponse} from "axios";
import {catchError, finalize, from, map, Observable, switchMap, tap} from "rxjs";

import {sessionStore, SessionStore} from "@store/session/session.store";
import {AuthenticationResponse, CustomAuthentication, IdentificationCode} from "@store/session/session.model";

import APIAxios, {APIRoutes} from "@api/axios.api";
import SnackError from "@utils/error.utils";
import {User} from '@store/users';

export class SessionService {
  constructor(private store: SessionStore) {
  }

  login = (data: CustomAuthentication): Observable<AuthenticationResponse> => {
    this.store.setLoading(true);

    return from(APIAxios({...APIRoutes.POSTLogin(), data}))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError(err.response?.data?.message, "error");
        }),
        map((response: AxiosResponse<AuthenticationResponse>) => {
          return response.data;
        }),
        map((response) => {
          this.store.login(response);
          return response;
        }),
        finalize(() => this.store.setLoading(false)),
      );
  }

  forgotPassword = (email: string): Observable<AuthenticationResponse> => {
    return from(APIAxios(APIRoutes.GETForgotPassword(email)))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError(err.response?.data?.message, "error");
        }),
        map((response: AxiosResponse<AuthenticationResponse>) => {
          return response.data;
        }),
      );
  }

  fetchCurrentUser = (): Observable<User> => {
    return from(APIAxios(APIRoutes.GETCurrentUser()))
      .pipe(
        catchError((err: AxiosError) => {
          this.store.logout();
          throw new SnackError(err.response?.data?.message, "error");
        }),
        map((response: AxiosResponse<User>) => {
          return response.data;
        }),
        tap((user) => this.store.update({user})),
      );
  }

  getIdentificationCode = (password: string): Observable<IdentificationCode> => {
    return from(APIAxios({...APIRoutes.GETCurrentUserCode(), data: {password}}))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError(err.response?.data?.message, "error");
        }),
        map((response: AxiosResponse<IdentificationCode>) => {
          return response.data;
        }),
      );
  }

  confirmIdentificationCode = (code: string): Observable<AxiosResponse> => {
    return from(APIAxios({...APIRoutes.POSTConfirmCode(), data: {code}})).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
    );
  };

  logout = () => this.store.logout();

  changePassword = (previousPassword: string, newPassword: string): Observable<User> => {
    return from(APIAxios({...APIRoutes.PATCHChangePassword(), data: {previousPassword, newPassword}}))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError(err.response?.data?.message, "error");
        }),
        switchMap(() => this.fetchCurrentUser()),
      );
  }
}

export const sessionService = new SessionService(sessionStore);
