import React, {useEffect, useMemo, useState} from "react";
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';

import {ProjectDispatchesStats, reportingService} from '@store/reporting';
import {projectQuery, TrackElement} from '@store/project';
import {TrackElementType} from '@store/project/schema.model';

import DoughnutChart from '@components/chart/Doughnut.chart';
import {allDispatchesTypes} from '@store/dispatches/dispatch.model';
import {finalize} from 'rxjs';

const ProjectDispatchesChart = () => {
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ProjectDispatchesStats[]>([]);

  useEffect(() => {
    reportingService.getDispatchesStats(projectQuery.projectId).pipe(finalize(() => setLoading(false))).subscribe({
      next: setData,
      error: (err) => enqueueSnackbar(err.text),
    });
  }, []);

  const total = useMemo(() => {
    return data.reduce((res, d) => res + d.total, 0);
  }, [data]);

  return (
    <DoughnutChart<ProjectDispatchesStats>
      displayTotal
      total={total}
      loading={loading}
      labels={[
        {label: 'Catégorie de dépêches', field: 'type'},
        {label: 'Nombre de dépêches', field: 'total'},
      ]}
      getTooltip={(x) =>
        `<div style="padding: 5px 10px">
          <div style="line-height: 1.25; margin-bottom: 5px">${x.type}</div>
          <strong style="white-space: nowrap">${t("dispatches.dispatchCount", {count: x.total ?? 0})}</strong>
          <br/>
          <br/>
          ${Object.entries(x.perType).map(([key, value]) =>
          `<div style="font-size: 12px">${allDispatchesTypes.find((y) => y.value === key)?.label} : <span style="font-weight: bold">${value}</span></div>`
        ).join('')}
        </div>`
      }
      data={data.map((x) => ({
        ...x,
        type: TrackElementType.getRELFElementLabel(x.type as TrackElementType),
        total: x.total,
        color: TrackElementType.getColor({type: x.type} as TrackElement),
      }))}
    />
  );
}

export default ProjectDispatchesChart;