import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement, TrackElementType} from "@store/project";
import {ID} from "@datorama/akita";
import checkZFWithOneFreeTrack from '@utils/incompatibilities/ZF/ZF_WITH_ONE_FREE_TRACK/ZFWithOneFreeTrack';
import {getZFBlockingElementsOnPks} from '@utils/incompatibilities/common/functions/getZFBlockingElementsOnPks';

interface CheckZFWithOneFreeTrackParams {
  trackElementId?: ID;
  type: TrackElementType;
  trackIds: ID[];
  pkStart: Pk;
  pkEnd?: Pk;
  length?: number;
  otherElements: TrackElement[];
  ZFs: TrackElement[];
  tracks: TrackElement[];
  ADVs: TrackElement[];
}

/* Should not be able to add specific elements on the tracks of a ZF if none of the ZF's tracks are a free zone */
const commonCheckZFWithOneFreeTrack = (params: CheckZFWithOneFreeTrackParams): IncompatibilityError[] => {
  const {trackElementId, type, trackIds, pkStart, pkEnd, length, otherElements, ZFs, tracks, ADVs} = params;

  const currentTrackElement = {
    id: trackElementId,
    type,
    pkStart,
    pkEnd,
    tracks: trackIds,
    attributes: {TTXLength: length ?? 0},
  } as TrackElement;

  // Get all ZFs on which the element is
  const ZFsOnElement = ZFs.filter((t) =>
    !!t.pkEnd && getZFBlockingElementsOnPks(currentTrackElement, t.pkStart, t.pkEnd)
  );

  if (!ZFsOnElement.length) return [];

  let incompatibilities: IncompatibilityError[] = [];

  ZFsOnElement.forEach((ZF) => {
    const ZFIncompatibilities = checkZFWithOneFreeTrack({
      trackElementId: ZF.id,
      pkStart: ZF.pkStart,
      pkEnd: ZF.pkEnd!,
      specificTrackElement: currentTrackElement,
      otherElements,
      tracks,
      ADVs,
    });

    if (!!ZFIncompatibilities.length) {
      incompatibilities.push({
        error: IncompatibilityErrorEnum.ZF_WITH_ONE_FREE_TRACK,
        trackElementId,
        concernedTrackElementIds: [ZF.id],
      });
    }
  });

  return incompatibilities;
};

export default commonCheckZFWithOneFreeTrack;
