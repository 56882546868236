import {boolean, date, number, object, string} from 'yup';

import * as YupUtils from "@utils/yup.utils";
import I18n from "@utils/i18n.utils";
import {CreateDispatch, CreateDispatchContent, DispatchTypeEnum} from "@store/dispatches/dispatch.model";

const requiredString = string().required(I18n.t("errors.required"));
const requiredNumber = number().typeError(I18n.t("errors.number")).required(I18n.t("errors.number"));
const requiredDate = date().nullable()
  .required(I18n.t("errors.required"))
  .typeError(I18n.t("errors.date"));
const requiredHour = date().nullable()
  .required(I18n.t("errors.required"))
  .typeError(I18n.t("errors.hour"));


const pnTermination = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      pnNbr: requiredString,
      date: requiredDate,
      hour: requiredHour,
      situation: boolean(),
      observation: requiredString,
      deliveredDispatchNbr: requiredNumber,
      receivedDispatchNbr: requiredNumber
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const pnRecoveryMonitoring = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      pnNbr: requiredString,
      date: requiredDate,
      hour: requiredHour,
      deliveredDispatchNbr: requiredNumber,
      receivedDispatchNbr: requiredNumber
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const pnRequestRemovePnProtection = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      pnNbr: requiredString,
      dateFrom: requiredDate,
      dateFromHour: requiredDate,
      dateTo: requiredDate,
      deliveredDispatchNbr: requiredNumber,
      receivedDispatchNbr: requiredNumber
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const pnArrangementRemovePnProtection = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      pnNbr: requiredString,
      date: requiredDate,
      hour: requiredHour,
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const pnInformingRemovePnProtection = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      pnNbr: requiredString,
      date: requiredDate,
      hour: requiredHour,
      deliveredDispatchNbr: requiredNumber,
      receivedDispatchNbr: requiredNumber
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const pnInformingReopeningPnProtection = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      pnNbr: requiredString,
      date: requiredDate,
      deliveredDispatchNbr: requiredNumber,
      receivedDispatchNbr: requiredNumber
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const pnVerifyReopenPn = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      pnNbr: requiredString,
      date: requiredDate,
      hour: requiredHour,
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const pnConfirmReopenPn = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      pnNbr: requiredString,
      date: requiredDate,
      hour: requiredHour,
      deliveredDispatchNbr: requiredNumber,
      receivedDispatchNbr: requiredNumber
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

const pnReopened = (data: CreateDispatchContent) => {
  try {
    const schema = object().shape({
      from: requiredString,
      to: requiredString,
      pnNbr: requiredString,
      deliveredDispatchNbr: requiredNumber,
      receivedDispatchNbr: requiredNumber
    });
    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

export function pnDispatchValidation(data: CreateDispatch) {

  switch (data.type) {
    case DispatchTypeEnum.PN_TERMINATION:
      return pnTermination(data.dispatch || {});
    case DispatchTypeEnum.PN_RECOVERY_MONITORING:
      return pnRecoveryMonitoring(data.dispatch || {});
    case DispatchTypeEnum.PN_REQUEST_TO_CLOSE_PN:
      return pnRequestRemovePnProtection(data.dispatch || {});
    case DispatchTypeEnum.PN_AUTHORIZATION_TO_CLOSE_PN:
      return pnArrangementRemovePnProtection(data.dispatch || {});
    case DispatchTypeEnum.PN_NOTICE_TO_CLOSE_PN:
      return pnInformingRemovePnProtection(data.dispatch || {});
    case DispatchTypeEnum.PN_REQUEST_TO_REOPEN_PN:
      return pnInformingReopeningPnProtection(data.dispatch || {});
    case DispatchTypeEnum.PN_AUTHORIZATION_TO_REOPEN_PN:
      return pnVerifyReopenPn(data.dispatch || {});
    case DispatchTypeEnum.PN_NOTICE_TO_REOPEN_PN:
      return pnConfirmReopenPn(data.dispatch || {});
    case DispatchTypeEnum.PN_ATTEST_REOPENED:
      return pnReopened(data.dispatch || {});
  }
  return {};
}
