import React from "react";
import {Box} from "@mui/material";

import {DECLIVITY_HEIGHT, DECLIVITY_WIDTH, Pk, projectQuery, TrackElement, TrackElementCategory} from "@store/project";

import {Colors} from "@utils/theme/constants.utils";

import {SchemaParameters} from "@screens/auth/common/schema/Schema.component";

interface DeclivityComponentProps {
  handleElementClick?: (trackElement: TrackElement) => void;
  params: SchemaParameters;
  stepStart: number;
  trackElements: TrackElement[];
}

const DeclivityComponent = (props: DeclivityComponentProps) => {
  const {handleElementClick, params, stepStart, trackElements} = props;

  const {kilometerSpacing, meterSpacing, navigationScale: step, padding, width} = params;

  const getPkPosition = (pk: Pk) => {
    const pkKilometer = pk.kilometer - stepStart !== step ? (pk.kilometer - stepStart) % step : pk.kilometer - stepStart;

    return kilometerSpacing * pkKilometer + padding + meterSpacing * pk.meter;
  }

  return (
    <Box height={`${DECLIVITY_HEIGHT + DECLIVITY_HEIGHT * 0.25 + 2}px`} width="100%">
      <svg height="100%" width={width}>
        {trackElements.map((e) => {
          if (!e.pkStart || !e.pkEnd || (!e.attributes?.declivity && e.attributes?.declivity !== 0)) return null;

          const isAfterStep = Pk.isAfter(e.pkStart, stepStart);
          const isBeforeStep = Pk.isBefore(e.pkEnd, stepStart + step);

          const pkStart = isAfterStep ? getPkPosition(e.pkStart) : 0;
          const pkEnd = isBeforeStep ? getPkPosition(e.pkEnd) : width;

          return (
            <g key={e.id} height="100%" transform="translate(0, 1)">
              {e.attributes.declivity !== 0 ? (
                <polyline
                  points={e.attributes.declivity > 0
                    ? `${isAfterStep ? `${pkStart},0` : ""} ${pkStart},${DECLIVITY_HEIGHT} ${pkEnd},0${isBeforeStep ? ` ${pkEnd},${DECLIVITY_HEIGHT}` : ""}`
                    : `${isAfterStep ? `${pkStart},${DECLIVITY_HEIGHT}` : ""} ${pkStart},0 ${pkEnd},${DECLIVITY_HEIGHT}${isBeforeStep ? ` ${pkEnd},0` : ""}`
                  }
                  fill="none" strokeWidth={DECLIVITY_WIDTH} stroke={Colors.schema.declivity}/>
              ) : (
                <>
                  <line
                    x1={pkStart} x2={pkStart} y1="0" y2={DECLIVITY_HEIGHT}
                    strokeWidth={DECLIVITY_WIDTH} stroke={Colors.schema.declivity}/>
                  <line
                    x1={pkStart} x2={pkEnd}
                    y1={DECLIVITY_HEIGHT / 2} y2={DECLIVITY_HEIGHT / 2}
                    strokeWidth={DECLIVITY_WIDTH} stroke={Colors.schema.declivity}/>
                  <line
                    x1={pkEnd} x2={pkEnd} y1="0" y2={DECLIVITY_HEIGHT}
                    strokeWidth={DECLIVITY_WIDTH} stroke={Colors.schema.declivity}/>
                </>
              )}
              <text
                id="test"
                x={pkStart + (pkEnd - pkStart) / 2}
                y={DECLIVITY_HEIGHT * 1.25}
                fill={Colors.primary}
                fontSize={8}
                fontWeight="600"
                dominantBaseline="bottom"
                textAnchor="middle">
                {Math.abs(e.attributes.declivity)}‰
              </text>
              <rect
                x={pkStart} y={0}
                width={pkEnd - pkStart} height={DECLIVITY_HEIGHT * 1.25}
                onClick={handleElementClick && TrackElementCategory.canBeModified(projectQuery.projectInfo, e.category) ? () => handleElementClick(e) : undefined}
                style={{
                  cursor: handleElementClick && TrackElementCategory.canBeModified(projectQuery.projectInfo, e.category) ? 'pointer' : undefined,
                  opacity: 0,
                }}/>
            </g>
          );
        })}
      </svg>
    </Box>
  );
}

export default DeclivityComponent;