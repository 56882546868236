import React, {useState} from "react";
import {Box, Button, Grid} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import {CreateOrUpdateTrackElement, projectQuery, TrackElement} from "@store/project";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {sessionQuery} from "@store/session";
import {FieldErrors} from "@utils/yup.utils";
import {CreatePNAcquittal} from "@store/dispatches/dispatch.model";
import ModalComponent from "@components/modals/Modal.component";
import ConfirmCodeModal from "@components/confirmCode/ConfirmCode.modal";
import {historyService} from "@store/history";
import {createPNAcquittalValidation} from "./createPNAcquittal.yup";

interface PNAcquittalFormProps {
  handleClose: () => void;
  trackElement: CreateOrUpdateTrackElement;
  TTXWaitingForAcquittal: TrackElement;
}

const CreatePNAcquittalForm = (props: PNAcquittalFormProps) => {
  const {handleClose, trackElement, TTXWaitingForAcquittal} = props;

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [data, setDate] = useState<CreatePNAcquittal>({
    projectId: projectQuery.projectId,
    RELF: `${sessionQuery.getValue().user?.firstname || ""} ${sessionQuery.getValue().user?.lastname || ""}`,
    fromAPN: trackElement.attributes?.APN ?? "",
    pnName: trackElement.attributes?.name ?? "",
    TTxNbr: TTXWaitingForAcquittal.attributes?.name ?? "",
    TTxId: TTXWaitingForAcquittal.id,
    date: new Date(),
    hour: new Date(),
  });
  const [showConfirmCode, setShowConfirmCode] = useState(false);

  const [errors, setErrors] = useState<FieldErrors>({});

  const updateData = (name: string) => (value: any) => setDate((state) => ({...state, [name]: value}));

  const handleValidate = () => {
    const errors = createPNAcquittalValidation(data);
    setErrors(errors);

    if (!Object.keys(errors).length) setShowConfirmCode(true);
  };

  return (
    <>
      <ModalComponent
        handleClose={handleClose}
        title={t("schema.pn.PNAcquittal.title")}
        content={
          <Box mt="15px" width="500px" display="flex" alignItems="flex-start" justifyContent="center">
            <Grid container direction={"row"} columnSpacing={2.5} rowSpacing={1.5}>
              <Grid item xs={6}>
                <TextFieldComponent
                  label={t("schema.pn.PNAcquittal.RELF")}
                  placeholder=" "
                  value={data.RELF || ""}
                  handleChange={updateData("RELF")}
                  error={errors["RELF"]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldComponent
                  label={t("schema.pn.PNAcquittal.attest")}
                  placeholder=" "
                  value={data.TTxNbr || ""}
                  handleChange={updateData("TTxNbr")}
                  error={errors["TTxNbr"]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldComponent
                  label={t("schema.pn.PNAcquittal.atPN")}
                  placeholder=" "
                  value={data.pnName || ""}
                  handleChange={updateData("pnName")}
                  error={errors["pnName"]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldComponent
                  label={t("schema.pn.PNAcquittal.APNConfirm") + ', ' + t("schema.pn.PNAcquittal.APN")}
                  handleChange={updateData("fromAPN")}
                  value={data.fromAPN || ""}
                  error={errors["fromAPN"]}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePickerComponent
                  label={t("schema.pn.PNAcquittal.date")}
                  handleChange={updateData("date")}
                  value={data.date}
                  error={errors["date"]}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePickerComponent
                  label={t("schema.pn.PNAcquittal.time")}
                  handleChange={updateData("hour")}
                  value={data.hour}
                  error={errors["hour"]}
                />
              </Grid>
            </Grid>
          </Box>
        }
        actions={
          <Button variant="contained" color="primary" onClick={handleValidate}>
            {t("global.save")}
          </Button>
        }
      />
      {showConfirmCode && (
        <ConfirmCodeModal
          handleClose={() => setShowConfirmCode(false)}
          handleConfirm={(code) => historyService.createPNAcquittal(code, data, trackElement)}
          handleSuccess={() => {
            enqueueSnackbar(t("schema.pn.PNAcquittal.success"), {variant: "success"});
            handleClose();
          }}
        />
      )}
    </>
  );
};

export default CreatePNAcquittalForm;
