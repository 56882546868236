import {OptionsObject, VariantType} from "notistack";

import I18n from "@utils/i18n.utils";

const errorTexts: { [key: string]: string } = {
  "EMAIL_OR_PASSWORD_INCORRECT": I18n.t("errors.wrongCredentials"),
  "PASSWORD_DONT_MATCH": I18n.t("errors.wrongCredentials"),
  "EMAIL_NOT_FOUND": I18n.t("errors.wrongEmail"),
  "EMAIL_ALREADY_USED": I18n.t("errors.emailExists", {count: 1}),
  "USER_WITH_SAME_EMAIL_ALREADY_EXISTS": I18n.t("errors.emailExists", {count: 1}),
  "USER_WITH_USERNAME_DOES_NOT_EXISTS": I18n.t("errors.userNotFound"),
  "PROJECT_WITH_SAME_TITLE_ALREADY_EXISTS": I18n.t("errors.projectExists"),
  "CUSTOMER_DON'T_HAVE_PROJECT": I18n.t("errors.noProject"),
  "PK_BOUNDS_IS_NOT_IN_PROJECT_BOUNDS": I18n.t("errors.trackElementNotInProject"),
  "PK_START_IS_NOT_IN_TRACK_PK": I18n.t("errors.pkNotInTrackPK"),
  "PK_BOUNDS_IS_NOT_IN_TRACK_PK": I18n.t("errors.pkNotInTrackPK"),
  "WORK_TRAIN_BOUNDS_IS_NOT_IN_PROJECT_MIN/MAX_BOUNDS": I18n.t("errors.pkNotInTrackPK"),
  "PK_END_IS_NOT_IN_TRACK_1_PK_BOUNDS": I18n.t("errors.pkNotInTrackPK"),
  "PK_END_IS_BEFORE_PK_START": I18n.t("errors.pkEndBeforePkStart"),
  "PK_BOUNDS_IS_NOT_IN_ZT_PK": I18n.t("errors.pkBoundsNotInZTPk"),
  "PK_BOUNDS_IS_NOT_IN_CE_PK": I18n.t("errors.pkBoundsNotInCEPk"),
  "CODE_NOT_CORRECT": I18n.t("errors.codeNotCorrect"),
  "PASSWORD_CHANGE_REQUIRED": I18n.t("errors.passwordChangeRequired"),
};

class SnackError extends Error {
  private readonly text: string;
  private readonly options: OptionsObject;
  private readonly error: string;

  constructor(errorMessage: string, variant: VariantType, customMessage?: boolean, ...params: any[]) {
    super(...params);


    this.name = 'SnackError';

    this.text = customMessage ? errorMessage : errorTexts[errorMessage] || I18n.t("errors.serverError");
    this.options = {variant};
    this.error = errorMessage;
  }
}

export default SnackError;
