import {ID} from "@datorama/akita";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {Pk, TrackElement} from "@store/project";

import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";

interface checkNoCCIInOpenPNParams {
  trackElementId?: ID;
  pkStart: Pk;
  pkEnd: Pk;
  PNs: TrackElement[];
}

/* Should not be able to set the PKs of the CCI on an open PN */
const checkNoOpenPNinCCI = (params: checkNoCCIInOpenPNParams): IncompatibilityError[] => {
  const {pkStart, pkEnd, PNs, trackElementId} = params;

  const openPNsInCCI = PNs.filter(
    (t) =>
      (!t.attributes.status ||
        ![
          DispatchTypeEnum.PN_NOTICE_TO_CLOSE_PN,
          DispatchTypeEnum.PN_REQUEST_TO_REOPEN_PN,
          DispatchTypeEnum.PN_AUTHORIZATION_TO_REOPEN_PN,
          DispatchTypeEnum.PN_NOTICE_TO_REOPEN_PN,
        ].includes(t.attributes.status)) &&
      Pk.isPkBetweenPks(t.pkStart, pkStart, pkEnd)
  );

  if (openPNsInCCI.length) {
    return openPNsInCCI.map((PN) => ({
      error: IncompatibilityErrorEnum.NO_CCI_IN_OPEN_PN,
      trackElementId,
      concernedTrackElementIds: [PN.id],
    }));
  }

  return [];
};
export default checkNoOpenPNinCCI;
