import React, {useState} from "react";
import {Box, Button, Grid, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {finalize} from "rxjs";

import {Role, UpdateUser, User, usersService} from "@store/users";

import {FieldErrors} from "@utils/yup.utils";
import {updateUserValidation} from "@screens/auth/OS/users/userDetails/updateUser.yup";

import ModalComponent from "@components/modals/Modal.component";
import TextFieldComponent from "@components/textField/TextField.component";
import SelectComponent from "@components/select/Select.component";
import SelectFileComponent from "@components/selectFile/SelectFile.component";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import {sessionQuery} from "@store/session";

interface UpdateUserFormProps {
  handleCancelEditMode: () => void;
  handleClose: () => void;
  handleSave: (updatedUser: User) => void;
  user: User;
}

const UpdateUserForm = (props: UpdateUserFormProps) => {
  const {handleCancelEditMode, handleClose, handleSave, user} = props;

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<UpdateUser>(user);
  const [errors, setErrors] = useState<FieldErrors>({});

  const updateData = (name: string) => (value: any) => setData({...data, [name]: value});

  const handleValidate = () => {
    const errors = updateUserValidation(data);

    setErrors(errors);

    if (Object.keys(errors).length) return;

    setLoading(true);
    usersService.updateUser(data).pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (u) => {
          enqueueSnackbar(t("users.success.edited"), {variant: "success"});
          handleSave(u);
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  }

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("users.edit.title")}
      description={t("users.edit.description")}
      content={(
        <Stack width="450px" mt="15px" spacing={4}>
          <Box>
            <Grid container columnSpacing={2.5} rowSpacing={1.5}>
              <Grid item xs={12}>
                <TextFieldComponent
                  label={t("users.fields.lastname")}
                  handleChange={updateData("lastname")}
                  value={data.lastname || ""}
                  error={errors.lastname}
                  required/>
              </Grid>
              <Grid item xs={12}>
                <TextFieldComponent
                  label={t("users.fields.firstname")}
                  handleChange={updateData("firstname")}
                  value={data.firstname || ""}
                  error={errors.firstname}
                  required/>
              </Grid>
              <Grid item xs={12}>
                <TextFieldComponent
                  label={t("users.fields.email")}
                  handleChange={updateData("email")}
                  value={data.email || ""}
                  error={errors.email}
                  required/>
              </Grid>
              <Grid item xs={12}>
                <SelectComponent
                  label={t("users.fields.function")}
                  placeholder={t("users.fields.functionPlaceholder")}
                  handleChange={updateData("role")}
                  items={Role.selectItems.filter((role) => sessionQuery.hasSufficientRoleOrAbove(role.data as Role))}
                  value={data.role || ""}
                  error={errors.role}
                  required/>
              </Grid>
              {user.role !== Role.CLIENT && (
                <>
                  <Grid item xs={6}>
                    <SelectFileComponent
                      label={t("users.fields.trainingCertificate")}
                      placeholder={t("users.fields.importFile")}
                      accept=".pdf"
                      handleFileSelected={updateData("formationLocalFile")}
                      currentFile={data.formationFile}
                      file={data.formationLocalFile}
                      error={errors.formationLocalFile}
                      required/>
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerComponent
                      label={t("users.fields.fileExpirationDate")}
                      value={data.formationExpireDate}
                      handleChange={updateData("formationExpireDate")}
                      disablePast
                      error={errors.formationExpireDate}
                      required/>
                  </Grid>
                  <Grid item xs={6}>
                    <SelectFileComponent
                      label={t("users.fields.qualificationTitle")}
                      placeholder={t("users.fields.importFile")}
                      accept=".pdf"
                      handleFileSelected={updateData("habilitationLocalFile")}
                      currentFile={data.habilitationFile}
                      file={data.habilitationLocalFile}
                      error={errors.habilitationLocalFile}
                      required/>
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerComponent
                      label={t("users.fields.fileExpirationDate")}
                      value={data.habilitationExpireDate}
                      handleChange={updateData("habilitationExpireDate")}
                      disablePast
                      error={errors.habilitationExpireDate}
                      required/>
                  </Grid>
                  <Grid item xs={6}>
                    <SelectFileComponent
                      label={t("users.fields.medicalVisit")}
                      placeholder={t("users.fields.importFile")}
                      accept=".pdf"
                      handleFileSelected={updateData("medicalVisitLocalFile")}
                      currentFile={data.medicalVisitFile}
                      file={data.medicalVisitLocalFile}
                      error={errors.medicalVisitFile}
                      required/>
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerComponent
                      label={t("users.fields.fileExpirationDate")}
                      value={data.medicalVisitExpireDate}
                      handleChange={updateData("medicalVisitExpireDate")}
                      disablePast
                      error={errors.medicalVisitExpireDate}
                      required/>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Stack>
      )}
      actions={(
        <>
          <Button variant="outlined" color="primary" disabled={loading} onClick={handleCancelEditMode}>
            {t("global.cancel")}
          </Button>
          <Button variant="contained" color="primary" disabled={loading} onClick={handleValidate}>
            {t("global.save")}
          </Button>
        </>
      )}/>
  );
}

export default UpdateUserForm;
