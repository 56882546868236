import {ID} from "@datorama/akita";
import {catchError, finalize, from, map, Observable, tap} from "rxjs";
import {AxiosError, AxiosResponse} from "axios";

import {ProjectsStore, projectsStore} from "@store/projects/projects.store";
import {ProjectLight} from "@store/projects/projects.model";

import APIAxios, {APIRoutes} from "@api/axios.api";
import SnackError from "@utils/error.utils";

export class ProjectsService {
  constructor(private store: ProjectsStore) {
  }

  addProject = (project: ProjectLight) => this.store.add(project);

  updateProject = (project: ProjectLight) => this.store.update(project.id, project);

  closeProject = (projectId: ID) => this.store.update(projectId, {isPending: false});

  deleteProject = (projectId: ID) => this.store.remove(projectId);

  getProjects = (): Observable<ProjectLight[]> => {
    this.store.setLoading(true);

    return from(APIAxios(APIRoutes.GETProjects()))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError(err.response?.data?.message, "error");
        }),
        map((response: AxiosResponse<ProjectLight[]>) => {
          return response.data;
        }),
        tap((projects) => this.store.upsertMany(projects)),
        finalize(() => this.store.setLoading(false))
      );
  }
}

export const projectsService = new ProjectsService(projectsStore);
