import React from "react";
import {useTranslation} from "react-i18next";
import {FormControlLabel, Grid, InputLabel, Radio, RadioGroup, Stack, Typography} from "@mui/material";

import {CreateOrUpdateTrackElement, projectQuery} from "@store/project";
import {FieldErrors} from "@utils/yup.utils";
import TextFieldComponent from "@components/textField/TextField.component";
import SelectComponent from "@components/select/Select.component";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";

interface WorkTrainFormProps {
  readOnly?: boolean;
  setTrackElement: (trackElement: CreateOrUpdateTrackElement) => void;
  trackElement: CreateOrUpdateTrackElement;
  errors: FieldErrors;
  IncompatibilitiesAcceptation?: (() => JSX.Element) | null;
  isCreation?: boolean;
}

const WorkTrainForm = (props: WorkTrainFormProps) => {
  const {
    errors,
    readOnly,
    setTrackElement,
    trackElement,
    IncompatibilitiesAcceptation,
    isCreation,
  } = props;

  const {t} = useTranslation();

  const updateTrackElement = (name: string) => (value: any) => setTrackElement({...trackElement, [name]: value});
  const updateAttributes = (name: string) => (value: any) => setTrackElement({
    ...trackElement,
    attributes: {...trackElement.attributes, [name]: value},
  });

  return (
    <Stack flex={1} direction="column" justifyContent="center" alignItems="center" height="100%" pt="20px" pb="40px"
           overflow="auto" className="scrollable">
      <Grid container width="90%" spacing={2} mx={"auto"}>
        <Grid item xs={4}>
          <TextFieldComponent
            readOnly={readOnly}
            label={t("schema.workTrain.ATTX")}
            handleChange={updateAttributes("ATTX")}
            value={trackElement.attributes?.ATTX || ""}
            error={errors["attributes.ATTX"]}/>
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent
            readOnly={readOnly}
            label={t("schema.workTrain.TTX")}
            handleChange={updateAttributes("name")}
            value={trackElement.attributes?.name || ""}
            error={errors["attributes.name"]}/>
        </Grid>
        <Grid item xs={4}/>
        <Grid item xs={4}>
          <TextFieldComponent
            readOnly={readOnly}
            label={t("schema.workTrain.PK")}
            handleChange={updateTrackElement("pkStart")}
            value={trackElement.pkStart || ""}
            error={errors.pkStart}/>
        </Grid>
        <Grid item xs={4}>
          <SelectComponent
            readOnly={readOnly}
            label={t("schema.workTrain.onTrack")}
            handleChange={(track) => updateTrackElement("tracks")(track ? [track] : undefined)}
            value={trackElement.tracks?.length ? trackElement.tracks[0].toString() || "" : ""}
            items={projectQuery.projectTracks.map((t) => ({
              label: t.attributes.name || "-",
              value: t.id.toString()
            }))}
            error={errors.tracks}/>
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent
            readOnly={readOnly}
            label={t("schema.workTrain.TTXLength")}
            handleChange={(length) => updateAttributes("TTXLength")(length.replace(",", "."))}
            pattern={new RegExp(/^(\d*|\d+)[,.]?\d*$/)}
            value={trackElement.attributes?.TTXLength || ""}
            error={errors["attributes.TTXLength"]}/>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" mb="-7.5px">
            <InputLabel sx={{ml: '5px', pt: '6px', width: '180px'}}>
              {t("schema.workTrain.authorizedSplitting")}
            </InputLabel>
            <RadioGroup
              row
              value={trackElement.attributes?.authorizedSplitting || false}
              onChange={((evt) => updateAttributes("authorizedSplitting")(evt.target.value === "true"))}>
              <FormControlLabel
                control={<Radio size="small"/>}
                disabled={readOnly}
                value={"true"}
                label={(
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    {t("global.yes")}
                  </Typography>
                )}/>
              <FormControlLabel
                control={<Radio size="small"/>}
                disabled={readOnly}
                value={"false"}
                label={(
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    {t("global.no")}
                  </Typography>
                )}/>
            </RadioGroup>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <InputLabel sx={{ml: '5px', pt: '6px', width: '180px'}}>
              {t("schema.workTrain.plannedImmobilization")}
            </InputLabel>
            <RadioGroup
              row
              value={trackElement.attributes?.plannedImmobilization || false}
              onChange={((evt) => updateAttributes("plannedImmobilization")(evt.target.value === "true"))}>
              <FormControlLabel
                control={<Radio size="small"/>}
                disabled={readOnly}
                value={"true"}
                label={(
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    {t("global.yes")}
                  </Typography>
                )}/>
              <FormControlLabel
                control={<Radio size="small"/>}
                disabled={readOnly}
                value={"false"}
                label={(
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    {t("global.no")}
                  </Typography>
                )}/>
            </RadioGroup>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <DatePickerComponent
            label={t("schema.workTrain.date")}
            disablePast
            handleChange={isCreation ? updateAttributes("dateTime") : undefined}
            value={trackElement.attributes?.dateTime}
            readOnly={!isCreation || readOnly}
            error={errors["attributes.dateTime"]}/>
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            label={t("schema.workTrain.time")}
            handleChange={isCreation ? updateAttributes("dateTimeHour") : undefined}
            readOnly={!isCreation || readOnly}
            value={isCreation ? trackElement.attributes?.dateTimeHour : trackElement.attributes?.dateTime}
            error={errors["attributes.dateTimeHour"]}/>
        </Grid>
      </Grid>
      {IncompatibilitiesAcceptation && (
        <Stack pt="20px">
          <IncompatibilitiesAcceptation/>
        </Stack>
      )}
    </Stack>
  );
}

export default WorkTrainForm;