import {array, date, number, object, string} from 'yup';

import {CreateProject, PK_REGEXP} from "@store/project";

import * as YupUtils from "@utils/yup.utils";
import I18n from "@utils/i18n.utils";

export const updateProjectValidation = (data: CreateProject) => {
  const requiredString = string().required(I18n.t("errors.required"));
  const requiredNumber = number().required(I18n.t("errors.required"));
  const requiredObject = object().required(I18n.t("errors.required"));
  const requiredPK = requiredString.matches(PK_REGEXP, I18n.t("errors.pk"));
  const requiredDate = date().nullable()
    .required(I18n.t("errors.required"))
    .typeError(I18n.t("errors.date"));

  try {
    const schema = object().shape({
      name: requiredString,
      lineId: requiredString,
      from: requiredString,
      to: requiredString,
      startingDate: requiredDate,
      endingDate: requiredDate,
      startingKilometerPoint: requiredPK,
      endingKilometerPoint: requiredPK,
      os_r: requiredObject,
      os_c: requiredObject,
      relfs: array(),
      navigationScale: requiredNumber,
    });

    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}
