import React, {useEffect} from "react";
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {Box, FormControlLabel, Grid, InputLabel, Radio, RadioGroup, Stack, Typography} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import {Pk, PK_INPUT_REGEXP, PK_REGEXP, projectQuery} from "@store/project";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {useTranslation} from "react-i18next";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import NumberTextFieldComponent from "@components/textField/NumberTextField.component";
import SelectComponent from "@components/select/Select.component";
import {useParams} from "react-router-dom";
import {ordersService} from "@store/dispatches/orders.service";
import {
  getADVsOnPath,
  getElementsOnPath,
  getNotPassableZTsOnPath,
  getPNsOnPath,
  getStopSignsOnPath
} from "@screens/auth/common/schema/forms/dispatches/workTrain/getElementsOnDispatch";

const MovementEngagementForm = (props: DispatchFormGlobalProps) => {
  const {projectId} = useParams();
  const {t} = useTranslation();

  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const updateDispatchContent = (name: string) => (value: any) =>
    setDispatch({
      ...dispatch,
      dispatch: {...dispatch.dispatch, [name]: value},
    });

  useEffect(() => {
    ordersService.getDispatchNumber(projectId!).subscribe({
      next: (dispatchNbr) => {
        setDispatch({
          type: DispatchTypeEnum.MOVEMENT_ENGAGEMENT,
          dispatch: {
            nbr: trackElement?.attributes.name,
            ATTX: trackElement?.attributes.ATTX,
            splitting: trackElement?.attributes?.authorizedSplitting ?? false,
            immobilization: trackElement?.attributes?.plannedImmobilization ?? false,
            date: new Date(),
            hour: new Date(),
            engagementRequestDate: new Date(),
            deliveredDispatchNbr: dispatchNbr,
          },
        });
      },
    });
  }, []);

  useEffect(() => {
    if (editable) {
      const trainPkStart = trackElement?.pkStart;
      const stringPkStart = dispatch.dispatch?.pkStart;
      const stringPkEnd = dispatch.dispatch?.pkEnd;

      if (!!trainPkStart && !!stringPkStart && PK_REGEXP.test(stringPkStart) && !!stringPkEnd && PK_REGEXP.test(stringPkEnd) && trackElement?.tracks) {
        const dispatchPkStart = Pk.fromString(stringPkStart)!;
        const dispatchPkEnd = Pk.fromString(stringPkEnd)!;

        const pkStart = Pk.toMeters(trainPkStart) >= Pk.toMeters(dispatchPkEnd) ? dispatchPkEnd : dispatchPkStart;
        const pkEnd = Pk.toMeters(trainPkStart) >= Pk.toMeters(dispatchPkEnd) ? dispatchPkStart : dispatchPkEnd;

        const TTXPath = projectQuery.getTTxPath(pkStart, pkEnd, trackElement.tracks[0]);

        const pnsOnPath = getPNsOnPath(pkStart, pkEnd);
        const instructions = getElementsOnPath(TTXPath);
        const advsOnPath = getADVsOnPath(TTXPath);
        const notPassableZTs = getNotPassableZTsOnPath(TTXPath);
        const stopSignsOnPath = getStopSignsOnPath(TTXPath);

        setDispatch({
          ...dispatch,
          dispatch: {
            ...dispatch.dispatch,
            pn: [...pnsOnPath, ...advsOnPath, ...stopSignsOnPath].join("\n"),
            specificInstructions: [...instructions, ...notPassableZTs].join("\n"),
          },
        });
      } else {
        setDispatch({
          ...dispatch,
          dispatch: {
            ...dispatch.dispatch,
            pn: undefined,
            specificInstructions: undefined,
          },
        });
      }
    }
  }, [editable, dispatch.dispatch?.pkStart, dispatch.dispatch?.pkEnd]);

  return (
    <div>
      <Grid container direction="row" columnSpacing={2.5} rowSpacing={1.5}>
        <Grid item xs={4}>
          <TextFieldComponent
            label={"TTx n°"}
            readOnly={!editable}
            value={dispatch?.dispatch?.nbr || ""}
            handleChange={updateDispatchContent("nbr")}
            error={errors["nbr"]}/>
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent
            label={"Nom ATTX"}
            readOnly={!editable}
            value={dispatch?.dispatch?.ATTX || ""}
            handleChange={updateDispatchContent("ATTX")}
            error={errors["ATTX"]}/>
        </Grid>
        <Grid item xs={4}/>
        <Grid item xs={5}>
          <SelectComponent
            label={"ZONE TRAVAUX n°"}
            readOnly={!editable}
            value={dispatch?.dispatch?.workZoneNbr || ""}
            items={projectQuery.projectZT.map((zt) => ({
              label: zt.attributes.name || "-",
              value: zt.id,
            }))}
            handleChange={(workZoneNbr) => {
              const selectedZT = workZoneNbr ? projectQuery.projectZT.find((e) => e.id === workZoneNbr) : undefined;
              setDispatch({
                ...dispatch,
                dispatch: {
                  ...dispatch.dispatch,
                  workZoneNbr,
                  trainingZoneNbr: undefined,
                  pkStart: Pk.toString(selectedZT?.pkStart),
                  pkEnd: Pk.toString(selectedZT?.pkEnd),
                },
              });
            }}
            error={errors["workZoneNbr"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputLabel sx={{ml: "0px", mt: "30px", textAlign: "center"}}>
            {"OU"}
          </InputLabel>
        </Grid>
        <Grid item xs={5}>
          <SelectComponent
            label={"ZONE DE FORMATION n°"}
            readOnly={!editable}
            value={dispatch?.dispatch?.trainingZoneNbr || ""}
            items={projectQuery.projectTrainingZone.map((tz) => ({
              label: t("schema.zone.types.trainingZone") + ` (${Pk.toString(tz.pkStart)} - ${Pk.toString(tz.pkEnd)})`,
              value: tz.id,
            }))}
            handleChange={(trainingZoneNbr) => {
              const selectedTrainingZone = trainingZoneNbr ? projectQuery.projectTrainingZone.find((e) => e.id === trainingZoneNbr) : undefined;
              setDispatch({
                ...dispatch,
                dispatch: {
                  ...dispatch.dispatch,
                  trainingZoneNbr,
                  workZoneNbr: undefined,
                  pkStart: Pk.toString(selectedTrainingZone?.pkStart),
                  pkEnd: Pk.toString(selectedTrainingZone?.pkEnd),
                },
              });
            }}
            error={errors["trainingZoneNbr"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent
            label={"Du Km"}
            placeholder=" "
            readOnly
            handleChange={updateDispatchContent("pkStart")}
            pattern={PK_INPUT_REGEXP}
            value={dispatch?.dispatch?.pkStart || ""}
          />
        </Grid>

        <Grid item xs={4}>
          <TextFieldComponent
            label={"Au Km"}
            placeholder=" "
            readOnly
            handleChange={updateDispatchContent("pkEnd")}
            pattern={PK_INPUT_REGEXP}
            value={dispatch?.dispatch?.pkEnd || ""}
          />
        </Grid>
        <Grid item xs={4}/>

        <Grid item xs={!editable ? 4 : 12}>
          <TimePickerComponent
            label={"Demande d'engagement à"}
            readOnly={!editable}
            placeholder=" "
            value={dispatch?.dispatch?.engagementRequestDate}
            handleChange={updateDispatchContent("engagementRequestDate")}
            error={errors["engagementRequestDate"]}
          />
        </Grid>
        {!editable && (
          <>
            <Grid item xs={4}>
              <TimePickerComponent
                label={"Autorisation d'engagement reçue à"}
                readOnly={!editable}
                placeholder=" "
                value={dispatch?.dispatch?.engagementAuthorizedDate}
                handleChange={updateDispatchContent("engagementAuthorizedDate")}
                error={errors["engagementAuthorizedDate"]}
              />
            </Grid>
            <Grid item xs={4}>
              <TimePickerComponent
                label={"Heure d'arrivée"}
                readOnly={!editable}
                placeholder=" "
                value={dispatch?.dispatch?.arrivalDate}
                handleChange={updateDispatchContent("arrivalDate")}
                error={errors["arrivalDate"]}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" mb="-7.5px">
            <InputLabel sx={{ml: "5px", pt: "6px", width: "180px"}}>{"Fractionnement autorisé"}</InputLabel>
            <RadioGroup
              row
              value={dispatch.dispatch?.splitting || false}
              onChange={(evt) => updateDispatchContent("splitting")(evt.target.value === "true")}>
              <FormControlLabel
                control={<Radio size="small"/>}
                value={"true"}
                disabled={!editable}
                label={
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    {t("global.yes")}
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Radio size="small"/>}
                value={"false"}
                disabled={!editable}
                label={
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    {t("global.no")}
                  </Typography>
                }
              />
            </RadioGroup>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" flex={1}>
            <Stack direction="row" alignItems="center">
              <InputLabel sx={{ml: "5px", pt: "6px", width: "180px"}}>{"Immobilisation prévue"}</InputLabel>
              <RadioGroup
                row
                value={dispatch.dispatch?.immobilization || false}
                onChange={(evt) => updateDispatchContent("immobilization")(evt.target.value === "true")}>
                <FormControlLabel
                  control={<Radio size="small"/>}
                  value={"true"}
                  disabled={!editable}
                  label={
                    <Typography fontSize={12} fontWeight="600" pt="3px">
                      {t("global.yes")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={<Radio size="small"/>}
                  value={"false"}
                  disabled={!editable}
                  label={
                    <Typography fontSize={12} fontWeight="600" pt="3px">
                      {t("global.no")}
                    </Typography>
                  }
                />
              </RadioGroup>
            </Stack>
            {dispatch?.dispatch?.immobilization && (
              <Stack direction="row" alignItems="center">
                <InputLabel sx={{pt: "6px", width: "50px"}}>{"AU KM"}</InputLabel>
                <Box width="250px">
                  <TextFieldComponent
                    readOnly={!editable}
                    handleChange={updateDispatchContent("pkImmobilization")}
                    pattern={PK_INPUT_REGEXP}
                    value={dispatch?.dispatch?.pkImmobilization || ""}
                    error={errors["pkImmobilization"]}
                  />
                </Box>
              </Stack>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Grid container direction={"row"} columnSpacing={2.5} rowSpacing={1.5}>
            <Grid item xs={8}>
              <TextFieldComponent
                label={"Mesures particulières - Restrictions de circulation"}
                placeholder=" "
                multiline
                readOnly={!editable}
                minRows={3}
                handleChange={updateDispatchContent("specificInstructions")}
                value={dispatch?.dispatch?.specificInstructions || ""}
                error={errors["specificInstructions"]}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldComponent
                label={"PN"}
                placeholder=" "
                multiline
                readOnly={!editable}
                minRows={3}
                handleChange={updateDispatchContent("pn")}
                value={dispatch?.dispatch?.pn || ""}
                error={errors["pn"]}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <DatePickerComponent
            label={"Le"}
            placeholder=" "
            readOnly={!editable}
            value={dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("date")}
            error={errors["date"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            label={"à"}
            placeholder=" "
            readOnly={!editable}
            value={editable ? dispatch?.dispatch?.hour : dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("hour")}
            error={errors["hour"]}
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={t("schema.pn.dispatchDelivered")}
            readOnly={!editable}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            handleChange={updateDispatchContent("deliveredDispatchNbr")}
            value={dispatch?.dispatch?.deliveredDispatchNbr}
            error={errors["deliveredDispatchNbr"]}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={t("schema.pn.dispatchReceived")}
            readOnly={!editable}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            handleChange={updateDispatchContent("receivedDispatchNbr")}
            value={dispatch?.dispatch?.receivedDispatchNbr}
            error={errors["receivedDispatchNbr"]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MovementEngagementForm;
