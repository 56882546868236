import {ID} from "@datorama/akita";
import {SelectComponentItem} from "@components/select/Select.component";

import {User} from "@store/users";
import {Pk} from "@store/project";

import I18n from "@utils/i18n.utils";

export enum ProjectType {
  LEGACY = 'LEGACY',
  NEW = 'NEW',
}

export namespace ProjectType {
  export const selectItems: SelectComponentItem[] = [
    {label: I18n.t("projects.type.legacy"), value: ProjectType.LEGACY},
    {label: I18n.t("projects.type.new"), value: ProjectType.NEW},
  ]
}

export interface ProjectFilters {
  isPending: boolean;
}

export interface ProjectLight {
  id: ID;
  isPending: boolean;
  name: string;
  lineId: string;
  from: string;
  to: string;
  os_c?: User;
  os_r?: User;
  relfs: User[];
  clients: User[];
  startingDate?: Date;
  endingDate?: Date;
  startingKilometerPoint: Pk;
  endingKilometerPoint: Pk;
  navigationScale: number;
  projectType: ProjectType;
}
