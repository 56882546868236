import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {ID} from "@datorama/akita";

interface CheckInZT20mActivityParams {
  trackElementId?: ID;
  pkStart: Pk;
  pkEnd: Pk;
  ZTs: TrackElement[];
}

/* Should not be able to set the PKs of a ZT less than 20 meters of a none encompassed activity */
const checkInZT20mActivity = (params: CheckInZT20mActivityParams): IncompatibilityError[] => {
  const {pkStart, pkEnd, trackElementId, ZTs} = params;

  const ZTLessThan20m = ZTs.filter(
    (t) =>
      !!t.pkEnd &&
      Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd) &&
      Pk.isPkBetweenPks(pkEnd, t.pkStart, t.pkEnd) &&
      (!Pk.isPkBetweenPks(pkStart, Pk.addMeters(t.pkStart, 20), Pk.addMeters(t.pkEnd, -20)) ||
        !Pk.isPkBetweenPks(pkEnd, Pk.addMeters(t.pkStart, 20), Pk.addMeters(t.pkEnd, -20)))
  );

  if (ZTLessThan20m.length) {
    return ZTLessThan20m.map((ZT) => ({
      error: IncompatibilityErrorEnum.IN_ZT_20M_ACTIVITY,
      trackElementId,
      concernedTrackElementIds: [ZT.id],
    }));
  }

  return [];
};

export default checkInZT20mActivity;
