import {createStyles} from "@mui/styles";
import {alpha, Theme} from "@mui/material";

const classes = (theme: Theme) => createStyles({
  container: {
    alignItems: 'center',
    background: `
			linear-gradient(${alpha(theme.palette.primary.main, 0.3)}, ${alpha(theme.palette.primary.main, 0.3)}),
			url(./images/background_login.png)
		`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    top: '15vh',
    width: 500,
  },
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '50px 60px 30px',
    marginTop: '15vh',
    width: 550,
    "& > :first-child": {
      marginBottom: 5,
    },
  },
  custom: {
    paddingTop: 30,
    "& .MuiButton-contained": {
      marginTop: 50,
      minWidth: 150,
    },
  },
});

export default classes;
