import {array, date, object, ref, string} from 'yup';

import {CreateProject, Pk, PK_REGEXP} from "@store/project";

import * as YupUtils from "@utils/yup.utils";
import I18n from "@utils/i18n.utils";

export const step1Validation = (data: CreateProject) => {
  const requiredString = string().required(I18n.t("errors.required"));
  const requiredObject = object().required(I18n.t("errors.required"));
  const requiredPK = requiredString.matches(PK_REGEXP, I18n.t("errors.pk"));
  const requiredDate = date().nullable()
    .required(I18n.t("errors.required"))
    .typeError(I18n.t("errors.date"));

  try {
    const schema = object().shape({
      projectType: requiredString,
      name: requiredString,
      lineId: requiredString,
      from: requiredString,
      to: requiredString,
      startingDate: requiredDate,
      endingDate: requiredDate,
      startingKilometerPoint: requiredPK
        .test("test pkStart < pkEnd (pkStart)", (value, {createError, resolve}) => {
          const startingKilometerPoint = resolve(ref("startingKilometerPoint")) as string;
          const endingKilometerPoint = resolve(ref("endingKilometerPoint")) as string;

          if (!!startingKilometerPoint && !!endingKilometerPoint && !Pk.isPkBetweenPks(Pk.fromString(startingKilometerPoint)!, {
            kilometer: 0,
            meter: 0
          }, Pk.fromString(endingKilometerPoint)!)) {
            return createError({message: I18n.t("errors.pkEndBeforePkStart")});
          }
          return true;
        }),
      endingKilometerPoint: requiredPK
        .test("test pkStart < pkEnd (pkEnd)", (value, {createError, resolve}) => {
          const startingKilometerPoint = resolve(ref("startingKilometerPoint")) as string;
          const endingKilometerPoint = resolve(ref("endingKilometerPoint")) as string;

          if (!!startingKilometerPoint && !!endingKilometerPoint && !Pk.isPkBetweenPks(Pk.fromString(startingKilometerPoint)!, {
            kilometer: 0,
            meter: 0
          }, Pk.fromString(endingKilometerPoint)!)) {
            return createError({message: I18n.t("errors.pkEndBeforePkStart")});
          }
          return true;
        }),
      os_r: requiredObject,
      os_c: requiredObject,
      relfs: array(),
    });

    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err: any) {
    return YupUtils.transformError(err as any);
  }
}
