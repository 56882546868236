import {GridColumns} from "@mui/x-data-grid";
import i18n from "i18next";
import {Tooltip} from "@mui/material";

import {allDispatchesTypes, Dispatch} from "@store/dispatches/dispatch.model";
import {TrackElementType} from "@store/project";

import I18n from "@utils/i18n.utils";

export const dispatchHistoryColumns = (isProjectNew?: boolean) =>
  [
    {
      field: "category",
      headerName: I18n.t("dispatches.table.category"),
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        return params.row.type === "FREE_DISPATCH"
          ? i18n.t("dispatches.eventTypes.free")
          : TrackElementType.getRELFElementLabel(
            params.row.content.trackElement.type
          );
      },
      width: 250,
    },
    {
      field: "nature",
      headerName: I18n.t("dispatches.table.nature"),
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        return params.row.type === "FREE_DISPATCH"
          ? params.row.content.name
          : allDispatchesTypes.find(
          (it) => it.value === params.row.content.type
        )?.label || "";
      },
      renderCell: (params) => (
        <Tooltip title={params.value ?? ""}>
        <span style={{
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center'
        }}>{params.value}</span>
        </Tooltip>
      ),
      flex: 1.5,
    },
    {
      field: "trackElementName",
      headerName: I18n.t("dispatches.table.trackElementName"),
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        return params.row.type === "FREE_DISPATCH"
          ? params.row.content.description
          : params.row.content?.trackElement?.attributes?.name
      },
      renderCell: (params) => (
        <Tooltip title={params.value ?? ""}>
        <span style={{
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center'
        }}>{params.value}</span>
        </Tooltip>
      ),
      flex: 1.5,
    },
    {
      field: "workZone",
      headerName: I18n.t("dispatches.table.zone"),
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        params.row.content?.workZoneNbr?.attributes?.name || params.row.content?.trainingZoneNbr?.attributes?.name,
      width: 150,
    },
    {
      field: "responsible",
      headerName: I18n.t(`dispatches.table.interlocutor`),
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueGetter: (params) => {
        if (params.row.type === "FREE_DISPATCH") return "-";

        if (!params.row.content?.content?.[Dispatch.getInterlocutor(params.row.content.type)]) return "-";

        return `${
          params.row.content.content[Dispatch.getInterlocutor(params.row.content.type)]
        } - ${TrackElementType.getInterlocutorFunctionLabel(
          params.row.content.trackElement.type,
          isProjectNew ?? false
        )}`;
      }
    },
    {
      field: "relf",
      headerName: I18n.t(
        `dispatches.table.${isProjectNew ? "relfNew" : "relf"}`
      ),
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        params.row.user
          ? `${params.row.user.firstname} ${params.row.user.lastname?.toUpperCase()}`
          : "-",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: I18n.t(
        `dispatches.table.${isProjectNew ? "dispatchDateNew" : "dispatchDateOld"}`
      ),
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) =>
        params.value
          ? I18n.t("dispatches.table.day", {
            date: new Date(params.value),
          })
          : "-",
      width: 150,
    },
    {
      field: "actionDate",
      headerName: I18n.t("dispatches.table.actionDate"),
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.content ? params.row.content.content?.[Dispatch.getActionDate(params.row.content.type)] : undefined,
      valueFormatter: (params) =>
        params.value
          ? I18n.t("dispatches.table.day", {
            date: new Date(params.value),
          })
          : "-",
      width: 150,
    },
  ] as GridColumns;
