import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Outlet, useNavigate} from "react-router-dom";

import {projectQuery} from "@store/project";

import {RoutesAuth, RoutesReporting, useRouteMatch} from "@utils/routes.utils";

import TabsComponent from "@components/tabs/Tabs.component";

interface ReportingScreenProps {
  isClientView?: boolean;
}

const ReportingScreen = (props: ReportingScreenProps) => {
  const {isClientView} = props;

  const {t} = useTranslation();
  const navigate = useNavigate();

  const currentPath = useRouteMatch(RoutesReporting.routes);

  return (
    <Stack width="100%" height="100%" pt={!isClientView ? "30px" : undefined} pb={!isClientView ? "20px" : undefined}>
      {!isClientView && (
        <>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack height="48px" justifyContent="center">
              <Typography fontSize={26} fontWeight="900">
                {projectQuery.projectInfo.name || "-"}
              </Typography>
              <Stack direction="row" alignItems="center" spacing="3px">
                <Typography textAlign="center" fontSize={14} fontWeight="700">
                  {t("schema.title", {lineId: projectQuery.projectInfo.lineId})} -
                </Typography>
                <Typography textAlign="center" fontSize={14} fontWeight="400">
                  {projectQuery.projectInfo.from || "-"} / {projectQuery.projectInfo.to || "-"}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Box mt="30px" mb="30px">
            <TabsComponent
              value={currentPath ? RoutesReporting.routes.indexOf(currentPath as RoutesAuth) : 0}
              tabs={[t("reporting.tabs.0"), t("reporting.tabs.1")]}
              handleChange={(value) => navigate(RoutesReporting.routes[value])}/>
          </Box>
        </>
      )}
      <Outlet/>
    </Stack>
  );
};

export default ReportingScreen;
