import React from 'react';
import {withStyles, WithStyles} from '@mui/styles';
import {Card, CardActionArea, IconButton, Stack, Typography} from '@mui/material';

import {User} from "@store/users/users.model";

import style from './User.style';

interface UserCardProps extends WithStyles<typeof style> {
  disabled?: boolean;
  handleClick?: () => void;
  handleDelete?: () => void;
  selected?: boolean;
  user: User;
  variant?: "elevation" | "outlined";
}

const UserCard = (props: UserCardProps) => {
  const {classes, disabled, user, handleClick, handleDelete, selected, variant = "elevation"} = props;

  return (
    <Card
      aria-label="user-card"
      variant={variant}
      aria-selected={selected}
      aria-disabled={disabled}
      component={handleClick && !disabled ? CardActionArea : Card}
      onClick={!disabled ? handleClick : undefined}>
      {handleDelete && (
        <IconButton
          aria-label="remove-user"
          size="small"
          onClick={(evt) => {
            evt.stopPropagation();
            handleDelete?.();
          }}>
          <img alt="" src="/images/delete_red_icon.svg"/>
        </IconButton>
      )}
      <div className={user.isDisabled ? [classes.icon, 'disabled'].join(' ') : classes.icon}>
        <img alt="" src="/images/user_icon.svg"/>
      </div>
      <Stack alignItems="center">
        <Typography
          align="center"
          className={user.isDisabled ? [classes.text, 'disabled'].join(' ') : classes.text}
          fontSize={16}
          fontWeight="700">
          {user.lastname.toUpperCase()}
        </Typography>
        <Typography
          align="center"
          className={user.isDisabled ? [classes.text, 'disabled'].join(' ') : classes.text}
          fontSize={16}
          fontWeight="500">
          {user.firstname}
        </Typography>
      </Stack>
    </Card>
  );
}

export default withStyles(style)(UserCard);
