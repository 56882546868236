import {ID} from "@datorama/akita";

import I18n from "@utils/i18n.utils";

import {SelectComponentItem} from "@components/select/Select.component";
import {Colors} from "@utils/theme/constants.utils";
import {sessionQuery} from "@store/session";
import {Role} from "@store/users";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {ProjectLight, ProjectType} from "@store/projects";

export const PK_REGEXP = new RegExp(/(^\d+\+\d{3}$)/);
export const PK_INPUT_REGEXP = new RegExp(/(^\d+\+\d{1,3}$)|(^\d+\+$)|(^\d+$)/);

export const SCHEMA_SCALE = 0.96;

export const TRAIN_TRACK_SPACING = 70;
export const TOOLTIP_HEIGHT = 40;
export const TOOLTIP_DEFAULT_SPACING = 10;

export const TRAIN_TRACK_HEIGHT = 4;
export const DECLIVITY_HEIGHT = 30;
export const DECLIVITY_WIDTH = 1.6;
export const TRACK_ELEMENT_HEIGHT = 12;

export enum TrackElementCategory {
  TRAIN_TRACK = "TRAIN_TRACK",
  TRACK_DEVICE = "TRACK_DEVICE",
  INSTALLATION = "INSTALLATION",
  SIGNALISATION = "SIGNALISATION",
  ZONE = "ZONE",
  DECLIVITY = "DECLIVITY",
  PRS = "PRS",
  TRAIN_STATION = "TRAIN_STATION",
  CONSTRUCTION = "CONSTRUCTION",

  WORKSITE = "WORKSITE",
  ACTIVITY = "ACTIVITY",
  RESTRICTION = "RESTRICTION",
  WORK_TRAIN = "WORK_TRAIN",
  WORKSITE_MACHINE = "WORKSITE_MACHINE",
  WORK_ZONE = "WORK_ZONE",
  ELEMENTARY_CONSTRUCTION_SITE = "ELEMENTARY_CONSTRUCTION_SITE",

  RAILWAY_MOBILES = "RAILWAY_MOBILES",
  OTHER = "OTHER",
}

export namespace TrackElementCategory {
  export const trackElementCategoriesLegacy: SelectComponentItem[] = [
    {
      label: I18n.t("schema.selectElement.categories.installations"),
      value: TrackElementCategory.INSTALLATION,
    },
    {
      label: I18n.t("schema.selectElement.categories.signalisations"),
      value: TrackElementCategory.SIGNALISATION,
    },
    {
      label: I18n.t("schema.selectElement.categories.zones"),
      value: TrackElementCategory.ZONE,
    },
    {
      label: I18n.t("schema.selectElement.categories.restrictions"),
      value: TrackElementCategory.RESTRICTION,
    },
    {
      label: I18n.t("schema.selectElement.categories.activities"),
      value: TrackElementCategory.ACTIVITY,
    },
    {
      label: I18n.t("schema.selectElement.categories.workTrains"),
      value: TrackElementCategory.WORK_TRAIN,
    },
    {
      label: I18n.t("schema.selectElement.categories.railwayMobiles"),
      value: TrackElementCategory.RAILWAY_MOBILES,
    },
    {
      label: I18n.t("schema.selectElement.categories.others"),
      value: TrackElementCategory.OTHER,
    },
  ];

  export const trackElementCategoriesNew: SelectComponentItem[] = [
    {
      label: I18n.t("schema.selectElement.categories.installations"),
      value: TrackElementCategory.INSTALLATION,
    },
    {
      label: I18n.t("schema.selectElement.categories.signalisations"),
      value: TrackElementCategory.SIGNALISATION,
    },
    {
      label: I18n.t("schema.selectElement.categories.zones"),
      value: TrackElementCategory.ZONE,
    },
    {
      label: I18n.t("schema.selectElement.categories.restrictions"),
      value: TrackElementCategory.RESTRICTION,
    },
    {
      label: I18n.t("schema.selectElement.categories.activities"),
      value: TrackElementCategory.ACTIVITY,
    },
    {
      label: I18n.t("schema.selectElement.categories.railwayMobiles"),
      value: TrackElementCategory.RAILWAY_MOBILES,
    },
    {
      label: I18n.t("schema.selectElement.categories.others"),
      value: TrackElementCategory.OTHER,
    },
  ];

  export const canBeModified = (projectInfo: ProjectLight, category: TrackElementCategory, isSpecialZone = false) => {
    if (sessionQuery.hasAnyOfRoles(Role.CLIENT)) return false;

    if (sessionQuery.hasAnyOfRoles(Role.RELF)) {
      if (projectInfo.projectType === ProjectType.NEW) {
        return [
          TrackElementCategory.ACTIVITY,
          TrackElementCategory.RESTRICTION,
          TrackElementCategory.WORKSITE_MACHINE,
          TrackElementCategory.ELEMENTARY_CONSTRUCTION_SITE,
          TrackElementCategory.TRACK_DEVICE,
        ].includes(category);
      } else if (isSpecialZone) {
        return [
          TrackElementCategory.WORKSITE,
          TrackElementCategory.ACTIVITY,
          TrackElementCategory.RESTRICTION,
          TrackElementCategory.WORK_TRAIN,
          TrackElementCategory.WORKSITE_MACHINE,
          TrackElementCategory.WORK_ZONE,
        ].includes(category);
      }
    }

    return true;
  };

  export const elementToShow = (categoriesSelected: TrackElementCategory[]) => {
    let categoriesToDisplay: TrackElementCategory[] = [];

    if (categoriesSelected.includes(TrackElementCategory.INSTALLATION)) {
      categoriesToDisplay.push(
        TrackElementCategory.INSTALLATION,
        TrackElementCategory.PRS,
        TrackElementCategory.TRAIN_STATION,
        TrackElementCategory.CONSTRUCTION
      );
    }

    if (categoriesSelected.includes(TrackElementCategory.SIGNALISATION)) {
      categoriesToDisplay.push(TrackElementCategory.SIGNALISATION);
    }

    if (categoriesSelected.includes(TrackElementCategory.ZONE)) {
      categoriesToDisplay.push(TrackElementCategory.ZONE, TrackElementCategory.WORK_ZONE, TrackElementCategory.ELEMENTARY_CONSTRUCTION_SITE);
    }

    if (categoriesSelected.includes(TrackElementCategory.RESTRICTION)) {
      categoriesToDisplay.push(TrackElementCategory.RESTRICTION);
    }

    if (categoriesSelected.includes(TrackElementCategory.ACTIVITY)) {
      categoriesToDisplay.push(TrackElementCategory.ACTIVITY);
    }

    if (categoriesSelected.includes(TrackElementCategory.RAILWAY_MOBILES)) {
      categoriesToDisplay.push(TrackElementCategory.WORKSITE_MACHINE, TrackElementCategory.WORK_TRAIN);
    }

    if (categoriesSelected.includes(TrackElementCategory.OTHER)) {
      categoriesToDisplay.push(TrackElementCategory.WORKSITE);
    }

    return categoriesToDisplay;
  };
}

export enum TrackElementType {
  MAIN_TRACK = "MAIN_TRACK",
  SERVICE_TRACK = "SERVICE_TRACK",
  TRACK_OUT_OF_SERVICE = "TRACK_OUT_OF_SERVICE",
  SIDING_TRACK = "SIDING_TRACK",

  TRACK_CHANGE = "TRACK_CHANGE",
  WEDGE = "WEDGE",

  PN = "PN",
  PRA = "PRA",
  PRO = "PRO",
  BUFFER = "BUFFER",
  TUNNEL = "TUNNEL",
  VIADUCT = "VIADUCT",
  STOPPER = "STOPPER",
  INST_OTHER = "INST_OTHER",

  WHISTLE = "WHISTLE",
  SIGN_STOP = "SIGN_STOP",
  SIGN_OTHER = "SIGN_OTHER",
  SIGNALISATION = "SIGNALISATION",

  TRAINING_ZONE = "TRAINING_ZONE",

  DECLIVITY = "DECLIVITY",

  PRS = "PRS",

  TRAIN_STATION = "TRAIN_STATION",

  CONSTRUCTION = "CONSTRUCTION",

  WORKSITE = "WORKSITE",

  OUTSIDE_ZPF = "OUTSIDE_ZPF",
  LIGHTWEIGHT = "LIGHTWEIGHT",
  ACTIVITY = "ACTIVITY",

  WORK_ZONE = "WORK_ZONE",

  ELEMENTARY_CONSTRUCTION_SITE = "ELEMENTARY_CONSTRUCTION_SITE",

  WORK_TRAIN = "WORK_TRAIN",

  WORKSITE_MACHINE = "WORKSITE_MACHINE",

  MAP = "MAP",
  TRAFFIC_BAN = "TRAFFIC_BAN",
  SPEED_LIMIT = "SPEED_LIMIT",
  OBSTACLE = "OBSTACLE",
}

export enum InterlocutorFunctionEnum {
  APN = "APN",
  RE = "RE",
  DRIVER = "DRIVER",
  SWITCHER = "SWITCHER",
  RZT = "RZT",
  ATTX = "ATTX",
  ACGEC_R = "ACGEC_R",
  RZF = "RZF",
}

export enum WorkTrainDirectionEnum {
  RIGHT_TO_LEFT = "RIGHT_TO_LEFT",
  LEFT_TO_RIGHT = "LEFT_TO_RIGHT",
}

export enum WorkTrainStatus {
  WAITING_FOR_ENGAGEMENT = "WAITING_FOR_ENGAGEMENT",
  WAITING_FOR_MOVEMENT_RELEASE = "WAITING_FOR_MOVEMENT_RELEASE",
  WAITING_FOR_ADV_ACQUITTAL = "WAITING_FOR_ADV_ACQUITTAL",
  WAITING_FOR_PN_ACQUITTAL = "WAITING_FOR_PN_ACQUITTAL",
  WAITING_FOR_STOP_SIGN_ACQUITTAL = "WAITING_FOR_STOP_SIGN_ACQUITTAL",
  WAITING_FOR_END_CONSIGNE = "WAITING_FOR_END_CONSIGNE",
  WAITING_FOR_IMMOBILIZATION = "WAITING_FOR_IMMOBILIZATION",
  BLOCKED = "BLOCKED",
}

export namespace TrackElementType {
  export const all: TrackElementType[] = [
    TrackElementType.MAIN_TRACK,
    TrackElementType.SERVICE_TRACK,
    TrackElementType.TRACK_OUT_OF_SERVICE,
    TrackElementType.SIDING_TRACK,

    TrackElementType.TRACK_CHANGE,

    TrackElementType.PN,
    TrackElementType.PRA,
    TrackElementType.PRO,
    TrackElementType.BUFFER,
    TrackElementType.TUNNEL,
    TrackElementType.VIADUCT,
    TrackElementType.STOPPER,
    TrackElementType.INST_OTHER,

    TrackElementType.WHISTLE,
    TrackElementType.SIGN_STOP,
    TrackElementType.SIGN_OTHER,

    TrackElementType.TRAINING_ZONE,

    TrackElementType.DECLIVITY,

    TrackElementType.PRS,

    TrackElementType.TRAIN_STATION,

    TrackElementType.WORKSITE,

    TrackElementType.OUTSIDE_ZPF,
    TrackElementType.LIGHTWEIGHT,

    TrackElementType.WORK_ZONE,

    TrackElementType.WORK_TRAIN,

    TrackElementType.WORKSITE_MACHINE,

    TrackElementType.MAP,
    TrackElementType.TRAFFIC_BAN,
    TrackElementType.SPEED_LIMIT,
    TrackElementType.OBSTACLE,
  ];

  export const allNew: TrackElementType[] = [
    TrackElementType.MAIN_TRACK,
    TrackElementType.SERVICE_TRACK,
    TrackElementType.TRACK_OUT_OF_SERVICE,
    TrackElementType.SIDING_TRACK,

    TrackElementType.TRACK_CHANGE,

    TrackElementType.PN,
    TrackElementType.PRA,
    TrackElementType.PRO,
    TrackElementType.BUFFER,
    TrackElementType.TUNNEL,
    TrackElementType.VIADUCT,
    TrackElementType.STOPPER,
    TrackElementType.INST_OTHER,

    TrackElementType.SIGNALISATION,

    TrackElementType.DECLIVITY,

    TrackElementType.TRAIN_STATION,

    TrackElementType.CONSTRUCTION,

    TrackElementType.LIGHTWEIGHT,
    TrackElementType.ACTIVITY,

    TrackElementType.ELEMENTARY_CONSTRUCTION_SITE,

    TrackElementType.WORKSITE_MACHINE,

    TrackElementType.MAP,
    TrackElementType.TRAFFIC_BAN,
    TrackElementType.OBSTACLE,
  ];

  export const getRELFElementLabel = (trackElementType: TrackElementType) => {
    switch (trackElementType) {
      case TrackElementType.MAIN_TRACK:
        return I18n.t("schema.trainTrack.types.mainTrack");
      case TrackElementType.SERVICE_TRACK:
        return I18n.t("schema.trainTrack.types.serviceTrack");
      case TrackElementType.TRACK_OUT_OF_SERVICE:
        return I18n.t("schema.trainTrack.types.trackOutOfService");
      case TrackElementType.SIDING_TRACK:
        return I18n.t("schema.trainTrack.types.sidingTrack");

      case TrackElementType.TRACK_CHANGE:
        return I18n.t("schema.trackDevice.types.trackChange");
      case TrackElementType.WEDGE:
        return I18n.t("schema.trackDevice.types.wedge");

      case TrackElementType.PN:
        return I18n.t("schema.installation.types.PN");
      case TrackElementType.PRA:
        return I18n.t("schema.installation.types.PRA");
      case TrackElementType.PRO:
        return I18n.t("schema.installation.types.PRO");
      case TrackElementType.BUFFER:
        return I18n.t("schema.installation.types.buffer");
      case TrackElementType.TUNNEL:
        return I18n.t("schema.installation.types.tunnel");
      case TrackElementType.VIADUCT:
        return I18n.t("schema.installation.types.viaduct");
      case TrackElementType.STOPPER:
        return I18n.t("schema.installation.types.stopper");
      case TrackElementType.INST_OTHER:
        return I18n.t("schema.installation.types.other");

      case TrackElementType.WHISTLE:
        return I18n.t("schema.signalisation.types.whistle");
      case TrackElementType.SIGN_STOP:
        return I18n.t("schema.signalisation.types.stop");
      case TrackElementType.SIGN_OTHER:
        return I18n.t("schema.signalisation.types.other");
      case TrackElementType.SIGNALISATION:
        return I18n.t("schema.signalisation.types.signalisation");

      case TrackElementType.TRAINING_ZONE:
        return I18n.t("schema.zone.types.trainingZone");

      case TrackElementType.DECLIVITY:
        return I18n.t("schema.declivity.declivity");

      case TrackElementType.PRS:
        return I18n.t("schema.PRS.types.PRS");

      case TrackElementType.TRAIN_STATION:
        return I18n.t("schema.trainStation.type");

      case TrackElementType.CONSTRUCTION:
        return I18n.t("schema.construction.type");

      case TrackElementType.WORKSITE:
        return I18n.t("schema.worksite.type");

      case TrackElementType.OUTSIDE_ZPF:
        return I18n.t("schema.activity.natures.outsideZPF");
      case TrackElementType.LIGHTWEIGHT:
        return I18n.t("schema.activity.natures.lightweight");
      case TrackElementType.ACTIVITY:
        return I18n.t("schema.activity.natures.activity");

      case TrackElementType.MAP:
        return I18n.t("schema.restriction.natures.MAP");
      case TrackElementType.TRAFFIC_BAN:
        return I18n.t("schema.restriction.natures.trafficBan");
      case TrackElementType.SPEED_LIMIT:
        return I18n.t("schema.restriction.natures.speedLimit");
      case TrackElementType.OBSTACLE:
        return I18n.t("schema.restriction.natures.obstacle");

      case TrackElementType.WORK_TRAIN:
        return I18n.t("schema.workTrain.title");

      case TrackElementType.WORKSITE_MACHINE:
        return I18n.t("schema.worksiteMachine.title.default");

      case TrackElementType.WORK_ZONE:
        return I18n.t("schema.workArea.title");

      case TrackElementType.ELEMENTARY_CONSTRUCTION_SITE:
        return I18n.t("schema.CE.title.default");
      default:
        return "-";
    }
  };

  export const getInterlocutorFunctionLabel = (trackElementType: TrackElementType, isProjectTypeNew: boolean) => {
    switch (trackElementType) {
      case TrackElementType.ELEMENTARY_CONSTRUCTION_SITE:
        return I18n.t(`dispatches.table.interlocutorFunctions.${InterlocutorFunctionEnum.RE}`);

      case TrackElementType.WORKSITE_MACHINE:
        return I18n.t(
          `dispatches.table.interlocutorFunctions.${isProjectTypeNew ? InterlocutorFunctionEnum.DRIVER : InterlocutorFunctionEnum.ACGEC_R}`
        );

      case TrackElementType.TRACK_CHANGE:
        return I18n.t(`dispatches.table.interlocutorFunctions.${InterlocutorFunctionEnum.SWITCHER}`);

      case TrackElementType.WORK_ZONE:
        return I18n.t(`dispatches.table.interlocutorFunctions.${InterlocutorFunctionEnum.RZT}`);

      case TrackElementType.WORK_TRAIN:
        return I18n.t(`dispatches.table.interlocutorFunctions.${InterlocutorFunctionEnum.ATTX}`);

      case TrackElementType.PN:
        return I18n.t(`dispatches.table.interlocutorFunctions.${InterlocutorFunctionEnum.APN}`);

      case TrackElementType.TRAINING_ZONE:
        return I18n.t(`dispatches.table.interlocutorFunctions.${InterlocutorFunctionEnum.RZF}`);

      default:
        return "-";
    }
  };

  export const trainTrackTypes: SelectComponentItem[] = [
    {
      label: I18n.t("schema.trainTrack.types.mainTrack"),
      value: TrackElementType.MAIN_TRACK,
    },
    {
      label: I18n.t("schema.trainTrack.types.serviceTrack"),
      value: TrackElementType.SERVICE_TRACK,
    },
    {
      label: I18n.t("schema.trainTrack.types.trackOutOfService"),
      value: TrackElementType.TRACK_OUT_OF_SERVICE,
    },
    {
      label: I18n.t("schema.trainTrack.types.sidingTrack"),
      value: TrackElementType.SIDING_TRACK,
    },
  ];

  export const trackDeviceTypes: SelectComponentItem[] = [
    {
      label: I18n.t("schema.trackDevice.types.trackChange"),
      value: TrackElementType.TRACK_CHANGE,
    },
    // {label: I18n.t("schema.trackDevice.typ…es.wedge"), value: TrackElementType.WEDGE},
  ];

  export const installationTypes: SelectComponentItem[] = [
    {
      label: I18n.t("schema.installation.types.PN"),
      value: TrackElementType.PN,
    },
    {
      label: I18n.t("schema.installation.types.PRA"),
      value: TrackElementType.PRA,
    },
    {
      label: I18n.t("schema.installation.types.PRO"),
      value: TrackElementType.PRO,
    },
    {
      label: I18n.t("schema.installation.types.buffer"),
      value: TrackElementType.BUFFER,
    },
    {
      label: I18n.t("schema.installation.types.tunnel"),
      value: TrackElementType.TUNNEL,
    },
    {
      label: I18n.t("schema.installation.types.viaduct"),
      value: TrackElementType.VIADUCT,
    },
    {
      label: I18n.t("schema.installation.types.stopper"),
      value: TrackElementType.STOPPER,
    },
    {
      label: I18n.t("schema.installation.types.other"),
      value: TrackElementType.INST_OTHER,
    },
  ];

  export const signalisationTypes: SelectComponentItem[] = [
    {
      label: I18n.t("schema.signalisation.types.whistle"),
      value: TrackElementType.WHISTLE,
    },
    {
      label: I18n.t("schema.signalisation.types.stop"),
      value: TrackElementType.SIGN_STOP,
    },
    {
      label: I18n.t("schema.signalisation.types.other"),
      value: TrackElementType.SIGN_OTHER,
    },
  ];

  export const signalisationTypesNew: SelectComponentItem[] = [
    {
      label: I18n.t("schema.signalisation.types.signalisation"),
      value: TrackElementType.SIGNALISATION,
    },
  ];

  export const zoneTypes: SelectComponentItem[] = [
    {
      label: I18n.t("schema.zone.types.trainingZone"),
      value: TrackElementType.TRAINING_ZONE,
    },
  ];

  export const activities: SelectComponentItem[] = [
    {
      label: I18n.t("schema.activity.natures.outsideZPF"),
      value: TrackElementType.OUTSIDE_ZPF,
    },
    {
      label: I18n.t("schema.activity.natures.lightweight"),
      value: TrackElementType.LIGHTWEIGHT,
    },
    {
      label: I18n.t("schema.activity.natures.activity"),
      value: TrackElementType.ACTIVITY,
    },
  ];

  export const activitiesNew: SelectComponentItem[] = [
    {
      label: I18n.t("schema.activity.natures.lightweight"),
      value: TrackElementType.LIGHTWEIGHT,
    },
    {
      label: I18n.t("schema.activity.natures.activity"),
      value: TrackElementType.ACTIVITY,
    },
  ];

  export const restrictions: SelectComponentItem[] = [
    {
      label: I18n.t("schema.restriction.natures.MAP"),
      value: TrackElementType.MAP,
    },
    {
      label: I18n.t("schema.restriction.natures.trafficBan"),
      value: TrackElementType.TRAFFIC_BAN,
    },
    {
      label: I18n.t("schema.restriction.natures.speedLimit"),
      value: TrackElementType.SPEED_LIMIT,
    },
    {
      label: I18n.t("schema.restriction.natures.obstacle"),
      value: TrackElementType.OBSTACLE,
    },
  ];

  export const restrictionsNew: SelectComponentItem[] = [
    {
      label: I18n.t("schema.restriction.natures.MAP"),
      value: TrackElementType.MAP,
    },
    {
      label: I18n.t("schema.restriction.natures.trafficBan"),
      value: TrackElementType.TRAFFIC_BAN,
    },
    {
      label: I18n.t("schema.restriction.natures.obstacle"),
      value: TrackElementType.OBSTACLE,
    },
  ];

  export const shortcuts: SelectComponentItem[] = [
    {
      label: I18n.t("schema.workArea.title"),
      value: TrackElementType.WORK_ZONE,
    },
    {
      label: I18n.t("schema.workTrain.title"),
      value: TrackElementType.WORK_TRAIN,
    },
    {
      label: I18n.t("schema.zone.types.trainingZone"),
      value: TrackElementType.TRAINING_ZONE,
    },
    {
      label: I18n.t("schema.installation.types.PN"),
      value: TrackElementType.PN,
    },
  ];

  export const shortcutsNew: SelectComponentItem[] = [
    {
      label: I18n.t("schema.workArea.typeNew.title"),
      value: TrackElementType.WORK_ZONE,
    },
  ];

  export const getColor = (trackElement: TrackElement) => {
    let color = "#FFFFFF";

    switch (trackElement.type) {
      case TrackElementType.WEDGE:
        return Colors.schema.elements.wedge;
      case TrackElementType.TRAIN_STATION:
        return Colors.schema.elements.trainStation;
      case TrackElementType.CONSTRUCTION:
        return Colors.schema.elements.construction;
      case TrackElementType.PN:
        return getPnStateColor(trackElement.attributes?.status);
      case TrackElementType.PRS:
        return Colors.schema.elements.PRS;
      case TrackElementType.PRA:
        return Colors.schema.elements.PRA;
      case TrackElementType.PRO:
        return Colors.schema.elements.PRO;
      case TrackElementType.BUFFER:
        return Colors.schema.elements.buffer;
      case TrackElementType.TUNNEL:
        return Colors.schema.elements.tunnel;
      case TrackElementType.VIADUCT:
        return Colors.schema.elements.viaduct;
      case TrackElementType.STOPPER:
        return Colors.schema.elements.stopper;
      case TrackElementType.INST_OTHER:
        return Colors.schema.elements.instOther;
      case TrackElementType.SIGN_STOP:
        return Colors.black;
      case TrackElementType.WHISTLE:
      case TrackElementType.SIGN_OTHER:
        return Colors.schema.elements.whistle;
      case TrackElementType.SIGNALISATION:
        return Colors.schema.elements.whistle;
      case TrackElementType.TRAINING_ZONE:
        return Colors.schema.elements.trainingZone;
      case TrackElementType.WORKSITE:
        return Colors.schema.elements.worksite;
      case TrackElementType.OUTSIDE_ZPF:
      case TrackElementType.LIGHTWEIGHT:
      case TrackElementType.ACTIVITY:
        return Colors.schema.elements.activity;
      case TrackElementType.ELEMENTARY_CONSTRUCTION_SITE:
        return Colors.schema.elements.trainingZone;
      case TrackElementType.WORK_ZONE:
        color = Colors.schema.elements.workZone.default;

        switch (trackElement.attributes?.step) {
          case WorkZoneStep.DEMANDE:
            color = Colors.schema.elements.workZone.request;
            break;
          case WorkZoneStep.AUTORISATION:
            color = Colors.schema.elements.workZone.authorization;
            break;
          case WorkZoneStep.AVIS:
            color = Colors.schema.elements.workZone.notice;
            break;
        }

        if (trackElement.attributes?.status === WorkZoneStatus.SOMMEIL && trackElement.attributes?.step !== WorkZoneStep.DEMANDE) {
          color = Colors.schema.elements.workZone.default;
        }

        return color;
      case TrackElementType.WORK_TRAIN:
        color = Colors.schema.elements.workTrain.schema;

        switch (trackElement.attributes?.status) {
          case WorkTrainStatus.WAITING_FOR_ENGAGEMENT:
          case WorkTrainStatus.WAITING_FOR_MOVEMENT_RELEASE:
          case WorkTrainStatus.WAITING_FOR_PN_ACQUITTAL:
          case WorkTrainStatus.WAITING_FOR_ADV_ACQUITTAL:
          case WorkTrainStatus.WAITING_FOR_END_CONSIGNE:
          case WorkTrainStatus.WAITING_FOR_IMMOBILIZATION:
            color = Colors.schema.elements.workTrain.waiting;
            break;
        }

        return color;
      case TrackElementType.SPEED_LIMIT:
      case TrackElementType.MAP:
      case TrackElementType.OBSTACLE:
      case TrackElementType.TRAFFIC_BAN:
        return Colors.schema.elements.restriction.color1;
      case TrackElementType.WORKSITE_MACHINE:
        if (!!trackElement.attributes?.immobile) return Colors.schema.elements.workTrain.waiting;
        return Colors.schema.elements.worksiteMachine;
      case TrackElementType.TRACK_CHANGE:
        return Colors.schema.track;
      default:
        return "#FFFFFF";
    }
  };

  export const getPnStateColor = (pnStatus: DispatchTypeEnum) => {
    switch (pnStatus) {
      case DispatchTypeEnum.PN_REQUEST_TO_CLOSE_PN:
        return Colors.schema.elements.pnState.darkGreen;
      case DispatchTypeEnum.PN_AUTHORIZATION_TO_CLOSE_PN:
        return Colors.schema.elements.pnState.yellow;
      case DispatchTypeEnum.PN_NOTICE_TO_CLOSE_PN:
        return Colors.schema.elements.pnState.orange;
      case DispatchTypeEnum.PN_AUTHORIZATION_TO_REOPEN_PN:
        return Colors.schema.elements.pnState.darkGreen;
      case DispatchTypeEnum.PN_REQUEST_TO_REOPEN_PN:
        return Colors.schema.elements.pnState.yellow;
      case DispatchTypeEnum.PN_NOTICE_TO_REOPEN_PN:
        return Colors.schema.elements.pnState.paleGreen;
      case DispatchTypeEnum.PN_ATTEST_REOPENED:
      default:
        return Colors.schema.elements.PN;
    }
  };

  export const getIcon = (type: TrackElementType, trackElement?: TrackElement) => {
    switch (type) {
      case TrackElementType.WEDGE:
        return "/images/cleat_icon.svg";
      case TrackElementType.TRAIN_STATION:
        return "/images/station_icon.svg";
      case TrackElementType.CONSTRUCTION:
        return "/images/ouvrage_icon.svg";
      case TrackElementType.PN:
        return "/images/PN_icon.svg";
      case TrackElementType.PRS:
        return "/images/PRS_icon.svg";
      case TrackElementType.PRA:
        return "/images/PRa_icon.svg";
      case TrackElementType.PRO:
        return "/images/PRo_icon.svg";
      case TrackElementType.INST_OTHER:
        return "/images/letters_ins.svg";
      case TrackElementType.BUFFER:
        return "/images/zone_tampon_icon.svg";
      case TrackElementType.TUNNEL:
        return "/images/tunnel_icon.svg";
      case TrackElementType.VIADUCT:
        return "/images/tunnel_icon.svg";
      case TrackElementType.STOPPER:
        return "/images/knocker_icon.svg";
      case TrackElementType.WHISTLE:
        return "/images/S_icon.svg";
      case TrackElementType.SIGN_STOP:
        return "/images/letter_a.svg";
      case TrackElementType.SIGNALISATION:
      case TrackElementType.SIGN_OTHER:
        return "/images/letters_sig.svg";
      case TrackElementType.TRAINING_ZONE:
        return "/images/training_area_icon.svg";
      case TrackElementType.WORKSITE:
        return "/images/worksite_icon.svg";
      case TrackElementType.OUTSIDE_ZPF:
      case TrackElementType.LIGHTWEIGHT:
      case TrackElementType.ACTIVITY:
        return "/images/activity_icon.svg";
      case TrackElementType.WORK_ZONE:
      case TrackElementType.ELEMENTARY_CONSTRUCTION_SITE:
        return "/images/works_area_icon.svg";
      case TrackElementType.SPEED_LIMIT:
      case TrackElementType.MAP:
      case TrackElementType.OBSTACLE:
      case TrackElementType.TRAFFIC_BAN:
        return "/images/restriction_icon.svg";
      case TrackElementType.WORK_TRAIN:
        return trackElement?.attributes?.direction === WorkTrainDirectionEnum.RIGHT_TO_LEFT
          ? "/images/train_icon_flipped.svg"
          : "/images/train_icon.svg";
      case TrackElementType.WORKSITE_MACHINE:
        return "/images/gear_icon.svg";
      case TrackElementType.TRACK_CHANGE:
        return "/images/track_device_icon.svg";
      case TrackElementType.MAIN_TRACK:
      case TrackElementType.SERVICE_TRACK:
      case TrackElementType.SIDING_TRACK:
      case TrackElementType.TRACK_OUT_OF_SERVICE:
        return "/images/way_icon.svg";
      default:
        return "";
    }
  };
}

export enum PNType {
  SAL2 = "SAL2",
  SAL4 = "SAL4",
  UNGUARDED = "UNGUARDED",
  PEDESTRIAN = "PEDESTRIAN",
}

export namespace PNType {
  export const label = (type: PNType) => {
    switch (type) {
      case PNType.SAL2:
        return I18n.t("schema.installation.PNTypes.SAL2");
      case PNType.SAL4:
        return I18n.t("schema.installation.PNTypes.SAL4");
      case PNType.UNGUARDED:
        return I18n.t("schema.installation.PNTypes.unguarded");
      case PNType.PEDESTRIAN:
        return I18n.t("schema.installation.PNTypes.pedestrian");
      default:
        return "";
    }
  };

  export const pnTypes: SelectComponentItem[] = [
    {label: PNType.label(PNType.SAL2), value: PNType.SAL2},
    {label: PNType.label(PNType.SAL4), value: PNType.SAL4},
    {label: PNType.label(PNType.UNGUARDED), value: PNType.UNGUARDED},
    {label: PNType.label(PNType.PEDESTRIAN), value: PNType.PEDESTRIAN},
  ];
}

export enum WorkZoneStep {
  DEMANDE = "DEMANDE",
  AUTORISATION = "AUTORISATION",
  AVIS = "AVIS",
}

export enum WorkZoneStatus {
  OUVERTE = "OUVERTE",
  FERMEE = "FERMEE",
  SOMMEIL = "SOMMEIL",
}

export interface CreateOrUpdateTrackElement extends Partial<Omit<TrackElement, "pkStart" | "pkEnd">> {
  pkStart?: string;
  pkEnd?: string;
}

export namespace CreateOrUpdateTrackElement {
  export const fromTrackElement = (trackElement: TrackElement): CreateOrUpdateTrackElement => ({
    ...trackElement,
    attributes: {
      ...trackElement.attributes,
      dateTimeHour: trackElement.attributes.dateTime,
    },
    pkStart: Pk.toString(trackElement.pkStart),
    pkEnd: Pk.toString(trackElement.pkEnd),
  });
}

export interface TrackCursor {
  track: ID;
  pk: Pk;
}

export interface Pk {
  kilometer: number;
  meter: number;
}

export namespace Pk {
  export const toString = (pk?: Pk): string | undefined => {
    if (!pk) return undefined;

    if (pk.meter < 10) return `${pk.kilometer}+00${pk.meter}`;

    if (pk.meter < 100) return `${pk.kilometer}+0${pk.meter}`;

    return `${pk.kilometer}+${pk.meter}`;
  };

  export const fromString = (pk?: string): Pk | undefined => {
    if (!pk) return undefined;

    const splitPk = pk.split("+");

    return {kilometer: +splitPk[0], meter: +splitPk[1]};
  };

  export const toMeters = (pk: Pk) => pk.kilometer * 1000 + pk.meter;

  export const isPkBetweenPks = (pk: Pk, pkStart: Pk, pkEnd: Pk) => {
    return (
      (pk.kilometer > pkStart.kilometer || (pk.kilometer === pkStart.kilometer && pk.meter >= pkStart.meter)) &&
      (pk.kilometer < pkEnd.kilometer || (pk.kilometer === pkEnd.kilometer && pk.meter <= pkEnd.meter))
    );
  };
  export const isPkBetweenPksWithoutEnd = (pk: Pk, pkStart: Pk, pkEnd: Pk) => {
    return (
      (pk.kilometer > pkStart.kilometer || (pk.kilometer === pkStart.kilometer && pk.meter >= pkStart.meter)) &&
      (pk.kilometer < pkEnd.kilometer || (pk.kilometer === pkEnd.kilometer && pk.meter < pkEnd.meter))
    );
  };

  export const isBefore = (pk: Pk, kilometer: number) => pk.kilometer < kilometer;

  export const isBeforePk = (pk: Pk, otherPk: Pk) =>
    pk.kilometer < otherPk.kilometer || (pk.kilometer === otherPk.kilometer && pk.meter < otherPk.meter);

  export const isAfter = (pk: Pk, kilometer: number) => pk.kilometer > kilometer || (pk.kilometer === kilometer && pk.meter > 0);

  export const isAfterPk = (pk: Pk, otherPk: Pk) =>
    pk.kilometer > otherPk.kilometer || (pk.kilometer === otherPk.kilometer && pk.meter > otherPk.meter);

  export const isBeforeOrEqual = (pk: Pk, kilometer: number) => Pk.isBefore(pk, kilometer) || (pk.kilometer === kilometer && pk.meter === 0);

  export const isBeforeOrEqualPk = (pk: Pk, otherPk: Pk) =>
    Pk.isBeforePk(pk, otherPk) || (pk.kilometer === otherPk.kilometer && pk.meter === otherPk.meter);

  export const isAfterOrEqual = (pk: Pk, kilometer: number) => Pk.isAfter(pk, kilometer) || pk.kilometer === kilometer;

  export const isAfterOrEqualPk = (pk: Pk, otherPk: Pk) =>
    Pk.isAfterPk(pk, otherPk) || (pk.kilometer === otherPk.kilometer && pk.meter === otherPk.meter);

  export const addMeters = (pk: Pk, meters: number): Pk => {
    const totalMeters = Pk.toMeters(pk) + meters;

    const newKilometer = Math.floor(totalMeters / 1000);
    const newMeter = totalMeters % 1000;

    return {kilometer: newKilometer, meter: newMeter};
  };

  export const max = (pk1: Pk, pk2: Pk): Pk => {
    if (Pk.isAfterPk(pk1, pk2)) return pk1;

    return pk2;
  };

  export const min = (pk1: Pk, pk2: Pk): Pk => {
    if (Pk.isBeforePk(pk1, pk2)) return pk1;

    return pk2;
  };
}

export interface TrackElement {
  id: ID;
  category: TrackElementCategory;
  type: TrackElementType;
  project: ID;
  tracks: ID[];
  pkStart: Pk;
  pkEnd?: Pk;
  attributes: any;
  deletedAt?: Date;
}

export interface GetZtLastIdDto {
  nextId: string;
}
