import {Pk, TrackElement, TrackElementType} from '@store/project';
import {IncompatibilityError} from '@utils/incompatibilities/incompatibility.utils';
import checkNoTTXBiggerThanZF from '@utils/incompatibilities/TTX/NO_TTX_BIGGER_THAN_ZF/noTTXBiggerThanZF';
import checkNoTTXBiggerThanZT from '@utils/incompatibilities/TTX/NO_TTX_BIGGER_THAN_ZT/noTTXBiggerThanZT';
import checkTTXOnlyAtStartOrEndOfTrack
  from '@utils/incompatibilities/TTX/TTX_ONLY_AT_START_OR_END_OF_TRACK/TTXOnlyAtStartOrEndOfTrack';
import {ID} from '@datorama/akita';
import {SplittingPart, TTxDispatchType} from '@store/dispatches/dispatch.model';
import checkNoTTxOnSamePath from '@utils/incompatibilities/TTX/NO_TTX_ON_SAME_PATH/noTTxOnSamePath';
import checkNoMovementInSleepingZT
  from '@utils/incompatibilities/TTX/NO_MOVEMENT_IN_SLEEPING_ZT/noMovementInSleepingZT';
import checkNoMovementWithoutDispatch
  from '@utils/incompatibilities/TTX/NO_MOVEMENT_WITHOUT_DISPATCH/noMovementWithoutDispatch';
import checkNoImmobilizedTTXOutsideZFOrZT
  from '@utils/incompatibilities/TTX/NO_IMMOBILIZED_TTX_OUTSIDE_ZF_OR_ZT/noImmobilizedTTXOutsideZFOrZT';
import checkNoTTxOnNotPassableZT from '@utils/incompatibilities/TTX/NO_TTX_ON_NOT_PASSABLE_ZT/noTTxOnNotPassableZT';
import checkNoBlockingElementOnTTxPath
  from '@utils/incompatibilities/TTX/NO_BLOCKING_ELEMENT_ON_TTX_PATH/noBlockingElementOnTTxPath';
import checkTTXPathIncomplete from '@utils/incompatibilities/TTX/TTX_PATH_INCOMPLETE/ttxPathIncomplete';
import checkNoSplittingTTXOutsideZFOrZT
  from '@utils/incompatibilities/TTX/NO_SPLITTING_OUTSIDE_ZT_OR_ZF/noSplittingOutsideZTOrZF';
import checkNoDifferentZTOrZFOnPostSplitting
  from './NO_DIFFERENT_ZT_OR_ZF_ON_POST_SPLITTING/noDifferentZTOrZFOnPostSplitting';
import checkNoElementOnSidelanesOnSplitting from '@utils/incompatibilities/TTX/NO_ELEMENT_ON_SIDELANES_ON_SPLITTING/noElementOnSidelanesOnSplitting';

interface GetS9A3TTXIncParams {
  fromDispatch?: boolean;
  dispatchType?: TTxDispatchType;
  trackElementId?: ID;
  trackIds?: ID[];
  pkStart?: Pk;
  length?: number;
  finalPk?: Pk;
  finalTrack?: ID;
  TTx?: TrackElement[];
  CCIs?: TrackElement[];
  ZTs?: TrackElement[];
  ZFs?: TrackElement[];
  WMs?: TrackElement[];
  restrictions?: TrackElement[];
  tracks?: TrackElement[];
  ADVs?: TrackElement[];
  splittingParts?: SplittingPart[];
  activities?: TrackElement[];
}

export const getS9A3TTXInc = (params: GetS9A3TTXIncParams): IncompatibilityError[] => {
  const {
    fromDispatch,
    dispatchType,
    trackElementId,
    trackIds,
    pkStart,
    length = 0,
    finalPk,
    finalTrack,
    CCIs = [],
    ZTs = [],
    ZFs = [],
    WMs = [],
    restrictions = [],
    tracks = [],
    TTx = [],
    ADVs = [],
    splittingParts = [],
    activities = [],
  } = params;

  const incompatibilities: IncompatibilityError[] = [];

  if (!!splittingParts) {
    incompatibilities.push(...checkNoDifferentZTOrZFOnPostSplitting({
      fromDispatch,
      dispatchType,
      trackElementId,
      ZFs,
      ZTs,
      splittingParts
    }));
  }

  if (!pkStart || !length || trackIds?.length !== 1) return incompatibilities;

  incompatibilities.push(...checkNoTTXBiggerThanZF({trackElementId, pkStart, length, ZFs}));
  incompatibilities.push(...checkNoTTXBiggerThanZT({trackElementId, pkStart, length, ZTs}));
  incompatibilities.push(...checkTTXOnlyAtStartOrEndOfTrack({trackElementId, trackIds, pkStart, length, tracks}));
  incompatibilities.push(...checkNoBlockingElementOnTTxPath({
    fromDispatch,
    dispatchType,
    trackElementId,
    trackIds,
    pkStart,
    finalPk,
    otherElements: [
      ...CCIs,
      ...TTx.filter((t) => !trackElementId || t.id !== trackElementId),
      ...WMs,
      ...restrictions.filter((r) => ![TrackElementType.MAP, TrackElementType.SPEED_LIMIT].includes(r.type)),
      ...ZTs.filter((ZT) => !!ZT.attributes?.isNotPassable),
    ],
  }));
  incompatibilities.push(...checkNoTTxOnSamePath({
    trackIds,
    trackElementId,
    pkStart,
    finalPk,
    TTx,
    fromDispatch,
    dispatchType
  }))
  incompatibilities.push(...checkNoMovementInSleepingZT({trackElementId, pkStart, length, ZTs, TTx}));
  incompatibilities.push(...checkNoMovementWithoutDispatch({
    fromDispatch,
    dispatchType,
    trackElementId,
    pkStart,
    length,
    ZTs,
    ZFs,
    TTx
  }));
  incompatibilities.push(...checkNoImmobilizedTTXOutsideZFOrZT({
    fromDispatch,
    dispatchType,
    trackElementId,
    pkStart,
    length,
    ZFs,
    ZTs
  }));
  incompatibilities.push(...checkNoTTxOnNotPassableZT({
    fromDispatch,
    trackElementId,
    pkStart,
    length,
    ZTs
  }))
  incompatibilities.push(...checkTTXPathIncomplete({
    fromDispatch,
    dispatchType,
    trackElementId,
    trackIds,
    pkStart,
    finalPk,
    finalTrack,
    ADVs,
  }));
  incompatibilities.push(...checkNoSplittingTTXOutsideZFOrZT({
    fromDispatch,
    dispatchType,
    trackElementId,
    pkStart,
    length,
    ZFs,
    ZTs
  }));
  incompatibilities.push(...checkNoElementOnSidelanesOnSplitting({
    fromDispatch,
    dispatchType,
    trackElementId,
    trackIds,
    pkStart,
    length,
    tracks,
    otherElements: [
      ...CCIs,
      ...TTx.filter((t) => !trackElementId || t.id !== trackElementId),
      ...WMs,
      ...restrictions.filter((r) => [TrackElementType.OBSTACLE, TrackElementType.TRAFFIC_BAN].includes(r.type)),
      ...activities,
    ],
  }));

  return incompatibilities;
}
