import dayjsUtils from '@utils/dayjs.utils';

export const mergeDateAndHour = (dateToMerge?: Date, hourToMerge?: Date) => {
  let date = dateToMerge ? dayjsUtils(dateToMerge) : undefined;
  const hour = hourToMerge ? dayjsUtils(hourToMerge) : undefined;

  if (date && hour) {
    date = date.set("hours", hour.get("hours")).set("minutes", hour.get("minutes")).set("seconds", 0).set("milliseconds", 0);
  }

  return date?.toDate();
};