import {EntityState, EntityStore, ID, StoreConfig} from "@datorama/akita";
import {Order} from "@store/dispatches/dispatch.model";

export interface OrdersState extends EntityState<Order, ID> {
}

@StoreConfig({name: "order", resettable: true})
export class OrdersStore extends EntityStore<OrdersState, Order> {
}

export const ordersStore = new OrdersStore();
