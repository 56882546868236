import React from "react";
import {Box, Card, IconButton, Stack} from "@mui/material";
import {NavLink} from "react-router-dom";

import {RoutesAuth, useRouteMatch} from "@utils/routes.utils";

interface LeftBarComponentProps {
  handleClose?: () => void;
  routes?: RoutesAuth[];
}

const LeftBarComponent = (props: LeftBarComponentProps) => {
  const {handleClose, routes = RoutesAuth.leftBarRoutes()} = props;

  const currentTab = useRouteMatch(routes);

  return (
    <Card variant="left-bar">
      {!!handleClose ? (
        <Box position="absolute" top={33}>
          <IconButton onClick={handleClose} sx={{p: 0}}>
            <img alt="close" style={{height: 48, width: 48}} src="/images/cross_icon.svg"/>
          </IconButton>
        </Box>
      ) : (
        <Stack alignItems="center" spacing={0.75} position="absolute" top={30}>
          <img alt="nge logo" src="/images/logo_menu.svg"/>
        </Stack>
      )}
      <Stack spacing={3}>
        {routes.map((route) => (
          <IconButton
            key={route}
            component={NavLink}
            to={route}>
            <img
              alt={`${route} ${currentTab === route ? "active" : ""}`}
              src={RoutesAuth.routeIcon(route, currentTab === route)}/>
          </IconButton>
        ))}
      </Stack>
    </Card>
  );
}

export default LeftBarComponent;
