import {OrderLogic} from "@store/dispatches/orderLogic/orderLogicMap";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {TrackElement, WorkZoneStatus, WorkZoneStep} from '@store/project';

export const getZTOrderLogic = (trackElement: TrackElement): OrderLogic[] => {
  return [
    {
      dispatchType: DispatchTypeEnum.WORK_ZONE_REQUEST_TO_OPEN_A_WORK_AREA,
      dependsOf: [
        {
          condition: !trackElement.attributes?.step || !trackElement.attributes?.status,
          dispatchType: null,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_OPEN_A_WORK_AREA,
      dependsOf: [
        {
          condition: true,
          dispatchType: DispatchTypeEnum.WORK_ZONE_REQUEST_TO_OPEN_A_WORK_AREA,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.WORK_ZONE_NOTICE_OF_OPENING_OF_A_WORK_AREA,
      dependsOf: [
        {
          condition: true,
          dispatchType: DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_OPEN_A_WORK_AREA,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA,
      dependsOf: [
        {
          condition: trackElement.attributes?.step === WorkZoneStep.AVIS && trackElement.attributes?.status === WorkZoneStatus.OUVERTE,
          dispatchType: null,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.WORK_ZONE_NOTICE_OF_OPENING_OF_A_WORK_AREA,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_RESTARTING_A_WORK_AREA_IN_STANDBY,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.WORK_ZONE_NOTICE_OF_MODIFICATION_OF_THE_WORK_AREA,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_MODIFY_THE_WORK_AREA,
      dependsOf: [
        {
          condition: true,
          dispatchType: DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.WORK_ZONE_NOTICE_OF_MODIFICATION_OF_THE_WORK_AREA,
      dependsOf: [
        {
          condition: true,
          dispatchType: DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_MODIFY_THE_WORK_AREA,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.WORK_ZONE_CLOSURE_OF_A_WORK_AREA,
      dependsOf: [
        {
          condition: trackElement.attributes?.step === WorkZoneStep.AVIS && trackElement.attributes?.status === WorkZoneStatus.OUVERTE,
          dispatchType: null,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.WORK_ZONE_NOTICE_OF_OPENING_OF_A_WORK_AREA,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_RESTARTING_A_WORK_AREA_IN_STANDBY,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.WORK_ZONE_NOTICE_OF_MODIFICATION_OF_THE_WORK_AREA,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.WORK_ZONE_SET_STANDBY_WORK_AREA,
      dependsOf: [
        {
          condition: trackElement.attributes?.step === WorkZoneStep.AVIS && trackElement.attributes?.status === WorkZoneStatus.OUVERTE,
          dispatchType: null,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.WORK_ZONE_NOTICE_OF_OPENING_OF_A_WORK_AREA,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_RESTARTING_A_WORK_AREA_IN_STANDBY,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.WORK_ZONE_NOTICE_OF_MODIFICATION_OF_THE_WORK_AREA,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_RESTARTING_A_WORK_AREA_IN_STANDBY,
      dependsOf: [
        {
          condition: trackElement.attributes?.step === WorkZoneStep.AVIS && trackElement.attributes?.status === WorkZoneStatus.SOMMEIL,
          dispatchType: null,
        },
        {
          condition: true,
          dispatchType: DispatchTypeEnum.WORK_ZONE_SET_STANDBY_WORK_AREA,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_RESTARTING_A_WORK_AREA_IN_STANDBY,
      dependsOf: [
        {
          condition: true,
          dispatchType: DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_RESTARTING_A_WORK_AREA_IN_STANDBY,
        },
      ],
    },
  ];
}
