import React from "react";
import {Dialog, DialogContent, IconButton} from "@mui/material";

import SchemaComponent from "@screens/auth/common/schema/Schema.component";

interface FullScreenSchemaModalProps {
  handleClose: () => void;
}

const FullScreenSchemaModal = (props: FullScreenSchemaModalProps) => {
  const {handleClose} = props;

  return (
    <Dialog open={true} fullScreen scroll="paper" onClose={handleClose} aria-label="schema-fullscreen">
      <IconButton aria-label="close" onClick={handleClose}>
        <img alt="" src="/images/cross_icon.svg"/>
      </IconButton>
      <DialogContent>
        <SchemaComponent isFullScreen/>
      </DialogContent>
    </Dialog>
  );
}

export default FullScreenSchemaModal;