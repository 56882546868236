import {alpha, Components} from "@mui/material";

import {Colors} from "@utils/theme/constants.utils";

const menu: Components = {
  MuiMenu: {
    styleOverrides: {
      list: {
        maxHeight: '50vh',
        padding: 0,
        width: '100%',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        color: Colors.text,
        fontSize: 14,
        fontWeight: 400,
        height: 38,
        paddingTop: 10,
        "&.Mui-selected": {
          backgroundColor: alpha(Colors.primary, 0.2),
          fontWeight: 700,
        },
      },
    },
  },
};

export default menu;
