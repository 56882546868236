import React from "react";
import {Box, Button, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {createPortal} from "react-dom";
import {Draggable, DraggableProvided, DraggableStateSnapshot, Droppable} from "react-beautiful-dnd";
import {useTranslation} from "react-i18next";

import {Pk, TrackElement} from "@store/project";

import {Colors} from "@utils/theme/constants.utils";

import {TracksDistribution} from "@screens/auth/common/schema/components/tracksDistribution/TracksDistribution.modal";

const portal: HTMLElement = document.createElement('div');
document.body.appendChild(portal);

const style = {
  dropZone: {
    backgroundColor: Colors.primary,
    borderRadius: 6,
    height: 50,
    padding: '7.5px 10px',
    width: '100%',
  },
  row: {
    marginBottom: 15,
  },
  track: {
    alignItems: 'center',
    backgroundColor: Colors.primary,
    border: `2px solid ${Colors.white}`,
    borderRadius: 6,
    color: Colors.white,
    display: 'flex',
    fontSize: 14,
    fontWeight: 500,
    height: 30,
    marginRight: 10,
    padding: '3px 5px 0px',
  },
};

interface TrackComponentProps {
  item: TrackElement;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
}

const TrackComponent = (props: TrackComponentProps) => {
  const {item, provided, snapshot} = props;

  const usePortal = snapshot.isDragging;

  const child = (
    <Box
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{...style.track, ...provided.draggableProps.style}}>
      {item.attributes?.name || "-"}
    </Box>
  );

  if (!usePortal) return child;

  return createPortal(child, portal);
}

interface RowComponentProps {
  deleteRow: (rowIndex: number) => void;
  disabled?: boolean;
  row: TrackElement[];
  rowIndex: number;
}

const RowComponent = (props: RowComponentProps) => {
  const {deleteRow, disabled, row, rowIndex} = props;

  const {t} = useTranslation();

  let conflicts: [TrackElement, TrackElement][] = [];

  row.forEach((track) => {
    const conflictTracks = row.filter((t) => t.id !== track.id && t.pkEnd
      && Pk.isPkBetweenPksWithoutEnd(track.pkStart, t.pkStart, t.pkEnd)
    );

    conflicts = conflicts.concat(conflictTracks.map((c) => [track, c]));
  });

  return (
    <Draggable draggableId={`tracks-row ${rowIndex}`} index={rowIndex} isDragDisabled={disabled}>
      {(provided) => (
        <Stack
          direction="row" alignItems="center" width="100%" spacing={1.5}
          ref={provided.innerRef} {...provided.draggableProps}
          style={{...style.row, ...provided.draggableProps.style}}>
          <Stack
            direction="row" alignItems="center" justifyContent="stretch" spacing={1.5} flex={1}
            {...provided.dragHandleProps}>
            <Box width="34px" height="34px" display="flex" alignItems="center" justifyContent="center">
              <img alt="drag row" src="/images/handle_icon.svg"/>
            </Box>
            <Droppable droppableId={`tracks-row ${rowIndex}`} direction="horizontal">
              {(dropProvided) => (
                <Stack
                  alignItems="start" direction="row"
                  ref={dropProvided.innerRef} {...dropProvided.droppableProps} style={style.dropZone}>
                  {row.map((track, trackPosition) => (
                    <Draggable key={track.id} draggableId={track.id.toString()} index={trackPosition}
                               isDragDisabled={disabled}>
                      {(dragProvided, dragSnapshot) => (
                        <TrackComponent item={track} provided={dragProvided} snapshot={dragSnapshot}/>
                      )}
                    </Draggable>
                  ))}
                  {dropProvided.placeholder}
                </Stack>
              )}
            </Droppable>
          </Stack>
          <Box width="34px" height="34px">
            {!!conflicts.length && (
              <Tooltip
                aria-label="incompatibility"
                arrow
                title={(
                  <Stack spacing={0.5}>
                    <Typography whiteSpace="pre-line" fontSize={16} fontWeight="700">
                      {t("schema.distribution.conflicts")}
                    </Typography>
                    <Typography whiteSpace="pre-line" fontSize={14} fontWeight="400">
                      {"● " + conflicts.map((c) => `${c[0].attributes?.name || "-"} - ${c[1].attributes?.name || "-"}`).join("\n● ")}
                    </Typography>
                  </Stack>
                )}>
                <img alt="track conflict" src="/images/incompatibility_red.svg"/>
              </Tooltip>
            )}
            {!row.length && (
              <IconButton onClick={() => deleteRow(rowIndex)}>
                <img alt="delete row" src="/images/delete_red_icon.svg"/>
              </IconButton>
            )}
          </Box>
        </Stack>
      )}
    </Draggable>
  );
}

interface DraggableTracksComponentProps {
  addRow: () => void;
  deleteRow: (rowIndex: number) => void;
  disabled?: boolean;
  tracksDistribution: TracksDistribution;
}

const DraggableTracksComponent = (props: DraggableTracksComponentProps) => {
  const {addRow, deleteRow, disabled, tracksDistribution} = props;

  const {t} = useTranslation();

  return (
    <Stack alignItems="center" spacing={4}>
      <Stack alignItems="center" spacing={1.5} width="100%">
        <Typography fontSize={14} fontWeight="700">{t("schema.distribution.unplacedTracks")}</Typography>
        <Stack direction="row" alignItems="center" width="100%" spacing={1.5}>
          <Box width="34px" height="34px"/>
          <Droppable droppableId="unplacedTracks" direction="horizontal" isDropDisabled>
            {(dropProvided) => (
              <Stack
                alignItems="start" direction="row"
                ref={dropProvided.innerRef} {...dropProvided.droppableProps} style={style.dropZone}>
                {tracksDistribution.unplacedTracks.map((track, trackPosition) => (
                  <Draggable
                    key={track.id} draggableId={track.id.toString()} index={trackPosition}
                    isDragDisabled={disabled}>
                    {(dragProvided, dragSnapshot) => (
                      <TrackComponent item={track} provided={dragProvided} snapshot={dragSnapshot}/>
                    )}
                  </Draggable>
                ))}
                {dropProvided.placeholder}
              </Stack>
            )}
          </Droppable>
          <Box width="34px" height="34px"/>
        </Stack>
      </Stack>
      <Stack alignItems="center" spacing={1.5} width="100%">
        <Typography fontSize={14} fontWeight="700">{t("schema.distribution.placedTracks")}</Typography>
        <Droppable droppableId="rows" type="ROWS">
          {(dropProvided) => (
            <Stack alignItems="center" width="100%" ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
              {tracksDistribution.distribution.map((row, index) => (
                <RowComponent
                  key={`tracks-row ${index}`}
                  row={row}
                  deleteRow={deleteRow}
                  rowIndex={index}
                  disabled={disabled}/>
              ))}
              {dropProvided.placeholder}
            </Stack>
          )}
        </Droppable>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          startIcon={<img alt="" src="/images/add_icon_white.svg"/>}
          onClick={addRow}>
          {t("schema.distribution.addRow")}
        </Button>
      </Stack>
    </Stack>
  );
}

export default DraggableTracksComponent;