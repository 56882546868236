import {Pk, TrackElement, TrackElementType} from "@store/project";
import {ZFDispatchType} from "@store/dispatches/dispatch.model";
import {IncompatibilityError} from "@utils/incompatibilities/incompatibility.utils";
import checkOutZF20mPN from "@utils/incompatibilities/ZF/OUT_ZF_20M_PN/outZF20mPN";
import checkInZF20mPN from "@utils/incompatibilities/ZF/IN_ZF_20M_PN/inZF20mPN";
import checkNoZFCutsZT from "@utils/incompatibilities/ZF/NO_ZF_CUTS_ZT/noZFCutsZT";
import checkZFWithOneFreeTrack from "@utils/incompatibilities/ZF/ZF_WITH_ONE_FREE_TRACK/ZFWithOneFreeTrack";
import {ID} from "@datorama/akita";

interface GetS9A3ZFIncParams {
  trackElementId?: ID;
  fromDispatch?: boolean;
  pkStart?: Pk;
  pkEnd?: Pk;
  status?: ZFDispatchType;
  PNs?: TrackElement[];
  ZTs?: TrackElement[];
  CCIs?: TrackElement[];
  TTx?: TrackElement[];
  WMs?: TrackElement[];
  ADVs?: TrackElement[];
  restrictions?: TrackElement[];
  tracks?: TrackElement[];
}

export const getS9A3ZFInc = (params: GetS9A3ZFIncParams): IncompatibilityError[] => {
  const {
    fromDispatch,
    pkStart,
    pkEnd,
    PNs = [],
    status,
    ZTs = [],
    tracks = [],
    CCIs = [],
    TTx = [],
    WMs = [],
    ADVs = [],
    restrictions = [],
    trackElementId,
  } = params;

  const incompatibilities: IncompatibilityError[] = [];

  if (!pkStart || !pkEnd) return [];

  incompatibilities.push(...checkOutZF20mPN({fromDispatch, pkStart, pkEnd, PNs, status, trackElementId}));

  incompatibilities.push(...checkInZF20mPN({fromDispatch, pkStart, pkEnd, PNs, status, trackElementId}));

  incompatibilities.push(...checkNoZFCutsZT({fromDispatch, pkStart, pkEnd, status, ZTs, trackElementId}));

  incompatibilities.push(
    ...checkZFWithOneFreeTrack({
      fromDispatch,
      trackElementId,
      pkStart,
      pkEnd,
      status,
      otherElements: [
        ...CCIs,
        ...TTx,
        ...WMs,
        ...restrictions.filter((r) => [TrackElementType.OBSTACLE, TrackElementType.TRAFFIC_BAN].includes(r.type)),
      ],
      tracks,
      ADVs,
    })
  );

  return incompatibilities;
};
