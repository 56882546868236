import React, {useEffect} from 'react';
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {Grid} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import NumberTextFieldComponent from "@components/textField/NumberTextField.component";
import {Pk, PK_INPUT_REGEXP} from "@store/project";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {sessionQuery} from "@store/session";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import {ordersService} from "@store/dispatches/orders.service";
import {useParams} from "react-router-dom";

const MovementResetForm = (props: DispatchFormGlobalProps) => {
  const {projectId} = useParams();

  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const updateDispatchContent = (name: string) => (value: any) => setDispatch({
    ...dispatch,
    dispatch: {...dispatch.dispatch, [name]: value}
  });

  useEffect(() => {
    if (trackElement) {
      ordersService.getDispatchNumber(projectId!).subscribe({
        next: (dispatchNbr) => {
          setDispatch({
            type: DispatchTypeEnum.MOVEMENT_RESET,
            dispatch: {
              from: trackElement?.attributes.ATTX,
              to: `${sessionQuery.getValue().user?.firstname || ''} ${sessionQuery.getValue().user?.lastname || ''}`,
              ttxNbr: trackElement?.attributes?.name,
              pkStart: Pk.toString(trackElement?.pkStart),
              resetDate: new Date(),
              date: new Date(),
              hour: new Date(),
              deliveredDispatchNbr: dispatchNbr,
            }
          });
        },
      });
    }
  }, [])

  return (
    <div>
      <Grid container direction={"row"} columnSpacing={2.5} rowSpacing={1.5}>
        <Grid item xs={4}>
          <TextFieldComponent
            label={"M./Mme ATTx"}
            placeholder=" "
            readOnly={!editable}
            value={dispatch?.dispatch?.from || ''}
            handleChange={updateDispatchContent("from")}
            error={errors["from"]}/>
        </Grid>

        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={"du TTX n°"}
            placeholder=" "
            readOnly={!editable}
            value={dispatch?.dispatch?.ttxNbr}
            handleChange={updateDispatchContent("ttxNbr")}
            error={errors["ttxNbr"]}/>
        </Grid>

        <Grid item xs={4}>
          <TextFieldComponent
            label={"À M./Mme RZT"}
            placeholder=" "
            readOnly={!editable}
            value={dispatch?.dispatch?.to || ''}
            handleChange={updateDispatchContent("to")}
            error={errors["to"]}/>
        </Grid>


        <Grid item xs={6}>
          <TextFieldComponent
            label={"Je vous confirme la remise en place du bornage au Km"}
            placeholder=" "
            readOnly={!editable}
            handleChange={updateDispatchContent("pkStart")}
            pattern={PK_INPUT_REGEXP}
            value={dispatch?.dispatch?.pkStart || ""}
            error={errors["pkStart"]}/>
        </Grid>

        <Grid item xs={4}>
          <TimePickerComponent
            label={"à"}
            placeholder=" "
            readOnly={!editable}
            value={dispatch?.dispatch?.resetDate}
            handleChange={updateDispatchContent("resetDate")}
            error={errors["resetDate"]}
          />
        </Grid>

        <Grid item xs={4}>
          <DatePickerComponent
            label={"Le"}
            placeholder=" "
            readOnly={!editable}
            value={dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("date")}
            error={errors["date"]}/>
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            label={"à"}
            placeholder=" "
            readOnly={!editable}
            value={editable ? dispatch?.dispatch?.hour : dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("hour")}
            error={errors["hour"]}/>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={"N° de dépêche délivré"}
            placeholder=" "
            readOnly={!editable}
            handleChange={updateDispatchContent("deliveredDispatchNbr")}
            value={dispatch?.dispatch?.deliveredDispatchNbr}
            error={errors["deliveredDispatchNbr"]}/>
        </Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={"N° de dépêche reçu"}
            placeholder=" "
            readOnly={!editable}
            handleChange={updateDispatchContent("receivedDispatchNbr")}
            value={dispatch?.dispatch?.receivedDispatchNbr}
            error={errors["receivedDispatchNbr"]}/>
        </Grid>

      </Grid>
    </div>
  );
};

export default MovementResetForm;
