import {Query} from "@datorama/akita";

import {DispatchFiltersState, dispatchFiltersStore, DispatchFiltersStore,} from "./filters.store";

export class DispatchFiltersQuery extends Query<DispatchFiltersState> {
  filters$ = this.select(["search", "dispatchCategory", "dispatchNature", "trackElementHistoryCategory", "trackElementHistoryType", "startDate", "endDate", "startHour", "endHour"]);

  constructor(protected store: DispatchFiltersStore) {
    super(store);
  }

  get filters() {
    return this.getValue();
  }
}

export const dispatchFiltersQuery = new DispatchFiltersQuery(
  dispatchFiltersStore
);
