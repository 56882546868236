import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {DispatchTypeEnum, ZTDispatchType} from "@store/dispatches/dispatch.model";
import {ID} from "@datorama/akita";

interface CheckImmobilizedTTxInClosingZTParams {
  trackElementId?: ID;
  fromDispatch?: boolean;
  pkStart: Pk;
  pkEnd: Pk;
  TTx: TrackElement[];
  ZFs: TrackElement[];
  status?: ZTDispatchType;
}

/* Should not be able to close the ZT if it contains an immobilized TTx and the TTx is not in a ZF */
const checkImmobilizedTTxInClosingZT = (params: CheckImmobilizedTTxInClosingZTParams): IncompatibilityError[] => {
  const {fromDispatch, pkStart, pkEnd, TTx, ZFs, status, trackElementId} = params;

  if (!fromDispatch || status === DispatchTypeEnum.WORK_ZONE_CLOSURE_OF_A_WORK_AREA) {
    const immobilizedTTxInClosingZTAndNotInZF = TTx.filter(
      (t) =>
        t.attributes.isImmobilized &&
        Pk.isPkBetweenPks(t.pkStart, pkStart, pkEnd) &&
        !ZFs.some((ZF) => !!ZF.pkEnd && Pk.isPkBetweenPks(t.pkStart, ZF.pkStart, ZF.pkEnd))
    );

    if (!!immobilizedTTxInClosingZTAndNotInZF.length) {
      return [
        {
          error: IncompatibilityErrorEnum.IMMOBILIZED_TTX_IN_CLOSING_ZT,
          trackElementId,
          concernedTrackElementIds: immobilizedTTxInClosingZTAndNotInZF.map((TTx) => TTx.id),
        },
      ];
    }
  }

  return [];
};

export default checkImmobilizedTTxInClosingZT;
