import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import ModalComponent from "@components/modals/Modal.component";
import React, {useState} from "react";
import {Box, Button, Grid} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import {FieldErrors} from "@utils/yup.utils";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {CreateOrUpdateTrackElement, projectQuery} from "@store/project";
import {CreateRZTHandover} from "@store/dispatches/dispatch.model";
import {sessionQuery} from '@store/session';
import {createRZTHandoverValidation} from '@screens/auth/common/dispatch/createRZTHandover/createRZTHandover.yup';
import ConfirmCodeModal from '@components/confirmCode/ConfirmCode.modal';
import {historyService} from '@store/history';

interface RZTHandoverFormProps {
  handleClose: () => void;
  trackElement: CreateOrUpdateTrackElement;
}

export const CreateRZTHandoverModal = (props: RZTHandoverFormProps) => {
  const {handleClose, trackElement} = props;

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [data, setDate] = useState<CreateRZTHandover>({
    projectId: projectQuery.projectId,
    RELF: `${sessionQuery.getValue().user?.firstname || ''} ${sessionQuery.getValue().user?.lastname || ''}`,
    fromRZT: trackElement.attributes?.RZT,
    date: new Date(),
    hour: new Date(),
  });
  const [showConfirmCode, setShowConfirmCode] = useState(false);

  const [errors, setErrors] = useState<FieldErrors>({});

  const updateData = (name: string) => (value: any) => setDate((state) => ({...state, [name]: value}));

  const handleValidate = () => {
    const errors = createRZTHandoverValidation(data);
    setErrors(errors);

    if (!Object.keys(errors).length) setShowConfirmCode(true);
  };

  return (
    <>
      <ModalComponent
        handleClose={handleClose}
        title={t("schema.workArea.RZTHandover.title")}
        content={
          <Box mt="15px" width="500px" display="flex" alignItems="flex-start" justifyContent="center">
            <Grid container columnSpacing={2.5} rowSpacing={1.5}>
              <Grid item xs={6}>
                <TextFieldComponent
                  label={t('schema.workArea.RZTHandover.RELFConfirm')}
                  placeholder=" "
                  handleChange={updateData('RELF')}
                  value={data.RELF || ''}
                  error={errors['RELF']}/>
              </Grid>
              <Grid item xs={6}/>
              <Grid item xs={6}>
                <TextFieldComponent
                  label={t('schema.workArea.RZTHandover.RZTTransferor')}
                  placeholder=" "
                  handleChange={updateData('fromRZT')}
                  value={data.fromRZT || ''}
                  error={errors['fromRZT']}/>
              </Grid>
              <Grid item xs={6}>
                <TextFieldComponent
                  label={t('schema.workArea.RZTHandover.RZTReceiver')}
                  placeholder=" "
                  handleChange={updateData('toRZT')}
                  value={data.toRZT || ''}
                  error={errors['toRZT']}/>
              </Grid>
              <Grid item xs={6}>
                <DatePickerComponent
                  label={t("schema.workArea.RZTHandover.date")}
                  handleChange={updateData('date')}
                  value={data.date}
                  error={errors['date']}/>
              </Grid>
              <Grid item xs={6}>
                <TimePickerComponent
                  label={t("schema.workArea.RZTHandover.time")}
                  handleChange={updateData('hour')}
                  value={data.hour}
                  error={errors['hour']}/>
              </Grid>
            </Grid>
          </Box>
        }
        actions={
          <Button variant="contained" color="primary" onClick={handleValidate}>
            {t("global.save")}
          </Button>
        }
      />
      {showConfirmCode && (
        <ConfirmCodeModal
          handleClose={() => setShowConfirmCode(false)}
          handleConfirm={(code) => historyService.createRZTHandover(code, data, trackElement)}
          handleSuccess={() => {
            enqueueSnackbar(t("schema.workArea.RZTHandover.success"), {variant: "success"});
            handleClose();
          }}/>
      )}
    </>
  );
};
