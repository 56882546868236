import {OrderLogic} from "@store/dispatches/orderLogic/orderLogicMap";
import {Pk, projectQuery, TrackElement, WorkZoneStatus, WorkZoneStep} from "@store/project";
import {DispatchTypeEnum} from '@store/dispatches/dispatch.model';

const isPNInsideOpenedZT = (trackElement: TrackElement): boolean => {
  const openedZTOnPN = projectQuery.projectZT.filter((zt) => {
    return zt.attributes?.step === WorkZoneStep.AVIS && zt.attributes?.status === WorkZoneStatus.OUVERTE
      && !!zt.pkEnd && Pk.isPkBetweenPks(trackElement.pkStart, zt.pkStart, zt.pkEnd);
  });

  return !!openedZTOnPN.length;
}

export const getPNOrderLogic = (trackElement: TrackElement): OrderLogic[] => {
  return [
    {
      dispatchType: DispatchTypeEnum.PN_RECOVERY_MONITORING,
      dependsOf: [
        {
          condition: true,
          dispatchType: null,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.PN_TERMINATION,
      dependsOf: [
        {
          condition: true,
          dispatchType: DispatchTypeEnum.PN_RECOVERY_MONITORING,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.PN_REQUEST_TO_CLOSE_PN,
      dependsOf: [
        {
          condition: isPNInsideOpenedZT(trackElement),
          dispatchType: null,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.PN_AUTHORIZATION_TO_CLOSE_PN,
      dependsOf: [
        {
          condition: true,
          dispatchType: DispatchTypeEnum.PN_REQUEST_TO_CLOSE_PN,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.PN_NOTICE_TO_CLOSE_PN,
      dependsOf: [
        {
          condition: true,
          dispatchType: DispatchTypeEnum.PN_AUTHORIZATION_TO_CLOSE_PN,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.PN_REQUEST_TO_REOPEN_PN,
      dependsOf: [
        {
          condition: isPNInsideOpenedZT(trackElement),
          dispatchType: null,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.PN_AUTHORIZATION_TO_REOPEN_PN,
      dependsOf: [
        {
          condition: true,
          dispatchType: DispatchTypeEnum.PN_REQUEST_TO_REOPEN_PN,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.PN_NOTICE_TO_REOPEN_PN,
      dependsOf: [
        {
          condition: true,
          dispatchType: DispatchTypeEnum.PN_AUTHORIZATION_TO_REOPEN_PN,
        },
      ],
    },
    {
      dispatchType: DispatchTypeEnum.PN_ATTEST_REOPENED,
      dependsOf: [
        {
          condition: true,
          dispatchType: DispatchTypeEnum.PN_NOTICE_TO_REOPEN_PN,
        },
      ],
    },
  ];
}
