import {GridColumns} from "@mui/x-data-grid";
import {Tooltip} from "@mui/material";

import I18n from "@utils/i18n.utils";
import {TrackElementHistoryType} from "@store/dispatches/dispatch.model";

export const peopleCountingColumns = () => [
  {
    field: "content.interlocutor",
    headerName: I18n.t("reporting.peopleCounting.table.interlocutor"),
    headerAlign: "center",
    valueGetter: (params) => params.row.content.interlocutor,
    align: "center",
    width: 200,
  },
  {
    field: "content.nature",
    headerName: I18n.t("reporting.peopleCounting.table.nature"),
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.content.nature,
    width: 200,
  },
  {
    field: "content.company",
    headerName: I18n.t("reporting.peopleCounting.table.company"),
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.content.company ?? '-',
    width: 200,
  },
  {
    field: "createdAt",
    headerName: I18n.t("reporting.peopleCounting.table.date"),
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      params.value
        ? I18n.t("reporting.peopleCounting.table.day", {date: new Date(params.value)})
        : "-",
    width: 150,
  },
  {
    field: "type",
    headerName: I18n.t("reporting.peopleCounting.table.type"),
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      switch (params.row.type as TrackElementHistoryType) {
        case TrackElementHistoryType.PEOPLE_COUNTING_ENTRANCE:
          return I18n.t("dispatches.managePeopleCounting.types.PEOPLE_COUNTING_ENTRANCE");
        case TrackElementHistoryType.PEOPLE_COUNTING_EXIT:
          return I18n.t("dispatches.managePeopleCounting.types.PEOPLE_COUNTING_EXIT");
        default:
          return "-";
      }
    },
    width: 150,
  },
  {
    field: "content.number",
    headerName: I18n.t("reporting.peopleCounting.table.number"),
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.content.number,
    width: 100,
  },
  {
    field: "content.currentNumber",
    headerName: I18n.t("reporting.peopleCounting.table.currentNumber"),
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.content.currentNumber,
    width: 150,
  },
  {
    field: "content.observations",
    headerName: I18n.t("reporting.peopleCounting.table.observations"),
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.content.observations,
    renderCell: (params) => (
      <Tooltip title={params.value ?? ""}>
        <span style={{
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center'
        }}>{params.value ?? "-"}</span>
      </Tooltip>
    ),
    flex: 1,
  },
] as GridColumns;
