import React, {useState} from "react";
import {Box, Button, Grid, IconButton, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {finalize} from "rxjs";

import {CreateProject, PK_INPUT_REGEXP, projectService, UpdateProject} from "@store/project";
import {Role, usersQuery} from "@store/users";
import {ProjectLight, ProjectType} from "@store/projects";

import {updateProjectValidation} from "@screens/auth/common/projectDetails/projectInfo/updateProject/updateProject.yup";
import {FieldErrors} from "@utils/yup.utils";

import dayjs from "@utils/dayjs.utils";

import ModalComponent from "@components/modals/Modal.component";
import TextFieldComponent from "@components/textField/TextField.component";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import SelectUserModal from "@screens/auth/common/selectUser/SelectUser.modal";
import {sessionQuery} from '@store/session';

interface UpdateProjectModalProps {
  handleClose: () => void;
  project: ProjectLight;
}

const UpdateProjectModal = (props: UpdateProjectModalProps) => {
  const {handleClose, project} = props;

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [openSelectUser, setOpenSelectUser] = useState<Role | undefined>();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<UpdateProject>(UpdateProject.fromProject(project));
  const [errors, setErrors] = useState<FieldErrors>({});

  const updateData = (d: CreateProject) => setData({...data, ...d});

  const handleValidate = () => {
    let errors = updateProjectValidation(data);

    setErrors(errors);
    if (Object.keys(errors).length) return;

    setLoading(true);
    projectService.updateProject(data).pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("projectDetails.projectInfo.projectInfo.success.edited"), {variant: "success"});
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  }

  return (
    <>
      <ModalComponent
        handleClose={handleClose}
        title={t("projectDetails.projectInfo.projectInfo.edit.title")}
        description={t(`projectDetails.projectInfo.projectInfo.edit.description`)}
        content={(
          <Stack flex={1} width="100%">
            <Box flex={1} width="100%" display="flex" alignItems="flex-start" justifyContent="center">
              <Grid container mt="40px" width="450px" columnSpacing={2.5} rowSpacing={1.5}>
                <Grid item xs={12}>
                  <TextFieldComponent
                    label={t("projectDetails.projectInfo.projectInfo.type")}
                    value={project.projectType || ""}
                    readOnly/>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldComponent
                    label={t("projectDetails.projectInfo.projectInfo.name")}
                    handleChange={(name) => updateData({name})}
                    value={data.name || ""}
                    error={errors.name}
                    required/>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldComponent
                    label={t("projectDetails.projectInfo.projectInfo.lineId")}
                    handleChange={(lineId) => updateData({lineId})}
                    value={data.lineId || ""}
                    error={errors.lineId}
                    required/>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={t("projectDetails.projectInfo.projectInfo.from")}
                    handleChange={(from) => updateData({from})}
                    value={data.from || ""}
                    error={errors.from}
                    required/>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={t("projectDetails.projectInfo.projectInfo.to")}
                    handleChange={(to) => updateData({to})}
                    value={data.to || ""}
                    error={errors.to}
                    required/>
                </Grid>
                <Grid item xs={6}>
                  <DatePickerComponent
                    label={t("projectDetails.projectInfo.projectInfo.startDate")}
                    handleChange={(startDate) => {
                      if (startDate && data.endingDate) {
                        const endingDate = dayjs(startDate).isAfter(data.endingDate) ? startDate : data.endingDate;

                        updateData({startingDate: startDate, endingDate});
                      } else {
                        updateData({startingDate: startDate});
                      }
                    }}
                    value={data.startingDate}
                    error={errors.startingDate}
                    required/>
                </Grid>
                <Grid item xs={6}>
                  <DatePickerComponent
                    label={t("projectDetails.projectInfo.projectInfo.endDate")}
                    handleChange={(endDate) => {
                      if (endDate && data.startingDate) {
                        const startingDate = dayjs(endDate).isBefore(data.startingDate) ? endDate : data.startingDate;

                        updateData({endingDate: endDate, startingDate});
                      } else {
                        updateData({endingDate: endDate});
                      }
                    }}
                    value={data.endingDate}
                    error={errors.endingDate}
                    required/>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={t("projectDetails.projectInfo.projectInfo.startPK")}
                    handleChange={(startingKilometerPoint) => updateData({startingKilometerPoint})}
                    pattern={PK_INPUT_REGEXP}
                    value={data.startingKilometerPoint || ""}
                    error={errors.startingKilometerPoint}
                    required/>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={t("projectDetails.projectInfo.projectInfo.endPK")}
                    handleChange={(endingKilometerPoint) => updateData({endingKilometerPoint})}
                    pattern={PK_INPUT_REGEXP}
                    value={data.endingKilometerPoint || ""}
                    error={errors.endingKilometerPoint}
                    required/>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldComponent
                    label={t("projectDetails.projectInfo.projectInfo.navigationScale")}
                    handleChange={(navigationScale) => updateData({navigationScale: navigationScale ? +navigationScale : undefined})}
                    pattern={new RegExp(/[1-9]\d*/)}
                    value={data.navigationScale?.toString() || ""}
                    error={errors.navigationScale}
                    readOnly={!sessionQuery.hasAnyOfRoles(Role.SUPER_ADMIN)}
                    required/>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={t("global.roles.OSC")}
                    handleClick={() => setOpenSelectUser(Role.OS_C)}
                    value={data.os_c ? `${data.os_c.lastname.toUpperCase()} ${data.os_c.firstname}` : ""}
                    endAdornment={data.os_c ? (
                      <IconButton
                        size="small"
                        sx={{marginRight: "-5px"}}
                        onClick={(evt) => {
                          evt.stopPropagation();
                          updateData({os_c: undefined});
                        }}>
                        <img alt="remove user" src="/images/delete_red_icon.svg"/>
                      </IconButton>
                    ) : undefined}
                    error={errors.os_c}
                    readOnly
                    required/>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={t(`global.roles.${project.projectType === ProjectType.NEW ? "OSRNew" : "OSR"}`)}
                    handleClick={() => setOpenSelectUser(Role.OS_R)}
                    value={data.os_r ? `${data.os_r.lastname.toUpperCase()} ${data.os_r.firstname}` : ""}
                    endAdornment={data.os_r ? (
                      <IconButton
                        size="small"
                        sx={{marginRight: "-5px"}}
                        onClick={(evt) => {
                          evt.stopPropagation();
                          updateData({os_r: undefined});
                        }}>
                        <img alt="remove user" src="/images/delete_red_icon.svg"/>
                      </IconButton>
                    ) : undefined}
                    error={errors.os_r}
                    readOnly
                    required/>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={t(`global.roles.${data.projectType === ProjectType.NEW ? "RELFNew" : "RELF"}`)}
                    handleClick={() => setOpenSelectUser(Role.RELF)}
                    value={data.relfs?.[0] ? `${data.relfs[0].lastname.toUpperCase()} ${data.relfs[0].firstname}` : ""}
                    endAdornment={data.relfs && data.relfs.length ? (
                      <IconButton
                        size="small"
                        sx={{marginRight: "-5px"}}
                        onClick={(evt) => {
                          evt.stopPropagation();
                          updateData({relfs: data.relfs!.slice(1, data.relfs!.length)});
                        }}>
                        <img alt="remove user" src="/images/delete_red_icon.svg"/>
                      </IconButton>
                    ) : undefined}
                    error={errors.relfs}
                    readOnly/>
                </Grid>
                {data.relfs && data.relfs.length > 1 && data.relfs.slice(1, data.relfs!.length).map((r) => (
                  <Grid item xs={6} key={r.id}>
                    <TextFieldComponent
                      label={t(`global.roles.${data.projectType === ProjectType.NEW ? "RELFNew" : "RELF"}`)}
                      handleClick={() => undefined}
                      value={r ? `${r.lastname.toUpperCase()} ${r.firstname}` : ""}
                      endAdornment={
                        <IconButton
                          size="small"
                          sx={{marginRight: "-5px"}}
                          onClick={(evt) => {
                            evt.stopPropagation();
                            updateData({relfs: data.relfs!.filter((relf) => relf.id !== r.id)});
                          }}>
                          <img alt="remove user" src="/images/delete_red_icon.svg"/>
                        </IconButton>
                      }
                      readOnly/>
                  </Grid>
                ))}
                {data.relfs && data.relfs.length > 0 && (
                  <Grid item xs={6} container justifyContent="center" alignContent="center">
                    <Box mt={data.relfs.length % 2 === 1 ? "20px" : undefined}>
                      <Button variant="text" color="primary" size="small" onClick={() => setOpenSelectUser(Role.RELF)}>
                        {t(`projectDetails.projectInfo.projectInfo.edit.${data.projectType === ProjectType.NEW ? "addARELFNew" : "addARELF"}`)}
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Stack>
        )}
        actions={(
          <Button variant="contained" color="primary" disabled={loading} onClick={handleValidate}>
            {t("global.modify")}
          </Button>
        )}/>
      {!!openSelectUser && (
        <SelectUserModal
          button={t("global.validate")}
          handleAdd={(user) => {
            switch (openSelectUser) {
              case Role.OS_C:
                updateData({os_c: user});
                break;
              case Role.OS_R:
                updateData({os_r: user});
                break;
              case Role.RELF:
                updateData({relfs: (data.relfs || []).concat(user)});
                break;
            }
          }}
          handleClose={() => setOpenSelectUser(undefined)}
          search={t("projectDetails.projectInfo.projectInfo.selectUser.search")}
          getUsersByRole={usersQuery.usersByRole$(openSelectUser)}
          usersDisabled={openSelectUser === Role.RELF ? data.relfs : undefined}
          title={t("projectDetails.projectInfo.projectInfo.selectUser.title", {role: openSelectUser})}/>
      )}
    </>
  );
};

export default UpdateProjectModal;