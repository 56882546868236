import React from "react";
import {WithStyles, withStyles} from "@mui/styles";

import style from "./loading.style";

import EnvironmentRibbonComponent from '@components/EnvironmentRibbon.component';

interface LoadingScreenProps extends WithStyles<typeof style> {
}

const LoadingScreen = (props: LoadingScreenProps) => {
  const {classes} = props;

  return (
    <div className={classes.container}>
      <EnvironmentRibbonComponent dark/>
      <img className={classes.logo} alt="logo sages rail" src="/images/logo_white.png"/>
      <img alt="loading train" className={classes.loading} src="/images/loading_animation.gif"/>
    </div>
  );
}

export default withStyles(style)(LoadingScreen);
