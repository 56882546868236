import React, {useEffect} from 'react';
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {Grid} from "@mui/material";
import TimePickerComponent from "@components/datePicker/TimePicker.component";

const MovementEngagementArrivalForm = (props: DispatchFormGlobalProps) => {
  const {dispatch, setDispatch, errors = {}} = props;

  const updateDispatchContent = (name: string) => (value: any) => setDispatch({
    ...dispatch,
    dispatch: {...dispatch.dispatch, [name]: value}
  });

  useEffect(() => {
    setDispatch({
      type: DispatchTypeEnum.MOVEMENT_ENGAGEMENT_ARRIVAL,
      dispatch: {
        date: new Date(),
        hour: new Date(),
      },
    })
  }, []);

  return (
    <div>
      <Grid container direction={"row"} columnSpacing={2.5} rowSpacing={1.5}>
        <Grid item xs={6}>
          <TimePickerComponent
            label={"Heure d'arrivée"}
            handleChange={updateDispatchContent("date")}
            value={dispatch?.dispatch?.date}
            error={errors["date"]}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default MovementEngagementArrivalForm;
