import React, {useEffect, useState} from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {withStyles, WithStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

import Table from "@components/table/Table";
import {ordersQuery} from "@store/dispatches/orders.query";
import {GetDispatchHistory} from "@store/dispatches/dispatch.model";
import {ordersService} from "@store/dispatches/orders.service";
import {Button, debounce, Stack} from "@mui/material";
import {dispatchHistoryColumns} from "./table.utils";

import style from "./DispatchHistoryTab.style";
import {dispatchFiltersQuery} from "@store/dispatches/filters.query";
import {DispatchFiltersState} from "@store/dispatches/filters.store";
import {filesService} from "@store/files";
import {useSnackbar} from "notistack";
import dayjs from "dayjs";
import {projectQuery} from "@store/project";
import DispatchDrawer, {FiltersType} from "@screens/auth/common/dispatch/Dispatch.drawer";
import {dispatchFiltersService} from "@store/dispatches/filters.service";
import SearchBar from "@components/textField/SearchBar.component";

interface DispatchHistoryProps extends WithStyles<typeof style> {
  isClientView?: boolean;
}

const DispatchHistoryTab = (props: DispatchHistoryProps) => {
  const {classes, isClientView} = props;

  const {t} = useTranslation();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const {projectId} = useParams();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<DispatchFiltersState>({});

  const [activeDrawer, setActiveDrawer] = useState<"filter" | undefined>(undefined);

  const [dispatches, setDispatches] = useState<GetDispatchHistory>();

  useEffect(() => {
    return () => {
      if (isClientView) dispatchFiltersService.resetStore();
    };
  }, [isClientView]);

  useEffect(() => {
    window.addEventListener("RELOAD_DISPATCH", () => {
      if (projectId) {
        ordersService
          .getDispatchHistory(projectId, page + 1, filters)
          .subscribe(setDispatches);
      }
    });

    return () => {
      window.removeEventListener("RELOAD_DISPATCH", () => {
      });
    };
  });

  useEffect(() => {
    if (projectId) {
      ordersService
        .getDispatchHistory(projectId, page + 1, filters)
        .subscribe(setDispatches);
    }

    const _loading$ = ordersQuery.selectLoading().subscribe(setLoading);

    return () => {
      _loading$.unsubscribe();
    };
  }, [page, projectId, filters]);

  useEffect(() => {
    const _filters$ = dispatchFiltersQuery.filters$.subscribe(setFilters);

    return () => {
      _filters$.unsubscribe();
    };
  }, []);

  const handleDownloadDispatchCSV = () => {
    if (!projectId) return;
    ordersService.getDispatchHistoryCSV(projectId, filters).subscribe({
      next: (res) => {
        filesService.downloadFile(res, `dispatch-history_${projectQuery.projectId}_${dayjs().format("DD-MM-YYYY")}.csv`);
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  return (
    <>
      <div className={classes.tableContainer}>
        {!isClientView ? (
          <Stack direction="row" spacing={2} className={classes.tableButtons}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleDownloadDispatchCSV}
              startIcon={<img src="/images/export_icon.svg"/>}
            >
              {t("dispatches.export")}
            </Button>
          </Stack>
        ) : (
          <Stack direction="row" spacing={2} mb="20px">
            <SearchBar
              placeholder={t(`dispatches.${projectQuery.isProjectTypeNew ? "searchNew" : "search"}`)}
              color="secondary"
              handleChange={debounce((value) => {
                dispatchFiltersService.setFilters({search: value})
              }, 300)}/>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setActiveDrawer("filter")}
              startIcon={<img src="/images/filter_icon.svg" alt=""/>}>
              {t("dispatches.filter")}
            </Button>
          </Stack>
        )}
        <Table
          loading={loading}
          columns={dispatchHistoryColumns(projectQuery.isProjectTypeNew)}
          pageCount={dispatches?.meta.pageCount ?? 1}
          page={page}
          handlePageChange={setPage}
          data={dispatches?.data || []}
          onRowClick={(params) => {
            if (params.row.content.order) {
              navigate(
                `${params.row.content.order || "free-dispatch"}/${
                  params.row.content._id || params.row.id
                }`
              );
            } else {
              navigate(`free-dispatch/${params.row.id}`);
            }
          }}
        />
      </div>
      <Outlet/>
      {activeDrawer && (
        <DispatchDrawer
          handleClose={() => setActiveDrawer(undefined)}
          type={FiltersType.DISPATCH}/>
      )}
    </>
  );
};

export default withStyles(style)(DispatchHistoryTab);
