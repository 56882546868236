import {ID} from "@datorama/akita";

import {SelectComponentItem} from "@components/select/Select.component";
import {UploadedFile} from "@store/files";
import {AutocompleteComponentItem} from "@components/select/Autocomplete.component";

export enum Role {
  SUPER_ADMIN = "SUPER_ADMIN",
  OS_C = "OS-C",
  OS_R = "OS-R",
  RELF = "RELF",
  CLIENT = "CLIENT",
}

export namespace Role {
  export const selectItems: SelectComponentItem[] = [
    {label: Role.SUPER_ADMIN.toString(), value: Role.SUPER_ADMIN, data: Role.SUPER_ADMIN},
    {label: Role.OS_C.toString(), value: Role.OS_C, data: Role.OS_C},
    {label: Role.OS_R.toString(), value: Role.OS_R, data: Role.OS_C},
    {label: Role.RELF.toString(), value: Role.RELF, data: Role.OS_R},
    {label: Role.CLIENT.toString(), value: Role.CLIENT, data: Role.OS_R},
  ];

  export const roles = [Role.OS_C, Role.OS_R, Role.RELF, Role.CLIENT, Role.SUPER_ADMIN];
}

export interface UsersFilters {
  search: string;
  roles: Role[];
}

export interface UpdateUserPassword {
  newPassword?: string;
  confirmPassword?: string;
}

export interface UpdateUser extends Partial<Omit<User, "currentProject">> {
  id: ID;
  formationLocalFile?: File;
  habilitationLocalFile?: File;
  medicalVisitLocalFile?: File;
}

export namespace UpdateUser {
  export const toFormData = (data: CreateUser) => {
    const formData = new FormData();
    formData.append("firstname", data.firstname!);
    formData.append("lastname", data.lastname!);
    formData.append("email", data.email!);
    formData.append("role", data.role!);
    if (data.role !== Role.CLIENT) {
      formData.append(
        "formationExpireDate",
        typeof data.formationExpireDate === 'string'
          ? data.formationExpireDate
          : data.formationExpireDate!.toISOString(),
      );
      formData.append(
        "habilitationExpireDate",
        typeof data.habilitationExpireDate === 'string'
          ? data.habilitationExpireDate
          : data.habilitationExpireDate!.toISOString(),
      );
      formData.append(
        "medicalVisitExpireDate",
        typeof data.medicalVisitExpireDate === 'string'
          ? data.medicalVisitExpireDate
          : data.medicalVisitExpireDate!.toISOString(),
      );

      if (data.formationLocalFile)
        formData.append("formationFile", data.formationLocalFile!);

      if (data.habilitationLocalFile)
        formData.append("habilitationFile", data.habilitationLocalFile!);

      if (data.medicalVisitLocalFile)
        formData.append("medicalVisitFile", data.medicalVisitLocalFile!);
    }

    return formData;
  };
}

export interface CreateUser extends Partial<Omit<User, "currentProject">> {
  password?: string;
  confirmPassword?: string;
  formationLocalFile?: File;
  habilitationLocalFile?: File;
  medicalVisitLocalFile?: File;
  currentProject?: AutocompleteComponentItem;
}

export namespace CreateUser {
  export const toFormData = (data: CreateUser) => {
    const formData = new FormData();
    formData.append("firstname", data.firstname!);
    formData.append("lastname", data.lastname!);
    formData.append("email", data.email!);
    formData.append("password", data.password!);
    formData.append("role", data.role!);
    if (data.role !== Role.CLIENT) {
      if (data.formationLocalFile) {
        formData.append("formationFile", data.formationLocalFile!);
      }
      formData.append("formationExpireDate", data.formationExpireDate!.toISOString());
      if (data.habilitationLocalFile) {
        formData.append("habilitationFile", data.habilitationLocalFile!);
      }
      formData.append("habilitationExpireDate", data.habilitationExpireDate!.toISOString());
      if (data.medicalVisitLocalFile) {
        formData.append("medicalVisitFile", data.medicalVisitLocalFile!);
      }
      formData.append("medicalVisitExpireDate", data.medicalVisitExpireDate!.toISOString());
    }
    return formData;
  };
}

export interface User {
  id: ID;
  firstname: string;
  lastname: string;
  email: string;
  role: Role;
  formationFile: UploadedFile;
  formationExpireDate: Date;
  habilitationFile: UploadedFile;
  habilitationExpireDate: Date;
  medicalVisitFile: UploadedFile;
  medicalVisitExpireDate: Date;
  isDisabled: boolean;
}
