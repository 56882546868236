import ModalComponent from "@components/modals/Modal.component";
import {Button, IconButton, Stack} from "@mui/material";
import {CreateOrUpdateTrackElement, Pk, TrackElement, TrackElementType} from "@store/project";
import {useTranslation} from "react-i18next";
import useIncompatibilitiesAcceptation from "./IncompatibilitiesAcceptation.component";
import ConfirmCodeModal from "@components/confirmCode/ConfirmCode.modal";
import React, {useMemo, useState} from "react";
import {sessionService} from "@store/session";
import I18n from "@utils/i18n.utils";
import {IncompatibilityError} from "@utils/incompatibilities/incompatibility.utils";
import getIcon = TrackElementType.getIcon;

interface DeleteConfirmationProps {
  handleClose: () => void;
  handleConfirm: (incompatibilities?: IncompatibilityError[]) => void;
  trackElement: TrackElement | CreateOrUpdateTrackElement;
}

const DeleteConfirmation = (props: DeleteConfirmationProps) => {
  const {handleClose, handleConfirm, trackElement} = props;

  const {t} = useTranslation();

  const [showConfirmCode, setOpenConfirmCode] = useState<IncompatibilityError[] | false>(false);

  const {
    IncompatibilitiesAcceptation,
    disabledByIncompatibilities,
    incompatibilities
  } = useIncompatibilitiesAcceptation({
    trackElement,
    deletion: true,
  });

  const getModalTitle = () => {
    switch (trackElement.type) {
      case TrackElementType.MAIN_TRACK:
      case TrackElementType.SERVICE_TRACK:
      case TrackElementType.TRACK_OUT_OF_SERVICE:
      case TrackElementType.SIDING_TRACK:
        return I18n.t("schema.trainTrack.delete.title");

      case TrackElementType.TRACK_CHANGE:
      case TrackElementType.WEDGE:
        return I18n.t("schema.trackDevice.delete.title");

      case TrackElementType.PN:
        return t("schema.pn.delete.title");
      case TrackElementType.PRA:
      case TrackElementType.PRO:
      case TrackElementType.BUFFER:
      case TrackElementType.TUNNEL:
      case TrackElementType.VIADUCT:
      case TrackElementType.STOPPER:
      case TrackElementType.INST_OTHER:
        return I18n.t("schema.installation.delete.title");

      case TrackElementType.WHISTLE:
      case TrackElementType.SIGN_STOP:
      case TrackElementType.SIGN_OTHER:
      case TrackElementType.SIGNALISATION:
        return I18n.t("schema.signalisation.delete.title");

      case TrackElementType.TRAINING_ZONE:
        return I18n.t("schema.zone.delete.title");

      case TrackElementType.DECLIVITY:
        return I18n.t("schema.declivity.delete.title");

      case TrackElementType.PRS:
        return I18n.t("schema.PRS.delete.title");

      case TrackElementType.TRAIN_STATION:
        return I18n.t("schema.trainStation.delete.title");

      case TrackElementType.CONSTRUCTION:
        return I18n.t("schema.construction.delete.title");

      case TrackElementType.WORKSITE:
        return I18n.t("schema.worksite.delete.title");

      case TrackElementType.OUTSIDE_ZPF:
      case TrackElementType.LIGHTWEIGHT:
      case TrackElementType.ACTIVITY:
        return I18n.t("schema.activity.delete.title");

      case TrackElementType.MAP:
      case TrackElementType.TRAFFIC_BAN:
      case TrackElementType.SPEED_LIMIT:
      case TrackElementType.OBSTACLE:
        return I18n.t("schema.restriction.delete.title");

      case TrackElementType.WORK_TRAIN:
        return I18n.t("schema.workTrain.delete.title");

      case TrackElementType.WORKSITE_MACHINE:
        return I18n.t("schema.worksiteMachine.delete.title");

      case TrackElementType.WORK_ZONE:
        return I18n.t("schema.workArea.delete.title");

      case TrackElementType.ELEMENTARY_CONSTRUCTION_SITE:
        return I18n.t("schema.CE.delete.title");
      default:
        return "-";
    }
  };

  const getModalDescription = () => {
    switch (trackElement.type) {
      case TrackElementType.MAIN_TRACK:
        return I18n.t("schema.trainTrack.delete.description.mainTrack");
      case TrackElementType.SERVICE_TRACK:
        return I18n.t("schema.trainTrack.delete.description.serviceTrack");
      case TrackElementType.TRACK_OUT_OF_SERVICE:
        return I18n.t("schema.trainTrack.delete.description.trackOutOfService");
      case TrackElementType.SIDING_TRACK:
        return I18n.t("schema.trainTrack.delete.description.sidingTrack");

      case TrackElementType.TRACK_CHANGE:
        return I18n.t("schema.trackDevice.delete.description.trackChange");
      case TrackElementType.WEDGE:
        return I18n.t("schema.trackDevice.delete.description.wedge");

      case TrackElementType.PN:
        return t("schema.pn.delete.description");
      case TrackElementType.PRA:
        return I18n.t("schema.installation.delete.description.PRA");
      case TrackElementType.PRO:
        return I18n.t("schema.installation.delete.description.PRO");
      case TrackElementType.BUFFER:
        return I18n.t("schema.installation.delete.description.buffer");
      case TrackElementType.TUNNEL:
        return I18n.t("schema.installation.delete.description.tunnel");
      case TrackElementType.VIADUCT:
        return I18n.t("schema.installation.delete.description.viaduct");
      case TrackElementType.STOPPER:
        return I18n.t("schema.installation.delete.description.stopper");
      case TrackElementType.INST_OTHER:
        return I18n.t("schema.installation.delete.description.other");

      case TrackElementType.WHISTLE:
        return I18n.t("schema.signalisation.delete.description.whistle");
      case TrackElementType.SIGN_STOP:
        return I18n.t("schema.signalisation.delete.description.stop");
      case TrackElementType.SIGN_OTHER:
        return I18n.t("schema.signalisation.delete.description.other");
      case TrackElementType.SIGNALISATION:
        return I18n.t("schema.signalisation.delete.description.signalisation");

      case TrackElementType.TRAINING_ZONE:
        return I18n.t("schema.zone.delete.description.trainingZone");

      case TrackElementType.DECLIVITY:
        return I18n.t("schema.declivity.delete.description");

      case TrackElementType.PRS:
        return I18n.t("schema.PRS.delete.description");

      case TrackElementType.TRAIN_STATION:
        return I18n.t("schema.trainStation.delete.description");

      case TrackElementType.CONSTRUCTION:
        return I18n.t("schema.construction.delete.description");

      case TrackElementType.WORKSITE:
        return I18n.t("schema.worksite.delete.description");

      case TrackElementType.OUTSIDE_ZPF:
        return I18n.t("schema.activity.delete.description.outsideZPF");
      case TrackElementType.LIGHTWEIGHT:
        return I18n.t("schema.activity.delete.description.lightweight");
      case TrackElementType.ACTIVITY:
        return I18n.t("schema.activity.delete.description.activity");

      case TrackElementType.MAP:
        return I18n.t("schema.restriction.delete.description.MAP");
      case TrackElementType.TRAFFIC_BAN:
        return I18n.t("schema.restriction.delete.description.trafficBan");
      case TrackElementType.SPEED_LIMIT:
        return I18n.t("schema.restriction.delete.description.speedLimit");
      case TrackElementType.OBSTACLE:
        return I18n.t("schema.restriction.delete.description.obstacle");

      case TrackElementType.WORK_TRAIN:
        return I18n.t("schema.workTrain.delete.description");

      case TrackElementType.WORKSITE_MACHINE:
        return I18n.t("schema.worksiteMachine.delete.description");

      case TrackElementType.WORK_ZONE:
        return I18n.t("schema.workArea.delete.description");

      case TrackElementType.ELEMENTARY_CONSTRUCTION_SITE:
        return I18n.t("schema.CE.delete.description");
      default:
        return "-";
    }
  };

  const warning = useMemo(() => {
    switch (trackElement.type) {
      case TrackElementType.MAIN_TRACK:
      case TrackElementType.SERVICE_TRACK:
      case TrackElementType.TRACK_OUT_OF_SERVICE:
      case TrackElementType.SIDING_TRACK:
        return I18n.t("schema.trainTrack.delete.warning");
      default:
        return "";
    }
  }, [trackElement]);

  return (
    <>
      <ModalComponent
        handleClose={handleClose}
        maxWidth="md"
        titleIcon={
          !!trackElement?.type ? (
            <IconButton color="primary" sx={{height: "60px", width: "60px"}}>
              <img alt="" src={getIcon(trackElement.type)}/>
            </IconButton>
          ) : undefined
        }
        title={getModalTitle()}
        description={`${getModalDescription()} ${trackElement.attributes?.name || ""} ${
          trackElement.pkStart && trackElement.pkEnd
            ? t("incompatibilities.labels.fromPKToPK", {
              fromPK: typeof trackElement.pkStart === "string" ? trackElement.pkStart : Pk.toString(trackElement.pkStart),
              toPK: typeof trackElement.pkEnd === "string" ? trackElement.pkEnd : Pk.toString(trackElement.pkEnd),
            })
            : t("incompatibilities.labels.atPK", {
              atPK: typeof trackElement.pkStart === "string" ? trackElement.pkStart : Pk.toString(trackElement.pkStart),
            })
        }${warning !! ? `\n\n ${warning}` : ''}`}
        content={
          <Stack width="100%">
            {IncompatibilitiesAcceptation && (
              <Stack width="100%" px={10}>
                <IncompatibilitiesAcceptation/>
              </Stack>
            )}
          </Stack>
        }
        actions={
          <Button
            variant="contained"
            color="error"
            disabled={disabledByIncompatibilities}
            onClick={() => (!!IncompatibilitiesAcceptation ? setOpenConfirmCode(incompatibilities) : handleConfirm())}>
            {t("global.delete")}
          </Button>
        }
      />
      {showConfirmCode && (
        <ConfirmCodeModal
          handleSuccess={() => {
            handleConfirm(incompatibilities);
            setOpenConfirmCode(false);
          }}
          handleConfirm={(code) => sessionService.confirmIdentificationCode(code)}
          handleClose={() => setOpenConfirmCode(false)}
        />
      )}
    </>
  );
};

export default DeleteConfirmation;
