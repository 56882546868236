import React, {useEffect, useState} from "react";
import {Button, Stack} from "@mui/material";
import {CreateOrUpdateTrackElement} from "@store/project";
import {useTranslation} from "react-i18next";
import {FieldErrors} from "@utils/yup.utils";
import {trackElementValidation} from "@screens/auth/common/schema/forms/trackElements/forms.yup";
import ModalComponent from "@components/modals/Modal.component";
import WorksiteMachineForm from "@screens/auth/common/schema/forms/trackElements/WorksiteMachine.form";
import useIncompatibilitiesAcceptation from "../../../components/IncompatibilitiesAcceptation.component";
import { IncompatibilityError } from "@utils/incompatibilities/incompatibility.utils";

interface ManageWorksiteMachineFormProps {
  description: string;
  handleClose: () => void;
  handleDelete?: () => void;
  handleValidate: (incompatibilities?: IncompatibilityError[]) => void;
  loading?: boolean;
  setTrackElement: (trackElement: CreateOrUpdateTrackElement) => void;
  trackElement: CreateOrUpdateTrackElement;
}

const ManageWorksiteMachineForm = (props: ManageWorksiteMachineFormProps) => {
  const {description, handleClose, handleDelete, handleValidate, loading, setTrackElement, trackElement} = props;

  const {t} = useTranslation();

  const [errors, setErrors] = useState<FieldErrors>({});

  const {IncompatibilitiesAcceptation, disabledByIncompatibilities, incompatibilities} = useIncompatibilitiesAcceptation({trackElement});

  const handleUpdate = () => {
    let errors = trackElementValidation(trackElement);

    setErrors(errors);
    if (!Object.keys(errors).length) handleValidate(incompatibilities);
  };

  useEffect(() => {
    setTrackElement({...trackElement, attributes: {...trackElement.attributes, dateTime: new Date()}});
  }, []);

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t(`schema.worksiteMachine.title.edit`)}
      description={description}
      content={
        <>
          <WorksiteMachineForm errors={errors} trackElement={trackElement} setTrackElement={setTrackElement}/>
          {IncompatibilitiesAcceptation && (
            <Stack pt="20px">
              <IncompatibilitiesAcceptation/>
            </Stack>
          )}
        </>
      }
      actions={
        <>
          <Button
            variant="contained"
            color="primary"
            disabled={loading || disabledByIncompatibilities}
            onClick={handleUpdate}>
            {t("global.modify")}
          </Button>
          {!!handleDelete && (
            <Button variant="contained" color="error" disabled={loading} onClick={handleDelete}>
              {t("global.delete")}
            </Button>
          )}
        </>
      }
    />
  );
};

export default ManageWorksiteMachineForm;
