import {createStyles} from "@mui/styles";

const classes = () => createStyles({
  modalContent: {
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    marginTop: "30px",
    height: "100%",
    position: "relative",
    width: '100%',
  },
  tableButtons: {
    position: "absolute",
    bottom: 40,
    right: 10,
    zIndex: 2
  }
})

export default classes;