import React, {useEffect} from "react";
import {Grid, InputLabel} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import {Pk, PK_INPUT_REGEXP, projectQuery} from "@store/project";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import SelectComponent from "@components/select/Select.component";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {sessionQuery} from "@store/session";
import NumberTextFieldComponent from "@components/textField/NumberTextField.component";
import {useTranslation} from "react-i18next";
import {ordersService} from "@store/dispatches/orders.service";
import {useParams} from "react-router-dom";

const SplittingForm = (props: DispatchFormGlobalProps) => {
  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const {t} = useTranslation();

  const {projectId} = useParams();

  const updateDispatchContent = (name: string) => (value: any) =>
    setDispatch({
      ...dispatch,
      dispatch: {...dispatch.dispatch, [name]: value},
    });

  useEffect(() => {
    ordersService.getDispatchNumber(projectId!).subscribe({
      next: (dispatchNbr) => {
        setDispatch({
          type: DispatchTypeEnum.SPLITTING_SPLITTING,
          dispatch: {
            ttxNbr: trackElement?.attributes.name,
            from: `${sessionQuery.getValue().user?.firstname || ""} ${
              sessionQuery.getValue().user?.lastname || ""
            }`,
            to: trackElement?.attributes.ATTX,
            pkStart: Pk.toString(trackElement?.pkStart),
            fromDate: new Date(),
            date: new Date(),
            hour: new Date(),
            way: trackElement?.tracks[0],
            deliveredDispatchNbr: dispatchNbr,
          },
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid container columnSpacing={2.5} rowSpacing={1.5}>
        <Grid item xs={4}>
          <TextFieldComponent
            label={t("schema.splitting.TTX")}
            placeholder={t("schema.splitting.TTX")}
            readOnly
            value={dispatch?.dispatch?.ttxNbr || ""}
            handleChange={updateDispatchContent("ttxNbr")}
            error={errors["ttxNbr"]}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={t("schema.splitting.motorEnginesNbr")}
            placeholder={t("schema.splitting.motorEnginesNbr")}
            readOnly={!editable}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            value={dispatch?.dispatch?.emNbr}
            handleChange={updateDispatchContent("emNbr")}
            error={errors["emNbr"]}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={t("schema.splitting.nbrOfCars")}
            placeholder={t("schema.splitting.nbrOfCars")}
            readOnly={!editable}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            value={dispatch?.dispatch?.nbrOfCars}
            handleChange={updateDispatchContent("nbrOfCars")}
            error={errors["nbrOfCars"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent
            label={t("schema.splitting.RELF")}
            placeholder={t("schema.splitting.RELF")}
            readOnly
            value={dispatch?.dispatch?.from || ""}
            handleChange={updateDispatchContent("from")}
            error={errors["from"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent
            readOnly={!editable}
            label={t("schema.splitting.toATTX")}
            placeholder={t("schema.splitting.ATTX")}
            value={dispatch?.dispatch?.to || ""}
            handleChange={updateDispatchContent("to")}
            error={errors["to"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent
            label={t("schema.splitting.splittingAuthorization")}
            placeholder={t("schema.splitting.number")}
            readOnly
            value={dispatch?.dispatch?.ttxNbr || ""}
            handleChange={updateDispatchContent("ttxNbr")}
            error={errors["ttxNbr"]}
          />
        </Grid>
        <Grid item xs={5}>
          <SelectComponent
            label={t("schema.splitting.onZT")}
            readOnly={!editable}
            value={dispatch?.dispatch?.workZoneNbr || ""}
            items={projectQuery.projectZT.map((zt) => ({
              label: zt.attributes.name || "-",
              value: zt.id,
            }))}
            handleChange={(workZoneNbr) => {
              setDispatch({
                ...dispatch,
                dispatch: {
                  ...dispatch.dispatch,
                  workZoneNbr,
                  trainingZoneNbr: undefined,
                },
              });
            }}
            error={errors["workZoneNbr"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputLabel sx={{ml: "0px", mt: "30px", textAlign: "center"}}>
            {t("schema.splitting.or")}
          </InputLabel>
        </Grid>
        <Grid item xs={5}>
          <SelectComponent
            label={t("schema.splitting.onZF")}
            readOnly={!editable}
            value={dispatch?.dispatch?.trainingZoneNbr || ""}
            items={projectQuery.projectTrainingZone.map((tz) => ({
              label:
                t("schema.zone.types.trainingZone") +
                ` (${Pk.toString(tz.pkStart)} - ${Pk.toString(tz.pkEnd)})`,
              value: tz.id,
            }))}
            handleChange={(trainingZoneNbr) => {
              setDispatch({
                ...dispatch,
                dispatch: {
                  ...dispatch.dispatch,
                  trainingZoneNbr,
                  workZoneNbr: undefined,
                },
              });
            }}
            error={errors["trainingZoneNbr"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent
            readOnly={!editable}
            label={t("schema.splitting.toPK")}
            placeholder={t("schema.splitting.PK")}
            handleChange={updateDispatchContent("pkStart")}
            pattern={PK_INPUT_REGEXP}
            value={dispatch?.dispatch?.pkStart || ""}
            error={errors["pkStart"]}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectComponent
            readOnly={!editable}
            label={t("schema.splitting.onTrack")}
            handleChange={(t) => updateDispatchContent("way")(t)}
            value={dispatch?.dispatch?.way || ""}
            error={errors["way"]}
            items={projectQuery.projectTracks.map((t) => ({
              label: t.attributes.name || "-",
              value: t.id,
            }))}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            readOnly={!editable}
            label={t("schema.splitting.toTime")}
            value={dispatch?.dispatch?.fromDate}
            handleChange={(d) => {
              updateDispatchContent("fromDate")(d);
            }}
            error={errors["fromDate"]}
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldComponent
            readOnly={!editable}
            multiline
            rows={4}
            label={t("schema.splitting.additionalInformation")}
            placeholder={t("schema.splitting.additionalInformation")}
            handleChange={updateDispatchContent("additionalInformation")}
            value={dispatch?.dispatch?.additionalInformation || ""}
            error={errors["additionalInformation"]}
          />
        </Grid>

        <Grid item xs={4}>
          <DatePickerComponent
            label={t("schema.splitting.fromDate")}
            placeholder=" "
            readOnly={!editable}
            value={dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("date")}
            error={errors["date"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            label={t("schema.splitting.toTime")}
            placeholder=" "
            readOnly={!editable}
            value={editable ? dispatch?.dispatch?.hour : dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("hour")}
            error={errors["hour"]}
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={t("schema.splitting.deliveredDispatchNbr")}
            readOnly={!editable}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            handleChange={updateDispatchContent("deliveredDispatchNbr")}
            value={dispatch?.dispatch?.deliveredDispatchNbr}
            error={errors["deliveredDispatchNbr"]}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={t("schema.splitting.receivedDispatchNbr")}
            readOnly={!editable}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            handleChange={updateDispatchContent("receivedDispatchNbr")}
            value={dispatch?.dispatch?.receivedDispatchNbr}
            error={errors["receivedDispatchNbr"]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SplittingForm;
