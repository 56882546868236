import React, {useState} from "react";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {finalize} from "rxjs";

import {sessionService, UpdateCurrentUserPassword} from "@store/session";

import Modal from "@components/modals/Modal.component";
import TextFieldComponent from "@components/textField/TextField.component";
import {newPasswordValidation} from "./newPassword.yup";
import {FieldErrors} from "@utils/yup.utils";

const NewPasswordModal = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<UpdateCurrentUserPassword>({});

  const [errors, setErrors] = useState<FieldErrors>({});

  const updateData = (name: string) => (value: any) => setData((state) => ({...state, [name]: value}));

  const handleValidate = () => {
    const errors = newPasswordValidation(data);

    setErrors(errors);

    if (!data.currentPassword || !data.newPassword || Object.keys(errors).length) return;

    setLoading(true);
    sessionService.changePassword(data.currentPassword, data.newPassword)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => enqueueSnackbar(t('newPassword.success'), {variant: 'success'}),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  }

  return (
    <Modal
      title={t("newPassword.title")}
      content={(
        <Stack mt="25px" mb="10px" width="70%" justifyContent="center" alignContent="center" spacing={3.5}>
          <Stack spacing={3.5}>
            <Typography align="center" fontWeight="400" fontSize={16}>
              {t("newPassword.description1")}
            </Typography>
            <Typography align="center" fontWeight="400" fontSize={16}>
              {t("newPassword.description2")}
            </Typography>
          </Stack>
          <Box>
            <Grid container rowSpacing={1.5}>
              <Grid item xs={2}/>
              <Grid item xs={8}>
                <TextFieldComponent
                  required
                  type="password"
                  label={t("newPassword.currentPassword")}
                  value={data.currentPassword || ""}
                  handleChange={updateData("currentPassword")}
                  placeholder={t("newPassword.currentPassword")}
                  error={errors.currentPassword}
                />
              </Grid>
              <Grid item xs={2}/>
              <Grid item xs={2}/>
              <Grid item xs={8}>
                <TextFieldComponent
                  required
                  type="password"
                  label={t("newPassword.newPassword")}
                  value={data.newPassword || ""}
                  handleChange={updateData("newPassword")}
                  placeholder={t("newPassword.newPassword")}
                  error={errors.newPassword}
                />
              </Grid>
              <Grid item xs={2}/>
              <Grid item xs={2}/>
              <Grid item xs={8}>
                <TextFieldComponent
                  required
                  type="password"
                  label={t("newPassword.confirmPassword")}
                  value={data.confirmPassword || ""}
                  handleChange={updateData("confirmPassword")}
                  placeholder={t("newPassword.confirmPassword")}
                  error={errors.confirmPassword}
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      )}
      actions={(
        <>
          <Button variant="contained" color="primary" onClick={handleValidate} disabled={loading}>
            {t("newPassword.button")}
          </Button>
        </>
      )}/>
  );
}

export default NewPasswordModal;
