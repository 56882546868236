import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import ModalComponent from "@components/modals/Modal.component";
import React, {useState} from "react";
import {Box, Button, Grid} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import {projectQuery} from "@store/project";
import {FieldErrors} from "@utils/yup.utils";
import {CreateAccidentAtWork} from "@store/reporting";
import {
  addAccidentAtWorkYup
} from "@screens/auth/common/reporting/components/accidentsAtWork/addAccidentAtWork/addAccidentAtWork.yup";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {historyService} from "@store/history";

interface AddAccidentAtWorkModalProps {
  handleClose: () => void;
}

export const AddAccidentAtWorkModal = (props: AddAccidentAtWorkModalProps) => {
  const {handleClose} = props;

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [accidentAtWork, setAccidentAtWork] = useState<CreateAccidentAtWork>({
    projectId: projectQuery.projectId,
    date: new Date(),
    hour: new Date(),
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<FieldErrors>({});

  const updateAccidentAtWork = (name: string) => (value: any) =>
    setAccidentAtWork((state) => ({...state, [name]: value}));

  const handleCreate = () => {
    const errors = addAccidentAtWorkYup(accidentAtWork);

    setErrors(errors);

    if (!!Object.keys(errors).length) return;

    setLoading(true);

    historyService.createAccidentAtWorkHistory(accidentAtWork)
      .subscribe({
        next: () => {
          enqueueSnackbar(t('reporting.accidentsAtWork.add.success'), {variant: 'success'});
          window.dispatchEvent(new Event('RELOAD_ACCIDENT_AT_WORK'));
          handleClose();
        },
        error: err => enqueueSnackbar(err.text, err.options),
      });
  }

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("reporting.accidentsAtWork.add.title")}
      content={(
        <Box mt="15px" width="500px" display="flex" alignItems="flex-start" justifyContent="center">
          <Grid container columnSpacing={2.5} rowSpacing={1.5}>
            <Grid item xs={12}>
              <TextFieldComponent
                label={t("reporting.accidentsAtWork.add.accident")}
                handleChange={updateAccidentAtWork("name")}
                value={accidentAtWork.name || ""}
                error={errors.name}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePickerComponent
                label={t("reporting.accidentsAtWork.add.date")}
                value={accidentAtWork.date}
                handleChange={updateAccidentAtWork("date")}
                error={errors.date}
              />
            </Grid>
            <Grid item xs={6}>
              <TimePickerComponent
                label={t("reporting.accidentsAtWork.add.hour")}
                value={accidentAtWork.hour}
                handleChange={updateAccidentAtWork("hour")}
                error={errors.hour}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldComponent
                label={t("reporting.accidentsAtWork.add.victim")}
                handleChange={updateAccidentAtWork("victim")}
                value={accidentAtWork.victim || ""}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldComponent
                label={t("reporting.accidentsAtWork.add.company")}
                handleChange={updateAccidentAtWork("company")}
                value={accidentAtWork.company || ""}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldComponent
                label={t("reporting.accidentsAtWork.add.location")}
                handleChange={updateAccidentAtWork("location")}
                value={accidentAtWork.location || ""}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldComponent
                label={t("reporting.accidentsAtWork.add.nature")}
                handleChange={updateAccidentAtWork("nature")}
                value={accidentAtWork.nature || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldComponent
                multiline
                rows={3}
                label={t("reporting.accidentsAtWork.add.causes")}
                handleChange={updateAccidentAtWork("causes")}
                value={accidentAtWork.causes || ""}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      actions={(
        <Button variant="contained" color="primary" disabled={loading} onClick={handleCreate}>
          {t("global.saveAndValidate")}
        </Button>
      )}/>
  );
}
