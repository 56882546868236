import React, {useState} from "react";
import {Box, Button, Grid, IconButton, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {finalize} from "rxjs";

import {Role, usersQuery} from "@store/users";
import {CreateProject, PK_INPUT_REGEXP, projectService} from "@store/project";

import dayjs from "@utils/dayjs.utils";

import {step1Validation} from "@screens/auth/OS/projects/createProject/steps/steps.yup";
import {FieldErrors} from "@utils/yup.utils";

import ModalComponent from "@components/modals/Modal.component";
import TextFieldComponent from "@components/textField/TextField.component";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import SelectUserModal from "@screens/auth/common/selectUser/SelectUser.modal";
import SelectComponent from "@components/select/Select.component";
import {ProjectType} from "@store/projects";

interface Step1FormProps {
  goToNextStep: () => void;
  handleClose: () => void;
}

const Step1Form = (props: Step1FormProps) => {
  const {goToNextStep, handleClose} = props;

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [openSelectUser, setOpenSelectUser] = useState<Role | undefined>();

  const [loading, setLoading] = useState(false);

  const [project, setProject] = useState<CreateProject>({navigationScale: 5});
  const [errors, setErrors] = useState<FieldErrors>({});

  const updateProject = (data: CreateProject) => setProject({...project, ...data});

  const handleValidate = () => {
    let errors = step1Validation(project);

    setErrors(errors);
    if (Object.keys(errors).length) return;

    setLoading(true);
    projectService.createProject(project).pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: goToNextStep,
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  }

  return (
    <>
      <ModalComponent
        handleClose={handleClose}
        title={t("projects.create.title")}
        content={(
          <Stack flex={1} width="100%">
            <Stack mt="40px" alignItems="center" spacing={1}>
              <Typography fontSize={16} fontWeight="800">
                {t("global.step", {step: 1})}
              </Typography>
              <Typography fontSize={14} fontWeight="400">
                {t(`projects.create.stepDescription.0`)}
              </Typography>
            </Stack>
            <Box flex={1} width="100%" display="flex" alignItems="flex-start" justifyContent="center">

              <Grid container mt="40px" width="450px" columnSpacing={2.5} rowSpacing={1.5}>
                <Grid item xs={12}>
                  <SelectComponent
                    label={t("projects.create.step1.type")}
                    handleChange={(projectType) => updateProject({projectType})}
                    items={ProjectType.selectItems}
                    value={project.projectType || ""}
                    error={errors.projectType}
                    required/>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldComponent
                    label={t("projects.create.step1.name")}
                    handleChange={(name) => updateProject({name})}
                    value={project.name || ""}
                    error={errors.name}
                    required/>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldComponent
                    label={t("projects.create.step1.lineId")}
                    handleChange={(lineId) => updateProject({lineId})}
                    value={project.lineId || ""}
                    error={errors.lineId}
                    required/>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={t("projects.create.step1.from")}
                    handleChange={(from) => updateProject({from})}
                    value={project.from || ""}
                    error={errors.from}
                    required/>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={t("projects.create.step1.to")}
                    handleChange={(to) => updateProject({to})}
                    value={project.to || ""}
                    error={errors.to}
                    required/>
                </Grid>
                <Grid item xs={6}>
                  <DatePickerComponent
                    label={t("projects.create.step1.startDate")}
                    handleChange={(startDate) => {
                      if (startDate && project.endingDate) {
                        const endingDate = dayjs(startDate).isAfter(project.endingDate) ? startDate : project.endingDate;

                        updateProject({startingDate: startDate, endingDate});
                      } else {
                        updateProject({startingDate: startDate});
                      }
                    }}
                    value={project.startingDate}
                    error={errors.startingDate}
                    required/>
                </Grid>
                <Grid item xs={6}>
                  <DatePickerComponent
                    label={t("projects.create.step1.endDate")}
                    handleChange={(endDate) => {
                      if (endDate && project.startingDate) {
                        const startingDate = dayjs(endDate).isBefore(project.startingDate) ? endDate : project.startingDate;

                        updateProject({endingDate: endDate, startingDate});
                      } else {
                        updateProject({endingDate: endDate});
                      }
                    }}
                    value={project.endingDate}
                    error={errors.endingDate}
                    required/>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={t("projects.create.step1.startPK")}
                    handleChange={(startingKilometerPoint) => updateProject({startingKilometerPoint})}
                    pattern={PK_INPUT_REGEXP}
                    value={project.startingKilometerPoint || ""}
                    error={errors.startingKilometerPoint}
                    required/>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={t("projects.create.step1.endPK")}
                    handleChange={(endingKilometerPoint) => updateProject({endingKilometerPoint})}
                    pattern={PK_INPUT_REGEXP}
                    value={project.endingKilometerPoint || ""}
                    error={errors.endingKilometerPoint}
                    required/>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldComponent
                    label={t("projects.create.step1.navigationScale")}
                    value={project.navigationScale?.toString() || ""}
                    readOnly/>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={t("global.roles.OSC")}
                    handleClick={() => setOpenSelectUser(Role.OS_C)}
                    value={project.os_c ? `${project.os_c.lastname.toUpperCase()} ${project.os_c.firstname}` : ""}
                    endAdornment={project.os_c ? (
                      <IconButton
                        size="small"
                        sx={{marginRight: "-5px"}}
                        onClick={(evt) => {
                          evt.stopPropagation();
                          updateProject({os_c: undefined});
                        }}>
                        <img alt="remove user" src="/images/delete_red_icon.svg"/>
                      </IconButton>
                    ) : undefined}
                    error={errors.os_c}
                    readOnly
                    required/>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={t(`global.roles.${project.projectType === ProjectType.NEW ? "OSRNew" : "OSR"}`)}
                    handleClick={() => setOpenSelectUser(Role.OS_R)}
                    value={project.os_r ? `${project.os_r.lastname.toUpperCase()} ${project.os_r.firstname}` : ""}
                    endAdornment={project.os_r ? (
                      <IconButton
                        size="small"
                        sx={{marginRight: "-5px"}}
                        onClick={(evt) => {
                          evt.stopPropagation();
                          updateProject({os_r: undefined});
                        }}>
                        <img alt="remove user" src="/images/delete_red_icon.svg"/>
                      </IconButton>
                    ) : undefined}
                    error={errors.os_r}
                    readOnly
                    required/>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={t(`global.roles.${project.projectType === ProjectType.NEW ? "RELFNew" : "RELF"}`)}
                    handleClick={() => setOpenSelectUser(Role.RELF)}
                    value={project.relfs?.[0] ? `${project.relfs[0].lastname.toUpperCase()} ${project.relfs[0].firstname}` : ""}
                    endAdornment={project.relfs && project.relfs.length ? (
                      <IconButton
                        size="small"
                        sx={{marginRight: "-5px"}}
                        onClick={(evt) => {
                          evt.stopPropagation();
                          updateProject({relfs: project.relfs!.slice(1, project.relfs!.length)});
                        }}>
                        <img alt="remove user" src="/images/delete_red_icon.svg"/>
                      </IconButton>
                    ) : undefined}
                    error={errors.relfs}
                    readOnly/>
                </Grid>
                {project.relfs && project.relfs.length > 1 && project.relfs.slice(1, project.relfs!.length).map((r) => (
                  <Grid item xs={6} key={r.id}>
                    <TextFieldComponent
                      label={t(`global.roles.${project.projectType === ProjectType.NEW ? "RELFNew" : "RELF"}`)}
                      handleClick={() => undefined}
                      value={r ? `${r.lastname.toUpperCase()} ${r.firstname}` : ""}
                      endAdornment={
                        <IconButton
                          size="small"
                          sx={{marginRight: "-5px"}}
                          onClick={(evt) => {
                            evt.stopPropagation();
                            updateProject({relfs: project.relfs!.filter((relf) => relf.id !== r.id)});
                          }}>
                          <img alt="remove user" src="/images/delete_red_icon.svg"/>
                        </IconButton>
                      }
                      readOnly/>
                  </Grid>
                ))}
                {project.relfs && project.relfs.length > 0 && (
                  <Grid item xs={6} container justifyContent="center" alignContent="center">
                    <Box mt={project.relfs.length % 2 === 1 ? "20px" : undefined}>
                      <Button variant="text" color="primary" size="small" onClick={() => setOpenSelectUser(Role.RELF)}>
                        {t(`projects.create.step1.${project.projectType === ProjectType.NEW ? "addARELFNew" : "addARELF"}`)}
                      </Button>
                    </Box>
                  </Grid>
                )}
                {project.clients && !project.relfs || (project.relfs && project.relfs.length % 2 === 0) && (
                  <Grid item xs={6}/>
                )}
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={t('global.roles.client')}
                    handleClick={() => setOpenSelectUser(Role.CLIENT)}
                    value={project.clients?.[0] ? `${project.clients[0].lastname.toUpperCase()} ${project.clients[0].firstname}` : ""}
                    endAdornment={project.clients && project.clients.length ? (
                      <IconButton
                        size="small"
                        sx={{marginRight: "-5px"}}
                        onClick={(evt) => {
                          evt.stopPropagation();
                          updateProject({clients: project.clients!.slice(1, project.clients!.length)});
                        }}>
                        <img alt="remove user" src="/images/delete_red_icon.svg"/>
                      </IconButton>
                    ) : undefined}
                    error={errors.clients}
                    readOnly/>
                </Grid>
                {project.clients && project.clients.length > 1 && project.clients.slice(1, project.clients!.length).map((r) => (
                  <Grid item xs={6} key={r.id}>
                    <TextFieldComponent
                      label={t('global.roles.client')}
                      handleClick={() => undefined}
                      value={r ? `${r.lastname.toUpperCase()} ${r.firstname}` : ""}
                      endAdornment={
                        <IconButton
                          size="small"
                          sx={{marginRight: "-5px"}}
                          onClick={(evt) => {
                            evt.stopPropagation();
                            updateProject({clients: project.clients!.filter((client) => client.id !== r.id)});
                          }}>
                          <img alt="remove user" src="/images/delete_red_icon.svg"/>
                        </IconButton>
                      }
                      readOnly/>
                  </Grid>
                ))}
                {project.clients && project.clients.length > 0 && (
                  <Grid item xs={6} container justifyContent="center" alignContent="center">
                    <Box mt={project.clients.length % 2 === 1 ? "20px" : undefined}>
                      <Button
                        variant="text"
                        color="primary"
                        size="small"
                        onClick={() => setOpenSelectUser(Role.CLIENT)}>
                        {t('projects.create.step1.addAClient')}
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Stack>
        )}
        actions={(
          <Button variant="contained" color="primary" disabled={loading} onClick={handleValidate}>
            {t("global.create")}
          </Button>
        )}/>
      {!!openSelectUser && (
        <SelectUserModal
          button={t("global.validate")}
          handleAdd={(user) => {
            switch (openSelectUser) {
              case Role.OS_C:
                updateProject({os_c: user});
                break;
              case Role.OS_R:
                updateProject({os_r: user});
                break;
              case Role.RELF:
                updateProject({relfs: (project.relfs || []).concat(user)});
                break;
              case Role.CLIENT:
                updateProject({clients: (project.clients || []).concat(user)});
                break;
            }
          }}
          handleClose={() => setOpenSelectUser(undefined)}
          search={t("projects.create.step1.selectUser.search")}
          getUsersByRole={usersQuery.usersByRole$(openSelectUser)}
          usersDisabled={openSelectUser === Role.RELF ? project.relfs : openSelectUser === Role.CLIENT ? project.clients : undefined}
          title={t("projects.create.step1.selectUser.title", {role: openSelectUser})}/>
      )}
    </>
  );
}

export default Step1Form;
