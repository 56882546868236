import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {DispatchTypeEnum, ZTDispatchType} from "@store/dispatches/dispatch.model";
import _ from "lodash";
import {ID} from "@datorama/akita";

interface checkNoZTCutsZFParams {
  trackElementId?: ID;
  fromDispatch?: boolean;
  pkStart: Pk;
  pkEnd: Pk;
  status?: ZTDispatchType;
  ZFs: TrackElement[];
}

/* Should not be able to set the PKs of a ZT on a ZF but ZT can encompass ZF */
const checkNoZTCutsZF = (params: checkNoZTCutsZFParams): IncompatibilityError[] => {
  const {fromDispatch, pkStart, pkEnd, status, trackElementId, ZFs} = params;

  if (!ZFs.length) return [];

  const ZFonZTs = ZFs.filter(
    (t) =>
      !!t.pkEnd &&
      (Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd) || Pk.isPkBetweenPks(pkEnd, t.pkStart, t.pkEnd)) &&
      !_.isEqual(pkStart, t.pkEnd) &&
      !_.isEqual(pkEnd, t.pkStart)
  );

  if (!fromDispatch || status === DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA) {
    return ZFonZTs.map((ZF) => ({
      error: IncompatibilityErrorEnum.NO_ZT_CUTS_ZF,
      trackElementId,
      concernedTrackElementIds: [ZF.id],
    }));
  }

  return [];
};

export default checkNoZTCutsZF;
