import React, {useState} from "react";
import {Box, Button, Grid, Stack} from "@mui/material";
import {CreateOrUpdateTrackElement, Pk, PK_INPUT_REGEXP, projectQuery, TrackElement} from "@store/project";
import TextFieldComponent from "@components/textField/TextField.component";
import {useTranslation} from "react-i18next";
import {FieldErrors} from "@utils/yup.utils";
import {CreateRZTHandoverModal} from "../../../dispatch/createRZTHandover/CreateRZTHandover.modal";

interface WorkAreaFormProps {
  isCreation?: boolean;
  readOnly?: boolean;
  setTrackElement: (trackElement: CreateOrUpdateTrackElement) => void;
  trackElement: CreateOrUpdateTrackElement;
  errors: FieldErrors;
  IncompatibilitiesAcceptation?: (() => JSX.Element) | null;
}

const WorkAreaForm = (props: WorkAreaFormProps) => {
  const {isCreation, readOnly, trackElement, setTrackElement, errors, IncompatibilitiesAcceptation} = props;

  const {t} = useTranslation();

  const updateTrackElement = (name: string) => (value: any) => setTrackElement({...trackElement, [name]: value});
  const updateAttributes = (name: string) => (value: any) =>
    setTrackElement({
      ...trackElement,
      attributes: {...trackElement.attributes, [name]: value},
    });

  const [showZTHandover, setShowZTHandover] = useState(false);

  const tracks =
    projectQuery.projectTracks.filter((t) => {
      if (trackElement.pkStart && t.pkEnd && Pk.isPkBetweenPks(Pk.fromString(trackElement.pkStart)!, t.pkStart, t.pkEnd)) return true;
      return !!(trackElement.pkEnd && t.pkEnd && Pk.isPkBetweenPks(Pk.fromString(trackElement.pkEnd)!, t.pkStart, t.pkEnd));
    }) || ([] as TrackElement[]);

  return (
    <Stack
      flex={1}
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      pt="20px"
      pb="40px"
      overflow="auto"
      className="scrollable">
      <Grid container width="60%" spacing={2} mx={"auto"}>
        <Grid item xs={12}>
          <TextFieldComponent
            readOnly={readOnly}
            label={t(`schema.workArea.ZTNb`)}
            pattern={new RegExp("^ZT\\d*$")}
            value={trackElement.attributes?.name || "ZT"}
            handleChange={updateAttributes("name")}
            error={errors["attributes.name"]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldComponent readOnly multiline label={t("schema.workArea.tracks")}
                              value={tracks.map((t) => t.attributes.name).join(", ")}/>
        </Grid>
        <Grid item xs={6}>
          <TextFieldComponent
            readOnly={readOnly}
            label={t("schema.workArea.fromPK")}
            handleChange={updateTrackElement("pkStart")}
            pattern={PK_INPUT_REGEXP}
            value={trackElement.pkStart || ""}
            error={errors.pkStart}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldComponent
            readOnly={readOnly}
            label={t("schema.workArea.toPK")}
            handleChange={updateTrackElement("pkEnd")}
            pattern={PK_INPUT_REGEXP}
            value={trackElement.pkEnd || ""}
            error={errors.pkEnd}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldComponent
            readOnly={readOnly}
            label={t("schema.workArea.company")}
            handleChange={updateAttributes("company")}
            value={trackElement.attributes?.company || ""}
            error={errors["attributes.company"]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldComponent
            label={t(`schema.workArea.RZTName`)}
            value={trackElement.attributes?.RZT || ""}
            readOnly={!isCreation || readOnly}
            handleChange={updateAttributes("RZT")}
            endAdornment={!readOnly ? (
              <Box width="fit-content">
                <Button
                  variant="text"
                  color="primary"
                  sx={{whiteSpace: "nowrap"}}
                  onClick={() => setShowZTHandover(true)}>
                  {t(`schema.workArea.updateRZT`)}
                </Button>
              </Box>
            ) : undefined}
            error={errors["attributes.RZT"]}
          />
        </Grid>
      </Grid>
      {IncompatibilitiesAcceptation && (
        <Stack pt="20px">
          <IncompatibilitiesAcceptation/>
        </Stack>
      )}
      {showZTHandover &&
        <CreateRZTHandoverModal handleClose={() => setShowZTHandover(false)} trackElement={trackElement}/>}
    </Stack>
  );
};

export default WorkAreaForm;
