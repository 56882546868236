import {Pk, TrackElement, WorkTrainDirectionEnum} from '@store/project';
import {getZFBlockingElementsOnPks} from '@utils/incompatibilities/common/functions/getZFBlockingElementsOnPks';
import _ from "lodash";

class TreeNode {
  public parent: TreeNode | null;
  public children: TreeNode[] = [];
  public direction: WorkTrainDirectionEnum;
  public track: TrackElement;
  public pkStart: Pk;
  public pkEnd: Pk;
  public hasOneFreeTrack: boolean = false;
  public blockingElements: TrackElement[] = [];

  constructor(parent: TreeNode | null, direction: WorkTrainDirectionEnum, track: TrackElement, pkStart: Pk, pkEnd: Pk) {
    this.parent = parent;
    this.direction = direction;
    this.track = track;
    this.pkStart = pkStart;
    this.pkEnd = pkEnd;

    if (this.parent) this.parent.children.push(this);
  }
}

interface IBuildNodes extends Omit<IGetTrackNodes, "toPkEnd" | "ADVs" | "blockingElementsOnZF"> {
  parent: TreeNode | null;
}

interface IGetTrackNodes {
  currentTrack: TrackElement;
  ADVs: TrackElement[];
  projectTracks: TrackElement[];
  direction: WorkTrainDirectionEnum;
  fromPkStart: Pk;
  toPkEnd: Pk;
  blockingElementsOnZF: TrackElement[];
}

export const getTrackNodes = (getTrackNodesParams: IGetTrackNodes) => {
  const {direction, toPkEnd, ADVs, blockingElementsOnZF} = getTrackNodesParams;

  const isRightToLeft = direction === WorkTrainDirectionEnum.RIGHT_TO_LEFT;

  let remainingADVs = [...ADVs];
  let firstTreeNode: TreeNode | null = null;

  const buildNodes = (buildNodesParams: IBuildNodes) => {
    const {parent, currentTrack, projectTracks, fromPkStart} = buildNodesParams;

    const currentTrackPkEnd = isRightToLeft ? currentTrack.pkStart : currentTrack.pkEnd!;
    const cappedCurrentTrackPkEnd = isRightToLeft ? Pk.max(currentTrackPkEnd, toPkEnd) : Pk.min(currentTrackPkEnd, toPkEnd);

    const currentTrackADVs = remainingADVs.filter((a) => {
      if (!a.pkEnd || !a.tracks.includes(currentTrack.id)) return false;

      const ADVDeparturePk = a.tracks.indexOf(currentTrack.id) === 0 ? a.pkStart : a.pkEnd;
      const ADVArrivalPk = a.tracks.indexOf(currentTrack.id) === 1 ? a.pkStart : a.pkEnd;

      if (isRightToLeft ? Pk.toMeters(ADVDeparturePk) < Pk.toMeters(ADVArrivalPk) : Pk.toMeters(ADVDeparturePk) > Pk.toMeters(ADVArrivalPk))
        return false;

      return isRightToLeft
        ? Pk.isPkBetweenPks(ADVDeparturePk, cappedCurrentTrackPkEnd, fromPkStart)
        : Pk.isPkBetweenPks(ADVDeparturePk, fromPkStart, cappedCurrentTrackPkEnd);
    });

    let closestADV: TrackElement | undefined = undefined;

    for (const adv of currentTrackADVs) {
      const advPK = adv.tracks.indexOf(currentTrack.id) === 0 ? adv.pkStart : adv.pkEnd!;

      const closestADVPk = closestADV?.tracks.indexOf(currentTrack.id) === 0 ? closestADV?.pkStart : closestADV?.pkEnd;

      if (!closestADV) closestADV = adv;
      else if (isRightToLeft && !!closestADVPk && Pk.toMeters(advPK) > Pk.toMeters(closestADVPk)) closestADV = adv;
      else if (!isRightToLeft && !!closestADVPk && Pk.toMeters(closestADVPk) > Pk.toMeters(advPK)) closestADV = adv;
    }

    if (!closestADV) {
      const newTreeNode = new TreeNode(
        parent,
        direction,
        currentTrack,
        isRightToLeft ? cappedCurrentTrackPkEnd : fromPkStart,
        isRightToLeft ? fromPkStart : cappedCurrentTrackPkEnd,
      );

      if (!firstTreeNode) firstTreeNode = newTreeNode;

      const blockingElementsOnNode = blockingElementsOnZF.filter((e) =>
        getZFBlockingElementsOnPks(e, newTreeNode.pkStart, newTreeNode.pkEnd, newTreeNode.track.id)
      );

      if (!blockingElementsOnNode.length) {
        if (newTreeNode === firstTreeNode || Pk.toMeters(newTreeNode.pkEnd) === Pk.toMeters(toPkEnd)) {
          firstTreeNode.hasOneFreeTrack = true;
        }
        return;
      } else {
        firstTreeNode.blockingElements = _.uniq([...firstTreeNode.blockingElements, ...blockingElementsOnNode])
      }
    } else {
      const ADVCurrenTrackIndex = closestADV.tracks.indexOf(currentTrack.id);
      const ADVDeparturePk = ADVCurrenTrackIndex === 0 ? closestADV.pkStart : closestADV.pkEnd!;
      const ADVArrivalPk = ADVCurrenTrackIndex === 1 ? closestADV.pkStart : closestADV.pkEnd!;

      remainingADVs = remainingADVs.filter((a) => a.id !== closestADV!.id);

      const newTreeNode = new TreeNode(
        parent,
        direction,
        currentTrack,
        isRightToLeft ? ADVDeparturePk : fromPkStart,
        isRightToLeft ? fromPkStart : ADVDeparturePk,
      );

      if (!firstTreeNode) firstTreeNode = newTreeNode;

      const blockingElementsOnNode = blockingElementsOnZF.filter((e) =>
        getZFBlockingElementsOnPks(e, newTreeNode.pkStart, newTreeNode.pkEnd, newTreeNode.track.id)
      );

      if (!blockingElementsOnNode.length) {
        if (Pk.toMeters(newTreeNode.pkEnd) === Pk.toMeters(toPkEnd)) {
          firstTreeNode.hasOneFreeTrack = true;
          return;
        }
      } else {
        firstTreeNode.blockingElements = _.uniq([...firstTreeNode.blockingElements, ...blockingElementsOnNode])
        return;
      }

      // Continue on the same track if it ends after the ADV departure
      if (
        isRightToLeft ? Pk.toMeters(currentTrackPkEnd) < Pk.toMeters(ADVDeparturePk) : Pk.toMeters(currentTrackPkEnd) > Pk.toMeters(ADVDeparturePk)
      ) {
        buildNodes({parent: newTreeNode, currentTrack, projectTracks, direction, fromPkStart: ADVDeparturePk});
      }

      // Switch Track to the other side of the ADV
      const nextTrack = projectTracks.find((t) =>
        t.id === closestADV!.tracks[ADVCurrenTrackIndex === 0 ? 1 : 0]
      );
      if (!nextTrack) return;

      buildNodes({parent: newTreeNode, currentTrack: nextTrack, projectTracks, direction, fromPkStart: ADVArrivalPk});
    }
  };

  buildNodes({parent: null, ...getTrackNodesParams});

  return firstTreeNode!;
};