import {GridColumns} from "@mui/x-data-grid";

import {allDispatchesTypes, Dispatch} from "@store/dispatches/dispatch.model";

import I18n from "@utils/i18n.utils";
import {TrackElementType} from "@store/project";
import i18n from "i18next";
import {Tooltip} from "@mui/material";

export const dispatchHistoryColumns = (isProjectNew?: boolean) =>
  [
    {
      field: "category",
      headerName: I18n.t("dispatches.table.category"),
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.type === "FREE_DISPATCH"
          ? i18n.t("dispatches.eventTypes.free")
          : TrackElementType.getRELFElementLabel(
            params.row.order?.trackElement?.type
          );
      },
      align: "center",
      width: 250,
    },
    {
      field: "type",
      headerName: I18n.t("dispatches.table.nature"),
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.type === "FREE_DISPATCH"
          ? params.row.content.name
          : allDispatchesTypes.find(
          (it) => it.value === params.value
        )?.label || "";
      },
      align: "center",
      flex: 1.5,
      renderCell: (params) => (
        <Tooltip title={params.value ?? ""}>
        <span style={{
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center'
        }}>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "trackElementName",
      headerName: I18n.t("dispatches.table.trackElementName"),
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        return params.row.type === "FREE_DISPATCH"
          ? params.row.content.description
          : params.row.order?.trackElement?.attributes?.name;
      },
      flex: 1.5,
      renderCell: (params) => (
        <Tooltip title={params.value ?? ""}>
        <span style={{
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center'
        }}>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "workZone",
      headerName: I18n.t("dispatches.table.zone"),
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.dispatch?.workZone?.attributes?.name,
      width: 150,
    },
    {
      field: "responsibleName",
      headerName: I18n.t(`dispatches.table.interlocutor`),
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueGetter: (params) => {
        if (params.row.type === "FREE_DISPATCH") return "-";

        if (!params.row.dispatch[Dispatch.getInterlocutor(params.row.type)]) return "-";

        return `${
          params.row.dispatch[Dispatch.getInterlocutor(params.row.type)]
        } - ${TrackElementType.getInterlocutorFunctionLabel(
          params.row.order.trackElement?.type,
          isProjectNew ?? false
        )}`;
      }
    },
    {
      field: "relf",
      headerName: I18n.t(
        `dispatches.table.${isProjectNew ? "relfNew" : "relf"}`
      ),
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        params.row.creator
          ? `${params.row.creator.firstname} ${params.row.creator.lastname?.toUpperCase()}`
          : "-",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: I18n.t(
        `dispatches.table.${isProjectNew ? "dispatchDateNew" : "dispatchDateOld"}`
      ),
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) =>
        params.value
          ? I18n.t("dispatches.table.day", {
            date: new Date(params.value),
          })
          : "-",
      width: 150,
    },
    {
      field: "actionDate",
      headerName: I18n.t("dispatches.table.actionDate"),
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.dispatch?.[Dispatch.getActionDate(params.row.type)],
      valueFormatter: (params) =>
        params.value
          ? I18n.t("dispatches.table.day", {
            date: new Date(params.value),
          })
          : "-",
      width: 150,
    },
  ] as GridColumns;
