import {
  CreateADVAcquittal,
  CreateHistoryCommon,
  CreatePeopleCountingHistory,
  CreatePNAcquittal,
  CreateRZTHandover,
  CreateStopSignAcquittal,
} from "@store/dispatches/dispatch.model";
import APIAxios, {APIRoutes} from "@api/axios.api";
import {catchError, from, map, Observable, switchMap} from "rxjs";
import {AxiosError, AxiosResponse} from "axios";
import SnackError from "@utils/error.utils";
import {ordersService} from "@store/dispatches/orders.service";
import {dispatchFiltersStore} from "@store/dispatches/filters.store";
import {CreateAccidentAtWork} from "@store/reporting";
import {CreateOrUpdateTrackElement, projectService, TrackElement} from "@store/project";
import {sessionService} from "@store/session";
import i18n from "@utils/i18n.utils";
import {mergeDateAndHour} from '@utils/date.utils';

export class HistoryService {
  createCommonHistory = (history: CreateHistoryCommon) => {
    return from(APIAxios({...APIRoutes.POSTHistoryCommon(), data: history})).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<any>) => {
        return response.data;
      }),
      switchMap(() => ordersService.getTrackElementHistory(history.projectId, 1, dispatchFiltersStore.getValue()))
    );
  };

  createDispatchHistory = (history: CreateHistoryCommon) => {
    return from(APIAxios({...APIRoutes.POSTHistoryDispatch(), data: history})).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<any>) => {
        return response.data;
      }),
      switchMap(() => ordersService.getDispatchHistory(history.projectId, 1, dispatchFiltersStore.getValue()))
    );
  };

  createPeopleCountingHistory = (history: CreatePeopleCountingHistory): Observable<AxiosResponse> => {
    return from(APIAxios({...APIRoutes.POSTHistoryPeopleCounting(), data: history})).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      })
    );
  };

  createAccidentAtWorkHistory = (history: CreateAccidentAtWork): Observable<AxiosResponse> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTHistoryAccidentsAtWork(),
        data: {...history, date: mergeDateAndHour(history.date, history.hour)?.toISOString() ?? undefined},
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      })
    );
  };

  createRZTHandover = (code: string, data: CreateRZTHandover, trackElement: CreateOrUpdateTrackElement): Observable<TrackElement[]> => {
    const formattedData: CreateHistoryCommon = {
      projectId: data.projectId,
      name: `Passation de RZT de la ${trackElement.attributes?.name}`,
      description: `${data.RELF}, RELF, confirme la passation entre ${data.fromRZT}, RZT cédant, et ${data.toRZT}, RZT prenant.`,
      newRZT: data.toRZT,
      dateTime: mergeDateAndHour(data.date, data.hour),
    };

    return sessionService.confirmIdentificationCode(code).pipe(
      switchMap(() =>
        from(
          APIAxios({
            ...APIRoutes.POSTRZTHandoverHistory(trackElement.id!),
            data: {...formattedData, dateTime: formattedData.dateTime?.toISOString()},
          })
        )
      ),
      map((response: AxiosResponse) => {
        return response.data;
      }),
      switchMap(() => projectService.getProjectTrackElements())
    );
  };

  createADVAcquittal = (code: string, data: CreateADVAcquittal, trackElement: CreateOrUpdateTrackElement): Observable<TrackElement[]> => {
    const formattedData: CreateHistoryCommon = {
      projectId: data.projectId,
      name: `${i18n.t("schema.trackDevice.ADVAcquittal.TTxPassing")} ${data.TTxNbr} à ${data.advName}`,
      description: `${data.RELF}, ${i18n.t("schema.trackDevice.ADVAcquittal.RELF")}, ${i18n.t("schema.trackDevice.ADVAcquittal.attest")} ${
        data.TTxNbr
      } ${i18n.t("schema.trackDevice.ADVAcquittal.atADV")} ${data.advName} ${i18n.t("schema.trackDevice.ADVAcquittal.RZTConfirm")} ${
        data.fromRZT
      } ${i18n.t("schema.trackDevice.ADVAcquittal.RZT")}.`,
      dateTime: mergeDateAndHour(data.date, data.hour),
      TTxId: data.TTxId,
    };

    return sessionService.confirmIdentificationCode(code).pipe(
      switchMap(() =>
        from(
          APIAxios({
            ...APIRoutes.POSTADVAcquittalHistory(trackElement.id!),
            data: {...formattedData, dateTime: formattedData.dateTime?.toISOString()},
          })
        )
      ),
      map((response: AxiosResponse) => {
        return response.data;
      }),
      switchMap(() => projectService.getProjectTrackElements())
    );
  };

  createPNAcquittal = (code: string, data: CreatePNAcquittal, trackElement: CreateOrUpdateTrackElement): Observable<TrackElement[]> => {
    const formattedData: CreateHistoryCommon = {
      projectId: data.projectId,
      name: `${i18n.t("schema.pn.PNAcquittal.TTxPassing")} ${data.TTxNbr} à ${data.pnName}`,
      description: `${data.RELF}, ${i18n.t("schema.pn.PNAcquittal.RELF")}, ${i18n.t("schema.pn.PNAcquittal.attest")} ${data.TTxNbr} ${i18n.t(
        "schema.pn.PNAcquittal.atPN"
      )} ${data.pnName} ${i18n.t("schema.pn.PNAcquittal.APNConfirm")} ${data.fromAPN} ${i18n.t("schema.pn.PNAcquittal.APN")}.`,
      dateTime: mergeDateAndHour(data.date, data.hour),
      TTxId: data.TTxId,
    };

    return sessionService.confirmIdentificationCode(code).pipe(
      switchMap(() =>
        from(
          APIAxios({
            ...APIRoutes.POSTPNAcquittalHistory(trackElement.id!),
            data: {...formattedData, dateTime: formattedData.dateTime?.toISOString()},
          })
        )
      ),
      map((response: AxiosResponse) => {
        return response.data;
      }),
      switchMap(() => projectService.getProjectTrackElements())
    );
  };

  createStopSignAcquittal = (code: string, data: CreateStopSignAcquittal, trackElement: CreateOrUpdateTrackElement): Observable<TrackElement[]> => {
    const formattedData: CreateHistoryCommon = {
      projectId: data.projectId,
      name: `${i18n.t("schema.signalisation.stopSignAcquittal.TTxPassing")} ${data.TTxNbr} ${i18n.t('schema.signalisation.stopSignAcquittal.atTheStopSignPk')} ${trackElement.pkStart}`,
      description: `${data.RELF}, ${i18n.t("schema.signalisation.stopSignAcquittal.RELF")}, ${i18n.t("schema.signalisation.stopSignAcquittal.authorizeATTX")} ${
        data.ATTX
      } ${i18n.t("schema.signalisation.stopSignAcquittal.passStopSignPk")} ${data.stopSignPk} ${i18n.t(
        "schema.signalisation.stopSignAcquittal.theTTx"
      )} ${data.TTxNbr}.`,
      dateTime: mergeDateAndHour(data.date, data.hour),
      TTxId: data.TTxId,
    };

    return sessionService.confirmIdentificationCode(code).pipe(
      switchMap(() =>
        from(
          APIAxios({
            ...APIRoutes.POSTStopSignAcquittalHistory(trackElement.id!),
            data: {...formattedData, dateTime: formattedData.dateTime?.toISOString()},
          })
        )
      ),
      map((response: AxiosResponse) => {
        return response.data;
      }),
      switchMap(() => projectService.getProjectTrackElements())
    );
  };
}

export const historyService = new HistoryService();
