export const Colors = {
  primary: "#0E2B73",
  secondaryYellow: "#EDC041",
  secondaryRed: "#ED3939",
  secondaryBlue: "#4DABB4",
  secondaryGreen: "#B9CA35",
  black: "#000000",
  white: "#FFFFFF",
  background: "#F5F7F9",
  inputBackground: "#F6F6F6",
  text: "#2C2C2C",

  schema: {
    pkTooltip: "#E0E0E0",
    track: "#8695B9",
    halfOpacityTrack: "#DBDFEA",
    declivity: "#0E2B73",

    elements: {
      wedge: "#B9CA35",
      trainStation: "#0E2B73",
      construction: "#6E777C",
      PN: "#4DABB4",
      PRS: "#ED3939",
      PRA: "#8695B9",
      PRO: "#8695B9",
      buffer: "#07739B",
      tunnel: "#6C6245",
      viaduct: "#6C6245",
      stopper: "#9D239F",
      instOther: "#146900",
      whistle: "#E2743A",
      trainingZone: "#BEBDBD",
      pnState: {
        darkGreen: "#146900",
        paleGreen: "#6eab60",
        yellow: "#EDC041",
        orange: "#E2743A"
      },
      workZone: {
        notice: "#B9CA35",
        authorization: "#EDC041",
        request: "#E2743A",
        default: "#F1BA9D",
      },
      workTrain: {
        schema: "#622827",
        waiting: "#ED3939",
      },
      activity: "#526FB2",
      restriction: {
        color1: "#000000",
        color2: "#ED3939"
      },
      worksiteMachine: "#B9CA35",
      worksite: "#9D239F"
    },
  },
};

export const Sizes = {
  leftBar: 50,
};
