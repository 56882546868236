import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {DispatchTypeEnum, PNDispatchType} from "@store/dispatches/dispatch.model";
import {ID} from "@datorama/akita";

interface CheckClosePNInZTParams {
  fromDispatch?: boolean;
  pkStart: Pk;
  status?: PNDispatchType;
  ZTs: TrackElement[];
  trackElementId?: ID;
}

/* Should not be able to close PN if not in a ZT */
const checkClosePNInZT = (params: CheckClosePNInZTParams): IncompatibilityError[] => {
  const {fromDispatch, trackElementId, pkStart, status, ZTs} = params;

  if (status) {
    const ZTsOnPN = ZTs.filter((t) => !!t.pkEnd && Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd));

    const PNIsClosing = fromDispatch
      ? [DispatchTypeEnum.PN_REQUEST_TO_CLOSE_PN, DispatchTypeEnum.PN_AUTHORIZATION_TO_CLOSE_PN, DispatchTypeEnum.PN_NOTICE_TO_CLOSE_PN].includes(
          status
        )
      : [DispatchTypeEnum.PN_REQUEST_TO_CLOSE_PN, DispatchTypeEnum.PN_AUTHORIZATION_TO_CLOSE_PN].includes(status);

    if (PNIsClosing && !ZTsOnPN.length) {
      return [
        {
          error: IncompatibilityErrorEnum.CLOSE_PN_IN_ZT,
          trackElementId,
          concernedTrackElementIds: [],
        },
      ];
    }
  }

  return [];
};

export default checkClosePNInZT;
