import React from "react";
import {DatePicker} from "@mui/lab";
import {InputLabel, TextField} from "@mui/material";

interface DatePickerProps {
	color?: "primary" | "secondary";
	disableFuture?: boolean;
	disablePast?: boolean;
	error?: string;
	format?: string;
	handleChange?: (date?: Date) => void;
	label?: string;
	maxDate?: Date;
	minDate?: any;
	placeholder?: string;
	readOnly?: boolean;
	required?: boolean;
	value?: Date;
}

const DatePickerComponent = (props: DatePickerProps) => {
  const {
    color = "primary",
    disableFuture,
    disablePast,
    error,
    format,
    handleChange,
    label,
    maxDate,
    minDate,
    placeholder,
    readOnly,
    required,
    value
  } = props;

  return (
    <DatePicker
      maxDate={maxDate}
      minDate={minDate}
      readOnly={readOnly}
      disablePast={disablePast}
      disableFuture={disableFuture}
      value={value || null}
      inputFormat={format || "DD/MM/YYYY"}
      onChange={(date) => handleChange?.(date || undefined)}
      renderInput={(params) => (
        <>
          {!!label && <InputLabel error={!!error}>{label}{(required) ? '*' : ''}</InputLabel>}
          <TextField
            {...params}
            focused={readOnly ? false : undefined}
            InputProps={{
              ...params.InputProps,
              endAdornment: <img alt="" src="/images/calendar_icon.svg"/>,
            }}
            error={!!error}
            helperText={error}
            fullWidth
            inputProps={{
              ...params.inputProps,
              readOnly,
              placeholder: placeholder || label,
            }}
            color={color}/>
        </>
      )}/>
  );
}

export default DatePickerComponent;
