import React, {useEffect, useState} from "react";
import ModalComponent from "@components/modals/Modal.component";
import OpeningNoticeWorkAreaForm from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningNoticeWorkAreaForm";
import ClosingWorkAreaForm from "@screens/auth/common/schema/forms/dispatches/workArea/ClosingWorkArea.form";
import {useTranslation} from "react-i18next";
import {
  CreateDispatch,
  Dispatch,
  DispatchHistory,
  DispatchTypeEnum,
  installationPnDispatchTypes,
  trackChangeDispatchTypes,
  trainingZoneDispatchTypes,
  workAreaDispatchTypes,
  worksiteMachineDispatchTypes,
  workTrainDispatchTypes,
} from "@store/dispatches/dispatch.model";
import OpeningRequestWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import OpeningAuthorizationWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningAuthorizationWorkAreaForm";
import RestartingStandbyAuthorizationWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/RestartingStandbyAuthorizationWorkAreaForm";
import StandbySetWorkArea from "@screens/auth/common/schema/forms/dispatches/workArea/StandbySetWorkArea.form";
import {useNavigate, useParams} from "react-router-dom";
import RestartingStandbyRequestWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/RestartingStandbyRequestWorkAreaForm";
import ModificationRequestWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/ModificationRequestWorkAreaForm";
import ModificationAuthorizationWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/ModificationAuthorizationWorkAreaForm";
import ModificationNoticeWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/ModificationNoticeWorkAreaForm";
import {useSnackbar} from "notistack";
import {ordersService} from "@store/dispatches/orders.service";
import AccordionComponent from "@components/accordion/Accordion.component";
import {Stack} from "@mui/material";
import SplittingForm from "@screens/auth/common/schema/forms/dispatches/workTrain/Splitting.form";
import PostSplittingForm from "@screens/auth/common/schema/forms/dispatches/workTrain/PostSplitting.form";
import GroupingForm from "@screens/auth/common/schema/forms/dispatches/workTrain/Grouping.form";
import MovementDeliveryRouteForm
  from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementDeliveryRoute.form";
import MovementEngagementForm from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementEngagement.form";
import MovementReleaseForm from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementRelease.form";
import MovementRemovalForm from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementRemoval.form";
import MovementResetForm from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementReset.form";
import MovementImmobilizationTTxForm
  from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementImmobilizationTTx.form";
import MovementTransferForm from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementTransfer.form";
import PnTerminationForm from "@screens/auth/common/schema/forms/dispatches/pn/PnTermination.form";
import PnRecoveryMonitoringForm from "@screens/auth/common/schema/forms/dispatches/pn/PnRecoveryMonitoring.form";
import PnRequestToClosePnForm from "@screens/auth/common/schema/forms/dispatches/pn/PnRequestToClosePn.form";
import PnAuthorizationToClosePnForm
  from "@screens/auth/common/schema/forms/dispatches/pn/PnAuthorizationToClosePn.form";
import PnNoticeToClosePnForm from "@screens/auth/common/schema/forms/dispatches/pn/PnNoticeToClosePn.form";
import PnRequestToReopenPnForm from "@screens/auth/common/schema/forms/dispatches/pn/PnRequestToReopenPn.form";
import PnAuthorizationToReopenPnForm
  from "@screens/auth/common/schema/forms/dispatches/pn/PnAuthorizationToReopenPn.form";
import PnConfirmReopenPnForm from "@screens/auth/common/schema/forms/dispatches/pn/PnConfirmReopenPn.form";
import PnAttestReopenedForm from "@screens/auth/common/schema/forms/dispatches/pn/PnAttestReopened.form";
import AuthorizationTrainingZoneForm
  from "@screens/auth/common/schema/forms/dispatches/trainingZone/AuthorizationTrainingZone.form";
import ModificationTrainingZoneForm
  from "@screens/auth/common/schema/forms/dispatches/trainingZone/ModificationTrainingZone.form";
import ConfirmationInitialDemarcationTrainingZoneForm
  from "@screens/auth/common/schema/forms/dispatches/trainingZone/ConfirmationInitialDemarcationTrainingZone.form";
import EndMissionTrainingZoneForm
  from "@screens/auth/common/schema/forms/dispatches/trainingZone/EndMissionTrainingZone.form";
import MovementWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/MovementWorksiteMachine.form";
import EndMovementWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/EndMovementWorksiteMachine.form";
import UnenrailementWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/UnenrailementWorksiteMachine.form";
import EnrailementWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/EnrailementWorksiteMachine.form";
import EngagementWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/EngagementWorksiteMachine.form";
import ReleaseWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/ReleaseWorksiteMachine.form";
import ImmobilizationWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/ImmobilizationWorksiteMachine.form";
import ManeuverTrackChangeForm from "@screens/auth/common/schema/forms/dispatches/trackChange/ManeuverTrackChange.form";
import {FreeDispatchForm} from "../../schema/forms/dispatches/freeDispatch/FreeDispatch.form";
import MovementEngagementArrivalForm
  from '@screens/auth/common/schema/forms/dispatches/workTrain/MovementEngagementArrival.form';
import MovementEngagementAuthorizationForm
  from '@screens/auth/common/schema/forms/dispatches/workTrain/MovementEngagementAuthorization.form';

const DispatchDetail = () => {
  const {dispatchId, orderId} = useParams();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const [dispatch, setDispatch] = useState<Dispatch>();
  const [freeDispatch, setFreeDispatch] = useState<DispatchHistory>();

  useEffect(() => {
    if (orderId && orderId !== "free-dispatch" && dispatchId) {
      ordersService.getDispatchesByOrder(orderId).subscribe({
        next: (dispatches) => {
          setDispatch(dispatches.find((d) => d.id === dispatchId) || undefined);
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    } else if (dispatchId) {
      ordersService.getFreeDispatchDetails(dispatchId).subscribe({
        next: (dispatch) => {
          setFreeDispatch(dispatch);
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  }, [dispatchId, enqueueSnackbar, orderId]);

  const {t} = useTranslation();

  const dispatchFormSelected = (dispatch: CreateDispatch) => {
    switch (dispatch.type) {
      case DispatchTypeEnum.WORK_ZONE_REQUEST_TO_OPEN_A_WORK_AREA:
        return (
          <OpeningRequestWorkAreaForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_OPEN_A_WORK_AREA:
        return (
          <OpeningAuthorizationWorkAreaForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.WORK_ZONE_NOTICE_OF_OPENING_OF_A_WORK_AREA:
        return (
          <OpeningNoticeWorkAreaForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_RESTARTING_A_WORK_AREA_IN_STANDBY:
        return (
          <RestartingStandbyRequestWorkAreaForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_RESTARTING_A_WORK_AREA_IN_STANDBY:
        return (
          <RestartingStandbyAuthorizationWorkAreaForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.WORK_ZONE_CLOSURE_OF_A_WORK_AREA:
        return (
          <ClosingWorkAreaForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.WORK_ZONE_SET_STANDBY_WORK_AREA:
        return (
          <StandbySetWorkArea
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA:
        return (
          <ModificationRequestWorkAreaForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_MODIFY_THE_WORK_AREA:
        return (
          <ModificationAuthorizationWorkAreaForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.WORK_ZONE_NOTICE_OF_MODIFICATION_OF_THE_WORK_AREA:
        return (
          <ModificationNoticeWorkAreaForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.SPLITTING_SPLITTING:
        return (
          <SplittingForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.SPLITTING_POST_SPLITTING:
        return (
          <PostSplittingForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.SPLITTING_GROUPING:
        return (
          <GroupingForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE:
        return (
          <MovementDeliveryRouteForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.MOVEMENT_ENGAGEMENT:
        return (
          <MovementEngagementForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.MOVEMENT_ENGAGEMENT_AUTHORIZATION:
        return (
          <MovementEngagementAuthorizationForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.MOVEMENT_ENGAGEMENT_ARRIVAL:
        return (
          <MovementEngagementArrivalForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.MOVEMENT_RELEASE:
        return (
          <MovementReleaseForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.MOVEMENT_REMOVAL:
        return (
          <MovementRemovalForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.MOVEMENT_RESET:
        return (
          <MovementResetForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.MOVEMENT_IMMOBILIZATION_TTX:
        return (
          <MovementImmobilizationTTxForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.MOVEMENT_TRANSFER:
        return (
          <MovementTransferForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.PN_TERMINATION:
        return (
          <PnTerminationForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.PN_RECOVERY_MONITORING:
        return (
          <PnRecoveryMonitoringForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.PN_REQUEST_TO_CLOSE_PN:
        return (
          <PnRequestToClosePnForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.PN_AUTHORIZATION_TO_CLOSE_PN:
        return (
          <PnAuthorizationToClosePnForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.PN_NOTICE_TO_CLOSE_PN:
        return (
          <PnNoticeToClosePnForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.PN_REQUEST_TO_REOPEN_PN:
        return (
          <PnRequestToReopenPnForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.PN_AUTHORIZATION_TO_REOPEN_PN:
        return (
          <PnAuthorizationToReopenPnForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.PN_NOTICE_TO_REOPEN_PN:
        return (
          <PnConfirmReopenPnForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.PN_ATTEST_REOPENED:
        return (
          <PnAttestReopenedForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.AUTHORIZATION_TRAINING_ZONE:
        return (
          <AuthorizationTrainingZoneForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.MODIFICATION_TRAINING_ZONE:
        return (
          <ModificationTrainingZoneForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.CONFIRMATION_INITIAL_DEMARCATION_TRAINING_ZONE:
        return (
          <ConfirmationInitialDemarcationTrainingZoneForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.END_MISSION_TRAINING_ZONE:
        return (
          <EndMissionTrainingZoneForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.MOVEMENT_WORKSITE_MACHINE:
        return (
          <MovementWorksiteMachineForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.END_MOVEMENT_WORKSITE_MACHINE:
        return (
          <EndMovementWorksiteMachineForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.UNENRAILMENT_WORKSITE_MACHINE:
        return (
          <UnenrailementWorksiteMachineForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.ENRAILMENT_WORKSITE_MACHINE:
        return (
          <EnrailementWorksiteMachineForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.ENGAGEMENT_WORKSITE_MACHINE:
        return (
          <EngagementWorksiteMachineForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.RELEASE_WORKSITE_MACHINE:
        return (
          <ReleaseWorksiteMachineForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.IMMOBILIZATION_WORKSITE_MACHINE:
        return (
          <ImmobilizationWorksiteMachineForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
      case DispatchTypeEnum.MANEUVER_TRACK_CHANGE:
        return (
          <ManeuverTrackChangeForm
            setDispatch={() => undefined}
            dispatch={dispatch}
            editable={false}
          />
        );
    }
  };

  const dispatchTitle = (dispatch: Dispatch) => {
    return (
      workAreaDispatchTypes
        .concat(workTrainDispatchTypes)
        .concat(installationPnDispatchTypes)
        .concat(trainingZoneDispatchTypes)
        .concat(worksiteMachineDispatchTypes)
        .concat(trackChangeDispatchTypes)
        .find((dt) => {
          return dt.value === dispatch.type;
        })?.label || ""
    );
  };

  const handleClose = () => navigate("..");

  if (freeDispatch) {
    return (
      <ModalComponent
        title=""
        handleClose={handleClose}
        fullWidth
        content={
          <Stack pt="15px" width="100%">
            <AccordionComponent
              variant="dispatch"
              expended
              title={"Dépêche libre"}
              content={<FreeDispatchForm dispatch={freeDispatch}/>}
            />
          </Stack>
        }
      />
    );
  }

  if (!dispatch?.dispatch) {
    return null;
  }

  return (
    <ModalComponent
      title=""
      handleClose={handleClose}
      fullWidth
      content={
        <Stack pt="15px">
          <AccordionComponent
            variant="dispatch"
            expended
            title={dispatchTitle(dispatch)}
            description={t("global.dateAndHours", {
              date: new Date(dispatch.createdAt),
            })}
            content={dispatchFormSelected(
              CreateDispatch.fromDispatch(dispatch)
            )}
          />
        </Stack>
      }
    />
  );
};

export default DispatchDetail;
