import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {ID} from '@datorama/akita';
import _ from 'lodash';

interface checkTTXOnlyAtStartOrEndOfTrackParams {
  trackElementId?: ID;
  trackIds: ID[];
  pkStart: Pk;
  length: number;
  tracks: TrackElement[];
}

/* Should not be able to put on track a TTX if it is not at the start or end of a track */
const checkTTXOnlyAtStartOrEndOfTrack = (params: checkTTXOnlyAtStartOrEndOfTrackParams): IncompatibilityError[] => {
  const {trackElementId, trackIds, pkStart, length, tracks} = params;

  const pkEnd = Pk.addMeters(pkStart, +length);

  const currentTTxTrack = tracks.find((t) => t.id === trackIds[0]);

  if (currentTTxTrack && !_.isEqual(pkStart, currentTTxTrack.pkStart) && !_.isEqual(pkEnd, currentTTxTrack.pkEnd)) {
    return [{
      error: IncompatibilityErrorEnum.TTX_ONLY_AT_START_OR_END_OF_TRACK,
      trackElementId,
      concernedTrackElementIds: [currentTTxTrack.id],
    }];
  } else if (!currentTTxTrack) {
    return [{
      error: IncompatibilityErrorEnum.TTX_ONLY_AT_START_OR_END_OF_TRACK,
      trackElementId,
      concernedTrackElementIds: [],
    }];
  }

  return [];
}

export default checkTTXOnlyAtStartOrEndOfTrack;