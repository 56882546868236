import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import ModalComponent from "@components/modals/Modal.component";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {Box, Button, Grid} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import {CreateHistoryCommon} from "@store/dispatches/dispatch.model";
import {createHistoryCommon} from "@screens/auth/common/dispatch/createHistoryCommon/createHistoryCommon.yup";
import {projectQuery} from "@store/project";
import {FieldErrors} from "@utils/yup.utils";
import {historyService} from "@store/history";

export const CreateHistoryCommonModal = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const [historyCommon, setHistoryCommon] = useState<CreateHistoryCommon>({projectId: projectQuery.projectId})
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<FieldErrors>({});

  const handleClose = () => navigate("..");

  const updateHistory = (name: string) => (value: any) => setHistoryCommon({...historyCommon, [name]: value});

  const handleCreate = () => {
    const errors = createHistoryCommon(historyCommon);

    setErrors(errors);

    if (!!Object.keys(errors).length) return;

    setLoading(true);

    historyService.createCommonHistory(historyCommon)
      .subscribe({
        next: () => {
          enqueueSnackbar(t('dispatches.createHistoryCommon.success'), {variant: 'success'});
          window.dispatchEvent(new Event('RELOAD_TRACK_HISTORY'));
          handleClose();
        },
        error: err => enqueueSnackbar(err.text, err.options),
      });
  }

  return <ModalComponent handleClose={handleClose} title={t("dispatches.createHistoryCommon.title")} content={(
    <Box mt="15px" width="450px" display="flex" alignItems="flex-start" justifyContent="center">
      <Grid container columnSpacing={2.5} rowSpacing={1.5}>
        <Grid item xs={12}>
          <TextFieldComponent
            label={t("dispatches.createHistoryCommon.fields.name")}
            handleChange={updateHistory("name")}
            value={historyCommon.name || ""}
            error={errors.name}
            required/>
        </Grid>
        <Grid item xs={12}>
          <TextFieldComponent
            label={t("dispatches.createHistoryCommon.fields.description")}
            rows={3}
            multiline
            handleChange={updateHistory("description")}
            value={historyCommon.description || ""}
          />
        </Grid>
      </Grid>
    </Box>
  )} actions={(
    <Button variant="contained" color="primary" disabled={loading} onClick={handleCreate}>
      {t("dispatches.createHistoryCommon.fields.confirm")}
    </Button>
  )}/>
}
