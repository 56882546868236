import {arrayAdd, arrayRemove, arrayUpsert, ID, Store, StoreConfig} from "@datorama/akita";

import {Project} from "./project.model";
import {TrackElement} from "@store/project/schema.model";
import {User} from '@store/users';

const initProject = (): Project => {
  return Project.create();
}

@StoreConfig({name: "project", resettable: true})
export class ProjectStore extends Store<Project> {
  constructor() {
    super(initProject());
  }

  upsertManyCustomer = (customersToUpsert: User[]) => {
    let newCustomers = [...this.getValue().customers];

    customersToUpsert.forEach((customer) => {
      newCustomers = arrayUpsert(newCustomers, customer.id, customer);
    });

    this.update({customers: newCustomers});
  };

  deleteCustomer = (customerId: ID) => {
    this.update({customers: arrayRemove(this.getValue().customers, customerId)});
  };

  addTrackElement = (trackElement: TrackElement) => {
    this.update({trackElements: arrayAdd(this.getValue().trackElements, trackElement)});
  };

  upsertTrackElement = (trackElement: TrackElement) => {
    this.update({trackElements: arrayUpsert(this.getValue().trackElements, trackElement.id, trackElement)});
  };

  deleteTrackElement = (trackElementId: ID) => {
    this.update({trackElements: arrayRemove(this.getValue().trackElements, trackElementId)});
  };

  upsertManyTrackElements = (trackElementsToUpsert: TrackElement[]) => {
    let newTrackElements = [...this.getValue().trackElements];

    trackElementsToUpsert.forEach((trackElement) => {
      newTrackElements = arrayUpsert(newTrackElements, trackElement.id, trackElement);
    });

    this.update({trackElements: newTrackElements});
  };
}

export const projectStore = new ProjectStore();
