import {alpha, PaletteOptions} from "@mui/material";

import {Colors} from "@utils/theme/constants.utils";

const palette: PaletteOptions = {
	primary: {
		main: Colors.primary,
	},
	secondary: {
		main: alpha(Colors.primary, 0.1),
	},
	warning: {
		main: Colors.secondaryYellow,
	},
	info: {
		main: Colors.secondaryBlue,
	},
	success: {
		main: Colors.secondaryGreen,
	},
	error: {
		main: Colors.secondaryRed,
	},
	text: {
		primary: Colors.text,
	},
	background: {
		default: Colors.background,
		paper: Colors.white,
	},
};

export default palette;
