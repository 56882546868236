import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, IconButton, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

import {projectQuery, TrackCursor, TrackElementCategory} from "@store/project";
import {Role} from "@store/users";
import {sessionQuery} from "@store/session";

import ModalComponent from "@components/modals/Modal.component";
import CreateTrackElementForm from "@screens/auth/common/schema/forms/trackElements/CreateTrackElement.form";

interface SelectElementFormProps {
  baseElementOnly?: boolean;
  handleClose: () => void;
  isCreation?: boolean;
  isSpecialZone?: boolean;
  trackCursor?: TrackCursor;
}

const SelectElementForm = (props: SelectElementFormProps) => {
  const {baseElementOnly, handleClose, isCreation, isSpecialZone, trackCursor} = props;

  const {t} = useTranslation();
  const navigate = useNavigate();

  const [trackElementCategory, setTrackElementCategory] = useState<TrackElementCategory>();

  const selectCategory = (category?: TrackElementCategory) => () => setTrackElementCategory(category);

  if (!!trackElementCategory) {
    return <CreateTrackElementForm
      trackElementCategory={trackElementCategory}
      trackCursor={trackCursor}
      handleClose={handleClose}
      handlePrevious={selectCategory(undefined)}/>
  }

  return (
    <ModalComponent
      handleClose={handleClose}
      maxWidth={false}
      fullWidth={false}
      title={t("schema.selectElement.title")}
      description={t("schema.selectElement.description")}
      content={(
        <Box p="40px 40px 20px" minWidth="750px">
          <Stack spacing={3}>
            {baseElementOnly && (
              <Stack direction="row" spacing={2} justifyContent="center">
                <Stack spacing={1} alignItems="center" width="100px">
                  <IconButton color="primary" size="large" onClick={selectCategory(TrackElementCategory.TRAIN_TRACK)}>
                    <img alt="train track" src="/images/way_icon.svg"/>
                  </IconButton>
                  <Typography fontSize={12} fontWeight="500">
                    {t("schema.selectElement.categories.trainTrack")}
                  </Typography>
                </Stack>
                <Stack spacing={1} alignItems="center" width="100px">
                  <IconButton color="primary" size="large" onClick={selectCategory(TrackElementCategory.DECLIVITY)}>
                    <img alt="train track" src="/images/gradient_icon.svg"/>
                  </IconButton>
                  <Typography fontSize={12} fontWeight="500">
                    {t("schema.selectElement.categories.declivity")}
                  </Typography>
                </Stack>
                {!projectQuery.isProjectTypeNew && (
                  <Stack spacing={1} alignItems="center" width="100px">
                    <IconButton color="primary" size="large" onClick={selectCategory(TrackElementCategory.PRS)}>
                      <img alt="train track" style={{height: 27, width: 27}} src="/images/PRS_icon.svg"/>
                    </IconButton>
                    <Typography fontSize={12} fontWeight="500">
                      {t("schema.selectElement.categories.PRS")}
                    </Typography>
                  </Stack>
                )}
                <Stack spacing={1} alignItems="center" width="100px">
                  <IconButton color="primary" size="large" onClick={selectCategory(TrackElementCategory.TRAIN_STATION)}>
                    <img alt="train station" style={{height: 27, width: 27}} src="/images/station_icon.svg"/>
                  </IconButton>
                  <Typography fontSize={12} fontWeight="500">
                    {t("schema.selectElement.categories.trainStation")}
                  </Typography>
                </Stack>
                {projectQuery.isProjectTypeNew && (
                  <Stack spacing={1} alignItems="center" width="100px">
                    <IconButton color="primary" size="large"
                                onClick={selectCategory(TrackElementCategory.CONSTRUCTION)}>
                      <img alt="train track" style={{height: 27, width: 27}} src="/images/ouvrage_icon.svg"/>
                    </IconButton>
                    <Typography fontSize={12} fontWeight="500">
                      {t("schema.selectElement.categories.construction")}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}
            {!baseElementOnly && sessionQuery.hasSufficientRoleOrAbove(Role.OS_R) && !isSpecialZone && (
              <Stack direction="row" spacing={2} justifyContent="center">
                <Stack spacing={1} alignItems="center" width="100px">
                  <IconButton color="primary" size="large" onClick={selectCategory(TrackElementCategory.TRACK_DEVICE)}>
                    <img alt="train track" src="/images/track_device_icon.svg"/>
                  </IconButton>
                  <Typography fontSize={12} fontWeight="500">
                    {t("schema.selectElement.categories.trackDevice")}
                  </Typography>
                </Stack>
                <Stack spacing={1} alignItems="center" width="100px">
                  <IconButton color="primary" size="large" onClick={selectCategory(TrackElementCategory.INSTALLATION)}>
                    <img alt="train track" src="/images/installation_icon.svg"/>
                  </IconButton>
                  <Typography fontSize={12} fontWeight="500">
                    {t("schema.selectElement.categories.installation")}
                  </Typography>
                </Stack>
                <Stack spacing={1} alignItems="center" width="100px">
                  <IconButton color="primary" size="large" onClick={selectCategory(TrackElementCategory.SIGNALISATION)}>
                    <img alt="train track" src="/images/signage_icon.svg"/>
                  </IconButton>
                  <Typography fontSize={12} fontWeight="500">
                    {t("schema.selectElement.categories.signalisation")}
                  </Typography>
                </Stack>
                {!projectQuery.isProjectTypeNew && (
                  <Stack spacing={1} alignItems="center" width="100px">
                    <IconButton color="primary" size="large" onClick={selectCategory(TrackElementCategory.ZONE)}>
                      <img alt="train track" src="/images/area_icon.svg"/>
                    </IconButton>
                    <Typography fontSize={12} fontWeight="500">
                      {t("schema.selectElement.categories.zone")}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}
            {!baseElementOnly && !isCreation && (
              <Stack direction="row" justifyContent="center" spacing={2}>
                {!projectQuery.isProjectTypeNew && (
                  <Stack spacing={1} alignItems="center" width="100px">
                    <IconButton color="primary" size="large" onClick={selectCategory(TrackElementCategory.WORKSITE)}>
                      <img alt="train track" src="/images/worksite_icon.svg"/>
                    </IconButton>
                    <Typography fontSize={12} fontWeight="500">
                      {t("schema.selectElement.categories.worksite")}
                    </Typography>
                  </Stack>
                )}
                <Stack spacing={1} alignItems="center" width="100px">
                  <IconButton color="primary" size="large" onClick={selectCategory(TrackElementCategory.ACTIVITY)}>
                    <img alt="train track" src="/images/activity_icon.svg"/>
                  </IconButton>
                  <Typography fontSize={12} fontWeight="500">
                    {t("schema.selectElement.categories.activity")}
                  </Typography>
                </Stack>
                <Stack spacing={1} alignItems="center" width="100px">
                  <IconButton color="primary" size="large" onClick={selectCategory(TrackElementCategory.RESTRICTION)}>
                    <img alt="train track" src="/images/restriction_icon.svg"/>
                  </IconButton>
                  <Typography fontSize={12} fontWeight="500">
                    {t("schema.selectElement.categories.restriction")}
                  </Typography>
                </Stack>
                {!projectQuery.isProjectTypeNew && (
                  <Stack spacing={1} alignItems="center" width="100px">
                    <IconButton color="primary" size="large" onClick={selectCategory(TrackElementCategory.WORK_TRAIN)}>
                      <img alt="train track" src="/images/train_icon.svg"/>
                    </IconButton>
                    <Typography fontSize={12} fontWeight="500">
                      {t("schema.selectElement.categories.workTrain")}
                    </Typography>
                  </Stack>
                )}
                <Stack spacing={1} alignItems="center" width="100px">
                  <IconButton color="primary" size="large"
                              onClick={selectCategory(TrackElementCategory.WORKSITE_MACHINE)}>
                    <img alt="train track" src="/images/gear_icon.svg"/>
                  </IconButton>
                  <Typography fontSize={12} fontWeight="500">
                    {t("schema.selectElement.categories.worksiteMachine")}
                  </Typography>
                </Stack>
                {!isSpecialZone && !projectQuery.isProjectTypeNew && (
                  <Stack spacing={1} alignItems="center" width="100px">
                    <IconButton color="primary" size="large" onClick={selectCategory(TrackElementCategory.WORK_ZONE)}>
                      <img alt="train track" src="/images/works_area_icon.svg"/>
                    </IconButton>
                    <Typography fontSize={12} fontWeight="500">
                      {t(`schema.selectElement.categories.ZT`)}
                    </Typography>
                  </Stack>
                )}
                {!isSpecialZone && projectQuery.isProjectTypeNew && (
                  <Stack spacing={1} alignItems="center" width="100px">
                    <IconButton color="primary" size="large"
                                onClick={selectCategory(TrackElementCategory.ELEMENTARY_CONSTRUCTION_SITE)}>
                      <img alt="train track" src="/images/works_area_icon.svg"/>
                    </IconButton>
                    <Typography fontSize={12} fontWeight="500">
                      {t(`schema.selectElement.categories.CE`)}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </Box>
      )}
      actions={trackCursor && sessionQuery.hasSufficientRoleOrAbove(Role.OS_R) && !isSpecialZone ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleClose();
            navigate(trackCursor.track.toString());
          }}>
          {t("schema.selectElement.modifyTrack")}
        </Button>
      ) : undefined}/>
  );
}

export default SelectElementForm;