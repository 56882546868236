import React, {useEffect} from 'react';
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {Grid, InputLabel} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import {Pk, PK_INPUT_REGEXP, projectQuery} from "@store/project";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import NumberTextFieldComponent from "@components/textField/NumberTextField.component";
import SelectComponent from "@components/select/Select.component";
import {useTranslation} from "react-i18next";
import DatePickerComponent from '@components/datePicker/DatePicker.component';

const MovementImmobilizationTTxForm = (props: DispatchFormGlobalProps) => {
  const {t} = useTranslation();

  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const updateDispatchContent = (name: string) => (value: any) => setDispatch({
    ...dispatch,
    dispatch: {...dispatch.dispatch, [name]: value}
  });

  useEffect(() => {
    setDispatch({
      type: DispatchTypeEnum.MOVEMENT_IMMOBILIZATION_TTX,
      dispatch: {
        ttxNbr: trackElement?.attributes.name,
        ATTX: trackElement?.attributes.ATTX,
        pkStart: Pk.toString(trackElement?.pkStart),
        date: new Date(),
        hour: new Date(),
      }
    })
  }, [])

  return (
    <div>
      <Grid container direction={"row"} columnSpacing={2.5} rowSpacing={1.5}>

        <Grid item xs={4}>
          <TextFieldComponent
            label={"TTx n°"}
            readOnly={!editable}
            value={dispatch?.dispatch?.ttxNbr || ""}
            handleChange={updateDispatchContent("ttxNbr")}
            error={errors["ttxNbr"]}/>
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent
            label={"Nom ATTX"}
            readOnly={!editable}
            value={dispatch?.dispatch?.ATTX || ""}
            handleChange={updateDispatchContent("ATTX")}
            error={errors["ATTX"]}/>
        </Grid>
        <Grid item xs={4}/>

        <Grid item xs={5}>
          <SelectComponent
            label={"Immobilisé sur ZONE TRAVAUX n°"}
            readOnly={!editable}
            value={dispatch?.dispatch?.workZoneNbr || ""}
            items={projectQuery.projectZT.map((zt) => ({
              label: zt.attributes.name || "-",
              value: zt.id,
            }))}
            handleChange={(workZoneNbr) => {
              setDispatch({
                ...dispatch,
                dispatch: {...dispatch.dispatch, workZoneNbr, trainingZoneNbr: undefined}
              })
            }}
            error={errors["workZoneNbr"]}/>
        </Grid>
        <Grid item xs={2}>
          <InputLabel sx={{ml: "0px", mt: "30px", textAlign: "center"}}>
            {"OU"}
          </InputLabel>
        </Grid>
        <Grid item xs={5}>
          <SelectComponent
            label={"Immobilisé sur ZONE DE FORMATION n°"}
            readOnly={!editable}
            value={dispatch?.dispatch?.trainingZoneNbr || ""}
            items={projectQuery.projectTrainingZone.map((tz) => ({
              label: t("schema.zone.types.trainingZone") + ` (${Pk.toString(tz.pkStart)} - ${Pk.toString(tz.pkEnd)})`,
              value: tz.id,
            }))}
            handleChange={(trainingZoneNbr) => {
              setDispatch({
                ...dispatch,
                dispatch: {...dispatch.dispatch, trainingZoneNbr, workZoneNbr: undefined}
              })
            }}
            error={errors["trainingZoneNbr"]}/>
        </Grid>

        <Grid item xs={4}>
          <TextFieldComponent
            label={"Au Km"}
            readOnly={!editable}
            handleChange={updateDispatchContent("pkStart")}
            pattern={PK_INPUT_REGEXP}
            value={dispatch?.dispatch?.pkStart || ""}
            error={errors["pkStart"]}/>
        </Grid>
        <Grid item xs={8}/>

        <Grid item xs={4}>
          <DatePickerComponent
            label={"Le"}
            placeholder=" "
            readOnly={!editable}
            value={dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("date")}
            error={errors["date"]}/>
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            label={"à"}
            placeholder=" "
            readOnly={!editable}
            value={editable ? dispatch?.dispatch?.hour : dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("hour")}
            error={errors["hour"]}/>
        </Grid>
        <Grid item xs={4}/>

        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={"Nombre de cales antidérive"}
            readOnly={!editable}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            value={dispatch?.dispatch?.nbrAntiDriftShims}
            handleChange={updateDispatchContent("nbrAntiDriftShims")}
            error={errors["nbrAntiDriftShims"]}
          />
        </Grid>

        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={"Nombre de freis  vis"}
            readOnly={!editable}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            value={dispatch?.dispatch?.nbrScrewBrakes}
            handleChange={updateDispatchContent("nbrScrewBrakes")}
            error={errors["nbrScrewBrakes"]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MovementImmobilizationTTxForm;
