import React, {useEffect} from 'react';
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {FormControlLabel, Grid, InputLabel, Radio, RadioGroup, Stack, Typography} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import {useTranslation} from "react-i18next";
import {ordersService} from "@store/dispatches/orders.service";
import {useParams} from "react-router-dom";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import NumberTextFieldComponent from "@components/textField/NumberTextField.component";
import {sessionQuery} from "@store/session";

const PnTerminationForm = (props: DispatchFormGlobalProps) => {

  const {projectId} = useParams();
  const {t} = useTranslation();

  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const updateDispatchContent = (name: string) => (value: any) => setDispatch({
    ...dispatch,
    dispatch: {...dispatch.dispatch, [name]: value}
  });

  useEffect(() => {
    ordersService.getDispatchNumber(projectId!).subscribe({
      next: (dispatchNbr) => {
        setDispatch({
          type: DispatchTypeEnum.PN_TERMINATION,
          dispatch: {
            from: trackElement?.attributes.APN,
            to: `${sessionQuery.getValue().user?.firstname || ''} ${sessionQuery.getValue().user?.lastname || ''}`,
            pnNbr: trackElement?.attributes?.name,
            date: new Date(),
            hour: new Date(),
            deliveredDispatchNbr: dispatchNbr,
            situation: false,
          }
        });
      },
    });
  }, [])

  return (
    <div>
      <Grid container direction={"row"} columnSpacing={2.5} rowSpacing={1.5}>
        <Grid item xs={4}>
          <TextFieldComponent
            label={t('schema.pn.APN')}
            readOnly={!editable}
            placeholder=" "
            value={dispatch?.dispatch?.from || ''}
            handleChange={updateDispatchContent("from")}
            error={errors["from"]}/>
        </Grid>

        <Grid item xs={4}>
          <TextFieldComponent
            label={`${t('schema.pn.to')} ${t('schema.pn.RELF')}`}
            readOnly={!editable}
            placeholder=" "
            value={dispatch?.dispatch?.to || ''}
            handleChange={updateDispatchContent("to")}
            error={errors["to"]}/>
        </Grid>

        <Grid item xs={4}>
          <TextFieldComponent
            label={`${t('schema.pn.ceaseSurveillanceCrossing')} N°`}
            placeholder=" "
            readOnly={!editable}
            value={dispatch?.dispatch?.pnNbr || ""}
            handleChange={updateDispatchContent("pnNbr")}
            error={errors["pnNbr"]}
          />
        </Grid>

        <Grid item xs={4}>
          <DatePickerComponent
            label={t('schema.pn.date')}
            readOnly={!editable}
            value={dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("date")}
            error={errors["date"]}/>
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            label={t('schema.pn.time')}
            readOnly={!editable}
            value={editable ? dispatch?.dispatch?.hour : dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("hour")}
            error={errors["hour"]}/>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" mb="-7.5px">
            <InputLabel sx={{ml: '5px', pt: '6px', width: '180px'}}>
              {t('schema.pn.normalSituation')}
            </InputLabel>
            <RadioGroup
              row
              value={dispatch.dispatch?.situation || false}
              onChange={((evt) => updateDispatchContent("situation")(evt.target.value === "true"))}>
              <FormControlLabel
                control={<Radio size="small"/>}
                value={"true"}
                disabled={!editable}
                label={(
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    {t("global.yes")}
                  </Typography>
                )}/>
              <FormControlLabel
                control={<Radio size="small"/>}
                value={"false"}
                disabled={!editable}
                label={(
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    {t("global.no")}
                  </Typography>
                )}/>
            </RadioGroup>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <TextFieldComponent
            label={t('schema.pn.observation')}
            multiline
            readOnly={!editable}
            rows={3}
            handleChange={updateDispatchContent("observation")}
            error={errors["observation"]}/>
        </Grid>

        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={t('schema.pn.dispatchDelivered')}
            readOnly={!editable}
            handleChange={updateDispatchContent("deliveredDispatchNbr")}
            value={dispatch?.dispatch?.deliveredDispatchNbr}
            error={errors["deliveredDispatchNbr"]}/>
        </Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={t('schema.pn.dispatchReceived')}
            readOnly={!editable}
            handleChange={updateDispatchContent("receivedDispatchNbr")}
            value={dispatch?.dispatch?.receivedDispatchNbr}
            error={errors["receivedDispatchNbr"]}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default PnTerminationForm;
