import React, {useState} from "react";
import {IconButton, Stack, styled, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Outlet} from "react-router-dom";
import {useSnackbar} from "notistack";

import {projectQuery, projectService} from "@store/project";
import SchemaComponent from "@screens/auth/common/schema/Schema.component";
import ElementsListDrawer from "@screens/auth/common/schema/components/ElementsList.drawer";

import {Colors} from "@utils/theme/constants.utils";
import {getProjectIncompatibilities} from "@utils/incompatibilities";
import ProjectIncompatibilitiesDrawer from "../../schema/components/ProjectIncompatibilities.drawer";
import {isOnlyForCreation} from "@utils/incompatibilities/incompatibility.utils";
import {forkJoin} from 'rxjs';
import {sessionQuery} from '@store/session';
import {Role} from '@store/users';

const StyledRoundedIconButton = styled(IconButton)({
  height: 50,
  width: 50,
});

const StyledPillShapeIconButton = styled(IconButton)({
  backgroundColor: Colors.primary,
  borderRadius: "20px 0 0 20px",
  height: 50,
  width: 50,
  "&:hover": {
    backgroundColor: Colors.primary,
  },
});

const ProjectViewScreen = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [showShortcuts, setShowShortcuts] = useState(false);
  const [showIncompatibilities, setShowIncompatibilities] = useState(false);

  const projectIncompatibilities = sessionQuery.hasAnyOfRoles(Role.CLIENT)
    ? []
    : getProjectIncompatibilities().filter((incompatibility) => !isOnlyForCreation(incompatibility.error));

  const refreshTrackElements = () => {
    const _trackElements$ = forkJoin([
      projectService.getProjectTrackElements(),
      projectService.getProjectClosedZTToday(),
    ]).subscribe({
      next: () => enqueueSnackbar(t("schema.refreshed"), {variant: "success"}),
      error: (err) => enqueueSnackbar(err.text, err.options),
    });

    return () => _trackElements$.unsubscribe();
  };

  return (
    <Stack width="100%" height="100%" pt="30px" pb="20px">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb="30px">
        <Stack height="48px" justifyContent="center">
          <Typography fontSize={26} fontWeight="900">
            {projectQuery.projectInfo.name || "-"}
          </Typography>
          <Stack direction="row" alignItems="center" spacing="3px">
            <Typography textAlign="center" fontSize={14} fontWeight="700">
              {t("schema.title", {lineId: projectQuery.projectInfo.lineId})} -
            </Typography>
            <Typography textAlign="center" fontSize={14} fontWeight="400">
              {projectQuery.projectInfo.from || "-"} / {projectQuery.projectInfo.to || "-"}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1} height="48px">
          <StyledRoundedIconButton color="primary" onClick={refreshTrackElements}>
            <img alt="" src="/images/icon_refresh.svg" style={{height: 30, width: 30}}/>
          </StyledRoundedIconButton>
          {!!projectIncompatibilities.length && (
            <StyledRoundedIconButton color="secondary" onClick={() => setShowIncompatibilities(true)}>
              <img alt="" src="/images/incompatibilities_icon.svg" style={{height: 30, width: 30}}/>
            </StyledRoundedIconButton>
          )}
          {!projectQuery.isProjectTypeNew && (
            <StyledPillShapeIconButton onClick={() => setShowShortcuts(true)} centerRipple={false}>
              <img alt="" src="/images/icon_list.svg" style={{height: 30, width: 30}}/>
            </StyledPillShapeIconButton>
          )}
        </Stack>
      </Stack>
      <SchemaComponent/>
      <Outlet/>
      <ElementsListDrawer handleClose={() => setShowShortcuts(false)} open={showShortcuts}/>
      <ProjectIncompatibilitiesDrawer
        handleClose={() => setShowIncompatibilities(false)}
        open={showIncompatibilities}
        projectIncompatibilities={projectIncompatibilities}
      />
    </Stack>
  );
};

export default ProjectViewScreen;
