import React from "react";
import {useTranslation} from "react-i18next";

import {Pk, PNType, projectQuery, TrackElement, TrackElementType} from "@store/project";

interface TooltipDetailsProps {
  position?: "start" | "end";
  textY?: number;
  trackElement: TrackElement;
  trackName?: string;
  x: number;
  y: number;
}

const TooltipDetails = (props: TooltipDetailsProps) => {
  const {position = "end", textY = 0, trackElement, trackName, x, y} = props;

  const {t} = useTranslation();

  const getDetailsByType = () => {
    switch (trackElement.type) {
      case TrackElementType.WEDGE:
      case TrackElementType.TRAIN_STATION:
      case TrackElementType.CONSTRUCTION:
      case TrackElementType.PRS:
      case TrackElementType.SIGNALISATION:
      case TrackElementType.SIGN_OTHER:
      case TrackElementType.WORKSITE:
      case TrackElementType.INST_OTHER:
        return (
          <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-12}>
            {trackElement.attributes?.name || "-"}
          </tspan>
        );
      case TrackElementType.PN:
        return (
          <>
            <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-16}>
              {trackElement.attributes?.name || "-"}
            </tspan>
            <tspan fontSize={8} fontWeight="400" alignmentBaseline="middle" x={x} dy={11}>
              {trackElement.attributes?.type ? PNType.label(trackElement.attributes.type) : "-"}
            </tspan>
          </>
        );
      case TrackElementType.WHISTLE:
        return (
          <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-12}>
            {t("schema.signalisation.types.whistle")}
          </tspan>
        );
      case TrackElementType.SIGN_STOP:
        return (
          <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-12}>
            {t("schema.signalisation.types.stop")}
          </tspan>
        );
      case TrackElementType.PRA:
        return (
          <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-12}>
            {t("schema.installation.types.PRA")}
          </tspan>
        );
      case TrackElementType.PRO:
        return (
          <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-12}>
            {t("schema.installation.types.PRO")}
          </tspan>
        );
      case TrackElementType.BUFFER:
        return (
          <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-12}>
            {t("schema.installation.types.buffer")}
          </tspan>
        );
      case TrackElementType.TUNNEL:
        return (
          <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-12}>
            {t("schema.installation.types.tunnel")}
          </tspan>
        );
      case TrackElementType.VIADUCT:
        return (
          <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-12}>
            {t("schema.installation.types.viaduct")}
          </tspan>
        );
      case TrackElementType.STOPPER:
        return (
          <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-12}>
            {t("schema.installation.types.stopper")}
          </tspan>
        );
      case TrackElementType.TRAINING_ZONE:
        return (
          <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-12 + textY}>
            {t("schema.zone.types.trainingZoneLight")}
          </tspan>
        );
      case TrackElementType.WORK_ZONE:
        return (
          <>
            <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-16 + textY}>
              {t(`schema.workArea.name`, {name: trackElement.attributes?.name || "-"})}
            </tspan>
            <tspan fontSize={8} fontWeight="400" alignmentBaseline="middle" x={x} dy={11}>
              {t(`schema.workArea.RZTSmall`)} {trackElement.attributes?.RZT || "-"}
            </tspan>
          </>
        );
      case TrackElementType.ELEMENTARY_CONSTRUCTION_SITE:
        return (
          <>
            <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-16 + textY}>
              {t(`schema.CE.name`, {name: trackElement.attributes?.name || "-"})}
            </tspan>
            <tspan fontSize={8} fontWeight="400" alignmentBaseline="middle" x={x} dy={11}>
              {t(`schema.CE.RZTSmall`)} {trackElement.attributes?.RZT || "-"}
            </tspan>
          </>
        );
      case TrackElementType.WORK_TRAIN:
        return (
          <>
            <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-16}>
              {t("schema.workTrain.workTrain", {name: trackElement.attributes?.name || "-"})}
            </tspan>
            {trackElement.attributes?.isImmobilized && (
              <tspan fontSize={8} fontWeight="400" alignmentBaseline="middle" x={x} dy={11}>
                {t("schema.workTrain.immobilized")}
              </tspan>
            )}
          </>
        );
      case TrackElementType.WORKSITE_MACHINE:
        return (
          <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-12}>
            {t("schema.worksiteMachine.worksiteMachine", {name: trackElement.attributes?.name || "-"})}
          </tspan>
        );
      case TrackElementType.OBSTACLE:
        return (
          <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-12}>
            {t("schema.restriction.natures.obstacle")}
          </tspan>
        );
      case TrackElementType.TRAFFIC_BAN:
        return (
          <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-12}>
            {t("schema.restriction.natures.trafficBan")}
          </tspan>
        );
      case TrackElementType.MAP:
        return (
          <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-12}>
            {t("schema.restriction.natures.MAP")}
          </tspan>
        );
      case TrackElementType.SPEED_LIMIT:
        return (
          <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-12}>
            {trackElement.attributes?.limitation || "-"} {t("schema.restriction.kmh")}
          </tspan>
        );
      case TrackElementType.OUTSIDE_ZPF:
      case TrackElementType.LIGHTWEIGHT:
      case TrackElementType.ACTIVITY:
        let natureOfActivity = "";
        if (trackElement.type === TrackElementType.ACTIVITY)
          natureOfActivity = t("schema.activity.typeNew.activityName", {
            name: trackElement.attributes?.name || "-",
            nature: trackElement.attributes?.natureOfActivity
          });
        if (trackElement.type === TrackElementType.OUTSIDE_ZPF)
          natureOfActivity = t("schema.activity.natures.outsideZPF");
        if (trackElement.type === TrackElementType.LIGHTWEIGHT)
          natureOfActivity = t("schema.activity.lightweightName", {name: trackElement.attributes.name || "-"});
        return (
          <>
            <tspan fontSize={10} fontWeight="800" alignmentBaseline="middle" x={x} dy={-16}>
              {natureOfActivity}
            </tspan>
            <tspan fontSize={8} fontWeight="400" alignmentBaseline="middle" x={x} dy={11}>
              {t(`schema.activity.${projectQuery.isProjectTypeNew ? "typeNew." : ""}RASmall`)} {trackElement.attributes?.RA || "-"}
            </tspan>
          </>
        )
      default:
        return null;
    }
  }

  return (
    <text
      x={x} y={y}
      fill={TrackElementType.getColor(trackElement)}
      dominantBaseline="central" textAnchor={position === "start" ? "end" : "start"}>
      {getDetailsByType()}
      <tspan fontSize={8} fontWeight="600" alignmentBaseline="middle" x={x} dy={14}>
        {t("global.pk")} {Pk.toString(trackElement.pkStart)}{trackElement.pkEnd ? ` - ${t("global.pk")} ${Pk.toString(trackElement.pkEnd)}` : ""}
      </tspan>
      {!!trackName && (
        <tspan fontSize={8} fontWeight="600" alignmentBaseline="middle" x={x} dy={10}>
          {trackName || "-"}
        </tspan>
      )}
    </text>
  );
}

export default TooltipDetails;