import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Grid, Stack} from "@mui/material";

import {
  CreateOrUpdateTrackElement,
  PK_INPUT_REGEXP,
  projectQuery,
  TrackElementType,
  WorkTrainStatus
} from "@store/project";

import {trackElementValidation} from "@screens/auth/common/schema/forms/trackElements/forms.yup";
import {FieldErrors} from "@utils/yup.utils";

import ModalComponent from "@components/modals/Modal.component";
import SelectComponent from "@components/select/Select.component";
import TextFieldComponent from "@components/textField/TextField.component";

import useIncompatibilitiesAcceptation from "../../components/IncompatibilitiesAcceptation.component";
import CreateStopSignAcquittalForm
  from "@screens/auth/common/dispatch/createStopSignAcquittal/createStopSignAcquittal.from";
import {IncompatibilityError} from "@utils/incompatibilities/incompatibility.utils";

interface SignalisationFormProps {
  description: string;
  handleClose: () => void;
  handleDelete?: () => void;
  handlePrevious?: () => void;
  handleValidate: (incompatibilities?: IncompatibilityError[]) => void;
  hideDelete?: boolean;
  loading?: boolean;
  setTrackElement: (trackElement: CreateOrUpdateTrackElement) => void;
  title: string;
  trackElement: CreateOrUpdateTrackElement;
}

const SignalisationForm = (props: SignalisationFormProps) => {
  const {
    description,
    handleClose,
    handleDelete,
    handlePrevious,
    handleValidate,
    hideDelete,
    loading,
    setTrackElement,
    title,
    trackElement,
  } = props;

  const {t} = useTranslation();

  const [errors, setErrors] = useState<FieldErrors>({});
  const [showStopSignAcquittal, setShowStopSignAcquittal] = useState(false);

  const {
    IncompatibilitiesAcceptation,
    disabledByIncompatibilities,
    incompatibilities
  } = useIncompatibilitiesAcceptation({trackElement, creation: !handleDelete});

  const updateTrackElement = (name: string) => (value: any) => setTrackElement({...trackElement, [name]: value});
  const updateAttributes = (name: string) => (value: any) =>
    setTrackElement({
      ...trackElement,
      attributes: {...trackElement.attributes, [name]: value},
    });

  const handleAdd = () => {
    const errors = trackElementValidation(trackElement);

    setErrors(errors);
    if (!Object.keys(errors).length) handleValidate(incompatibilities);
  };

  const TTXWaitingForStopSignAcquittal = useMemo(() => {
    return projectQuery.projectTTX.find(
      (ttx) => ttx.attributes?.waitingForStopSignId === trackElement.id && ttx.attributes?.status === WorkTrainStatus.WAITING_FOR_STOP_SIGN_ACQUITTAL
    );
  }, [trackElement]);

  return (
    <ModalComponent
      handleClose={handleClose}
      title={title}
      description={description}
      content={
        <Box width="450px">
          <Grid container columnSpacing={2.5} rowSpacing={1.5}>
            <Grid item xs={12}>
              <SelectComponent
                label={t("schema.signalisation.addType")}
                placeholder={t("schema.signalisation.addTypePlaceHolder")}
                handleChange={(type) => {
                  updateTrackElement("type")(type);
                  setErrors({});
                }}
                value={trackElement.type || ""}
                error={errors.type}
                items={!projectQuery.isProjectTypeNew ? TrackElementType.signalisationTypes : TrackElementType.signalisationTypesNew}
              />
            </Grid>
            {!!trackElement.type && [TrackElementType.SIGN_OTHER, TrackElementType.SIGNALISATION].includes(trackElement.type) && (
              <Grid item xs={12}>
                <TextFieldComponent
                  label={t("schema.signalisation.name")}
                  handleChange={updateAttributes("name")}
                  value={trackElement.attributes?.name || ""}
                  error={errors["attributes.name"]}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextFieldComponent
                label={t("schema.signalisation.pk")}
                handleChange={updateTrackElement("pkStart")}
                pattern={PK_INPUT_REGEXP}
                value={trackElement.pkStart || ""}
                error={errors.pkStart}
              />
            </Grid>
          </Grid>
          {TTXWaitingForStopSignAcquittal && (
            <Stack direction={"row"} justifyContent={"center"} width={"100%"} mt={"50px"}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setShowStopSignAcquittal(true);
                }}>
                {t("schema.signalisation.stopSignAcquittal.title")}
              </Button>
            </Stack>
          )}
          {IncompatibilitiesAcceptation && (
            <Stack pt="20px">
              <IncompatibilitiesAcceptation/>
            </Stack>
          )}
          {showStopSignAcquittal && TTXWaitingForStopSignAcquittal && (
            <CreateStopSignAcquittalForm
              handleClose={() => setShowStopSignAcquittal(false)}
              trackElement={trackElement}
              TTXWaitingForAcquittal={TTXWaitingForStopSignAcquittal}
            />
          )}
        </Box>
      }
      actions={
        <>
          {!!handlePrevious && (
            <Button variant="outlined" color="primary" disabled={loading} onClick={handlePrevious}>
              {t("global.previous")}
            </Button>
          )}
          <Button variant="contained" color="primary" disabled={loading || disabledByIncompatibilities}
                  onClick={handleAdd}>
            {t(handleDelete ? "global.modify" : "global.add")}
          </Button>
          {!!handleDelete && !hideDelete && (
            <Button variant="contained" color="error" disabled={loading} onClick={handleDelete}>
              {t("global.delete")}
            </Button>
          )}
        </>
      }
    />
  );
};

export default SignalisationForm;
