import React, {createRef} from 'react';
import {IconButton, InputAdornment, TextField} from '@mui/material';

interface SearchBarProps {
	fullWidth?: boolean;
	placeholder?: string;
	color?: "primary" | "secondary";
	handleChange?: (value: string) => void;
	value?: string;
}

const SearchBar = (props: SearchBarProps) => {
	const {
		color = "primary",
		fullWidth = true,
		handleChange,
		placeholder,
		value,
	} = props;

	const inputRef = createRef<HTMLInputElement>();

	return (
		<TextField
			inputRef={inputRef}
			color={color}
			fullWidth={fullWidth}
			placeholder={placeholder}
			aria-label="search"
			value={value}
			InputProps={{
				startAdornment: <img alt="" src="/images/search_icon.svg"/>,
				endAdornment: (
					<InputAdornment position="end">
						<IconButton size="small" onClick={() => {
							if (inputRef.current) inputRef.current.value = "";
							handleChange?.("")
						}}>
							<img alt="" src="/images/clear_icon.svg"/>
						</IconButton>
					</InputAdornment>
				),
			}}
			onChange={(evt) => handleChange?.(evt.target.value)}
		/>
	);
};

export default SearchBar;
