import React, {useEffect} from "react";
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {FormControlLabel, Grid, InputLabel, Radio, RadioGroup, Stack, Typography} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import {Pk, PK_INPUT_REGEXP, PK_REGEXP, projectQuery, TrackElementType} from "@store/project";
import SelectComponent from "@components/select/Select.component";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import NumberTextFieldComponent from "@components/textField/NumberTextField.component";
import {useTranslation} from "react-i18next";
import {ordersService} from "@store/dispatches/orders.service";
import {useParams} from "react-router-dom";
import {
  getADVsOnPath,
  getElementsOnPath,
  getNotPassableZTsOnPath,
  getPNsOnPath,
  getStopSignsOnPath
} from "@screens/auth/common/schema/forms/dispatches/workTrain/getElementsOnDispatch";

const MovementReleaseForm = (props: DispatchFormGlobalProps) => {
  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const {projectId} = useParams();
  const {t} = useTranslation();

  const updateDispatchContent = (name: string) => (value: any) =>
    setDispatch({
      ...dispatch,
      dispatch: {...dispatch.dispatch, [name]: value},
    });

  useEffect(() => {
    ordersService.getDispatchNumber(projectId!).subscribe({
      next: (dispatchNbr) => {
        const pkStart = trackElement?.pkStart;
        const pkEnd = trackElement?.attributes?.finalPk;

        let type = undefined;

        if (!!pkEnd) {
          if (projectQuery.projectZT.some((ZT) => !!ZT.pkEnd && Pk.isPkBetweenPks(pkEnd, ZT.pkStart, ZT.pkEnd))) {
            type = TrackElementType.WORK_ZONE;
          } else if (projectQuery.projectZF.some((ZF) => !!ZF.pkEnd && Pk.isPkBetweenPks(pkEnd, ZF.pkStart, ZF.pkEnd))) {
            type = TrackElementType.TRAINING_ZONE;
          }
        }

        setDispatch({
          type: DispatchTypeEnum.MOVEMENT_RELEASE,
          dispatch: {
            ttxNbr: trackElement?.attributes.name,
            ATTX: trackElement?.attributes.ATTX,
            pkStart: Pk.toString(pkStart),
            pkEnd: Pk.toString(pkEnd),
            startWay: trackElement?.tracks[0],
            endWay: trackElement?.attributes?.finalTrack,
            type,
            date: new Date(),
            hour: new Date(),
            deliveredDispatchNbr: dispatchNbr,
          },
        });
      },
    });
  }, []);

  useEffect(() => {
    if (editable) {
      let stringPkStart = dispatch.dispatch?.pkStart;
      let stringPkEnd = dispatch.dispatch?.pkEnd;

      if (!!stringPkStart && PK_REGEXP.test(stringPkStart) && !!stringPkEnd && PK_REGEXP.test(stringPkEnd) && !!trackElement?.tracks) {
        const pkStart = Pk.fromString(stringPkStart)!;
        const pkEnd = Pk.fromString(stringPkEnd)!;

        const TTXPath = projectQuery.getTTxPath(pkStart, pkEnd, trackElement.tracks[0]);

        const pnsOnPath = getPNsOnPath(pkStart, pkEnd);
        const instructions = getElementsOnPath(TTXPath);
        const advsOnPath = getADVsOnPath(TTXPath);
        const notPassableZTs = getNotPassableZTsOnPath(TTXPath);
        const stopSignsOnPath = getStopSignsOnPath(TTXPath);

        setDispatch({
          ...dispatch,
          dispatch: {
            ...dispatch.dispatch,
            pn: [...pnsOnPath, ...advsOnPath, ...stopSignsOnPath].join("\n"),
            specificInstructions: [...instructions, ...notPassableZTs].join("\n"),
          },
        });
      } else {
        setDispatch({
          ...dispatch,
          dispatch: {
            ...dispatch.dispatch,
            pn: undefined,
            specificInstructions: undefined,
          },
        });
      }
    }
  }, [editable, dispatch.dispatch?.pkStart, dispatch.dispatch?.pkEnd]);

  return (
    <div>
      <Grid container direction={"row"} columnSpacing={2.5} rowSpacing={1.5}>
        <Grid item xs={4}>
          <TextFieldComponent
            label={"Dégagement du TTX n°"}
            placeholder=" "
            readOnly={!editable}
            value={dispatch?.dispatch?.ttxNbr || ""}
            handleChange={updateDispatchContent("ttxNbr")}
            error={errors["ttxNbr"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent
            label={"Nom ATTX"}
            readOnly={!editable}
            value={dispatch?.dispatch?.ATTX || ""}
            handleChange={updateDispatchContent("ATTX")}
            error={errors["ATTX"]}/>
        </Grid>
        <Grid item xs={4}/>
        <Grid item xs={4}>
          <TextFieldComponent
            label={"Point de dégagement"}
            placeholder=" "
            readOnly={!editable}
            value={dispatch?.dispatch?.pkStart || ""}
            handleChange={updateDispatchContent("pkStart")}
            error={errors["pkStart"]}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectComponent
            label={"Sur voie n°"}
            readOnly={!editable}
            handleChange={updateDispatchContent("startWay")}
            value={dispatch?.dispatch?.startWay || ""}
            error={errors["startWay"]}
            items={projectQuery.projectTracks.map((t) => ({
              label: t.attributes.name || "-",
              value: t.id,
            }))}
          />
        </Grid>

        <Grid item xs={3}/>
        <Grid item xs={6}>
          <TextFieldComponent
            label={"Acheminiement sur la ligne fermée du TTX n°"}
            placeholder=" "
            readOnly
            value={dispatch?.dispatch?.ttxNbr || ""}
          />
        </Grid>
        <Grid item xs={3}/>

        <Grid item xs={1}/>
        <Grid item xs={10}>
          <Stack direction="row" alignItems="center" justifyContent="center" mb="-7.5px">
            <InputLabel sx={{ml: "5px", pt: "6px", width: "60px"}}>{"Vers"}</InputLabel>
            <RadioGroup
              row
              value={dispatch.dispatch?.type ?? "null"}
              onChange={(evt, type) => updateDispatchContent("type")(type === "null" ? undefined : type)}>
              <FormControlLabel
                control={<Radio size="small"/>}
                value={TrackElementType.WORK_ZONE}
                disabled={!editable}
                label={
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    ZONE TRAVAUX
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Radio size="small"/>}
                value={TrackElementType.TRAINING_ZONE}
                disabled={!editable}
                label={
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    ZONE DE FORMATION
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Radio size="small"/>}
                value="null"
                disabled={!editable}
                label={
                  <Typography fontSize={12} fontWeight="600" pt="3px">
                    PK DE DESTINATION
                  </Typography>
                }
              />
            </RadioGroup>
          </Stack>
        </Grid>
        <Grid item xs={1}/>

        <Grid item xs={2}/>
        <Grid item xs={4}>
          <TextFieldComponent
            label={"Située au Km"}
            readOnly={!editable}
            placeholder=" "
            handleChange={updateDispatchContent("pkEnd")}
            pattern={PK_INPUT_REGEXP}
            value={dispatch?.dispatch?.pkEnd || ""}
            error={errors["pkEnd"]}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectComponent
            label={"Sur voie"}
            readOnly={!editable}
            handleChange={updateDispatchContent("endWay")}
            value={dispatch?.dispatch?.endWay || ""}
            error={errors["endWay"]}
            items={projectQuery.projectTracks.map((t) => ({
              label: t.attributes.name || "-",
              value: t.id,
            }))}
          />
        </Grid>
        <Grid item xs={2}/>

        <Grid item xs={12}>
          <Grid container direction={"row"} columnSpacing={2.5} rowSpacing={1.5}>
            <Grid item xs={8}>
              <TextFieldComponent
                label={"Mesures particulières - Restrictions de circulation"}
                placeholder=" "
                multiline
                readOnly={!editable}
                minRows={3}
                handleChange={updateDispatchContent("specificInstructions")}
                value={dispatch?.dispatch?.specificInstructions || ""}
                error={errors["specificInstructions"]}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldComponent
                label={"PN"}
                placeholder=" "
                multiline
                readOnly={!editable}
                minRows={3}
                handleChange={updateDispatchContent("pn")}
                value={dispatch?.dispatch?.pn || ""}
                error={errors["pn"]}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <DatePickerComponent
            label={"Le"}
            placeholder=" "
            readOnly={!editable}
            value={dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("date")}
            error={errors["date"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            label={"à"}
            placeholder=" "
            readOnly={!editable}
            value={editable ? dispatch?.dispatch?.hour : dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("hour")}
            error={errors["hour"]}
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={t("schema.pn.dispatchDelivered")}
            readOnly={!editable}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            handleChange={updateDispatchContent("deliveredDispatchNbr")}
            value={dispatch?.dispatch?.deliveredDispatchNbr}
            error={errors["deliveredDispatchNbr"]}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={t("schema.pn.dispatchReceived")}
            readOnly={!editable}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            handleChange={updateDispatchContent("receivedDispatchNbr")}
            value={dispatch?.dispatch?.receivedDispatchNbr}
            error={errors["receivedDispatchNbr"]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MovementReleaseForm;
