import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, projectQuery, TrackElement, TrackElementType} from "@store/project";
import {ID} from "@datorama/akita";
import {DispatchTypeEnum, TTxDispatchType} from '@store/dispatches/dispatch.model';

interface CheckNoBlockingElementOnTTxPathParams {
  fromDispatch?: boolean;
  dispatchType?: TTxDispatchType;
  trackElementId?: ID;
  trackIds: ID[];
  pkStart: Pk;
  finalPk?: Pk;
  otherElements: TrackElement[];
}

/* Movement delivery can't have blocking element */
const checkNoBlockingElementOnTTxPath = (params: CheckNoBlockingElementOnTTxPathParams): IncompatibilityError[] => {
  const {fromDispatch, dispatchType, trackElementId, trackIds, pkStart, finalPk, otherElements} = params;

  if (!finalPk
    || (fromDispatch && dispatchType && ![
      DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE,
      DispatchTypeEnum.MOVEMENT_ENGAGEMENT,
      DispatchTypeEnum.MOVEMENT_RELEASE
    ].includes(dispatchType))) return [];

  const TTxPath = projectQuery.getTTxPath(
    pkStart,
    finalPk,
    trackIds[0],
  );

  const elementsOnTTxMovement = otherElements.filter((t) => {
    return projectQuery.checkIfElementInTTxPath(
      TTxPath,
      t.pkStart,
      t.type === TrackElementType.WORK_TRAIN ? Pk.addMeters(t.pkStart, t.attributes?.TTXLength ?? 0) : t.pkEnd,
      ![TrackElementType.WORK_ZONE, TrackElementType.WORKSITE].includes(t.type) ? t.tracks : undefined,
    );
  });

  if (!!elementsOnTTxMovement?.length) {
    return [{
      error: IncompatibilityErrorEnum.NO_BLOCKING_ELEMENT_ON_TTX_PATH,
      trackElementId,
      concernedTrackElementIds: elementsOnTTxMovement.map((e) => e.id),
    }]
  }

  return [];
};

export default checkNoBlockingElementOnTTxPath
