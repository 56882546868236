import * as React from 'react';
import {useEffect} from 'react';
import {Grid} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {sessionQuery} from "@store/session";
import NumberTextFieldComponent from "@components/textField/NumberTextField.component";
import {ordersService} from "@store/dispatches/orders.service";
import {useParams} from "react-router-dom";

export const RestartingStandbyAuthorizationWorkAreaForm = (props: DispatchFormGlobalProps) => {
  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const {projectId} = useParams();

  const updateDispatchContent = (name: string) => (value: any) => setDispatch({
    ...dispatch,
    dispatch: {...dispatch.dispatch, [name]: value}
  });

  useEffect(() => {
    if (trackElement) {
      ordersService.getDispatchNumber(projectId!).subscribe({
        next: (dispatchNbr) => {
          setDispatch({
            type: DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_RESTARTING_A_WORK_AREA_IN_STANDBY,
            dispatch: {
              from: trackElement.attributes.RZT,
              to: `${sessionQuery.getValue().user?.firstname || ''} ${sessionQuery.getValue().user?.lastname || ''}`,
              date: new Date(),
              hour: new Date(),
              nbr: trackElement.attributes.name,
              natureOfWork: trackElement.attributes.natureOfWork,
              deliveredDispatchNbr: dispatchNbr,
            }
          });
        },
      });
    }
  }, [])

  return (
    <div>
      <Grid container columnSpacing={2.5} rowSpacing={1.5}>
        <Grid item xs={4}>
          <TextFieldComponent
            label={"M./Mme RZT"}
            readOnly={!editable}
            value={dispatch?.dispatch?.from || ''}
            handleChange={updateDispatchContent("from")}
            error={errors["from"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent
            label={"À M./Mme RELF"}
            readOnly={!editable}
            value={dispatch?.dispatch?.to || ''}
            handleChange={updateDispatchContent("to")}
            error={errors["to"]}/>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={6}>
          <TextFieldComponent
            label={"J'autorise la reprise des activités sur la la ZT N°"}
            readOnly
            placeholder={"N°"}
            value={dispatch?.dispatch?.nbr || ''}
            error={errors["nbr"]}/>
        </Grid>
        <Grid item xs={12}>
          <TextFieldComponent
            label={"Nature des travaux"}
            readOnly={!editable}
            value={dispatch?.dispatch?.natureOfWork || ''}
            handleChange={updateDispatchContent("natureOfWork")}
            error={errors["natureOfWork"]}/>
        </Grid>
        <Grid item xs={12}>
          <TextFieldComponent
            multiline
            readOnly={!editable}
            rows={4}
            label={"Informations complémentaires"}
            handleChange={updateDispatchContent("additionalInformation")}
            value={dispatch?.dispatch?.additionalInformation || ""}
            error={errors["additionalInformation"]}/>
        </Grid>
        <Grid item xs={4}>
          <DatePickerComponent
            label={"Le"}
            readOnly={!editable}
            value={dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("date")}
            error={errors["date"]}/>
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            label={"à"}
            readOnly={!editable}
            value={editable ? dispatch?.dispatch?.hour : dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("hour")}
            error={errors["hour"]}/>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={"N° de dépêche délivré"}
            readOnly={!editable}
            handleChange={updateDispatchContent("deliveredDispatchNbr")}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            value={dispatch?.dispatch?.deliveredDispatchNbr}
            error={errors["deliveredDispatchNbr"]}/>
        </Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={"N° de dépêche reçu"}
            readOnly={!editable}
            handleChange={updateDispatchContent("receivedDispatchNbr")}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            value={dispatch?.dispatch?.receivedDispatchNbr}
            error={errors["receivedDispatchNbr"]}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default RestartingStandbyAuthorizationWorkAreaForm;
