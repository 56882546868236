import {date, object, string} from "yup";
import I18n from "@utils/i18n.utils";
import * as YupUtils from "@utils/yup.utils";
import {CreatePNAcquittal} from "@store/dispatches/dispatch.model";

export const createPNAcquittalValidation = (data: CreatePNAcquittal) => {
  const requiredString = string().required(I18n.t("errors.required"));
  const requiredDate = date().nullable()
    .required(I18n.t("errors.required"))
    .typeError(I18n.t("errors.date"));
  const requiredHour = date().nullable()
    .required(I18n.t("errors.required"))
    .typeError(I18n.t("errors.hour"));

  try {
    const schema = object().shape({
      projectId: requiredString,
      pnName: requiredString,
      RELF: requiredString,
      fromAPN: requiredString,
      TTxNbr: requiredString,
      date: requiredDate,
      hour: requiredHour,
    });

    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}
