import React, {useEffect, useMemo} from "react";
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import TextFieldComponent from "@components/textField/TextField.component";
import {Pk, PK_INPUT_REGEXP, projectQuery} from "@store/project";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {useTranslation} from "react-i18next";
import SelectComponent from "@components/select/Select.component";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import {Colors} from "@utils/theme/constants.utils";
import NumberTextFieldComponent from '@components/textField/NumberTextField.component';

const PostSplittingForm = (props: DispatchFormGlobalProps) => {
  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const {t} = useTranslation();

  const emptyPart = {
    ATTX: trackElement?.attributes.ATTX,
    way: trackElement?.tracks[0],
    date: new Date(),
    hour: new Date(),
    authorizedSplitting: false,
    immobilization: false,
  };

  const firstEmptyPart = {
    ...emptyPart,
    pkStart: Pk.toString(trackElement?.pkStart),
  };

  const updateDispatchSplittingPartContent = (index: number, name: string) => (value: any) => {
    setDispatch({
      ...dispatch,
      dispatch: {
        ...dispatch.dispatch,
        splittingParts: dispatch?.dispatch?.splittingParts?.map((part, i) =>
          i === index ? {...part, [name]: value} : part
        ),
      },
    });
  };

  const onDeletePart = (index: number) => {
    const newParts = dispatch?.dispatch?.splittingParts?.filter((_, i) => i !== index);
    setDispatch({
      ...dispatch,
      dispatch: {
        ...dispatch.dispatch,
        splittingParts: newParts,
      },
    });
  };

  const onAddPart = () => {
    setDispatch({
      ...dispatch,
      dispatch: {
        ...dispatch.dispatch,
        splittingParts: [...(dispatch?.dispatch?.splittingParts || []), emptyPart],
      },
    });
  };

  useEffect(() => {
    setDispatch({
      ...dispatch,
      type: DispatchTypeEnum.SPLITTING_POST_SPLITTING,
      dispatch: {
        ...dispatch.dispatch,
        splittingParts: [firstEmptyPart, emptyPart],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const splittingPartsLength = useMemo(() => {
    return dispatch.dispatch?.splittingParts?.length ?? 0;
  }, [dispatch.dispatch?.splittingParts]);

  return (
    <Stack spacing="30px" pt="10px">
      {dispatch?.dispatch?.splittingParts?.map((part, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && (
              <Divider variant="fullWidth" orientation="horizontal" color={Colors.primary}/>
            )}
            <Stack spacing="5px">
              <Stack direction="row" alignItems="center" justifyContent="space-between" height="36px">
                <Typography fontSize={16} fontWeight={700} color="primary" justifyContent="center">
                  {t("schema.splitting.part", {part: index + 1})}
                </Typography>
                {editable && splittingPartsLength > 2 && (
                  <Box>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<img alt="delete" src="/images/delete_white_icon.svg"/>}
                      color="error"
                      onClick={() => onDeletePart(index)}>
                      {t("schema.splitting.deletePart")}
                    </Button>
                  </Box>
                )}
              </Stack>
              <Box>
                <Grid container item xs={12} columnSpacing={2.5} rowSpacing={1.5}>
                  <Grid item xs={4}>
                    <TextFieldComponent
                      readOnly={!editable}
                      label={t("schema.splitting.ATTX")}
                      value={part?.ATTX || ""}
                      handleChange={updateDispatchSplittingPartContent(index, "ATTX")}
                      error={errors[`splittingParts[${index}].ATTX`]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldComponent
                      readOnly={!editable}
                      label={t("schema.splitting.TTX")}
                      value={part?.ttxNbr || ""}
                      handleChange={updateDispatchSplittingPartContent(index, "ttxNbr")}
                      error={errors[`splittingParts[${index}].ttxNbr`]}
                    />
                  </Grid>
                  <Grid item xs={4}/>
                  <Grid item xs={4}>
                    <NumberTextFieldComponent
                      label={t("schema.splitting.motorEnginesNbr")}
                      placeholder={t("schema.splitting.motorEnginesNbr")}
                      readOnly={!editable}
                      pattern={new RegExp(/^(\d*|\d+)$/)}
                      value={part?.emNbr}
                      handleChange={updateDispatchSplittingPartContent(index, "emNbr")}
                      error={errors["emNbr"]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberTextFieldComponent
                      label={t("schema.splitting.nbrOfCars")}
                      placeholder={t("schema.splitting.nbrOfCars")}
                      readOnly={!editable}
                      pattern={new RegExp(/^(\d*|\d+)$/)}
                      value={part?.nbrOfCars}
                      handleChange={updateDispatchSplittingPartContent(index, "nbrOfCars")}
                      error={errors["nbrOfCars"]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberTextFieldComponent
                      readOnly={!editable}
                      label={t("schema.splitting.TTXLength")}
                      placeholder={t("schema.splitting.TTXLength")}
                      pattern={new RegExp(/^(\d*|\d+)[,.]?\d*$/)}
                      value={part?.TTXLength}
                      handleChange={updateDispatchSplittingPartContent(index, "TTXLength")}
                      error={errors[`splittingParts[${index}].TTXLength`]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldComponent
                      readOnly={!editable}
                      label={t("schema.splitting.toPK")}
                      placeholder={t("schema.splitting.PK")}
                      pattern={PK_INPUT_REGEXP}
                      value={part?.pkStart || ""}
                      handleChange={updateDispatchSplittingPartContent(index, "pkStart")}
                      error={errors[`splittingParts[${index}].pkStart`]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SelectComponent
                      readOnly={!editable}
                      label={t("schema.splitting.onTrack")}
                      value={part?.way || ""}
                      items={projectQuery.projectTracks.map((t) => ({
                        label: t.attributes.name || "-",
                        value: t.id,
                      }))}
                      handleChange={updateDispatchSplittingPartContent(index, "way")}
                      error={errors[`splittingParts[${index}].way`]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" mb="-7.5px">
                      <InputLabel sx={{ml: "10px", pt: "6px", width: "180px"}}>
                        {t("schema.workTrain.authorizedSplitting")}
                      </InputLabel>
                      <RadioGroup
                        aria-readonly={!editable}
                        row
                        value={part?.authorizedSplitting || false}
                        onChange={(evt) =>
                          updateDispatchSplittingPartContent(
                            index,
                            "authorizedSplitting"
                          )(evt.target.value === "true")
                        }
                      >
                        <FormControlLabel
                          control={<Radio size="small"/>}
                          value={"true"}
                          label={
                            <Typography fontSize={12} fontWeight="600" pt="3px">
                              {t("global.yes")}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={<Radio size="small"/>}
                          value={"false"}
                          label={
                            <Typography fontSize={12} fontWeight="600" pt="3px">
                              {t("global.no")}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center">
                      <InputLabel sx={{ml: "10px", pt: "6px", width: "180px"}}>
                        {t("schema.splitting.plannedImmobilization")}
                      </InputLabel>
                      <RadioGroup
                        aria-readonly={!editable}
                        row
                        value={part?.immobilization || false}
                        onChange={(evt) =>
                          updateDispatchSplittingPartContent(
                            index,
                            "immobilization"
                          )(evt.target.value === "true")
                        }
                      >
                        <FormControlLabel
                          control={<Radio size="small"/>}
                          value={"true"}
                          label={
                            <Typography fontSize={12} fontWeight="600" pt="3px">
                              {t("global.yes")}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={<Radio size="small"/>}
                          value={"false"}
                          label={
                            <Typography fontSize={12} fontWeight="600" pt="3px">
                              {t("global.no")}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <DatePickerComponent
                      readOnly={!editable}
                      label={t("schema.splitting.fromDate")}
                      placeholder={t("schema.splitting.fromDate")}
                      value={part?.date}
                      handleChange={updateDispatchSplittingPartContent(index, "date")}
                      error={errors[`splittingParts[${index}].date`]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TimePickerComponent
                      readOnly={!editable}
                      label={t("schema.splitting.toTime")}
                      placeholder={t("schema.splitting.toTime")}
                      value={editable ? part?.hour : part?.date}
                      handleChange={updateDispatchSplittingPartContent(index, "hour")}
                      error={errors[`splittingParts[${index}].hour`]}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </React.Fragment>
        );
      })}
      {editable && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="small"
            startIcon={<img alt="add" src="/images/add_icon_white.svg"/>}
            color="primary"
            onClick={() => {
              onAddPart();
            }}
          >
            {t("schema.splitting.addPart")}
          </Button>
        </Grid>
      )}
    </Stack>
  );
};

export default PostSplittingForm;
