import React, {useEffect} from 'react';
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {Grid, InputLabel} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import {Pk, PK_INPUT_REGEXP, projectQuery} from "@store/project";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import NumberTextFieldComponent from "@components/textField/NumberTextField.component";
import SelectComponent from "@components/select/Select.component";
import {useTranslation} from "react-i18next";
import {sessionQuery} from "@store/session";

const MovementRemovalForm = (props: DispatchFormGlobalProps) => {

  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const {t} = useTranslation();

  const updateDispatchContent = (name: string) => (value: any) => setDispatch({
    ...dispatch,
    dispatch: {...dispatch.dispatch, [name]: value}
  });

  useEffect(() => {
    if (trackElement) {
      setDispatch({
        type: DispatchTypeEnum.MOVEMENT_REMOVAL,
        dispatch: {
          from: `${sessionQuery.getValue().user?.firstname || ''} ${sessionQuery.getValue().user?.lastname || ''}`,
          to: trackElement?.attributes.ATTX,
          ttxNbr: trackElement?.attributes?.name,
          pkStart: Pk.toString(trackElement?.pkStart),
          date: new Date(),
          hour: new Date(),
        }
      })
    }
  }, [])

  return (
    <div>
      <Grid container direction={"row"} columnSpacing={2.5} rowSpacing={1.5}>
        <Grid item xs={4}>
          <TextFieldComponent
            label={"M./Mme RZT/RZF"}
            readOnly={!editable}
            placeholder=" "
            value={dispatch?.dispatch?.from || ''}
            handleChange={updateDispatchContent("from")}
            error={errors["from"]}/>
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent
            label={"À M./Mme ATTx"}
            readOnly={!editable}
            placeholder=" "
            value={dispatch?.dispatch?.to || ''}
            handleChange={updateDispatchContent("to")}
            error={errors["to"]}/>
        </Grid>


        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={"du TTX n°"}
            readOnly={!editable}
            placeholder=" "
            value={dispatch?.dispatch?.ttxNbr}
            handleChange={updateDispatchContent("ttxNbr")}
            error={errors["ttxNbr"]}/>
        </Grid>


        <Grid item xs={5}>
          <SelectComponent
            label={"Je vous autorise à retirer le bornage de la ZT n°"}
            readOnly={!editable}
            value={dispatch?.dispatch?.workZoneNbr || ""}
            items={projectQuery.projectZT.map((zt) => ({
              label: zt.attributes.name || "-",
              value: zt.id,
            }))}
            handleChange={(workZoneNbr) => {
              setDispatch({
                ...dispatch,
                dispatch: {...dispatch.dispatch, workZoneNbr, trainingZoneNbr: undefined}
              })
            }}
            error={errors["workZoneNbr"]}/>
        </Grid>
        <Grid item xs={2}>
          <InputLabel sx={{ml: '0px', mt: "30px", textAlign: "center"}}>
            {"OU"}
          </InputLabel>
        </Grid>
        <Grid item xs={5}>
          <SelectComponent
            label={"Je vous autorise à retirer le bornage de la ZF n°"}
            readOnly={!editable}
            value={dispatch?.dispatch?.trainingZoneNbr || ""}
            items={projectQuery.projectTrainingZone.map((tz) => ({
              label: t("schema.zone.types.trainingZone") + ` (${Pk.toString(tz.pkStart)} - ${Pk.toString(tz.pkEnd)})`,
              value: tz.id,
            }))}
            handleChange={(trainingZoneNbr) => {
              setDispatch({
                ...dispatch,
                dispatch: {...dispatch.dispatch, trainingZoneNbr, workZoneNbr: undefined}
              })
            }}
            error={errors["trainingZoneNbr"]}/>
        </Grid>

        <Grid item xs={4}>
          <TextFieldComponent
            label={"Au Km"}
            placeholder=" "
            readOnly={!editable}
            handleChange={updateDispatchContent("pkStart")}
            pattern={PK_INPUT_REGEXP}
            value={dispatch?.dispatch?.pkStart || ""}
            error={errors["pkStart"]}/>
        </Grid>

        <Grid item xs={4}>
          <TimePickerComponent
            label={"à"}
            readOnly={!editable}
            value={dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("date")}
            error={errors["date"]}/>
        </Grid>

      </Grid>
    </div>
  );
};

export default MovementRemovalForm;
