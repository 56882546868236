import {ID} from "@datorama/akita";
import {catchError, finalize, from, iif, map, Observable, of, switchMap, tap} from "rxjs";
import {AxiosError, AxiosResponse} from "axios";

import {projectStore, ProjectStore} from "@store/project/project.store";
import {CreateProject, UpdateProject} from "@store/project/project.model";
import {
  CreateOrUpdateTrackElement,
  GetZtLastIdDto,
  Pk,
  TrackElement,
  TrackElementType
} from "@store/project/schema.model";
import {ProjectLight, projectsService} from "@store/projects";
import {projectQuery} from "@store/project/project.query";

import APIAxios, {APIRoutes} from "@api/axios.api";
import SnackError from "@utils/error.utils";
import {mergeDateAndHour} from '@utils/date.utils';
import {User} from '@store/users';

export class ProjectService {
  constructor(private store: ProjectStore) {
  }

  setActiveProject = (project: ProjectLight) => {
    this.store.update({info: project});
  };

  unsetActiveProject = () => {
    this.store.reset();
  };

  createProject = (data: CreateProject): Observable<ProjectLight> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTCreateProject(),
        data: {
          ...data,
          os_c: data.os_c?.id,
          os_r: data.os_r?.id,
          relfs: data.relfs?.map((relf) => relf.id),
          clients: data.clients?.map((client) => client.id),
          startingKilometerPoint: Pk.fromString(data.startingKilometerPoint),
          endingKilometerPoint: Pk.fromString(data.endingKilometerPoint),
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<ProjectLight>) => {
        return response.data;
      }),
      tap((project) => {
        projectsService.addProject(project);
        this.store.update({info: project});
      })
    );
  };

  getProjectInfoById = (projectId: ID): Observable<ProjectLight> => {
    return from(APIAxios(APIRoutes.GETProjectById(projectId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<ProjectLight>) => {
        return response.data;
      }),
      tap((info) => this.store.update({info}))
    );
  };

  updateProject = (data: UpdateProject): Observable<ProjectLight> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTUpdateProject(data.id),
        data: {
          ...data,
          os_c: data.os_c?.id,
          os_r: data.os_r?.id,
          relfs: data.relfs?.map((relf) => relf.id),
          clients: data.clients?.map((client) => client.id),
          startingKilometerPoint: Pk.fromString(data.startingKilometerPoint),
          endingKilometerPoint: Pk.fromString(data.endingKilometerPoint),
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<ProjectLight>) => {
        return response.data;
      }),
      tap((project) => {
        this.store.update({info: project});
        projectsService.updateProject(project);
      })
    );
  };

  closeProject = (): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.PATCHCloseProject(projectQuery.projectId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      tap(() => {
        this.store.update(({info}) => ({info: {...info, isPending: false}}));
        projectsService.closeProject(projectQuery.projectId);
      })
    );
  };

  deleteProject = (): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.DELETEProject(projectQuery.projectId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      tap(() => {
        projectsService.deleteProject(projectQuery.projectId);
      })
    );
  };

  getProjectCustomers = (): Observable<User[]> => {
    this.store.setLoading(true);

    return from(APIAxios(APIRoutes.GETCustomers(projectQuery.projectId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<User[]>) => {
        return response.data;
      }),
      tap((customers) => this.store.update({customers})),
      finalize(() => this.store.setLoading(false))
    );
  };

  associateCustomer = (customerId: ID): Observable<User[]> => {
    return from(
      APIAxios(APIRoutes.POSTAssociateCustomers(projectQuery.projectId, customerId))
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<User[]>) => {
        return response.data;
      }),
      tap((customers) => this.store.upsertManyCustomer(customers))
    );
  };

  removeCustomer = (customerId: ID): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.DELETERemoveCustomer(projectQuery.projectId, customerId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      tap(() => this.store.deleteCustomer(customerId))
    );
  };

  getProjectTrackElements = (): Observable<TrackElement[]> => {
    return from(APIAxios(APIRoutes.GETTrackElements(projectQuery.projectId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<TrackElement[]>) => {
        return response.data;
      }),
      tap((trackElements) => this.store.update({trackElements}))
    );
  };

  getProjectLastZT = (): Observable<GetZtLastIdDto> => {
    return from(APIAxios(APIRoutes.GETTrackElementLastZT(projectQuery.projectId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<GetZtLastIdDto>) => response.data)
    );
  };

  getProjectClosedZTToday = (): Observable<TrackElement[]> => {
    return from(APIAxios(APIRoutes.GETTrackElementClosedZTToday(projectQuery.projectId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<TrackElement[]>) => response.data),
      tap((closedZTToday) => this.store.update({closedZTToday}))
    );
  };

  createTrackElement = (data: CreateOrUpdateTrackElement, incompatibilities?: string[]): Observable<TrackElement> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTCreateTrackElement(projectQuery.projectId),
        data: {
          ...data,
          pkStart: Pk.fromString(data.pkStart),
          pkEnd: Pk.fromString(data.pkEnd),
          attributes: data.attributes
            ? {
              ...data.attributes,
              TTXLength: data.attributes.TTXLength ? +data.attributes.TTXLength : undefined,
              dateTime: mergeDateAndHour(data.attributes.dateTime, data.attributes.dateTimeHour),
              dateTimeHour: undefined,
            }
            : undefined,
          incompatibilities,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<TrackElement>) => {
        return response.data;
      }),
      tap((trackElement) => this.store.addTrackElement(trackElement))
    );
  };

  updateTrackElement = (data: CreateOrUpdateTrackElement, incompatibilities?: string[]): Observable<TrackElement> => {
    const trackElement = {
      ...data,
      pkStart: Pk.fromString(data.pkStart),
      pkEnd: Pk.fromString(data.pkEnd),
      attributes: data.attributes
        ? {
          ...data.attributes,
          TTXLength: data.attributes.TTXLength ? +data.attributes.TTXLength : undefined,
        }
        : undefined,
      incompatibilities,
    } as TrackElement;

    return from(
      APIAxios({
        ...APIRoutes.PATCHUpdateTrack(data.id || ""),
        data: trackElement,
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<TrackElement>) => {
        return response.data;
      }),
      tap(() => this.store.upsertTrackElement(trackElement))
    );
  };

  deleteTrackElement = (trackElementId: ID, incompatibilities?: string[]): Observable<TrackElement> => {
    return from(APIAxios({...APIRoutes.DELETETrackElement(trackElementId), data: {incompatibilities}})).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      switchMap((response: AxiosResponse<TrackElement>) =>
        iif(
          () => response.data.type === TrackElementType.WORK_ZONE,
          projectService.getProjectClosedZTToday().pipe(map(() => response.data)),
          of(response.data)
        )
      ),
      tap(() => this.store.deleteTrackElement(trackElementId))
    );
  };

  updateTracks = (tracks: TrackElement[], incompatibilities?: string[]): Observable<TrackElement[]> => {
    return from(
      APIAxios({
        ...APIRoutes.PATCHUpdateTracks(),
        data: {trackElements: tracks, incompatibilities},
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<TrackElement[]>) => {
        return response.data;
      }),
      tap((tracks) => this.store.upsertManyTrackElements(tracks))
    );
  };

  getActivityNumber = (trackElementId: ID): Observable<number> => {
    return from(APIAxios(APIRoutes.GETActivityNumber(trackElementId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<number>) => {
        return response.data;
      })
    );
  };
}

export const projectService = new ProjectService(projectStore);
