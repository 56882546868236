import React from "react";
import {Card, CardActionArea, Divider, Grid, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import dayjs from "@utils/dayjs.utils";

import {ProjectLight, ProjectType} from "@store/projects";
import {Pk, projectService} from "@store/project";

interface ProjectCardProps {
  project: ProjectLight;
}

const ProjectCard = (props: ProjectCardProps) => {
  const {project} = props;

  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <Card
      sx={{height: "100%"}}
      component={CardActionArea}
      onClick={() => {
        projectService.setActiveProject(project);
        navigate(project.id.toString());
      }}>
      <Stack p="30px" spacing={2} justifyContent="space-between" height="100%">
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize={22} fontWeight="800">
              {project.name}
            </Typography>
            <img alt="go to project details" src="/images/arrow_right_icon_black.svg"/>
          </Stack>
          <Typography fontSize={14} fontWeight="400">
            {t("projects.list.lineId", {lineId: project.lineId})}
          </Typography>
        </Stack>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography fontSize={10} fontWeight="400" mb="2px" sx={{opacity: 0.5}}>
                {t("projects.list.fromTo")}
              </Typography>
              <Typography fontSize={14} fontWeight="500" whiteSpace="nowrap">
                {project.from || "-"} / {project.to || "-"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize={10} fontWeight="400" mb="2px" sx={{opacity: 0.5}}>
                {t("global.roles.OSC")}
              </Typography>
              <Typography fontSize={14} fontWeight="500" whiteSpace="nowrap">
                {`${project.os_c?.lastname.toUpperCase() || ""} ${project.os_c?.firstname || ""}`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize={10} fontWeight="400" mb="2px" sx={{opacity: 0.5}}>
                {t(`global.roles.${project.projectType === ProjectType.NEW ? "OSRNew" : "OSR"}`)}
              </Typography>
              <Typography fontSize={14} fontWeight="500">
                {`${project.os_r?.lastname.toUpperCase() || ""} ${project.os_r?.firstname || ""}`}
              </Typography>
            </Grid>
            {!!project.relfs?.length && (
              <Grid item xs={12}>
                <Typography fontSize={10} fontWeight="400" mb="2px" sx={{opacity: 0.5}}>
                  {t(`global.roles.${project.projectType === ProjectType.NEW ? "RELFNew" : "RELF"}`)}
                </Typography>
                <Typography fontSize={14} fontWeight="500">
                  {project.relfs?.slice(0, 2)
                      .map((r) => `${r.lastname.toUpperCase()} ${r.firstname}`)
                      .join(", ")
                    + (project.relfs?.length > 2 ? `, +${project.relfs?.length - 2}` : "")
                  }
                </Typography>
              </Grid>
            )}
          </Grid>
        </div>
        <Stack spacing={2}>
          <Divider/>
          <div>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography fontSize={10} fontWeight="400" mb="2px" sx={{opacity: 0.5}}>
                  {t("projects.list.startDate")}
                </Typography>
                <Typography fontSize={14} fontWeight="500">
                  {dayjs(project.startingDate).format("DD MMM YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={10} fontWeight="400" mb="2px" sx={{opacity: 0.5}}>
                  {t("projects.list.endDate")}
                </Typography>
                <Typography fontSize={14} fontWeight="500">
                  {dayjs(project.endingDate).format("DD MMM YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={10} fontWeight="400" mb="2px" sx={{opacity: 0.5}}>
                  {t("projects.list.startPK")}
                </Typography>
                <Typography fontSize={14} fontWeight="500">
                  {Pk.toString(project.startingKilometerPoint) || ""}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={10} fontWeight="400" mb="2px" sx={{opacity: 0.5}}>
                  {t("projects.list.endPK")}
                </Typography>
                <Typography fontSize={14} fontWeight="500">
                  {Pk.toString(project.endingKilometerPoint) || ""}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Stack>
    </Card>
  );
}

export default ProjectCard;
