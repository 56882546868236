import {Pk, TrackElement, TrackElementType} from "@store/project";
import {IncompatibilityError} from "@utils/incompatibilities/incompatibility.utils";
import {ID} from "@datorama/akita";
import commonCheckZFWithOneFreeTrack from "@utils/incompatibilities/common/ZF_WITH_ONE_FREE_TRACK/ZFWithOneFreeTrack";

interface GetS9A3CommonIncParams {
  trackElementId?: ID;
  type: TrackElementType;
  trackIds?: ID[];
  pkStart?: Pk;
  pkEnd?: Pk;
  length?: number;
  TTx?: TrackElement[];
  WMs?: TrackElement[];
  restrictions?: TrackElement[];
  ZFs?: TrackElement[];
  tracks?: TrackElement[];
  CCIs?: TrackElement[];
  ADVs?: TrackElement[];
}

export const getS9A3CommonInc = (params: GetS9A3CommonIncParams): IncompatibilityError[] => {
  const {
    trackElementId,
    pkStart,
    pkEnd,
    length,
    type,
    trackIds = [],
    TTx = [],
    WMs = [],
    restrictions = [],
    ZFs = [],
    tracks = [],
    CCIs = [],
    ADVs = []
  } = params;

  if (!pkStart) return [];

  const incompatibilities: IncompatibilityError[] = [];

  incompatibilities.push(
    ...commonCheckZFWithOneFreeTrack({
      trackElementId,
      type,
      trackIds,
      pkStart,
      pkEnd,
      length,
      otherElements: [
        ...CCIs,
        ...TTx,
        ...WMs,
        ...restrictions.filter((r) => [TrackElementType.OBSTACLE, TrackElementType.TRAFFIC_BAN].includes(r.type)),
      ],
      ZFs,
      tracks,
      ADVs,
    })
  );

  return incompatibilities;
};
