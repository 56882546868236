import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {Box, Stack} from "@mui/material";

import {RoutesAuth, RoutesProjectInfo, RoutesTrackElement} from "@utils/routes.utils";

import LeftBarComponent from "@components/leftBar/LeftBar.component";

import ProjectsScreen from "@screens/auth/OS/projects/Projects.screen";
import CreateProjectModal from "@screens/auth/OS/projects/createProject/CreateProject.modal";

import ProjectDetailsModal from "@screens/auth/OS/projects/projectDetails/ProjectDetails.modal";

import ProjectViewScreen from "@screens/auth/common/projectDetails/projectView/ProjectView.screen";
import ManageTrackElementForm from "@screens/auth/common/schema/forms/trackElements/ManageTrackElement.form";
import SpecialElementInfoModal
  from "@screens/auth/common/schema/forms/trackElements/specialElements/SpecialElementInfo.modal";
import SpecialElementOrdersModal
  from "@screens/auth/common/schema/forms/trackElements/specialElements/SpecialElementOrders.modal";

import ProjectInfoScreen from "@screens/auth/common/projectDetails/projectInfo/ProjectInfo.screen";
import InformationTab from "@screens/auth/common/projectDetails/projectInfo/Information.tab";
import CloseProjectModal from "@screens/auth/common/projectDetails/projectInfo/closeProject/CloseProject.modal";
import ClientsTab from "@screens/auth/common/projectDetails/projectInfo/Clients.tab";

import UsersScreen from "@screens/auth/OS/users/Users.screen";
import CreateUserModal from "@screens/auth/OS/users/createUser/CreateUser.modal";
import UserDetailsModal from "@screens/auth/OS/users/userDetails/UserDetails.modal";
import DeleteUserModal from "@screens/auth/OS/users/deleteUser/DeleteUser.modal";

import AccountScreen from "@screens/auth/common/account/Account.screen";
import DispatchesModal from "@screens/auth/common/schema/forms/orders/Dispatches.modal";
import DispatchScreen from "@screens/auth/common/dispatch/Dispatch.screen";
import DispatchHistoryTab from "@screens/auth/common/dispatch/dispatchHistoryTab/DispatchHistoryTab.screen";
import TodayDispatchTab from "@screens/auth/common/dispatch/todayDispatchesTab/TodayDispatchesTab.screen";
import RelfRegisterTab from "@screens/auth/common/dispatch/relfRegister/RelfRegister.screen";
import DispatchDetail from "@screens/auth/common/dispatch/dispatchDetail/DispatchDetail.screen";
import {CreateHistoryCommonModal} from "@screens/auth/common/dispatch/createHistoryCommon/CreateHistoryCommon.modal";
import {
  CreateHistoryDispatchModal
} from "@screens/auth/common/dispatch/createHistoryDispatch/CreateHistoryDispatch.modal";
import {ManagePeopleCountingModal} from "@screens/auth/common/dispatch/managePeopleCounting/ManagePeopleCounting.modal";
import ReportingScreen from "@screens/auth/common/reporting/Reporting.screen";
import ProjectNumbersTab from "@screens/auth/common/reporting/tabs/ProjectNumbers.tab";
import PeopleCountingTab from "@screens/auth/common/reporting/tabs/peopleCounting/PeopleCounting.tab";
import EnvironmentRibbonComponent from '@components/EnvironmentRibbon.component';
import ClientDetailsModal
  from '@screens/auth/common/projectDetails/projectInfo/clients/clientDetails/ClientDetails.modal';
import AssociateClientModal
  from '@screens/auth/common/projectDetails/projectInfo/clients/associateClient/AssociateClient.modal';
import RemoveClientModal from '@screens/auth/common/projectDetails/projectInfo/clients/removeClient/RemoveClient.modal';
import DisableUserModal from "./users/disableUser/DisableUser.modal";
import ReactivateUserModal from "./users/reactivateUser/ReactivateUser.modal";
import ResetUserIdentificationCodeModal from "./users/resetUserIdentificationCode/ResetUserIdentificationCode.modal";
import DeleteProjectModal from "../common/projectDetails/projectInfo/deleteProject/DeleteProject.modal";
import ResetUserPassword from "./users/resetUserPassword/ResetUserPassword.modal";

const OSRoutes = () => {
  return (
    <Stack direction="row" height="100%" p="20px 20px 0">
      <EnvironmentRibbonComponent/>
      <Box height="100%" pb="20px" pr="20px">
        <LeftBarComponent/>
      </Box>
      <Routes>
        <Route path={RoutesAuth.PROJECTS} element={<ProjectsScreen/>}>
          <Route path=":projectId" element={<ProjectDetailsModal/>}>
            <Route path={RoutesAuth.PROJECT_VIEW}>
              <Route path=":tabIndex" element={<ProjectViewScreen/>}>
                <Route path=":trackElementId">
                  <Route path=":subTabIndex" element={<ManageTrackElementForm/>}>
                    <Route path="details">
                      <Route path={RoutesTrackElement.INFO} element={<SpecialElementInfoModal/>}/>
                      <Route path={RoutesTrackElement.ORDERS} element={<SpecialElementOrdersModal/>}>
                        <Route path=":orderId" element={<DispatchesModal/>}/>
                      </Route>
                      <Route index element={<Navigate to={RoutesTrackElement.INFO} replace/>}/>
                    </Route>
                    <Route path=":subTrackElementId" element={<ManageTrackElementForm/>}/>
                  </Route>
                  <Route index element={<Navigate to="0" replace/>}/>
                </Route>
              </Route>
              <Route index element={<Navigate to="0" replace/>}/>
            </Route>
            <Route path={RoutesAuth.DISPATCHES} element={<DispatchScreen/>}>

              <Route path={RoutesAuth.TODAY_DISPATCHES} element={<TodayDispatchTab/>}>
                <Route path={RoutesAuth.HISTORY_COMMON} element={<CreateHistoryCommonModal/>}/>
                <Route path={RoutesAuth.HISTORY_DISPATCH} element={<CreateHistoryDispatchModal/>}/>
                <Route path={RoutesAuth.PEOPLE_COUNTING} element={<ManagePeopleCountingModal/>}/>
                <Route path=":orderId/:dispatchId" element={<DispatchDetail/>}/>
              </Route>
              <Route path={RoutesAuth.HISTORY} element={<DispatchHistoryTab/>}>
                <Route path={RoutesAuth.HISTORY_COMMON} element={<CreateHistoryCommonModal/>}/>
                <Route path={RoutesAuth.HISTORY_DISPATCH} element={<CreateHistoryDispatchModal/>}/>
                <Route path={RoutesAuth.PEOPLE_COUNTING} element={<ManagePeopleCountingModal/>}/>
                <Route path=":orderId/:dispatchId" element={<DispatchDetail/>}/>
              </Route>
              <Route path={RoutesAuth.TRACK_ELEMENTS} element={<RelfRegisterTab/>}>
                <Route path={RoutesAuth.HISTORY_COMMON} element={<CreateHistoryCommonModal/>}/>
                <Route path={RoutesAuth.HISTORY_DISPATCH} element={<CreateHistoryDispatchModal/>}/>
                <Route path={RoutesAuth.PEOPLE_COUNTING} element={<ManagePeopleCountingModal/>}/>
              </Route>
              <Route index element={<Navigate to={RoutesAuth.TODAY_DISPATCHES} replace/>}/>
            </Route>
            <Route path={RoutesAuth.REPORTING} element={<ReportingScreen/>}>
              <Route path={RoutesAuth.PROJECT_NUMBERS} element={<ProjectNumbersTab/>}/>
              <Route path={RoutesAuth.PEOPLE_COUNTING} element={<PeopleCountingTab/>}/>
              <Route index element={<Navigate to={RoutesAuth.PROJECT_NUMBERS} replace/>}/>
            </Route>
            <Route path={RoutesAuth.PROJECT_INFO} element={<ProjectInfoScreen/>}>
              <Route path={RoutesProjectInfo.INFO} element={<InformationTab/>}>
                <Route path="close" element={<CloseProjectModal/>}/>
                <Route path="delete" element={<DeleteProjectModal/>}/>
              </Route>
              <Route path={RoutesProjectInfo.CLIENTS} element={<ClientsTab/>}>
                <Route path="create" element={<AssociateClientModal/>}/>
                <Route path=":userId" element={<ClientDetailsModal/>}>
                  <Route path="delete" element={<RemoveClientModal/>}/>
                </Route>
              </Route>
              <Route index element={<Navigate to={RoutesProjectInfo.INFO} replace/>}/>
            </Route>
            <Route index element={<Navigate to={RoutesAuth.PROJECT_VIEW} replace/>}/>
          </Route>
          <Route path="create" element={<CreateProjectModal/>}/>
        </Route>
        <Route path={RoutesAuth.USERS} element={<UsersScreen/>}>
          <Route path="create" element={<CreateUserModal/>}/>
          <Route path=":userId" element={<UserDetailsModal/>}>
            <Route path="delete" element={<DeleteUserModal/>}/>
            <Route path="disable" element={<DisableUserModal/>}/>
            <Route path="reactivate" element={<ReactivateUserModal/>}/>
            <Route path="reset-identification-code" element={<ResetUserIdentificationCodeModal/>}/>
            <Route path="reset-password" element={<ResetUserPassword/>}/>
          </Route>
        </Route>
        <Route path={RoutesAuth.ACCOUNT} element={<AccountScreen/>}/>
        <Route path="*" element={<Navigate to={RoutesAuth.PROJECTS} replace/>}/>
      </Routes>
    </Stack>
  );
}

export default OSRoutes;
