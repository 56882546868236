import React, {FormEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Stack} from "@mui/material";
import {finalize} from "rxjs";

import {FieldErrors} from "@utils/yup.utils";
import {forgotPasswordValidation} from "@screens/unauth/forgotPassword/forgotPassword.yup";
import {useSnackbar} from "notistack";

import {sessionService} from "@store/session";

import ModalComponent from "@components/modals/Modal.component";
import TextFieldComponent from "@components/textField/TextField.component";

interface ForgotPasswordModalProps {
  handleClose: () => void;
}

const ForgotPasswordModal = (props: ForgotPasswordModalProps) => {
  const {handleClose} = props;

  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState<FieldErrors>({});

  const handleValidate = (event: FormEvent) => {
    event.preventDefault();

    const errors = forgotPasswordValidation(email);

    setErrors(errors);
    if (!!Object.keys(errors).length) return;

    setLoading(true);
    sessionService.forgotPassword(email).pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => enqueueSnackbar(t("auth.forgotPassword.success"), {variant: "success"}),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  }

  return (
    <ModalComponent
      maxWidth="sm"
      handleClose={handleClose}
      title={t("auth.forgotPassword.title")}
      description={t("auth.forgotPassword.description")}
      content={(
        <Stack width="450px" component="form" onSubmit={handleValidate} noValidate>
          <TextFieldComponent
            label={t("auth.email")}
            placeholder={t("auth.emailPlaceholder")}
            handleChange={setEmail}
            value={email}
            error={errors.email}/>
        </Stack>
      )}
      actions={(
        <Button variant="contained" color="primary" disabled={!email || loading} onClick={handleValidate}>
          {t("global.validate")}
        </Button>
      )}/>
  );
}

export default ForgotPasswordModal;