import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {Button, Stack, Typography} from "@mui/material";

import {IdentificationCode, sessionService} from "@store/session";

import ModalComponent from "@components/modals/Modal.component";
import TextFieldComponent from "@components/textField/TextField.component";
import {finalize} from "rxjs";

interface AskCodeModalProps {
  handleClose: () => void;
}

const AskCodeModal = (props: AskCodeModalProps) => {
  const {handleClose} = props;

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState<IdentificationCode>();

  const [password, setPassword] = useState("");

  const handleDisplay = () => {
    setLoading(true);
    sessionService.getIdentificationCode(password).pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (identificationCode) => {
          setCode(identificationCode);
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      })
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      maxWidth="sm"
      title={t("account.askCode.title")}
      description={!code ? t("account.askCode.description") : undefined}
      content={(
        <Stack width="100%" pt="20px">
          {!!code ? (
            <Typography fontSize={30} fontWeight="700" textAlign="center" color="primary">
              {code.identificationCode}
            </Typography>
          ) : (
            <TextFieldComponent
              label={t("account.askCode.password")}
              handleChange={setPassword}
              value={password}
              required
              type="password"/>
          )}
        </Stack>
      )}
      actions={!code ? (
        <Button variant="contained" color="primary" disabled={!password || loading} onClick={handleDisplay}>
          {t("global.display")}
        </Button>
      ) : undefined}/>
  );
}

export default AskCodeModal;