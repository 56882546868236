import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {TrackElement} from "@store/project";
import {ID} from "@datorama/akita";

interface CheckNoDuplicateZTNameParams {
  trackElementId?: ID;
  name?: string;
  ZTs: TrackElement[];
  closedZTToday: TrackElement[];
}

/* Should not be able to set the name of a ZT used by an open ZT or a closed today ZT */
const checkNoDuplicateZTName = (params: CheckNoDuplicateZTNameParams): IncompatibilityError[] => {
  const {name, trackElementId, ZTs, closedZTToday} = params;

  const sameNameZTs = [] as TrackElement[];
  [...ZTs, ...closedZTToday].forEach((zt) => {
    if (name && +zt.attributes.name?.replace(/\D/g, "") === +name.replace(/\D/g, "")) {
      sameNameZTs.push(zt);
    }
  });

  if (!!sameNameZTs.length) {
    return [
      {
        error: IncompatibilityErrorEnum.NO_DUPLICATE_ZT_NAME,
        trackElementId,
        concernedTrackElementIds: sameNameZTs.map((ZT) => ZT.id),
      },
    ];
  }

  return [];
};

export default checkNoDuplicateZTName;
