import {Pk, TrackElement} from "@store/project";
import {ZTDispatchType} from "@store/dispatches/dispatch.model";
import {IncompatibilityError} from "@utils/incompatibilities/incompatibility.utils";
import checkInZT20mPN from "@utils/incompatibilities/ZT/IN_ZT_20M_PN/inZT20mPN";
import checkOutZT20mPN from "@utils/incompatibilities/ZT/OUT_ZT_20M_PN/outZT20mPN";
import checkNoZTonZT from "@utils/incompatibilities/ZT/NO_ZT_ON_ZT/noZTonZT";
import checkInZT20mActivity from "@utils/incompatibilities/ZT/IN_ZT_20M_ACTIVITY/inZT20mActivity";
import checkClosePNInZT from "@utils/incompatibilities/ZT/CLOSE_PN_IN_ZT/closePNInZT";
import checkOpenPNInZT from "@utils/incompatibilities/ZT/OPEN_PN_IN_ZT/openPNInZT";
import checkNoZTCutsZF from "@utils/incompatibilities/ZT/NO_ZT_CUTS_ZF/noZTCutsZF";
import checkNoZTonCCI from "./NO_ZT_ON_CCI/noZTonCCI";
import checkImmobilizedTTxInClosingZT
  from "@utils/incompatibilities/ZT/IMMOBILIZED_TTX_IN_CLOSING_ZT/immobilizedTTxInClosingZT";
import {ID} from "@datorama/akita";
import checkNoZTOpenAuthorizationInTTxMovement
  from "@utils/incompatibilities/ZT/NO_ZT_OPEN_AUTHORIZATION_IN_TTX_MOVEMENT/noZTOpenAuthorizationInTTxMovement";
import checkNoDuplicateZTName from "./NO_DUPLICATE_ZT_NAME/noDuplicateZTName";

interface GetS9A3ZTIncParams {
  trackElementId?: ID;
  activities?: TrackElement[];
  fromDispatch?: boolean;
  pkStart?: Pk;
  pkEnd?: Pk;
  status?: ZTDispatchType;
  PNs?: TrackElement[];
  ZTs?: TrackElement[];
  ZFs?: TrackElement[];
  CCIs?: TrackElement[];
  TTx?: TrackElement[];
  closedZTToday?: TrackElement[];
  name?: string;
}

export const getS9A3ZTInc = (params: GetS9A3ZTIncParams): IncompatibilityError[] => {
  const {
    activities = [],
    fromDispatch,
    pkStart,
    pkEnd,
    PNs = [],
    status,
    ZTs = [],
    ZFs = [],
    CCIs = [],
    TTx = [],
    closedZTToday = [],
    trackElementId,
    name,
  } = params;

  const incompatibilities: IncompatibilityError[] = [];

  if (name) incompatibilities.push(...checkNoDuplicateZTName({name, ZTs, closedZTToday, trackElementId}));

  if (pkStart && pkEnd) {
    incompatibilities.push(...checkNoZTonZT({fromDispatch, pkStart, pkEnd, status, ZTs, trackElementId}));

    incompatibilities.push(...checkNoZTCutsZF({fromDispatch, pkStart, pkEnd, status, ZFs, trackElementId}));

    incompatibilities.push(...checkOutZT20mPN({fromDispatch, pkStart, pkEnd, PNs, status, trackElementId}));

    incompatibilities.push(...checkInZT20mPN({fromDispatch, pkStart, pkEnd, PNs, status, trackElementId}));

    incompatibilities.push(...checkInZT20mActivity({activities, fromDispatch, pkStart, pkEnd, status, trackElementId}));

    incompatibilities.push(...checkClosePNInZT({fromDispatch, pkStart, pkEnd, PNs, status, trackElementId}));

    incompatibilities.push(...checkOpenPNInZT({fromDispatch, pkStart, pkEnd, PNs, status, trackElementId}));

    incompatibilities.push(...checkNoZTonCCI({fromDispatch, pkStart, pkEnd, CCIs, status, trackElementId}));

    incompatibilities.push(
      ...checkImmobilizedTTxInClosingZT({
        fromDispatch,
        pkStart,
        pkEnd,
        TTx,
        ZFs,
        status,
        trackElementId,
      })
    );

    incompatibilities.push(
      ...checkNoZTOpenAuthorizationInTTxMovement({
        trackElementId,
        fromDispatch,
        pkStart,
        pkEnd,
        TTx,
        status,
      })
    );
  }

  return incompatibilities;
};
