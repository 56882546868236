import {object, string} from "yup";
import I18n from "@utils/i18n.utils";
import * as YupUtils from "@utils/yup.utils";
import {CreateHistoryCommon} from "@store/dispatches/dispatch.model";

export const createHistoryCommon = (data: CreateHistoryCommon) => {
  const requiredString = string().required(I18n.t("errors.required"));

  try {
    const schema = object().shape({
      name: requiredString,
      projectId: requiredString
    });

    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}
