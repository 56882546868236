import React from "react";

import {
  Pk,
  projectQuery,
  TOOLTIP_HEIGHT,
  TRACK_ELEMENT_HEIGHT,
  TrackCursor,
  TrackElement,
  TrackElementCategory,
  TrackElementType,
  TRAIN_TRACK_SPACING
} from "@store/project";

import {SchemaParameters} from "@screens/auth/common/schema/Schema.component";

import TrackLineElement from "@screens/auth/common/schema/elements/TrackLine.element";
import PointElement from "@screens/auth/common/schema/elements/Point.element";
import ZoneElement from "@screens/auth/common/schema/elements/Zone.element";
import TrackChangeElement from "@screens/auth/common/schema/elements/TrackChange.element";
import PRSElement from "@screens/auth/common/schema/elements/PRS.element";
import {Colors} from "@utils/theme/constants.utils";
import TrainStationElement from "@screens/auth/common/schema/elements/TrainStation.element";
import SpecialZoneElement from "@screens/auth/common/schema/elements/SpecialZone.element";
import ConstructionElement from "@screens/auth/common/schema/elements/Construction.element";
import {ManeuverOrientation} from "@store/dispatches/dispatch.model";

interface LineSchemaComponentProps {
  displayCategories: TrackElementCategory[];
  handleLineClick?: (trackCursor: TrackCursor) => void;
  handleElementClick?: (trackElement: TrackElement) => void;
  handleSpecialZoneClick?: (trackElement: TrackElement) => void;
  isSpecialZone?: boolean;
  params: SchemaParameters;
  stepStart: number;
  trackElements: TrackElement[];
}

const LineSchemaComponent = (props: LineSchemaComponentProps) => {
  const {
    displayCategories,
    handleElementClick,
    handleLineClick,
    handleSpecialZoneClick,
    isSpecialZone,
    params,
    stepStart,
    trackElements
  } = props;

  const {kilometerSpacing, meterSpacing, navigationScale: step, padding, width, startX} = params;

  const getPkPosition = (pk: Pk) => {
    const pkKilometer = pk.kilometer - stepStart !== step ? (pk.kilometer - stepStart) % step : pk.kilometer - stepStart;

    return kilometerSpacing * pkKilometer + padding + meterSpacing * pk.meter;
  }

  const transformPointToPk = (screenPoint: number) => {
    const realPoint = screenPoint - startX - padding;

    let kilometer = Math.floor(realPoint / kilometerSpacing);

    const meter = Math.round((realPoint - (kilometer * kilometerSpacing)) / meterSpacing);

    if (kilometer < 0) return {kilometer: stepStart, meter: 0};
    if (kilometer > step || (kilometer === step && meter > 0)) return {
      kilometer: stepStart + step,
      meter: 0
    };

    return {
      kilometer: kilometer + stepStart + (meter === 1000 ? 1 : 0),
      meter: meter === 1000 ? 0 : meter
    };
  };

  const stepElements = trackElements.filter((e) => {
    if (e.category === TrackElementCategory.TRAIN_TRACK) return false;

    const elementPkEnd = e.type === TrackElementType.WORK_TRAIN ? Pk.addMeters(e.pkStart, e.attributes?.TTXLength ?? 0) : e.pkEnd;

    if (!!e.pkStart && !!elementPkEnd) {
      const pkStart = {kilometer: stepStart, meter: 0};
      const pkEnd = {kilometer: stepStart + step, meter: 0};
      return !(!Pk.isPkBetweenPksWithoutEnd(e.pkStart, pkStart, pkEnd)
        && !Pk.isPkBetweenPksWithoutEnd(pkStart, e.pkStart, elementPkEnd)
        && !Pk.isPkBetweenPksWithoutEnd(elementPkEnd, pkStart, pkEnd)
        && !Pk.isPkBetweenPksWithoutEnd(pkEnd, e.pkStart, elementPkEnd));
    }

    return Pk.isAfterOrEqual(e.pkStart, stepStart) && Pk.isBefore(e.pkStart, stepStart + step);
  });

  const prs = stepElements.filter((e) => e.type === TrackElementType.PRS);
  const trainStations = stepElements.filter((e) => e.type === TrackElementType.TRAIN_STATION);
  const constructions = stepElements.filter((e) => e.type === TrackElementType.CONSTRUCTION);
  const trackChanges = stepElements.filter((e) => e.type === TrackElementType.TRACK_CHANGE);
  const trainingZone = stepElements.filter((e) => e.type === TrackElementType.TRAINING_ZONE);
  const workArea = stepElements.filter((e) => e.type === TrackElementType.WORK_ZONE);
  const otherElements = stepElements.filter((e) =>
    e.type !== TrackElementType.PRS && e.type !== TrackElementType.TRACK_CHANGE && e.type !== TrackElementType.TRAIN_STATION
    && e.type !== TrackElementType.WORK_ZONE && e.type !== TrackElementType.TRAINING_ZONE
  );

  let lines = trackElements.filter((e) => e.category === TrackElementCategory.TRAIN_TRACK);

  const allTracksMaxY = !!projectQuery.projectTracks.length ? Math.max(...projectQuery.projectTracks.map((track) => track.attributes?.yIndex || 0)) : 0;
  let distribution: TrackElement[][] = Array.from(Array(allTracksMaxY)).map(() => []);
  lines.forEach((track) => {
    if (!!track.attributes?.yIndex || track.attributes?.yIndex === 0) {
      const row = distribution[track.attributes.yIndex] || [];
      distribution[track.attributes.yIndex] = row.concat(track);
      return;
    }
  });
  distribution = distribution.filter((d) => !!d.length);
  lines = lines.map((l) => ({
    ...l,
    attributes: {...l.attributes, yIndex: distribution.findIndex((tracks) => tracks.find((t) => t.id === l.id))},
  }));

  const yMax = Math.max(...lines.map((line) => line.attributes?.yIndex || 0));
  const prsAndStationsHeight = !!prs.length || !!trainStations.length || !!constructions.length ? TOOLTIP_HEIGHT * 1.5 : 0;
  const linesHeight = (yMax + 2) * TRAIN_TRACK_SPACING + prsAndStationsHeight;
  const specialZoneHeight = !!trainingZone.length || !!workArea.length ? TRACK_ELEMENT_HEIGHT + TRAIN_TRACK_SPACING : 0;

  const getTooltipPosition = (linePosition: number, elIndex: number, isMainTrack?: boolean) => {
    if (isMainTrack) {
      return elIndex % 2 === 0 ? "top" : "bottom";
    }

    if (yMax % 2 === 1 && Math.floor(yMax / 2) === linePosition)
      return elIndex % 2 === 0 ? "top" : "bottom";

    if (linePosition <= yMax / 2) {
      return "top";
    } else if (linePosition > yMax / 2) {
      return "bottom";
    }
  };

  const getYPosition = (index: number) => {
    return (linesHeight - TRAIN_TRACK_SPACING * yMax) / 2 + index * TRAIN_TRACK_SPACING;
  }

  return (
    <svg height={linesHeight + specialZoneHeight} width="100%" alignmentBaseline="middle">
      <pattern id="restriction" width="10" height="10" patternTransform="rotate(45 0 0)"
               patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2={TRACK_ELEMENT_HEIGHT} stroke={Colors.schema.elements.restriction.color2}
              strokeWidth={10}/>
        <line x1="10" y1="0" x2="10" y2={TRACK_ELEMENT_HEIGHT} stroke={Colors.schema.elements.restriction.color1}
              strokeWidth={10}/>
      </pattern>
      {trainStations.map((el) => !!el.pkEnd ? (
        <TrainStationElement
          key={el.id}
          showTooltip={TrackElementCategory.elementToShow(displayCategories).includes(el.category)}
          tooltipDirection={(isSpecialZone && el.pkStart.meter > 900) || (!isSpecialZone && Pk.isAfter(el.pkStart, stepStart + step - 1)) ? "end" : "start"}
          handleClick={handleElementClick && TrackElementCategory.canBeModified(projectQuery.projectInfo, el.category, isSpecialZone) ? () => handleElementClick(el) : undefined}
          trackElement={el}
          x1={getPkPosition(el.pkStart)}
          x2={getPkPosition(el.pkEnd)}/>
      ) : null)}
      {constructions.map((el) => (
        <ConstructionElement
          key={el.id}
          showTooltip={TrackElementCategory.elementToShow(displayCategories).includes(el.category)}
          tooltipDirection={(isSpecialZone && el.pkStart.meter > 900) || (!isSpecialZone && Pk.isAfter(el.pkStart, stepStart + step - 1)) ? "end" : "start"}
          handleClick={handleElementClick && TrackElementCategory.canBeModified(projectQuery.projectInfo, el.category, isSpecialZone) ? () => handleElementClick(el) : undefined}
          trackElement={el}
          x={getPkPosition(el.pkStart)}/>
      ))}
      {prs.map((el) => (
        <PRSElement
          key={el.id}
          showTooltip={TrackElementCategory.elementToShow(displayCategories).includes(el.category)}
          tooltipDirection={(isSpecialZone && el.pkStart.meter > 900) || (!isSpecialZone && Pk.isAfter(el.pkStart, stepStart + step - 1)) ? "start" : undefined}
          handleClick={handleElementClick && TrackElementCategory.canBeModified(projectQuery.projectInfo, el.category, isSpecialZone) ? () => handleElementClick(el) : undefined}
          trackElement={el}
          x={getPkPosition(el.pkStart)}/>
      ))}
      {trackChanges.map((el) => {
        if (!el.pkEnd) return null;

        const isStartBeforeEnd = el.pkStart.kilometer < el.pkEnd.kilometer || (el.pkStart.kilometer === el.pkEnd.kilometer && el.pkStart.meter <= el.pkEnd.meter);

        const track1 = lines.find((t) => t.id === el.tracks[isStartBeforeEnd ? 0 : 1]);
        const track2 = lines.find((t) => t.id === el.tracks[isStartBeforeEnd ? 1 : 0]);

        const pkStart = isStartBeforeEnd ? el.pkStart : el.pkEnd;
        const pkEnd = isStartBeforeEnd ? el.pkEnd : el.pkStart;

        return !!track1 && !!track2 ? (
          <TrackChangeElement
            key={el.id}
            handleClick={handleElementClick && TrackElementCategory.canBeModified(projectQuery.projectInfo, el.category, isSpecialZone) ? () => handleElementClick(el) : undefined}
            fromTrack={track1} toTrack={track2}
            deflect={el.attributes?.maneuverOrientation === ManeuverOrientation.DEFLECT}
            x1={Pk.isAfterOrEqual(pkStart, stepStart) ? getPkPosition(pkStart) : 0}
            x2={Pk.isBefore(pkEnd, stepStart + step) ? getPkPosition(pkEnd) : width}
            y1={getYPosition(track1.attributes?.yIndex !== undefined ? track1.attributes.yIndex : yMax / 2)}
            y2={getYPosition(track2.attributes?.yIndex !== undefined ? track2.attributes.yIndex : yMax / 2)}/>
        ) : null;
      })}
      {lines.map((e) => {
        if (!e.pkEnd) return null;
        if (!(Pk.isAfter(e.pkEnd, stepStart) && Pk.isBefore(e.pkStart, stepStart + step))) return null;

        const yPosition = getYPosition(e.attributes?.yIndex !== undefined ? e.attributes.yIndex : yMax / 2);

        const currentTrackElements = otherElements
          .filter((el) => el.tracks.includes(e.id))
          .sort((a, b) => {
            if (a.category === TrackElementCategory.RESTRICTION && b.category !== TrackElementCategory.RESTRICTION) {
              return -1;
            } else if (a.category !== TrackElementCategory.RESTRICTION && b.category === TrackElementCategory.RESTRICTION) {
              return 1;
            }
            return 0;
          });

        return (
          <g key={e.id}>
            <TrackLineElement
              handleLineClick={handleLineClick ? (pk) => handleLineClick?.({track: e.id, pk}) : undefined}
              trackElement={e}
              transformPointToPk={transformPointToPk}
              x1={Pk.isAfterOrEqual(e.pkStart, stepStart) ? getPkPosition(e.pkStart) : 0}
              x2={Pk.isBefore(e.pkEnd, stepStart + step) ? getPkPosition(e.pkEnd) : width}
              y={yPosition}/>
            {currentTrackElements.map((el, elIndex) => {
              if (!(!!el.pkEnd || el.type === TrackElementType.WORK_TRAIN) && !!el.pkStart) return null;

              const x1 = Pk.isAfterOrEqual(el.pkStart, stepStart) ? getPkPosition(el.pkStart) : 0;
              let x2 = x1;

              if (el.type === TrackElementType.WORK_TRAIN) {
                x2 = x1 + (+el.attributes.TTXLength || 0) * meterSpacing;
              } else if (el.pkEnd) {
                x2 = Pk.isBeforeOrEqual(el.pkEnd, stepStart + step) ? getPkPosition(el.pkEnd) : width;
              }

              return (
                <ZoneElement
                  key={el.id}
                  showTooltip={TrackElementCategory.elementToShow(displayCategories).includes(el.category)}
                  tooltipDirection={Pk.isAfter(el.pkStart, stepStart + step - 1) ? "start" : undefined}
                  handleClick={handleElementClick && TrackElementCategory.canBeModified(projectQuery.projectInfo, el.category, isSpecialZone) ? () => handleElementClick(el) : undefined}
                  position={getTooltipPosition(e.attributes?.yIndex || 0, elIndex, e.type === TrackElementType.MAIN_TRACK)}
                  trackElement={el}
                  trackName={e.attributes?.name || ""}
                  x1={x1}
                  x2={x2}
                  y={yPosition}/>
              );
            })}
            {currentTrackElements.map((el, elIndex) => !el.pkEnd && el.type !== TrackElementType.WORK_TRAIN ? (
              <PointElement
                key={el.id}
                showTooltip={TrackElementCategory.elementToShow(displayCategories).includes(el.category)}
                tooltipDirection={Pk.isAfter(el.pkStart, stepStart + step - 1) ? "start" : undefined}
                handleClick={handleElementClick && TrackElementCategory.canBeModified(projectQuery.projectInfo, el.category, isSpecialZone) ? () => handleElementClick(el) : undefined}
                position={getTooltipPosition(e.attributes?.yIndex || 0, elIndex, e.type === TrackElementType.MAIN_TRACK)}
                trackElement={el}
                trackName={e.attributes?.name || ""}
                x={getPkPosition(el.pkStart)}
                y={yPosition}/>
            ) : null)}
            <TrackLineElement
              hidden
              handleLineClick={handleLineClick ? (pk) => handleLineClick?.({track: e.id, pk}) : undefined}
              trackElement={e}
              transformPointToPk={transformPointToPk}
              x1={Pk.isAfterOrEqual(e.pkStart, stepStart) ? getPkPosition(e.pkStart) : 0}
              x2={Pk.isBefore(e.pkEnd, stepStart + step) ? getPkPosition(e.pkEnd) : width}
              y={yPosition}/>
          </g>
        );
      })}
      {trainingZone.concat(workArea).map((el) => el.pkEnd ? (
        <SpecialZoneElement
          key={el.id}
          showTooltip={TrackElementCategory.elementToShow(displayCategories).includes(el.category)}
          tooltipDirection={Pk.isAfter(el.pkStart, stepStart + step - 1) ? "start" : undefined}
          handleClick={handleSpecialZoneClick ? () => handleSpecialZoneClick(el) : undefined}
          trackElement={el}
          x1={Pk.isAfterOrEqual(el.pkStart, stepStart) ? getPkPosition(el.pkStart) : 0}
          x2={Pk.isBefore(el.pkEnd, stepStart + step) ? getPkPosition(el.pkEnd) : width}
          y={linesHeight + specialZoneHeight - TRAIN_TRACK_SPACING}/>
      ) : null)}
    </svg>
  );
}

export default LineSchemaComponent;