import React, {useEffect} from 'react';
import {Grid} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {Pk, PK_INPUT_REGEXP, projectQuery, TrackElement} from "@store/project";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {sessionQuery} from "@store/session";

const ModificationRequestWorkAreaForm = (props: DispatchFormGlobalProps) => {

  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const updateDispatchContent = (name: string) => (value: any) => setDispatch({
    ...dispatch,
    dispatch: {...dispatch.dispatch, [name]: value}
  });

  useEffect(() => {
    if (trackElement) {
      const tracks = projectQuery.projectTracks.filter((t) => {
        if (trackElement.pkStart && t.pkEnd && Pk.isPkBetweenPks(trackElement.pkStart, t.pkStart, t.pkEnd)) return true;
        return !!(trackElement.pkEnd && t.pkEnd && Pk.isPkBetweenPks(trackElement.pkEnd, t.pkStart, t.pkEnd));
      }) || [] as TrackElement[];

      setDispatch({
        type: DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA,
        dispatch: {
          from: trackElement.attributes.RZT,
          to: `${sessionQuery.getValue().user?.firstname || ''} ${sessionQuery.getValue().user?.lastname || ''}`,
          date: new Date(),
          hour: new Date(),
          tracks: tracks.map((t) => t.id),
          pkStart: Pk.toString(trackElement.pkStart),
          pkEnd: Pk.toString(trackElement.pkEnd),
          nbr: trackElement.attributes.name,
          natureOfWork: trackElement.attributes.natureOfWork
        }
      })
    }
  }, [])

  return (
    <div>
      <Grid container columnSpacing={2.5} rowSpacing={1.5}>

        <Grid item xs={4}>
          <TextFieldComponent label={"M./Mme RZT"}
                              readOnly={!editable}
                              value={dispatch?.dispatch?.from || ''}
                              handleChange={updateDispatchContent("from")}
                              error={errors["from"]}/>
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent label={"M./Mme RELF"}
                              readOnly={!editable}
                              value={dispatch?.dispatch?.to || ''}
                              handleChange={updateDispatchContent("to")}
                              error={errors["to"]}/>
        </Grid>

        <Grid item xs={6}>
          <TextFieldComponent label={"Je demande l'extension / le rétrécissement de la ZT N°"} placeholder={"N°"}
                              readOnly
                              value={dispatch?.dispatch?.nbr || ''}
                              error={errors["nbr"]}/>
        </Grid>

        <Grid item xs={12}>
          <TextFieldComponent label={"Nature des travaux"}
                              readOnly={!editable}
                              value={dispatch?.dispatch?.natureOfWork || ''}
                              handleChange={updateDispatchContent("natureOfWork")}
                              error={errors["natureOfWork"]}/>
        </Grid>

        <Grid item xs={3}>
          <TextFieldComponent
            label={"Du Km"}
            readOnly={!editable}
            handleChange={updateDispatchContent("pkStart")}
            pattern={PK_INPUT_REGEXP}
            value={dispatch?.dispatch?.pkStart || ""}
            error={errors["pkStart"]}/>
        </Grid>

        <Grid item xs={3}>
          <TextFieldComponent
            label={"Au Km"}
            readOnly={!editable}
            handleChange={updateDispatchContent("pkEnd")}
            pattern={PK_INPUT_REGEXP}
            value={dispatch?.dispatch?.pkEnd || ""}
            error={errors["pkEnd"]}/>
        </Grid>

        <Grid item xs={6}>
          <TextFieldComponent
            label={'Sur voie(s)'}
            readOnly
            multiline
            value={projectQuery.projectTracks
              .filter((t) => (dispatch.dispatch?.tracks || []).includes(t.id))
              .map((t) => t.attributes.name)
              .join(", ")
            }/>
        </Grid>

        <Grid item xs={4}>
          <DatePickerComponent
            label={"Le"}
            readOnly={!editable}
            value={dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("date")}
            error={errors["date"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            label={"à"}
            readOnly={!editable}
            value={editable ? dispatch?.dispatch?.hour : dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("hour")}
            error={errors["hour"]}
          />
        </Grid>

      </Grid>
    </div>
  );
};

export default ModificationRequestWorkAreaForm;
