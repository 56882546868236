import React, {useState} from "react";
import {IconButton, Stack} from "@mui/material";

import SelectElementForm from "@screens/auth/common/schema/forms/SelectElement.form";
import TracksDistributionModal
  from "@screens/auth/common/schema/components/tracksDistribution/TracksDistribution.modal";

const AddElementButtonComponent = () => {
    const [showTracksDistribution, setShowTracksDistribution] = useState(false);
    const [showBaseElementForm, setShowBaseElementForm] = useState(false);

    return (
        <>
            <Stack direction="row" aria-label="schema-options" spacing={1}>
                <IconButton color="primary" onClick={() => setShowBaseElementForm(true)}>
                    <img alt="train track" src="/images/add_icon_white.svg"/>
                </IconButton>
                <IconButton color="primary" onClick={() => setShowTracksDistribution(true)}>
                    <img alt="train track" src="/images/settings_white_icon.svg"/>
                </IconButton>

            </Stack>
            {showBaseElementForm && (
                <SelectElementForm
                    baseElementOnly
                    handleClose={() => setShowBaseElementForm(false)}/>
            )}
            {showTracksDistribution && (
                <TracksDistributionModal handleClose={() => setShowTracksDistribution(false)}/>
            )}
        </>
    );
}

export default AddElementButtonComponent;
