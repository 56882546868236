import {createStyles} from '@mui/styles';
import {alpha, Theme} from "@mui/material";

const classes = (theme: Theme) =>
	createStyles({
		placeholder: {
			color: alpha(theme.palette.text.primary, 0.5) + ' !important',
		},
	});
export default classes;
