import {DispatchFiltersState, DispatchFiltersStore, dispatchFiltersStore} from "./filters.store";

export class DispatchFiltersService {
  constructor(private dispatchFiltersStore: DispatchFiltersStore) {
  }

  resetStore() {
    this.dispatchFiltersStore.update({
      search: "",
      dispatchCategory: undefined,
      dispatchNature: undefined,
      trackElementHistoryCategory: undefined,
      trackElementHistoryType: undefined,
      startDate: undefined,
      endDate: undefined,
    });
  }

  resetFilters() {
    this.dispatchFiltersStore.update((state) => {
      return {
        search: state.search,
        dispatchCategory: undefined,
        dispatchNature: undefined,
        trackElementHistoryCategory: undefined,
        trackElementHistoryType: undefined,
        startDate: undefined,
        endDate: undefined,
      };
    });
  }

  setFilters(filters: Partial<DispatchFiltersState>) {
    this.dispatchFiltersStore.update((state) => {
      return {
        ...state,
        ...filters,
      };
    });
  }
}

export const dispatchFiltersService = new DispatchFiltersService(
  dispatchFiltersStore
);
