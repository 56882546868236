import React, {useEffect, useState} from 'react';
import {InputLabel, TextField} from '@mui/material';

interface NumberTextFieldComponentProps {
  label?: string;
  fullWidth?: boolean;
  required?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  color?: "primary" | "secondary";
  pattern?: RegExp;
  handleChange?: (value?: number) => void;
  disabled?: boolean;
  value?: number;
  error?: string;
  endAdornment?: JSX.Element;
}

const NumberTextFieldComponent = (props: NumberTextFieldComponentProps) => {
  const {
    color = "primary",
    endAdornment,
    error,
    fullWidth = true,
    handleChange,
    label,
    placeholder,
    readOnly,
    disabled,
    pattern,
    required,
    value,
  } = props;

  const [inputValue, setInputValue] = useState(value || "");

  useEffect(() => {
    if (value === 0) {
      setInputValue("0");
      return;
    }
    if (inputValue !== value?.toString()) setInputValue(value !== undefined ? value.toString() : "");
  }, [value]);

  return (
    <>
      {!!label && (
        <InputLabel error={!!error}>{label + (required ? "*" : "")}</InputLabel>
      )}
      <TextField
        color={color}
        fullWidth={fullWidth}
        required={required}
        disabled={disabled}
        placeholder={!readOnly ? placeholder : undefined}
        value={inputValue}
        onChange={(evt) => {
          let value = evt.target.value.replace(",", ".").slice(0, 15);
          evt.target.value = value;

          if (pattern && value && !pattern.test(value.toString())) {
            evt.target.value = value.slice(0, value.length - 1);
            return;
          }

          setInputValue(value);
          if (!value) return;

          const floatValue = parseFloat(value);
          if (!!value && !isNaN(floatValue)) {
            handleChange?.(floatValue);
          }
        }}
        onBlur={!readOnly ? () => {
          if (!inputValue) return handleChange?.();
        } : undefined}
        focused={readOnly ? false : undefined}
        InputProps={{
          readOnly,
          endAdornment
        }}
        inputProps={{pattern}}
        error={!!error}
        helperText={error}/>
    </>
  );
};

export default NumberTextFieldComponent;
