import React from 'react';
import {Accordion, AccordionActions, AccordionDetails, AccordionSummary, Stack, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface AccordionComponentProps {
  actions?: JSX.Element | JSX.Element[];
  content?: JSX.Element | JSX.Element[];
  description?: string;
  expended?: boolean;
  title: string;
  variant?: "dispatch";
}

const AccordionComponent = (props: AccordionComponentProps) => {
  const {
    actions,
    content,
    description,
    expended,
    title,
    variant,
  } = props;

  return (
    <Accordion expanded={expended} variant={variant}>
      <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>}>
        <Stack direction="column" spacing={0.25}>
          <Typography fontSize={16} fontWeight={700} color="#0E2B73">{title}</Typography>
          {!!description && (<Typography fontSize={12} fontWeight={400}>{description}</Typography>)}
        </Stack>
      </AccordionSummary>
      {!!content && (<AccordionDetails>{content}</AccordionDetails>)}
      {!!actions && (
        <AccordionActions>{actions}</AccordionActions>
      )}
    </Accordion>
  );
};

export default AccordionComponent;
