import React from 'react';
import {InputLabel, TextField} from '@mui/material';
import {TFunctionResult} from "i18next";

interface TextFieldComponentProps {
  autoFocus?: boolean;
  label?: string;
  fullWidth?: boolean;
  required?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  color?: "primary" | "secondary";
  pattern?: RegExp;
  handleChange?: (value: string) => void;
  handleClick?: () => void;
  disabled?: boolean;
  minRows?: number;
  multiline?: boolean;
  rows?: number;
  type?: string;
  value?: string | number | TFunctionResult;
  error?: string;
  endAdornment?: JSX.Element;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const TextFieldComponent = (props: TextFieldComponentProps) => {
  const {
    autoFocus = false,
    color = "primary",
    endAdornment,
    error,
    fullWidth = true,
    handleChange,
    handleClick,
    label,
    placeholder,
    readOnly,
    disabled,
    minRows,
    pattern,
    required,
    type,
    value,
    multiline,
    rows,
    onKeyDown
  } = props;

  return (
    <>
      {!!label && <InputLabel error={!!error}>{label}{(required) ? '*' : ''}</InputLabel>}
      <TextField
        autoFocus={autoFocus}
        color={color}
        fullWidth={fullWidth}
        multiline={multiline}
        minRows={minRows}
        rows={rows}
        required={required}
        disabled={disabled}
        placeholder={placeholder || label}
        type={type}
        onClick={handleClick}
        value={value}
        onChange={(evt) => {
          let value = evt.target.value;

          if (pattern && value && !pattern.test(value.toString())) {
            evt.target.value = value.slice(0, value.length - 1);
            return;
          }

          handleChange?.(value);
        }}
        onKeyDown={onKeyDown}
        focused={readOnly ? false : undefined}
        InputProps={{readOnly, "aria-label": handleClick ? "clickable" : undefined, endAdornment}}
        inputProps={{pattern}}
        error={!!error}
        helperText={error}
      />
    </>
  );
};

export default TextFieldComponent;
