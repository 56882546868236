import {Pk, TrackElement} from "@store/project";
import {IncompatibilityError} from "@utils/incompatibilities/incompatibility.utils";

import checkNoCCIOutZT from "./NO_CCI_OUT_ZT/noCCIOutZT";
import checkInZT20mCCI from "./IN_ZT_20M_CCI/inZT20mCCI";
import checkNoOpenPNinCCI from "./NO_OPEN_PN_IN_CCI/noOpenPNinCCI";
import {ID} from "@datorama/akita";

interface GetS9A3CCIIncParams {
  trackElementId?: ID;
  pkStart?: Pk;
  pkEnd?: Pk;
  ZTs?: TrackElement[];
  PNs?: TrackElement[];
}

export const getS9A3CCIInc = (params: GetS9A3CCIIncParams): IncompatibilityError[] => {
  const {pkStart, pkEnd, trackElementId, ZTs = [], PNs = []} = params;

  if (!pkStart || !pkEnd) return [];

  const incompatibilities: IncompatibilityError[] = [];

  incompatibilities.push(...checkNoCCIOutZT({trackElementId, pkStart, pkEnd, ZTs}));

  incompatibilities.push(...checkInZT20mCCI({trackElementId, pkStart, pkEnd, ZTs}));

  incompatibilities.push(...checkNoOpenPNinCCI({trackElementId, pkStart, pkEnd, PNs}));

  return incompatibilities;
};
