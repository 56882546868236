import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement, WorkTrainDirectionEnum} from "@store/project";
import {DispatchTypeEnum, ZTDispatchType} from "@store/dispatches/dispatch.model";
import {ID} from "@datorama/akita";
import _ from 'lodash';

interface CheckNoZTOpenAuthorizationInTTxMovementParams {
  trackElementId?: ID;
  fromDispatch?: boolean;
  pkStart: Pk;
  pkEnd: Pk;
  TTx: TrackElement[];
  status?: ZTDispatchType;
}

/* Should not be able to authorize ZT opening if TTx is passing through it */
const checkNoZTOpenAuthorizationInTTxMovement = (params: CheckNoZTOpenAuthorizationInTTxMovementParams): IncompatibilityError[] => {
  const {fromDispatch, pkStart, pkEnd, TTx, status, trackElementId} = params;

  if (fromDispatch && status === DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_OPEN_A_WORK_AREA) {
    const TTxPathOnZT = TTx.filter((t) => {
      if (!t.attributes?.finalPk || !t.attributes?.direction) return false;

      const TTxStart = t.attributes.direction === WorkTrainDirectionEnum.RIGHT_TO_LEFT ? t.attributes.finalPk : t.pkStart;
      const TTxEnd = t.attributes.direction === WorkTrainDirectionEnum.RIGHT_TO_LEFT ? Pk.addMeters(t.pkStart, t.attributes.TTXLength ?? 0) : t.attributes.finalPk;

      return (Pk.isPkBetweenPks(pkStart, TTxStart, TTxEnd) ||
          Pk.isPkBetweenPks(pkEnd, TTxStart, TTxEnd) ||
          Pk.isPkBetweenPks(TTxStart, pkStart, pkEnd) ||
          Pk.isPkBetweenPks(TTxEnd, pkStart, pkEnd)) &&
        !_.isEqual(pkStart, TTxEnd) &&
        !_.isEqual(pkEnd, TTxStart);
    });

    if (!!TTxPathOnZT.length) {
      return [{
        error: IncompatibilityErrorEnum.NO_ZT_OPEN_AUTHORIZATION_IN_TTX_MOVEMENT,
        trackElementId,
        concernedTrackElementIds: TTxPathOnZT.map((t) => t.id),
      }];
    }
  }

  return [];
};

export default checkNoZTOpenAuthorizationInTTxMovement;
