import React, {useState} from 'react';
import SelectComponent, {SelectComponentItem} from "@components/select/Select.component";
import {
  CreateDispatch,
  DispatchTypeEnum,
  installationPnDispatchTypes,
  trackChangeDispatchTypes,
  trainingZoneDispatchTypes,
  workAreaDispatchTypes,
  worksiteMachineDispatchTypes,
  workTrainDispatchTypes
} from "@store/dispatches/dispatch.model";
import OpeningRequestWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {Box, Stack} from "@mui/material";
import OpeningAuthorizationWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningAuthorizationWorkAreaForm";
import OpeningNoticeWorkAreaForm from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningNoticeWorkAreaForm";
import RestartingStandbyAuthorizationWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/RestartingStandbyAuthorizationWorkAreaForm";
import ClosingWorkAreaForm from "@screens/auth/common/schema/forms/dispatches/workArea/ClosingWorkArea.form";
import {useTranslation} from "react-i18next";
import {projectQuery, TrackElement, TrackElementType} from "@store/project";
import StandbySetWorkArea from "@screens/auth/common/schema/forms/dispatches/workArea/StandbySetWorkArea.form";
import {FieldErrors} from "@utils/yup.utils";
import RestartingStandbyRequestWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/RestartingStandbyRequestWorkAreaForm";
import ModificationRequestWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/ModificationRequestWorkAreaForm";
import ModificationAuthorizationWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/ModificationAuthorizationWorkAreaForm";
import ModificationNoticeWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/ModificationNoticeWorkAreaForm";
import SplittingForm from "@screens/auth/common/schema/forms/dispatches/workTrain/Splitting.form";
import PostSplittingForm from "@screens/auth/common/schema/forms/dispatches/workTrain/PostSplitting.form";
import GroupingForm from "@screens/auth/common/schema/forms/dispatches/workTrain/Grouping.form";
import MovementTransferForm from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementTransfer.form";
import MovementDeliveryRouteForm
  from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementDeliveryRoute.form";
import MovementEngagementForm from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementEngagement.form";
import MovementReleaseForm from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementRelease.form";
import MovementRemovalForm from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementRemoval.form";
import MovementImmobilizationTTxForm
  from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementImmobilizationTTx.form";
import MovementResetForm from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementReset.form";
import PnTerminationForm from "@screens/auth/common/schema/forms/dispatches/pn/PnTermination.form";
import PnRecoveryMonitoringForm from "@screens/auth/common/schema/forms/dispatches/pn/PnRecoveryMonitoring.form";
import PnRequestToClosePnForm from "@screens/auth/common/schema/forms/dispatches/pn/PnRequestToClosePn.form";
import PnAuthorizationToClosePnForm
  from "@screens/auth/common/schema/forms/dispatches/pn/PnAuthorizationToClosePn.form";
import PnNoticeToClosePnForm from "@screens/auth/common/schema/forms/dispatches/pn/PnNoticeToClosePn.form";
import PnRequestToReopenPnForm from "@screens/auth/common/schema/forms/dispatches/pn/PnRequestToReopenPn.form";
import PnAuthorizationToReopenPnForm
  from "@screens/auth/common/schema/forms/dispatches/pn/PnAuthorizationToReopenPn.form";
import PnConfirmReopenPnForm from "@screens/auth/common/schema/forms/dispatches/pn/PnConfirmReopenPn.form";
import PnAttestReopenedForm from "@screens/auth/common/schema/forms/dispatches/pn/PnAttestReopened.form";
import AuthorizationTrainingZoneForm
  from "@screens/auth/common/schema/forms/dispatches/trainingZone/AuthorizationTrainingZone.form";
import ModificationTrainingZoneForm
  from "@screens/auth/common/schema/forms/dispatches/trainingZone/ModificationTrainingZone.form";
import ConfirmationInitialDemarcationTrainingZoneForm
  from "@screens/auth/common/schema/forms/dispatches/trainingZone/ConfirmationInitialDemarcationTrainingZone.form";
import EndMissionTrainingZoneForm
  from "@screens/auth/common/schema/forms/dispatches/trainingZone/EndMissionTrainingZone.form";
import MovementWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/MovementWorksiteMachine.form";
import EndMovementWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/EndMovementWorksiteMachine.form";
import UnenrailementWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/UnenrailementWorksiteMachine.form";
import EnrailementWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/EnrailementWorksiteMachine.form";
import EngagementWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/EngagementWorksiteMachine.form";
import ReleaseWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/ReleaseWorksiteMachine.form";
import ImmobilizationWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/ImmobilizationWorksiteMachine.form";
import ManeuverTrackChangeForm from "@screens/auth/common/schema/forms/dispatches/trackChange/ManeuverTrackChange.form";
import MovementEngagementArrivalForm
  from '@screens/auth/common/schema/forms/dispatches/workTrain/MovementEngagementArrival.form';
import MovementEngagementAuthorizationForm
  from '@screens/auth/common/schema/forms/dispatches/workTrain/MovementEngagementAuthorization.form';

interface DispatchFormProps {
  trackElement: TrackElement;
  dispatch: CreateDispatch;
  setDispatch: (dispatch: CreateDispatch) => void;
  handleReset: () => void;
  errors: FieldErrors | undefined;
}

const DispatchForm = (props: DispatchFormProps) => {

    const {t} = useTranslation();
    const {handleReset, trackElement, dispatch, setDispatch, errors} = props;

    const [typeSelected, setTypeSelected] = useState<DispatchTypeEnum>();

    const dispatchFormSelected = (trackElement: TrackElement) => {
      switch (typeSelected) {
        case DispatchTypeEnum.WORK_ZONE_REQUEST_TO_OPEN_A_WORK_AREA:
          return <OpeningRequestWorkAreaForm editable={true} setDispatch={setDispatch} dispatch={dispatch}
                                             trackElement={trackElement} errors={errors}/>;
        case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_OPEN_A_WORK_AREA:
          return <OpeningAuthorizationWorkAreaForm dispatch={dispatch} setDispatch={setDispatch}
                                                   trackElement={trackElement} editable={true} errors={errors}/>;
        case DispatchTypeEnum.WORK_ZONE_NOTICE_OF_OPENING_OF_A_WORK_AREA:
          return <OpeningNoticeWorkAreaForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                            editable={true} errors={errors}/>;
        case DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_RESTARTING_A_WORK_AREA_IN_STANDBY:
          return <RestartingStandbyRequestWorkAreaForm trackElement={trackElement} setDispatch={setDispatch}
                                                       dispatch={dispatch} editable={true} errors={errors}/>;
        case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_RESTARTING_A_WORK_AREA_IN_STANDBY:
          return <RestartingStandbyAuthorizationWorkAreaForm trackElement={trackElement} setDispatch={setDispatch}
                                                             dispatch={dispatch} editable={true} errors={errors}/>;
        case DispatchTypeEnum.WORK_ZONE_CLOSURE_OF_A_WORK_AREA:
          return <ClosingWorkAreaForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                      editable={true} errors={errors}/>;
        case DispatchTypeEnum.WORK_ZONE_SET_STANDBY_WORK_AREA:
          return <StandbySetWorkArea trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                     editable={true} errors={errors}/>;
        case DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA:
          return <ModificationRequestWorkAreaForm trackElement={trackElement} setDispatch={setDispatch}
                                                  dispatch={dispatch} editable={true} errors={errors}/>;
        case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_MODIFY_THE_WORK_AREA:
          return <ModificationAuthorizationWorkAreaForm trackElement={trackElement} setDispatch={setDispatch}
                                                        dispatch={dispatch} editable={true} errors={errors}/>;
        case DispatchTypeEnum.WORK_ZONE_NOTICE_OF_MODIFICATION_OF_THE_WORK_AREA:
          return <ModificationNoticeWorkAreaForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                                 editable={true} errors={errors}/>;
        case DispatchTypeEnum.SPLITTING_SPLITTING:
          return <SplittingForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch} editable={true}
                                errors={errors}/>;
        case DispatchTypeEnum.SPLITTING_POST_SPLITTING:
          return <PostSplittingForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                    editable={true} errors={errors}/>;
        case DispatchTypeEnum.SPLITTING_GROUPING:
          return <GroupingForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch} editable={true}
                               errors={errors}/>;
        case DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE:
          return <MovementDeliveryRouteForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                            editable={true} errors={errors}/>;
        case DispatchTypeEnum.MOVEMENT_ENGAGEMENT:
          return <MovementEngagementForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                         editable={true} errors={errors}/>;
        case DispatchTypeEnum.MOVEMENT_ENGAGEMENT_AUTHORIZATION:
          return <MovementEngagementAuthorizationForm trackElement={trackElement} setDispatch={setDispatch}
                                                      dispatch={dispatch}
                                                      editable={true} errors={errors}/>;
        case DispatchTypeEnum.MOVEMENT_ENGAGEMENT_ARRIVAL:
          return <MovementEngagementArrivalForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                                editable={true} errors={errors}/>;
        case DispatchTypeEnum.MOVEMENT_RELEASE:
          return <MovementReleaseForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                      editable={true} errors={errors}/>;
        case DispatchTypeEnum.MOVEMENT_REMOVAL:
          return <MovementRemovalForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                      editable={true} errors={errors}/>;
        case DispatchTypeEnum.MOVEMENT_RESET:
          return <MovementResetForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                    editable={true} errors={errors}/>;
        case DispatchTypeEnum.MOVEMENT_IMMOBILIZATION_TTX:
          return <MovementImmobilizationTTxForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                                editable={true} errors={errors}/>;
        case DispatchTypeEnum.MOVEMENT_TRANSFER:
          return <MovementTransferForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                       editable={true} errors={errors}/>;
        // PN
        case DispatchTypeEnum.PN_TERMINATION:
          return <PnTerminationForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                    editable={true} errors={errors}/>;
        case DispatchTypeEnum.PN_RECOVERY_MONITORING:
          return <PnRecoveryMonitoringForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                           editable={true} errors={errors}/>;
        case DispatchTypeEnum.PN_REQUEST_TO_CLOSE_PN:
          return <PnRequestToClosePnForm trackElement={trackElement} setDispatch={setDispatch}
                                         dispatch={dispatch}
                                         editable={true} errors={errors}/>;
        case DispatchTypeEnum.PN_AUTHORIZATION_TO_CLOSE_PN:
          return <PnAuthorizationToClosePnForm trackElement={trackElement} setDispatch={setDispatch}
                                               dispatch={dispatch}
                                               editable={true} errors={errors}/>;
        case DispatchTypeEnum.PN_NOTICE_TO_CLOSE_PN:
          return <PnNoticeToClosePnForm trackElement={trackElement} setDispatch={setDispatch}
                                        dispatch={dispatch}
                                        editable={true} errors={errors}/>;
        case DispatchTypeEnum.PN_REQUEST_TO_REOPEN_PN:
          return <PnRequestToReopenPnForm trackElement={trackElement} setDispatch={setDispatch}
                                          dispatch={dispatch}
                                          editable={true} errors={errors}/>;
        case DispatchTypeEnum.PN_AUTHORIZATION_TO_REOPEN_PN:
          return <PnAuthorizationToReopenPnForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                                editable={true} errors={errors}/>;
        case DispatchTypeEnum.PN_NOTICE_TO_REOPEN_PN:
          return <PnConfirmReopenPnForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                        editable={true} errors={errors}/>;
        case DispatchTypeEnum.PN_ATTEST_REOPENED:
          return <PnAttestReopenedForm trackElement={trackElement} setDispatch={setDispatch} dispatch={dispatch}
                                       editable={true} errors={errors}/>;
        case DispatchTypeEnum.AUTHORIZATION_TRAINING_ZONE:
          return <AuthorizationTrainingZoneForm trackElement={trackElement} dispatch={dispatch} editable={true}
                                                setDispatch={setDispatch} errors={errors}/>;
        case DispatchTypeEnum.MODIFICATION_TRAINING_ZONE:
          return <ModificationTrainingZoneForm trackElement={trackElement} dispatch={dispatch} editable={true}
                                               setDispatch={setDispatch} errors={errors}/>;
        case DispatchTypeEnum.CONFIRMATION_INITIAL_DEMARCATION_TRAINING_ZONE:
          return <ConfirmationInitialDemarcationTrainingZoneForm trackElement={trackElement} dispatch={dispatch}
                                                                 editable={true} setDispatch={setDispatch}
                                                                 errors={errors}/>;
        case DispatchTypeEnum.END_MISSION_TRAINING_ZONE:
          return <EndMissionTrainingZoneForm trackElement={trackElement} dispatch={dispatch} editable={true}
                                             setDispatch={setDispatch} errors={errors}/>;
        case DispatchTypeEnum.MOVEMENT_WORKSITE_MACHINE:
          return <MovementWorksiteMachineForm trackElement={trackElement} dispatch={dispatch} editable={true}
                                              setDispatch={setDispatch} errors={errors}/>
        case DispatchTypeEnum.END_MOVEMENT_WORKSITE_MACHINE:
          return <EndMovementWorksiteMachineForm trackElement={trackElement} dispatch={dispatch} editable={true}
                                                 setDispatch={setDispatch} errors={errors}/>
        case DispatchTypeEnum.UNENRAILMENT_WORKSITE_MACHINE:
          return <UnenrailementWorksiteMachineForm trackElement={trackElement} dispatch={dispatch} editable={true}
                                                   setDispatch={setDispatch} errors={errors}/>
        case DispatchTypeEnum.ENRAILMENT_WORKSITE_MACHINE:
          return <EnrailementWorksiteMachineForm trackElement={trackElement} dispatch={dispatch} editable={true}
                                                 setDispatch={setDispatch} errors={errors}/>
        case DispatchTypeEnum.ENGAGEMENT_WORKSITE_MACHINE:
          return <EngagementWorksiteMachineForm trackElement={trackElement} dispatch={dispatch} editable={true}
                                                setDispatch={setDispatch} errors={errors}/>
        case DispatchTypeEnum.RELEASE_WORKSITE_MACHINE:
          return <ReleaseWorksiteMachineForm trackElement={trackElement} dispatch={dispatch} editable={true}
                                             setDispatch={setDispatch} errors={errors}/>
        case DispatchTypeEnum.IMMOBILIZATION_WORKSITE_MACHINE:
          return <ImmobilizationWorksiteMachineForm trackElement={trackElement} dispatch={dispatch} editable={true}
                                                    setDispatch={setDispatch} errors={errors}/>
        case DispatchTypeEnum.MANEUVER_TRACK_CHANGE:
          return <ManeuverTrackChangeForm trackElement={trackElement} dispatch={dispatch} editable={true}
                                          setDispatch={setDispatch} errors={errors}/>
        default:
          return null;
      }
    }

    const dispatchesTypeToBeSelected = (trackElement: TrackElement): SelectComponentItem[] => {
      switch (trackElement.type) {
        case TrackElementType.WORK_TRAIN:
          return workTrainDispatchTypes;
        case TrackElementType.WORK_ZONE:
          return workAreaDispatchTypes;
        case TrackElementType.PN:
          return installationPnDispatchTypes;
        case TrackElementType.TRAINING_ZONE:
          return trainingZoneDispatchTypes;
        case TrackElementType.WORKSITE_MACHINE:
          return worksiteMachineDispatchTypes;
        case TrackElementType.TRACK_CHANGE:
          return trackChangeDispatchTypes;
        default:
          return [];
      }
    }

    return (
      <Stack spacing={2}>
        <Box width={'100%'}>
          <SelectComponent
            label={t(`dispatches.form.${projectQuery.isProjectTypeNew ? "typeDispatchNew" : "typeDispatch"}`)}
            items={dispatchesTypeToBeSelected(trackElement)}
            handleChange={(type) => {
              handleReset();
              setTypeSelected(type);
            }}
            value={typeSelected || ''}
          />
        </Box>
        {dispatchFormSelected(trackElement)}
      </Stack>
    );
  }
;

export default DispatchForm;
