import React from "react";
import {Box} from "@mui/material";

import {SchemaParameters} from "@screens/auth/common/schema/Schema.component";

import {Colors} from "@utils/theme/constants.utils";

interface ScaleComponentProps {
  params: SchemaParameters;
  stepStart: number;
}

const ScaleComponent = (props: ScaleComponentProps) => {
  const {params, stepStart} = props;

  const {kilometerSpacing, meterSpacing, navigationScale: step, padding, realWidth, width} = params;

  return (
    <Box height="30px" width="100%" bgcolor={Colors.background}>
      <svg height="100%" width={width}>
        <g style={{transform: `translateX(${padding}px)`}}>
          <defs>
            <filter x="-0.15" y="-0.125" width="1.3" height="1.2" id="removebackground">
              <feFlood floodColor={Colors.primary}/>
            </filter>
          </defs>
          {Array.from(Array((step * 10) + 1)).map((_, s) => {
            const position = realWidth / (step * 10) * s;

            if (s % 10 === 0) {
              return (
                <g key={`PK ${stepStart + (s / 10)}`}>
                  <line
                    x1={kilometerSpacing * (s / 10)}
                    x2={kilometerSpacing * (s / 10)}
                    y1="0"
                    y2="100%"
                    strokeWidth="3px"
                    stroke={Colors.primary}/>
                  <use xlinkHref="#scalePk" x={kilometerSpacing * (s / 10)} filter="url(#removebackground)"/>
                  <text
                    id="scalePk"
                    x={kilometerSpacing * (s / 10)}
                    y="50%"
                    fill={Colors.white}
                    fontSize={12}
                    fontWeight="700"
                    dominantBaseline="middle"
                    textAnchor="middle">
                    {stepStart + (s / 10)}
                  </text>
                </g>
              );
            }

            if (s % 10 === 5) {
              return (
                <g key={`PK ${stepStart + (s / 10)} + 500`}>
                  <line
                    x1={meterSpacing * s * 100}
                    x2={meterSpacing * s * 100}
                    y1="0"
                    y2="6"
                    strokeWidth="1px"
                    opacity={0.3}
                    stroke={Colors.primary}/>
                  <line
                    x1={meterSpacing * s * 100}
                    x2={meterSpacing * s * 100}
                    y1="24"
                    y2="30"
                    strokeWidth="1px"
                    opacity={0.3}
                    stroke={Colors.primary}/>
                  <text
                    id="meterScale"
                    x={meterSpacing * s * 100}
                    y="50%"
                    fill={Colors.primary}
                    fontSize={12}
                    fontWeight="700"
                    dominantBaseline="middle"
                    textAnchor="middle">
                    500
                  </text>
                </g>
              );
            }

            return (
              <g key={`PK ${stepStart + (s / 10)} + ${(s + 1) * 100}`}>
                <line
                  x1={position}
                  x2={position}
                  y1="0"
                  y2="4"
                  strokeWidth="1px"
                  stroke={Colors.primary}/>
                <line
                  x1={position}
                  x2={position}
                  y1="26"
                  y2="30"
                  strokeWidth="1px"
                  stroke={Colors.primary}/>
              </g>
            );
          })}
        </g>
      </svg>
    </Box>
  );
}

export default ScaleComponent;