import React, {useMemo} from "react";
import {CircularProgress, Stack} from '@mui/material';
import {Chart} from "react-google-charts";
import {Colors} from '@utils/theme/constants.utils';

interface StackedBarChartProps<T> {
  colors?: string[];
  data: [string, number[], T][];
  labels: string[];
  loading?: boolean;
  getTooltip?: (data: T) => string;
}

const StackedBarChart = <T, >(props: StackedBarChartProps<T>) => {
  const {colors, data, labels, loading, getTooltip} = props;

  const formattedData = useMemo(() => {
    return [
      [
        labels[0],
        ...(getTooltip ? [{role: "tooltip", type: "string", p: {html: true}}] : []),
        ...labels.slice(1),
      ],
      ...data.map((x) =>
        !!getTooltip ? [x[0], getTooltip(x[2]), ...x[1]] : [x[0], ...x[1]]
      ),
    ];
  }, [data, labels]);

  return (
    <Stack flex={1} position="relative" width="100%" alignItems="center" justifyContent="center">
      {loading ? (
        <CircularProgress/>
      ) : (
        <Chart
          width="100%"
          height="100%"
          chartType="ColumnChart"
          data={formattedData}
          options={{
            fontName: 'Axiforma, sans-serif',
            fontSize: 14,
            colors,
            isStacked: true,
            focusTarget: 'category',
            legend: {
              alignment: 'center',
              position: 'top',
            },
            hAxis: {
              textStyle: {color: Colors.black},
            },
            vAxis: {
              minValue: 20,
              textStyle: {color: Colors.primary, bold: true},
            },
            tooltip: {
              isHtml: true,
              trigger: !!getTooltip ? 'selection' : 'none',
              textStyle: {color: Colors.black},
            },
          }}
        />
      )}
    </Stack>
  );
}

export default StackedBarChart;