import React, {useEffect} from 'react';
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {useTranslation} from "react-i18next";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {Grid, InputLabel} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {sessionQuery} from "@store/session";

const PnAuthorizationToReopenPnForm = (props: DispatchFormGlobalProps) => {

  const {t} = useTranslation();

  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const updateDispatchContent = (name: string) => (value: any) => setDispatch({
    ...dispatch,
    dispatch: {...dispatch.dispatch, [name]: value}
  });

  useEffect(() => {
    setDispatch({
      type: DispatchTypeEnum.PN_AUTHORIZATION_TO_REOPEN_PN,
      dispatch: {
        from: `${sessionQuery.getValue().user?.firstname || ''} ${sessionQuery.getValue().user?.lastname || ''}`,
        to: trackElement?.attributes.APN,
        pnNbr: trackElement?.attributes?.name,
        date: new Date(),
        hour: new Date()
      }
    });
  }, [])

  return (
    <div>
      <Grid container direction={"row"} columnSpacing={2.5} rowSpacing={1.5}>
        <Grid item xs={4}>
          <TextFieldComponent
            label={t('schema.pn.RELF')}
            readOnly={!editable}
            placeholder=" "
            value={dispatch?.dispatch?.from || ''}
            handleChange={updateDispatchContent("from")}
            error={errors["from"]}/>
        </Grid>

        <Grid item xs={4}>
          <TextFieldComponent
            label={`${t('schema.pn.to')} ${t('schema.pn.APN')}`}
            readOnly={!editable}
            placeholder=" "
            value={dispatch?.dispatch?.to || ''}
            handleChange={updateDispatchContent("to")}
            error={errors["to"]}/>
        </Grid>

        <Grid item xs={8}>
          <TextFieldComponent
            label={`${t('schema.pn.pleaseRestoreProtectiveDevices')} ${t('schema.pn.ofPn')} N°`}
            readOnly={!editable}
            placeholder=" "
            value={dispatch?.dispatch?.pnNbr || ""}
            handleChange={updateDispatchContent("pnNbr")}
            error={errors["pnNbr"]}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel sx={{mx: '14px', textAlign: "left", whiteSpace: 'pre-line'}}>
            {t('schema.pn.andRemovePnRemoveAndExecutionSigns')}
          </InputLabel>
        </Grid>

        <Grid item xs={4}>
          <DatePickerComponent
            label={t('schema.pn.date')}
            readOnly={!editable}
            value={dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("date")}
            error={errors["date"]}/>
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            label={t('schema.pn.time')}
            readOnly={!editable}
            value={editable ? dispatch?.dispatch?.hour : dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("hour")}
            error={errors["hour"]}/>
        </Grid>

      </Grid>
    </div>
  );
};

export default PnAuthorizationToReopenPnForm;
