import React, {useEffect, useState} from "react";
import {DragDropContext, DropResult} from "react-beautiful-dnd";
import {Box, Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";

import {projectQuery, projectService, TrackElement} from "@store/project";

import ModalComponent from "@components/modals/Modal.component";
import DraggableTracksComponent
  from "@screens/auth/common/schema/components/tracksDistribution/DraggableTracks.component";
import {finalize} from "rxjs";

export interface TracksDistribution {
  unplacedTracks: TrackElement[];
  distribution: TrackElement[][];
}

interface TracksDistributionModalProps {
  handleClose: () => void;
}

const TracksDistributionModal = (props: TracksDistributionModalProps) => {
  const {handleClose} = props;

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [tracksDistribution, setTracksDistribution] = useState<TracksDistribution>();

  const getTracksDistribution = (tracks: TrackElement[]): TracksDistribution => {
    const yMax = Math.max(...tracks.map((track) => track.attributes?.yIndex || 0));

    let distribution: TrackElement[][] = Array.from(Array(yMax)).map(() => []);
    let unplacedTracks: TrackElement[] = [];

    tracks.forEach((track) => {
      if (!!track.attributes?.yIndex || track.attributes?.yIndex === 0) {
        const row = distribution[track.attributes.yIndex] || [];
        distribution[track.attributes.yIndex] = row.concat(track);
        return;
      }

      unplacedTracks.push(track);
    });

    return {
      unplacedTracks,
      distribution,
    };
  };

  useEffect(() => {
    const _tracks$ = projectQuery.projectTracks$.subscribe((tracks) => {
      setTracksDistribution(getTracksDistribution(tracks));
    });

    return () => {
      _tracks$.unsubscribe();
    };
  }, []);

  if (!tracksDistribution) return null;

  const updateTracksDistribution = (name: string) => (value: any) => setTracksDistribution({
    ...tracksDistribution,
    [name]: value
  });

  const handleValidate = () => {
    let tracksToUpdate: TrackElement[] = [];

    tracksDistribution.distribution.forEach((row, rowIndex) => {
      tracksToUpdate = tracksToUpdate.concat(row.map((track) => ({
        ...track,
        attributes: {
          ...track.attributes,
          yIndex: rowIndex,
        },
      })));
    });

    setLoading(true);
    projectService.updateTracks(tracksToUpdate).pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("schema.distribution.success"), {variant: "success"});
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const endRow = +result.destination.droppableId.split(" ")[1];

    let newTracksDistribution = {...tracksDistribution};

    if (result.type === "ROWS") {
      const newDistribution = Array.from(newTracksDistribution.distribution);
      const [removed] = newDistribution.splice(result.source.index, 1);
      newDistribution.splice(result.destination.index, 0, removed);

      setTracksDistribution({...tracksDistribution, distribution: newDistribution});
      return;
    }

    let track: TrackElement;
    if (result.source.droppableId === "unplacedTracks") {
      track = newTracksDistribution.unplacedTracks[result.source.index];

      newTracksDistribution.unplacedTracks = newTracksDistribution.unplacedTracks.filter((t) => t.id !== track.id);
    } else {
      const startRow = +result.source.droppableId.split(" ")[1];

      track = newTracksDistribution.distribution[startRow][result.source.index];

      let startRowDistribution = newTracksDistribution.distribution[startRow];
      startRowDistribution = startRowDistribution.filter((t) => t.id !== track.id);
      newTracksDistribution.distribution[startRow] = startRowDistribution;
    }

    newTracksDistribution.distribution[endRow] = (newTracksDistribution.distribution[endRow] || []).concat(track);

    setTracksDistribution(newTracksDistribution);
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("schema.distribution.title")}
      content={(
        <Box width="100%" py="20px">
          <DragDropContext onDragEnd={handleDragEnd}>
            <DraggableTracksComponent
              deleteRow={(rowIndex) => {
                const distribution = tracksDistribution.distribution;

                const unchangedRows = [...distribution.slice(0, rowIndex)];
                let rowsToUpdate = [...distribution.slice(rowIndex + 1)];
                rowsToUpdate = rowsToUpdate.map((row, index) =>
                  row.map((t) => ({...t, attributes: {...t.attributes, yIndex: rowIndex + index}}))
                );

                updateTracksDistribution("distribution")([...unchangedRows, ...rowsToUpdate]);
              }}
              tracksDistribution={tracksDistribution}
              disabled={loading}
              addRow={() => updateTracksDistribution("distribution")(tracksDistribution.distribution.concat([[]]))}/>
          </DragDropContext>
        </Box>
      )}
      actions={(
        <Button variant="contained" color="primary" disabled={loading} onClick={handleValidate}>
          {t("global.modify")}
        </Button>
      )}/>
  );
}

export default TracksDistributionModal;