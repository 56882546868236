import React, {useMemo, useState} from "react";
import {Box, Button, Grid, Stack} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import {
  CreateOrUpdateTrackElement,
  PK_INPUT_REGEXP,
  PNType,
  projectQuery,
  TrackElementType,
  WorkTrainStatus
} from "@store/project";
import SelectComponent from "@components/select/Select.component";
import {useTranslation} from "react-i18next";
import {FieldErrors} from "@utils/yup.utils";
import CreatePNAcquittalForm from "@screens/auth/common/dispatch/createPNAcquittal/createPNAcquittal.from";

interface InstallationPnFormProps {
  readOnly?: boolean;
  setTrackElement: (trackElement: CreateOrUpdateTrackElement) => void;
  trackElement: CreateOrUpdateTrackElement;
  errors: FieldErrors;
  IncompatibilitiesAcceptation?: (() => JSX.Element) | null;
}

const InstallationPnForm = (props: InstallationPnFormProps) => {
  const {errors, readOnly, setTrackElement, trackElement, IncompatibilitiesAcceptation} = props;

  const {t} = useTranslation();

  const [showPNAcquittal, setShowPNAcquittal] = useState(false);

  const updateTrackElement = (name: string) => (value: any) => setTrackElement({...trackElement, [name]: value});
  const updateAttributes = (name: string) => (value: any) =>
    setTrackElement({
      ...trackElement,
      attributes: {...trackElement.attributes, [name]: value},
    });

  const TTXWaitingForPNAcquittal = useMemo(() => {
    return projectQuery.projectTTX.find(
      (ttx) => ttx.attributes?.waitingForPNId === trackElement.id && ttx.attributes?.status === WorkTrainStatus.WAITING_FOR_PN_ACQUITTAL
    );
  }, [trackElement]);

  return (
    <Stack
      flex={1}
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      pt="20px"
      pb="40px"
      overflow="auto"
      className="scrollable">
      <Grid container width="90%" spacing={2} mx={"auto"}>
        <Grid item xs={12}>
          <TextFieldComponent
            readOnly={readOnly}
            label={t("schema.installation.name")}
            handleChange={updateAttributes("name")}
            value={trackElement.attributes?.name || ""}
            error={errors["attributes.name"]}
          />
        </Grid>
        {trackElement.type === TrackElementType.PN && (
          <Grid item xs={12}>
            <SelectComponent
              readOnly={readOnly}
              label={t("schema.installation.PNType")}
              placeholder={t("schema.installation.PNTypePlaceholder")}
              handleChange={updateAttributes("type")}
              value={trackElement.attributes?.type || ""}
              error={errors["attributes.type"]}
              items={PNType.pnTypes}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextFieldComponent
            readOnly={readOnly}
            label={t("schema.installation.pk")}
            handleChange={updateTrackElement("pkStart")}
            pattern={PK_INPUT_REGEXP}
            value={trackElement.pkStart || ""}
            error={errors.pkStart}
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldComponent
            readOnly={readOnly}
            label={t("schema.pn.nameAPN")}
            value={trackElement.attributes?.APN || ""}
            handleChange={updateAttributes("APN")}
            error={errors["attributes.APN"]}
          />
        </Grid>
      </Grid>
      {TTXWaitingForPNAcquittal && (
        <Box mt="50px">
          <Button
            variant="contained"
            color="primary"
            disabled={readOnly}
            onClick={() => setShowPNAcquittal(true)}>
            {t("schema.pn.PNAcquittal.title")}
          </Button>
        </Box>
      )}
      {IncompatibilitiesAcceptation && (
        <Stack pt="20px">
          <IncompatibilitiesAcceptation/>
        </Stack>
      )}
      {showPNAcquittal && TTXWaitingForPNAcquittal && (
        <CreatePNAcquittalForm
          handleClose={() => setShowPNAcquittal(false)}
          trackElement={trackElement}
          TTXWaitingForAcquittal={TTXWaitingForPNAcquittal}
        />
      )}
    </Stack>
  );
};

export default InstallationPnForm;
