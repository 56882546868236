import React, {useEffect} from 'react';
import {
  DispatchFormGlobalProps
} from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import {Grid} from "@mui/material";
import TextFieldComponent from "@components/textField/TextField.component";
import {Pk, PK_INPUT_REGEXP} from "@store/project";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";
import {DispatchTypeEnum} from "@store/dispatches/dispatch.model";
import {sessionQuery} from "@store/session";
import NumberTextFieldComponent from "@components/textField/NumberTextField.component";
import {ordersService} from "@store/dispatches/orders.service";
import {useParams} from "react-router-dom";

const ModificationTrainingZoneForm = (props: DispatchFormGlobalProps) => {

  const {trackElement, dispatch, editable, setDispatch, errors = {}} = props;

  const {projectId} = useParams();

  const updateDispatchContent = (name: string) => (value: any) => setDispatch({
    ...dispatch,
    dispatch: {...dispatch.dispatch, [name]: value}
  });

  useEffect(() => {
    ordersService.getDispatchNumber(projectId!).subscribe({
      next: (dispatchNbr) => {
        setDispatch({
          type: DispatchTypeEnum.MODIFICATION_TRAINING_ZONE,
          dispatch: {
            nbr: trackElement?.attributes?.name,
            to: trackElement?.attributes?.RZF,
            from: `${sessionQuery.getValue().user?.firstname || ''} ${sessionQuery.getValue().user?.lastname || ''}`,
            date: new Date(),
            hour: new Date(),
            pkStart: trackElement?.pkStart ? Pk.toString(trackElement.pkStart) : undefined,
            pkEnd: trackElement?.pkEnd ? Pk.toString(trackElement.pkEnd) : undefined,
            deliveredDispatchNbr: dispatchNbr,
          }
        });
      },
    });
  }, [])


  return (
    <div>
      <Grid container columnSpacing={2.5} rowSpacing={1.5}>

        <Grid item xs={4}>
          <TextFieldComponent label={"M./Mme RELF"}
                              readOnly={!editable}
                              value={dispatch?.dispatch?.from || ''}
                              handleChange={updateDispatchContent("from")}
                              error={errors["from"]}/>
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent label={"M./Mme RZF"}
                              readOnly={!editable}
                              value={dispatch?.dispatch?.to || ''}
                              handleChange={updateDispatchContent("to")}
                              error={errors["to"]}/>
        </Grid>

        <Grid item xs={6}>
          <TextFieldComponent label={"Je vous autorise l'extension Zone de Formation"} placeholder={""}
                              readOnly={!editable}
                              value={dispatch?.dispatch?.nbr || ''}
                              handleChange={updateDispatchContent("nbr")}
                              error={errors["nbr"]}/>
        </Grid>

        <Grid item xs={3}>
          <TextFieldComponent
            label={"Du Km"}
            readOnly={!editable}
            handleChange={updateDispatchContent("pkStart")}
            pattern={PK_INPUT_REGEXP}
            value={dispatch?.dispatch?.pkStart || ""}
            error={errors["pkStart"]}/>
        </Grid>

        <Grid item xs={3}>
          <TextFieldComponent
            label={"Au Km"}
            readOnly={!editable}
            handleChange={updateDispatchContent("pkEnd")}
            pattern={PK_INPUT_REGEXP}
            value={dispatch?.dispatch?.pkEnd || ""}
            error={errors["pkEnd"]}/>
        </Grid>

        <Grid item xs={4}>
          <DatePickerComponent
            label={"Le"}
            readOnly={!editable}
            value={dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("date")}
            error={errors["date"]}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePickerComponent
            label={"à"}
            readOnly={!editable}
            value={editable ? dispatch?.dispatch?.hour : dispatch?.dispatch?.date}
            handleChange={updateDispatchContent("hour")}
            error={errors["hour"]}
          />
        </Grid>
        <Grid item xs={4}/>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={"N° de dépêche délivré"}
            readOnly={!editable}
            handleChange={updateDispatchContent("deliveredDispatchNbr")}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            value={dispatch?.dispatch?.deliveredDispatchNbr}
            error={errors["deliveredDispatchNbr"]}/>
        </Grid>
        <Grid item xs={4}>
          <NumberTextFieldComponent
            label={"N° de dépêche reçu"}
            readOnly={!editable}
            handleChange={updateDispatchContent("receivedDispatchNbr")}
            pattern={new RegExp(/^(\d*|\d+)$/)}
            value={dispatch?.dispatch?.receivedDispatchNbr}
            error={errors["receivedDispatchNbr"]}/>
        </Grid>

      </Grid>
    </div>
  );
};

export default ModificationTrainingZoneForm;
