import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Box, Button, Grid, Stack} from "@mui/material";
import {useSnackbar} from "notistack";
import {finalize} from "rxjs";

import {CreateUser, Role, usersService} from "@store/users";

import {FieldErrors} from "@utils/yup.utils";
import {createUserValidation} from "@screens/auth/OS/users/createUser/createUser.yup";

import ModalComponent from "@components/modals/Modal.component";
import TextFieldComponent from "@components/textField/TextField.component";
import SelectComponent from "@components/select/Select.component";
import SelectFileComponent from "@components/selectFile/SelectFile.component";
import DatePickerComponent from "@components/datePicker/DatePicker.component";
import {sessionQuery} from "@store/session";

const CreateUserModal = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState<Partial<CreateUser>>({});
  const [errors, setErrors] = useState<FieldErrors>({});

  const handleClose = () => navigate("..");

  const updateUser = (name: string) => (value: any) => setUser({...user, [name]: value});

  const handleValidate = () => {
    const errors = createUserValidation(user);

    setErrors(errors);

    if (Object.keys(errors).length) return;

    setLoading(true);
    usersService.createUser(user).pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("users.success.created"), {variant: "success"});
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  }

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("users.create.title")}
      description={t("users.create.description")}
      content={(
        <Stack width="450px" mt="15px" spacing={4}>
          <Box>
            <Grid container columnSpacing={2.5} rowSpacing={1.5}>
              <Grid item xs={12}>
                <TextFieldComponent
                  label={t("users.fields.lastname")}
                  handleChange={updateUser("lastname")}
                  value={user.lastname || ""}
                  error={errors.lastname}
                  required/>
              </Grid>
              <Grid item xs={12}>
                <TextFieldComponent
                  label={t("users.fields.firstname")}
                  handleChange={updateUser("firstname")}
                  value={user.firstname || ""}
                  error={errors.firstname}
                  required/>
              </Grid>
              <Grid item xs={12}>
                <TextFieldComponent
                  label={t("users.fields.email")}
                  handleChange={updateUser("email")}
                  value={user.email || ""}
                  error={errors.email}
                  required/>
              </Grid>
              <Grid item xs={6}>
                <TextFieldComponent
                  label={t("users.fields.password")}
                  handleChange={updateUser("password")}
                  value={user.password || ""}
                  error={errors.password}
                  type="password"
                  required/>
              </Grid>
              <Grid item xs={6}>
                <TextFieldComponent
                  label={t("users.fields.confirmPassword")}
                  handleChange={updateUser("confirmPassword")}
                  value={user.confirmPassword || ""}
                  error={errors.confirmPassword}
                  type="password"
                  required/>
              </Grid>
              <Grid item xs={12}>
                <SelectComponent
                  label={t("users.fields.function")}
                  placeholder={t("users.fields.functionPlaceholder")}
                  handleChange={updateUser("role")}
                  items={Role.selectItems.filter((role) => sessionQuery.hasSufficientRoleOrAbove(role.data as Role))}
                  value={user.role || ""}
                  error={errors.role}
                  required/>
              </Grid>
              {user.role !== Role.CLIENT && (
                <>

                  <Grid item xs={6}>
                    <SelectFileComponent
                      label={t("users.fields.trainingCertificate")}
                      placeholder={t("users.fields.importFile")}
                      accept=".pdf"
                      handleFileSelected={updateUser("formationLocalFile")}
                      file={user.formationLocalFile}
                      error={errors.formationLocalFile}
                      required/>
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerComponent
                      label={t("users.fields.fileExpirationDate")}
                      value={user.formationExpireDate}
                      handleChange={updateUser("formationExpireDate")}
                      disablePast
                      error={errors.formationExpireDate}
                      required/>
                  </Grid>
                  <Grid item xs={6}>
                    <SelectFileComponent
                      label={t("users.fields.qualificationTitle")}
                      placeholder={t("users.fields.importFile")}
                      accept=".pdf"
                      handleFileSelected={updateUser("habilitationLocalFile")}
                      file={user.habilitationLocalFile}
                      error={errors.habilitationLocalFile}
                      required/>
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerComponent
                      label={t("users.fields.fileExpirationDate")}
                      value={user.habilitationExpireDate}
                      handleChange={updateUser("habilitationExpireDate")}
                      disablePast
                      error={errors.habilitationExpireDate}
                      required/>
                  </Grid>
                  <Grid item xs={6}>
                    <SelectFileComponent
                      label={t("users.fields.medicalVisit")}
                      placeholder={t("users.fields.importFile")}
                      accept=".pdf"
                      handleFileSelected={updateUser("medicalVisitLocalFile")}
                      file={user.medicalVisitLocalFile}
                      error={errors.medicalVisitLocalFile}
                      required/>
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerComponent
                      label={t("users.fields.fileExpirationDate")}
                      value={user.medicalVisitExpireDate}
                      handleChange={updateUser("medicalVisitExpireDate")}
                      disablePast
                      error={errors.medicalVisitExpireDate}
                      required/>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Stack>
      )}
      actions={(
        <Button variant="contained" color="primary" disabled={loading} onClick={handleValidate}>
          {t("global.finish")}
        </Button>
      )}/>
  );
};

export default CreateUserModal;
