import React from "react";
import {DataGrid, GridColumns, GridRowsProp, useGridApiContext} from "@mui/x-data-grid";
import {withStyles, WithStyles} from "@mui/styles";
import {Pagination} from "@mui/material";

import style from "./Table.style";

const PAGE_SIZE = 20

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const {state} = apiRef.current;

  return (
    <Pagination
      color="primary"
      size="small"
      count={state.pagination.pageCount}
      page={state.pagination.page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

interface TableProps extends WithStyles<typeof style> {
  columns: GridColumns;
  data: GridRowsProp;
  disablePagination?: boolean;
  handlePageChange?: (page: number) => void;
  loading?: boolean;
  page?: number;
  pageCount?: number;
  onRowClick?: (param: any) => void;
}

const Table = (props: TableProps) => {
  const {columns, data, disablePagination, handlePageChange, loading, page, pageCount, classes, onRowClick} = props;

  return (
    <div className={classes.container}>
      <DataGrid
        disableColumnMenu
        disableColumnFilter
        hideFooterSelectedRowCount
        isRowSelectable={() => false}
        headerHeight={40}
        components={{
          Pagination: CustomPagination
        }}
        page={page}
        rowCount={pageCount ? PAGE_SIZE * pageCount : undefined}
        onPageChange={handlePageChange}
        onRowClick={(params) => onRowClick && onRowClick(params)}
        rowHeight={40}
        loading={loading}
        pageSize={!disablePagination ? PAGE_SIZE : undefined}
        paginationMode={!disablePagination ? "server" : undefined}
        filterMode="server"
        rows={data}
        columns={columns.map((col) => ({...col, sortable: false}))}
      />
    </div>
  )
}

export default withStyles(style)(Table);