import React, {useState} from 'react';
import {Box, Button, FormHelperText, InputLabel} from "@mui/material";
import {useTranslation} from "react-i18next";
import {withStyles, WithStyles} from "@mui/styles";

import {MAX_FILE_SIZE, UploadedFile} from "@store/files";

import style from "./SelectFile.style";

interface SelectFileComponentProps extends WithStyles<typeof style> {
  accept?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  readOnly?: boolean
  size?: number;
  file?: File;
  currentFile?: UploadedFile;
  handleFileSelected?: (file?: File) => void;
  error?: string;
}

const SelectFileComponent = (props: SelectFileComponentProps) => {
  const {
    accept,
    classes,
    currentFile,
    error,
    file,
    handleFileSelected,
    label,
    placeholder,
    required,
    size,
  } = props;

  const {t} = useTranslation();

  const [importError, setImportError] = useState<string | undefined>();

  const inputFileRef = React.useRef<HTMLInputElement | null>(null);

  return (
    <>
      <input
        type="file"
        accept={accept || ".pdf, .png, .jpg, .jpeg"}
        ref={inputFileRef}
        style={{display: 'none'}}
        onChange={(e) => {
          const file = e.target.files?.[0];

          if (file && (file.size / 1024 / 1024) < MAX_FILE_SIZE) {
            setImportError(undefined);
            handleFileSelected?.(e.target.files?.[0]);
            e.currentTarget.value = "";
          } else {
            setImportError(t("errors.fileSize", {fileSize: MAX_FILE_SIZE}));
          }
        }}
      />
      {!!label && <InputLabel error={!!error}>{label}{(required) ? '*' : ''}</InputLabel>}
      <Box className={classes.selectFile} sx={{height: size}}>
        <Button
          variant="text"
          className={file || currentFile ? classes.fileButton : classes.fileButtonPlaceholder}
          onClick={() => inputFileRef.current?.click()}
          endIcon={<img alt="" src="/images/upload_dark_icon.svg"/>}>
          <div className={classes.fileName}>{file?.name || currentFile?.fileName || placeholder}</div>
        </Button>
      </Box>
      {(error || importError) && <FormHelperText error>{error || importError}</FormHelperText>}
    </>
  );
};

export default withStyles(style)(SelectFileComponent);
