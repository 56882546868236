import {catchError, finalize, from, iif, map, Observable, of, switchMap, tap} from "rxjs";
import {
  CreateDispatch,
  Dispatch,
  DispatchHistory,
  DispatchTypeEnum,
  GetDispatchHistory,
  Order,
  TodayDispatchHistory,
  TrackElementHistory,
} from "@store/dispatches/dispatch.model";
import APIAxios, {APIRoutes} from "@api/axios.api";
import {ID} from "@datorama/akita";
import {AxiosError, AxiosResponse} from "axios";
import SnackError from "@utils/error.utils";
import {ordersStore, OrdersStore} from "@store/dispatches/orders.store";
import {Pk, projectService} from "@store/project";
import {DispatchFiltersState} from "@store/dispatches/filters.store";
import {mergeDateAndHour} from "@utils/date.utils";

export class OrdersService {
  constructor(private store: OrdersStore) {
  }

  getOrders(trackElementId: ID): Observable<Order[]> {
    return from(APIAxios(APIRoutes.GETOrder(trackElementId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<Order[]>) => {
        return response.data;
      }),
      tap((orders) => {
        this.store.set(orders);
      })
    );
  }

  createOrder = (trackElementId: ID, name: string) => {
    return from(
      APIAxios({
        ...APIRoutes.POSTOrder(trackElementId),
        data: {name},
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      switchMap(() => this.getOrders(trackElementId))
    );
  };

  sendDispatch(trackElementId: ID, orderId: ID, dispatch: CreateDispatch, incompatibilities?: string[]): Observable<any> {
    return from(
      APIAxios({
        ...APIRoutes.POSTDispatch(orderId),
        data: {
          ...dispatch,
          dispatch: {
            ...dispatch.dispatch,
            pkStart: Pk.fromString(dispatch.dispatch?.pkStart),
            pkEnd: Pk.fromString(dispatch.dispatch?.pkEnd),
            route: dispatch.dispatch?.route ? dispatch.dispatch.route.map((r) => Pk.fromString(r)) : undefined,
            pkImmobilization: Pk.fromString(dispatch.dispatch?.pkImmobilization),
            date: mergeDateAndHour(dispatch.dispatch?.date, dispatch.dispatch?.hour),
            dateFrom: mergeDateAndHour(dispatch.dispatch?.dateFrom, dispatch.dispatch?.dateFromHour),
            toDate: mergeDateAndHour(dispatch.dispatch?.toDate, dispatch.dispatch?.toHour),
            standByDate: mergeDateAndHour(dispatch.dispatch?.standByDate, dispatch.dispatch?.standByHour),
            closingDate: mergeDateAndHour(dispatch.dispatch?.closingDate, dispatch.dispatch?.closingHour),
            splittingParts: dispatch.dispatch?.splittingParts?.map((sp) => ({
              ...sp,
              pkStart: Pk.fromString(sp.pkStart),
              date: mergeDateAndHour(sp.date, sp.hour),
            }))
          },
          incompatibilities,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<any>) => {
        return response.data;
      }),
      switchMap(() => projectService.getProjectTrackElements()),
      switchMap(() =>
        iif(
          () => !!dispatch.type && [
            DispatchTypeEnum.WORK_ZONE_CLOSURE_OF_A_WORK_AREA,
            DispatchTypeEnum.SPLITTING_POST_SPLITTING,
            DispatchTypeEnum.SPLITTING_GROUPING
          ].includes(dispatch.type),
          iif(() => dispatch.type === DispatchTypeEnum.WORK_ZONE_CLOSURE_OF_A_WORK_AREA,
            projectService.getProjectClosedZTToday().pipe(map(() => false)),
            of(false),
          ),
          this.getOrders(trackElementId).pipe(map(() => true))
        )
      )
    );
  }

  setDispatchDatesFilters = (filters?: DispatchFiltersState) => {
    return {
      startDate: mergeDateAndHour(filters?.startDate, filters?.startHour),
      endDate: mergeDateAndHour(filters?.endDate, filters?.endHour),
    };
  }

  getTodaysDispatches(projectId: ID, page: number, filters?: DispatchFiltersState): Observable<TodayDispatchHistory> {
    this.store.setLoading(true);

    const dateFilters = this.setDispatchDatesFilters(filters);

    return from(
      APIAxios({
        ...APIRoutes.GETTodayDispatches(projectId),
        params: {
          search: filters?.search ?? undefined,
          category: filters?.dispatchCategory ?? undefined,
          nature: filters?.dispatchNature ?? undefined,
          startDate: dateFilters?.startDate?.toISOString() ?? undefined,
          endDate: dateFilters?.endDate?.toISOString() ?? undefined,
          page,
          order: "ASC",
          take: 20,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<TodayDispatchHistory>) => {
        return response.data;
      }),
      finalize(() => this.store.setLoading(false))
    );
  }

  getTodayDispatchCSV = (projectId: ID, filters?: DispatchFiltersState): Observable<Blob> => {
    const dateFilters = this.setDispatchDatesFilters(filters);

    return from(
      APIAxios({
        ...APIRoutes.GETTodayDispatchCSV(projectId),
        params: {
          search: filters?.search ?? undefined,
          category: filters?.dispatchCategory ?? undefined,
          nature: filters?.dispatchNature ?? undefined,
          startDate: dateFilters?.startDate?.toISOString() ?? undefined,
          endDate: dateFilters?.endDate?.toISOString() ?? undefined,
          order: "ASC",
        },
        responseType: "blob",
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Blob>) => {
        return response.data;
      })
    );
  };

  getDispatchHistory(projectId: ID, page: number, filters?: DispatchFiltersState): Observable<GetDispatchHistory> {
    this.store.setLoading(true);

    const dateFilters = this.setDispatchDatesFilters(filters);

    return from(
      APIAxios({
        ...APIRoutes.GETDispatchHistory(),
        params: {
          projectId,
          search: filters?.search ?? undefined,
          category: filters?.dispatchCategory ?? undefined,
          nature: filters?.dispatchNature ?? undefined,
          startDate: dateFilters?.startDate?.toISOString() ?? undefined,
          endDate: dateFilters?.endDate?.toISOString() ?? undefined,
          page,
          order: "ASC",
          take: 20,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<GetDispatchHistory>) => {
        return response.data;
      }),
      finalize(() => this.store.setLoading(false))
    );
  }

  getFreeDispatchDetails(dispatchId: ID): Observable<DispatchHistory> {
    return from(APIAxios(APIRoutes.GETOneDispatch(dispatchId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<DispatchHistory>) => {
        return response.data;
      }),
      finalize(() => this.store.setLoading(false))
    );
  }

  getTrackElementHistory(projectId: ID, page: number, filters?: DispatchFiltersState): Observable<TrackElementHistory> {
    this.store.setLoading(true);

    const dateFilters = this.setDispatchDatesFilters(filters);

    return from(
      APIAxios({
        ...APIRoutes.GETTrackElementHistory(),
        params: {
          projectId,
          search: filters?.search ?? undefined,
          category: filters?.trackElementHistoryCategory ? [filters.trackElementHistoryCategory] : undefined,
          type: filters?.trackElementHistoryType ?? undefined,
          startDate: dateFilters?.startDate?.toISOString() ?? undefined,
          endDate: dateFilters?.endDate?.toISOString() ?? undefined,
          page,
          order: "ASC",
          take: 20,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<TrackElementHistory>) => {
        return response.data;
      }),
      finalize(() => this.store.setLoading(false))
    );
  }

  getDispatchesByOrder(orderId: ID): Observable<Dispatch[]> {
    return from(APIAxios(APIRoutes.GETDispatchesByOrder(orderId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<Dispatch[]>) => {
        return response.data;
      }),
      finalize(() => this.store.setLoading(false))
    );
  }

  getDispatchNumber = (projectId: ID): Observable<number> => {
    return from(APIAxios(APIRoutes.GETDispatchNbr(projectId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<number>) => {
        return response.data;
      })
    );
  };

  closeOrder = (orderId: ID, trackElementId: ID, code: string): Observable<Order[]> => {
    return from(
      APIAxios({
        ...APIRoutes.PATCHCloseOrder(orderId),
        data: {code},
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      switchMap(() => this.getOrders(trackElementId))
    );
  };

  getTrackElementCSV = (projectId: ID, filters?: DispatchFiltersState): Observable<Blob> => {
    const dateFilters = this.setDispatchDatesFilters(filters);

    return from(
      APIAxios({
        ...APIRoutes.GETTrackElementCSV(),
        params: {
          projectId,
          search: filters?.search ?? undefined,
          category: filters?.trackElementHistoryCategory ? [filters.trackElementHistoryCategory] : undefined,
          type: filters?.trackElementHistoryType ?? undefined,
          startDate: dateFilters?.startDate?.toISOString() ?? undefined,
          endDate: dateFilters?.endDate?.toISOString() ?? undefined,
          order: "ASC",
        },
        responseType: "blob",
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Blob>) => {
        return response.data;
      })
    );
  };

  getDispatchHistoryCSV = (projectId: ID, filters?: DispatchFiltersState): Observable<Blob> => {
    const dateFilters = this.setDispatchDatesFilters(filters);

    return from(
      APIAxios({
        ...APIRoutes.GETDispatchHistoryCSV(),
        params: {
          projectId,
          search: filters?.search ?? undefined,
          category: filters?.dispatchCategory ?? undefined,
          nature: filters?.dispatchNature ?? undefined,
          startDate: dateFilters?.startDate?.toISOString() ?? undefined,
          endDate: dateFilters?.endDate?.toISOString() ?? undefined,
          order: "ASC",
        },
        responseType: "blob",
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Blob>) => {
        return response.data;
      })
    );
  };

  getAllDispatchCSV = (projectId: ID): Observable<Blob> => {
    return from(
      APIAxios({...APIRoutes.GETProjectDispatchesCSV(projectId.toString()), responseType: "blob"})
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Blob>) => {
        return response.data;
      })
    );
  }

  getAllDispatchesByProject = (projectId: ID): Observable<Dispatch[]> => {
    return from(APIAxios(APIRoutes.GETAllDispatchesByProject(projectId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<Dispatch[]>) => {
        return response.data;
      })
    );
  }
}

export const ordersService = new OrdersService(ordersStore);
