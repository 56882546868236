import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import {ID} from "@datorama/akita";

interface CheckInZT20mCCIParams {
  trackElementId?: ID;
  pkStart: Pk;
  pkEnd: Pk;
  ZTs: TrackElement[];
}

/* Should not be able to set the CCI less than 20 meters of a ZT if encompassed  */
const checkInZT20mCCI = (params: CheckInZT20mCCIParams): IncompatibilityError[] => {
  const {trackElementId, pkStart, pkEnd, ZTs} = params;

  const ZTLessThan20Meters = ZTs.filter(
    (t) =>
      (Pk.isAfterOrEqualPk(pkStart, t.pkStart) && Pk.isBeforePk(pkStart, Pk.addMeters(t.pkStart, 20))) ||
      (!!t.pkEnd && Pk.isAfterPk(pkEnd, Pk.addMeters(t.pkEnd, -20)) && Pk.isBeforeOrEqualPk(pkEnd, t.pkEnd))
  );

  if (ZTLessThan20Meters.length) {
    return ZTLessThan20Meters.map((ZT) => ({
      error: IncompatibilityErrorEnum.IN_ZT_20M_CCI,
      trackElementId,
      concernedTrackElementIds: [ZT.id],
    }));
  }

  return [];
};

export default checkInZT20mCCI;
