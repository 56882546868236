import React, {useEffect, useState} from "react";
import {Outlet, useParams} from "react-router-dom";
import {withStyles, WithStyles} from "@mui/styles";
import {Button, debounce, Stack} from "@mui/material";
import {t} from "i18next";
import {useSnackbar} from "notistack";
import dayjs from "dayjs";

import {trackElementsColumns} from "./table.utils";
import Table from "@components/table/Table";

import {TrackElementHistory} from "@store/dispatches/dispatch.model";
import {ordersService} from "@store/dispatches/orders.service";
import {ordersQuery} from "@store/dispatches/orders.query";
import {dispatchFiltersService} from "@store/dispatches/filters.service";
import {projectQuery} from "@store/project";
import {DispatchFiltersState} from "@store/dispatches/filters.store";
import {dispatchFiltersQuery} from "@store/dispatches/filters.query";
import {filesService} from "@store/files";

import style from "./RelfRegisterTab.style";
import SearchBar from "@components/textField/SearchBar.component";
import DispatchDrawer, {FiltersType} from "@screens/auth/common/dispatch/Dispatch.drawer";

interface RelfRegisterProps extends WithStyles<typeof style> {
  isClientView?: boolean;
}

const RelfRegisterTab = (props: RelfRegisterProps) => {
  const {classes, isClientView} = props;

  const {enqueueSnackbar} = useSnackbar();

  const {projectId} = useParams();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<DispatchFiltersState>({});

  const [activeDrawer, setActiveDrawer] = useState<"filter" | undefined>(undefined);

  const [trackElements, setTrackElements] = useState<TrackElementHistory>();

  useEffect(() => {
    return () => {
      if (isClientView) dispatchFiltersService.resetStore();
    };
  }, [isClientView]);

  useEffect(() => {
    window.addEventListener("RELOAD_TRACK_HISTORY", () => {
      if (projectId) {
        ordersService.getTrackElementHistory(projectId, page + 1, filters).subscribe(setTrackElements);
      }
    });

    return () => {
      window.removeEventListener("RELOAD_TRACK_HISTORY", () => {
      });
    };
  }, []);

  useEffect(() => {
    if (projectId) {
      ordersService.getTrackElementHistory(projectId, page + 1, filters).subscribe(setTrackElements);
    }

    const _loading$ = ordersQuery.selectLoading().subscribe(setLoading);

    return () => {
      _loading$.unsubscribe();
    };
  }, [page, projectId, filters]);

  useEffect(() => {
    const _filters$ = dispatchFiltersQuery.filters$.subscribe(setFilters);

    return () => {
      _filters$.unsubscribe();
    };
  }, []);

  const handleDownloadTrackElementCSV = () => {
    if (!projectId) return;
    ordersService.getTrackElementCSV(projectId, filters).subscribe({
      next: (res) => {
        filesService.downloadFile(res, `registre-${projectQuery.isProjectTypeNew ? 'opel' : 'relf'}_${projectQuery.projectId}_${dayjs().format("DD-MM-YYYY")}.csv`);
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  return (
    <>
      <div className={classes.tableContainer}>
        {!isClientView ? (
          <Stack direction="row" spacing={2} className={classes.tableButtons}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleDownloadTrackElementCSV}
              startIcon={<img src="/images/export_icon.svg"/>}>
              {t("dispatches.export")}
            </Button>
          </Stack>
        ) : (
          <Stack direction="row" spacing={2} mb="20px">
            <SearchBar
              placeholder={t(`dispatches.${projectQuery.isProjectTypeNew ? "searchNew" : "search"}`)}
              color="secondary"
              handleChange={debounce((value) => {
                dispatchFiltersService.setFilters({search: value})
              }, 300)}/>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setActiveDrawer("filter")}
              startIcon={<img src="/images/filter_icon.svg" alt=""/>}>
              {t("dispatches.filter")}
            </Button>
          </Stack>
        )}
        <Table
          loading={loading}
          columns={trackElementsColumns(projectQuery.isProjectTypeNew)}
          page={page}
          pageCount={trackElements?.meta.pageCount || 1}
          handlePageChange={setPage}
          data={trackElements?.data || []}
        />
      </div>
      <Outlet/>
      {activeDrawer && (
        <DispatchDrawer
          handleClose={() => setActiveDrawer(undefined)}
          type={FiltersType.RELF}/>
      )}
    </>
  );
};

export default withStyles(style)(RelfRegisterTab);
