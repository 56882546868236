import React, {useState} from "react";
import {Button, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {finalize} from "rxjs";

import ModalComponent from "@components/modals/Modal.component";
import {projectService} from "@store/project";

const CloseProjectModal = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);

  const handleClose = () => navigate("..");

  const handleValidate = () => {
    setLoading(true);
    projectService.closeProject().pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("projectDetails.projectInfo.projectInfo.success.closed"), {variant: "success"});
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  }

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("projectDetails.projectInfo.projectInfo.close.title")}
      content={(
        <Stack spacing={4} mt="20px">
          <img alt="success" height="80px" src="/images/close_project_icon.svg"/>
          <Typography fontSize={16} fontWeight="400" whiteSpace="pre-line" textAlign="center" lineHeight={1.5}>
            {t("projectDetails.projectInfo.projectInfo.close.description")}
          </Typography>
        </Stack>
      )}
      actions={(
        <Button variant="contained" color="primary" disabled={loading} onClick={handleValidate}>
          {t("global.close")}
        </Button>
      )}/>
  );
}

export default CloseProjectModal;