import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import {Pk, TrackElement} from "@store/project";
import _ from "lodash";
import {ID} from "@datorama/akita";

interface checkNoLWinViaductOrTunnelParams {
  trackElementId?: ID;
  tracks?: ID[];
  pkStart: Pk;
  pkEnd: Pk;
  viaducts: TrackElement[];
  tunnels: TrackElement[];
}

/* Should not be able to set the LW Pks inside a viaduct ot a tunnel's Pks */
const checkNoLWinViaductOrTunnel = (params: checkNoLWinViaductOrTunnelParams): IncompatibilityError[] => {
  const {pkStart, pkEnd, viaducts, tunnels, trackElementId, tracks} = params;

  if (!tracks) return [];

  const LWInViaductsOrTunnels = [...tunnels, ...viaducts].filter(
    (t) =>
      !!t.pkEnd &&
      (Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd) ||
        Pk.isPkBetweenPks(pkEnd, t.pkStart, t.pkEnd) ||
        Pk.isPkBetweenPks(t.pkStart, pkStart, pkEnd) ||
        Pk.isPkBetweenPks(t.pkEnd, pkStart, pkEnd)) &&
      !_.isEqual(pkStart, t.pkEnd) &&
      !_.isEqual(pkEnd, t.pkStart) &&
      t.tracks.some((tId) => tracks.includes(tId))
  );

  if (LWInViaductsOrTunnels.length) {
    return LWInViaductsOrTunnels.map((viaductOrTunnel) => ({
      error: IncompatibilityErrorEnum.NO_LW_IN_VIA_OR_TUN,
      trackElementId,
      concernedTrackElementIds: [viaductOrTunnel.id],
    }));
  }

  return [];
};

export default checkNoLWinViaductOrTunnel;
