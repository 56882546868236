import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Grid, Stack} from "@mui/material";

import {CreateOrUpdateTrackElement, PK_INPUT_REGEXP, projectQuery, WorkTrainStatus} from "@store/project";
import {FieldErrors} from "@utils/yup.utils";
import SelectComponent from "@components/select/Select.component";
import TextFieldComponent from "@components/textField/TextField.component";
import CreateADVAcquittalForm from "@screens/auth/common/dispatch/createADVAcquittal/createADVAcquittal.form";

interface TrackDeviceFormProps {
  setTrackElement: (trackElement: CreateOrUpdateTrackElement) => void;
  trackElement: CreateOrUpdateTrackElement;
  errors: FieldErrors;
  readOnly?: boolean;
  IncompatibilitiesAcceptation?: (() => JSX.Element) | null;
}

const TrackDeviceTrackChangeForm = (props: TrackDeviceFormProps) => {
  const {errors, setTrackElement, trackElement, readOnly, IncompatibilitiesAcceptation} = props;

  const {t} = useTranslation();

  const [showADVAcquittal, setShowADVAcquittal] = useState(false);

  const updateTrackElement = (name: string) => (value: any) => setTrackElement({...trackElement, [name]: value});
  const updateAttributes = (name: string) => (value: any) =>
    setTrackElement({
      ...trackElement,
      attributes: {...trackElement.attributes, [name]: value},
    });

  const TTXWaitingForADVAcquittal = useMemo(() => {
    return projectQuery.projectTTX.find(
      (ttx) => ttx.attributes?.waitingForADVId === trackElement.id && ttx.attributes?.status === WorkTrainStatus.WAITING_FOR_ADV_ACQUITTAL
    );
  }, [trackElement]);

  return (
    <Stack
      flex={1}
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      pt="20px"
      pb="40px"
      overflow="auto"
      className="scrollable">
      <Grid container width="90%" columnSpacing={2.5} rowSpacing={1.5}>
        <Grid item xs={12}>
          <TextFieldComponent
            label={t("schema.trackDevice.trackChangeNumber")}
            handleChange={updateAttributes("name")}
            value={trackElement.attributes?.name || ""}
            readOnly={readOnly}
            error={errors["attributes.name"]}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectComponent
            label={t("schema.trackDevice.selectTrack")}
            placeholder={t("schema.trackDevice.selectTrackPlaceholder")}
            handleChange={(track) => {
              if (!trackElement.tracks) return;
              updateTrackElement("tracks")([trackElement.tracks?.[0] || "", track]);
            }}
            value={trackElement.tracks && trackElement.tracks.length > 1 ? trackElement.tracks[1] : ""}
            error={errors.tracks}
            readOnly={readOnly}
            items={projectQuery.projectTracks
              .filter((t) => t.id !== trackElement.tracks?.[0])
              .map((t) => ({label: t.attributes?.name || "-", value: t.id}))}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldComponent
            label={t("schema.trackDevice.startPK")}
            handleChange={updateTrackElement("pkStart")}
            pattern={PK_INPUT_REGEXP}
            value={trackElement.pkStart || ""}
            readOnly={readOnly}
            error={errors.pkStart}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldComponent
            label={t("schema.trackDevice.endPK")}
            handleChange={updateTrackElement("pkEnd")}
            pattern={PK_INPUT_REGEXP}
            value={trackElement.pkEnd || ""}
            readOnly={readOnly}
            error={errors.pkEnd}
          />
        </Grid>
      </Grid>
      {TTXWaitingForADVAcquittal && (
        <Box mt={"50px"}>
          <Button
            variant="contained"
            color="primary"
            disabled={readOnly}
            onClick={() => {
              setShowADVAcquittal(true);
            }}>
            {t("schema.trackDevice.ADVAcquittal.title")}
          </Button>
        </Box>
      )}
      {IncompatibilitiesAcceptation && (
        <Stack pt="20px">
          <IncompatibilitiesAcceptation/>
        </Stack>
      )}
      {showADVAcquittal && TTXWaitingForADVAcquittal && (
        <CreateADVAcquittalForm
          handleClose={() => setShowADVAcquittal(false)}
          trackElement={trackElement}
          TTXWaitingForAcquittal={TTXWaitingForADVAcquittal}
        />
      )}
    </Stack>
  );
};

export default TrackDeviceTrackChangeForm;
