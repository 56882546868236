import React, {useState} from "react";
import {Box, Button, Stack} from "@mui/material";
import {t} from "i18next";
import {peopleCountingCategories, PeopleCountingFilters} from "@store/reporting";

import DatePickerComponent from "@components/datePicker/DatePicker.component";
import SelectComponent from "@components/select/Select.component";
import RightDrawer from "@components/rightDrawer/RightDrawer.component";
import TimePickerComponent from "@components/datePicker/TimePicker.component";

interface PeopleCountingDrawerProps {
  filters?: PeopleCountingFilters;
  handleClose: () => void;
  handleReset: () => void;
  handleSave: (filters: PeopleCountingFilters) => void;
}

const PeopleCountingDrawer = (props: PeopleCountingDrawerProps) => {
  const {filters: propsFilters, handleClose, handleReset, handleSave} = props;

  const [filters, setFilters] = useState<PeopleCountingFilters>(propsFilters || {});

  return (
    <RightDrawer
      open
      title={t("reporting.peopleCounting.filter")}
      actions={(
        <Stack direction="row" spacing={2} alignSelf="center">
          <Box>
            <Button variant="outlined" color="primary" fullWidth onClick={() => {
              handleReset();
              handleClose();
            }}>{t("reporting.peopleCounting.filters.resetFilters")}</Button>
          </Box>
          <Box>
            <Button variant="contained" color="primary" fullWidth onClick={() => {
              handleSave(filters);
              handleClose();
            }}>{t("reporting.peopleCounting.filter")}</Button>
          </Box>
        </Stack>
      )}
      content={(
        <Stack spacing={1} pt="30px">
          <Box>
            <SelectComponent
              value={filters?.category || ""}
              handleChange={(category) => setFilters((state) => ({...state, category}))}
              label={t("reporting.peopleCounting.filters.type")}
              items={peopleCountingCategories}
              placeholder={t("reporting.peopleCounting.filters.typePlaceholder")}/>
          </Box>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box>
              <DatePickerComponent
                value={filters?.startDate}
                handleChange={(startDate) => setFilters((state) => ({
                  ...state,
                  startDate,
                  startHour: !startDate ? undefined : state.startHour
                }))}
                placeholder=" "
                label={t("reporting.peopleCounting.filters.fromDate")}/>
            </Box>
            <Box>
              <TimePickerComponent
                disabled={!filters.startDate}
                value={filters?.startHour}
                handleChange={(startHour) => setFilters((state) => ({...state, startHour}))}
                placeholder=" "
                label={t("reporting.peopleCounting.filters.at")}/>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box>
              <DatePickerComponent
                value={filters?.endDate}
                handleChange={(endDate) => setFilters((state) => ({
                  ...state,
                  endDate,
                  endHour: !endDate ? undefined : state.endHour
                }))}
                placeholder=" "
                label={t("reporting.peopleCounting.filters.toDate")}/>
            </Box>
            <Box>
              <TimePickerComponent
                disabled={!filters.endDate}
                value={filters?.endHour}
                handleChange={(endHour) => setFilters((state) => ({...state, endHour}))}
                placeholder=" "
                label={t("reporting.peopleCounting.filters.at")}/>
            </Box>
          </Stack>
        </Stack>
      )}
      handleClose={handleClose}/>
  )
}

export default PeopleCountingDrawer;