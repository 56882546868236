import React, {useEffect, useState} from "react";
import ModalComponent from "@components/modals/Modal.component";
import {Box, Button, Stack, Typography} from "@mui/material";
import AccordionComponent from "@components/accordion/Accordion.component";
import OpeningNoticeWorkAreaForm from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningNoticeWorkAreaForm";
import ClosingWorkAreaForm from "@screens/auth/common/schema/forms/dispatches/workArea/ClosingWorkArea.form";
import DispatchForm from "@screens/auth/common/schema/forms/dispatches/Dispatch.form";
import {useTranslation} from "react-i18next";
import {
  CreateDispatch,
  Dispatch,
  DispatchTypeEnum,
  installationPnDispatchTypes,
  Order,
  trackChangeDispatchTypes,
  trainingZoneDispatchTypes,
  workAreaDispatchTypes,
  worksiteMachineDispatchTypes,
  workTrainDispatchTypes,
} from "@store/dispatches/dispatch.model";
import {sessionQuery, sessionService} from "@store/session";
import OpeningRequestWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningRequestWorkAreaForm";
import OpeningAuthorizationWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/OpeningAuthorizationWorkAreaForm";
import RestartingStandbyAuthorizationWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/RestartingStandbyAuthorizationWorkAreaForm";
import StandbySetWorkArea from "@screens/auth/common/schema/forms/dispatches/workArea/StandbySetWorkArea.form";
import {useNavigate, useParams} from "react-router-dom";
import {Pk, projectQuery, TrackElement, TrackElementType} from "@store/project";
import {FieldErrors} from "@utils/yup.utils";
import {workAreaDispatchValidation} from "@screens/auth/common/schema/forms/dispatches/workArea/work-area.yup";
import RestartingStandbyRequestWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/RestartingStandbyRequestWorkAreaForm";
import ModificationRequestWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/ModificationRequestWorkAreaForm";
import ModificationAuthorizationWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/ModificationAuthorizationWorkAreaForm";
import ModificationNoticeWorkAreaForm
  from "@screens/auth/common/schema/forms/dispatches/workArea/ModificationNoticeWorkAreaForm";
import {ordersService} from "@store/dispatches/orders.service";
import {useSnackbar} from "notistack";
import {ordersQuery} from "@store/dispatches/orders.query";
import SplittingForm from "@screens/auth/common/schema/forms/dispatches/workTrain/Splitting.form";
import PostSplittingForm from "@screens/auth/common/schema/forms/dispatches/workTrain/PostSplitting.form";
import GroupingForm from "@screens/auth/common/schema/forms/dispatches/workTrain/Grouping.form";
import MovementDeliveryRouteForm
  from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementDeliveryRoute.form";
import MovementEngagementForm from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementEngagement.form";
import MovementReleaseForm from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementRelease.form";
import MovementRemovalForm from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementRemoval.form";
import MovementResetForm from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementReset.form";
import MovementImmobilizationTTxForm
  from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementImmobilizationTTx.form";
import MovementTransferForm from "@screens/auth/common/schema/forms/dispatches/workTrain/MovementTransfer.form";
import {workTrainDispatchValidation} from "@screens/auth/common/schema/forms/dispatches/workTrain/work-train.yup";
import PnTerminationForm from "@screens/auth/common/schema/forms/dispatches/pn/PnTermination.form";
import {pnDispatchValidation} from "@screens/auth/common/schema/forms/dispatches/pn/pn.yup";
import PnAttestReopenedForm from "@screens/auth/common/schema/forms/dispatches/pn/PnAttestReopened.form";
import PnConfirmReopenPnForm from "@screens/auth/common/schema/forms/dispatches/pn/PnConfirmReopenPn.form";
import PnAuthorizationToReopenPnForm
  from "@screens/auth/common/schema/forms/dispatches/pn/PnAuthorizationToReopenPn.form";
import PnRequestToReopenPnForm from "@screens/auth/common/schema/forms/dispatches/pn/PnRequestToReopenPn.form";
import PnNoticeToClosePnForm from "@screens/auth/common/schema/forms/dispatches/pn/PnNoticeToClosePn.form";
import PnAuthorizationToClosePnForm
  from "@screens/auth/common/schema/forms/dispatches/pn/PnAuthorizationToClosePn.form";
import PnRequestToClosePnForm from "@screens/auth/common/schema/forms/dispatches/pn/PnRequestToClosePn.form";
import PnRecoveryMonitoringForm from "@screens/auth/common/schema/forms/dispatches/pn/PnRecoveryMonitoring.form";
import {
  trainingZoneDispatchValidation
} from "@screens/auth/common/schema/forms/dispatches/trainingZone/training-zone.yup";
import AuthorizationTrainingZoneForm
  from "@screens/auth/common/schema/forms/dispatches/trainingZone/AuthorizationTrainingZone.form";
import ModificationTrainingZoneForm
  from "@screens/auth/common/schema/forms/dispatches/trainingZone/ModificationTrainingZone.form";
import ConfirmationInitialDemarcationTrainingZoneForm
  from "@screens/auth/common/schema/forms/dispatches/trainingZone/ConfirmationInitialDemarcationTrainingZone.form";
import EndMissionTrainingZoneForm
  from "@screens/auth/common/schema/forms/dispatches/trainingZone/EndMissionTrainingZone.form";
import {
  worksiteMachineDispatchValidation
} from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/worksiteMachine.yup";
import MovementWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/MovementWorksiteMachine.form";
import UnenrailementWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/UnenrailementWorksiteMachine.form";
import EnrailementWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/EnrailementWorksiteMachine.form";
import EngagementWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/EngagementWorksiteMachine.form";
import ReleaseWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/ReleaseWorksiteMachine.form";
import ImmobilizationWorksiteMachineForm
  from "@screens/auth/common/schema/forms/dispatches/worksiteMachine/ImmobilizationWorksiteMachine.form";
import ManeuverTrackChangeForm from "@screens/auth/common/schema/forms/dispatches/trackChange/ManeuverTrackChange.form";
import {trackChangeDispatchValidation} from "@screens/auth/common/schema/forms/dispatches/trackChange/trackChange.yup";
import ConfirmCodeModal from "@components/confirmCode/ConfirmCode.modal";

import useIncompatibilitiesAcceptation from "../../components/IncompatibilitiesAcceptation.component";
import {IncompatibilityError} from "@utils/incompatibilities/incompatibility.utils";
import {Role} from '@store/users';

const DispatchesModal = () => {
  const {trackElementId, orderId} = useParams();
  const {closeSnackbar, enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const [openCloseOrderConfirmCode, setOpenCloseOrderConfirmCode] = useState(false);
  const [openAddConfirmCode, setOpenAddConfirmCode] = useState<IncompatibilityError[] | false>(false);

  const [dispatch, setDispatch] = useState<CreateDispatch>({});

  const [trackElement, setTrackElement] = useState<TrackElement>();
  const [order, setOrder] = useState<Order>();

  const [errors, setErrors] = useState<FieldErrors>({});

  const {
    IncompatibilitiesAcceptation,
    disabledByIncompatibilities,
    incompatibilities
  } = useIncompatibilitiesAcceptation({
    trackElement,
    dispatch,
  });

  useEffect(() => {
    const _trackElement$ = projectQuery.projectTrackElementById$(trackElementId || "").subscribe(setTrackElement);

    const _order$ = ordersQuery.orderById$(orderId || "").subscribe(setOrder);

    return () => {
      _trackElement$.unsubscribe();
      _order$.unsubscribe();
      closeSnackbar();
    };
  }, []);

  useEffect(() => {
    if (!!order && !!trackElement && !ordersQuery.isDispatchInOrderLogic(dispatch, order, trackElement)) {
      enqueueSnackbar(t("dispatches.dispatchOutOfLogic"), {variant: "warning", autoHideDuration: null});
    } else {
      closeSnackbar();
    }
  }, [dispatch.type, enqueueSnackbar]);

  const [showNewDispatchesForm, setShowNewDispatchesForm] = useState(false);

  const {t} = useTranslation();

  const dispatchFormSelected = (dispatch: CreateDispatch, trackElement: TrackElement) => {
    switch (dispatch.type) {
      case DispatchTypeEnum.WORK_ZONE_REQUEST_TO_OPEN_A_WORK_AREA:
        return <OpeningRequestWorkAreaForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_OPEN_A_WORK_AREA:
        return <OpeningAuthorizationWorkAreaForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.WORK_ZONE_NOTICE_OF_OPENING_OF_A_WORK_AREA:
        return <OpeningNoticeWorkAreaForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_RESTARTING_A_WORK_AREA_IN_STANDBY:
        return <RestartingStandbyRequestWorkAreaForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_RESTARTING_A_WORK_AREA_IN_STANDBY:
        return <RestartingStandbyAuthorizationWorkAreaForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.WORK_ZONE_CLOSURE_OF_A_WORK_AREA:
        return <ClosingWorkAreaForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.WORK_ZONE_SET_STANDBY_WORK_AREA:
        return <StandbySetWorkArea setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.WORK_ZONE_REQUEST_FOR_MODIFICATION_OF_THE_WORK_AREA:
        return <ModificationRequestWorkAreaForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.WORK_ZONE_AUTHORIZATION_TO_MODIFY_THE_WORK_AREA:
        return <ModificationAuthorizationWorkAreaForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.WORK_ZONE_NOTICE_OF_MODIFICATION_OF_THE_WORK_AREA:
        return <ModificationNoticeWorkAreaForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.SPLITTING_SPLITTING:
        return <SplittingForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.SPLITTING_POST_SPLITTING:
        return <PostSplittingForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.SPLITTING_GROUPING:
        return <GroupingForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.MOVEMENT_DELIVERY_ROUTE:
        return <MovementDeliveryRouteForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.MOVEMENT_ENGAGEMENT:
        return <MovementEngagementForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.MOVEMENT_RELEASE:
        return <MovementReleaseForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.MOVEMENT_REMOVAL:
        return <MovementRemovalForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.MOVEMENT_RESET:
        return <MovementResetForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.MOVEMENT_IMMOBILIZATION_TTX:
        return <MovementImmobilizationTTxForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.MOVEMENT_TRANSFER:
        return <MovementTransferForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.PN_TERMINATION:
        return <PnTerminationForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.PN_RECOVERY_MONITORING:
        return <PnRecoveryMonitoringForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.PN_REQUEST_TO_CLOSE_PN:
        return <PnRequestToClosePnForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.PN_AUTHORIZATION_TO_CLOSE_PN:
        return <PnAuthorizationToClosePnForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.PN_NOTICE_TO_CLOSE_PN:
        return <PnNoticeToClosePnForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.PN_REQUEST_TO_REOPEN_PN:
        return <PnRequestToReopenPnForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.PN_AUTHORIZATION_TO_REOPEN_PN:
        return <PnAuthorizationToReopenPnForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.PN_NOTICE_TO_REOPEN_PN:
        return <PnConfirmReopenPnForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.PN_ATTEST_REOPENED:
        return <PnAttestReopenedForm setDispatch={() => {
        }} trackElement={trackElement} dispatch={dispatch} editable={false}/>;
      case DispatchTypeEnum.AUTHORIZATION_TRAINING_ZONE:
        return <AuthorizationTrainingZoneForm dispatch={dispatch} editable={false} setDispatch={() => {
        }} trackElement={trackElement}/>;
      case DispatchTypeEnum.MODIFICATION_TRAINING_ZONE:
        return <ModificationTrainingZoneForm dispatch={dispatch} editable={false} setDispatch={() => {
        }} trackElement={trackElement}/>;
      case DispatchTypeEnum.CONFIRMATION_INITIAL_DEMARCATION_TRAINING_ZONE:
        return (
          <ConfirmationInitialDemarcationTrainingZoneForm dispatch={dispatch} editable={false} setDispatch={() => {
          }} trackElement={trackElement}/>
        );
      case DispatchTypeEnum.END_MISSION_TRAINING_ZONE:
        return <EndMissionTrainingZoneForm dispatch={dispatch} editable={false} setDispatch={() => {
        }} trackElement={trackElement}/>;
      case DispatchTypeEnum.MOVEMENT_WORKSITE_MACHINE:
        return <MovementWorksiteMachineForm dispatch={dispatch} editable={false} setDispatch={() => {
        }}/>;
      case DispatchTypeEnum.UNENRAILMENT_WORKSITE_MACHINE:
        return <UnenrailementWorksiteMachineForm dispatch={dispatch} editable={false} setDispatch={() => {
        }}/>;
      case DispatchTypeEnum.ENRAILMENT_WORKSITE_MACHINE:
        return <EnrailementWorksiteMachineForm dispatch={dispatch} editable={false} setDispatch={() => {
        }}/>;
      case DispatchTypeEnum.ENGAGEMENT_WORKSITE_MACHINE:
        return <EngagementWorksiteMachineForm dispatch={dispatch} editable={false} setDispatch={() => {
        }}/>;
      case DispatchTypeEnum.RELEASE_WORKSITE_MACHINE:
        return <ReleaseWorksiteMachineForm dispatch={dispatch} editable={false} setDispatch={() => {
        }}/>;
      case DispatchTypeEnum.IMMOBILIZATION_WORKSITE_MACHINE:
        return <ImmobilizationWorksiteMachineForm dispatch={dispatch} editable={false} setDispatch={() => {
        }}/>;
      case DispatchTypeEnum.MANEUVER_TRACK_CHANGE:
        return <ManeuverTrackChangeForm dispatch={dispatch} editable={false} setDispatch={() => {
        }}/>;
    }
  };

  const dispatchTitle = (dispatch: Dispatch) => {
    return (
      workAreaDispatchTypes
        .concat(workTrainDispatchTypes)
        .concat(installationPnDispatchTypes)
        .concat(trainingZoneDispatchTypes)
        .concat(worksiteMachineDispatchTypes)
        .concat(trackChangeDispatchTypes)
        .find((dt) => {
          return dt.value === dispatch.type;
        })?.label || ""
    );
  };

  const getOrderDispatchesComponents = (trackElement: TrackElement) => {
    let dispatches = [...(order?.dispatches || [])];
    dispatches = dispatches.sort((d1: Dispatch, d2: Dispatch) => {
      const date1 = new Date(d1.createdAt).getTime();
      const date2 = new Date(d2.createdAt).getTime();
      return date2 - date1;
    });
    return dispatches.map((d: Dispatch) => {
      return (
        <AccordionComponent
          key={d.id}
          variant="dispatch"
          title={dispatchTitle(d)}
          description={t("global.dateAndHours", {date: new Date(d.createdAt)})}
          content={dispatchFormSelected(CreateDispatch.fromDispatch(d), trackElement)}
        />
      );
    });
  };

  const checkErrors = () => {
    let errors = {};
    switch (trackElement?.type) {
      case TrackElementType.TRACK_CHANGE:
        errors = trackChangeDispatchValidation(dispatch);
        break;
      case TrackElementType.WORKSITE_MACHINE:
        errors = worksiteMachineDispatchValidation(dispatch);
        break;
      case TrackElementType.WORK_ZONE:
        errors = workAreaDispatchValidation(dispatch);
        break;
      case TrackElementType.WORK_TRAIN:
        errors = workTrainDispatchValidation(dispatch);
        break;
      case TrackElementType.PN:
        errors = pnDispatchValidation(dispatch);
        break;
      case TrackElementType.TRAINING_ZONE:
        errors = trainingZoneDispatchValidation(dispatch);
    }

    setErrors(errors);
    return errors;
  };

  const handleAdd = (incompatibilities?: IncompatibilityError[]) => {
    const errors = checkErrors();

    if (!Object.keys(errors).length) {
      if (trackElement?.id && order?.id) {
        ordersService
          .sendDispatch(
            trackElement.id,
            order.id,
            dispatch,
            incompatibilities?.map((i) => t(`incompatibilities.${i.error}`))
          )
          .subscribe({
            next: (stayOpen) => {
              enqueueSnackbar(t("dispatches.dispatchSuccess"), {variant: "success"});
              stayOpen ? setShowNewDispatchesForm(false) : navigate("../../../../..");
            },
            error: (err) => enqueueSnackbar(err.text, err.options),
          });
      }
    }
  };

  const handleClose = () => {
    navigate("..");
    closeSnackbar();
  };

  if (!trackElement || !order) {
    return null;
  }

  const isZFEncompassedByZT =
    trackElement.type === TrackElementType.TRAINING_ZONE &&
    projectQuery.projectZT.some(
      (ZT) =>
        !!ZT.pkEnd &&
        !!trackElement.pkEnd &&
        Pk.isPkBetweenPks(trackElement.pkStart, ZT.pkStart, ZT.pkEnd) &&
        Pk.isPkBetweenPks(trackElement.pkEnd, ZT.pkStart, ZT.pkEnd)
    );

  return (
    <>
      <ModalComponent
        title={order.name}
        handleClose={handleClose}
        fullHeight
        fullWidth
        content={
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={4} width="100%" pt="15px"
                 overflow="hidden">
            <Stack spacing={0.5}>
              <Typography textAlign="center" fontSize={16} fontWeight="500">
                {t(`dispatches.${projectQuery.isProjectTypeNew ? "dispatchCountNew" : "dispatchCount"}`, {count: order?.dispatches?.length || 0})}
              </Typography>
              <Typography textAlign="center" fontSize={12} fontWeight="400">
                {t("global.todayInLetters", {date: new Date()})}
              </Typography>
            </Stack>
            <Stack spacing={4} width="100%" overflow="auto" py="20px" className="scrollable">
              {!showNewDispatchesForm && (order == undefined || order?.isClose === false) && !sessionQuery.hasAnyOfRoles(Role.CLIENT) && !projectQuery.isClosed && (
                <Box mx={"auto"}>
                  <Button
                    variant={"contained"}
                    startIcon={<img alt="" src="/images/add_icon_white.svg"/>}
                    disabled={isZFEncompassedByZT}
                    onClick={() => setShowNewDispatchesForm(true)}>
                    {t(`dispatches.form.${projectQuery.isProjectTypeNew ? "newDispatchNew" : "newDispatch"}`)}
                  </Button>
                </Box>
              )}
              <Stack spacing={2} width="100%">
                {showNewDispatchesForm && (
                  <AccordionComponent
                    expended
                    variant="dispatch"
                    title={t(`dispatches.form.${projectQuery.isProjectTypeNew ? "newDispatchNew" : "newDispatch"}`)}
                    content={
                      <Stack spacing={"20px"}>
                        <DispatchForm
                          errors={errors}
                          dispatch={dispatch}
                          setDispatch={setDispatch}
                          handleReset={() => {
                            setDispatch({});
                            setErrors({});
                          }}
                          trackElement={trackElement}
                        />
                        {!!IncompatibilitiesAcceptation && <IncompatibilitiesAcceptation/>}
                      </Stack>
                    }
                    actions={
                      <Stack width="100%" direction="row" justifyContent="center" spacing={2} py="10px">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            setShowNewDispatchesForm(false);
                            setDispatch({});
                            setErrors({});
                          }}>
                          {t("global.cancel")}
                        </Button>
                        <Button
                          variant="contained"
                          disabled={disabledByIncompatibilities}
                          onClick={
                            !!IncompatibilitiesAcceptation
                              ? () => {
                                const errors = checkErrors();
                                if (!Object.keys(errors).length) setOpenAddConfirmCode(incompatibilities);
                              }
                              : () => handleAdd()
                          }>
                          {t("global.saveAndValidate")}
                        </Button>
                      </Stack>
                    }
                  />
                )}
                {getOrderDispatchesComponents(trackElement)}
              </Stack>
            </Stack>
          </Stack>
        }
        actions={
          order.isClose || sessionQuery.hasAnyOfRoles(Role.CLIENT) || projectQuery.isClosed ? undefined : (
            <Button
              variant="contained"
              color="error"
              disabled={isZFEncompassedByZT}
              onClick={() => setOpenCloseOrderConfirmCode(true)}>
              {t("dispatches.closeOrder")}
            </Button>
          )
        }
      />
      {openCloseOrderConfirmCode && (
        <ConfirmCodeModal
          handleSuccess={() => {
            enqueueSnackbar(t("dispatches.closeOrderSuccess"), {variant: "success"});
            handleClose();
          }}
          handleConfirm={(code) => ordersService.closeOrder(orderId ?? "", trackElementId ?? "", code)}
          handleClose={() => setOpenCloseOrderConfirmCode(false)}
        />
      )}
      {openAddConfirmCode && (
        <ConfirmCodeModal
          handleClose={() => setOpenAddConfirmCode(false)}
          handleConfirm={(code) => sessionService.confirmIdentificationCode(code)}
          handleSuccess={() => {
            setOpenAddConfirmCode(false);
            handleAdd(openAddConfirmCode);
          }}
        />
      )}
    </>
  );
};

export default DispatchesModal;
