import {Pk, TrackElement} from "@store/project";
import {DispatchTypeEnum, ZFDispatchType} from "@store/dispatches/dispatch.model";

import {IncompatibilityError, IncompatibilityErrorEnum} from "@utils/incompatibilities/incompatibility.utils";
import _ from 'lodash';
import { ID } from "@datorama/akita";

interface checkNoZFCutsZTParams {
  trackElementId?: ID;
  fromDispatch?: boolean;
  pkStart: Pk;
  pkEnd: Pk;
  status?: ZFDispatchType;
  ZTs: TrackElement[];
}

/* Should not be able to set the PKs of a ZF on a ZT */
const checkNoZFCutsZT = (params: checkNoZFCutsZTParams): IncompatibilityError[] => {
  const {fromDispatch, pkStart, pkEnd, status, trackElementId, ZTs} = params;

  const ZTOnZFs = ZTs.filter(
    (t) =>
      !!t.pkEnd && (Pk.isPkBetweenPks(pkStart, t.pkStart, t.pkEnd) || Pk.isPkBetweenPks(pkEnd, t.pkStart, t.pkEnd))
      && !_.isEqual(pkStart, t.pkEnd) && !_.isEqual(pkEnd, t.pkStart)
  );

  if (!fromDispatch || status === DispatchTypeEnum.MODIFICATION_TRAINING_ZONE) {
    return ZTOnZFs.map((ZT) => ({
      error: IncompatibilityErrorEnum.NO_ZF_CUTS_ZT,
      trackElementId,
      concernedTrackElementIds: [ZT.id],
    }));
  }
  return [];
};
export default checkNoZFCutsZT;
