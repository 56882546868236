import {date, object, string} from "yup";
import I18n from "@utils/i18n.utils";
import * as YupUtils from "@utils/yup.utils";

import {CreatePeopleCountingHistory} from "@store/dispatches/dispatch.model";

export const addAccidentAtWorkYup = (data: CreatePeopleCountingHistory) => {
  const requiredString = string().required(I18n.t("errors.required"));
  const requiredDate = date().required(I18n.t("errors.required"));

  try {
    const schema = object().shape({
      projectId: requiredString,
      name: requiredString,
      date: requiredDate,
    });

    schema.validateSync(data, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}
