import {ID} from "@datorama/akita";
import {catchError, from, map, Observable, tap} from "rxjs";
import {AxiosError, AxiosResponse} from "axios";

import APIAxios, {APIRoutes} from "@api/axios.api";
import SnackError from "@utils/error.utils";

export class FilesService {
  getFile = (fileId: ID): Observable<Blob> => {
    return from(APIAxios(APIRoutes.GETFile(fileId)))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError(err.response?.data?.message, "error");
        }),
        map((response: AxiosResponse<Blob>) => {
          return response.data;
        }),
        tap(this.openFileInNewTab),
      );
  }

  openFileInNewTab = (file: Blob) => {
    const url = URL.createObjectURL(file)
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }

  downloadFile = (file: Blob, filename: string) => {
    const url = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.setAttribute("download", filename);
    link.click();
    link.parentNode?.removeChild(link);
  };
}

export const filesService = new FilesService();
