import I18nUtils from "@utils/i18n.utils";

import {Pk, projectQuery, TrackElement, TrackElementType, TTxPath} from "@store/project";
import {ManeuverOrientation} from "@store/dispatches/dispatch.model";

export const getPNsOnPath = (pkStart: Pk, pkEnd: Pk) => {
  const pns = projectQuery.projectPN.filter((pn) =>
    Pk.toMeters(pkEnd) < Pk.toMeters(pkStart) ? Pk.isPkBetweenPks(pn.pkStart, pkEnd, pkStart) : Pk.isPkBetweenPks(pn.pkStart, pkStart, pkEnd)
  );

  return pns.map((pn) => `${pn.attributes?.name ?? ""} - ${pn.attributes?.isClosed ? "Fermé" : "Ouvert"}`);
};

export const getStopSignsOnPath = (TTXPath: TTxPath[]) => {
  const stopSigns = projectQuery.projectSignalisation.filter((signalisation) => {
    return (
      signalisation.type === TrackElementType.SIGN_STOP &&
      projectQuery.checkIfElementInTTxPath(TTXPath, signalisation.pkStart, signalisation.pkEnd, signalisation.tracks)
    );
  });

  return stopSigns.map((stopSign) => `${I18nUtils.t("schema.signalisation.types.stop")} - ${Pk.toString(stopSign.pkStart)}`);
};

export const getADVsOnPath = (TTXPath: TTxPath[]) => {
  return (TTXPath.flatMap((p) => [p.ADV]).filter((a) => !!a) as TrackElement[]).map(
    (adv) => `${adv.attributes?.name ?? ""} - ${ManeuverOrientation.label(adv.attributes?.maneuverOrientation)}`
  );
};

export const getNotPassableZTsOnPath = (TTXPath: TTxPath[]) => {
  const notPassableZTsOnPath = projectQuery.projectZT.filter(
    (ZT) => !!ZT.attributes?.isNotPassable && projectQuery.checkIfElementInTTxPath(TTXPath, ZT.pkStart, ZT.pkEnd)
  );

  return notPassableZTsOnPath.map((ZT) => `${ZT.attributes?.name ?? ""} - ${I18nUtils.t("schema.workArea.notPassableZT")}`);
};

export const getElementsOnPath = (TTXPath: TTxPath[]) => {
  const restrictions = projectQuery.projectRestrictions.filter((restriction) => {
    return !restriction.attributes?.limitation || +restriction.attributes.limitation < 31;
  });

  const lightweightsActivities = projectQuery.projectActivities.filter((activity) => activity.type === TrackElementType.LIGHTWEIGHT);

  const specificInstructionsElements = [...restrictions, ...lightweightsActivities].filter((instruction) =>
    projectQuery.checkIfElementInTTxPath(TTXPath, instruction.pkStart, instruction.pkEnd, instruction.tracks)
  );

  return specificInstructionsElements.map(
    (instruction) =>
      `${I18nUtils.t(`TTXDispatch.${instruction.type}`) ?? ""}${
        instruction.type === TrackElementType.SPEED_LIMIT ? ` ${instruction.attributes?.limitation ?? 0}${I18nUtils.t("schema.restriction.kmh")}` : ""
      } - ${instruction.attributes?.name ?? ""}`
  );
};
