import {ID} from "@datorama/akita";
import {catchError, from, map, Observable} from "rxjs";
import {AxiosError, AxiosResponse} from "axios";

import {
  AccidentAtWorkFilters,
  AccidentAtWorkModel,
  DateFilters,
  PeopleCountingFilters,
  PeopleCountingModel,
  ProjectDispatchesByDateStats,
  ProjectDispatchesStats,
  ProjectOrdersStats,
  ProjectStats
} from "@store/reporting/reporting.model";

import APIAxios, {APIRoutes} from "@api/axios.api";
import SnackError from "@utils/error.utils";
import {mergeDateAndHour} from '@utils/date.utils';

export class ReportingService {
  getProjectStats(projectId: ID): Observable<ProjectStats> {
    return from(
      APIAxios({
        ...APIRoutes.GETProjectStats(),
        params: {projectId},
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<ProjectStats>) => {
        return response.data;
      }),
    );
  }

  setDatesFilters = (filters?: DateFilters) => {
    return {
      startDate: mergeDateAndHour(filters?.startDate, filters?.startHour),
      endDate: mergeDateAndHour(filters?.endDate, filters?.endHour),
    };
  }

  getPeopleCounting(projectId: ID, filters?: PeopleCountingFilters): Observable<PeopleCountingModel[]> {
    const dateFilters = this.setDatesFilters(filters);

    return from(
      APIAxios({
        ...APIRoutes.GETProjectCountingPeople(),
        params: {
          projectId,
          search: filters?.search ?? undefined,
          category: filters?.category ? [filters.category] : undefined,
          startDate: dateFilters?.startDate?.toISOString() ?? undefined,
          endDate: dateFilters?.endDate?.toISOString() ?? undefined,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<PeopleCountingModel[]>) => {
        return response.data;
      }),
    );
  }

  getPeopleCountingCSV = (projectId: ID, filters?: PeopleCountingFilters): Observable<Blob> => {
    const dateFilters = this.setDatesFilters(filters);

    return from(
      APIAxios({
        ...APIRoutes.GETProjectCountingPeopleCSV(),
        params: {
          projectId,
          search: filters?.search ?? undefined,
          category: filters?.category ? [filters.category] : undefined,
          startDate: dateFilters?.startDate?.toISOString() ?? undefined,
          endDate: dateFilters?.endDate?.toISOString() ?? undefined,
        },
        responseType: "blob",
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Blob>) => {
        return response.data;
      })
    );
  };

  getAccidentsAtWork(projectId: ID, filters?: AccidentAtWorkFilters): Observable<AccidentAtWorkModel[]> {
    const dateFilters = this.setDatesFilters(filters);

    return from(
      APIAxios({
        ...APIRoutes.GETProjectAccidentsAtWork(),
        params: {
          projectId,
          search: filters?.search ?? undefined,
          startDate: dateFilters?.startDate?.toISOString() ?? undefined,
          endDate: dateFilters?.endDate?.toISOString() ?? undefined,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError(err.response?.data?.message, "error");
      }),
      map((response: AxiosResponse<AccidentAtWorkModel[]>) => {
        return response.data;
      }),
    );
  }

  getAccidentsAtWorkCSV = (projectId: ID, filters?: AccidentAtWorkFilters): Observable<Blob> => {
    const dateFilters = this.setDatesFilters(filters);

    return from(
      APIAxios({
        ...APIRoutes.GETProjectAccidentsAtWorkCSV(),
        params: {
          projectId,
          search: filters?.search ?? undefined,
          startDate: dateFilters?.startDate?.toISOString() ?? undefined,
          endDate: dateFilters?.endDate?.toISOString() ?? undefined,
        },
        responseType: "blob",
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Blob>) => {
        return response.data;
      })
    );
  };

  getDispatchesStats = (projectId: ID): Observable<ProjectDispatchesStats[]> => {
    return from(APIAxios(APIRoutes.GETProjectDispatchesStats(projectId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<ProjectDispatchesStats[]>) => {
        return response.data;
      })
    );
  };

  getOrdersStats = (projectId: ID): Observable<ProjectOrdersStats[]> => {
    return from(APIAxios(APIRoutes.GETProjectOrdersStats(projectId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<ProjectOrdersStats[]>) => {
        return response.data;
      })
    );
  };

  getDispatchesByDayStats = (projectId: ID, selectedDate?: Date): Observable<ProjectDispatchesByDateStats[]> => {
    return from(APIAxios({
      ...APIRoutes.GETProjectDispatchesByDayStats(projectId),
      params: {selectedDate: selectedDate?.toISOString()},
    })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<ProjectDispatchesByDateStats[]>) => {
        return response.data;
      })
    );
  };
}

export const reportingService = new ReportingService();