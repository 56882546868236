import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, DialogActions, Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {Outlet, useNavigate, useParams} from "react-router-dom";

import {projectQuery, projectService} from "@store/project";
import {User} from "@store/users";

import UserCard from "@components/user/User.card";

const ClientsTab = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const {projectId} = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState<User[]>([]);

  useEffect(() => {
    projectService.getProjectCustomers().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });

    const _customers$ = projectQuery.projectCustomers$.subscribe(setCustomers);

    const _loading$ = projectQuery.selectLoading().subscribe(setLoading);

    return () => {
      _customers$.unsubscribe();
      _loading$.unsubscribe();
    };
  }, [enqueueSnackbar, projectId]);

  return (
    <>
      {loading ? (
        <Box height="100%" width="100%" display="flex" alignItems="center" mb="20px" justifyContent="center">
          <CircularProgress size={50}/>
        </Box>
      ) : (
        <Box flex={1} className="scrollable" overflow="auto" pr="5px" pt="20px" pb="10px">
          <Grid container spacing={4}>
            {customers.map((user) => (
              <Grid key={user.id} item>
                <UserCard user={user} handleClick={() => navigate(user.id.toString())}/>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {!projectQuery.isClosed && (
        <Box mb="20px">
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("create")}
              startIcon={<img alt="" src="/images/add_icon_white.svg"/>}>
              {t("projectDetails.projectInfo.clients.button")}
            </Button>
          </DialogActions>
        </Box>
      )}
      <Outlet/>
    </>
  );
}

export default ClientsTab;
