import {array, date, number, object, string} from 'yup';

import {CreateOrUpdateTrackElement, PK_REGEXP, TrackElementCategory, TrackElementType} from "@store/project";

import * as YupUtils from "@utils/yup.utils";
import I18n from "@utils/i18n.utils";

const requiredString = string().required(I18n.t("errors.required"));
const requiredPK = requiredString.matches(PK_REGEXP, I18n.t("errors.pk"));
const requiredNumber = number().typeError(I18n.t("errors.number")).required(I18n.t("errors.required"));
const requiredDate = date().nullable()
  .required(I18n.t("errors.required"))
  .typeError(I18n.t("errors.date"));
const requiredHour = date().nullable()
  .required(I18n.t("errors.required"))
  .typeError(I18n.t("errors.hour"));

const trainTrackValidation = object().shape({
  type: requiredString,
  pkStart: requiredPK,
  pkEnd: requiredPK,
  attributes: object().shape({
    name: requiredString,
    lineId: requiredString,
  }),
});

const trackChangeValidation = object().shape({
  type: requiredString,
  pkStart: requiredPK,
  pkEnd: requiredPK,
  tracks: array().required(I18n.t("errors.required")).length(2, I18n.t("errors.required")),
});

const trackChangeNewValidation = object().shape({
  type: requiredString,
  pkStart: requiredPK,
  pkEnd: requiredPK,
  tracks: array().required(I18n.t("errors.required")).length(2, I18n.t("errors.required")),
  attributes: object().shape({
    name: requiredString,
  }),
});

const nameAndPkValidation = object().shape({
  type: requiredString,
  pkStart: requiredPK,
  attributes: object().shape({
    name: requiredString,
  }),
});

const pnValidation = object().shape({
  type: requiredString,
  pkStart: requiredPK,
  attributes: object().shape({
    name: requiredString,
    type: requiredString,
  }),
});

const pkOnlyValidation = object().shape({
  type: requiredString,
  pkStart: requiredPK,
});

const doublePKValidation = object().shape({
  type: requiredString,
  pkStart: requiredPK,
  pkEnd: requiredPK,
});

const declivityValidation = object().shape({
  type: requiredString,
  pkStart: requiredPK,
  pkEnd: requiredPK,
  attributes: object().shape({
    declivity: requiredNumber,
  }),
});

const activityValidation = object().shape({
  type: requiredString,
  pkStart: requiredPK,
  pkEnd: requiredPK,
  attributes: object().when("type", {
    is: TrackElementType.LIGHTWEIGHT,
    then: object().shape({
      name: requiredString,
    }),
  }).when("type", {
    is: TrackElementType.ACTIVITY,
    then: object().shape({
      name: requiredString,
      natureOfActivity: requiredString,
      ZT: requiredString,
    }),
  }).shape({
    dateTime: requiredDate,
    RA: requiredString,
  }),
});

const speedLimitValidation = object().shape({
  type: requiredString,
  pkStart: requiredPK,
  pkEnd: requiredPK,
  attributes: object().shape({
    name: requiredString,
    limitation: requiredNumber,
  }),
});

const restrictionValidation = object().shape({
  type: requiredString,
  pkStart: requiredPK,
  pkEnd: requiredPK,
  attributes: object().shape({
    name: requiredString,
  }),
});

const worksiteValidation = object().shape({
  type: requiredString,
  pkStart: requiredPK,
  pkEnd: requiredPK,
  attributes: object().shape({
    name: requiredString,
    RCCI: requiredString,
    company: requiredString,
  }),
});

const worksiteMachineValidation = object().shape({
  type: requiredString,
  tracks: array().required(I18n.t("errors.required")).min(1, I18n.t("errors.required")),
  pkStart: requiredPK,
  attributes: object().shape({
    name: requiredString,
    dateTime: requiredDate,
  }),
});

const workZoneValidation = object().shape({
  type: requiredString,
  pkStart: requiredPK,
  pkEnd: requiredPK,
  attributes: object().shape({
    name: requiredString,
    RZT: requiredString,
  }),
});

const ceValidation = object().shape({
  type: requiredString,
  pkStart: requiredPK,
  pkEnd: requiredPK,
  tracks: array().required(I18n.t("errors.required")).min(1, I18n.t("errors.required")),
  attributes: object().shape({
    name: requiredString,
    RZT: requiredString,
  }),
});

const workTrainValidation = object().shape({
  type: requiredString,
  tracks: array().required(I18n.t("errors.required")).min(1, I18n.t("errors.required")),
  pkStart: requiredPK,
  attributes: object().shape({
    name: requiredString,
    TTXLength: requiredNumber,
    dateTime: requiredDate,
    dateTimeHour: requiredHour,
  }),
});

export const trackElementValidation = (data: CreateOrUpdateTrackElement) => {

  try {
    let schema = object();

    switch (data.category) {
      case TrackElementCategory.WORK_ZONE:
        schema = workZoneValidation;
        break;
      case TrackElementCategory.ELEMENTARY_CONSTRUCTION_SITE:
        schema = ceValidation;
        break;
      case TrackElementCategory.TRAIN_TRACK:
        schema = trainTrackValidation;
        break;
      case TrackElementCategory.TRACK_DEVICE:
        switch (data.type) {
          case TrackElementType.TRACK_CHANGE:
            schema = trackChangeNewValidation;
            break;
          case TrackElementType.WEDGE:
            schema = nameAndPkValidation;
            break;
        }
        break;
      case TrackElementCategory.INSTALLATION:
        switch (data.type) {
          case TrackElementType.TRAIN_STATION:
          case TrackElementType.PN:
            schema = pnValidation;
            break;
          case TrackElementType.PRA:
          case TrackElementType.PRO:
          case TrackElementType.STOPPER:
            schema = pkOnlyValidation;
            break;
          case TrackElementType.BUFFER:
          case TrackElementType.TUNNEL:
          case TrackElementType.VIADUCT:
            schema = doublePKValidation;
            break;
          case TrackElementType.INST_OTHER:
            schema = nameAndPkValidation;
            break;
        }
        break;
      case TrackElementCategory.SIGNALISATION:
        switch (data.type) {
          case TrackElementType.WHISTLE:
          case TrackElementType.SIGN_STOP:
            schema = pkOnlyValidation;
            break;
          case TrackElementType.SIGNALISATION:
          case TrackElementType.SIGN_OTHER:
            schema = nameAndPkValidation;
            break;
        }
        break;
      case TrackElementCategory.ZONE:
        switch (data.type) {
          case TrackElementType.TRAINING_ZONE:
            schema = restrictionValidation;
            break;
          default:
            schema = doublePKValidation;
            break;
        }
        break;
      case TrackElementCategory.DECLIVITY:
        schema = declivityValidation;
        break;
      case TrackElementCategory.ACTIVITY:
        schema = activityValidation;
        break;
      case TrackElementCategory.WORKSITE:
        schema = worksiteValidation;
        break;
      case TrackElementCategory.RESTRICTION:
        switch (data.type) {
          case TrackElementType.SPEED_LIMIT:
            schema = speedLimitValidation;
            break;
          default:
            schema = restrictionValidation;
            break;
        }
        break;
      case TrackElementCategory.WORKSITE_MACHINE:
        schema = worksiteMachineValidation;
        break;
      case TrackElementCategory.PRS:
        schema = nameAndPkValidation;
        break;
      case TrackElementCategory.TRAIN_STATION:
        schema = restrictionValidation;
        break;
      case TrackElementCategory.CONSTRUCTION:
        schema = nameAndPkValidation;
        break;
      case TrackElementCategory.WORK_TRAIN:
        schema = workTrainValidation;
        break;
    }

    schema.validateSync({
      ...data,
      attributes: {
        ...data.attributes,
        declivity: data.attributes?.declivity !== undefined ? parseFloat(data.attributes.declivity) : undefined,
        limitation: data.attributes?.limitation !== undefined ? parseFloat(data.attributes.limitation) : undefined,
        TTXLength: data.attributes?.TTXLength !== undefined ? parseFloat(data.attributes.TTXLength) : undefined,
      },
    }, {abortEarly: false});
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
}

