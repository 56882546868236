import {GridColumns} from "@mui/x-data-grid";
import {Tooltip} from "@mui/material";

import I18n from "@utils/i18n.utils";

export const accidentsAtWorkColumns = () => [
  {
    field: "content.name",
    headerName: I18n.t("reporting.accidentsAtWork.table.accident"),
    headerAlign: "center",
    valueGetter: (params) => params.row.content.name,
    align: "center",
    width: 180,
  },
  {
    field: "content.date",
    headerName: I18n.t("reporting.accidentsAtWork.table.date"),
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.content.date,
    valueFormatter: (params) =>
      params.value
        ? I18n.t("reporting.accidentsAtWork.table.day", {date: new Date(params.value)})
        : "-",
    width: 135,
  },
  {
    field: "content.victim",
    headerName: I18n.t("reporting.accidentsAtWork.table.victim"),
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.content.victim ?? '-',
    width: 200,
  },
  {
    field: "content.company",
    headerName: I18n.t("reporting.accidentsAtWork.table.company"),
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.content.company ?? '-',
    width: 200,
  },
  {
    field: "content.location",
    headerName: I18n.t("reporting.accidentsAtWork.table.location"),
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.content.location ?? '-',
    width: 200,
  },
  {
    field: "content.nature",
    headerName: I18n.t("reporting.accidentsAtWork.table.nature"),
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.content.nature ?? '-',
    width: 200,
  },
  {
    field: "content.causes",
    headerName: I18n.t("reporting.accidentsAtWork.table.causes"),
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.content.causes,
    renderCell: (params) => (
      <Tooltip title={params.value ?? ""}>
        <span style={{
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center'
        }}>{params.value ?? "-"}</span>
      </Tooltip>
    ),
    flex: 1,
  },
] as GridColumns;
